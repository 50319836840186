/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
import ReactDOM from 'react-dom';

// This portal is so the dragged item is not scaled and translated
// when dragged
let portal = document.getElementById('drag-portal');
if (!portal) {
  portal = document.createElement('div');
  portal.id = 'drag-portal';
  document.body.appendChild(portal);
}

export const PortalAware = ({ provided, snapshot, children }) => {
  const usePortal = snapshot.isDragging;

  if (!usePortal) {
    return children;
  }

  return ReactDOM.createPortal(children, portal);
};
