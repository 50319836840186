/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { endpointConfig } from './config/endpoint_config';
import { CommsProvider } from './context/CommsContext';
import { EventProvider } from './context/EventContext';
import { LocationProvider } from './context/LocationContext';
import { NotificationProvider } from './context/NotificationContext';
import { SignalProvider } from './context/SignalContext';
import { useUser } from './context/UserContext';
import { historyType } from './enums/propTypes';
import { useDarkMode } from './context/DarkModeContext';
import { getClassNames } from './utils/string';

/**
 * Router wrapper component to ensure that pages that need authentication can only be access by authenticated users
 *
 * @param {any} history react router
 * @param {Jsx} children child elements
 */
export const Authenticated = ({ history, children }) => {
  const userValue = useUser();
  const globalDarkMode = useDarkMode();

  useEffect(() => {
    if (!userValue.user) {
      // If user not found, re-route to login.
      history.push(endpointConfig.frontendEndpoints.login);
    }
  });

  return !userValue.user ? (
    <></>
  ) : (
    <EventProvider>
      <SignalProvider>
        <LocationProvider>
          <NotificationProvider parentHistory={history}>
            <div
              className={getClassNames({
                'dot-toaster': true,
                'dark-mode': globalDarkMode.darkMode,
              })}
            >
              <Toaster
                position="bottom-right"
                toastOptions={{
                  duration: 4000,
                  className: 'dot-toast',
                  success: {
                    iconTheme: {
                      primary: 'var(--colour-positive-status-a)',
                      secondary: 'var(--colour-white)',
                    },
                  },
                  error: {
                    iconTheme: {
                      primary: 'var(--colour-white)',
                      secondary: 'var(--colour-negative-status-a)',
                    },
                    style: {
                      background: 'var(--colour-negative-status-a)',
                      color: 'var(--colour-white)',
                    },
                  },
                }}
              />
            </div>
            <CommsProvider>{children}</CommsProvider>
          </NotificationProvider>
        </LocationProvider>
      </SignalProvider>
    </EventProvider>
  );
};

Authenticated.propTypes = {
  history: historyType,
  children: PropTypes.any,
};
