/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { AutoSizer, List } from 'react-virtualized';
import { useComms } from '../../../context/CommsContext';
import { SIDEBARS, useSidebar } from '../../../context/SidebarContext';
import { useUser } from '../../../context/UserContext';
import {
  groupType,
  missionType,
  orgRoleType,
  personnelType,
  userType,
} from '../../../enums/propTypes';
import { sortBy } from '../../../utils/array';
import { displayName, getClassNames, capitalize } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { MenuButton } from '../../common/Menu';
import { PortalAware } from '../../common/PortalAware';
import { LightScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import { Tabs } from '../../common/Tabs';
import { getMissionPersonnel } from '../../../api/orgs_users';
import add from '../../../assets/images/map-icons/add.png';
import { secrets } from '../../../config/secrets';

/**
 * Map Sidebar - mission roles
 * Lists all roles participating in the mission
 *
 * @param {Function} closeSidebar callback to close sidebar
 * @param {Boolean} canEdit are we the owner of the mission
 * @param {Boolean} isMultiple are we on multimission view
 * @param {Function} setUser callback to set user
 * @param {any[]} orgUsers org users list
 * @param {any[]} users users list
 * @param {any[]} roles roles list
 * @param {Function} chatWithUser callback to chat with user
 * @param {Function} updateUsers callback to chat with user
 * @param {string} draggingFrom is there something being dragged
 * @param {Function} centreMapOnPersonnel callback to centre map on a unit
 */
export const MissionUsers = ({
  closeSidebar,
  canEdit,
  isMultiple,
  orgUsers,
  users,
  setUser,
  chatWithUser,
  roles,
  mission,
  draggingFrom,
  addToMission,
  removeFromMission,
  centreMapOnPersonnel,
}) => {
  const [search, setSearch] = useState('');
  const [userList, setUserList] = useState([]);
  const [tab, setTab] = useState('users');
  const [role, setRole] = useState(null);

  const searchUsers = (searchText) => {
    const allUsers = [
      ...orgUsers.map((u) => {
        u.inMission = !!users.find((uu) => uu.id === u.id);
        return u;
      }),
      ...users
        .filter((u) => !orgUsers.map((ou) => ou.id).includes(u.id))
        .map((u) => {
          u.inMission = true;
          u.roleId = u.guest ? 'guest' : u.roleId;
          u.organisation = !orgUsers.map((ou) => ou.id).includes(u.id)
            .organisation;
          return u;
        }),
    ].filter((u) => u.type !== 'role');

    const allRoles = [
      ...roles.map((u) => {
        u.type = 'role';
        u.inMission =
          !!allUsers.filter((al) => al.roleId === u.id).length &&
          allUsers.filter((al) => al.roleId === u.id).length ===
            allUsers.filter((al) => al.roleId === u.id && al.inMission).length;
        u.users = allUsers.filter((al) => al.roleId === u.id).length;
        u.usersInMission = allUsers.filter(
          (al) => al.roleId === u.id && al.inMission
        ).length;
        return u;
      }),
    ].filter((r) => r.users !== 0);

    sortBy(allRoles, ['inMission', 'role'], ['desc', 'asc'], true);

    sortBy(
      allUsers,
      ['inMission', 'firstname', 'lastname'],
      ['desc', 'asc', 'asc'],
      true
    );

    setUserList([
      ...allRoles.filter((o) =>
        o.role.toLowerCase().includes(searchText.toLowerCase())
      ),
      ...allUsers.filter(
        (o) =>
          o.firstname &&
          o.lastname &&
          `${o.firstname} ${o.lastname}`
            .toLowerCase()
            .includes(searchText.toLowerCase())
      ),
    ]);
  };

  useEffect(() => {
    searchUsers(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUsers, users, search]);

  const exit = () => {
    closeSidebar();
  };

  let title = canEdit
    ? 'Users'
    : `${capitalize(secrets.EVENT_NAME)}
  Users`;

  return (
    <div className="mission-sidebar mission-allies">
      <div className="top">
        <div className="heading">
          <div className="text">{title}</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>

        <SearchInput
          placeholder="Search users..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          light
        />
      </div>

      <Tabs
        items={[
          {
            text: `Users`,
            active: tab === 'users',
            onClick: () => {
              setRole(null);
              setTab('users');
            },
          },
          {
            text: `Roles`,
            active: tab === 'roles',
            onClick: () => setTab('roles'),
          },
        ]}
      />
      <SelectUserList
        users={userList}
        setUser={setUser}
        canEdit={canEdit}
        chatWithUser={chatWithUser}
        roles={roles}
        addToMission={addToMission}
        removeFromMission={removeFromMission}
        draggingFrom={draggingFrom}
        isMultiple={isMultiple}
        roleChanged={() => {
          setSearch('');
        }}
        mission={mission}
        closeSidebar={closeSidebar}
        tab={tab}
        centreMapOnPersonnel={centreMapOnPersonnel}
        role={role}
        setRole={setRole}
      ></SelectUserList>
    </div>
  );
};

MissionUsers.propTypes = {
  closeSidebar: PropTypes.func,
  canEdit: PropTypes.bool,
  isMultiple: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.any),
  setUser: PropTypes.func,
  roles: PropTypes.arrayOf(orgRoleType),
  draggingFrom: PropTypes.string,
  centreMapOnPersonnel: PropTypes.func,
};

/**
 * Display the unit or personnel in left hand sidebar
 *
 * @param {any} group the unit / personnel
 * @param {Function} addToMission assign to mission
 * @param {Function} setUnit update unit
 * @param {Function} onClick unit is clicked on (drills down to children)
 * @param {bool} active unit is assigned
 * @returns
 */
export const MissionUnitPanel = ({
  group,
  addToMission,
  setUnit,
  onClick,
  active,
}) => {
  const options = [];
  const sidebars = useSidebar();

  if (group.firstname && setUnit) {
    options.push({
      text: 'User Info',
      ariaLabel: 'User Info',
      icon: 'info',
      onClick: () => {
        setUnit(group);
        sidebars.open(SIDEBARS.unitInformation);
      },
    });
  }

  if (addToMission) {
    options.push({
      text: `Add to ${secrets.EVENT_NAME}`,
      ariaLabel: `Add to ${secrets.EVENT_NAME}`,
      icon: 'plus',
      onClick: () => {
        if (group.firstname) {
          addToMission([group.id], null);
        } else {
          addToMission(null, [group.id]);
        }
      },
    });
  }

  return (
    <div
      onClick={(e) => {
        if (!group.firstname) {
          onClick(e);
        }
      }}
      className={getClassNames({
        'unit-panel-container': true,
        draggable: true,
        clickable: true,
        active,
      })}
    >
      <div className="body">
        {/* <div className="breadcrumb">{group.breadcrumb}</div> */}
        <div className="unit">
          <div className="img">
            <Avatar entity={group} size={'var(--team-size)'} />
          </div>
          <div className="text">
            {displayName(group)} <span>{group.role?.role}</span>
          </div>
          <div className="more">
            {!!options.length && (
              <MenuButton
                buttonTheme="light"
                position="right"
                options={options}
                ariaLabel="Group Menu"
              >
                <FontAwesomeIcon icon="bars" />
              </MenuButton>
            )}
          </div>
          {!group.firstname && (
            <div className="count">
              {/* <FontAwesomeIcon
                icon="user"
                style={{
                  width: '16px',
                  height: '16px',
                }}
              /> */}
              {group.firstname ? 1 : group?.users || 0}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MissionUnitPanel.propTypes = {
  group: PropTypes.oneOfType([groupType, personnelType]),
  addToMission: PropTypes.func,
  setUnit: PropTypes.func,
  onClick: PropTypes.func,
};

/**
 * List of users
 *
 * @param {any[]} users list of users
 * @param {Function} setUser set user
 * @param {Boolean} isMultiple are we on multimission view
 * @param {Boolean} canEdit is owner of the mission
 * @param {Function} chatWithUser callback to chat with user
 * @param {any[]} roles roles list
 * @param {Function} addToMission callback to add user to mission
 * @param {Function} removeFromMission callback to remove user to mission
 * @param {Function} roleChanged callback when role is changed
 * @param {string} draggingFrom is there something being dragged
 * @param {Function} closeSidebar callback to close sidebar
 * @param {string} tab the currently selected tab
 * @param {Function} centreMapOnPersonnel callback to centre map on unit
 * @param {any} role the role currently selected
 * @param {Function} setRole callback to set the currently selected role
 */
const SelectUserList = ({
  users,
  setUser,
  canEdit,
  chatWithUser,
  roles,
  addToMission,
  removeFromMission,
  draggingFrom,
  isMultiple,
  roleChanged,
  mission,
  closeSidebar,
  tab,
  centreMapOnPersonnel,
  role,
  setRole,
}) => {
  const list = useRef();

  const roleUsers = role
    ? users.filter((u) => u.roleId === role.id)
    : tab === 'roles'
    ? users
    : users.filter((u) => u.type !== 'role');
  const roleCount = roleUsers.filter((u) => u.type === 'role').length;
  const userCount = roleUsers.filter((u) => u.type !== 'role').length;

  const getItemStyle = (isDragging, draggableStyle, listStyle) => ({
    userSelect: 'none',
    transform: 'rotate(-1deg)',
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(isDragging ? {} : listStyle),
  });

  const handleScroll = (e) => {
    const { scrollTop, scrollLeft } = e;
    const { Grid } = list.current;
    Grid.handleScrollEvent({ scrollTop, scrollLeft });
  };

  const rowRenderer = ({ index, key, isScrolling, style }) => {
    const user = roleUsers[index];

    return canEdit ? (
      <Draggable key={key} draggableId={user.id} index={index}>
        {(provided, snapshot) => (
          <React.Fragment>
            <PortalAware provided={provided} snapshot={snapshot}>
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                    { ...style }
                  ),
                }}
                className={getClassNames({
                  dragging: snapshot.isDragging,
                })}
              >
                {snapshot.isDragging ? (
                  <MissionUnitPanel group={user} addToMission={() => {}} />
                ) : (
                  <>
                    {user.type === 'role' && tab === 'roles' && !role && (
                      <RoleSection
                        draggable
                        role={user}
                        setRole={(r) => {
                          roleChanged();
                          setRole(r);
                        }}
                        canEdit={canEdit}
                        roles={roles}
                        addToMission={addToMission}
                        removeFromMission={removeFromMission}
                        mission={mission}
                      ></RoleSection>
                    )}
                    {((tab === 'users' && user.type !== 'role') ||
                      (tab === 'roles' && role)) && (
                      <UserSection
                        draggable
                        user={user}
                        setUser={setUser}
                        setRole={(r) => {
                          roleChanged();
                          setRole(r);
                        }}
                        canEdit={canEdit}
                        chatWithUser={chatWithUser}
                        roles={roles}
                        addToMission={addToMission}
                        removeFromMission={removeFromMission}
                        mission={mission}
                        closeSidebar={closeSidebar}
                        centreMapOnPersonnel={centreMapOnPersonnel}
                      />
                    )}
                  </>
                )}
              </div>
            </PortalAware>
            {snapshot.isDragging && (
              <div className="clone" style={style}>
                <>
                  {user.type === 'role' ? (
                    <RoleSection role={user}></RoleSection>
                  ) : (
                    <UserSection user={user} roles={roles} />
                  )}
                </>
              </div>
            )}
          </React.Fragment>
        )}
      </Draggable>
    ) : (
      <>
        {user.type === 'role' && tab === 'roles' && !role && (
          <div key={key} style={style}>
            <RoleSection
              role={user}
              setRole={setRole}
              canEdit={canEdit}
              roles={roles}
              addToMission={addToMission}
              removeFromMission={removeFromMission}
              isMultiple={isMultiple}
            ></RoleSection>
          </div>
        )}
        {((tab === 'users' && user.type !== 'role') ||
          (tab === 'roles' && role)) && (
          <div key={key} style={style}>
            <UserSection
              user={user}
              setUser={setUser}
              setRole={setRole}
              canEdit={canEdit}
              chatWithUser={chatWithUser}
              roles={roles}
              addToMission={addToMission}
              removeFromMission={removeFromMission}
              isMultiple={isMultiple}
              centreMapOnPersonnel={centreMapOnPersonnel}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="content" style={{ marginBottom: '-20px', flexGrow: 0 }}>
        {
          <h5>
            {!role && !!roleUsers.filter((u) => u.type === 'role').length && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '0.5rem',
                }}
              >
                {!!roleUsers.filter((u) => u.inMission && u.type === 'role')
                  .length && (
                  <span
                    style={{
                      color: 'grey',
                      fontWeight: 800,
                      fontSize: '0.9rem',
                    }}
                  >
                    <FontAwesomeIcon icon="user-group" />{' '}
                    {pluralize(
                      'role',
                      roleUsers.filter((u) => u.inMission && u.type === 'role')
                        .length,
                      true
                    )}{' '}
                    in {secrets.EVENT_NAME}
                  </span>
                )}
                {!!roleUsers.filter((u) => u.type === 'role').length && (
                  <span
                    style={{
                      color: 'grey',
                      fontWeight: 800,
                      fontSize: '0.9rem',
                    }}
                  >
                    <FontAwesomeIcon icon="user-group" />{' '}
                    {pluralize(
                      'role',
                      roleUsers.filter((u) => u.type === 'role').length,
                      true
                    )}{' '}
                    total
                  </span>
                )}
              </div>
            )}
            {!!role && (
              <div
                style={{
                  color: 'grey',
                  marginRight: '1rem',
                  marginBottom: '0.5rem',
                  fontWeight: '800',
                  fontSize: '0.9rem',
                }}
              >
                Showing {role.users} users in {role.role}{' '}
                <DotButton
                  style={{
                    border: 'none',
                    background: 'none',
                    display: 'inline-block',
                  }}
                  onClick={() => {
                    if (setRole) {
                      setRole(null);
                    }
                  }}
                >
                  <FontAwesomeIcon style={{ color: 'grey' }} icon="times" />
                </DotButton>
              </div>
            )}
            {tab === 'users' &&
              !role &&
              !!roleUsers.filter((u) => u.type !== 'role').length && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '0.5rem',
                  }}
                >
                  {!!roleUsers.filter((u) => u.inMission && u.type !== 'role')
                    .length && (
                    <span
                      style={{
                        color: 'grey',
                        fontWeight: 800,
                        fontSize: '0.9rem',
                      }}
                    >
                      <FontAwesomeIcon icon="user-group" />{' '}
                      {pluralize(
                        'user',
                        roleUsers.filter(
                          (u) => u.inMission && u.type !== 'role'
                        ).length,
                        true
                      )}{' '}
                      in {secrets.EVENT_NAME}
                    </span>
                  )}
                  {!!roleUsers.filter((u) => u.type !== 'role').length && (
                    <span
                      style={{
                        color: 'grey',
                        fontWeight: 800,
                        fontSize: '0.9rem',
                      }}
                    >
                      <FontAwesomeIcon icon="user-group" />{' '}
                      {pluralize(
                        'user',
                        roleUsers.filter((u) => u.type !== 'role').length,
                        true
                      )}{' '}
                      total
                    </span>
                  )}
                </div>
              )}
          </h5>
        }
      </div>
      <div
        style={{ flexGrow: 1, width: '100%', padding: '10px 10px 10px 10px' }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <LightScrollbar
              onScroll={handleScroll}
              style={{
                height,
                width: width + 20,
                marginLeft: '-10px',
                marginRight: '-10px',
              }}
            >
              {canEdit ? (
                <Droppable droppableId={`inventory`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="blocks"
                    >
                      <List
                        height={height}
                        width={width + 20}
                        rowHeight={62}
                        rowRenderer={rowRenderer}
                        ref={list}
                        rowCount={
                          tab === 'roles' && !role ? roleCount : userCount
                        }
                        style={{ overflowX: false, overflowY: false }}
                      />
                    </div>
                  )}
                </Droppable>
              ) : (
                <div className="blocks">
                  <List
                    height={height}
                    width={width + 20}
                    rowHeight={62}
                    rowRenderer={rowRenderer}
                    ref={list}
                    rowCount={tab === 'roles' && !role ? roleCount : userCount}
                    style={{
                      overflowX: false,
                      overflowY: false,
                    }}
                  />
                </div>
              )}
            </LightScrollbar>
          )}
        </AutoSizer>
      </div>
      {/* {canEdit && !mission?.archived && (
        <Droppable droppableId={`remove-mission`}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={getClassNames({
                remove: true,
                highlight: !!draggingFrom,
              })}
            >
              <div>
                <FontAwesomeIcon icon="minus-circle" />
              </div>
              <div>Drag a user here to remove them from a mission.</div>
            </div>
          )}
        </Droppable>
      )} */}
    </>
  );
};

SelectUserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.any),
  setUser: PropTypes.func,
  canEdit: PropTypes.bool,
  isMultiple: PropTypes.bool,
  roles: PropTypes.arrayOf(orgRoleType),
  chatWithUser: PropTypes.func,
  addToMission: PropTypes.func,
  removeFromMission: PropTypes.func,
  draggingFrom: PropTypes.string,
  roleChanged: PropTypes.func,
  mission: missionType,
  closeSidebar: PropTypes.func,
  tab: PropTypes.string,
  centreMapOnPersonnel: PropTypes.func,
  role: PropTypes.any,
  setRole: PropTypes.func,
};

/**
 * User list item
 *
 * @param {any} user user
 * @param {Function} setUser callback to set user
 * @param {Boolean} canEdit is owner of this mission
 * @param {Boolean} isMultiple are we on multimission view
 * @param {Function} chatWithUser callback to chat with user
 * @param {any[]} roles roles list
 * @param {Function} addToMission callback to add user to mission
 * @param {Function} removeFromMission callback to remove user to mission
 * @param {Boolean} draggable is this component draggable
 * @param {Function} closeSidebar callback to close sidebar
 * @param {Function} centreMapOnPersonnel callback to centre map on unit
 */
const UserSection = ({
  user,
  setUser,
  canEdit,
  isMultiple,
  chatWithUser,
  roles,
  addToMission,
  removeFromMission,
  draggable,
  mission,
  closeSidebar,
  centreMapOnPersonnel,
}) => {
  const userValue = useUser();
  const comms = useComms();

  const role = user.guest
    ? { role: 'Guest' }
    : roles.find((r) => r.id === user.roleId);

  return (
    <div
      key={user.id}
      className={`item-container ${
        draggable ? 'draggable' : 'clickable'
      } has-hover`}
      style={{
        marginLeft: '0px',
        marginRight: '0px',
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
      onClick={() => {
        if (!draggable) {
          setUser(user);
        }
      }}
    >
      {canEdit && !mission?.archived && (
        <>
          {user.role !== 'Guest' && !user.guest ? (
            <DotButton
              onClick={async (e) => {
                if (user.inMission) {
                  // remove from mission
                  removeFromMission([user.id]);
                } else {
                  // add to mission
                  addToMission([user]);
                }
                e.preventDefault();
                e.stopPropogation();
              }}
              ariaLabel={
                user.inMission
                  ? `Remove From ${capitalize(secrets.EVENT_NAME)}`
                  : `Add To ${capitalize(secrets.EVENT_NAME)}`
              }
              style={{ padding: 0 }}
            >
              {!user.inMission ? (
                <img
                  src={add}
                  alt="Add"
                  style={{ height: '40px', width: '28px' }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={'minus'}
                  color={'black'}
                  style={{
                    borderRadius: '50%',
                    border: '1px solid grey',
                    backgroundColor: 'white',
                    height: '25px',
                    width: '25px',
                  }}
                  size="2x"
                />
              )}
            </DotButton>
          ) : (
            <DotButton
              onClick={async (e) => {
                if (user.inMission) {
                  // remove from mission
                  removeFromMission([user.id]);
                }
                e.preventDefault();
                e.stopPropogation();
              }}
              ariaLabel={`Remove From ${capitalize(secrets.EVENT_NAME)}`}
              style={{ padding: 0 }}
            >
              <FontAwesomeIcon
                icon={'minus'}
                color={'black'}
                style={{
                  borderRadius: '50%',
                  border: '1px solid grey',
                  backgroundColor: 'white',
                  height: '25px',
                  width: '25px',
                }}
                size="2x"
              />
            </DotButton>
          )}
        </>
      )}
      <div className="img">
        <Avatar entity={user} />
      </div>
      <div className="text">
        {user.firstname} {user.lastname}
        {!!role && (
          <div style={{ display: 'flex' }}>
            <div style={{ alignSelf: 'center', paddingRight: '0.5rem' }}>
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="diamond" size="sm" color="white" />
                <FontAwesomeIcon
                  icon="diamond"
                  color={role.color ? role.color : 'black'}
                  size={'xs'}
                />
              </span>
            </div>
            <span>{role.role}</span>
          </div>
        )}
      </div>
      {!isMultiple && (
        <>
          <DotButton
            onClick={async (e) => {
              closeSidebar();
              //To refactor - Had to do this because there is no location on orgUsers
              const userWithLocation = await getMissionPersonnel(
                mission.id,
                user.id
              );
              centreMapOnPersonnel({
                ...user,
                coords: userWithLocation.location.location.coords,
              });
              e.preventDefault();
              e.stopPropogation();
            }}
            ariaLabel={'Centre on map'}
            style={{ paddingRight: 5 }}
          >
            <FontAwesomeIcon icon={'location-dot'} />
          </DotButton>
          <div></div>
          <MenuButton
            buttonTheme="light"
            className="action"
            position="left"
            options={[
              {
                text: 'User Info',
                icon: 'info',
                ariaLabel: 'User Info',
                onClick: async () => {
                  setUser(user);
                },
              },
              !!chatWithUser && userValue.user.id !== user.id
                ? {
                    text: 'Chat with User',
                    icon: 'comment-alt',
                    ariaLabel: 'Chat with User',
                    onClick: async (e) => {
                      await chatWithUser(user);
                    },
                  }
                : null,
              !!chatWithUser &&
              userValue.user.id !== user.id &&
              user?.communicationServices
                ? {
                    text: 'Call User',
                    icon: 'phone-flip',
                    ariaLabel: 'Call User',
                    onClick: async (e) => {
                      //To refactor - Had to do this because there is no location on orgUsers
                      const userWithLocation = await getMissionPersonnel(
                        mission.id,
                        user.id
                      );
                      await comms.makePhoneCall({
                        ...user,
                        coords: userWithLocation.location.location.coords,
                      });
                      if (closeSidebar) {
                        closeSidebar();
                      }
                    },
                  }
                : null,
            ]}
            ariaLabel="Mission Menu"
          >
            <FontAwesomeIcon icon="ellipsis-vertical" />
          </MenuButton>
        </>
      )}
    </div>
  );
};

UserSection.propTypes = {
  user: userType.isRequired,
  setUser: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  isMultiple: PropTypes.bool,
  chatWithUser: PropTypes.func,
  roles: PropTypes.arrayOf(orgRoleType),
  addToMission: PropTypes.func,
  removeFromMission: PropTypes.func,
  draggable: PropTypes.bool,
  mission: missionType,
  closeSidebar: PropTypes.func,
  centreMapOnPersonnel: PropTypes.func,
};

/**
 * Role list item
 *
 * @param {any} role user
 * @param {Function} setRole callback to set role
 * @param {Boolean} canEdit is owner of this mission
 * @param {Boolean} isMultiple are we on multimission view
 * @param {Function} addToMission callback to add role to mission
 * @param {Function} removeFromMission callback to remove role to mission
 * @param {Boolean} draggable is this component draggable
 */
const RoleSection = ({
  role,
  setRole,
  canEdit,
  isMultiple,
  addToMission,
  removeFromMission,
  draggable,
  mission,
}) => {
  return (
    <div
      key={role.id}
      className={`item-container ${
        draggable ? 'draggable' : 'clickable'
      } has-hover`}
      style={{
        marginLeft: '0px',
        marginRight: '0px',
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
      onClick={() => {
        if (!draggable && setRole) {
          setRole(role);
        }
      }}
    >
      {canEdit && !mission?.archived && (
        <>
          {role.id !== 'unassigned' && role.id !== 'guest' ? (
            <DotButton
              onClick={async (e) => {
                if (role.inMission) {
                  // remove from mission
                  removeFromMission([role.id]);
                } else {
                  // add to mission
                  addToMission([role]);
                }
                e.preventDefault();
                e.stopPropogation();
              }}
              ariaLabel={
                role.inMission
                  ? `Remove From ${capitalize(secrets.EVENT_NAME)}`
                  : `Add To ${capitalize(secrets.EVENT_NAME)}`
              }
              style={{ padding: 0 }}
            >
              {!role.inMission ? (
                <img
                  src={add}
                  alt={'Add'}
                  style={{ height: '40px', width: '28px' }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={'minus'}
                  color={'black'}
                  size="2x"
                  style={{
                    borderRadius: '50%',
                    border: '1px solid grey',
                    backgroundColor: 'white',
                    height: '25px',
                    width: '25px',
                  }}
                />
              )}
            </DotButton>
          ) : (
            <DotButton
              onClick={async (e) => {
                if (role.inMission) {
                  // remove from mission
                  removeFromMission([role.id]);
                }
                e.preventDefault();
                e.stopPropogation();
              }}
              ariaLabel={`Remove From ${capitalize(secrets.EVENT_NAME)}`}
              style={{ padding: 0 }}
            >
              <FontAwesomeIcon
                icon={'minus-circle'}
                color={'var(--colour-negative-status-a)'}
                size="2x"
              />
            </DotButton>
          )}
        </>
      )}
      <div className="img">
        <Avatar entity={role} />
      </div>
      <div className="text">
        {role.role}
        {!!role.users && (
          <span>
            {`${role.usersInMission} / ${pluralize('user', role.users, true)} in
            ${secrets.EVENT_NAME}`}
          </span>
        )}
      </div>
      <DotButton
        ariaLabel="Select Role"
        onClick={async () => {
          if (setRole) {
            setRole(role);
          }
        }}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </DotButton>
    </div>
  );
};

RoleSection.propTypes = {
  role: orgRoleType.isRequired,
  setRole: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  isMultiple: PropTypes.bool,
  addToMission: PropTypes.func,
  removeFromMission: PropTypes.func,
  draggable: PropTypes.bool,
  mission: missionType,
};
