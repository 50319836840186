/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, { useEffect, useRef, useState } from 'react';
import { useParams, withRouter } from 'react-router';
import { historyType } from '../../../enums/propTypes';
import { NavBar } from '../../common/Navbar';
import { MissionMenu } from '../../common/menus/MissionMenu';
import { MissionInfo } from './MissionInfo';
import badge from '../../../assets/svg/dot-badge.svg';
import { getMission } from '../../../api/missions';
import { cancellablePromise } from '../../../utils/promise';
import { CallState } from '../../../enums/call';
import { useComms } from '../../../context/CommsContext';
import { getClassNames } from '../../../utils/string';
import { sortBy } from '../../../utils/array';
import formsSvg from '../../../assets/svg/formsIcon.svg';
import tasksSvg from '../../../assets/svg/tasksIcon.svg';
//import auditSvg from '../../../assets/svg/auditIcon.svg';
import { DotButton } from '../../common/buttons/DotButton';
import { Sidebar } from '../../common/Sidebar';
import { SIDEBARS, useSidebar } from '../../../context/SidebarContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { FormHistory } from '../../sidebars/tasks/FormHistory';
import { LightScrollbar } from '../../common/Scrollbars';
import { CheckBox } from '../../common/CheckBox';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { SearchInput } from '../../common/Search';
import { FormType } from '../../../enums/forms';
import {
  getForms,
  updateFormState,
  updateFormStatus,
} from '../../../api/forms';
import { useUser } from '../../../context/UserContext';
import { getUsersInMission } from '../../../api/missions';
import { deleteForm } from '../../../api/forms';
import { faMultiply } from '@fortawesome/free-solid-svg-icons';
import { Avatar } from '../../common/Avatar';
import { SitrepPreview } from '../../forms/SitrepPreview';
import { getOrders } from '../../../api/orders';
import { CreateEditTaskSidebar } from '../../sidebars/CreateEditTask';
import { saveTask } from '../../../api/orders';
import toast from 'react-hot-toast';
import { handleError } from '../../../utils/error';

/**
 * Mission Forms page
 *
 * @param {any} history react router
 * @param {func} setManageForms set manage forms function
 * @param {func} handleEditForm handle edit form function
 */
export const Forms = withRouter(
  ({ history, setManageForms, handleEditForm }) => {
    const { missionId } = useParams();
    const comms = useComms();
    const sidebars = useSidebar();
    const scroll = useRef();
    const user = useUser();

    const [mission, setMission] = useState();
    const [users, setUsers] = useState();
    const [loading, setLoading] = useState(true);
    //const [showHistory, setShowHistory] = useState(null);
    const [activeTab, setActiveTab] = useState('all');
    const [activeSort, setActiveSort] = useState();
    const [forms, setForms] = useState([]);
    const [userForms, setUserForms] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [search, setSearch] = useState('');
    const [editTask, setEditTask] = useState({});
    const [availableSections, setAvailableSections] = useState([]);

    useEffect(() => {
      const stub = async () => {
        const savedMission = await getMission(missionId);
        setMission(savedMission);
        const usersForms = await getForms(missionId);
        setUserForms(usersForms);
        setForms(usersForms);
        const _users = await getUsersInMission(missionId);
        setUsers(_users);
        const _orders = await getOrders(missionId);
        const _availableSections = [];

        for (const section of _orders.sections) {
          _availableSections.push({
            id: section.id,
            name: section.name,
          });
        }
        setAvailableSections(_availableSections);
        setLoading(false);
      };

      const { promise, cancel } = cancellablePromise(stub());
      promise.then(() => {}).catch((e) => {});
      return cancel;
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (activeTab === 'all') {
        if (activeSort) {
          const formsCopy = [...userForms];
          const sortedForms = sortBy(
            formsCopy,
            [activeSort.split('-')[0]],
            [activeSort.split('-')[1]]
          );
          setForms(sortedForms);
        } else {
          setForms(userForms);
        }
      }

      if (activeTab === 'open') {
        const filteredForms = userForms.filter(
          (i) =>
            i.state === activeTab ||
            i.state === 'acknowledged' ||
            i.state === 'actioned'
        );
        if (activeSort) {
          const formsCopy = [...filteredForms];
          const sortedForms = sortBy(
            formsCopy,
            [activeSort.split('-')[0]],
            [activeSort.split('-')[1]]
          );
          setForms(sortedForms);
        } else {
          setForms(filteredForms);
        }
      }

      if (activeTab === 'completed') {
        const filteredForms = userForms.filter(
          (i) => i.state === activeTab || i.state === 'confirmed'
        );
        if (activeSort) {
          const formsCopy = [...filteredForms];
          const sortedForms = sortBy(
            formsCopy,
            [activeSort.split('-')[0]],
            [activeSort.split('-')[1]]
          );
          setForms(sortedForms);
        } else {
          setForms(filteredForms);
        }
      }

      if (activeTab === 'archived') {
        const filteredForms = userForms.filter((i) => i.state === 'closed');
        if (activeSort) {
          const formsCopy = [...filteredForms];
          const sortedForms = sortBy(
            formsCopy,
            [activeSort.split('-')[0]],
            [activeSort.split('-')[1]]
          );
          setForms(sortedForms);
        } else {
          setForms(filteredForms);
        }
      }
      // eslint-disable-next-line
    }, [activeTab, userForms]);

    useEffect(() => {
      if (activeSort) {
        const formsCopy = [...forms];
        const sortedForms = sortBy(
          formsCopy,
          [activeSort.split('-')[0]],
          [activeSort.split('-')[1]]
        );
        setForms(sortedForms);
      }
      // eslint-disable-next-line
    }, [activeSort, forms]);

    const handleSelectAll = (e) => {
      setSelectAll(!selectAll);
      setIsCheck(forms.map((i) => i.id));
      if (selectAll) {
        setIsCheck([]);
      }
    };

    const handleCheck = (e) => {
      const { id, checked } = e.target;
      setIsCheck([...isCheck, id]);
      if (!checked) {
        setIsCheck(isCheck.filter((i) => i !== id));
      }
    };

    const handleDeleteForm = async (e, id) => {
      await deleteForm(missionId, id);
      const usersForms = await getForms(missionId);
      setUserForms(usersForms);
      setForms(usersForms);
    };

    const handleChangeFormState = async (formId, state) => {
      const _form = userForms.find((f) => f.id === formId);
      const isConfirmed = _form.state === 'confirmed';
      const isClosed = _form.state === 'closed';
      if (!isConfirmed && !isClosed) {
        if (
          state === 'acknowledged' ||
          state === 'actioned' ||
          state === 'completed'
        ) {
          const isRecipient = _form.to.find((t) => t.id === user.user.id);
          if (!!isRecipient) {
            await updateFormState(missionId, formId, state);
          }
        }
        if (state === 'confirmed' || state === 'closed') {
          const isSender = _form.from === user.user.id;
          if (isSender) {
            await updateFormState(missionId, formId, state);
          }
        }
      }
      if (isConfirmed) {
        if (state === 'closed') {
          await updateFormState(missionId, formId, state);
        }
      }
      const usersForms = await getForms(missionId);
      setUserForms(usersForms);
      setForms(usersForms);
    };

    const handleChangeFormStatus = async (formId) => {
      await updateFormStatus(missionId, formId, {
        id: user.user.id,
        type: 'user',
        status: 'read',
      });
      const usersForms = await getForms(missionId);
      setUserForms(usersForms);
      setForms(usersForms);
    };

    return (
      <div className="page">
        <NavBar history={history}>
          <MissionMenu
            history={history}
            missionId={missionId}
            active="map"
            published={mission?.published}
            archived={mission?.archived}
          ></MissionMenu>
        </NavBar>
        {loading ? (
          <div
            className="loading-panel"
            style={{
              position: 'absolute',
              zIndex: '3',
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{ animation: 'rotation 2s infinite linear' }}>
              <img
                src={badge}
                alt="AUSTAC Logo"
                style={{ height: '50px', width: '50px' }}
              />
            </div>
            <div style={{ fontWeight: 600, fontSize: 20 }}>Loading forms</div>
          </div>
        ) : (
          <>
            <div
              className={getClassNames({
                topnav: true,
                'call-banner': comms.callState !== CallState.Disconnected,
              })}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {mission && (
                  <div className="mission-info-container">
                    <MissionInfo
                      missions={[mission]}
                      history={history}
                      enableEditMenu={false}
                    />
                  </div>
                )}
                <div
                  className={getClassNames({
                    active: true,
                    'menu-button': true,
                    'nav-mission': true,
                  })}
                >
                  <img src={formsSvg} alt="forms" />

                  <DotButton className="link">
                    Forms (
                    {
                      userForms.filter(
                        (f) =>
                          f.serial !== 'Draft' &&
                          f.to.find((t) => t.id === user.user.id).status ===
                            'unread'
                      ).length
                    }
                    )
                  </DotButton>
                </div>
              </div>
              <div className="nav-search">
                <SearchInput
                  placeholder="Search forms..."
                  onChange={(e) => setSearch(e.target.value)}
                  light={true}
                />
              </div>
              <div className="menu-button menu-history-button sub-header">
                {/* Empty div with width for spacing because no history button*/}
                <div style={{ width: '350px' }}></div>
              </div>
              <button
                style={{
                  background: 'none',
                  border: 'none',
                  padding: '50px',
                  color: 'grey',
                }}
                onClick={(e) => setManageForms(false)}
              >
                <FontAwesomeIcon icon={faMultiply} size={'2x'} />
              </button>
            </div>
            <div className="content">
              <LightScrollbar ref={scroll}>
                <div className="container">
                  <div className="header">
                    <div className="task-bar">
                      <span
                        className={getClassNames({
                          active: activeTab === 'all',
                          'task-menu-button': true,
                        })}
                      >
                        <DotButton
                          className="task-link"
                          onClick={() => {
                            setActiveTab('all');
                          }}
                        >
                          All Forms
                        </DotButton>
                      </span>
                      <span
                        className={getClassNames({
                          active: activeTab === 'open',
                          'task-menu-button': true,
                        })}
                      >
                        <DotButton
                          className="task-link"
                          onClick={() => {
                            setActiveTab('open');
                          }}
                        >
                          Open Forms
                        </DotButton>
                      </span>
                      <span
                        className={getClassNames({
                          active: activeTab === 'completed',
                          'task-menu-button': true,
                        })}
                      >
                        <DotButton
                          className="task-link"
                          onClick={() => {
                            setActiveTab('completed');
                          }}
                        >
                          Completed Forms
                        </DotButton>
                      </span>
                      <span
                        className={getClassNames({
                          active: activeTab === 'archived',
                          'task-menu-button': true,
                        })}
                      >
                        <DotButton
                          className="task-link"
                          onClick={() => {
                            setActiveTab('archived');
                          }}
                        >
                          Archived Forms
                        </DotButton>
                      </span>
                    </div>
                  </div>
                  <div className="sort-bar">
                    <div className="col-1">
                      <CheckBox
                        id={'selectAll'}
                        checked={selectAll}
                        value={'selectAll'}
                        onChange={handleSelectAll}
                      />
                    </div>
                    <div className="col-2">
                      <div
                        className={getClassNames({
                          inner: true,
                          active:
                            activeSort === 'serial-asc' ||
                            activeSort === 'serial-desc',
                        })}
                      >
                        <div className="text">SERIAL</div>
                        <div
                          className="chevrons"
                          onClick={() => {
                            if (activeSort === 'serial-asc') {
                              setActiveSort('serial-desc');
                            } else {
                              setActiveSort('serial-asc');
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="chevron-up" size="xs" />
                          <FontAwesomeIcon icon="chevron-down" size="xs" />
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div
                        className={getClassNames({
                          inner: true,
                          active:
                            activeSort === 'type-asc' ||
                            activeSort === 'type-desc',
                        })}
                      >
                        <div className="text">FORM TYPE</div>
                        <div
                          className="chevrons"
                          onClick={() => {
                            if (activeSort === 'type-asc') {
                              setActiveSort('type-desc');
                            } else {
                              setActiveSort('type-asc');
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="chevron-up" size="xs" />
                          <FontAwesomeIcon icon="chevron-down" size="xs" />
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div
                        className={getClassNames({
                          inner: true,
                          active:
                            activeSort === 'from-asc' ||
                            activeSort === 'from-desc',
                        })}
                      >
                        <div className="text">FROM</div>
                        <div
                          className="chevrons"
                          onClick={() => {
                            if (activeSort === 'from-asc') {
                              setActiveSort('from-desc');
                            } else {
                              setActiveSort('from-asc');
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="chevron-up" size="xs" />
                          <FontAwesomeIcon icon="chevron-down" size="xs" />
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div
                        className={getClassNames({
                          inner: true,
                          active:
                            activeSort === 'to-asc' || activeSort === 'to-desc',
                        })}
                      >
                        <div className="text">TO</div>
                        <div
                          className="chevrons"
                          onClick={() => {
                            if (activeSort === 'to-asc') {
                              setActiveSort('to-desc');
                            } else {
                              setActiveSort('to-asc');
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="chevron-up" size="xs" />
                          <FontAwesomeIcon icon="chevron-down" size="xs" />
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div
                        className={getClassNames({
                          inner: true,
                          active:
                            activeSort === '_ts-asc' ||
                            activeSort === '_ts-desc',
                        })}
                      >
                        <div className="text">RECEIVED</div>
                        <div
                          className="chevrons"
                          onClick={() => {
                            if (activeSort === '_ts-asc') {
                              setActiveSort('_ts-desc');
                            } else {
                              setActiveSort('_ts-asc');
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="chevron-up" size="xs" />
                          <FontAwesomeIcon icon="chevron-down" size="xs" />
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div
                        className={getClassNames({
                          inner: true,
                          active:
                            activeSort === 'status-asc' ||
                            activeSort === 'status-desc',
                        })}
                      >
                        <div className="text">STATUS</div>
                        <div
                          className="chevrons"
                          onClick={() => {
                            if (activeSort === 'status-asc') {
                              setActiveSort('status-desc');
                            } else {
                              setActiveSort('status-asc');
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="chevron-up" size="xs" />
                          <FontAwesomeIcon icon="chevron-down" size="xs" />
                        </div>
                      </div>
                    </div>
                    <div className="col-3"></div>
                  </div>
                  <FormSection
                    user={user}
                    forms={forms}
                    users={users}
                    isCheck={isCheck}
                    handleCheck={handleCheck}
                    handleDeleteForm={handleDeleteForm}
                    handleEditForm={handleEditForm}
                    handleChangeFormState={handleChangeFormState}
                    handleChangeFormStatus={handleChangeFormStatus}
                  />
                </div>
              </LightScrollbar>
              {isCheck.length > 1 && (
                <div className="multi-select">
                  <div className="col-1">
                    <CheckBox
                      id={'multi-select'}
                      value={'multi-select'}
                      readOnly={true}
                      checked={true}
                      onChange={() => {}}
                    />
                  </div>
                  <div className="col-2">
                    {isCheck.length} &nbsp; FORMS SELECTED:
                  </div>
                  <div className="col-2">
                    <div
                      className="inner"
                      onClick={async () => {
                        for (const i of isCheck) {
                          handleChangeFormState(i, 'acknowledged');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="flag" />
                      &nbsp;&nbsp; Mark as Acknowledged
                    </div>
                  </div>
                  <div className="col-2">
                    <div
                      className="inner"
                      onClick={async () => {
                        for (const i of isCheck) {
                          handleChangeFormState(i, 'actioned');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="play" />
                      &nbsp;&nbsp; Mark as Actioned
                    </div>
                  </div>
                  <div className="col-2">
                    <div
                      className="inner"
                      onClick={async () => {
                        for (const i of isCheck) {
                          handleChangeFormState(i, 'completed');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="check" />
                      &nbsp;&nbsp; Mark as Completed
                    </div>
                  </div>
                  <div className="col-2">
                    <div
                      className="inner"
                      onClick={async () => {
                        for (const i of isCheck) {
                          handleChangeFormState(i, 'confirmed');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="check-double" />
                      &nbsp;&nbsp; Mark as Confirmed
                    </div>
                  </div>
                  <div className="col-2">
                    <div
                      className="inner"
                      onClick={async () => {
                        for (const i of isCheck) {
                          handleChangeFormState(i, 'closed');
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="ban" />
                      &nbsp;&nbsp; Mark as Closed
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {/* <Sidebar id={SIDEBARS.history}>
        <FormHistory
          closeSidebar={() => {
            setShowHistory(null);
            sidebars.close(SIDEBARS.history);
          }}
          missionId={missionId}
          showHistory={showHistory}
          setShowHistory={(item) => {}}
          currentForms={null}
          setFormsToRestore={null}
          canEdit={null}
        />
      </Sidebar> */}
        {editTask && !!availableSections?.length && (
          <Sidebar id={SIDEBARS.createEditTask}>
            <CreateEditTaskSidebar
              missionId={missionId}
              task={editTask}
              availableSections={availableSections}
              setTask={async (t, _sectionId) => {
                if (t) {
                  await saveTask(missionId, _sectionId, t).catch(handleError);
                  toast.success('Task saved!');
                  sidebars.closeAll();
                }
                setEditTask({});
              }}
              closeSidebar={() => {
                setEditTask({});
                sidebars.close(SIDEBARS.createEditTask);
              }}
            />
          </Sidebar>
        )}
      </div>
    );
  }
);

Forms.propTypes = {
  history: historyType,
  setManageForms: PropTypes.func,
  handleEditForm: PropTypes.func,
};

/**
 * Form section
 *
 * @param {any} user user
 * @param {any} forms forms
 * @param {any} users users
 * @param {any} isCheck is checkbox checked array
 * @param {func} handleCheck handle check function
 * @param {func} handleDeleteForm handle delete form function
 * @param {func} handleEditForm handle edit form function
 * @param {func} handleChangeFormState handle change form state function
 * @param {func} handleChangeFormStatus handle change form status function
 */
const FormSection = ({
  user,
  forms,
  users,
  isCheck,
  handleCheck,
  handleDeleteForm,
  handleEditForm,
  handleChangeFormState,
  handleChangeFormStatus,
}) => {
  const btnRef = useRef();
  const [showDropdown, setShowDropdown] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState([]);
  const sidebars = useSidebar();

  useEffect(() => {
    const closeDropdown = (e) => {
      const path = e.composedPath ? e.composedPath() : e.path;
      if (path[0] !== btnRef.current) {
        setShowDropdown([]);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, []);

  const handleDropdown = (id) => {
    setShowDropdown([id]);
    if (showDropdown.includes(id)) {
      setShowDropdown([]);
    }
  };

  const handleViewDetails = (id) => {
    const formState = forms.find((f) => f.id === id).state;
    if (formState !== 'draft') {
      handleChangeFormStatus(id, 'read');
    }
    setDetailsOpen([...detailsOpen, id]);
    if (detailsOpen.includes(id)) {
      setDetailsOpen(detailsOpen.filter((i) => i !== id));
    }
  };

  const handleScrollToTop = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView();
  };

  const getFormTypeAcronym = (type) => {
    let acronym;
    switch (type.toUpperCase()) {
      case FormType.CT:
        acronym = 'CT';
        break;
      case FormType.CV:
        acronym = 'CV';
        break;
      case FormType.SR:
        acronym = 'SR';
        break;
      case FormType.MR:
        acronym = 'MR';
        break;
      case FormType.OD:
        acronym = 'OD';
        break;
      default:
        break;
    }
    return acronym;
  };

  return (
    <>
      {!!forms && !!forms.filter((f) => f.type === FormType.CT).length && (
        <div className="divider" />
      )}
      {!!forms &&
        forms
          .filter((f) => f.type === FormType.CT)
          .map((i) => {
            return (
              <div key={i.id}>
                <div
                  className={getClassNames({
                    'important-form-row': true,
                    selected: isCheck.includes(i.id),
                  })}
                >
                  <div className="col-1">
                    <CheckBox
                      id={i.id}
                      checked={isCheck.includes(i.id)}
                      value={i.id}
                      onChange={handleCheck}
                    />
                  </div>
                  <div className="col-2">{i.serial?.toUpperCase()}</div>
                  <div
                    className="col-2"
                    style={{ justifyContent: 'flex-start' }}
                  >
                    &nbsp;
                    <div className="acronym-container">
                      {getFormTypeAcronym(i.type)}
                    </div>
                    &nbsp; &nbsp;
                    <div>{i.type.toUppercase()}</div>
                  </div>
                  <div className="col-2">
                    <div className="avatar-container">
                      <Avatar
                        entity={users.find((u) => i.from === u.id)}
                        size="1.5rem"
                      ></Avatar>
                      {users.find((u) => i.from === u.id).firstname +
                        ' ' +
                        users.find((u) => i.from === u.id).lastname}
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {!!i.to && i.to.length > 1 && (
                      <>{i?.to?.length + ' Recipients'}</>
                    )}
                    {!!i.to && i.to.length === 1 && (
                      <>{i?.to?.length + ' Recipient'}</>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '5px',
                      }}
                    >
                      {!!i.to && i.to.length >= 1
                        ? i.to.map((t) => {
                            const entity = users.find((u) => u.id === t.id);
                            return (
                              <div className="personnel-tag">
                                <div className="avatar-container">
                                  <Avatar
                                    entity={entity}
                                    size="1.5rem"
                                  ></Avatar>
                                </div>
                              </div>
                            );
                          })
                        : '-'}
                    </div>
                  </div>
                  <div className="col-2">
                    {moment.unix(i._ts).format('HH:mm A, DD MMM YYYY')}
                  </div>
                  <div className="col-2">
                    {!!i.to.length && i.serial !== 'Draft'
                      ? i.state === 'open'
                        ? i.to
                            ?.find((t) => t.id === user.user.id)
                            ?.status[0].toUpperCase() +
                          i.to
                            ?.find((t) => t.id === user.user.id)
                            ?.status.slice(1)
                        : i.state[0].toUpperCase() + i.state.slice(1)
                      : 'Draft'}
                  </div>
                  <div className="col-3">
                    <DotButton
                      innerRef={btnRef}
                      onClick={() => handleDropdown(i.id)}
                    >
                      <FontAwesomeIcon icon="bars" />
                    </DotButton>
                    {showDropdown.includes(i.id) && (
                      <div
                        className={
                          'dropdown ' + (showDropdown ? 'open' : 'closed')
                        }
                      >
                        <button
                          className={'item-container'}
                          onClick={async (e) => {
                            e.preventDefault();
                            handleViewDetails(i.id);
                          }}
                        >
                          <div className={'item-icon'}>
                            <FontAwesomeIcon icon={'search'} />
                          </div>
                          Form Details
                        </button>
                        {i.serial !== 'Draft' && (
                          <>
                            {!!i.to.find((t) => t.id === user.user.id) &&
                              i.state !== 'confirmed' &&
                              i.state !== 'closed' && (
                                <>
                                  {i.state !== 'acknowledged' && (
                                    <button
                                      className={'item-container'}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        handleChangeFormState(
                                          i.id,
                                          'acknowledged'
                                        );
                                      }}
                                    >
                                      <div className={'item-icon'}>
                                        <FontAwesomeIcon icon={'flag'} />
                                      </div>
                                      Mark as Acknowledged
                                    </button>
                                  )}
                                  {i.state !== 'actioned' && (
                                    <button
                                      className={'item-container'}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        handleChangeFormState(i.id, 'actioned');
                                      }}
                                    >
                                      <div className={'item-icon'}>
                                        <FontAwesomeIcon icon={'play'} />
                                      </div>
                                      Mark as Actioned
                                    </button>
                                  )}
                                  {i.state !== 'completed' && (
                                    <button
                                      className={'item-container'}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        handleChangeFormState(
                                          i.id,
                                          'completed'
                                        );
                                      }}
                                    >
                                      <div className={'item-icon'}>
                                        <FontAwesomeIcon icon={'check'} />
                                      </div>
                                      Mark as Completed
                                    </button>
                                  )}
                                </>
                              )}
                            {i.from === user.user.id &&
                              i.state !== 'confirmed' &&
                              i.state === 'completed' && (
                                <button
                                  className={'item-container'}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    handleChangeFormState(i.id, 'confirmed');
                                  }}
                                >
                                  <div className={'item-icon'}>
                                    <FontAwesomeIcon icon={'check-double'} />
                                  </div>
                                  Mark as Confirmed
                                </button>
                              )}
                            {i.state !== 'confirmed' &&
                              i.state !== 'closed' && (
                                <button
                                  className={'item-container'}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    sidebars.open(SIDEBARS.createEditTask);
                                  }}
                                  disabled={true}
                                >
                                  <div className={'item-icon'}>
                                    <img src={tasksSvg} alt="task" />
                                  </div>
                                  Create Task
                                </button>
                              )}

                            {i.from === user.user.id &&
                              i.state === 'confirmed' && (
                                <button
                                  className={'item-container'}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    handleChangeFormState(i.id, 'closed');
                                  }}
                                >
                                  <div className={'item-icon'}>
                                    <FontAwesomeIcon icon={'ban'} />
                                  </div>
                                  Mark as Closed
                                </button>
                              )}
                            {/* <button
                          className={'item-container'}
                          onClick={async (e) => {
                            e.preventDefault();
                          }}
                        >
                          <div className={'item-icon'}>
                            <img src={auditSvg} />
                          </div>
                          Audit Form
                        </button> */}
                          </>
                        )}
                        {i.serial === 'Draft' && (
                          <button
                            className={'item-container'}
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditForm(e, i);
                            }}
                          >
                            <div className={'item-icon'}>
                              <FontAwesomeIcon icon={'pencil'} />
                            </div>
                            Edit Form
                          </button>
                        )}
                        {i.from === user.user.id && (
                          <button
                            className={'item-container'}
                            onClick={async (e) => {
                              e.preventDefault();
                              await handleDeleteForm(e, i.id);
                            }}
                          >
                            <div className={'item-icon'}>
                              <FontAwesomeIcon icon={'xmark'} />
                            </div>
                            Delete Form
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {detailsOpen.includes(i.id) && i.type === 'sitrep' && (
                  <SitrepPreview
                    form={i}
                    handleViewDetails={handleViewDetails}
                    handleScrollToTop={handleScrollToTop}
                    users={users}
                  />
                )}
              </div>
            );
          })}
      {!!forms && !!forms.filter((f) => f.type === FormType.CT).length && (
        <div className="divider" />
      )}
      {!!forms &&
        forms
          .filter((f) => f.type !== FormType.CT)
          .map((i) => {
            return (
              <div key={i.id}>
                <div
                  className={getClassNames({
                    'form-row': true,
                    unread:
                      i.to?.find((t) => t.id === user.user.id)?.status ===
                        'unread' && i.serial !== 'Draft',
                    selected: isCheck.includes(i.id),
                  })}
                >
                  <div className="col-1">
                    <CheckBox
                      id={i.id}
                      checked={isCheck.includes(i.id)}
                      value={i.id}
                      onChange={handleCheck}
                    />
                  </div>
                  <div className="col-2">{i.serial?.toUpperCase()}</div>
                  <div
                    className="col-2"
                    style={{ justifyContent: 'flex-start' }}
                  >
                    &nbsp;
                    <div className="acronym-container">
                      {getFormTypeAcronym(i.type)}
                    </div>
                    &nbsp; &nbsp;
                    <div>{i.type.toUpperCase()}</div>
                  </div>
                  <div className="col-2">
                    <div
                      className="avatar-container"
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Avatar
                        entity={users.find((u) => i.from === u.id)}
                        size="1.5rem"
                      ></Avatar>
                      {users.find((u) => i.from === u.id).firstname +
                        ' ' +
                        users.find((u) => i.from === u.id).lastname}
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {!!i.to && i.to.length > 1 && (
                      <>{i?.to?.length + ' Recipients'}</>
                    )}
                    {!!i.to && i.to.length === 1 && (
                      <>{i?.to?.length + ' Recipient'}</>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '5px',
                      }}
                    >
                      {!!i.to && i.to.length >= 1
                        ? i.to.map((t) => {
                            const entity = users.find((u) => u.id === t.id);
                            return (
                              <div className="personnel-tag">
                                <div className="avatar-container">
                                  <Avatar
                                    entity={entity}
                                    size="1.5rem"
                                  ></Avatar>
                                </div>
                              </div>
                            );
                          })
                        : '-'}
                    </div>
                  </div>
                  <div className="col-2">
                    {moment.unix(i._ts).format('HH:mm A, DD MMM YYYY')}
                  </div>
                  <div className="col-2">
                    {!!i.to.length && i.serial !== 'Draft'
                      ? i.state === 'open'
                        ? i.to
                            ?.find((t) => t.id === user.user.id)
                            ?.status[0].toUpperCase() +
                          i.to
                            ?.find((t) => t.id === user.user.id)
                            ?.status.slice(1)
                        : i.state[0].toUpperCase() + i.state.slice(1)
                      : 'Draft'}
                  </div>
                  <div className="col-3">
                    <DotButton
                      innerRef={btnRef}
                      onClick={() => handleDropdown(i.id)}
                    >
                      <FontAwesomeIcon icon="bars" />
                    </DotButton>
                    {showDropdown.includes(i.id) && (
                      <div
                        className={
                          'dropdown ' + (showDropdown ? 'open' : 'closed')
                        }
                      >
                        <button
                          className={'item-container'}
                          onClick={async (e) => {
                            e.preventDefault();
                            handleViewDetails(i.id);
                          }}
                        >
                          <div className={'item-icon'}>
                            <FontAwesomeIcon icon={'search'} />
                          </div>
                          Form Details
                        </button>
                        {i.serial !== 'Draft' && (
                          <>
                            {!!i.to.find((t) => t.id === user.user.id) &&
                              i.state !== 'confirmed' &&
                              i.state !== 'closed' && (
                                <>
                                  {i.state !== 'acknowledged' && (
                                    <button
                                      className={'item-container'}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        handleChangeFormState(
                                          i.id,
                                          'acknowledged'
                                        );
                                      }}
                                    >
                                      <div className={'item-icon'}>
                                        <FontAwesomeIcon icon={'flag'} />
                                      </div>
                                      Mark as Acknowledged
                                    </button>
                                  )}
                                  {i.state !== 'actioned' && (
                                    <button
                                      className={'item-container'}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        handleChangeFormState(i.id, 'actioned');
                                      }}
                                    >
                                      <div className={'item-icon'}>
                                        <FontAwesomeIcon icon={'play'} />
                                      </div>
                                      Mark as Actioned
                                    </button>
                                  )}
                                  {i.state !== 'completed' && (
                                    <button
                                      className={'item-container'}
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        handleChangeFormState(
                                          i.id,
                                          'completed'
                                        );
                                      }}
                                    >
                                      <div className={'item-icon'}>
                                        <FontAwesomeIcon icon={'check'} />
                                      </div>
                                      Mark as Completed
                                    </button>
                                  )}
                                </>
                              )}
                            {i.from === user.user.id &&
                              i.state !== 'confirmed' &&
                              i.state === 'completed' && (
                                <button
                                  className={'item-container'}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    handleChangeFormState(i.id, 'confirmed');
                                  }}
                                >
                                  <div className={'item-icon'}>
                                    <FontAwesomeIcon icon={'check-double'} />
                                  </div>
                                  Mark as Confirmed
                                </button>
                              )}
                            {i.state !== 'confirmed' &&
                              i.state !== 'closed' && (
                                <button
                                  className={'item-container'}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    sidebars.open(SIDEBARS.createEditTask);
                                  }}
                                >
                                  <div className={'item-icon'}>
                                    <img src={tasksSvg} alt="task" />
                                  </div>
                                  Create Task
                                </button>
                              )}

                            {i.from === user.user.id &&
                              i.state === 'confirmed' && (
                                <button
                                  className={'item-container'}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    handleChangeFormState(i.id, 'closed');
                                  }}
                                >
                                  <div className={'item-icon'}>
                                    <FontAwesomeIcon icon={'ban'} />
                                  </div>
                                  Mark as Closed
                                </button>
                              )}
                            {/* <button
                              className={'item-container'}
                              onClick={async (e) => {
                                e.preventDefault();
                              }}
                            >
                              <div className={'item-icon'}>
                                <img src={auditSvg} />
                              </div>
                              Audit Form
                            </button> */}
                          </>
                        )}
                        {i.serial === 'Draft' && (
                          <button
                            className={'item-container'}
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditForm(e, i);
                            }}
                          >
                            <div className={'item-icon'}>
                              <FontAwesomeIcon icon={'pencil'} />
                            </div>
                            Edit Form
                          </button>
                        )}
                        {i.from === user.user.id && (
                          <button
                            className={'item-container'}
                            onClick={async (e) => {
                              e.preventDefault();
                              await handleDeleteForm(e, i.id);
                            }}
                          >
                            <div className={'item-icon'}>
                              <FontAwesomeIcon icon={'xmark'} />
                            </div>
                            Delete Form
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {detailsOpen.includes(i.id) && i.type === 'sitrep' && (
                  <SitrepPreview
                    form={i}
                    handleViewDetails={handleViewDetails}
                    handleScrollToTop={handleScrollToTop}
                    users={users}
                  />
                )}
              </div>
            );
          })}
    </>
  );
};

FormSection.propTypes = {
  user: PropTypes.any,
  forms: PropTypes.any,
  users: PropTypes.any,
  isCheck: PropTypes.any,
  handleCheck: PropTypes.func,
  handleDeleteForm: PropTypes.func,
  handleEditForm: PropTypes.func,
  handleChangeFormState: PropTypes.func,
  handleChangeFormStatus: PropTypes.func,
};
