/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

export const addEventHandler = (ref, el, event, listener) => {
  const key = event;

  removeEventHandler(ref, el, event);

  if (el) {
    ref[key].push(listener);

    el.addEventListener(event, listener);
  }
};

export const removeEventHandler = (ref, el, event) => {
  const key = event;

  if (!ref[key]) {
    ref[key] = [];
  }

  if (el) {
    for (const l of ref[key]) {
      el.removeEventListener(event, l);
    }

    ref[key] = [];
  }
};

export const onEventHandler = (ref, el, event, listener, secondaryEvent) => {
  const key = secondaryEvent ? `${event}:${secondaryEvent}` : event;

  offEventHandler(ref, el, event, secondaryEvent);

  if (el) {
    ref[key].push(listener);

    if (secondaryEvent) {
      el.on(event, secondaryEvent, listener);
    } else {
      el.on(event, listener);
    }
  }
};

export const offEventHandler = (ref, el, event, secondaryEvent) => {
  const key = secondaryEvent ? `${event}:${secondaryEvent}` : event;

  if (!ref[key]) {
    ref[key] = [];
  }

  if (el) {
    for (const l of ref[key]) {
      if (secondaryEvent) {
        el.off(event, secondaryEvent, l);
      } else {
        el.off(event, l);
      }
    }

    ref[key] = [];
  }
};
