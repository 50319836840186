/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { getClassNames } from '../../utils/string';
import './CheckBox.scss';
import { PropTypes } from 'prop-types';

/**
 * Check Box
 *
 * @param {string} id id
 * @param {string} value check box value
 * @param {Boolean} checked checked?
 * @param {EventHandler} onChange on check changed
 * @param {string} className additional class name
 */
export const CheckBox = ({ id, value, checked, onChange, className }) => {
  const _className = getClassNames(
    {
      'dot-checkbox': true,
    },
    className
  );

  return (
    <label className={_className}>
      <input
        id={id}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="checkmark"></span>
    </label>
  );
};

CheckBox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
