/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// import the library
import { library } from '@fortawesome/fontawesome-svg-core';
// import your icons
import {
  faAngleDown,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBell,
  faBicycle,
  faBold,
  faBolt,
  faCalendar,
  faCalendarAlt,
  faCamera,
  faCar,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClipboardList,
  faClock,
  faClone,
  faCloudSun,
  faCode,
  faCog,
  faCommentAlt,
  faCompass,
  faCompressAlt,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEnvelopeSquare,
  faExclamation,
  faExclamationCircle,
  faExpandAlt,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAudio,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFire,
  faFont,
  faGlobe,
  faBorderNone,
  faHeading,
  faHistory,
  faHome,
  faHourglass,
  faHouseUser,
  faImage,
  faInbox,
  faInfoCircle,
  faItalic,
  faListOl,
  faListUl,
  faLocationArrow,
  faLock,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarker,
  faMapMarkerAlt,
  faMapPin,
  faMapSigns,
  faMask,
  faMedkit,
  faMoon,
  faMountain,
  faPaintBrush,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPencilAlt,
  faPhone,
  faPlane,
  faPlus,
  faPowerOff,
  faQuestion,
  faQuestionCircle,
  faQuoteLeft,
  faRoad,
  faGear,
  faLayerGroup,
  faRunning,
  faSatellite,
  faSave,
  faSearch,
  faSignOutAlt,
  faSlash,
  faSpinner,
  faSquareFull,
  faStreetView,
  faStrikethrough,
  faSun,
  faSyncAlt,
  faTachometerAlt,
  faTemperatureLow,
  faTimes,
  faTrash,
  faUnderline,
  faUser,
  faUserCircle,
  faUserMinus,
  faUserPlus,
  faUsers,
  faUserSecret,
  faVideo,
  faWalking,
  faWind,
  faProjectDiagram,
  faInfo,
  faQrcode,
  faUserFriends,
  faMinusCircle,
  faMobile,
  faTablet,
  faRedo,
  faWifi,
  faThLarge,
  faCrosshairs,
  faDrawPolygon,
  faVectorSquare,
  faMinus,
  faPlusCircle,
  faUndo,
  faArrowsAlt,
  faLongArrowAltUp,
  faLongArrowAltRight,
  faFile,
  faRotate,
  faPhoneSlash,
  faPhoneFlip,
  faMicrophone,
  faMicrophoneSlash,
  faShapes,
  faEllipsisVertical,
  faDiamond,
  faUserXmark,
  faCloudSunRain,
  faTowerBroadcast,
  faTriangleExclamation,
  faRectangleList,
  faFlag,
  faPlay,
  faCheckDouble,
  faList,
  faBan,
  faTableList,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';

library.add(
  faQuestionCircle,
  faTrash,
  faSave,
  faPen,
  faLock,
  faUsers,
  faPlus,
  faSearch,
  faTimes,
  faCheck,
  faCheckCircle,
  faCircle,
  faHouseUser,
  faMap,
  faCommentAlt,
  faExclamationCircle,
  faChevronUp,
  faChevronDown,
  faBars,
  faSignOutAlt,
  faUserCircle,
  faSatellite,
  faSyncAlt,
  faStreetView,
  faMoon,
  faRoad,
  faGear,
  faLayerGroup,
  faFire,
  faCloudSun,
  faTemperatureLow,
  faMedkit,
  faWind,
  faSun,
  faMountain,
  faMapMarker,
  faMapMarkerAlt,
  faMapPin,
  faInbox,
  faCamera,
  faEllipsisV,
  faPaperclip,
  faPaperPlane,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faPencilAlt,
  faSpinner,
  faPowerOff,
  faEye,
  faUserSecret,
  faMask,
  faUser,
  faPaintBrush,
  faInfoCircle,
  faCaretDown,
  faCaretUp,
  faChevronDown,
  faChevronRight,
  faClock,
  faHourglass,
  faLocationArrow,
  faCompass,
  faHome,
  faCalendar,
  faCalendarAlt,
  faCog,
  faEyeSlash,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faSlash,
  faBolt,
  faImage,
  faCommentAlt,
  faClipboard,
  faClipboardList,
  faGlobe,
  faBorderNone,
  faMountain,
  faTachometerAlt,
  faMapSigns,
  faExternalLinkAlt,
  faMapMarked,
  faMapMarkedAlt,
  faCheckSquare,
  faSquare,
  faSquareFull,
  faHistory,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faEnvelopeSquare,
  // filetypes
  faFilePdf,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePowerpoint,
  faFileWord,
  faFileVideo,
  faFileAudio,
  faFont,
  //editor
  faBold,
  faItalic,
  faUnderline,
  faCode,
  faHeading,
  faQuoteLeft,
  faListUl,
  faListOl,
  faVideo,
  faStrikethrough,
  faAngleDown,
  faUserPlus,
  faUserMinus,
  faExclamation,
  faEnvelope,
  faPhone,
  faPhoneSlash,
  faPhoneFlip,
  faBell,
  faClone,
  faQuestion,
  faWalking,
  faRunning,
  faCar,
  faBicycle,
  faPlane,
  // player
  faExpandAlt,
  faCompressAlt,
  faProjectDiagram,
  faInfo,
  faQrcode,
  faUserFriends,
  faMinusCircle,
  faMobile,
  faTablet,
  faRedo,
  faWifi,
  faThLarge,
  faCrosshairs,
  faVectorSquare,
  faDrawPolygon,
  faMinus,
  faPlusCircle,
  faUndo,
  faArrowsAlt,
  faLongArrowAltUp,
  faLongArrowAltRight,
  faFile,
  faRotate,
  faMicrophone,
  faMicrophoneSlash,
  faShapes,
  faEllipsisVertical,
  faDiamond,
  faUserXmark,
  faCloudSunRain,
  faTowerBroadcast,
  faTriangleExclamation,
  faRectangleList,
  faFlag,
  faPlay,
  faCheckDouble,
  faList,
  faBan,
  faTableList
);
