/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import { DotButton } from '../common/buttons/DotButton';
import { PropTypes } from 'prop-types';
import { EventManager } from '../common/EventManager';

/**
 * Generic modal component.
 *
 * @param {Boolean} isActive is modal active
 * @param {Jsx} children modal body
 * @param {string} modalId modal id
 * @param {Function} exit close modal
 */
export const Modal = ({ isActive, children, modalId, exit }) => {
  const isActiveRef = useRef(isActive);

  useEffect(() => {
    isActiveRef.current = isActive;
  });

  return isActive ? (
    <>
      <EventManager
        element={document}
        event="keydown"
        handler={(e) => {
          if (isActiveRef.current) {
            switch (e.key) {
              case 'Escape':
                if (typeof exit === 'function') {
                  exit();
                }
                break;
              default:
                break;
            }
          }
        }}
      />
      <div className="modal">
        <div
          className="modal-background"
          onClick={() => {
            if (exit) {
              exit(false);
            }
          }}
        ></div>
        <div className="modal-content" id={'modal-' + modalId}>
          {typeof exit === 'function' && (
            <DotButton
              className="exit"
              onClick={() => exit()}
              ariaLabel="Close"
            >
              <FontAwesomeIcon
                icon="times"
                style={{ height: '15px', width: '15px', color: 'grey' }}
              />
            </DotButton>
          )}
          <div className="box">{isActive && children}</div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

Modal.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.any,
  modalId: PropTypes.string,
  exit: PropTypes.func,
};
