import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import React, { useEffect } from 'react';
import ReactSwitch from 'react-switch';
import { integrationsInterface } from '../../../api/integrations';
import { handleError } from '../../../utils/error';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import './MissionIntegrations.scss';
import { PropTypes } from 'prop-types';
import { integrationType } from '../../../enums/propTypes';

/**
 * Map Sidebar - mission integrations
 * Lists all integrations enabled for the mission
 *
 * @param {string} organisationId organisation id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any[]} allIntegrations mission integrations list
 * @param {Function} setAllIntegrations callback to set integrations
 */
export const MissionActiveIntegrations = ({
  organisationId,
  closeSidebar,
  allIntegrations,
  setAllIntegrations,
}) => {
  const setSelected = (code, checked) => {
    if (!integrationsInterface[code]) {
      handleError(`${code} coming soon! 👷`);
      allIntegrations.find((i) => i.code === code).active = false;
    } else {
      log.debug('set selected', code, checked);
      allIntegrations.find((i) => i.code === code).active = checked;
    }
    setAllIntegrations([...allIntegrations]);
  };

  return (
    <div className="mission-integrations shadow">
      <div className="top">
        <div className="heading">
          <div className="text">Mission Integrations</div>
          <DotButton
            className="exit"
            onClick={() => {
              closeSidebar();
            }}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        <div className="subheading">Toggle Integration Visibility</div>
      </div>
      <LightScrollbar className="content">
        <div className="blocks gap pad">
          {allIntegrations.map((integration, index) => (
            <IntegrationSection
              key={index}
              {...integration}
              setSelected={setSelected}
            />
          ))}
        </div>
      </LightScrollbar>
      <div className="foot"></div>
    </div>
  );
};

MissionActiveIntegrations.propTypes = {
  organisationId: PropTypes.string,
  closeSidebar: PropTypes.func,
  allIntegrations: PropTypes.arrayOf(integrationType),
  setAllIntegrations: PropTypes.func,
};

/**
 * Integration list item
 *
 * @param {string} photoUrl integration icon
 * @param {string} code integration code
 * @param {string} description integration description
 * @param {Boolean} selected selected?
 * @param {Function} setSelected set selected
 */
const IntegrationSection = ({
  photoUrl,
  code,
  description,
  active,
  setSelected,
}) => {
  useEffect(() => {
    // setChecked(selected);
  });

  const handleCheckElement = (checked) => {
    setSelected(code, checked);
  };

  return (
    <div className="item-container shadow">
      <div className="img">
        <Avatar photoUrl={photoUrl} fallback={[code]} />
      </div>
      <div className="text">{code}</div>
      <div className="v-center">
        <label>
          <ReactSwitch
            height={28}
            width={56}
            checkedIcon={false}
            uncheckedIcon={false}
            checked={active || false}
            onColor={'#2c7691'}
            offColor={'#c7cce6'}
            onChange={handleCheckElement}
          ></ReactSwitch>
        </label>
      </div>
      <div className="v-center">
        <FontAwesomeIcon icon="ellipsis-v"></FontAwesomeIcon>
      </div>
    </div>
  );
};

IntegrationSection.propTypes = {
  photoUrl: PropTypes.string,
  code: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
};
