/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { getMission, getYourMissions } from '../../api/missions';
import interactionsSVG from '../../assets/images/status/interactions-b.svg';
import { useNotification } from '../../context/NotificationContext';
import { useUser } from '../../context/UserContext';
import { GeofenceLevel } from '../../enums/geofenceLevel';
import { sortArray, sortBy, unique } from '../../utils/array';
import {
  getNotificationIcon,
  notificationAction,
  NotificationStatus,
  notificationStatusText,
  NotificationType,
} from '../../utils/notifications';
import { displayName, getClassNames } from '../../utils/string';
import { formatDate, formatTime } from '../../utils/time';
import { MissionInfo } from '../missions/view-mission/MissionInfo';
import { Avatar } from './Avatar';
import { DotButton } from './buttons/DotButton';
import { MenuButton } from './Menu';
import { DarkScrollbar } from './Scrollbars';
import { SearchInput } from './Search';

const filters = ['All Notifications'];
const sorts = ['Most recent', 'Most important', 'Unread'];

const filterNotifications = (notifications, search, filter, sort) => {
  let fields = ['_ts'];
  let dirs = ['asc'];

  switch (sort) {
    case 'Unread':
      fields = ['read', '_ts'];
      dirs = ['desc', 'desc'];
      break;
    case 'Most important':
      fields = ['status', '_ts'];
      dirs = ['asc', 'desc'];
      break;
    default:
      fields = ['_ts'];
      dirs = ['desc'];
      break;
  }

  return sortBy(
    notifications.filter(
      (not) => not.message.toLowerCase().indexOf(search.toLowerCase()) !== -1
    ),
    fields,
    dirs
  );
};

const groupNotifications = (notifications, sort) => {
  switch (sort) {
    case 'Unread': {
      const groups = unique(
        sortArray(
          notifications?.length
            ? notifications.map((m) => (m.read || false ? 'Read' : 'Unread'))
            : [],
          'desc'
        )
      ).map((g) => {
        return {
          group: g,
          notifications: selectGroup(notifications, sort, g),
        };
      });
      return groups;
    }
    case 'Most important':
      return unique(sortArray(notifications.map((m) => m.status)))
        .map((t) => notificationStatusText(t))
        .map((g) => {
          return {
            group: g,
            notifications: selectGroup(notifications, sort, g),
          };
        });
    default: {
      const groups = unique(
        sortArray(
          notifications.map((m) => m._ts),
          'desc'
        ).map((t) => dayjs(t * 1000).format('YYYY-MM-DD'))
      )
        .map((t) => formatDate(t))
        .map((g) => {
          return {
            group: g,
            notifications: selectGroup(notifications, sort, g),
          };
        });

      const critical = selectCritical(notifications);

      if (critical.length) {
        return [{ group: 'Critical', notifications: critical }, ...groups];
      }

      return groups;
    }
  }
};

const selectGroup = (notifications, sort, group) => {
  switch (sort) {
    case 'Unread':
      return notifications
        .filter(
          (n) =>
            (n.read || false ? 'Read' : 'Unread') === group &&
            n.status !== NotificationStatus.critical
        )
        .map((not) => {
          not.when = formatTime(not._ts);
          return not;
        });
    case 'Most important':
      return notifications
        .filter((n) => notificationStatusText(n.status) === group)
        .map((not) => {
          not.when = formatTime(not._ts);
          return not;
        });
    default:
      return notifications
        .filter(
          (n) =>
            formatDate(dayjs(n._ts * 1000).format('YYYY-MM-DD')) === group &&
            n.status !== NotificationStatus.critical
        )
        .map((not) => {
          not.when = dayjs(not._ts * 1000).format('h:mmA');
          return not;
        });
  }
};

const selectCritical = (notifications) => {
  return notifications
    .filter((not) => not.status === NotificationStatus.critical)
    .map((not) => {
      not.when = formatTime(not._ts);
      return not;
    });
};

/**
 * Notification side panel
 *
 * @param {Function} openPreferences callback to open preferences sidebar
 * @returns
 */
export const NotificationsPanel = ({ openPreferences }) => {
  const { user } = useUser();
  const _notifications = useNotification();
  const notifications = _notifications.notifications;

  const [missions, setMissions] = useState([]);

  const [filter, setFilter] = useState(filters[0]);
  const [sort, setSort] = useState(sorts[0]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (user?.id) {
      if (_notifications.currentMissionId) {
        getMission(_notifications.currentMissionId).then((mission) =>
          setMissions([mission])
        );
      } else {
        getYourMissions().then(setMissions);
      }
    }
  }, [_notifications.currentMissionId, user?.id, user?.accessRoles]);

  const filteredNotifications = filterNotifications(
    notifications,
    search,
    filter,
    sort
  );

  const groups = groupNotifications(filteredNotifications, sort);

  return _notifications.showNotifications ? (
    <div id="dot-notifications-panel" className="notifications-panel">
      <div className="toolbar">
        <div>
          <MissionInfo
            history={_notifications.notificationHistory}
            missions={missions}
            readOnly
          ></MissionInfo>
        </div>
        <DotButton
          className="button exit"
          onClick={() => {
            const el = document.getElementById('dot-notifications-panel');
            el.addEventListener('webkitAnimationEnd', () => {
              _notifications.closeNotifications();
            });
            el.classList.add('pending-remove');
          }}
          ariaLabel="Close"
        >
          <FontAwesomeIcon
            icon="times"
            style={{ color: 'grey' }}
          ></FontAwesomeIcon>
        </DotButton>
      </div>
      <div className="header">
        <FontAwesomeIcon icon="bell"></FontAwesomeIcon>
        <span>Notifications</span>
      </div>
      <div className="search-panel">
        <SearchInput
          placeholder="Search notifications..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="filters">
        <MenuButton
          position="bottom-right"
          options={filters.map((f) => {
            return {
              text: f,
              onClick: () => {
                setFilter(f);
              },
            };
          })}
        >
          {filter}
          <FontAwesomeIcon icon="chevron-down" />
        </MenuButton>
        <MenuButton
          position="bottom"
          options={sorts.map((s) => {
            return {
              text: s,
              onClick: () => {
                setSort(s);
              },
            };
          })}
        >
          {sort} <FontAwesomeIcon icon="chevron-down" />
        </MenuButton>
      </div>
      <div className="status">
        <div>
          <span>
            {pluralize('Notification', notifications?.length || 0, true)}
          </span>

          <span className="new">
            {notifications?.filter((n) => !n.read)?.length || 0} new
          </span>
        </div>
        <DotButton
          className="link"
          onClick={() => {
            _notifications.markAllAsRead();
          }}
        >
          Mark all as read
        </DotButton>
      </div>
      <DarkScrollbar className="notifications-scroll">
        <div className="notifications">
          {!!groups?.length &&
            groups.map(
              (d, groupIndex) =>
                !!d.notifications.length && (
                  <div key={`group-${groupIndex}`}>
                    <div className="date">
                      {d.group} ({d.notifications.length})
                    </div>
                    {d.notifications.map((notification, notIndex) => (
                      <Notification
                        key={`notification-${groupIndex}-${notIndex}`}
                        notificationHistory={_notifications.notificationHistory}
                        notification={notification}
                        markAsRead={() => {
                          _notifications.markAsRead(notification.id);
                        }}
                      ></Notification>
                    ))}
                  </div>
                )
            )}
        </div>
      </DarkScrollbar>
    </div>
  ) : (
    <></>
  );
};

NotificationsPanel.propTypes = { openPreferences: PropTypes.func.isRequired };

/**
 * Notification component
 * @param {History} notificationHistory instance of history
 * @param {any} notification notification
 * @param {Function} markAsRead callback to mark as read
 */
const Notification = ({ notificationHistory, notification, markAsRead }) => {
  return (
    <div
      onClick={() => {
        notificationAction(notificationHistory, [notification]);
        markAsRead();
      }}
      className={getClassNames({
        notification: true,
        read: notification.read,
        unread: !notification.read,
        advisory: notification.status === GeofenceLevel.Advisory,
        caution: notification.caution === GeofenceLevel.Caution,
        warning: notification.warning === GeofenceLevel.Warning,
        critical: notification.status === NotificationStatus.critical,
      })}
    >
      <div className="notification-row">
        <img className="unread-icon" src={interactionsSVG} alt="unread" />
        {notification.type === NotificationType.comms ||
        notification.type === NotificationType.voice ? (
          <Avatar entity={notification.author} />
        ) : (
          <NotificationIcon
            icon={
              notification.status === NotificationStatus.critical
                ? 'status-critical'
                : notification.type
            }
          >
            {notification.author && (
              <Avatar entity={notification.author} size="1.25rem" />
            )}
          </NotificationIcon>
        )}
        <div className="notification-content">
          <div>
            {notification.status === NotificationStatus.important && (
              <img
                src={getNotificationIcon('status-important')}
                alt="priority"
              />
            )}
            <ReactMarkdown>{notification.message}</ReactMarkdown>
          </div>
          <span>
            by <strong>{displayName(notification.author)}</strong>
          </span>
        </div>
        <div className="when">{notification.when}</div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  notificationHistory: PropTypes.any.isRequired,
  notification: PropTypes.shape({
    status: PropTypes.string,
    type: PropTypes.string,
    read: PropTypes.bool,
    author: PropTypes.object,
    message: PropTypes.string,
    when: PropTypes.string,
  }).isRequired,
  markAsRead: PropTypes.func.isRequired,
};

export const NotificationIcon = ({ icon, children }) => {
  return (
    <div className="notification-icon">
      <img src={getNotificationIcon(icon)} alt="calendar" />
      {children}
    </div>
  );
};

NotificationIcon.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.any,
};
