import log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { getYourMissions } from '../../../api/missions';
import { historyType } from '../../../enums/propTypes';
import { sortBy } from '../../../utils/array';
import { cancellablePromise } from '../../../utils/promise';
import { AdminMenu } from '../../common/menus/AdminMenu';
import { NavBar } from '../../common/Navbar';
import './Mission.scss';
import { MissionMap } from './MissionMap';

/**
 * Mission Map page - multiple mission
 *
 * @param {any} history react router
 */
export const ActiveMissions = withRouter(({ history }) => {
  const [missions, setMissions] = useState([]);

  useEffect(() => {
    const stub = async () => {
      const savedMissions = await getYourMissions();

      savedMissions.forEach((savedMission) => {
        if (!savedMission.location) {
          savedMission.location = null;
        }
        if (!savedMission.bounds) {
          savedMission.bounds = null;
        } else if (
          savedMission.bounds &&
          savedMission.bounds?.coordinates?.length &&
          savedMission.bounds?.coordinates[0].length < 4
        ) {
          savedMission.bounds = null;
        }
      });

      sortBy(savedMissions, ['area'], ['desc']);

      log.debug('savedMissions', savedMissions);

      setMissions(savedMissions);
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mission">
      <NavBar activeRoute="map-view" history={history}>
        <AdminMenu history={history} activeRoute="map-view"></AdminMenu>
      </NavBar>
      {!!missions?.length && (
        <MissionMap
          isMultiple={true}
          missions={missions}
          history={history}
        ></MissionMap>
      )}
    </div>
  );
});

ActiveMissions.propTypes = {
  history: historyType,
};
