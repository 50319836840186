/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { postForgotPassword } from '../../api/orgs_users';
import { endpointConfig } from '../../config/endpoint_config';
import { useUser } from '../../context/UserContext';
import { historyType } from '../../enums/propTypes';
import { handleError } from '../../utils/error';
import { validateEmail } from '../../utils/string';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { Welcome } from '../generic-components/Welcome';

/**
 * Forgot password page
 *
 * @param {any} history react router
 */
export const ForgotPassword = ({ history }) => {
  const userValue = useUser();

  // Login details
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (userValue.user?.id) {
      history.push(endpointConfig.frontendEndpoints.missionNav);
    }
    // eslint-disable-next-line
  }, [userValue.user]);

  const recoverPassword = async (e) => {
    e.preventDefault();

    // Input validation
    if (!validateEmail(email)) {
      toast.error('Email is not valid.');
      return;
    }

    const rs = await postForgotPassword(email).catch(handleError);
    if (rs?.success) {
      setSent(true);
      toast.success('Password reset has been sent to your email');
    }
  };

  return (
    <Welcome>
      {!sent ? (
        <>
          <div className="login-title">Reset your password</div>
          <div className="text">
            <p>
              Enter your email address below to receive instructions to reset
              your password.
            </p>
          </div>
          <form>
            <Input
              label="Email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required={true}
            />
            <DotButton
              type="submit"
              onClick={recoverPassword}
              className="primary block"
            >
              Send Email
            </DotButton>
          </form>
          <div className="forgot-signup">
            <button
              className="link forgot-button is-pulled-left"
              onClick={() => {
                history.push(endpointConfig.frontendEndpoints.login);
              }}
            >
              <FontAwesomeIcon icon="chevron-left" /> <span>Back to login</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="login-title">
            Password reset has been sent to your email
          </div>
          <div className="text">
            <p>
              Please check the inbox of <strong>{email}</strong> and follow the
              instructions to reset your password.
            </p>
            <p>
              If you did not receive an email, please check your inbox or spam
              box again in a few minutes.
            </p>
            <p>
              You can request a{' '}
              <button
                className="link forgot-button is-pulled-left"
                onClick={() => {
                  setEmail('');
                  setSent(false);
                }}
              >
                password reset
              </button>{' '}
              again if you have not received the email.
            </p>
          </div>
          <div className="forgot-signup">
            <button
              className="link forgot-button is-pulled-left"
              onClick={() => {
                history.push(endpointConfig.frontendEndpoints.login);
              }}
            >
              <FontAwesomeIcon icon="chevron-left" /> <span>Back to login</span>
            </button>
          </div>
        </>
      )}
    </Welcome>
  );
};

ForgotPassword.propTypes = { history: historyType };
