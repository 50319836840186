/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { createContext, useContext, useState } from 'react';
import { getClassNames } from '../utils/string';
import { PropTypes } from 'prop-types';

/**
 * Bottombars enum.
 */
export const BOTTOMBARS = Object.freeze({
  weatherPanel: 'weather-panel',
});

/**
 * Bottombar state.
 *
 * @param {string[]} bottombars active bottombars
 * @param {Function} isOpen is bottombar open
 * @param {Function} isMinimized is bottombar minimized
 * @param {Function} open open a bottombar
 * @param {Function} close close a bottombar
 * @param {Function} closeAll close all bottombars
 */
export const BottombarContext = createContext({
  bottombars: [],
  previous: '',
  isOpen: (bottombarId) => {},
  isMinimized: () => {},
  open: (bottombarId, currentBottombarId) => {},
  close: (bottombarId) => {},
  closeAll: () => {},
});

/**
 * Bottombar provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const BottombarProvider = ({ children }) => {
  const [bottombars, setBottombars] = useState([]);
  const [previous, setPrevious] = useState('');
  const [minimized, setMinimized] = useState(false);

  const contextValue = {
    bottombars,
    previous,
    isOpen: (bottombarId) => {
      const isOpen = bottombars.indexOf(bottombarId) !== -1;
      return isOpen;
    },
    open: (bottombarId, currentBottombarId = '') => {
      console.log('opening', bottombarId);
      setPrevious(currentBottombarId);
      setBottombars([bottombarId]);
    },
    isMinimized: () => {
      return minimized;
    },
    setMinimized: (m) => {
      setMinimized(m);
    },
    close: (bottombarId) => {
      setBottombars([]);
    },
    closeAll: () => {
      setBottombars([]);
    },
  };

  return (
    <BottombarContext.Provider value={contextValue}>
      <div
        className={getClassNames({
          'bottombar-open': bottombars.length && !minimized,
          'bottombar-minimized': bottombars.length && minimized,
        })}
      >
        {children}
      </div>
    </BottombarContext.Provider>
  );
};

BottombarProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming bottombar context.
 *
 * @returns Bottombar
 */
export const useBottombar = () => {
  return useContext(BottombarContext);
};
