/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import dayjs from 'dayjs';
import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  getThread,
  getThreadMessages,
  getThreads,
  getToken,
  threadRead,
} from '../api/comms';
import { commsConfig } from '../config/commsConfig';
import { useNotification } from '../context/NotificationContext';
import { useUser } from '../context/UserContext';
import { Signal } from '../enums/signal';
import { getLocalStorage, useLocalStorage } from '../utils/localStorage';
import { NotificationStatus, NotificationType } from '../utils/notifications';
import { cancellablePromise } from '../utils/promise';
import { useEvent } from './EventContext';
import { CallClient } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { handleError } from '../utils/error';
import { VoiceCall } from '../components/common/VoiceCall';
import { CallState } from '../enums/call';
import { displayName } from '../utils/string';
import { Howl } from 'howler';
import ringtone from '../assets/mp3/ringtone.mp3';
import { useParams, withRouter } from 'react-router-dom';
import { getOrgUsers } from '../api/orgs_users';
import { getUserLocations } from '../api/missions';

const AWAY_TIMEOUT = 1000 * 60 * 5;

/**
 * Comms state.
 *
 * @param {any} threads all threads - dictionary on thread id
 * @param {any} messages all messages - dictionary on thread id
 * @param {any[]} typingIndicators is anyone typing?
 * @param {any[]} killThreads any thread listed here will be removed from the comms state, and any open thread windows closed
 * @param {Boolean} commsLoading is comms loading
 */
export const CommsContext = createContext({
  threads: null,
  messages: null,
  commsLoading: false,
  typingIndicators: [],
  killThreads: [],
  chatMode: false,
  setChatMode: (chatMode) => {},
  loadThread: (thread) => {},
  makePhoneCall: (user) => {},
  hangUp: () => {},
  accept: () => {},
  reject: () => {},
  callState: CallState.Disconnected,
  muted: false,
  mute: () => {},
  unmute: () => {},
  remoteMuted: false,
  callUser: null,
});

/**
 * Comms provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const CommsProvider = withRouter(({ history, location, children }) => {
  const userValue = useUser();
  const notification = useNotification();

  const [threads, setThreads] = useState({});
  const [messages, setMessages] = useState({});
  const [commsLoading, setCommsLoading] = useState(true);
  // eslint-disable-next-line
  const [typingIndicators, setTypingIndicators] = useState([]);
  // eslint-disable-next-line
  const [killThreads, setKillThreads] = useState([]);

  const threadsRef = useRef(threads);
  const messagesRef = useRef(messages);
  const typingIndicatorsRef = useRef(typingIndicators);
  const killThreadsRef = useRef(killThreads);
  const missionIdRef = useRef(notification?.lastMissionId);
  const { missionId } = useParams();
  const [chatMode, setChatMode] = useLocalStorage('chatMode', false);

  const callClient = useRef(null);
  const callAgent = useRef(null);
  const call = useRef(null);
  const [callUser, setCallUser] = useState(null);
  const localCallUser = useRef(callUser);
  const [callState, setCallState] = useState(CallState.Disconnected);
  const [incomingCall, setIncomingCall] = useState(null);
  const localIncomingCall = useRef(incomingCall);
  const [muted, setMuted] = useState(false);
  const [remoteMuted, setRemoteMuted] = useState(false);
  // ACS doesn't yet support presence for voice
  // eslint-disable-next-line
  const [talking, setTalking] = useState(true);
  const audio = useRef();
  const [time, setTime] = useState(0);
  const [centreMapOnPersonnel, setCentreMapOnPersonnel] = useState(null);
  const [openUserDetails, setOpenUserDetails] = useState(null);
  const [orgUsers, setOrgUsers] = useState(null);

  const loadThread = useCallback(
    (thread) => {
      const isOurs =
        (thread.missionWide && thread.missionId === missionIdRef.current) ||
        (thread.participants?.length &&
          !!thread.participants?.find((p) => p === userValue.user.id)) ||
        (thread.participantsFull?.length &&
          !!thread.participantsFull?.find((p) => p.id === userValue.user.id));

      log.debug('thread received', thread, isOurs);

      if (isOurs) {
        if (thread.archived) {
          log.debug('thread deleted', thread);
          delete threadsRef.current[thread.id];
          setThreads({ ...threadsRef.current });
        } else {
          log.debug('thread updated', thread);
          if (threadsRef.current[thread.id]) {
            threadsRef.current[thread.id] = {
              ...threadsRef.current[thread.id],
              ...thread,
            };
          } else {
            threadsRef.current[thread.id] = thread;
          }
          setThreads({ ...threadsRef.current });
        }
      }
    },
    [userValue.user.id]
  );

  // Triggered by local call.current on state change
  const onStateChanged = (e) => {
    log.debug('stateChanged', call.current?.state);
    if (call.current?.state === CallState.Disconnected) {
      // call disconnected
      localCallUser.current = null;
      setCallUser(null);
      call.current = null;
    }

    if (call.current?.state === CallState.Ringing) {
      audio.current?.play();
    } else {
      audio.current?.stop();
    }

    // if (call.current?.remoteParticipants?.length) {
    //   call.current.remoteParticipants[0].on('isSpeakingChanged', (e) => {
    //     log.debug('isSpeakingChanged', e);
    //   });
    // }
    setCallState(call.current?.state || CallState.Disconnected);
  };

  // ACS doesn't yet support presence for voice - yet
  const onSpeakingChanged = () => {
    // ACS doesn't yet support presence for voice
    // if (call.current?.remoteParticipants?.length) {
    //   setTalking(call.current.remoteParticipants[0].isSpeaking);
    // } else {
    //   setTalking(false);
    // }
  };

  // Triggered by remote participant when THEIR mute status is updated
  const onMutedChanged = () => {
    if (call.current?.remoteParticipants?.length) {
      setRemoteMuted(call.current.remoteParticipants[0].isMuted);
    } else {
      setRemoteMuted(false);
    }
  };

  // Provider for hanging up call
  const hangUp = useCallback(async () => {
    if (call.current) {
      await call.current.hangUp().catch(() => {});
      call.current = null;
      localCallUser.current = null;
      setCallUser(null);
      setCallState(CallState.Disconnected);
    } else {
      handleError('Call disconnected');
    }
  }, []);

  // Provider for making a call
  const makePhoneCall = useCallback(
    async (user) => {
      try {
        if (!user?.communicationServices) {
          handleError('User is not accepting calls');
          return;
        }

        if (callState === CallState.Connected && callAgent.current) {
          // hang up current call
          await hangUp().catch(() => {});
        }

        if (callAgent.current) {
          let identity = {
            communicationUserId: user.communicationServices.identity,
          };

          // uncomment to test
          // identity = { id: '8:echo123' };

          call.current = callAgent.current.startCall([identity], {});

          call.current.on('stateChanged', onStateChanged);
          const remoteParticipant = call.current.remoteParticipants[0];
          if (remoteParticipant) {
            // wire remote participant events
            remoteParticipant.on('isSpeakingChanged', onSpeakingChanged);
            remoteParticipant.on('isMutedChanged', onMutedChanged);
            setRemoteMuted(remoteParticipant.isMuted || false);
          }
          localCallUser.current = user;
          setCallUser(user);
          setMuted(call.current?.isMuted || false);
        }
      } catch (err) {
        log.error('call error', err);
      }
    },
    [callState, hangUp]
  );

  useEffect(() => {
    getPersonnel();
    // eslint-disable-next-line
  }, [missionId]);

  const getPersonnel = async () => {
    const users = await getOrgUsers(userValue.user.organisationId);
    setOrgUsers(users);
    if (localIncomingCall.current) {
      let found = null;
      found = users
        .filter(
          (p) =>
            p.firstname + ' ' + p.lastname ===
            localIncomingCall.current.callerInfo?.displayName
        )
        .filter((f) => f.id !== userValue.user.id);

      if (missionId) {
        const usersInMission = await getUserLocations(missionId);
        found = usersInMission
          .filter((u) => u.id === found[0].id)
          .filter((f) => f.id !== userValue.user.id);
      }
      if (!!found[0]) {
        found[0].coords = found[0].location?.coords;
        localCallUser.current = found[0];
        setCallUser(found[0]);
      }
    }
  };

  // Provider for accepting a call
  const accept = useCallback(async () => {
    try {
      if (incomingCall && callState === CallState.Connected) {
        // hang up current call
        await hangUp().catch(() => {});
      }

      audio.current?.stop();

      if (incomingCall) {
        call.current = await incomingCall.accept().catch((err) => {
          handleError(err);
        });
        call.current.on('stateChanged', onStateChanged);

        const remoteParticipant = call.current.remoteParticipants[0];
        if (remoteParticipant) {
          remoteParticipant.on('isSpeakingChanged', onSpeakingChanged);
          remoteParticipant.on('isMutedChanged', onMutedChanged);
          setRemoteMuted(remoteParticipant.isMuted);
        }
      }
      setIncomingCall(null);
      setMuted(call.current?.isMuted || false);
    } catch (err) {
      log.error('hangUp error', err);
    }
  }, [callState, incomingCall, hangUp]);

  // provider for rejecting a call
  const reject = useCallback(async () => {
    try {
      audio.current?.stop();

      if (incomingCall) {
        await incomingCall.reject().catch((err) => {
          handleError(err);
        });
      }
      localCallUser.current = null;
      setCallUser(null);
      setIncomingCall(null);
    } catch (err) {
      log.error('hangUp error', err);
    }
  }, [incomingCall]);

  // provider for muting YOURSELF
  const mute = async () => {
    await call.current.mute().catch((err) => log.error('mute error', err));
    setMuted(true);
  };

  // provider for unmuting YOURSELF
  const unmute = async () => {
    await call.current.unmute().catch((err) => log.error('mute error', err));
    setMuted(false);
  };

  // provider for initializing chats
  const initChats = async (missionId) => {
    setCommsLoading(true);

    if (missionId) {
      const _threads = await getThreads(missionId);
      const newThreads = {};
      for (const thread of _threads) {
        newThreads[thread.id] = thread;
      }
      setThreads(newThreads);

      const newMessages = {};
      for (const thread of _threads) {
        const threadMessages = await getThreadMessages(
          missionId,
          thread.id
        ).catch(() => {});
        newMessages[thread.id] = threadMessages.map((m) => {
          m.isSelf = m.user.userId === userValue.user.id;
          const user = orgUsers.find((u) => u.id === m.user.userId);
          if (user?.photoUrl) {
            m.user.photoUrl = user.photoUrl;
          }
          return m;
        });
      }
      setMessages(newMessages);
    }
    setCommsLoading(false);
  };

  const contextValue = {
    threads,
    messages,
    commsLoading,
    typingIndicators,
    killThreads,
    chatMode,
    setChatMode,
    loadThread,
    makePhoneCall,
    hangUp,
    callState,
    accept,
    reject,
    muted,
    mute,
    unmute,
    remoteMuted,
    callUser,
    initChats,
    time,
    setTime,
    isMap: notification?.isMap,
    setCentreMapOnPersonnel,
    centreMapOnPersonnel,
    openUserDetails,
    setOpenUserDetails,
  };

  useEffect(() => {
    threadsRef.current = threads;
    messagesRef.current = messages;
    typingIndicatorsRef.current = typingIndicators;
    killThreadsRef.current = killThreads;
    missionIdRef.current = notification?.lastMissionId;
  });

  const messageUpdate = useCallback(
    (e) => {
      log.debug('messageUpdate', e);
      if (e.user.userId === userValue.user.id) {
        e.isSelf = true;
      }

      const user = orgUsers.find((u) => u.id === e.user.userId);
      e.user.photoUrl = user.photoUrl;

      // check to see if thread is in your list
      if (threadsRef.current[e.threadId]) {
        const focusedThreads = getLocalStorage('focused-threads') || [];

        if (
          focusedThreads.find(
            (t) =>
              t.id === e.threadId && new Date().getTime() - t._ts < AWAY_TIMEOUT
          )
        ) {
          // we have the chat window open
          // only send RR if we haven't read it
          if (threadsRef.current[e.threadId]) {
            threadRead(e.missionId, e.threadId, userValue.user);
          }
        } else if (!e.isSelf) {
          // if the thread isn't in focus then send notification to notifications centre
          notification.addNotification(
            e.missionId,
            NotificationType.comms,
            NotificationStatus.general,
            e.text,
            e.user,
            e.threadId
          );
        }

        const threadMessages = messagesRef.current[e.threadId] || [];
        if (!threadMessages.find((m) => m.id === e.id)) {
          threadMessages.push(e);
        }
        setMessages({ ...messagesRef.current, [e.threadId]: threadMessages });
      }
    },
    // eslint-disable-next-line
    [notification, userValue.user]
  );

  const threadUpdate = useCallback(
    (e) => {
      getThread(missionIdRef.current, e.id).then(loadThread);
    },
    [loadThread]
  );

  const typing = useCallback(
    (e) => {
      log.debug('Typing indicator received', e);
      if (e.userId !== userValue.user.id) {
        const found = typingIndicatorsRef.current.find(
          (ti) => ti.userId === e.userId && ti.threadId === e.threadId
        );
        if (found) {
          found.receivedOn = new Date();
          setTypingIndicators([...typingIndicatorsRef.current]);
        } else {
          setTypingIndicators([
            ...typingIndicatorsRef.current,
            { ...e, receivedOn: new Date() },
          ]);
        }

        setTimeout(() => {
          setTypingIndicators([
            ...typingIndicatorsRef.current.filter((ti) => {
              return (
                dayjs().diff(ti.receivedOn, 'milliseconds') <=
                commsConfig.typingTimeout
              );
            }),
          ]);
        }, commsConfig.typingTimeout);
      }
    },
    [userValue.user.id]
  );

  useEvent(Signal.MessageUpdate, messageUpdate);
  useEvent(Signal.Typing, typing);
  useEvent(Signal.ThreadUpdate, threadUpdate);

  // effect called when router state has changed
  useEffect(() => {
    if (location.state?.length) {
      for (const action of location.state) {
        switch (action.type) {
          case 'voice':
            if (incomingCall) {
              if (action.action === 'answer') {
                accept();
              } else {
                reject();
              }
              // essentially undo the state change in the router
              // so that answer doesn't get triggered automatically the
              // next time a call comes through
              history.replace(history.location.pathname, null);
            }
            break;
          default:
            break;
        }
      }
    }
  }, [history, location.state, incomingCall, accept, reject]);

  // Triggered when incoming call was ended - while ringing
  const onCallEnded = useCallback(() => {
    // stop ringing
    audio.current?.stop();

    handleError('Call disconnected');
    localCallUser.current = null;
    setCallUser(null);
    notification.dismissCallNotification();
    // eslint-disable-next-line
  }, []);

  // run each time incoming call is changed
  useEffect(() => {
    const _incoming = incomingCall;

    if (_incoming) {
      _incoming.on('callEnded', onCallEnded);
    }

    return () => {
      if (_incoming) {
        _incoming.off('callEnded', onCallEnded);
      }
    };
  }, [incomingCall, onCallEnded]);

  // run each time mission has changed
  useEffect(() => {
    const onIncomingCall = async (call) => {
      log.debug('onIncomingCall', call);
      audio.current?.play();
      setIncomingCall(call.incomingCall);
      localIncomingCall.current = call.incomingCall;
      await getPersonnel();
      notification.addNotification(
        notification?.lastMissionId,
        NotificationType.voice,
        NotificationStatus.general,
        `INCOMING CALL`,
        localCallUser.current,
        'answer',
        null,
        localIncomingCall.current
      );
    };

    const onCallsUpdated = (calls) => {
      log.debug('onCallsUpdated', calls);
    };

    const initComms = async (missionId) => {
      setCommsLoading(true);

      if (!!!audio.current) {
        audio.current = new Howl({
          src: [ringtone],
          loop: true,
          autoplay: false,
          preload: true,
          autoUnlock: true,
        });
      }

      if (!!!callClient.current) {
        callClient.current = new CallClient();
      }

      if (!!!callAgent.current) {
        // set ACS token
        const _token = await getToken();

        const tokenCredential = new AzureCommunicationTokenCredential(
          _token.token
        );
        callAgent.current = await callClient.current.createCallAgent(
          tokenCredential,
          {
            displayName: displayName(userValue.user),
          }
        );
      }

      callAgent.current.on('incomingCall', onIncomingCall);
      callAgent.current.on('callsUpdated', onCallsUpdated);

      if (missionId) {
        const _threads = await getThreads(missionId);
        const newThreads = {};
        for (const thread of _threads) {
          newThreads[thread.id] = thread;
        }
        setThreads(newThreads);

        const newMessages = {};
        for (const thread of _threads) {
          const threadMessages = await getThreadMessages(
            missionId,
            thread.id
          ).catch(() => {});
          newMessages[thread.id] = threadMessages.map((m) => {
            m.isSelf = m.user.userId === userValue.user.id;
            return m;
          });
        }
        setMessages(newMessages);
      }
      setCommsLoading(false);
    };

    log.debug('init worker');
    const { promise, cancel } = cancellablePromise(initComms(missionId));
    promise.then(() => {}).catch((e) => {});

    return () => {
      cancel();

      if (call.current) {
        if (call.current?.remoteParticipants?.length) {
          call.current.remoteParticipants[0].off(
            'isSpeakingChanged',
            onSpeakingChanged
          );
          call.current.remoteParticipants[0].off(
            'isMutedChanged',
            onMutedChanged
          );
        }
        call.current.off('stateChanged', onStateChanged);
      }

      if (callAgent.current) {
        callAgent.current.off('incomingCall', onIncomingCall);
        callAgent.current.off('callsUpdated', onCallsUpdated);
      }
    };
    // eslint-disable-next-line
  }, [notification?.lastMissionId]);

  return (
    <CommsContext.Provider value={contextValue}>
      {callState !== CallState.Disconnected && (
        <div
          className="call-wrapper making-call"
          style={{ position: 'sticky' }}
        >
          <VoiceCall
            entity={localCallUser.current}
            remoteMuted={remoteMuted}
            talking={!remoteMuted && talking}
          ></VoiceCall>
        </div>
      )}
      <div>{children}</div>
    </CommsContext.Provider>
  );
});

CommsProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming Comms context.
 *
 * @returns CommsContext
 */
export const useComms = () => {
  return useContext(CommsContext);
};
