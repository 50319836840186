/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DotButton } from '../common/buttons/DotButton';
import './CreateEditSection.scss';
import { LightScrollbar } from '../common/Scrollbars';
import React, { useState } from 'react';
import { isEqual, only } from '../../utils';
import * as log from 'loglevel';
import { Input } from '../common/Input';
import { RichText } from '../common/RichText';
import { ConfirmModal } from '../modals/ConfirmModal';
import { PropTypes } from 'prop-types';
import { taggableType, sectionType } from '../../enums/propTypes';
import { saveOrderSection } from '../../api/orders';
import toast from 'react-hot-toast';

const SECTION_FIELDS = ['description', 'id', 'missionId', 'name'];

/**
 * @param {any} task
 * @param {Function} setTask
 * @param {string} missionId
 * @param {any[]} taggable
 * @param {Function} closeSidebar callback to close sidebar
 */

export const CreateEditSectionSidebar = ({
  section,
  setSection,
  missionId,
  taggables,
  setTaskToDelete,
  closeSidebar,
}) => {
  const exit = () => {
    closeSidebar();
  };

  const [form, setForm] = useState(section);

  const [formChanged, setFormChanged] = useState(section.isHistory || false);

  const [modalOpen, setModalOpen] = useState(false);

  // eslint-disable-next-line
  const [tasks, setTasks] = useState(section.tasks || []);

  const isValid = () => {
    return form?.name;
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const promptExit = () => {
    if (formChanged) {
      setModalOpen(true);
    } else {
      exit();
    }
  };

  const setFormWCD = (f) => {
    if (!isEqual(form, f)) {
      setFormChanged(true);
    }
    setForm(f);
  };

  const saveSection = async () => {
    log.debug('save section', only(section, SECTION_FIELDS));
    const updatedSection = {
      ...only(section, SECTION_FIELDS),
      ...only(form, SECTION_FIELDS),
      tasks,
    };

    log.debug('save section', updatedSection);

    // remove user id so the server assigns it
    delete updatedSection.userId;

    await saveOrderSection(missionId, updatedSection);

    setSection(null);

    toast.success('Section saved!');
  };

  return (
    <div className="task-sidebar">
      <div className="top has-border">
        <div className="heading">
          <div className="text">
            {section.id ? 'Edit Section' : 'Create Section'}
          </div>
          <DotButton className="exit" onClick={promptExit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <LightScrollbar className="content">
        <section className="new task sidebarTask">
          <div className="section-body">
            <Input
              label="Section Name"
              placeholder="Enter a name for this section"
              required
              value={form.name}
              onChange={(e) => setFormWCD({ ...form, name: e.target.value })}
            ></Input>

            <RichText
              label="Section Description"
              placeholder="Enter a description for this section"
              required
              value={form.description}
              onChange={(newValue) =>
                setFormWCD({ ...form, description: newValue })
              }
              taggables={taggables}
            ></RichText>
          </div>

          <ConfirmModal
            isActive={modalOpen}
            exit={() => {
              setModalOpen(null);
            }}
            icon="exclamation-circle"
            modalId="section-changes"
            title={'Cancel changes to this section?'}
            buttons={[
              {
                text: 'Yes, Cancel Changes',
                type: 'primary',
                callback: exit,
              },
              {
                text: 'No, Go Back',
                type: 'secondary',
                callback: closeModal,
              },
            ]}
          >
            You will lose any changes you have made to this section including
            tasks that have been created within this section.
          </ConfirmModal>
        </section>
      </LightScrollbar>
      <div className="section-footer">
        <DotButton className="secondary" onClick={promptExit}>
          Cancel
        </DotButton>
        <DotButton
          className="primary"
          disabled={!isValid() || !formChanged}
          onClick={saveSection}
        >
          {section.id ? 'Save Changes' : 'Create Section'}
        </DotButton>
      </div>
    </div>
  );
};

CreateEditSectionSidebar.propTypes = {
  section: sectionType,
  setSection: PropTypes.func,
  missionId: PropTypes.string,
  taggables: PropTypes.arrayOf(taggableType),
  setTaskToDelete: PropTypes.func,
  closeSidebar: PropTypes.func.isRequired,
};
