/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { getClassNames } from '../../utils/string';
import { DotButton } from './buttons/DotButton';
import { EventManager } from './EventManager';
import './Menu.scss';

let portal = document.getElementById('menu-button-portal');
if (!portal) {
  portal = document.createElement('div');
  portal.id = 'menu-button-portal';
  document.body.appendChild(portal);
}

/**
 * Button with pop out menu
 *
 * @param {string} title button text - shown to left of button
 * @param {Jsx} children menu button content - typically an icon
 * @param {{onClick: Function, text: string}[]} options menu options
 * @param {string} position where to display the popout menu
 * @param {'light' || 'dark'} buttonTheme button theme
 * @param {string} tip tooltip
 * @param {string} place where to display the tooltip
 */
export const MenuButton = ({
  title,
  children,
  options,
  position = 'bottom',
  buttonTheme = 'dark',
  tip,
  place,
  className,
  ariaLabel,
  disabled,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const wrapperRef = useRef(null);

  const getStyle = () => {
    var rect = wrapperRef.current.getBoundingClientRect();

    switch (position) {
      case 'left':
        return {
          top: rect.top,
          right: window.innerWidth - rect.left,
        };
      case 'right':
        return {
          top: rect.top,
          left: rect.left + rect.width,
        };
      case 'top-right':
        return {
          top: rect.top - 45 * (options?.length || 0),
          left: rect.left + rect.width,
        };
      case 'bottom-left':
        return {
          top: rect.top + rect.height,
          right: rect.left - rect.width,
        };
      case 'bottom':
        return {
          top: rect.top + rect.height,
          left: rect.left,
        };
      case 'bottom-right':
      default:
        return {
          top: rect.top + rect.height,
          left: rect.left + rect.width,
        };
    }
  };

  const portalMenu = showMenu ? (
    ReactDOM.createPortal(
      <div
        style={getStyle()}
        className={getClassNames({ menu: true }, position)}
      >
        {options &&
          options
            .filter((o) => !!o)
            .map((o, index) => (
              <DotButton
                disabled={o.disabled}
                key={index}
                ariaLabel={o.ariaLabel}
                className={getClassNames({ 'left-align': !!o.iconLeft })}
                onClick={() => {
                  setShowMenu(false);
                  o.onClick();
                }}
              >
                {!!o.icon && !!o.iconLeft && (
                  <span className="menu-icon">
                    <FontAwesomeIcon icon={o.icon}></FontAwesomeIcon>
                  </span>
                )}
                <span>{o.text}</span>
                {!!o.icon && !o.iconLeft && (
                  <FontAwesomeIcon icon={o.icon}></FontAwesomeIcon>
                )}
              </DotButton>
            ))}
      </div>,
      portal
    )
  ) : (
    <></>
  );

  return (
    <>
      {showMenu && (
        <EventManager
          element={document}
          event="mousedown"
          handler={(event) => {
            if (
              wrapperRef.current &&
              !wrapperRef.current.contains(event.target)
            ) {
              if (portal && !portal.contains(event.target)) {
                setShowMenu(false);
              }
            }
          }}
        />
      )}
      <div
        ref={wrapperRef}
        className={getClassNames(
          { 'menu-button': true },
          buttonTheme + ' ' + className
        )}
      >
        {!!title && <span>{title}</span>}
        <DotButton
          tip={tip}
          place={place}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setShowMenu(!showMenu);
          }}
          ariaLabel={ariaLabel}
          disabled={disabled}
        >
          {children}
        </DotButton>
        {portalMenu}
      </div>
    </>
  );
};

MenuButton.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      text: PropTypes.any.isRequired,
    })
  ),
  position: PropTypes.oneOf([
    'bottom',
    'top',
    'left',
    'right',
    'bottom-right',
    'top-right',
  ]),
  buttonTheme: PropTypes.oneOf(['light', 'dark']),
  tip: PropTypes.string,
  place: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
