/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import { useNotification } from '../../context/NotificationContext';
import { sortBy } from '../../utils/array';
import {
  getNotificationIcon,
  notificationAction,
  NotificationStatus,
  NotificationType,
} from '../../utils/notifications';
import { displayName, getClassNames } from '../../utils/string';
import { Avatar } from './Avatar';
import { DotButton } from './buttons/DotButton';
import { NotificationIcon } from './NotificationsPanel';

/**
 * Notifications toast modals. Shown when new notifications are received
 */
export const NotificationsModal = () => {
  const _notifications = useNotification();

  const notifications = sortBy(
    _notifications.notifications,
    ['read', 'dismissed'],
    ['asc', 'asc']
  );

  const unreadNotifications = notifications.filter((n) => !n.read);
  const activeNotifications = unreadNotifications.filter((n) => !n.dismissed);

  return (
    <div className="notifications-modal">
      {activeNotifications.length > 1 && (
        <DotButton
          className="dismiss"
          onClick={() => {
            _notifications.dismissAll();
          }}
        >
          Dismiss All
        </DotButton>
      )}

      {!!unreadNotifications?.length &&
        unreadNotifications.map((notification) => {
          let actionText = null;
          let actionTwoText = null;
          let voiceCall = false;
          switch (notification.type) {
            case NotificationType.battery:
              actionText = 'View Unit';
              break;
            case NotificationType.broadcast:
              actionText = 'View Comms';
              break;
            case NotificationType.comms:
              actionText = 'View Chat';
              break;
            case NotificationType.voice:
              actionText = 'Accept';
              actionTwoText = 'Decline';
              voiceCall = true;
              break;
            case NotificationType.calendar:
              actionText = 'View Orders';
              break;
            case NotificationType.critical:
              actionText = 'View Map';
              break;
            case NotificationType.generalOutage:
              break;
            case NotificationType.geofence:
              actionText = 'View Geofence';
              break;
            case NotificationType.integration:
              actionText = 'View Map';
              break;
            case NotificationType.location:
              actionText = 'View Map';
              break;
            case NotificationType.media:
              actionText = 'View Media';
              break;
            case NotificationType.mention:
              actionText = 'View Orders';
              break;
            case NotificationType.mission:
              actionText = 'View Map';
              break;
            case NotificationType.offline:
              break;
            case NotificationType.orders:
              actionText = 'View Orders';
              break;
            case NotificationType.userInteractive:
              break;
            case NotificationType.userLogoff:
              break;
            case NotificationType.userLogon:
              break;
            case NotificationType.waypoint:
              actionText = 'View Waypoint';
              break;
            default:
              break;
          }

          return (
            <div
              key={notification.id}
              className={getClassNames({
                notification: true,
                read: notification.dismissed || notification.read,
                unread: !(notification.dismissed || notification.read),
                critical: notification.status === NotificationStatus.critical,
              })}
              style={{
                background: voiceCall && 'black',
                borderTopLeftRadius: voiceCall && '8px',
                borderTopRightRadius: voiceCall && '8px',
              }}
            >
              <div className="notification-row">
                {notification.type === NotificationType.comms ||
                notification.type === NotificationType.voice ? (
                  <div className="caller-icon">
                    <div className="call-button">
                      {notification.type === NotificationType.voice ? (
                        <FontAwesomeIcon icon="phone-flip" />
                      ) : (
                        <FontAwesomeIcon icon="comment-alt" />
                      )}
                    </div>
                    <Avatar entity={notification.author} size="20px"></Avatar>
                  </div>
                ) : (
                  <NotificationIcon
                    icon={
                      notification.status === NotificationStatus.critical
                        ? 'status-critical'
                        : notification.type
                    }
                  >
                    {notification.author && (
                      <Avatar entity={notification.author} size="1.25rem" />
                    )}
                  </NotificationIcon>
                )}
                <div className="notification-content">
                  <div>
                    {notification.status === NotificationStatus.important && (
                      <img
                        src={getNotificationIcon('status-important')}
                        alt="priority"
                      />
                    )}
                    <ReactMarkdown>{notification.message}</ReactMarkdown>
                  </div>
                  <span>
                    {notification.type !== NotificationType.voice ? 'by ' : ''}{' '}
                    <strong>{displayName(notification.author)}</strong>
                  </span>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                {!!actionText && (
                  <div
                    className="action"
                    style={{
                      width: actionTwoText ? '50%' : '100%',
                      background:
                        voiceCall && 'var(--colour-positive-status-a)',
                      borderBottomLeftRadius: voiceCall && '8px',
                    }}
                  >
                    <DotButton
                      className="button"
                      onClick={() => {
                        _notifications.markAsRead(notification.id);
                        notificationAction(_notifications.notificationHistory, [
                          notification,
                        ]);
                      }}
                    >
                      {actionText}
                      {voiceCall && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon icon="phone-flip" />
                        </>
                      )}
                    </DotButton>
                  </div>
                )}
                {!!actionTwoText && (
                  <div
                    className="action"
                    style={{
                      width: actionTwoText ? '50%' : '100%',
                      background:
                        voiceCall && 'var(--colour-negative-status-a)',
                      borderBottomRightRadius: voiceCall && '8px',
                    }}
                  >
                    <DotButton
                      className="button"
                      onClick={() => {
                        _notifications.markAsRead(notification.id);
                        notification.target = 'decline';
                        notificationAction(_notifications.notificationHistory, [
                          notification,
                        ]);
                      }}
                    >
                      {actionTwoText}
                      {voiceCall && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon size={'lg'} icon="times" />
                        </>
                      )}
                    </DotButton>
                  </div>
                )}
              </div>

              {!voiceCall && (
                <div className="exit">
                  <DotButton
                    className="button"
                    onClick={() => {
                      _notifications.dismiss(notification.id);
                    }}
                    ariaLabel="Close"
                  >
                    <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
                  </DotButton>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

NotificationsModal.propTypes = {};
