/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { useState } from 'react';

/**
 * Hook for using local storage like useState.
 *
 * @param {string} key key
 * @param {any} initialValue intial value
 */
export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      log.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      log.error(error);
    }
  };
  return [storedValue, setValue];
};

/**
 * Save to local storage
 *
 * @param {*} name key
 * @param {*} data value
 * @param {boolean} [parse=true] automatically stringify data?
 */
export const saveToLocalStorage = (name, data, parse = true) => {
  try {
    if (parse) {
      if (data !== null && data !== undefined) {
        localStorage.setItem(name, JSON.stringify(data));
      }
    } else {
      localStorage.setItem(name, data);
    }
  } catch (e) {
    log.error(e);
  }
};

/**
 * Remove item from local storage
 *
 * @param {*} name key
 */
export const removeFromLocalStorage = (name) => {
  try {
    localStorage.removeItem(name);
  } catch (e) {
    log.error(e);
  }
};

/**
 * Get from local storage
 *
 * @param {*} name key
 * @param {boolean} [parse=true] automatically parse json data?
 * @return {*} value
 */
export const getLocalStorage = (name, parse = true) => {
  try {
    if (parse) {
      const json = localStorage.getItem(name);
      return json ? JSON.parse(json) : null;
    } else {
      return localStorage.getItem(name);
    }
  } catch {
    return null;
  }
};

/**
 * Clear local storage
 *
 * @param {string[]} except don't clear these values
 */
export const clearLocalStorage = (except) => {
  const retain = {};
  if (except?.length) {
    for (const key of except) {
      const val = getLocalStorage(key);
      if (val !== undefined && val !== null) {
        retain[key] = getLocalStorage(key);
      }
    }
  }

  try {
    localStorage.clear();
  } catch (e) {
    log.error(e);
  }

  if (except?.length) {
    for (const key of except) {
      saveToLocalStorage(key, retain[key]);
    }
  }
};
