/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getHistory } from '../../../api/orders';
import { displayName, getClassNames } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import ReactMarkdown from 'react-markdown';
import './OrderHistory.scss';
import { sortBy } from '../../../utils/array';
import { cancellablePromise } from '../../../utils/promise';
import { PropTypes } from 'prop-types';

/**
 * Sidebar - Order/Task history
 *
 * @param {string} missionId mission id
 * @param {any} currentOrders current set of orders
 * @param {Function} closeSidebar close sidebar
 * @param {any} showHistory orders to show history for
 * @param {Function} setShowHistory set orders
 * @param {Function} setOrdersToRestore set orders to restore - opens modal
 * @param {Boolean} canEdit can edit
 */
export const OrderHistory = ({
  missionId,
  currentOrders,
  closeSidebar,
  showHistory,
  setShowHistory,
  setOrdersToRestore,
  canEdit,
}) => {
  const [history, setHistory] = useState([]);

  // TEMP HELPERS WHILE SG SORTS HIS SHIT OUT
  const findSection = (item) => {
    const section = currentOrders?.sections.find(
      (s) => s.id === item.orderSectionId
    );
    if (!section) {
      // this is a task
      for (const s of currentOrders?.sections) {
        const task = s.tasks.find((t) => t.id === item.orderSectionId);
        if (task) {
          item.taskId = task.id;
          item.orderSectionId = s.id;
          return;
        }
      }
    }
  };

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(getHistory(missionId));
    promise
      .then((history) => {
        for (const item of history) {
          findSection(item);
        }
        setHistory(history);
      })
      .catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  const exit = () => {
    closeSidebar();
  };

  const getChangeText = (change) => {
    if (change.taskId) {
      const section = currentOrders.sections.find(
        (s) => s.id === change.orderSectionId
      );
      if (section) {
        return `Updated task **${section.name}** ▷ **${change.name}**`;
      }
    } else if (change.orderSectionId) {
      return `Updated section **${change.name}**`;
    }
    return ``;
  };

  return (
    <div className="history-sidebar">
      <div className="top">
        <div className="heading">
          <div className="text">Orders Archive</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <LightScrollbar className="content">
        <div className="list">
          {!!history?.length &&
            sortBy(history, ['_ts'], ['desc']).map((h) => (
              <div
                key={h.id}
                className={getClassNames({
                  'list-item': true,
                  selected: showHistory === h,
                })}
                onClick={() => {
                  if (showHistory === h) {
                    setShowHistory(null);
                  } else {
                    setShowHistory(h);
                  }
                }}
              >
                <div className="inner">
                  <div className="details">
                    <div className="date">
                      {dayjs(h._ts * 1000).format('D MMM, YYYY h:mmA')}
                    </div>
                    <div className="change">
                      <ReactMarkdown>{getChangeText(h)}</ReactMarkdown>
                    </div>
                    <div className="author">
                      <div className="tag">
                        <Avatar
                          entity={h.editor}
                          size="28px"
                          color={'#505366'}
                        />
                        <span>{displayName(h.editor)}</span>
                      </div>

                      {showHistory === h && canEdit && (
                        <div className="restore">
                          <DotButton
                            className="primary extra-small"
                            onClick={() => {
                              setOrdersToRestore(h);
                            }}
                          >
                            Restore
                          </DotButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </LightScrollbar>
    </div>
  );
};

OrderHistory.propTypes = {
  missionId: PropTypes.string,
  currentOrders: PropTypes.any,
  closeSidebar: PropTypes.func,
  showHistory: PropTypes.any,
  setShowHistory: PropTypes.func,
  setOrdersToRestore: PropTypes.func,
  canEdit: PropTypes.bool,
};
