/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { getClassNames } from '../../utils/string';
import './Tabs.scss';
import { useRef, useState } from 'react';
import { DotButton } from './buttons/DotButton';
import { useEffect } from 'react';
import { PropTypes } from 'prop-types';

/**
 * Tab control
 *
 * @param {any[]} items tab items
 * @param {string} className additional class names
 * @param {any} style style overrides
 */
export const Tabs = ({ items, className, style }) => {
  const ref = useRef();
  const [width, setWidth] = useState(0);
  const [translate, setTranslate] = useState(0);

  useEffect(() => {
    const activeIdx = items
      .filter((i) => !i.hidden)
      .indexOf(items.find((i) => i.active));
    const width = ref.current?.clientWidth || 0;
    const size =
      width / parseFloat(items.filter((i) => !i.hidden).length) - 0.5;
    setWidth(size);
    setTranslate(width - size * activeIdx);
  }, [items, ref.current?.clientWidth]);

  const _classNames = getClassNames({ tabs: true }, className);

  return (
    <div ref={ref} className={_classNames} style={style}>
      {items
        .filter((i) => !i.hidden)
        .map((i, idx) => (
          <DotButton
            key={idx}
            style={{ width }}
            className={getClassNames({
              'is-active': i.active,
              'step-4': idx === 0,
              'step-5': idx !== 0,
            })}
            onClick={i.onClick}
          >
            {i.text}
          </DotButton>
        ))}
      <div
        className="slider"
        style={{
          width,
          transform: `translate(-${translate}px, 0px)`,
        }}
      ></div>
    </div>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      text: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  className: PropTypes.string,
  style: PropTypes.object,
};
