/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { updatePointOfInterest } from '../../../api/missions';
import { getMissionPersonnel } from '../../../api/orgs_users';
import { useUser } from '../../../context/UserContext';
import { canDeletePoi } from '../../../utils/auth';
import { handleError } from '../../../utils/error';
import { toLngLat, toLongitudeLatitude } from '../../../utils/geo';
import { cancellablePromise } from '../../../utils/promise';
import { displayName } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { Input } from '../../common/Input';
import { LightScrollbar } from '../../common/Scrollbars';
import { TextArea } from '../../common/TextArea';
import { UploadImage } from '../../common/UploadImage';
import { LocationInfo } from './LocationInfo';
import './MissionSidebar.scss';
import { PropTypes } from 'prop-types';
import { locationType } from '../../../enums/propTypes';
import { POI } from '../../../enums/poi';
import { useSidebar } from '../../../context/SidebarContext';
import { useEvent } from '../../../context/EventContext';
import { Signal } from '../../../enums/signal';
import toast from 'react-hot-toast';
import badge from '../../../assets/svg/dot-badge.svg';

/**
 * Sidebar - show waypoint info
 *
 * @param {string} missionId mission id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any} image POI data
 * @param {any} location location data
 * @param {Boolean} isOwner is owner of this POI
 * @param {Function} setUnit opens personnel sidebar
 * @param {Function} updateImages refresh POIs
 * @param {Function} setPoiToDelete delete poi - opens modal
 * @param {object} searchResult result of searching for a location
 */
export const MissionImage = ({
  closeSidebar,
  missionId,
  image,
  location,
  isOwner,
  setUnit,
  updateImages,
  setPoiToDelete,
  searchResult,
}) => {
  const userValue = useUser();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const sidebars = useSidebar();

  const [inputValues, setInputValues] = useState({
    reference: image?.reference || '',
    name:
      !!!image?.name &&
      searchResult?.geometry?.coordinates[0] === location?.lng &&
      searchResult?.geometry?.coordinates[1] === location?.lat
        ? searchResult?.place_name.substring(
            0,
            searchResult?.place_name.indexOf(',')
          )
        : image?.name || '',
    description: image?.description || '',
  });

  const [imageLocation, setImageLocation] = useState(location);

  useEffect(() => {
    const stub = async () => {
      if (image) {
        const _user = await getMissionPersonnel(missionId, image.userId);
        setUser(_user);
      } else if (!image) {
        setUser(userValue.user);
      }
    };

    setInputValues({
      reference: image?.reference || '',
      name:
        !!!image?.name &&
        searchResult?.geometry?.coordinates[0] === location?.lng &&
        searchResult?.geometry?.coordinates[1] === location?.lat
          ? searchResult?.place_name.substring(
              0,
              searchResult?.place_name.indexOf(',')
            )
          : image?.name || '',
      description: image?.description || '',
    });

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [image, location]);

  const exit = () => {
    closeSidebar();
  };

  let title = 'Create Image';
  if (image) {
    if (!isOwner) {
      title = 'Image Details';
    } else {
      title = 'Edit Image';
    }
  }

  const isFormValid = () => {
    return !!inputValues?.name && !!inputValues?.reference && !image?.error;
  };

  const submitChanges = useCallback(async () => {
    const newValue = {
      ...image,
      ...inputValues,
    };

    if (!newValue.coords) {
      newValue.coords = toLongitudeLatitude(location);
    }
    if (!newValue.type) {
      newValue.type = POI.Photo;
    }
    newValue.missionId = missionId;

    await updatePointOfInterest(newValue).catch(handleError);

    await updateImages();

    if (newValue.id) {
      toast.success('Image updated', {
        id: 'poi-updated',
      });
    } else {
      toast.success('Image created', {
        id: 'poi-updated',
      });
    }

    exit();
    // eslint-disable-next-line
  }, [inputValues, image, imageLocation]);

  useEvent(Signal.PoiUpdate, (poi) => {
    if (poi.id === image.id) {
      setImageLocation(toLngLat(poi.location));
    }
  });

  return (
    <div className="mission-sidebar">
      {(loading || !user) && (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              animation: 'rotation 2s infinite linear',
              marginTop: '150px',
            }}
          >
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Loading details</div>
        </div>
      )}
      <>
        <div className="top">
          <div className="heading">
            <div className="text">{title}</div>
            <DotButton className="exit" onClick={exit} ariaLabel="Close">
              <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
            </DotButton>
          </div>
          {!!sidebars.previous && (
            <DotButton
              className="back"
              style={{ marginRight: '15px' }}
              onClick={() => {
                sidebars.open(sidebars.previous);
              }}
              ariaLabel="Back"
            >
              <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
            </DotButton>
          )}
        </div>
        <LightScrollbar className="content">
          <div className="blocks">
            {user?.user && (
              <div className="group">
                <h5>Created By</h5>
                <div
                  className="item-container"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (image) {
                      setUnit(user?.user);
                    }
                  }}
                >
                  <Avatar entity={user?.user} />
                  <div style={{ flexGrow: 1 }}>
                    <h4>{displayName(user?.user)}</h4>
                    <p>{user?.role?.role}</p>
                  </div>
                  {!!image && (
                    <DotButton>
                      <FontAwesomeIcon icon="chevron-right" />
                    </DotButton>
                  )}
                </div>
              </div>
            )}

            {!isOwner && (
              <div className="group">
                <h5>Image Name</h5>
                <p>{image.name}</p>
              </div>
            )}
            <div
              className="group"
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <h5>Upload Time</h5>
                <p>
                  {(image?._ts ? dayjs(image._ts * 1000) : dayjs())
                    .format('h:mmA, D MMM YYYY')
                    .toUpperCase()}
                </p>
              </div>
              {isOwner && <span>* Indicates mandatory field</span>}
            </div>

            {!isOwner && (
              <div className="group">
                <h5>Description</h5>
                <p>{image.description}</p>
              </div>
            )}

            <hr />
          </div>

          {isOwner && (
            <>
              <form>
                <div className="controls">
                  <UploadImage
                    photoUrl={{
                      url: inputValues.reference,
                      error: inputValues.error,
                    }}
                    setImage={(compressed) => {
                      setInputValues({
                        ...inputValues,
                        reference: compressed.url,
                        blob: compressed.blob,
                        error: compressed.error,
                      });
                    }}
                  />
                  <Input
                    label="Image Name"
                    placeholder="Enter the name of the image"
                    value={inputValues.name}
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        name: e.currentTarget.value,
                      })
                    }
                    required={true}
                    onEnter={() => {
                      if (isFormValid()) {
                        submitChanges();
                      }
                    }}
                  />
                  <TextArea
                    label="Image Description"
                    placeholder="The description to accompany the image."
                    value={inputValues.description}
                    onChange={(e) =>
                      setInputValues({
                        ...inputValues,
                        description: e.currentTarget.value,
                      })
                    }
                  />
                </div>
              </form>
            </>
          )}
          <LocationInfo
            title="Image Location"
            location={imageLocation}
            setLoading={setLoading}
          />
        </LightScrollbar>
        <div className="foot">
          {isOwner && (
            <DotButton
              className="primary block"
              onClick={submitChanges}
              disabled={!isFormValid()}
            >
              Save Changes
            </DotButton>
          )}
          {canDeletePoi(userValue.user, image) && !!setPoiToDelete && (
            <DotButton
              className="secondary block"
              onClick={() => {
                setPoiToDelete(image);
              }}
            >
              Delete Image
            </DotButton>
          )}
        </div>
      </>
    </div>
  );
};

MissionImage.propTypes = {
  closeSidebar: PropTypes.func,
  missionId: PropTypes.string,
  image: PropTypes.object,
  location: locationType,
  isOwner: PropTypes.bool,
  setUnit: PropTypes.func,
  updateImages: PropTypes.func,
  setPoiToDelete: PropTypes.func,
  searchResult: PropTypes.object,
};
