/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { Avatar } from '../../common/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endpointConfig } from '../../../config/endpoint_config.js';
import './orgPanel.scss';
import { DotButton } from '../../common/buttons/DotButton';
import { useUser } from '../../../context/UserContext';
import { Role } from '../../../enums/role';
import { historyType, organisationType } from '../../../enums/propTypes';
import { PropTypes } from 'prop-types';

/**
 * Renders the individual organisation panel (logo, name, edit/delete buttons, user button)
 *
 * @param {any} location org location
 * @param {number} activeUsers organisation users
 * @param {any} org current organisation
 * @param {Function} setOrgToDelete callback to set org to delete - open modal
 * @param {any} history react router
 */
export const OrgPanel = ({
  location,
  activeUsers,
  org,
  setOrgToDelete,
  history,
}) => {
  const userValue = useUser();

  const canEdit =
    userValue.user.accessRoles.includes(Role.SysAdmin) ||
    userValue.user.accessRoles.includes(Role.OrgAdmin);
  const canDelete = userValue.user.accessRoles.includes(Role.SysAdmin);

  return (
    <div className=" block org-info" style={{ marginBottom: '1rem' }}>
      <Avatar entity={org} />
      <div className="org-info-name">{org.organisationName}</div>
      <div className="org-info-location">{location}</div>
      <div className="org-info-active-users">{activeUsers} Active Users</div>
      {canDelete && (
        <DotButton
          className="org-info-delete-org"
          onClick={() => {
            setOrgToDelete(org);
          }}
        >
          Delete Organisation
          <FontAwesomeIcon
            icon="trash"
            style={{ height: '11px', marginLeft: '0.5rem' }}
          />
        </DotButton>
      )}
      {canEdit && (
        <>
          <DotButton
            className="org-info-edit-org"
            onClick={() => {
              history.push(
                endpointConfig.frontendEndpoints.organisation.replace(
                  ':id',
                  org.id
                )
              );
            }}
          >
            Edit Organisations
            <FontAwesomeIcon
              icon="pencil-alt"
              style={{ height: '11px', marginLeft: '0.5rem' }}
            />
          </DotButton>
          <DotButton
            className="secondary small"
            onClick={() => {
              history.push(
                endpointConfig.frontendEndpoints.orgUsers.replace(':id', org.id)
              );
            }}
          >
            Edit Users
          </DotButton>
        </>
      )}
    </div>
  );
};

OrgPanel.propTypes = {
  location: PropTypes.string,
  activeUsers: PropTypes.number,
  org: organisationType,
  setOrgToDelete: PropTypes.func,
  history: historyType,
};
