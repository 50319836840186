/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, { createContext, useEffect, useRef } from 'react';
import { useUser } from './UserContext';
import * as signalR from '@microsoft/signalr';
import { useDispatch } from './EventContext';
import { secrets } from '../config/secrets';
import * as log from 'loglevel';
import { cancellablePromise } from '../utils/promise';
import { offEventHandler, onEventHandler } from '../utils/handlers';
import { Signal } from '../enums/signal';

/**
 * SignalR context, uses event context to notify children of SignalR events.
 *
 * @requires EventContext
 */
export const SignalContext = createContext();

/**
 * Signal provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const SignalProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useUser();

  const signalr = useRef();

  const listeners = useRef({});
  const handlers = useRef({});

  useEffect(() => {
    for (const key in Signal) {
      handlers.current[key] = (msg) => {
        dispatch(Signal[key], msg);
      };
    }
  }, [dispatch]);

  useEffect(() => {
    const stub = async () => {
      if (user?.id) {
        // should always be true because we're in the Auth route
        await signalr.current.start();
        return true;
      }
      return false;
    };

    if (!signalr.current) {
      signalr.current = new signalR.HubConnectionBuilder()
        .withUrl(`${secrets.API_ENDPOINT_REALTIME}?uid=${user.id}`)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      for (const key in Signal) {
        offEventHandler(listeners.current, signalr.current, Signal[key]);
      }

      for (const key in Signal) {
        onEventHandler(
          listeners.current,
          signalr.current,
          Signal[key],
          handlers.current[key]
        );
      }

      signalr.current.onclose(() => log.debug('global hub disconnected'));
    }

    const { promise, cancel } = cancellablePromise(stub());
    promise
      .then((result) => {
        if (result) {
          log.debug('global hub connected');
        }
      })
      .catch((e) => {
        log.error('global hub error', e);
      });

    return () => {
      cancel();

      if (signalr.current) {
        for (const key in Signal) {
          // eslint-disable-next-line
          offEventHandler(listeners.current, signalr.current, Signal[key]);
        }

        signalr.current.stop();
        signalr.current = null;
      }
    };
    // eslint-disable-next-line
  }, [user?.id]);

  return (
    <SignalContext.Provider value={null}>{children}</SignalContext.Provider>
  );
};
