/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';
import { getNotifications } from '../api/notifications';
import { EventManager } from '../components/common/EventManager';
import { NotificationsBanner } from '../components/common/NotificationsBanner';
import { NotificationsModal } from '../components/common/NotificationsModal';
import { NotificationsPanel } from '../components/common/NotificationsPanel';
import { Sidebar } from '../components/common/Sidebar';
import { NotificationPreferences } from '../components/sidebars/notifications/NotificationPreferences';
import { getToken, onMessageListener } from '../config/firebaseConfig';
import { useSidebar } from '../context/SidebarContext';
import { useUser } from '../context/UserContext';
import {
  notificationAction,
  NotificationStatus,
  NotificationType,
} from '../utils/notifications';
import { cancellablePromise } from '../utils/promise';
import { displayName } from '../utils/string';
import { SIDEBARS } from './SidebarContext';

const DISMISS_TIMEOUT = 4000;

/**
 * Notification state.
 *
 * @param {History} notificationHistory instance of router history for navigation
 * @param {string[]} showNotifications show notification for mission id
 * @param {any[]} notifications all notifications
 * @param {string} currentMissionId current mission id
 * @param {string} lastMissionId last mission id
 * @param {Boolean} isMap are we on a map?
 * @param {Function} setHistory set router history instance
 * @param {Function} addNotification add notification
 * @param {Function} markAsRead mark notification as read
 * @param {Function} dismiss mark notification as dismissed
 * @param {Function} markAllAsRead mark all notifications as read
 * @param {Function} dismissAll mark all notifications as dismissed
 * @param {Function} openNotifications open notifications sidebar
 * @param {Function} closeNotifications close notifications sidebar
 * @param {Function} setCurrentMissionId set current mission id
 * @param {Function} setLastMissionId set last mission id
 * @param {Function} setIsMap set if is map
 */
export const NotificationContext = createContext({
  notificationHistory: null,
  showNotifications: '',
  notifications: [],
  currentMissionId: null,
  lastMissionId: null,
  missionName: null,
  isMap: null,
  setHistory: (history) => {},
  addNotification: (
    missionId,
    type,
    status,
    message,
    author,
    target,
    media,
    incoming
  ) => {},
  markAsRead: (id) => {},
  dismiss: (id) => {},
  markAllAsRead: () => {},
  dismissAll: () => {},
  openNotifications: (missionId) => {},
  closeNotifications: () => {},
  setCurrentMissionId: (missionId) => {},
  setLastMissionId: (missionId) => {},
  setMissionName: (missionName) => {},
  setIsMap: (isMap) => {},
  offline: false,
});

/**
 * Notification provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const NotificationProvider = ({ parentHistory, children }) => {
  const userValue = useUser();
  const sidebars = useSidebar();

  const [notificationHistory, setHistory] = useState(null);
  const [currentMissionId, setCurrentMissionId] = useState(null);
  const [lastMissionId, setLastMissionId] = useState(null);
  const [missionName, setMissionName] = useState(null);
  const [isMap, setIsMap] = useState(false);
  const [showNotifications, setShowNotifications] = useState('');
  const [notifications, setNotifications] = useState([]);
  // eslint-disable-next-line
  const [messagingToken, setMessagingToken] = useState(null);
  const [offline, setOffline] = useState(false);

  const localLastMissionId = useRef(lastMissionId);
  const localNotifications = useRef(notifications);

  // refs for managing app focus
  const focusRef = useRef(true);

  const markAsRead = (id) => {
    localNotifications.current.find((n) => n.id === id).read = true;
    setNotifications([...localNotifications.current]);
  };

  const dismiss = (id) => {
    log.debug('dismiss', id, localNotifications.current);
    if (localNotifications.current.find((n) => n.id === id)) {
      localNotifications.current.find((n) => n.id === id).dismissed = true;
      setNotifications([...localNotifications.current]);
    }
  };

  const markAllAsRead = () => {
    localNotifications.current.forEach((n) => (n.read = true));
    setNotifications([...localNotifications.current]);
  };

  const dismissAll = () => {
    localNotifications.current.forEach((n) =>
      n.type !== NotificationType.voice
        ? (n.dismissed = true)
        : (n.dismissed = false)
    );
    setNotifications([...localNotifications.current]);
  };

  const dismissCallNotification = () => {
    if (
      localNotifications.current.find((n) => n.type === NotificationType.voice)
    ) {
      localNotifications.current.find(
        (n) => n.type === NotificationType.voice
      ).dismissed = true;
    }
    setNotifications([...localNotifications.current]);
  };

  const addNotification = (
    missionId,
    type,
    status,
    message,
    author,
    target,
    media,
    incoming
  ) => {
    const id = uuid();

    const newNot = {
      id,
      missionId,
      type,
      status,
      message,
      author,
      read: false,
      dismiss: false,
      target,
      media,
      incoming,
      _ts: new Date().getTime() / 1000,
    };

    setNotifications([newNot, ...localNotifications.current]);

    // if the webapp is not in focus then show notification
    if (!focusRef.current) {
      log.debug('sending not');
      const not = new Notification(displayName(author), {
        body: message,
      });
      not.onclick = () => {
        markAsRead(id);
        notificationAction(notificationHistory, [newNot]);
      };
    }

    setTimeout(() => {
      if (type !== NotificationType.voice) {
        dismiss(id);
      }
    }, DISMISS_TIMEOUT);
  };

  const openNotifications = (missionId) => {
    setShowNotifications(missionId);
  };

  const closeNotifications = () => {
    setShowNotifications('');
  };

  useEffect(() => {
    localNotifications.current = notifications;
    localLastMissionId.current = lastMissionId;
  });

  const contextValue = {
    notificationHistory: notificationHistory ?? parentHistory,
    showNotifications,
    notifications,
    currentMissionId,
    lastMissionId,
    isMap,
    setHistory,
    addNotification,
    markAsRead,
    dismiss,
    markAllAsRead,
    dismissAll,
    dismissCallNotification,
    openNotifications,
    closeNotifications,
    setCurrentMissionId,
    setLastMissionId,
    setIsMap,
    offline,
    missionName,
    setMissionName,
  };

  const windowBlur = (e) => {
    focusRef.current = false;
  };

  const windowFocus = (e) => {
    focusRef.current = true;
  };

  const goneOffline = (e) => {
    log.debug('gone offline');
    setOffline(true);
    toast.error('You are offline');
  };

  const goneOnline = (e) => {
    log.debug('gone online');
    setOffline(false);
    toast.success('You are back online');
  };

  useEffect(() => {
    const stub = async () => {
      if (userValue.user?.id) {
        const notifications = await getNotifications();
        notifications.forEach((n) => {
          n.dismissed = true;
        });
        setNotifications(notifications);
      }
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});

    // ask for notification permissions
    if (window.Notification) {
      if (window.Notification.permission === 'granted') {
      }
    }

    getToken(setMessagingToken);

    onMessageListener().then((payload) => {
      // addNotification({
      //   title: payload.notification.title,
      //   body: payload.notification.body,
      // });
      log.debug('foreground notification', payload);
    });

    return () => {
      cancel();
    };
    // eslint-disable-next-line
  }, [userValue.user?.id]);

  return (
    <NotificationContext.Provider value={contextValue}>
      <NotificationsBanner
        isMission={!!currentMissionId}
        isMap={isMap}
        notifications={
          offline
            ? [{ status: NotificationStatus.offline }]
            : lastMissionId && !currentMissionId
            ? [...notifications, { status: NotificationStatus.tracked }]
            : notifications
        }
      >
        <EventManager element={window} event="blur" handler={windowBlur} />
        <EventManager element={window} event="focus" handler={windowFocus} />
        <EventManager element={window} event="offline" handler={goneOffline} />
        <EventManager element={window} event="online" handler={goneOnline} />

        {children}
        <NotificationsPanel
          openPreferences={() => {
            sidebars.open(SIDEBARS.notificationPreferences);
          }}
        ></NotificationsPanel>
        <Sidebar id={SIDEBARS.notificationPreferences}>
          <NotificationPreferences
            closeSidebar={() => {
              sidebars.close(SIDEBARS.notificationPreferences);
            }}
          />
        </Sidebar>
        <NotificationsModal></NotificationsModal>
      </NotificationsBanner>
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming Notification context.
 *
 * @returns NotificationContext
 */
export const useNotification = () => {
  return useContext(NotificationContext);
};
