/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import reactDom from 'react-dom';
import dom2image from 'dom-to-image';

/**
 * Get width and height of an image
 *
 * @param {*} image image
 * @return {{height: number, width: number}} dimensions
 */
export const getImageDimensions = (image) => {
  return new Promise((resolve, reject) => {
    image.onload = (e) => {
      const width = image.width;
      const height = image.height;
      resolve({ height, width });
    };
  });
};

/**
 * Client side image compression
 *
 * @param {*} image image
 * @param {*} scale compression scale
 * @param {*} initalWidth initial width of image
 * @param {*} initalHeight initial height of image
 * @param {*} quality compression quality (JPEG)
 * @return {Promise<Blob>} compressed blob
 */
export const compressImage = (
  image,
  scale,
  initalWidth,
  initalHeight,
  quality
) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');

    canvas.width = scale * initalWidth;
    canvas.height = scale * initalHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    const url = ctx.canvas.toDataURL('image/jpeg', quality);
    ctx.canvas.toBlob(
      (blob) => {
        resolve({
          url,
          blob,
        });
      },
      'image/jpeg',
      quality
    );
  });
};

export const renderToImage = (jsx, width, height) => {
  return new Promise((resolve, reject) => {
    let portal = document.getElementById('dom2image-portal');
    if (!portal) {
      portal = document.createElement('div');
      portal.id = 'dom2image-portal';
      if (width && height) {
        portal.style.width = width;
        portal.style.height = height;
        portal.style.display = 'flex';
        portal.style.alignItems = 'center';
        portal.style.justifyContent = 'center';
      }
      document.body.appendChild(portal);
    }
    if (portal.childNodes.length) {
      portal.removeChild(portal.firstChild);
    }

    reactDom.render(jsx, portal, () => {
      dom2image
        .toPng(document.getElementById('dom2image-portal'))
        .then((dataUrl) => {
          if (document.body.contains(portal)) {
            try {
              document.body.removeChild(portal);
            } catch {}
          }

          resolve(dataUrl);
        });
    });
  });
};
