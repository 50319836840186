/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

export const orderTemplates = {
  smeac: {
    name: 'SMEAC',
    sections: [
      {
        name: 'Situation',
        description: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'The background to a problem, or description of what has happened. What were the events leading up to where you are now?',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: 'What has happened? Why is it a problem? Who else is involved? When did it happen? What were the sequence of events? Where did it happen? How did it happen?',
                italic: true,
              },
            ],
          },
        ],
      },
      {
        name: 'Mission',
        description: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'What we need to do about what happened? The mission should be a short, clear and concise statement of what you want to achieve – “Our mission is to survey the area for danger”.',
              },
            ],
          },
        ],
      },
      {
        name: 'Execution',
        description: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'How you are going to achieve your mission?',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text: 'What are you going to do? Why? When? Where? Who is involved? How?',
                italic: true,
              },
            ],
          },
        ],
      },
      {
        name: 'Administration and Logistics',
        description: [
          {
            type: 'paragraph',
            children: [
              {
                text: 'What resources you need to do the job? How are these resources to be ',
              },
              {
                text: 'coordinated',
                bold: true,
              },
              {
                text: '.',
              },
            ],
          },
        ],
      },
      {
        name: 'Command and Communications',
        description: [
          {
            type: 'paragraph',
            children: [
              {
                type: 'paragraph',
                children: [
                  {
                    text: 'Who’s in charge? Who do you report to? How do you communicate with each other?',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
