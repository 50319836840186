/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as dayjs from 'dayjs';
import log from 'loglevel';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getOrders } from '../../../api/orders';
import { endpointConfig } from '../../../config/endpoint_config';
import {
  historyType,
  missionType,
  orgRoleType,
  personnelType,
  teamType,
} from '../../../enums/propTypes';
import { cancellablePromise } from '../../../utils/promise';
import { renderRichText } from '../../../utils/richtext';
import { displayName } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import { Tabs } from '../../common/Tabs';
import './MissionSidebar.scss';
import { Personnel } from './UnitInformation';

export const MissionDetails = ({
  closeSidebar,
  selectedMission,
  selectedRoles,
  selectedTeams,
  selectedPersonnel,
  isOwner,
  history,
}) => {
  const [tab, setTab] = useState('overview');

  const [searchRolesText, setSearchRolesText] = useState('');
  const [searchTeamsText, setSearchTeamsText] = useState('');
  const [searchPersonnelText, setSearchPersonnelText] = useState('');

  const [personnel, setPersonnel] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [orders, setOrders] = useState(null);

  useEffect(() => {
    const stub = async () => {
      log.debug('selectedMission', selectedMission);
      if (selectedMission) {
        const allOrders = await getOrders(selectedMission.id);
        if (allOrders.sections?.length) {
          setOrders(allOrders.sections[0].description);
        }
      }
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  const exit = () => {
    if (selectedUser) {
      setSelectedUser(null);
    } else if (selectedRole) {
      setSelectedRole(null);
    } else if (selectedTeam) {
      setSelectedTeam(null);
    } else if (personnel) {
      setPersonnel(null);
    } else {
      closeSidebar();
    }
  };

  const transformRoles = selectedRoles.filter((o) =>
    o?.role.toLowerCase().includes(searchRolesText.toLowerCase())
  );
  const transformTeams = selectedTeams.filter((o) =>
    o?.team.toLowerCase().includes(searchTeamsText.toLowerCase())
  );

  const selectPersonnel = (roleId, teamId) => {
    if (roleId) {
      setPersonnel(
        selectedPersonnel.filter(
          (u) =>
            u.roleId === roleId ||
            (u.guest && roleId === 'guest') ||
            (!u.roleId && roleId === 'unassigned' && !u.guest)
        )
      );
    } else if (teamId) {
      setPersonnel(
        selectedPersonnel.filter(
          (u) =>
            (u.teams && u.teams.includes(teamId)) ||
            (teamId === 'unassigned' && !u.teams?.length)
        )
      );
    } else {
      setPersonnel([]);
    }
  };

  const transformPersonnel = !personnel
    ? []
    : personnel.filter((o) =>
        `${o.firstname} ${o.lastname}`
          .toLowerCase()
          .includes(searchPersonnelText.toLowerCase())
      );

  const showBack = selectedUser || personnel;

  const getPanel = () => {
    if (selectedUser) {
      return (
        <>
          <Personnel
            user={selectedUser}
            teams={selectedTeams.filter((t) =>
              selectedUser.teams?.includes(t.id)
            )}
            role={selectedRoles.find((r) => selectedUser.roleId === r.id)}
          ></Personnel>
        </>
      );
    } else if (personnel) {
      return (
        <LightScrollbar className="content">
          <div className="information-block">
            <SearchInput
              placeholder="Search personnel..."
              onChange={(e) => {
                setSearchPersonnelText(e.target.value);
              }}
              light
            />
            <div className="subheading">
              {pluralize('personnel', transformPersonnel.length, true)} listed
            </div>
            <div className="blocks" style={{ marginTop: 0, paddingTop: 0 }}>
              {!!transformPersonnel.length &&
                transformPersonnel.map((unit) => (
                  <div
                    key={unit.id}
                    className="item-container has-hover"
                    style={{
                      cursor: 'pointer',
                      marginRight: '-24px',
                      marginLeft: '-24px',
                      paddingRight: '24px',
                      paddingLeft: '24px',
                    }}
                    onClick={() => {
                      setSelectedUser(unit);
                    }}
                  >
                    <div className="img">
                      <Avatar entity={unit} />
                    </div>
                    <div className="text">
                      {displayName(unit)} <span>{unit.breadcrumb}</span>
                    </div>
                    <DotButton>
                      <FontAwesomeIcon icon="chevron-right" />
                    </DotButton>
                  </div>
                ))}
            </div>
          </div>
        </LightScrollbar>
      );
    } else {
      return (
        <>
          <Tabs
            items={[
              {
                text: `Overview`,
                active: tab === 'overview',
                onClick: () => setTab('overview'),
              },
              {
                text: `Roles`,
                active: tab === 'roles',
                onClick: () => setTab('roles'),
              },
              {
                text: `Teams`,
                active: tab === 'teams',
                onClick: () => setTab('teams'),
              },
            ]}
          />
          <LightScrollbar className="content">
            {tab === 'overview' && (
              <>
                <div className="information-block">
                  <div className="subheading">Mission Area</div>
                  <img
                    className="map"
                    src={selectedMission?.staticMapUrl}
                    alt="mission map"
                  />
                </div>
                <div className="information-block">
                  {!!orders && (
                    <>
                      <div className="subheading">Orders</div>
                      <p>{renderRichText(orders)}</p>
                    </>
                  )}

                  <DotButton
                    className="link"
                    onClick={() =>
                      history.push(
                        endpointConfig.frontendEndpoints.missionOrders.replace(
                          ':missionId',
                          selectedMission?.id
                        )
                      )
                    }
                  >
                    View full orders
                  </DotButton>
                </div>
                <div className="information-block">
                  <div className="subheading">Description</div>
                  <p>{selectedMission?.description}</p>
                </div>
              </>
            )}
            {tab === 'roles' && (
              <>
                <div className="information-block">
                  <SearchInput
                    placeholder="Search roles..."
                    onChange={(e) => {
                      setSearchRolesText(e.target.value);
                    }}
                    light
                  />
                  <div className="subheading">
                    {pluralize('role', transformRoles.length, true)} listed
                  </div>
                  <div
                    className="blocks"
                    style={{ marginTop: 0, paddingTop: 0 }}
                  >
                    {!!transformRoles.length &&
                      transformRoles.map((role) => (
                        <div
                          key={role.id}
                          className="item-container has-hover"
                          style={{
                            cursor: 'pointer',
                            marginRight: '-24px',
                            marginLeft: '-24px',
                            paddingRight: '24px',
                            paddingLeft: '24px',
                          }}
                          onClick={() => {
                            selectPersonnel(role.id, null);
                          }}
                        >
                          <div className="img">
                            <Avatar entity={role} />
                          </div>
                          <div className="text">{role.role}</div>
                          <DotButton>
                            <FontAwesomeIcon icon="chevron-right" />
                          </DotButton>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
            {tab === 'teams' && (
              <>
                <div className="information-block">
                  <SearchInput
                    placeholder="Search teams..."
                    onChange={(e) => {
                      setSearchTeamsText(e.target.value);
                    }}
                    light
                  />
                  <div className="subheading">
                    {pluralize('team', transformTeams.length, true)} listed
                  </div>
                  <div
                    className="blocks"
                    style={{ marginTop: 0, paddingTop: 0 }}
                  >
                    {!!transformTeams.length &&
                      transformTeams.map((team) => (
                        <div
                          key={team.id}
                          className="item-container has-hover"
                          style={{
                            cursor: 'pointer',
                            marginRight: '-24px',
                            marginLeft: '-24px',
                            paddingRight: '24px',
                            paddingLeft: '24px',
                          }}
                          onClick={() => {
                            selectPersonnel(null, team.id);
                          }}
                        >
                          <div className="img">
                            <Avatar entity={team} />
                          </div>
                          <div className="text">{team.team}</div>
                          <DotButton>
                            <FontAwesomeIcon icon="chevron-right" />
                          </DotButton>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </LightScrollbar>
        </>
      );
    }
  };

  return (
    <div className="mission-sidebar">
      <div className="top">
        <div className="heading">
          <div className="text">Mission Details</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <div className="top personnel-info">
        <div>
          <Avatar entity={selectedMission?.organisation} />
          <div>
            {selectedMission?.missionName}
            {!!selectedMission?.start && (
              <span>
                Started {dayjs(selectedMission?.start).format('MMMM D, YYYY')}
              </span>
            )}
          </div>
        </div>
        <DotButton
          className="primary block"
          onClick={() => {
            closeSidebar();
            history.push(
              endpointConfig.frontendEndpoints.mission.replace(
                ':missionId',
                selectedMission?.id
              )
            );
          }}
        >
          View Full Mission
        </DotButton>
      </div>

      {showBack && (
        <DotButton className="back" onClick={exit} ariaLabel="Back">
          <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
        </DotButton>
      )}

      {getPanel()}
    </div>
  );
};

MissionDetails.propTypes = {
  closeSidebar: PropTypes.func,
  selectedMission: missionType,
  selectedRoles: PropTypes.arrayOf(orgRoleType),
  selectedTeams: PropTypes.arrayOf(teamType),
  selectedPersonnel: PropTypes.arrayOf(personnelType),
  isOwner: PropTypes.bool,
  history: historyType,
};
