/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
export const releases = {
  '0.10.0': {
    date: '2022-01-11T10:00',
    enhancements: ['Combined mission view'],
  },
  '0.9.17': {
    date: '2021-11-22T10:00',
    enhancements: ['Font scaling'],
  },
  '0.9.16': {
    date: '2021-11-10T10:00',
    newFeatures: ['Added joyride.'],
  },
  '0.9.15': {
    date: '2021-11-9T10:00',
    housekeeping: ['Added support for new iOS bundle IDs.'],
  },
  '0.9.14': {
    date: '2021-10-21T10:00',
    enhancements: [
      'Decreased size of ME marker.',
      'Click throughs on Team and Role sidebars without needing to go through a sub-menu or hit small click targets',
    ],
  },
  '0.9.13': {
    date: '2021-10-21T10:00',
    enhancements: [
      'Improvements to small screen resolutions and large text scaling.',
    ],
  },
  '0.9.12': {
    date: '2021-10-20T10:00',
    enhancements: ['Censored image support in chat.'],
  },
  '0.9.11': {
    date: '2021-10-18T10:00',
    enhancements: ['Reduce flickering when rendering clusters'],
  },
  '0.9.10': {
    date: '2021-10-18T10:00',
    bugs: [
      'Fix bug with waypoint clusters',
      'Chat threads go into away mode after 5 mins',
    ],
  },
  '0.9.9': {
    date: '2021-10-14T10:00',
    bugs: ['Add archive button back in'],
  },
  '0.9.8': {
    date: '2021-10-14T10:00',
    bugs: ['Refactored scaling method for smaller devices'],
    newFeatures: [
      'Added toasts for tracking and location sharing changes',
      'Teams are updated automatically on mission map',
    ],
  },
  '0.9.7': {
    date: '2021-10-14T10:00',
    newFeatures: ['Comms things'],
  },
  '0.9.6': {
    date: '2021-10-12T10:00',
    newFeatures: ['Many things'],
  },
  '0.9.5': {
    date: '2021-08-24T10:00',
    newFeatures: [],
    housekeeping: ['React component type checking'],
    enhancements: ['Remove mandatory from Call sign'],
    bugs: [],
  },
  '0.9.4': {
    date: '2021-08-18T10:00',
    newFeatures: [],
    housekeeping: ['React component type checking', 'Upgrade all packages'],
    enhancements: [],
    bugs: [
      'Task Details tab open when logging back in',
      'Cancel Task edit white screens',
    ],
  },
  '0.9.3': {
    date: '2021-08-17T10:00',
    newFeatures: [],
    housekeeping: [
      'Code documentation',
      'Code maintenance',
      'React component type checking',
      'Ensure any async functions are cleaned up on component destroy',
    ],
    enhancements: [
      'Admin - Checks to ensure admins can navigate between dashboard and management screens easily',
      'Dashboard - Request confirmation from user when logging out',
    ],
    bugs: ['Notifications sub menu placement'],
  },
  '0.9.2': {
    date: '2021-08-16T10:00',
    newFeatures: [],
    housekeeping: [
      'Code documentation',
      'Code maintenance',
      'React component type checking',
      'Ensure any async functions are cleaned up on component destroy',
    ],
    enhancements: [],
    bugs: ['Org & Users - Breadcrumb reset filter'],
  },
  '0.9.1': {
    date: '2021-08-11T10:00',
    newFeatures: [],
    housekeeping: [
      'Code documentation',
      'Code maintenance',
      'React component type checking',
      'Ensure any async functions are cleaned up on component destroy',
    ],
    enhancements: [],
    bugs: [],
  },
  '0.9.0': {
    date: '2021-08-04T10:00',
    newFeatures: ['New dev environments set up'],
    housekeeping: [],
    enhancements: [],
    bugs: [],
  },
  '0.8.11': {
    date: '2021-08-02T10:00',
    newFeatures: [
      'Update current user profile',
      'Implemented forgotPassword API call',
      'Update current user password',
    ],
    housekeeping: [],
    enhancements: [],
    bugs: [],
  },
  '0.8.10': {
    date: '2021-07-29T10:00',
    newFeatures: [],
    housekeeping: [],
    enhancements: ['Various UX updates for QA'],
    bugs: [],
  },
  '0.8.9': {
    date: '2021-07-28T10:00',
    newFeatures: [],
    housekeeping: [],
    enhancements: [
      'Various UX updates for QA',
      'Flight integration is now animated',
      'Typing indicators on chat list',
    ],
    bugs: [],
  },
  '0.8.8': {
    date: '2021-07-27T10:00',
    newFeatures: [],
    housekeeping: [],
    enhancements: [
      'Various UX updates for QA',
      'You can manually update your location by dragging the "self" marker',
      'Attempt to reduce jitter on map marker movement',
    ],
    bugs: [],
  },
  '0.8.7': {
    date: '2021-07-26T10:00',
    newFeatures: ['New integration interfaces for contextual info'],
    housekeeping: [],
    enhancements: [
      'Various UX updates for QA',
      'Added hover state info as well as sidebar info (for larger content) on bushfire and flight integrations',
      'Added additional location information',
      'If unit is travelling greater than 150km/h and is "stationary" assume they are sitting in a plane',
    ],
    bugs: [],
  },
  '0.8.6': {
    date: '2021-07-23T10:00',
    newFeatures: [],
    housekeeping: [],
    enhancements: ['Various UX updates for QA'],
    bugs: [],
  },
  '0.8.5': {
    date: '2021-07-22T10:00',
    newFeatures: [],
    housekeeping: ['Authentication stability fixes'],
    enhancements: ['Various UX updates for QA'],
    bugs: [],
  },
  '0.8.4': {
    date: '2021-07-21T10:00',
    newFeatures: [],
    housekeeping: [],
    enhancements: [
      'Improvements to UX and stability',
      'waypoint and image markers are now centered on the icon point',
      'can create DM from group thread',
      'temporarily disable group threads',
      'temporarily messages with attachments',
      'add integration polling to integrations API',
    ],
    bugs: [],
  },
  '0.8.3': {
    date: '2021-07-20T10:00',
    newFeatures: ['Add included personnel sidebar'],
    housekeeping: ['Code formatting and organisation'],
    enhancements: ['Updated permission for editing waypoints and image on map'],
    bugs: [
      'Fixed name not showing on create new chat modal',
      'Replace some missing bulma styles',
    ],
  },
  '0.8.2': {
    date: '2021-07-19T10:00',
    newFeatures: [],
    housekeeping: [
      'removed bulma packages from dependencies',
      'org list and details split into separate pages to allow browser back button to work as expected',
      'update scrollbar style on OrgAdmin mission nav',
      'update scrollbar style on orgs and users',
    ],
    enhancements: [
      'Your missions now ordered by status and date',
      'Users renamed to Edit Users in Figma',
      'Add multiple user select behaviours in edit users',
      'Added help text for create/edit mission area and geofences',
      'Managed to get the cursor to switch to crosshair when editing mission area / geofences',
    ],
    bugs: [
      'Fixed show/hide all allies button',
      'prevent toast from blocking sidebar buttons',
    ],
  },
  '0.8.1': {
    date: '2021-07-16T10:00',
    newFeatures: [],
    housekeeping: [
      'created dynamic HTML element class handler',
      'Squashed warnings about mapped element keys in Notifications panel',
    ],
    enhancements: [
      "app now aware if in a map view so we can adjust the position of controls so that they don't obscure map controls",
      'critical messaging inserted back into new comms components',
      'Mission sidebar closes when navigating from all missions to single mission view',
      'Added toast when invite resent to user',
    ],
    bugs: [],
  },
  '0.8.0': {
    date: '2021-07-15T10:00',
    newFeatures: [],
    housekeeping: ['Removed dependency on bulma'],
    enhancements: [
      'Comms threads now has participant list',
      'Org shown in avatar',
      'Can locate unit on map from comms',
      'Avatar updated to two upper case chars (Figma change)',
    ],
  },
  '0.7.1': {
    date: '2021-07-14T10:00',
    newFeatures: [
      'Show personnel who have read thread messages',
      'Allow upload of photos (pending API update)',
    ],
    housekeeping: [
      'Added sorting helpers to wrap Array.prototype.sort()',
      'remove simulated notifications, comms will now use notification centre',
    ],
    enhancements: [
      'Update allies list titles to match changes to Figma',
      'Update Allies sidebar with coloured bar from Figma changes',
      'Miscellaneous performance and stability updates',
      'Always maintain message read status locally',
      'Broadcast mode setting remembered on shutdown',
    ],
    bugs: [
      'Battery level indicator rounded to whole number',
      "When one ally has focus; that ally's clustered markers no longer fade out",
      'fix bug with not being able to insert image',
      'fix bug with not being able to open image',
    ],
  },
  '0.7.0': {
    date: '2021-07-12T10:00',
    newFeatures: ['Migrate from ACS to custom comms services'],
    housekeeping: [],
    enhancements: [],
    bugs: [],
  },
  '0.6.11': {
    date: '2021-07-08T10:00',
    newFeatures: ['Added chat members list'],
    housekeeping: [
      'Centralise any realtime comms activity',
      'Improvements to comms stability',
    ],
    enhancements: [
      'Mission broadcast is always pinned',
      'Avatars are now filled and circle by default',
    ],
    bugs: [],
  },
  '0.6.10': {
    date: '2021-07-07T10:00',
    newFeatures: [],
    enhancements: [
      'POI will be dynamically added to map as they are received by the socket',
      'Caching added to mission comms to improve performance',
      'Updated comms UX',
      'Unread threads now shown',
      'Unread messages shown on Comms button',
      'Typing indicator updated to match mobile',
    ],
    bugs: [],
  },
  '0.6.9': {
    date: '2021-07-06T10:00',
    newFeatures: [
      'Mission Broadcast sidebar on map',
      'Streaming audio player for critical notifications (currently plays a radio station for demo purposes)',
    ],
    enhancements: [
      'Validate images before upload on map view',
      'Validate waypoints before upload',
      'added critical banner to mission personnel sidebar',
      'report battery status for deskop device',
      'used centralised w3w api',
    ],
    bugs: ['Stop task sidebar showing when editing a task'],
  },
  '0.6.8': {
    date: '2021-07-02T10:00',
    newFeatures: [
      '🧪 BETA feature: Highlight diffs in changed orders',
      'Previous states of order sections and tasks can be restored into an active editor',
      'META information on all photos uploaded is now saved',
    ],
    enhancements: [
      'Location activity reported in mission personnel sidebar (if available)',
      'Battery status reporting in mission personnel sidebar (if available)',
    ],
  },
  '0.6.7': {
    date: '2021-07-01T10:00',
    newFeatures: [
      'Show updated sections and task from orders archive',
      'Highlight and scroll to selected section in archive',
      'Show previous version content in place',
    ],
  },
  '0.6.6': {
    date: '2021-07-01T10:00',
    enhancements: [
      'Evened out the organisations list columns',
      'Chat compose message box will now auto-grow as more text is entered',
    ],
    newFeatures: [
      'Add resend invite button to users list',
      'Add support for censored photos',
    ],
    bugs: [
      'Chat message box reset on send to prevent more than one message sending',
      'Fixed error with no info when chat thread created',
      'Chat events are NOT specific to a thread. Events are now filtered for each thread.',
    ],
  },
  '0.6.5': {
    date: '2021-06-30T10:00',
    enhancements: [
      'Increase zoom window of single mission view to accommodate larger missions areas',
    ],
    housekeeping: ['Removed some compiler warnings'],
  },
  '0.6.4': {
    date: '2021-06-30T10:00',
    description:
      "This is the start of the release notes. We think it's a good idea to keep everyone up to date with the changes as they happen.",
    newFeatures: [],
    bugs: [],
    enhancements: ['Swapped state of marker visibility on allies sidebar'],
    housekeeping: [],
    tools: ['Release notes tool'],
    documentation: ['Added these release notes'],
  },
  '0.6.3': {
    date: '2021-06-30T10:00',
    newFeatures: [
      'Added browser icons',
      'Clicking on notifications will switch to map and zoom to the target(s) of the critical message(s)',
      'Personnel in critical situations will be highlighted on map',
      'Added mission broadcast chat thread',
      'Chat send mode can be toggled between needing to press SHIFT + Enter (like Teams) or just the Enter key',
      'Allies sidebar on mission map now shows marker visibility',
    ],
    bugs: ['Critical notification banner was hiding some text on comms'],
    enhancements: [
      'Email validation for login, and users pages now use RFC 5322',
      'When you close an order or task that has pending edits you will be prompted to discard changes',
      'Allies toolbar on mission map changed from hover to hide others to click to toggle',
    ],
    housekeeping: [],
    tools: [
      'Added button to bottom of notification panel to force simulation of critical notifications',
    ],
    documentation: [],
  },
};
