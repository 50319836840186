/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import { useCallback } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { updateLocation } from '../api/missions';
import { getOrgSingle } from '../api/orgs_users';
import { handleError } from '../utils/error';
import { cancellablePromise } from '../utils/promise';
import { useNotification } from './NotificationContext';
import { useUser } from './UserContext';

/**
 * Location state.
 *
 * @param {Boolean} broadcasting are we broadcasting?
 * @param {any} currentLocation current location
 * @param {Function} setBroadcasting toggle broadcasting
 */
export const LocationContext = createContext({
  allowDesktopSharing: false,
  broadcasting: false,
  setBroadcasting: (broadcasting) => {},
  currentLocation: {},
  sendOutLocation: () => {},
});

/**
 * Location provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const LocationProvider = ({ children }) => {
  const { user } = useUser();
  const { lastMissionId } = useNotification();

  // todo: temp-desktop-sharing
  // const [broadcasting, setBroadcasting] = useLocalStorage(
  //   'broadcastMode',
  //   true
  // );
  const broadcasting = false;
  const setBroadcasting = (broadcasting) => {};

  // todo: temp-desktop-sharing
  // const [currentLocation, setCurrentLocation] = useState(null);
  const currentLocation = null;
  const setCurrentLocation = (broadcasting) => {};

  const [allowDesktopSharing, setAllowDesktopSharing] = useState(false);

  const geoLocationWatcherRef = useRef();
  const localLastMissionId = useRef(lastMissionId);
  const localBroadcasting = useRef(broadcasting);

  useEffect(() => {
    localLastMissionId.current = lastMissionId;
    localBroadcasting.current = localBroadcasting;
  });

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(
      getOrgSingle(user.organisationId)
    );
    promise
      .then((org) => {
        setAllowDesktopSharing(org?.allowDesktopSharing || false);
      })
      .catch((e) => {});
    return cancel;
  }, [user.organisationId]);

  const sendOutLocation = useCallback(
    async (position) => {
      // only update for single mission
      if (broadcasting && lastMissionId && (position || currentLocation)) {
        log.debug('locationContext: broadcasting');

        let battery = null;
        if (typeof navigator.getBattery === 'function') {
          battery = await navigator.getBattery();
        }

        await updateLocation(
          lastMissionId,
          user.id,
          (position || currentLocation).coords,
          battery
        );
      }
    },
    [broadcasting, lastMissionId, currentLocation, user.id]
  );

  const contextValue = {
    allowDesktopSharing,
    broadcasting,
    setBroadcasting,
    currentLocation,
    sendOutLocation,
  };

  useEffect(() => {
    const updateCurrentUserPosition = async (position) => {
      log.debug('locationContext: position', 'current user', position);
      if (!position) return;

      setCurrentLocation(position);

      await sendOutLocation(position);

      log.debug('locationContext: watching location');
    };

    if (broadcasting && user?.id && !geoLocationWatcherRef.current) {
      log.debug('locationContext: want location - start listening');

      if (navigator.geolocation) {
        geoLocationWatcherRef.current = navigator.geolocation.watchPosition(
          updateCurrentUserPosition,
          (ex) => {
            if (ex?.message) {
              handleError(ex.message);
              setBroadcasting(false);
            } else {
              log.warn(
                'locationContext: unable to watch location - getting manually',
                ex
              );
              navigator.geolocation.getCurrentPosition(
                updateCurrentUserPosition
              );
            }
          },
          {
            enableHighAccuracy: false,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        log.error(
          'locationContext: Geolocation is not supported by this browser.'
        );
      }
    }

    return () => {
      if (geoLocationWatcherRef.current) {
        log.debug('locationContext: destroy watcher');
        try {
          navigator.geolocation.clearWatch(geoLocationWatcherRef.current);
        } catch {
          log.warn('could not clean up watcher');
        } finally {
          log.debug('locationContext: watcher destroyed');
          geoLocationWatcherRef.current = null;
        }
      }
    };
    //eslint-disable-next-line
  }, [broadcasting, user.id]);

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming Location context.
 *
 * @returns LocationContext
 */
export const useLocation = () => {
  return useContext(LocationContext);
};
