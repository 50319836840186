/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import lockSvg from '../../../assets/svg/lock.svg';
import saveSvg from '../../../assets/svg/save.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Control3 - Date and time picker with DTG fields and 2 action  buttons
 *
 */
export const Control3 = ({
  control,
  handleSetFormData,
  propertiesToUpdate,
  values,
  handleSaveMultipleProperty,
}) => {
  const [fromDate, setFromDate] = useState();
  const [fromTime, setFromTime] = useState();
  const [toDate, setToDate] = useState();
  const [toTime, setToTime] = useState();
  const [fromDtg, setFromDtg] = useState();
  const [toDtg, setToDtg] = useState();
  const [utcFromDate, setUtcFromDate] = useState(values[0]);
  const [utcToDate, setUtcToDate] = useState(values[1]);
  const [locked, setLocked] = useState(false);

  const handleLock = () => {
    setLocked(!locked);
  };

  String.prototype.replaceAt = function (index, character) {
    return (
      this.substr(0, index) + character + this.substr(index + character.length)
    );
  };

  useEffect(() => {
    if (utcFromDate) {
      let _fromDate = moment(utcFromDate)._d;
      let _fromTime = moment(utcFromDate).format('hh:mm');
      setFromDate(_fromDate);
      setFromTime(_fromTime);
    }
    if (utcToDate) {
      let _toDate = moment(utcToDate)._d;
      let _toTime = moment(utcToDate).format('hh:mm');
      setToDate(_toDate);
      setToTime(_toTime);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fromDate && fromTime) {
      let _utcFromDate = new Date(moment(fromDate, 'YYYY-MM-DDTHH:mm').utc());
      _utcFromDate.setHours(fromTime.substring(0, 2));
      _utcFromDate.setMinutes(fromTime.substring(3, 5));
      setUtcFromDate(_utcFromDate);
      handleSetFormData(propertiesToUpdate[0], _utcFromDate);
      let _fromDtg = moment(fromDate).format('DD HHmm MMMYY');
      _fromDtg = _fromDtg.replaceAt(
        3,
        fromTime.substring(0, 2) + fromTime.substring(3, 5)
      );
      _fromDtg =
        _fromDtg.slice(0, 7) + 'Z ' + _fromDtg.slice(8, _fromDtg.length);
      setFromDtg(_fromDtg);
    }
    if (toDate && toTime) {
      let _utcToDate = new Date(moment(toDate, 'YYYY-MM-DDTHH:mm').utc());
      _utcToDate.setHours(toTime.substring(0, 2));
      _utcToDate.setMinutes(toTime.substring(3, 5));
      setUtcToDate(_utcToDate);
      handleSetFormData(propertiesToUpdate[1], _utcToDate);
      let _toDtg = moment(toDate).format('DD HHmm MMMYY');
      _toDtg = _toDtg.replaceAt(
        3,
        toTime.substring(0, 2) + toTime.substring(3, 5)
      );
      _toDtg = _toDtg.slice(0, 7) + 'Z ' + _toDtg.slice(8, _toDtg.length);
      setToDtg(_toDtg);
    }
    // eslint-disable-next-line
  }, [fromDate, fromTime, toDate, toTime]);

  const handleSaveClick = async (e) => {
    await handleSaveMultipleProperty(propertiesToUpdate, [
      utcFromDate,
      utcToDate,
    ]);
  };

  return (
    <div className="control-3">
      <div className="details">
        <div className="row">
          <div className="left"></div>
          <DatePicker
            disabled={locked}
            showIcon={true}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            dateFormat="dd/MM/yyyy"
            customInput={
              <div className="react-datepicker-wrapper">
                <input
                  onChange={(e) => setFromTime(e.target.value)}
                  value={fromDate ? moment(fromDate).format('DD/MM/yyyy') : ''}
                  disabled={locked}
                />
                <FontAwesomeIcon icon="calendar-alt" />
              </div>
            }
          />
          <input
            type="time"
            value={fromTime}
            onChange={(e) => setFromTime(e.target.value)}
            disabled={locked}
          />
          <DatePicker
            disabled={locked}
            showIcon={true}
            selected={toDate}
            onChange={(date) => setToDate(date)}
            dateFormat="dd/MM/yyyy"
            customInput={
              <div className="react-datepicker-wrapper">
                <input
                  onChange={(e) => setToTime(e.target.value)}
                  value={toDate ? moment(toDate).format('DD/MM/yyyy') : ''}
                  disabled={locked}
                />
                <FontAwesomeIcon icon="calendar-alt" />
              </div>
            }
          />
          <input
            type="time"
            value={toTime}
            onChange={(e) => setToTime(e.target.value)}
            disabled={locked}
          />
        </div>
        <div className="row">
          <div className="left"></div>
          <div className="input">
            <input disabled={true} value={fromDtg} />
          </div>
          <div className="to">TO</div>
          <div className="input">
            <input disabled={true} value={toDtg} />
          </div>
        </div>
      </div>
      <div className="action-buttons">
        <div className="row">
          <button onClick={async () => await handleSaveClick()}>
            <img src={saveSvg} alt="save" />
          </button>
        </div>
        <div className="row">
          <button onClick={handleLock}>
            <img src={lockSvg} alt="lock" />
          </button>
        </div>
      </div>
    </div>
  );
};

Control3.propTypes = {
  control: PropTypes.any.isRequired,
  handleSetFormData: PropTypes.func,
  propertiesToUpdate: PropTypes.array,
  values: PropTypes.array,
  handleSaveMultipleProperty: PropTypes.func,
};
