/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { createContext, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';

/**
 * Loading state.
 *
 * @param {Boolean} loading is loading
 * @param {Function} setLoading set is loading
 */
export const LoadingContext = createContext({
  loading: false,
  setLoading: (loading) => {},
});

/**
 * Loading provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const contextValue = {
    loading,
    setLoading,
  };

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming Loading context.
 *
 * @returns LoadingContext
 */
export const useLoading = () => {
  return useContext(LoadingContext);
};
