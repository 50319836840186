/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClassNames } from '../../utils/string';
import './Search.scss';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';

/**
 * Search input
 *
 * @param {string} placeholder input placeholder text
 * @param {EventHandler} onChange on search text changed
 * @param {Boolean} light light theme?
 */
export const SearchInput = ({
  placeholder,
  onChange,
  light = false,
  value,
}) => {
  const [text, setText] = useState('');

  const _onChange = (e) => {
    setText(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    if (!!value) {
      setText(value);
    }
  }, [value]);

  return (
    <div className={getClassNames({ search: true, light })}>
      <input value={text} placeholder={placeholder} onChange={_onChange} />
      {!text ? (
        <FontAwesomeIcon
          icon="search"
          style={{
            color: light ? 'rgba(25, 30, 31, 1)' : 'rgb(208, 208, 208)',
          }}
        />
      ) : (
        <FontAwesomeIcon
          aria-label="Cancel"
          icon="times"
          style={{
            color: 'grey',
            cursor: 'pointer',
          }}
          onClick={() => _onChange({ target: { value: '' } })}
        />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  light: PropTypes.bool,
};
