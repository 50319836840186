/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import microphoneSvg from '../../../assets/svg/microphone.svg';
import lockSvg from '../../../assets/svg/lock.svg';
import saveSvg from '../../../assets/svg/save.svg';
import crosshairSvg from '../../../assets/svg/crosshair.svg';
import gridSvg from '../../../assets/svg/grid.svg';
import atSvg from '../../../assets/svg/at.svg';
import { faPlus, faMultiply } from '@fortawesome/free-solid-svg-icons';
import { useDarkMode } from '../../../context/DarkModeContext';

/**
 * Control2 - Unit picker, location picker, 6 action buttons and duplicate field button
 *
 */
export const Control2 = ({
  control,
  handleSetFormData,
  propertyToUpdate,
  values,
  handleSaveSingleProperty,
}) => {
  const [locked, setLocked] = useState(false);
  const [inputs, setInputs] = useState();
  const [formValues, setFormValues] = useState([]);
  const [locations, setLocations] = useState([]);
  const globalDarkMode = useDarkMode();
  const isDarkMode = globalDarkMode.darkMode;

  useEffect(() => {
    if (!!values && values.length > 0) {
      let _formValues = values;
      let _locations = _formValues.map((v) => {
        return !!v.latitude && !!v.longitude
          ? [v.latitude, v.longitude].join(', ')
          : '';
      });
      setLocations(_locations);
      setFormValues(_formValues);
    } else {
      setFormValues([{ description: '', latitude: null, longitude: null }]);
    }
    // eslint-disable-next-line
  }, []);

  const handleLock = () => {
    setLocked(!locked);
  };

  const handleChange = async (i, e) => {
    let newFormValues = [...formValues];
    if (e.target.name === 'description') {
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
      await handleSetFormData(propertyToUpdate, newFormValues);
    } else {
      let _locations = locations;
      _locations[i] = e.target.value;
      setLocations(_locations);
      setFormValues(newFormValues);
    }
  };

  const handleOnBlur = async (i, e) => {
    let newFormValues = [...formValues];
    if (!!!e.target.value) {
      newFormValues[i]['latitude'] = null;
      newFormValues[i]['longitude'] = null;
    } else {
      const latLng = e.target.value.split(',');
      newFormValues[i]['latitude'] = Number(latLng[0]);
      newFormValues[i]['longitude'] = Number(latLng[1]);
    }
    setFormValues(newFormValues);
    await handleSetFormData(propertyToUpdate, newFormValues);
  };

  const addFormFields = async () => {
    const newFormValues = [
      ...formValues,
      { description: '', latitude: null, longitude: null },
    ];
    setFormValues(newFormValues);
    await handleSetFormData(propertyToUpdate, newFormValues);
  };

  const removeFormFields = async (i) => {
    let newFormValues = [...formValues];
    newFormValues = newFormValues.filter((v, index) => index !== i);
    setFormValues(newFormValues);
    await handleSetFormData(propertyToUpdate, newFormValues);
  };

  const handleSaveClick = async (e) => {
    await handleSaveSingleProperty(propertyToUpdate, formValues);
  };

  useEffect(() => {
    const _inputs = formValues.map((element, index) => (
      <div>
        <div className="body">
          <div
            className="unit-name"
            style={{
              background: locked
                ? isDarkMode
                  ? 'black'
                  : '#dee2ed'
                : isDarkMode
                ? '#191E1f'
                : '#edf3f5',
              border: locked ? '1px solid #d6d9e8' : '1px solid #d6d9e8',
            }}
          >
            <input
              name={'description'}
              disabled={locked}
              value={element.description}
              onChange={(e) => handleChange(index, e)}
              autoComplete="off"
            />
            <button
              disabled={locked}
              style={{
                background: locked
                  ? isDarkMode
                    ? 'black'
                    : '#dee2ed'
                  : isDarkMode
                  ? '#191E1f'
                  : '#edf3f5',
              }}
            >
              <FontAwesomeIcon icon="users" />
            </button>
          </div>
          <div className="at">at</div>
          <div
            className="unit-location"
            style={{
              background: locked
                ? isDarkMode
                  ? 'black'
                  : '#dee2ed'
                : isDarkMode
                ? '#191E1f'
                : '#edf3f5',
              border: locked ? '1px solid #d6d9e8' : '1px solid #d6d9e8',
            }}
          >
            <input
              name={'location'}
              disabled={locked}
              value={locations[index]}
              onChange={(e) => handleChange(index, e)}
              onBlur={(e) => handleOnBlur(index, e)}
              autoComplete="off"
            />
            <button
              disabled={locked}
              style={{
                background: locked
                  ? isDarkMode
                    ? 'black'
                    : '#dee2ed'
                  : isDarkMode
                  ? '#191E1f'
                  : '#edf3f5',
              }}
            >
              <img src={crosshairSvg} alt="crosshair" />
            </button>
          </div>
          {formValues.length > 1 && (
            <div className="remove-button">
              <button disabled={locked} onClick={() => removeFormFields(index)}>
                <FontAwesomeIcon icon={faMultiply} />
              </button>
            </div>
          )}
        </div>
        <div className="info">
          <div className="tip">{control.tip}</div>
        </div>
      </div>
    ));
    setInputs(_inputs);
    // eslint-disable-next-line
  }, [formValues, locations, locked]);

  return (
    <div className="control-2">
      <div className="left">{control.ref}</div>
      <div className="details">
        <div className="label">
          {control.label}{' '}
          {!!control.required && <div className="required"> * Required</div>}{' '}
        </div>
        {inputs}
        <div className="add-button">
          <button disabled={locked} onClick={() => addFormFields()}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
          &nbsp;
          <div className="tip">Add additional Units and HQ/s</div>
        </div>
      </div>
      <div className="action-buttons">
        <div className="row">
          <button disabled={true}>
            <img src={microphoneSvg} alt="microphone" />
          </button>
          <button onClick={handleLock}>
            <img src={lockSvg} alt="lock" />
          </button>
          <button onClick={async () => await handleSaveClick()}>
            <img src={saveSvg} alt="save" />
          </button>
        </div>
        <div className="row">
          <button disabled={true}>
            <img src={crosshairSvg} alt="crosshair" />
          </button>
          <button disabled={true}>
            <img src={gridSvg} alt="grid" />
          </button>
          <button disabled={true}>
            <img src={atSvg} alt="@" />
          </button>
        </div>
      </div>
    </div>
  );
};

Control2.propTypes = {
  control: PropTypes.any.isRequired,
  handleSetFormData: PropTypes.func,
  propertyToUpdate: PropTypes.string,
  values: PropTypes.any,
  handleSaveSingleProperty: PropTypes.func,
};
