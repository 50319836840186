/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { PrivacyLevel } from '../../../enums/privacyLevel';
import {
  organisationType,
  orgRoleType,
  roleType,
  userType,
} from '../../../enums/propTypes';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import {
  PrivacyContent,
  TestCurrentUser,
  TestOrganisation,
} from '../../organisations-and-users/organisations/edit-org/Sharing';
import { DataSharingSection } from '../organisation/OrgDataSharing';
import { PersonnelList } from './UnitInformation';

/**
 * Map Sidebar - mission roles
 * Lists all roles participating in the mission
 *
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any[]} roles roles list
 * @param {any[]} teams teams list
 * @param {Boolean} isOwner are we the owner of the mission
 * @param {Function} updateRoles callback to update allies
 * @param {string} showOnlyRole only show this role
 * @param {Function} setShowOnlyRole callback to only show this role
 * @param {any[]} users users list
 * @param {Function} setUser callback to set user
 */
export const MissionRoles = ({
  closeSidebar,
  roles,
  teams,
  isOwner,
  updateRoles,
  showOnlyRole,
  setShowOnlyRole,
  users,
  setUser,
}) => {
  const [searchText, setSearchText] = useState('');
  const [role, setRole] = useState();

  const searchRoles = (e) => {
    setSearchText(e.target.value);
  };

  const transformRoles = () => {
    return roles.filter(
      (o) => o.role && o.role.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const roleUsers = role ? users.filter((u) => u.roleId === role.id) : [];

  const createPanel = () => {
    if (role) {
      return <SelectedRole role={role} setUser={setUser} users={roleUsers} />;
    } else {
      return (
        <SelectRoleList
          roles={transformRoles()}
          setRole={setRole}
          invite={invite}
          isOwner={isOwner}
          updateRoles={updateRoles}
          showOnlyRole={showOnlyRole}
          setShowOnlyRole={setShowOnlyRole}
        ></SelectRoleList>
      );
    }
  };

  const exit = () => {
    if (role) {
      setRole(null);
    } else {
      closeSidebar();
    }
  };

  const invite = () => {
    closeSidebar();
  };

  const showSearch = !role;

  let title = 'Mission Roles';

  const showBack = !!role;

  return (
    <div className="mission-sidebar mission-allies">
      <div className="top">
        <div className="heading">
          <div className="text">{title}</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>

        {showSearch && (
          <SearchInput
            placeholder="Search roles"
            onChange={searchRoles}
            light
          />
        )}
      </div>

      {showBack && (
        <DotButton className="back" onClick={exit} ariaLabel="Back">
          <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
        </DotButton>
      )}

      {createPanel()}
    </div>
  );
};

MissionRoles.propTypes = {
  closeSidebar: PropTypes.func,
  roles: PropTypes.arrayOf(orgRoleType),
  teams: PropTypes.arrayOf(PropTypes.any),
  isOwner: PropTypes.bool,
  updateRoles: PropTypes.func,
  showOnlyRole: PropTypes.arrayOf(PropTypes.string),
  setShowOnlyRole: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.any),
  setUser: PropTypes.func,
};

/**
 * List of roles
 *
 * @param {any[]} roles list of roles
 * @param {Function} setRole set role
 * @param {Boolean} isOwner is owner of the mission
 * @param {string} missionId mission id
 * @param {Function} updateRoles update allies
 * @param {string} showOnlyRole show only this org id
 * @param {Function} setShowOnlyRole set org id
 */
const SelectRoleList = ({
  roles,
  setRole,
  invite,
  isOwner,
  updateRoles,
  showOnlyRole,
  setShowOnlyRole,
}) => {
  return (
    <>
      <div className="content" style={{ marginBottom: '-20px' }}>
        <h5>{pluralize('role', roles.length, true)} listed</h5>
      </div>
      <LightScrollbar className="content">
        <div className="blocks">
          {roles.length ? (
            <>
              {roles.map((role) => (
                <RoleSection
                  key={role.id}
                  role={role}
                  setRole={setRole}
                  isOwner={isOwner}
                  updateRoles={updateRoles}
                  showOnlyRole={showOnlyRole}
                  setShowOnlyRole={setShowOnlyRole}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </LightScrollbar>
    </>
  );
};

SelectRoleList.propTypes = {
  roles: PropTypes.arrayOf(roleType),
  setRole: PropTypes.func,
  invite: PropTypes.func,
  isOwner: PropTypes.bool,
  updateRoles: PropTypes.func,
  showOnlyRole: PropTypes.arrayOf(PropTypes.string),
  setShowOnlyRole: PropTypes.func,
};

/**
 * Edit data sharing settings
 *
 * @param {any} organisations org to edit data sharing for
 * @param {Function} setDataSharing set data sharing mode
 * @param {string} missionId mission id
 */
export const EditDataSharing = ({
  organisation,
  setDataSharing,
  missionId,
}) => {
  const [privacyLevel, setPrivacyLevel] = useState(
    organisation.privacy || PrivacyLevel.None
  );

  const saveChanges = async () => {
    setDataSharing(privacyLevel);
  };

  return (
    <>
      <TestOrganisation
        organisation={organisation}
        privacyLevel={privacyLevel}
      ></TestOrganisation>
      <LightScrollbar className="content">
        <div className="blocks">
          <h5 style={{ marginTop: '20px', marginBottom: '20px' }}>
            Select Data Sharing Default
          </h5>

          <DataSharingSection
            level={PrivacyLevel.None}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent privacyLevel={PrivacyLevel.None}></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection>
          <DataSharingSection
            level={PrivacyLevel.Partial}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent
              privacyLevel={PrivacyLevel.Partial}
            ></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection>
          <DataSharingSection
            level={PrivacyLevel.Full}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent privacyLevel={PrivacyLevel.Full}></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection>
        </div>
      </LightScrollbar>
      <div className="foot">
        <DotButton className="primary block" onClick={saveChanges}>
          Save Changes
        </DotButton>
      </div>
    </>
  );
};

EditDataSharing.propTypes = {
  organisation: organisationType,
  missionId: PropTypes.string,
  setDataSharing: PropTypes.func,
};

/**
 * Ally role list item
 *
 * @param {any} role role
 * @param {Function} setRole callback to set organisation
 */
const RoleSection = ({ role, setRole }) => {
  return (
    <div
      key={role.id}
      className="item-container clickable has-hover"
      style={{
        marginLeft: '-24px',
        marginRight: '-24px',
        paddingLeft: '20px',
        paddingRight: '24px',
      }}
      onClick={() => {
        if (setRole) {
          setRole(role);
        }
      }}
    >
      <div className="bar" style={{ backgroundColor: role.color }}></div>
      <div className="img">
        <Avatar entity={role} />
      </div>
      <div className="text">{role.role}</div>
      <DotButton ariaLabel="Role Info">
        <FontAwesomeIcon icon="chevron-right" />
      </DotButton>
    </div>
  );
};

RoleSection.propTypes = {
  role: roleType.isRequired,
  setRole: PropTypes.func.isRequired,
};

/**
 * Role details
 *
 * @param {any} role role
 * @param {any[]} users role users
 * @param {Function} setUser callback to set user
 */
export const SelectedRole = ({ role, users, setUser }) => {
  return (
    <div className="content">
      <div className="blocks">
        <h4>Role Details</h4>

        <div className="item-container">
          <div>
            <h5>Role Name</h5>
            <p>{role.role}</p>
          </div>
          <Avatar entity={role} />
        </div>
      </div>

      <h4>Role Users</h4>
      <PersonnelList
        role={[role]}
        users={users}
        setUser={setUser}
        roles={[role]}
      />
    </div>
  );
};

SelectedRole.propTypes = {
  role: roleType,
  users: PropTypes.arrayOf(userType),
  setUser: PropTypes.func,
};
