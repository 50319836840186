/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import toast from 'react-hot-toast';
import * as log from 'loglevel';

/**
 * Log error to log, and display toast
 *
 * @param {Exception} ex exception thrown
 */
export const handleError = (ex) => {
  log.error(ex);
  if (ex?.response?.data?.message) {
    let msg = ex?.response?.data?.message;
    msg = msg.replace(/\.response(\[\d+\])?\./g, '');
    toast.error(msg);
  } else if (typeof ex === 'string') {
    toast.error(ex);
  } else {
    toast.error(ex.message);
  }
};
