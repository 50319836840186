/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DotButton } from '../common/buttons/DotButton';
import { LightScrollbar } from '../common/Scrollbars';
import { Control1 } from './form-controls/Control1';
import { Control4 } from './form-controls/Control4';
import { Control7 } from './form-controls/Control7';
import { useUser } from '../../context/UserContext';
import { useSidebar } from '../../context/SidebarContext';
import { getClassNames } from '../../utils/string';

/**
 * CONTACT Form - Detailed version
 *
 */
export const ContactDetailed = ({ missionId, cancelForm }) => {
  const [hideContent, setHideContent] = useState(false);
  const [hidePanel, setHidePanel] = useState([]);
  const userValue = useUser();
  const sidebars = useSidebar();

  const panels = [
    {
      title: 'Serial and Destination',
      controls: [
        {
          label: 'Enter Names and/or Units to send CONTACT to',
          required: true,
        },
      ],
    },
    {
      title: '1. DTG of Incident',
      controls: [
        {
          label: ['Date', 'Time'],
          required: true,
        },
      ],
    },
    {
      title: '2. Location of Incident',
      controls: [],
    },
    {
      title: '3. Brief Description of Incident',
      controls: [
        {
          required: true,
          tip: 'Enter a brief description of the incident that took place',
        },
      ],
    },
    {
      title: '4. Details of Enemy Forces Involved',
      controls: [
        {
          required: true,
          tip: 'Enter details of any enemy forces that were involved in the incident',
        },
      ],
    },
    {
      title: '5. Enemy Personnel Casualties',
      tip: 'Enter the number of enemy personnel casualties in the incident according to type',
      controls: [],
    },
    {
      title: '6. Enemy Equipment Casualties',
      tip: 'Enter the details of any enemy equipment casualties',
      controls: [
        {
          ref: 'A.',
          label: 'WPN - Destroyed/Captured',
          tip: 'Enter the details of enemy weapons destroyed or captured in the incident (if any)',
        },
        {
          ref: 'B.',
          label: 'VEH - Destroyed/Captured',
          tip: 'Enter the details of enemy vehicles destroyed or captured in the incident (if any)',
        },
        {
          ref: 'C.',
          label: 'Other',
          tip: 'Enter the details of any other enemy equipment destroyed or captured in the incident',
        },
      ],
    },
    {
      title: '7. Friendly Personnel Casualties',
      tip: 'Enter the number of friendly personnel casualties in the incident according to type',
      controls: [
        {
          ref: 'A.KIA',
          tip: 'Enter the number of friendly personnel killed in the incident (if any)',
        },
        {
          ref: 'B.WIA',
          tip: 'Enter the number of friendly personnel wounded in the incident according to urgency of medical assistance. PRI 1 = 1hr to hospital, PRI 2 = 4hrs to hospital, PRI 3 = 24hrs to hospital',
        },
        {
          ref: 'C.MIA',
          tip: 'Enter the number of friendly personnel missing in action after the incident (if any)',
        },
        {
          ref: 'C.PW',
          tip: 'Enter the number of friendly personnel confirmed to be captured in the incident (if any)',
        },
      ],
    },
    {
      title: '8. Friendly Equipment Casualties',
      tip: 'Enter the details of any enemy equipment casualties',
      controls: [
        {
          ref: 'A.',
          label: 'WPN - Destroyed/Captured',
          tip: 'Enter the details of friendly weapons destroyed or captured in the incident (if any)',
        },
        {
          ref: 'B.',
          label: 'VEH - Destroyed/Captured',
          tip: 'Enter the details of friendly vehicles destroyed or captured in the incident (if any)',
        },
        {
          ref: 'C.',
          label: 'Other',
          tip: 'Enter the details of any other friendly equipment destroyed or captured in the incident',
        },
      ],
    },
    {
      title: "9. Commander's Evaluation/Intention",
      controls: [
        {
          label: "Commander's Evaluation/Intention",
          required: true,
          tip: "Enter the commander's evaluation of the incident as well as future intentions in response to the incident",
        },
      ],
    },
  ];

  return (
    <div className="form">
      <div className={getClassNames({ header: true, important: true })}>
        <div className="left">
          <div className="avatar">CT</div>
          <div className="form-title">CONTACT/INCIDENT REPORT - DETAILED</div>
        </div>
        <div className="right">
          <div className="last-updated">Last updated:</div>
          <div className="chevron" onClick={() => setHideContent(!hideContent)}>
            <FontAwesomeIcon
              icon={!hideContent ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
      </div>
      <LightScrollbar>
        <div
          className="form-content"
          style={{ display: hideContent && 'none' }}
        >
          {!!panels &&
            panels.map((p, idx) => {
              return (
                <div className="panel" key={idx}>
                  <div className="panel-header">
                    <div className="left">
                      <div className="panel-title">{p.title}</div>
                      {!!p.tip && <div className="panel-tip">{p.tip}</div>}
                    </div>
                    <div className="right">
                      {/* <div className="last-updated">Last updated:</div> */}
                      <div
                        className="chevron"
                        onClick={(e) => {
                          e.preventDefault();
                          const hidePanelCopy = [...hidePanel];
                          hidePanelCopy[idx] = !hidePanel[idx];
                          setHidePanel(hidePanelCopy);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={!hidePanel[idx] ? 'chevron-up' : 'chevron-down'}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="panel-content"
                    style={{ display: hidePanel[idx] && 'none' }}
                  >
                    {idx === 0 && (
                      <Control4
                        control={p.controls[0]}
                        user={userValue}
                        missionId={missionId}
                      />
                    )}
                    {idx === 1 && (
                      <Control7
                        control={p.controls[0]}
                        hideActionButtons={true}
                        defaultLocked={true}
                      />
                    )}
                    {/* {idx === 2 && <Control10 control={p.controls[0]} />} */}
                    {(idx === 3 ||
                      idx === 4 ||
                      idx === 6 ||
                      idx === 8 ||
                      idx === 9) &&
                      p.controls.map((c) => {
                        return <Control1 control={c} />;
                      })}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="footer">
          <div className="button-panel">
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                sidebars.closeAll();
                cancelForm();
              }}
            >
              DISCARD FORM
            </DotButton>
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              SAVE AS DRAFT
            </DotButton>
            <DotButton
              className="primary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              SEND FORM
            </DotButton>
          </div>
        </div>
      </LightScrollbar>
    </div>
  );
};

ContactDetailed.propTypes = {
  missionId: PropTypes.any.isRequired,
  cancelForm: PropTypes.func.isRequired,
};
