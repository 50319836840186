import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'cropperjs/dist/cropper.css';
import { useRef } from 'react';
import Cropper from 'react-cropper';
import { fileConfig } from '../../config/fileConfig';
import { DotButton } from './buttons/DotButton';
import './Crop.scss';
import { PropTypes } from 'prop-types';

/**
 * Image cropper.
 *
 * Cropper is a react wrapper for cropperjs. Documentation: https://github.com/fengyuanchen/cropperjs.
 *
 * @param {Function} close close cropper
 * @param {string} img image to crop
 * @param {Function} setImage callback to set cropped image
 */
export const Crop = ({ close, img, setImage }) => {
  const cropperRef = useRef();

  const onSubmit = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    const cropperOptions = { width: 120, height: 120, imageSmoothingEnabled: true, imageSmoothingQuality: 'high' }; //prettier-ignore

    const canvas = cropper.getCroppedCanvas(cropperOptions);

    const url = canvas.toDataURL('image/jpeg', fileConfig.quality);
    canvas.toBlob((blob) => {
      setImage({
        url,
        blob,
      });

      close();
    });
  };

  const rotate = (deg) => {
    cropperRef.current.cropper.rotate(deg);
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content modal-cropper">
        <DotButton className="exit" onClick={() => close()} ariaLabel="Close">
          <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
        </DotButton>
        <div className="box">
          <Cropper
            src={img}
            style={{ height: 500, width: 500 }}
            aspectRatio={1 / 1}
            guides={true}
            ref={cropperRef}
            checkOrientation={true}
            scalable={true}
            rotatable={true}
            movable={true}
          />
          <div className="rotate">
            <DotButton onClick={() => rotate(-90)} className="secondary">
              Rotate Left
            </DotButton>
            <DotButton onClick={() => rotate(90)} className="secondary">
              Rotate Right
            </DotButton>
          </div>
          <DotButton onClick={() => onSubmit()} className="primary block">
            Crop
          </DotButton>
        </div>
      </div>
    </div>
  );
};

Crop.propTypes = {
  close: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
};
