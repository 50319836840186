/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

export const SymbolSet = Object.freeze({
  Air: '10030100000000000000',
  SeaSurface: '10033000000000000000',
  LandUnit: '10031000000000000000',
  LandEquipment: '10031500000000000000',
  LandInstallation: '10032000000000000000',
});
