import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { deleteRole } from '../../../api/roles';
import { orgRoleType } from '../../../enums/propTypes';
import { handleError } from '../../../utils/error';
import { getClassNames } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { MenuButton } from '../../common/Menu';
import { ConfirmModal } from '../../modals/ConfirmModal';
import './GroupPanel.scss';

/**
 * Renders the Role panels on the side of org users page
 *
 * @param {any} role role
 * @param {bool} selected selected?
 * @param {Function} updateRoles update selected roles. pass null to reset. pass undefined to refresh.
 */
export const RolePanel = ({ role, updateRoles, editRole, selected }) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  return (
    <div
      className={getClassNames({
        'group-render': true,
        selected,
      })}
    >
      <DotButton
        className="group-render-select"
        onClick={() => updateRoles(role)}
      >
        <Avatar entity={role} />
        <div className="group-render-info">
          <div className="group-render-info-title">{role.role}</div>
          <div className="group-render-info-members">
            {`${pluralize('User', role.users, true)}`}
          </div>
        </div>
      </DotButton>
      <MenuButton
        position="right"
        options={[
          {
            text: 'Edit Role',
            ariaLabel: 'Edit Role',
            icon: 'pencil-alt',
            onClick: () => {
              editRole(role);
            },
          },
          {
            text: 'Delete Role',
            ariaLabel: 'Delete Role',
            icon: 'trash',
            onClick: () => {
              setDeleteConfirm(true);
            },
          },
        ]}
        ariaLabel="Role Menu"
      >
        <FontAwesomeIcon icon="bars"></FontAwesomeIcon>
      </MenuButton>

      <ConfirmModal
        isActive={!!deleteConfirm}
        exit={() => {
          setDeleteConfirm(false);
        }}
        icon="exclamation-circle"
        modalId="delete-group"
        title={['Delete the ', <strong>{role.role}</strong>, ' role?']}
        buttons={[
          {
            text: 'Yes, Delete Role',
            type: 'primary',
            callback: async () => {
              try {
                await deleteRole(role.organisationId, role.id);
                setDeleteConfirm(false);
                updateRoles({ ...role, deleted: true });
              } catch (err) {
                handleError(err);
              }
            },
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => {
              setDeleteConfirm(false);
            },
          },
        ]}
      >
        ALL organisation admins and users will no longer be able to access this
        role. All users assigned to this role will be unassigned from this role.
      </ConfirmModal>
    </div>
  );
};

RolePanel.propTypes = {
  role: orgRoleType,
  updateRoles: PropTypes.func,
};
