/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import ReactSwitch from 'react-switch';
import { useUser } from '../../../context/UserContext';
import { useDarkMode } from '../../../context/DarkModeContext';
import { Role } from '../../../enums/role';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { capitalize } from '../../../utils/string';
import { secrets } from '../../../config/secrets';
import svgShapes from '../../../assets/svg/shapes.svg';
import svgMapObjects from '../../../assets/svg/map-objects.svg';
import svgM2525 from '../../../assets/svg/m2525.svg';
import svgInterface from '../../../assets/svg/interface.svg';
import svgPerformance from '../../../assets/svg/performance.svg';
import ReactSlider from 'react-slider';
import ms from 'milsymbol';
import './MissionSidebar.scss';

/**
 * Map Sidebar - mission integrations
 * Lists all integrations enabled for the mission
 *
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any} layerSettings mission layer settings
 * @param {boolean} canEdit whether the user has permission to edit
 * @param {number} milStdIconSize milStd icon size
 * @param {Function} setMilStdIconSize callback to set milStd icon size
 */
export const MissionLayerSettings = ({
  closeSidebar,
  layerSettings,
  canEdit,
  milStdIconSize,
  setMilStdIconSize,
}) => {
  const { user } = useUser();
  const globalDarkMode = useDarkMode();
  const isDarkMode = globalDarkMode.darkMode;
  const symbolSvg = new ms.Symbol(10031000000000000000, {})
    .asCanvas()
    .toDataURL();

  const [temporaryIconSize, setTemporaryIconSize] = useState(milStdIconSize);

  return (
    <div className="mission-layers mission-sidebar">
      <div className="top">
        <div className="heading">
          <div className="text">Settings</div>
          <DotButton
            className="exit"
            onClick={() => {
              closeSidebar();
            }}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <LightScrollbar className="content">
        <div className="blocks gap pad">
          <div
            className="block-panel"
            style={{ borderBottom: 'none', padding: '0 0 0 0' }}
          >
            <div className="panel-head">
              <img
                src={svgMapObjects}
                alt="shapes"
                style={{
                  height: '25px',
                  width: '25px',
                  verticalAlign: 'bottom',
                }}
              />
              &nbsp;&nbsp; Map Objects
            </div>
            {/* <div className="row">
              <label
                htmlFor={'switch-mission-area'}
                style={{ cursor: 'pointer' }}
              >
                Marker clustering
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-mission-area'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.clustering.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.clustering.set(checked);
                    }}
                    disabled={true}
                  ></ReactSwitch>
                </label>
              </div>
            </div> */}
            {/* <br /> */}
            <div className="panel-sub-head">
              <FontAwesomeIcon
                icon="user-friends"
                style={{ height: '15px', width: '15px' }}
              />
              &nbsp; &nbsp;User Markers
            </div>
            <div className="row">
              <label htmlFor={'show-user-labels'} style={{ cursor: 'pointer' }}>
                Always show user labels
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'show-user-labels'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.personnelLabels.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.personnelLabels.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            <div className="row">
              <label htmlFor={'show-user-tracks'} style={{ cursor: 'pointer' }}>
                Always show user location tracks
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'show-user-tracks'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.tracks.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.tracks.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            <br />
            <div className="panel-sub-head">
              <FontAwesomeIcon
                icon="map-pin"
                style={{ height: '15px', width: '15px' }}
              />
              &nbsp; &nbsp;POI Markers
            </div>
            <div className="row">
              <label htmlFor={'waypoint-markers'} style={{ cursor: 'pointer' }}>
                Waypoint markers
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'waypoint-markers'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.waypoints.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.waypoints.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            <div className="row">
              <label htmlFor={'image-markers'} style={{ cursor: 'pointer' }}>
                Image markers
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'image-markers'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.photos.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.photos.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            <br />
            <div className="panel-sub-head">
              <img
                src={svgShapes}
                alt="shapes"
                style={{
                  height: '20px',
                  width: '20px',
                  verticalAlign: 'bottom',
                }}
              />
              &nbsp; &nbsp;Shapes/Annotations
            </div>
            <div className="row">
              <label
                htmlFor={'switch-mission-area'}
                style={{ cursor: 'pointer' }}
              >
                {capitalize(secrets.EVENT_NAME)} area
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-mission-area'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.missionArea.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.missionArea.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            <div className="row">
              <label
                htmlFor={'switch-mission-geofences'}
                style={{ cursor: 'pointer' }}
              >
                Shapes
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-mission-geofences'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.missionGeofences.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.missionGeofences.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            {layerSettings.missionGeofences.get && (
              <div className="row">
                <label
                  htmlFor={'switch-mission-geofence-labels'}
                  style={{ cursor: 'pointer' }}
                >
                  Shape labels
                </label>
                <div className="switch">
                  <label>
                    <ReactSwitch
                      id={'switch-mission-geofence-labels'}
                      height={28}
                      width={56}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      checked={layerSettings.missionGeofenceLabels.get || false}
                      onColor={'#2c7691'}
                      offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                      onChange={(checked) => {
                        layerSettings.missionGeofenceLabels.set(checked);
                      }}
                    ></ReactSwitch>
                  </label>
                </div>
              </div>
            )}
            <div className="row">
              <label
                htmlFor={'switch-mission-annotations'}
                style={{ cursor: 'pointer' }}
              >
                Annotations
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-mission-annotations'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.missionAnnotations.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.missionAnnotations.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            {layerSettings.missionAnnotations.get && (
              <div className="row">
                <label
                  htmlFor={'switch-mission-annotation-labels'}
                  style={{ cursor: 'pointer' }}
                >
                  Annotation labels
                </label>
                <div className="switch">
                  <label>
                    <ReactSwitch
                      id={'switch-mission-annotation-labels'}
                      height={28}
                      width={56}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      checked={
                        layerSettings.missionAnnotationLabels.get || false
                      }
                      onColor={'#2c7691'}
                      offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                      onChange={(checked) => {
                        layerSettings.missionAnnotationLabels.set(checked);
                      }}
                    ></ReactSwitch>
                  </label>
                </div>
              </div>
            )}
            <div className="row">
              <label
                style={{
                  marginTop: '7px',
                }}
              >
                Display labels at zoom level
              </label>
            </div>
            <div className="row">
              <ReactSlider
                defaultValue={layerSettings.labelZoomThreshold.get}
                min={1.0}
                max={22.0}
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                onAfterChange={(value) => {
                  layerSettings.labelZoomThreshold.set(value);
                }}
                renderThumb={(props, state) => (
                  <div {...props}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {state.valueNow}
                    </div>
                  </div>
                )}
              />
            </div>
            <br />
            <br />
            <br />
          </div>

          <div
            className="block-panel"
            style={{ borderBottom: 'none', padding: '0 0 0 0' }}
          >
            <div className="panel-head">
              <img
                src={svgM2525}
                alt="shapes"
                style={{
                  height: '25px',
                  width: '25px',
                  verticalAlign: 'bottom',
                }}
              />
              &nbsp;&nbsp;MIL-STD-2525 Symbols
            </div>
            <div className="row">
              <label
                htmlFor={'switch-mil-std-symbols'}
                style={{ cursor: 'pointer' }}
              >
                Display MIL-STD-2525 symbols
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-mil-std-symbols'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.symbols.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.symbols.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            <div className="row">
              <label
                htmlFor={'switch-display-icon'}
                style={{ cursor: 'pointer' }}
              >
                Display icon in symbol
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-display-icon'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.displayIcon.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.displayIcon.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            <div className="row" style={{ marginBottom: '8px' }}>
              <label
                htmlFor={'switch-display-info'}
                style={{ cursor: 'pointer' }}
              >
                Display information around symbol
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-display-info'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.displayInfo.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.displayInfo.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>

            {!!layerSettings.displayInfo.get && (
              <>
                <div className="row">
                  <label>Display information at zoom level</label>
                </div>
                <div className="row">
                  <ReactSlider
                    defaultValue={layerSettings.symbolDetailsZoomThreshold.get}
                    min={1.0}
                    max={22.0}
                    step={0.1}
                    className="horizontal-slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    onAfterChange={(value) => {
                      layerSettings.symbolDetailsZoomThreshold.set(value);
                    }}
                    renderThumb={(props, state) => (
                      <div {...props}>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {state.valueNow}
                        </div>
                      </div>
                    )}
                  />
                </div>
                <br />
                <br />
                <br />
              </>
            )}

            <div className="row">
              <label>Symbol size</label>
            </div>
            <div
              style={{
                justifyContent: 'center',
                minHeight: '100px',
                minWidth: '100px',
              }}
              className="row"
            >
              <img
                style={{
                  height: temporaryIconSize,
                  width: temporaryIconSize,
                }}
                src={symbolSvg}
                alt={'Symbol'}
              />
            </div>
            <div className="row">
              <ReactSlider
                defaultValue={temporaryIconSize}
                min={20}
                max={50}
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                onChange={(value) => setTemporaryIconSize(value)}
                onAfterChange={() => {
                  setMilStdIconSize(temporaryIconSize);
                }}
                renderThumb={(props, state) => (
                  <div {...props}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {state.valueNow}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          <br />
          <br />
          <div
            className="block-panel"
            style={{ borderBottom: 'none', padding: '20px 0 0 0' }}
          >
            <div className="panel-head">
              {' '}
              <img
                src={svgInterface}
                alt="shapes"
                style={{
                  height: '25px',
                  width: '25px',
                  verticalAlign: 'bottom',
                }}
              />
              &nbsp;&nbsp;Interface
            </div>
            <div className="row">
              <label htmlFor={'switch-dark-mode'} style={{ cursor: 'pointer' }}>
                Dark toolbars
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-dark-mode'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.darkMode.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.darkMode.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            <div className="row">
              <label htmlFor={'mgrs'} style={{ cursor: 'pointer' }}>
                Display location as MGRS
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'mgrs'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.mgrs.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.mgrs.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
          </div>

          <div
            className="block-panel"
            style={{ borderBottom: 'none', padding: '0 0 0 0' }}
          >
            <div className="panel-head">
              {' '}
              <img
                src={svgPerformance}
                alt="shapes"
                style={{
                  height: '25px',
                  width: '25px',
                  verticalAlign: 'bottom',
                }}
              />
              &nbsp;&nbsp;Performance
            </div>
            <div className="row">
              <label
                htmlFor={'switch-animations'}
                style={{ cursor: 'pointer' }}
              >
                Animations
              </label>
              <div className="switch">
                <label>
                  <ReactSwitch
                    id={'switch-animations'}
                    height={28}
                    width={56}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={layerSettings.animate.get || false}
                    onColor={'#2c7691'}
                    offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                    onChange={(checked) => {
                      layerSettings.animate.set(checked);
                    }}
                  ></ReactSwitch>
                </label>
              </div>
            </div>
            {!!user?.accessRoles?.includes(Role.SysAdmin) && (
              <>
                <div className="row">
                  <label
                    htmlFor={'switch-simulate-markers'}
                    style={{ cursor: 'pointer' }}
                  >
                    Simulate markers
                  </label>
                  <div className="switch">
                    <label>
                      <ReactSwitch
                        id={'switch-simulate-markers'}
                        height={28}
                        width={56}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        checked={layerSettings.simulate.get || false}
                        onColor={'#2c7691'}
                        offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                        onChange={(checked) => {
                          layerSettings.simulate.set(checked);
                        }}
                      ></ReactSwitch>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <label
                    htmlFor={'switch-debugging'}
                    style={{ cursor: 'pointer' }}
                  >
                    Debugging overlay
                  </label>
                  <div className="switch">
                    <label>
                      <ReactSwitch
                        id={'switch-debugging'}
                        height={28}
                        width={56}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        checked={layerSettings.debug.get || false}
                        onColor={'#2c7691'}
                        offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                        onChange={(checked) => {
                          layerSettings.debug.set(checked);
                        }}
                      ></ReactSwitch>
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </LightScrollbar>
    </div>
  );
};

MissionLayerSettings.propTypes = {
  closeSidebar: PropTypes.func,
  layerSettings: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  milStdIconSize: PropTypes.number,
  setMilStdIconSize: PropTypes.func,
};
