// External Modules
// Icons
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  validateMissionInvitation,
  respondToMissionInvite,
} from '../../../../api/missions';
import { endpointConfig } from '../../../../config/endpoint_config';
import { historyType } from '../../../../enums/propTypes';
import { handleError } from '../../../../utils/error';
import { cancellablePromise } from '../../../../utils/promise';
import { DotButton } from '../../../common/buttons/DotButton';
import { TextArea } from '../../../common/TextArea';
import { Welcome } from '../../../generic-components/Welcome';
// Style Sheets
import './Invitation.scss';

/**
 * Accept mission invitiation page
 *
 * @param {any} history react router
 */
export const DeclineInvitation = ({ history }) => {
  const [invite, setInvite] = useState();
  const [reason, setReason] = useState('');

  const { id } = useParams();

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(verifyInvitation(id));
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  const verifyInvitation = async (id) => {
    log.debug('verifying', id);
    try {
      const res = await validateMissionInvitation(id);
      setInvite(res);
    } catch (err) {
      setInvite(null);
      // Debug line
      log.error(`Something went wrong with invitation validation`, err);
    }
  };

  const declineInvitation = async (e) => {
    e.preventDefault();

    if (invite) {
      try {
        await respondToMissionInvite(invite.mission.id, id, false, reason);

        toast.success(
          `You have declined the invitation to ${invite.mission.missionName}`
        );

        history.push(endpointConfig.frontendEndpoints.missionNav);
      } catch (err) {
        handleError(err);
      } finally {
      }
    }
  };

  return (
    <Welcome>
      <div className="invitation">
        {invite ? (
          <div className="invitation-form">
            <div className="invitation-text">
              <div className="invitation-text-1">
                {['Decline the invite to join ', invite?.mission?.missionName]}
              </div>
              <div className="invitation-text-3">
                <strong>{invite?.senderOrganisation?.organisationName}</strong>
                &nbsp;has invited you to join their mission.
                <br />
                Please provide a reason for declining the invite.
              </div>
            </div>
            <form>
              <TextArea
                label="Reason"
                placeholder="Reason for declining this mission"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required={true}
              />

              <DotButton
                type="submit"
                className="primary "
                onClick={declineInvitation}
                disabled={!reason}
              >
                Decline Mission
              </DotButton>
            </form>
          </div>
        ) : (
          <div> Invalid invitation</div>
        )}
      </div>
    </Welcome>
  );
};

DeclineInvitation.propTypes = { history: historyType };
