/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, { useState, useRef, useEffect } from 'react';
import { getClassNames } from '../../utils/string';
import { Select } from './Select';
import { TextArea } from './TextArea';
import { Expandable } from './Expandable';
import { Input } from './Input';
import {
  decodeMilspecSymbolCode,
  encodeMilspecSymbolCode,
} from '../../utils/milspec';
import toast from 'react-hot-toast';
import { DotButton } from '../common/buttons/DotButton';
import { updateMilspecSymbol, resetMilspecSymbol } from '../../api/missions';
import { CheckBox } from './CheckBox';
import { addSymbol, updateSymbol } from '../../api/missions';
import { cancellablePromise } from '../../utils/promise';
/**
 * Milspec Options
 *
 * @param {object} milspec milspec data
 * @param {object} formData data for the dropdown lists
 * @param {object} symbolSet symbol set for the given code
 * @param {string} symbolSetId symbol set id
 * @param {object} echelonFormData echelon form data specific to symbol set
 * @param {object} mod1FormData icon modifier 1 form data specific to symbol set
 * @param {object} mod2FormData icon modifier 2 form data specific to symbol set
 * @param {string} id id of the integration marker
 * @param {boolean} insertedSymbol flag to determine whether inserted symbol or integration
 * @param {function} onChangeSymbol callback to set symbol preview
 * @param {function} refreshIntegrations function to redraw symbols
 * @param {function} refreshSymbols function to redraw symbols
 * @param {object} symbol symbol that was clicked
 * @param {function} setId callback to set ID in MissionSymbol
 */
export const MilspecOptions = ({
  milspec,
  formData,
  symbolSet,
  symbolSetId,
  echelonFormData,
  mod1FormData,
  mod2FormData,
  id,
  insertedSymbol,
  onChangeSymbol,
  refreshIntegrations,
  refreshSymbols,
  symbol,
  setId,
}) => {
  //Destructures form data object into usable variables
  const {
    standardIdentity1,
    standardIdentity2,
    headquarters,
    status,
    engagement,
  } = formData || {};

  //State hooks - set to existing value or empty string
  const [comments, setComments] = useState(
    milspec?.options?.staffComments || ''
  );
  const [additionalInfo, setAdditionalInfo] = useState(
    milspec?.options?.additionalInformation || ''
  );
  const [commonIdentifier, setCommonIdentifier] = useState(
    milspec?.options?.commonIdentifier || ''
  );
  const [iffSif, setIffSif] = useState(milspec?.options?.iffSif || '');
  const [direction, setDirection] = useState(milspec?.options?.direction || '');
  const [uniqueDesignation, setUniqueDesignation] = useState(
    milspec?.options?.uniqueDesignation || ''
  );
  const [type, setType] = useState(milspec?.options?.type || '');
  const [location, setLocation] = useState(milspec?.options?.location || '');
  const [speed, setSpeed] = useState(milspec?.options?.speed || '');
  const [altitudeDepth, setAltitudeDepth] = useState(
    milspec?.options?.altitudeDepth || ''
  );
  const [guardedUnit, setGuardedUnit] = useState(
    milspec?.options?.guardedUnit || ''
  );
  const [signatureEquipment, setSignatureEquipment] = useState(
    milspec?.options?.signatureEquipment || ''
  );
  const [hostile, setHostile] = useState(milspec?.options?.hostile || '');
  const [higherFormation, setHigherFormation] = useState(
    milspec?.options?.higherFormation || ''
  );
  const [specialHeadquarters, setSpecialHeadquarters] = useState(
    milspec?.options?.specialHeadquarters || ''
  );
  const [quantity, setQuantity] = useState(milspec?.options?.quantity || '');
  const [equipmentTeardownTime, setEquipmentTeardownTime] = useState(
    milspec?.options?.equipmentTeardownTime || ''
  );
  const [dtg, setDtg] = useState(milspec?.options?.dtg || '');
  const [symbolId, setSymbolId] = useState(symbol?.id || null);

  //Decode the incoming symbol
  const decodedSymbol = decodeMilspecSymbolCode(milspec.symbol);

  //Define refs and set initial value to state variables
  const selectedStandardIdentity1 = useRef(decodedSymbol.standardIdentity1);
  const selectedStandardIdentity2 = useRef(decodedSymbol.standardIdentity2);
  const selectedEntity = useRef(decodedSymbol.entity);
  const selectedModifier1 = useRef(decodedSymbol.modifier1);
  const selectedModifier2 = useRef(decodedSymbol.modifier2);
  const selectedStatus = useRef(decodedSymbol.status);
  const selectedHeadquarters = useRef(decodedSymbol.headquarters);
  const selectedEchelon = useRef(decodedSymbol.echelon);
  const commentsRef = useRef(comments);
  const additionalInfoRef = useRef(additionalInfo);
  const iffSifRef = useRef(iffSif);
  const directionRef = useRef(direction);
  const uniqueDesignationRef = useRef(uniqueDesignation);
  const typeRef = useRef(type);
  const locationRef = useRef(location);
  const speedRef = useRef(speed);
  const altitudeDepthRef = useRef(altitudeDepth);
  const guardedUnitRef = useRef(guardedUnit);
  const higherFormationRef = useRef(higherFormation);
  const specialHeadquartersRef = useRef(specialHeadquarters);
  const commonIdentifierRef = useRef(commonIdentifier);
  const signatureEquipmentRef = useRef(signatureEquipment);
  const quantityRef = useRef(quantity);
  const hostileRef = useRef(hostile);
  const equipmentTeardownTimeRef = useRef(equipmentTeardownTime);
  const dtgRef = useRef(dtg);
  const reinforcedReduced = useRef(milspec?.options?.reinforcedReduced);
  const reliabilityRating = useRef(
    milspec?.options?.evaluationRating?.charAt(0) || 'A'
  );
  const credibilityRating = useRef(
    milspec?.options?.evaluationRating?.charAt(1) || '1'
  );
  const combatEffectiveness = useRef(
    milspec?.options?.combatEffectiveness || 'FO'
  );
  const platformType = useRef(milspec?.options?.platformType || '');
  const specialDesignator = useRef(milspec?.options?.specialDesignator || '');
  const selectedEngagementState = useRef(
    milspec?.engagement?.engagementState || ''
  );
  const selectedRemoteEngagements = useRef(
    milspec?.engagement?.remoteAndLocalEngagements || ''
  );
  const selectedWeaponAssignment = useRef(
    milspec?.engagement?.weaponAssignmentOrDeployment || ''
  );

  //Runs when milspec changes
  useEffect(() => {
    const decodedSymbol = decodeMilspecSymbolCode(milspec.symbol);
    selectedStandardIdentity1.current = decodedSymbol.standardIdentity1;
    selectedStandardIdentity2.current = decodedSymbol.standardIdentity2;
    selectedEntity.current = decodedSymbol.entity;
    selectedModifier1.current = decodedSymbol.modifier1;
    selectedModifier2.current = decodedSymbol.modifier2;
    selectedStatus.current = decodedSymbol.status;
    selectedHeadquarters.current = decodedSymbol.headquarters;
    selectedEchelon.current = decodedSymbol.echelon;
    commentsRef.current = comments;
    additionalInfoRef.current = additionalInfo;
    iffSifRef.current = iffSif;
    directionRef.current = direction;
    uniqueDesignationRef.current = uniqueDesignation;
    typeRef.current = type;
    locationRef.current = location;
    speedRef.current = speed;
    altitudeDepthRef.current = altitudeDepth;
    guardedUnitRef.current = guardedUnit;
    higherFormationRef.current = higherFormation;
    specialHeadquartersRef.current = specialHeadquarters;
    commonIdentifierRef.current = commonIdentifier;
    signatureEquipmentRef.current = signatureEquipment;
    quantityRef.current = quantity;
    hostileRef.current = hostile;
    equipmentTeardownTimeRef.current = equipmentTeardownTime;
    dtgRef.current = dtg;
    reinforcedReduced.current = milspec?.options?.reinforcedReduced || '';
    reliabilityRating.current =
      milspec?.options?.evaluationRating?.charAt(0) || 'A';
    credibilityRating.current =
      milspec?.options?.evaluationRating?.charAt(1) || '1';
    combatEffectiveness.current = milspec?.options?.combatEffectiveness || 'FO';
    platformType.current = milspec?.options?.platformType || '';
    specialDesignator.current = milspec?.options?.specialDesignator || '';
    selectedEngagementState.current =
      milspec?.engagement?.engagementState || '';
    selectedRemoteEngagements.current =
      milspec?.engagement?.remoteAndLocalEngagements || '';
    selectedWeaponAssignment.current =
      milspec?.engagement?.weaponAssignmentOrDeployment || '';
    // eslint-disable-next-line
  }, [milspec]);

  //Runs when symbol is first created
  useEffect(() => {
    const stub = async () => {
      if (!!!symbol && !!insertedSymbol) {
        await handleSaveSymbol();
      }
    };
    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  //Runs when symbol changes
  useEffect(() => {
    if (!!!symbol) {
      setSymbolId(null);
    }
    // eslint-disable-next-line
  }, [symbol]);

  //Runs when location changes
  useEffect(() => {
    locationRef.current = milspec.options.location;
    setLocation(milspec.options.location);
    // eslint-disable-next-line
  }, [milspec.options.location]);

  //handles the updating of symbol preview then initiates save
  const handleUpdateSymbol = async () => {
    //Get new data
    const data = {
      id: id,
      standardIdentity1: selectedStandardIdentity1.current,
      standardIdentity2: selectedStandardIdentity2.current,
      status: selectedStatus.current,
      headquarters: selectedHeadquarters.current,
      symbolSet: symbolSetId.toString(),
      echelon: selectedEchelon.current,
      entity: selectedEntity.current,
      modifier1: selectedModifier1.current,
      modifier2: selectedModifier2.current,
      engagement: {
        remoteAndLocalEngagements: selectedRemoteEngagements.current,
        engagementState: selectedEngagementState.current,
        weaponAssignmentOrDeployment: selectedWeaponAssignment.current,
      },
      options: {
        staffComments: commentsRef.current,
        additionalInformation: additionalInfoRef.current,
        altitudeDepth: altitudeDepthRef.current,
        combatEffectiveness: combatEffectiveness.current,
        commonIdentifier: commonIdentifierRef.current,
        direction: directionRef.current,
        equipmentTeardownTime: equipmentTeardownTimeRef.current,
        dtg: dtgRef.current,
        evaluationRating: reliabilityRating.current + credibilityRating.current,
        guardedUnit: guardedUnitRef.current,
        higherFormation: higherFormationRef.current,
        hostile: hostileRef.current,
        iffSif: iffSifRef.current,
        location: locationRef.current,
        platformType: platformType.current,
        quantity: quantityRef.current,
        reinforcedReduced: reinforcedReduced.current,
        signatureEquipment: signatureEquipmentRef.current,
        specialDesignator: specialDesignator.current,
        specialHeadquarters: specialHeadquartersRef.current,
        speed: speedRef.current,
        type: typeRef.current,
        uniqueDesignation: uniqueDesignationRef.current,
      },
    };

    const updatedSymbol = encodeMilspecSymbolCode(data);

    milspec.options.engagementState = selectedEngagementState.current;
    milspec.options.remoteAndLocalEngagements =
      selectedRemoteEngagements.current;
    milspec.options.weaponAssignmentOrDeployment =
      selectedWeaponAssignment.current;
    milspec.options.staffComments = commentsRef.current;
    milspec.options.additionalInformation = additionalInfoRef.current;
    milspec.options.altitudeDepth = altitudeDepthRef.current;
    milspec.options.combatEffectiveness = combatEffectiveness.current;
    milspec.options.commonIdentifier = commonIdentifierRef.current;
    milspec.options.direction = directionRef.current;
    milspec.options.equipmentTeardownTime = equipmentTeardownTimeRef.current;
    milspec.options.dtg = dtgRef.current;
    milspec.options.evaluationRating =
      reliabilityRating.current + credibilityRating.current;
    milspec.options.guardedUnit = guardedUnitRef.current;
    milspec.options.higherFormation = higherFormationRef.current;
    milspec.options.hostile = hostileRef.current;
    milspec.options.iffSif = iffSifRef.current;
    milspec.options.location = locationRef.current;
    milspec.options.platformType = platformType.current;
    milspec.options.quantity = quantityRef.current;
    milspec.options.reinforcedReduced = reinforcedReduced.current;
    milspec.options.signatureEquipment = signatureEquipmentRef.current;
    milspec.options.specialDesignator = specialDesignator.current;
    milspec.options.specialHeadquarters = specialHeadquartersRef.current;
    milspec.options.speed = speedRef.current;
    milspec.options.type = typeRef.current;
    milspec.options.uniqueDesignation = uniqueDesignationRef.current;

    await onChangeSymbol(updatedSymbol, milspec.options);

    if (!!insertedSymbol) {
      await handleSaveSymbol();
    } else {
      await handleSaveMilspec();
    }
  };

  //Handles the saving of the updated integration symbol
  const handleSaveMilspec = async () => {
    const newData = {
      id: id,
      standardIdentity1: selectedStandardIdentity1.current,
      standardIdentity2: selectedStandardIdentity2.current,
      status: selectedStatus.current,
      headquarters: selectedHeadquarters.current,
      symbolSet: symbolSetId.toString(),
      echelon: selectedEchelon.current,
      entity: selectedEntity.current,
      modifier1: selectedModifier1.current,
      modifier2: selectedModifier2.current,
      options: {
        staffComments: commentsRef.current,
        additionalInformation: additionalInfoRef.current,
        altitudeDepth: altitudeDepthRef.current,
        combatEffectiveness: combatEffectiveness.current,
        commonIdentifier: commonIdentifierRef.current,
        direction: directionRef.current,
        equipmentTeardownTime: equipmentTeardownTimeRef.current,
        evaluationRating: reliabilityRating.current + credibilityRating.current,
        guardedUnit: guardedUnitRef.current,
        higherFormation: higherFormationRef.current,
        hostile: hostileRef.current,
        iffSif: iffSifRef.current,
        location: locationRef.current,
        platformType: platformType.current,
        quantity: quantityRef.current,
        reinforcedReduced: reinforcedReduced.current,
        signatureEquipment: signatureEquipmentRef.current,
        specialDesignator: specialDesignator.current,
        specialHeadquarters: specialHeadquartersRef.current,
        speed: speedRef.current,
        type: typeRef.current,
        uniqueDesignation: uniqueDesignationRef.current,
      },
      engagement: {
        remoteAndLocalEngagements: selectedRemoteEngagements.current,
        engagementState: selectedEngagementState.current,
        weaponAssignmentOrDeployment: selectedWeaponAssignment.current,
      },
    };
    try {
      await updateMilspecSymbol(newData);
      toast.success('Military details saved');
      await refreshIntegrations();
    } catch (e) {
      toast.error('Error saving military details ' + e);
    }
  };

  //Handles the saving of the inserted symbol
  const handleSaveSymbol = async () => {
    const newData = {
      id: symbolId,
      standardIdentity1: selectedStandardIdentity1.current,
      standardIdentity2: selectedStandardIdentity2.current,
      status: selectedStatus.current,
      headquarters: selectedHeadquarters.current,
      symbolSet: symbolSetId.toString(),
      echelon: selectedEchelon.current,
      entity: selectedEntity.current,
      modifier1: selectedModifier1.current,
      modifier2: selectedModifier2.current,
      options: {
        staffComments: commentsRef.current,
        additionalInformation: additionalInfoRef.current,
        altitudeDepth: altitudeDepthRef.current,
        combatEffectiveness: combatEffectiveness.current,
        commonIdentifier: commonIdentifierRef.current,
        direction: directionRef.current,
        equipmentTeardownTime: equipmentTeardownTimeRef.current,
        dtg: dtgRef.current,
        evaluationRating: reliabilityRating.current + credibilityRating.current,
        guardedUnit: guardedUnitRef.current,
        higherFormation: higherFormationRef.current,
        hostile: hostileRef.current,
        iffSif: iffSifRef.current,
        location: locationRef.current,
        platformType: platformType.current,
        quantity: quantityRef.current,
        reinforcedReduced: reinforcedReduced.current,
        signatureEquipment: signatureEquipmentRef.current,
        specialDesignator: specialDesignator.current,
        specialHeadquarters: specialHeadquartersRef.current,
        speed: speedRef.current,
        type: typeRef.current,
        uniqueDesignation: uniqueDesignationRef.current,
      },
      engagement: {
        remoteAndLocalEngagements: selectedRemoteEngagements.current,
        engagementState: selectedEngagementState.current,
        weaponAssignmentOrDeployment: selectedWeaponAssignment.current,
      },
    };
    try {
      if (!!symbolId) {
        await updateSymbol(symbolId, {
          location: {
            latitude: +location.split(',')[0],
            longitude: +location.split(',')[1].trim(),
          },
          milspec: newData,
        }).then(async () => {
          await refreshSymbols({ id: symbolId });
          toast.success('Symbol updated');
        });
      } else {
        if (location.split(',')[0] !== 'undefined') {
          await addSymbol({
            location: {
              latitude: +location.split(',')[0],
              longitude: +location.split(',')[1].trim(),
            },
            milspec: newData,
          }).then(async (response) => {
            setSymbolId(response.id);
            setId(response.id);
            await refreshSymbols({ id: symbolId });
            toast.success('Symbol saved');
          });
        }
      }
    } catch (e) {
      toast.error('Error saving symbol ' + e);
    }
  };

  return (
    <Expandable
      title={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div>Military Details</div>
        </div>
      }
      style={{ paddingBottom: '10px' }}
      defaultOpen={insertedSymbol}
      children={
        <>
          {!!!insertedSymbol && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <DotButton
                onClick={async (e) => {
                  await resetMilspecSymbol(id);
                  if (document.sidebars) {
                    document.sidebars.closeAll();
                  }
                  toast.success('Military details were reset');
                  await refreshIntegrations();
                }}
                className="primary block"
              >
                Reset Details
              </DotButton>
            </div>
          )}
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="Standard Identity 1"
              onChange={async (e) => {
                e.preventDefault();
                selectedStandardIdentity1.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedStandardIdentity1.current}
            >
              <option key={0} value={null} invalid={'true'} hidden>
                {'Select Standard Identity 1...'}
              </option>
              {standardIdentity1.map((i, idx) => (
                <option key={idx} value={i.value}>
                  {i.text}
                </option>
              ))}
            </Select>
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="Standard Identity 2"
              onChange={async (e) => {
                e.preventDefault();
                selectedStandardIdentity2.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedStandardIdentity2.current}
            >
              <option key={0} value={null} invalid={'true'} hidden>
                {'Select Standard Identity 2...'}
              </option>
              {standardIdentity2.map((i, idx) => (
                <option key={idx} value={i.value}>
                  {i.text}
                </option>
              ))}
            </Select>
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="Icon"
              onChange={async (e) => {
                e.preventDefault();
                selectedEntity.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedEntity.current}
            >
              {symbolSet.entity.map((entity, a) =>
                entity.entityType?.map((entityType, b) =>
                  entityType.entitySubType.map((entitySubType, c) => {
                    return (
                      <option key={c} value={entitySubType.code}>
                        {entity.name} &nbsp;
                        {entityType.name !== '-' && entityType.name} &nbsp;
                        {entitySubType.name !== '-' && entitySubType.name}
                      </option>
                    );
                  })
                )
              )}
            </Select>
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="Icon Modifier 1"
              onChange={async (e) => {
                e.preventDefault();
                selectedModifier1.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedModifier1.current}
            >
              {mod1FormData.map((i, idx) => (
                <option key={idx} value={i.value}>
                  {i.text}
                </option>
              ))}
            </Select>
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="Icon Modifier 2"
              onChange={async (e) => {
                e.preventDefault();
                selectedModifier2.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedModifier2.current}
            >
              {!!mod2FormData &&
                mod2FormData.map((i, idx) => (
                  <option key={idx} value={i.value}>
                    {i.text}
                  </option>
                ))}
            </Select>
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="Status"
              onChange={async (e) => {
                e.preventDefault();
                selectedStatus.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedStatus.current}
            >
              {status.map((i, idx) => (
                <option key={idx} value={i.value}>
                  {i.text}
                </option>
              ))}
            </Select>
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="Headquarters/Task Force/Dummy"
              onChange={async (e) => {
                e.preventDefault();
                selectedHeadquarters.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedHeadquarters.current}
            >
              {headquarters.map((i, idx) => (
                <option key={idx} value={i.value}>
                  {i.text}
                </option>
              ))}
            </Select>
          </div>
          {/* DTG is only applicable to Land Unit, Land Equipment and Land
          Installation symbols */}
          {(symbolSetId === '10' ||
            symbolSetId === '15' ||
            symbolSetId === '20') && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <Input
                label="DTG"
                type="datetime-local"
                value={dtg}
                onChange={async (e) => {
                  e.preventDefault();
                  setDtg(e.target.value);
                  dtgRef.current = e.target.value;
                }}
                onBlur={async (e) => {
                  await handleUpdateSymbol();
                }}
                className="date"
              ></Input>
            </div>
          )}
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{ marginBottom: '16px' }}
          >
            <TextArea
              label={`Location`}
              placeholder={`Enter location`}
              value={location}
              onChange={(e) => {
                e.preventDefault();
                setLocation(e.target.value);
                locationRef.current = e.target.value;
              }}
              handleBlur={async (e) => {
                await handleUpdateSymbol();
              }}
              maxLength={19}
              height={'3.5rem'}
            />
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{ marginBottom: '16px' }}
          >
            <TextArea
              label={`Unique Designation`}
              placeholder={`Enter unique designation`}
              value={uniqueDesignation}
              onChange={(e) => {
                e.preventDefault();
                setUniqueDesignation(e.target.value);
                uniqueDesignationRef.current = e.target.value;
              }}
              handleBlur={async (e) => {
                await handleUpdateSymbol();
              }}
              maxLength={21}
              height={'3.5rem'}
            />
          </div>
          {/* Higher Formation is only applicable to Land Unit symbols */}
          {symbolSetId === '10' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '16px' }}
            >
              <TextArea
                label={`Higher Formation`}
                placeholder={`Enter higher formation`}
                value={higherFormation}
                onChange={(e) => {
                  e.preventDefault();
                  setHigherFormation(e.target.value);
                  higherFormationRef.current = e.target.value;
                }}
                handleBlur={async (e) => {
                  await handleUpdateSymbol();
                }}
                maxLength={21}
                height={'3.5rem'}
              />
            </div>
          )}
          {/* Echelon is only applicable to Sea Surface, Land Unit and Land Equipment symbols */}
          {(symbolSetId === '30' ||
            symbolSetId === '10' ||
            symbolSetId === '15') && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <Select
                label="Echelon/Mobility/Towed Array"
                onChange={async (e) => {
                  e.preventDefault();
                  selectedEchelon.current = e.target.value;
                  await handleUpdateSymbol();
                }}
                placeholder={'Select...'}
                value={selectedEchelon.current}
              >
                {echelonFormData.echelon.map((i, idx) => (
                  <option key={idx} value={i.value}>
                    {i.text}
                  </option>
                ))}
              </Select>
            </div>
          )}
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{ marginBottom: '16px' }}
          >
            <TextArea
              label={`Direction`}
              placeholder={`Enter direction`}
              value={direction}
              onChange={(e) => {
                e.preventDefault();
                setDirection(e.target.value);
                directionRef.current = e.target.value;
              }}
              handleBlur={async (e) => {
                await handleUpdateSymbol();
              }}
              maxLength={3}
              height={'3.5rem'}
            />
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{ marginBottom: '16px' }}
          >
            <TextArea
              label={`Speed`}
              placeholder={`Enter speed`}
              value={speed}
              onChange={(e) => {
                e.preventDefault();
                setSpeed(e.target.value);
                speedRef.current = e.target.value;
              }}
              handleBlur={async (e) => {
                await handleUpdateSymbol();
              }}
              maxLength={8}
              height={'3.5rem'}
            />
          </div>
          {/* Altitude/Depth is only applicable to Air, Land Unit, Land Equipment and Land Installation symbols */}
          {(symbolSetId === '01' ||
            symbolSetId === '10' ||
            symbolSetId === '15' ||
            symbolSetId === '20') && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '16px' }}
            >
              <TextArea
                label={symbolSetId === '01' ? 'Altitude' : `Altitude/Depth`}
                placeholder={
                  symbolSetId === '01'
                    ? 'Enter altitude'
                    : `Enter altitude/depth`
                }
                value={altitudeDepth}
                onChange={(e) => {
                  e.preventDefault();
                  setAltitudeDepth(e.target.value);
                  altitudeDepthRef.current = e.target.value;
                }}
                handleBlur={async (e) => {
                  await handleUpdateSymbol();
                }}
                maxLength={14}
                height={'3.5rem'}
              />
            </div>
          )}
          {/* Reinforced or Reduced is only applicable to Land Unit symbols */}
          {symbolSetId === '10' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <Select
                label="Reinforced or Reduced"
                onChange={async (e) => {
                  e.preventDefault();
                  reinforcedReduced.current = e.target.value;
                  await handleUpdateSymbol();
                }}
                placeholder={'Select...'}
                value={reinforcedReduced.current}
              >
                <option key={0} value={''}>
                  None
                </option>
                <option key={1} value={'+'}>
                  +
                </option>
                <option key={2} value={'-'}>
                  -
                </option>
                <option key={3} value={'±'}>
                  ±
                </option>
              </Select>
            </div>
          )}
          {/* Combat Effectiveness is only applicable to Land Unit and Land Installation symbols */}
          {(symbolSetId === '10' || symbolSetId === '20') && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <Select
                label="Combat Effectiveness"
                onChange={async (e) => {
                  e.preventDefault();
                  combatEffectiveness.current = e.target.value;
                  await handleUpdateSymbol();
                }}
                placeholder={'Select...'}
                value={combatEffectiveness.current}
              >
                <option key={0} value={'FO'}>
                  Fully operational
                </option>
                <option key={1} value={'SO'}>
                  Substantially operational
                </option>
                <option key={2} value={'MO'}>
                  Marginally operational
                </option>
                <option key={3} value={'NO'}>
                  Not operational
                </option>
                <option key={4} value={'UNK'}>
                  Unknown
                </option>
              </Select>
            </div>
          )}
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{ marginBottom: '16px' }}
          >
            <TextArea
              label={`IFF/SIF`}
              placeholder={`Enter IFF/SIF`}
              value={iffSif}
              onChange={(e) => {
                e.preventDefault();
                setIffSif(e.target.value);
                iffSifRef.current = e.target.value;
              }}
              handleBlur={async (e) => {
                await handleUpdateSymbol();
              }}
              maxLength={5}
              height={'3.5rem'}
            />
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{ marginBottom: '16px' }}
          >
            <TextArea
              label={`Type`}
              placeholder={`Enter type`}
              value={type}
              onChange={(e) => {
                e.preventDefault();
                setType(e.target.value);
                typeRef.current = e.target.value;
              }}
              handleBlur={async (e) => {
                await handleUpdateSymbol();
              }}
              maxLength={24}
              height={'3.5rem'}
            />
          </div>
          {/* Guarded Unit is only applicable to Sea Surface symbols */}
          {symbolSetId === '30' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '28px' }}
            >
              <div className="check">
                <CheckBox
                  id={'guarded-unit'}
                  checked={guardedUnit === 'BG' ? true : false}
                  onChange={async (e) => {
                    setGuardedUnit(e.target.checked ? 'BG' : '');
                    guardedUnitRef.current = e.target.checked ? 'BG' : '';
                    await handleUpdateSymbol();
                  }}
                />
              </div>
              <label
                htmlFor={`guarded-unit`}
                className="dot-checkbox"
                style={{ cursor: 'pointer', marginLeft: '16px' }}
              >
                Guarded Unit
              </label>
            </div>
          )}
          {/* Special Designator is only applicable to Sea Surface symbols */}
          {symbolSetId === '30' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <Select
                label="Special Designator"
                onChange={async (e) => {
                  e.preventDefault();
                  specialDesignator.current = e.target.value;
                  await handleUpdateSymbol();
                }}
                placeholder={'Select...'}
                value={specialDesignator.current}
              >
                <option key={0} value={''}>
                  None
                </option>
                <option key={1} value={'NRT'}>
                  NRT
                </option>
                <option key={2} value={'SIG'}>
                  SIG
                </option>
              </Select>
            </div>
          )}
          {/* Special Headquarters is only applicable to Land Unit symbols */}
          {symbolSetId === '10' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '16px' }}
            >
              <TextArea
                label={`Special Headquarters`}
                placeholder={`Enter special headquarters`}
                value={specialHeadquarters}
                onChange={(e) => {
                  e.preventDefault();
                  setSpecialHeadquarters(e.target.value);
                  specialHeadquartersRef.current = e.target.value;
                }}
                handleBlur={async (e) => {
                  await handleUpdateSymbol();
                }}
                maxLength={9}
                height={'3.5rem'}
              />
            </div>
          )}
          {/* Reliability Rating is only applicable to Land Unit, Land Equipment and Land Installation symbols */}
          {(symbolSetId === '10' ||
            symbolSetId === '15' ||
            symbolSetId === '20') && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <Select
                label="Reliability Rating"
                onChange={async (e) => {
                  e.preventDefault();
                  reliabilityRating.current = e.target.value;
                  await handleUpdateSymbol();
                }}
                placeholder={'Select...'}
                value={reliabilityRating.current}
              >
                <option key={0} value={'A'}>
                  A-completely reliable
                </option>
                <option key={1} value={'B'}>
                  B-usually reliable
                </option>
                <option key={2} value={'C'}>
                  C-fairly reliable
                </option>
                <option key={3} value={'D'}>
                  D-not usually reliable
                </option>
                <option key={4} value={'E'}>
                  E-unreliable
                </option>
                <option key={5} value={'F'}>
                  F-reliability cannot be judged
                </option>
              </Select>
            </div>
          )}
          {/* Credibility Rating is only applicable to Land Unit, Land Equipment and Land Installation symbols */}
          {(symbolSetId === '10' ||
            symbolSetId === '15' ||
            symbolSetId === '20') && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <Select
                label="Credibility Rating"
                onChange={async (e) => {
                  e.preventDefault();
                  credibilityRating.current = e.target.value;
                  await handleUpdateSymbol();
                }}
                placeholder={'Select...'}
                value={credibilityRating.current}
              >
                <option key={0} value={'1'}>
                  1-confirmed by other sources
                </option>
                <option key={1} value={'2'}>
                  2-probably true
                </option>
                <option key={2} value={'3'}>
                  3-possibly true
                </option>
                <option key={3} value={'4'}>
                  4-doubtfully true
                </option>
                <option key={4} value={'5'}>
                  5-improbable
                </option>
                <option key={5} value={'6'}>
                  6-truth cannot be judged
                </option>
              </Select>
            </div>
          )}
          {/* Platform Type is only applicable to Land Unit, Land Equipment and Land Installation symbols */}
          {(symbolSetId === '10' ||
            symbolSetId === '15' ||
            symbolSetId === '20') && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{
                marginBottom: '16px',
              }}
            >
              <Select
                label="Platform Type"
                onChange={async (e) => {
                  e.preventDefault();
                  platformType.current = e.target.value;
                  await handleUpdateSymbol();
                }}
                placeholder={'Select...'}
                value={platformType.current}
              >
                <option key={0} value={''}>
                  None
                </option>
                <option key={1} value={'ELNOT'}>
                  ELNOT
                </option>
                <option key={2} value={'CENOT'}>
                  CENOT
                </option>
              </Select>
            </div>
          )}
          {/* Common Identifier is only applicable to Land Unit, Land Equipment and Land Installation symbols */}
          {(symbolSetId === '10' ||
            symbolSetId === '15' ||
            symbolSetId === '20') && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '16px' }}
            >
              <TextArea
                label={`Common Identifier`}
                placeholder={`Enter common identifier`}
                value={commonIdentifier}
                onChange={(e) => {
                  e.preventDefault();
                  setCommonIdentifier(e.target.value);
                  commonIdentifierRef.current = e.target.value;
                }}
                handleBlur={async (e) => {
                  await handleUpdateSymbol();
                }}
                maxLength={20}
                height={'3.5rem'}
              />
            </div>
          )}
          {/* Quantity is only applicable to Land Equipment symbols */}
          {symbolSetId === '15' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '16px' }}
            >
              <TextArea
                label={`Quantity`}
                placeholder={`Enter quantity`}
                value={quantity}
                onChange={(e) => {
                  e.preventDefault();
                  setQuantity(e.target.value);
                  quantityRef.current = e.target.value;
                }}
                handleBlur={async (e) => {
                  await handleUpdateSymbol();
                }}
                maxLength={9}
                height={'3.5rem'}
              />
            </div>
          )}
          {/* Signature Equipment is only applicable to Land Equipment symbols */}
          {symbolSetId === '15' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '28px' }}
            >
              <div className="check">
                <CheckBox
                  id={'signature-equipment'}
                  checked={signatureEquipment === '!' ? true : false}
                  onChange={async (e) => {
                    setSignatureEquipment(e.target.checked ? '!' : '');
                    signatureEquipmentRef.current = e.target.checked ? '!' : '';
                    await handleUpdateSymbol();
                  }}
                />
              </div>
              <label
                htmlFor={`signature-equipment`}
                className="dot-checkbox"
                style={{ cursor: 'pointer', marginLeft: '16px' }}
              >
                Signature Equipment
              </label>
            </div>
          )}
          {/* Hostile is only applicable to Land Equipment symbols */}
          {symbolSetId === '15' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '28px' }}
            >
              <div className="check">
                <CheckBox
                  id={'hostile'}
                  checked={hostile === 'ENY' ? true : false}
                  onChange={async (e) => {
                    setHostile(e.target.checked ? 'ENY' : '');
                    hostileRef.current = e.target.checked ? 'ENY' : '';
                    await handleUpdateSymbol();
                  }}
                />
              </div>
              <label
                htmlFor={`hostile`}
                className="dot-checkbox"
                style={{ cursor: 'pointer', marginLeft: '16px' }}
              >
                Hostile
              </label>
            </div>
          )}
          {/* Equipment Teardown Time is only applicable to Land Equipment symbols */}
          {symbolSetId === '15' && (
            <div
              className={getClassNames({
                'no-border': true,
                'no-padding': true,
              })}
              style={{ marginBottom: '16px' }}
            >
              <TextArea
                label={`Equipment Teardown Time`}
                placeholder={`Enter equipment teardown time`}
                value={equipmentTeardownTime}
                onChange={(e) => {
                  e.preventDefault();
                  setEquipmentTeardownTime(e.target.value);
                  equipmentTeardownTimeRef.current = e.target.value;
                }}
                handleBlur={async (e) => {
                  await handleUpdateSymbol();
                }}
                maxLength={20}
                height={'3.5rem'}
              />
            </div>
          )}
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="A - Remote and Local Engagements"
              onChange={async (e) => {
                e.preventDefault();
                selectedRemoteEngagements.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedRemoteEngagements.current}
            >
              {engagement.remoteAndLocalEngagements.map((i, idx) => (
                <option key={idx} value={i.value}>
                  {i.text}
                </option>
              ))}
            </Select>
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            <Select
              label="BBB - Engagement State"
              onChange={async (e) => {
                e.preventDefault();
                selectedEngagementState.current = e.target.value;
                await handleUpdateSymbol();
              }}
              placeholder={'Select...'}
              value={selectedEngagementState.current}
            >
              {engagement.engagementState.map((i, idx) => (
                <option key={idx} value={i.value}>
                  {i.text}
                </option>
              ))}
            </Select>
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{
              marginBottom: '16px',
            }}
          >
            {selectedEngagementState.current === 'MLT' ? (
              <Input
                label="CC - Weapon Assignment or Deployment"
                type="number"
                min="02"
                max="99"
                onChange={async (e) => {
                  let value = e.target.value;
                  if (value.length === 1) {
                    value = '0'.concat(value);
                  }
                  selectedWeaponAssignment.current = value;
                  await handleUpdateSymbol();
                }}
                value={selectedWeaponAssignment.current}
              />
            ) : (
              <Select
                label="CC - Weapon Assignment or Deployment"
                onChange={async (e) => {
                  e.preventDefault();
                  selectedWeaponAssignment.current = e.target.value;
                  await handleUpdateSymbol();
                }}
                placeholder={'Select...'}
                value={selectedWeaponAssignment.current}
              >
                {engagement.weaponAssignmentOrDeployment.map((i, idx) => (
                  <option key={idx} value={i.value}>
                    {i.text}
                  </option>
                ))}
              </Select>
            )}
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{ marginBottom: '16px' }}
          >
            <TextArea
              label={`Staff Comments`}
              placeholder={`Enter staff comments`}
              value={comments}
              onChange={(e) => {
                e.preventDefault();
                setComments(e.target.value);
                commentsRef.current = e.target.value;
              }}
              handleBlur={async (e) => {
                await handleUpdateSymbol();
              }}
              maxLength={20}
              height={'3.5rem'}
            />
          </div>
          <div
            className={getClassNames({
              'no-border': true,
              'no-padding': true,
            })}
            style={{ marginBottom: '8px' }}
          >
            <TextArea
              label={`Additional Information`}
              placeholder={`Enter additional information`}
              value={additionalInfo}
              onChange={(e) => {
                e.preventDefault();
                setAdditionalInfo(e.target.value);
                additionalInfoRef.current = e.target.value;
              }}
              handleBlur={async (e) => {
                await handleUpdateSymbol();
              }}
              maxLength={20}
              height={'3.5rem'}
            />
          </div>
        </>
      }
    />
  );
};
