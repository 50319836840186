/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { createContext, useContext, useState } from 'react';
import { getClassNames } from '../utils/string';
import { PropTypes } from 'prop-types';

/**
 * Sidebars enum.
 */
export const SIDEBARS = Object.freeze({
  notificationPreferences: 'notification-preferences-sidebar',
  editStyles: 'edit-styles-sidebar',
  unitInformation: 'unit-information-sidebar',
  newUser: 'new-user-sidebar',
  newGroup: 'new-group-sidebar',
  newOrg: 'new-org-sidebar',
  editTeam: 'edit-team-sidebar',
  editOrg: 'edit-org-sidebar',
  editProfile: 'profile-sidebar',
  orgDataSharing: 'org-data-sharing-sidebar',
  orgIntegrations: 'org-integrations-sidebar',
  missionAllies: 'mission-allies-sidebar',
  missionAlliesAlly: 'mission-allies-ally-sidebar',
  missionTeams: 'mission-teams-sidebar',
  missionRoles: 'mission-roles-sidebar',
  missionUsers: 'mission-users-sidebar',
  missionWaypoint: 'mission-waypoint-sidebar',
  missionImage: 'mission-image-sidebar',
  missionSymbol: 'mission-symbol-sidebar',
  missionUser: 'mission-unit-sidebar',
  missionIncludedUsers: 'mission-included-unit-sidebar',
  missionIncludedImages: 'mission-included-image-sidebar',
  missionIncludedWaypoint: 'mission-included-waypoint-sidebar',
  missionIncludedMarkers: 'mission-included-markers-sidebar',
  missionIntegrations: 'mission-integrations-sidebar',
  editMissionIntegrations: 'edit-mission-integrations-sidebar',
  shapeDetails: 'shape-details',
  missionBroadcast: 'mission-broadcast',
  mission: 'mission-sidebar',
  task: 'task-sidebar',
  history: 'history-sidebar',
  chatParticipants: 'chat-participants',
  integrationInfo: 'integration-info',
  missionLayers: 'mission-layers',
  missionActiveIntegrations: 'mission-active-integrations-sidebar',
  form: 'mission-form',
});

/**
 * Sidebar state.
 *
 * @param {string[]} sidebars active sidebars
 * @param {Function} isOpen is sidebar open
 * @param {Function} open open a sidebar
 * @param {Function} close close a sidebar
 * @param {Function} closeAll close all sidebars
 * @param {Function} setHalfScreen set half screen
 */
export const SidebarContext = createContext({
  sidebars: [],
  previous: '',
  isOpen: (sidebarId) => {},
  open: (sidebarId, currentSidebarId) => {},
  close: (sidebarId) => {},
  closeAll: () => {},
  setHalfScreen: () => {},
});

/**
 * Sidebar provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const SidebarProvider = ({ children }) => {
  const [sidebars, setSidebars] = useState([]);
  const [previous, setPrevious] = useState('');
  const [ishalfScreen, setIsHalfScreen] = useState();
  const contextValue = {
    sidebars,
    previous,
    isOpen: (sidebarId) => {
      const isOpen = sidebars.indexOf(sidebarId) !== -1;
      return isOpen;
    },
    open: (sidebarId, currentSidebarId = '') => {
      // setSidebars([...sidebars, sidebarId]);
      setPrevious(currentSidebarId);
      setSidebars([sidebarId]);
    },
    close: (sidebarId) => {
      // setSidebars(sidebars.filter((id) => id !== sidebarId));
      setSidebars([]);
      setIsHalfScreen(false);
    },
    closeAll: () => {
      setSidebars([]);
      setIsHalfScreen(false);
    },
    setHalfScreen: () => {
      setIsHalfScreen(true);
    },
  };

  return (
    <SidebarContext.Provider value={contextValue}>
      <div
        className={getClassNames({
          'sidebar-open': sidebars.length && !!!ishalfScreen,
          'half-screen-sidebar-open': !!ishalfScreen,
        })}
      >
        {children}
      </div>
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming sidebar context.
 *
 * @returns SidebarContext
 */
export const useSidebar = () => {
  return useContext(SidebarContext);
};
