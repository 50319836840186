/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DotButton } from '../common/buttons/DotButton';
import { LightScrollbar } from '../common/Scrollbars';
import { Control1 } from './form-controls/Control1';
import { Control4 } from './form-controls/Control4';
import { Control5 } from './form-controls/Control5';
import { Control6 } from './form-controls/Control6';
import { Control7 } from './form-controls/Control7';
import { useUser } from '../../context/UserContext';
import { useSidebar } from '../../context/SidebarContext';

/**
 * MOVREQ Form
 *
 */
export const Movreq = ({ missionId, cancelForm }) => {
  const [hideContent, setHideContent] = useState(false);
  const [hidePanel, setHidePanel] = useState([]);
  const userValue = useUser();
  const sidebars = useSidebar();

  const panels = [
    {
      title: 'Serial and Destination',
      controls: [
        {
          label: 'Enter Names and/or Units to send MOVREQ to',
          required: true,
        },
      ],
    },
    {
      title: '1. Unit Contact at Pickup Point',
      controls: [
        {
          label: 'Enter the point of contact at the pickup location',
          required: true,
        },
      ],
    },
    {
      title: "2. Contact Officer's Sub-Unit",
      controls: [
        {
          label: 'Enter the sub unit under the Contact Officer',
          required: true,
        },
      ],
    },
    {
      title: '3. Troops',
      tip: 'Enter details of troops to be relocated in this section',
      controls: [
        {
          required: true,
          label: ['Number', 'Order', 'Vehicle Configuration'],
          ref: ['A.', 'B.', 'C.'],
          tip: [
            'Values from 01-99',
            'Select the order of the troops',
            'Select the configuration of the vehicle',
          ],
        },
      ],
    },
    {
      title: '4. Cargo',
      tip: 'Enter details of cargo to be relocated in this section',
      controls: [
        {
          label: 'Description',
          tip: 'Enter a description of the cargo to be relocated',
          required: true,
        },
      ],
    },
    {
      title: '5. Pick Up Point',
      tip: 'Enter details of the requested Pick Up location in this section',
      controls: [
        {
          ref: 'A.',
          label: 'Map',
          tip: 'Specify the MAP of the Pick Up location',
        },
        {
          ref: 'B.',
          label: 'Name of Pick Up Point',
          tip: 'Specify the name (if any) of the Pick Up location',
          required: true,
        },
        {
          ref: 'C.',
          label: 'Grid of Pick Up Point',
          tip: 'Enter MGRS using WGS84 of the Pick Up location',
          required: true,
        },
        {
          ref: 'D.',
          label: 'Callsign and Frequency',
          tip: 'Specify the Callsign and Frequency for communication at Pick Up location',
          required: true,
        },
      ],
    },
    {
      title: '6. Drop Off Point',
      tip: 'Enter details of the requested Drop Off location in this section',
      controls: [
        {
          ref: 'A.',
          label: 'Map',
          tip: 'Specify the MAP of the Drop Off location',
        },
        {
          ref: 'B.',
          label: 'Name of Drop Off Point',
          tip: 'Specify the name (if any) of the Drop Off location',
          required: true,
        },
        {
          ref: 'C.',
          label: 'Grid of Drop Off Point',
          tip: 'Enter MGRS using WGS84 of the Drop Off location',
          required: true,
        },
        {
          ref: 'D.',
          label: 'Callsign and Frequency',
          tip: 'Specify the Callsign and Frequency for communication at Drop Off location',
          required: true,
        },
      ],
    },
    {
      title: '7. DTG of Pick Up Required',
      tip: 'Enter the DTG of the pick up required',
      controls: [
        {
          label: ['Date', 'Time'],
          required: true,
        },
      ],
    },
    {
      title: '8. DTG of Delivery Required',
      tip: 'Enter the DTG of the delivery required',
      controls: [
        {
          label: ['Date', 'Time'],
          required: true,
        },
      ],
    },
    {
      title: '9. Preferred Mode of Transport',
      controls: [
        {
          label: 'Preferred Mode of Transport',
          tip: 'Specify the preferred method of transport',
        },
      ],
    },
    {
      title: '10. Additional Remarks',
      controls: [
        {
          label: 'Additional Remarks',
        },
      ],
    },
  ];

  return (
    <div className="form">
      <div className="header">
        <div className="left">
          <div className="avatar">MR</div>
          <div className="form-title">MOVEREQ</div>
        </div>
        <div className="right">
          <div className="last-updated">Last updated:</div>
          <div className="chevron" onClick={() => setHideContent(!hideContent)}>
            <FontAwesomeIcon
              icon={!hideContent ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
      </div>
      <LightScrollbar>
        <div
          className="form-content"
          style={{ display: hideContent && 'none' }}
        >
          {!!panels &&
            panels.map((p, idx) => {
              return (
                <div className="panel" key={idx}>
                  <div className="panel-header">
                    <div className="left">
                      <div className="panel-title">{p.title}</div>
                      {!!p.tip && <div className="panel-tip">{p.tip}</div>}
                    </div>
                    <div className="right">
                      {/* <div className="last-updated">Last updated:</div> */}
                      <div
                        className="chevron"
                        onClick={(e) => {
                          e.preventDefault();
                          const hidePanelCopy = [...hidePanel];
                          hidePanelCopy[idx] = !hidePanel[idx];
                          setHidePanel(hidePanelCopy);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={!hidePanel[idx] ? 'chevron-up' : 'chevron-down'}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="panel-content"
                    style={{ display: hidePanel[idx] && 'none' }}
                  >
                    {idx === 0 && (
                      <Control4
                        control={p.controls[0]}
                        user={userValue}
                        missionId={missionId}
                      />
                    )}
                    {(idx === 1 || idx === 2) && (
                      <Control5 control={p.controls[0]} missionId={missionId} />
                    )}
                    {idx === 3 && (
                      <Control6 control={p.controls[0]} missionId={missionId} />
                    )}
                    {(idx === 4 || idx === 5 || idx === 6) &&
                      p.controls.map((c) => {
                        return <Control1 control={c} />;
                      })}
                    {(idx === 7 || idx === 8) && (
                      <Control7 control={p.controls[0]} />
                    )}
                    {(idx === 9 || idx === 10) &&
                      p.controls.map((c) => {
                        return <Control1 control={c} />;
                      })}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="footer">
          <div className="button-panel">
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                sidebars.closeAll();
                cancelForm();
              }}
            >
              DISCARD FORM
            </DotButton>
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              SAVE AS DRAFT
            </DotButton>
            <DotButton
              className="primary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              SEND FORM
            </DotButton>
          </div>
        </div>
      </LightScrollbar>
    </div>
  );
};

Movreq.propTypes = {
  missionId: PropTypes.any.isRequired,
  cancelForm: PropTypes.func.isRequired,
};
