/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { configureJWT } from '../api/global';
import { getUser, postLogin } from '../api/orgs_users';
import { handleError } from './error';
import {
  clearLocalStorage,
  getLocalStorage,
  saveToLocalStorage,
} from './localStorage';

/**
 * Logs a user into the platform
 *
 * A jwt is created, the user is verified, UserContext updated with a new
 * user, which updates the application state.
 *
 * @param {string} email
 * @param {string} password
 * @param {{func, params}} verifyUser (see app.js for info)
 * @returns {loginData} true if successful, false if user not found in database.
 */
export const loginSubmission = async (email, password, verifyUser) => {
  try {
    const loginRes = await postLogin(email, password);

    // Login was successful
    if (loginRes) {
      // Save jwt to local storage
      saveToLocalStorage('token', loginRes.token, false);
      verifyUser.func(verifyUser.params);
      return true;
    } else {
      return false;
    }
  } catch (ex) {
    log.error('Login error', ex);
    return false;
  }
};

/**
 * Verifies users JWT in storage
 *
 * @returns {{status: Boolean, jwt: Object, userDetails: Object, errorMsg: Object}} outputObj
 */
export const verifyJWT = async () => {
  let outputObj;

  // Get jwt and check if it exists
  // if True, get user details from db
  const jwt = getLocalStorage('token', false);
  if (jwt) {
    configureJWT(jwt);

    try {
      const userTemp = await getUser();

      // Check that the jwt has not expired and is
      // still assoiated with a user

      // Success
      if (userTemp) {
        outputObj = {
          status: true,
          jwt: jwt,
          userDetails: userTemp,
          errorDetails: {
            errorCode: 0,
            errorMsg: '',
          },
        };
        return outputObj;
      } else {
        outputObj = {
          status: false,
          jwt: null,
          userDetails: null,
          errorDetails: {
            errorCode: 1,
            errorMsg: 'User not found on database',
          },
        };
        handleError(outputObj.errorDetails.errorMsg);
        return outputObj;
      }
    } catch (ex) {
      outputObj = {
        status: false,
        jwt: null,
        userDetails: null,
        errorDetails: {
          errorCode: 1,
          errorMsg: 'Session has expired',
        },
      };
      handleError(outputObj.errorDetails.errorMsg);
      return outputObj;
    }
  } else {
    outputObj = {
      status: false,
      jwt: null,
      userDetails: null,
      errorDetails: {
        errorCode: 1,
        errorMsg: 'Jwt was not found in local storage',
      },
    };
    log.error(outputObj.errorDetails);
    return outputObj;
  }
};

/**
 * Logs a user out
 */
export const logout = (userValue) => {
  // clear local storage
  clearLocalStorage([
    'chatMode',
    'broadcastMode',
    'tour-home',
    'tour-users',
    'tour-map',
    'tour-create-mission',
  ]);

  // update user context state
  userValue.setUser(null);
};

/**
 * Is a user allowed to delete a POI
 *
 * @param {*} user  user
 * @param {*} poi   point of interest
 * @return {Boolean} true iff authorised, false iff not
 */
export const canDeletePoi = (user, poi) => {
  if (!poi?.id) {
    return false;
  }
  return !!user.accessRoles.find((r) => r.toLowerCase().indexOf('admin'));
};
