/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, { useEffect, useState } from 'react';
import { useBottombar } from '../../context/BottombarContext';
import { getClassNames } from '../../utils/string';
import './Bottombar.scss';
import { PropTypes } from 'prop-types';

const BOTTOMBAR_EXPOSED_HEIGHT = 26.25;
const BOTTOMBAR_MINIMIZED_HEIGHT = 5;

/**
 * Pop out Bottombar control
 *
 * @param {Jsx} children Bottombar children
 * @param {string} id Bottombar id
 */
export const Bottombar = ({ children, id }) => {
  const bottombars = useBottombar();
  const [height, setHeight] = useState(0);

  //1.875rem
  //bottom-left
  //extra-map-controls right

  useEffect(() => {
    if (bottombars.isOpen(id)) {
      console.log('open');
      // we're open
      if (bottombars.isMinimized()) {
        setHeight(BOTTOMBAR_MINIMIZED_HEIGHT);
      } else {
        setHeight(BOTTOMBAR_EXPOSED_HEIGHT);
      }
    } else {
      console.log('closed');
      setHeight(0);
    }
    // eslint-disable-next-line
  }, [bottombars.bottombars, bottombars.isMinimized()]);

  return (
    <div
      className={getClassNames({
        'pop-out-bottombar': true,
        'is-open': bottombars.isOpen(id),
        'is-minimized': bottombars.isMinimized(),
      })}
      id={id}
      style={{ bottom: `${0 - BOTTOMBAR_EXPOSED_HEIGHT + height}rem` }}
    >
      {!!height && children}
    </div>
  );
};

Bottombar.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any.isRequired,
};
