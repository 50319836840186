/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import {
  getMissionIntegrations,
  postMissionIntegration,
} from '../../../api/missions';
import { getIntegrationOptions } from '../../../api/orgs_users';
import { useSidebar } from '../../../context/SidebarContext';
import { integrationType } from '../../../enums/propTypes';
import { handleError } from '../../../utils/error';
import { getClassNames, capitalize } from '../../../utils/string';
import { DotButton } from '../../common/buttons/DotButton';
import { CheckBox } from '../../common/CheckBox';
import { LightScrollbar } from '../../common/Scrollbars';
import './MissionIntegrations.scss';
import { getLocalStorage } from '../../../utils/localStorage';
import { secrets } from '../../../config/secrets';

/**
 * Sidebar - show mission integrations
 *
 * @param {string} missionId mission id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any[]} orgIntegrations organisation integrations
 * @param {any[]} missionIntegrations mission integrations
 * @param {Function} setMissionIntegrations sets all integrations in the mission
 */
export const MissionIntegrations = ({
  missionId,
  closeSidebar,
  orgIntegrations,
  missionIntegrations,
  setMissionIntegrations,
}) => {
  const sidebars = useSidebar();

  const [integrationOptions, setIntegrationOptions] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [integration, setIntegration] = useState(null);
  const [integrationId, setIntegrationId] = useState(null);
  const [integrationName, setIntegrationName] = useState(null);
  const [touched, setTouched] = useState(false);

  // Handles adding integration to a mission
  const handleAddIntegration = async (e) => {
    e.preventDefault();

    if (!selectedOptions.length && !selectAll) {
      toast.error('Please select at least one option');
      return;
    }

    try {
      await postMissionIntegration(
        missionId,
        integrationId,
        selectAll,
        selectedOptions
      );
      const newIntegrations = (await getMissionIntegrations(missionId)).map(
        (i) => {
          i.active =
            getLocalStorage(`integrations-${missionId}`).find(
              (mi) => mi.id === i.id
            )?.active || false;
          i.visibleInToolbar =
            getLocalStorage(`integrations-${missionId}`).find(
              (mi) => mi.id === i.id
            )?.visibleInToolbar || false;
          return i;
        }
      );
      setMissionIntegrations(newIntegrations);
      toast.success(
        `${integrationName} was successfully added to the ${secrets.EVENT_NAME}`
      );
      closeSidebar();
    } catch (e) {
      handleError(e);
    }
  };

  const showBack = !!sidebars.previous;

  const exit = () => {
    closeSidebar();
  };

  return integration === null ? (
    <div className="mission-integrations">
      <div
        className={getClassNames({
          top: true,
          'has-back': showBack,
        })}
      >
        <div className="heading">
          <div className="text">Select Integrations</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        {showBack && (
          <DotButton className="back" onClick={exit} ariaLabel="Back">
            <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
          </DotButton>
        )}
        <div
          className="sub-heading"
          style={{ marginTop: '30px', marginBottom: '-15px' }}
        >
          <div className="text">Available Integrations</div>
        </div>
      </div>
      <LightScrollbar className="content">
        <div className="blocks">
          {orgIntegrations.map((integration, index) => (
            <IntegrationSection
              key={index}
              {...integration}
              setIntegrationId={setIntegrationId}
              setIntegration={setIntegration}
              setIntegrationOptions={setIntegrationOptions}
              setIntegrationName={setIntegrationName}
            />
          ))}
        </div>
      </LightScrollbar>
    </div>
  ) : (
    <div className="mission-integrations">
      <div className="top has-back">
        <div className="heading">
          <div className="text">Select Integrations</div>
          <DotButton
            className="exit"
            onClick={() => {
              closeSidebar();
            }}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        <DotButton
          className="back"
          onClick={() => {
            setIntegration(null);
          }}
          ariaLabel="Back"
        >
          <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
        </DotButton>
        <div
          className="sub-heading"
          style={{
            marginTop: '15px',
            marginBottom: '-15px',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <div className="text" style={{ paddingTop: '15px' }}>
            INTEGRATION DETAILS
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '45px',
          }}
        >
          <div style={{ alignSelf: 'flex-start' }}>
            <div className="text" style={{ fontWeight: '600' }}>
              INTEGRATION NAME
            </div>
            <div className="text" style={{ marginTop: '15px' }}>
              {integrationName}
            </div>
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            <img
              src={integration.photoUrl}
              alt={[integration.name]}
              style={{ height: '3.75rem' }}
            />
          </div>
        </div>
        <div className="text" style={{ fontWeight: '600', marginTop: '30px' }}>
          INTEGRATION DESCRIPTION
        </div>
        <div className="text" style={{ marginTop: '15px' }}>
          {integration.description}
        </div>
        <div
          className="text"
          style={{
            fontWeight: '600',
            marginTop: '30px',
            marginBottom: '-30px',
          }}
        >
          INTEGRATION OPTIONS
        </div>
      </div>
      <LightScrollbar className="content">
        {integrationOptions && (
          <div className="blocks">
            <div className="item-container" style={{ alignItems: 'center' }}>
              <div className="check">
                <CheckBox
                  id={`checkbox-all`}
                  value={'selectAll'}
                  onChange={(event) => {
                    setTouched(true);
                    setSelectAll(event.target.checked);
                    if (event.target.checked) {
                      setSelectedOptions([
                        ...integrationOptions.map((o) => o.id),
                      ]);
                    } else {
                      setSelectedOptions([]);
                    }
                  }}
                  checked={selectAll}
                ></CheckBox>
              </div>
              <label
                htmlFor={`checkbox-all`}
                className="text"
                style={{ cursor: 'pointer' }}
              >
                Select All
              </label>
            </div>
            {integrationOptions.map((option, index) => (
              <div
                key={index}
                className="item-container"
                style={{ alignItems: 'center' }}
              >
                <div className="check">
                  <CheckBox
                    id={`checkbox-${index}`}
                    value={option.id}
                    onChange={(event) => {
                      setTouched(true);
                      let options = [...selectedOptions];
                      if (event.target.checked) {
                        options.push(option.id);
                      } else {
                        options = options.filter(
                          (optionId) => optionId !== option.id
                        );
                      }
                      setSelectedOptions(options);

                      let allSelected =
                        options.length >= integrationOptions.length;
                      for (const io of integrationOptions) {
                        if (!options.find((o) => o !== io.id)) {
                          allSelected = false;
                          break;
                        }
                      }
                      setSelectAll(allSelected);
                    }}
                    checked={!!selectedOptions.find((o) => o === option.id)}
                  ></CheckBox>
                </div>
                <label
                  htmlFor={`checkbox-${index}`}
                  className="text"
                  style={{ cursor: 'pointer' }}
                >
                  {option.deviceFriendlyName}
                </label>
              </div>
            ))}
          </div>
        )}
      </LightScrollbar>
      <div className="foot">
        <DotButton
          onClick={(e) => handleAddIntegration(e)}
          className="primary block"
          disabled={!touched}
        >
          Add to {capitalize(secrets.EVENT_NAME)}
        </DotButton>
      </div>
    </div>
  );
};

MissionIntegrations.propTypes = {
  missionId: PropTypes.string,
  closeSidebar: PropTypes.func,
  orgIntegrations: PropTypes.arrayOf(integrationType),
  setMissionIntegrations: PropTypes.func,
};

/**
 * Integration list item
 *
 * @param {string} id integration id
 * @param {string} code integration code
 * @param {array} configurationItems integration configuration
 * @param {object} integration integration
 * @param {Function} setIntegrationId sets selected integration id
 * @param {Function} setIntegration sets selected integration
 * @param {Function} setIntegrationOptions sets options for selected integration
 * @param {Function} setIntegrationName sets name of selected integration
 */
const IntegrationSection = ({
  id,
  code,
  configurationItems,
  integration,
  setIntegrationId,
  setIntegration,
  setIntegrationOptions,
  setIntegrationName,
}) => {
  return (
    <div
      className="item-container"
      style={{ cursor: 'pointer' }}
      onClick={async () => {
        setIntegrationOptions(await getIntegrationOptions(id));
        setIntegrationId(id);
        setIntegration(integration);
        setIntegrationName(
          configurationItems.find((ci) => ci.key === 'name')?.value
        );
      }}
    >
      <img
        src={integration.photoUrl}
        alt={[code]}
        style={{ height: '3.75rem' }}
      />
      <div className="text" style={{ alignSelf: 'flex-start' }}>
        {configurationItems.find((ci) => ci.key === 'name')?.value}
      </div>
      <div className="button" style={{ alignSelf: 'flex-start' }}>
        <FontAwesomeIcon icon={'chevron-right'} />
      </div>
    </div>
  );
};

IntegrationSection.propTypes = {
  id: PropTypes.string,
  code: PropTypes.string,
  configurationItems: PropTypes.array,
  integration: PropTypes.object,
  setIntegrationId: PropTypes.func,
  setIntegration: PropTypes.func,
  setIntegrationOptions: PropTypes.func,
  setIntegrationName: PropTypes.func,
};
