/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { PrivacyLevel } from '../../../enums/privacyLevel';
import {
  organisationType,
  orgRoleType,
  teamType,
  userType,
} from '../../../enums/propTypes';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import {
  PrivacyContent,
  TestCurrentUser,
  TestOrganisation,
} from '../../organisations-and-users/organisations/edit-org/Sharing';
import { DataSharingSection } from '../organisation/OrgDataSharing';
import { PersonnelList } from './UnitInformation';

/**
 * Map Sidebar - mission teams
 * Lists all teams participating in the mission
 *
 * @param {string} missionId mission id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any[]} teams teams list
 * @param {any[]} roles roles list
 * @param {Boolean} isOwner are we the owner of the mission
 * @param {Function} updateTeams callback to update allies
 * @param {object} showOnlyTeam only show this team
 * @param {Function} setShowOnlyTeam callback to only show this team
 * @param {any[]} users users list
 * @param {Function} setUser callback to set user
 * @param {Function} openChatThread callback to chat with user
 */
export const MissionTeams = ({
  missionId,
  closeSidebar,
  teams,
  isOwner,
  updateTeams,
  showOnlyTeam,
  setShowOnlyTeam,
  users,
  setUser,
  openChatThread,
  roles,
}) => {
  const [searchText, setSearchText] = useState('');
  const [team, setTeam] = useState();

  const searchTeams = (e) => {
    setSearchText(e.target.value);
  };

  const transformTeams = () => {
    return teams.filter(
      (o) => o.team && o.team.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const allUsersInATeam = new Set();
  for (const t of teams) {
    if (t.users?.length) {
      for (const uid of t.users) {
        allUsersInATeam.add(uid);
      }
    }
  }

  const teamUsers = team
    ? users.filter((u) =>
        team.users
          ? team.users.includes(u.id)
          : !Array.from(allUsersInATeam).includes(u.id)
      )
    : [];

  const createPanel = () => {
    if (team) {
      return (
        <SelectedTeam
          team={team}
          setUser={setUser}
          users={teamUsers}
          openChatThread={openChatThread}
          roles={roles}
        />
      );
    } else {
      return (
        <SelectTeamList
          teams={transformTeams()}
          setTeam={setTeam}
          invite={invite}
          isOwner={isOwner}
          missionId={missionId}
          updateTeams={updateTeams}
          showOnlyTeam={showOnlyTeam}
          setShowOnlyTeam={setShowOnlyTeam}
          openChatThread={openChatThread}
        ></SelectTeamList>
      );
    }
  };

  const exit = () => {
    if (team) {
      setTeam(null);
    } else {
      closeSidebar();
    }
  };

  const invite = () => {
    closeSidebar();
  };

  const showSearch = !team;

  let title = 'Mission Teams';

  const showBack = !!team;

  return (
    <div className="mission-sidebar mission-allies">
      <div className="top">
        <div className="heading">
          <div className="text">{title}</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>

        {showSearch && (
          <SearchInput
            placeholder="Search teams"
            onChange={searchTeams}
            light
          />
        )}
      </div>

      {showBack && (
        <DotButton className="back" onClick={exit} ariaLabel="Back">
          <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
        </DotButton>
      )}

      {createPanel()}
    </div>
  );
};

MissionTeams.propTypes = {
  missionId: PropTypes.string,
  closeSidebar: PropTypes.func,
  teams: PropTypes.arrayOf(teamType),
  roles: PropTypes.arrayOf(orgRoleType),
  isOwner: PropTypes.bool,
  updateTeams: PropTypes.func,
  showOnlyTeam: PropTypes.arrayOf(PropTypes.string),
  setShowOnlyTeam: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.any),
  setUser: PropTypes.func,
};

/**
 * List of teams
 *
 * @param {any[]} teams list of teams
 * @param {Function} setTeam set team
 * @param {Boolean} isOwner is owner of the mission
 * @param {string} missionId mission id
 * @param {Function} updateTeams update allies
 * @param {string} showOnlyTeam show only this org id
 * @param {Function} setShowOnlyTeam set org id
 * @param {Function} openChatThread open chat thread
 */
const SelectTeamList = ({
  teams,
  setTeam,
  invite,
  isOwner,
  missionId,
  updateTeams,
  showOnlyTeam,
  setShowOnlyTeam,
  openChatThread,
}) => {
  return (
    <>
      <div className="content" style={{ marginBottom: '-20px' }}>
        <h5>{pluralize('team', teams.length, true)} listed</h5>
      </div>
      <LightScrollbar className="content">
        <div className="blocks">
          {teams.length ? (
            <>
              {teams.map((team) => (
                <TeamSection
                  key={team.id}
                  team={team}
                  setTeam={setTeam}
                  isOwner={isOwner}
                  missionId={missionId}
                  updateTeams={updateTeams}
                  showOnlyTeam={showOnlyTeam}
                  setShowOnlyTeam={setShowOnlyTeam}
                  openChatThread={openChatThread}
                />
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </LightScrollbar>
    </>
  );
};

SelectTeamList.propTypes = {
  teams: PropTypes.arrayOf(teamType),
  setTeam: PropTypes.func,
  invite: PropTypes.func,
  isOwner: PropTypes.bool,
  missionId: PropTypes.string,
  updateTeams: PropTypes.func,
  showOnlyTeam: PropTypes.arrayOf(PropTypes.string),
  setShowOnlyTeam: PropTypes.func,
};

/**
 * Edit data sharing settings
 *
 * @param {any} organisations org to edit data sharing for
 * @param {Function} setDataSharing set data sharing mode
 * @param {string} missionId mission id
 */
export const EditDataSharing = ({
  organisation,
  setDataSharing,
  missionId,
}) => {
  const [privacyLevel, setPrivacyLevel] = useState(
    organisation.privacy || PrivacyLevel.None
  );

  const saveChanges = async () => {
    setDataSharing(privacyLevel);
  };

  return (
    <>
      <TestOrganisation
        organisation={organisation}
        privacyLevel={privacyLevel}
      ></TestOrganisation>
      <LightScrollbar className="content">
        <div className="blocks">
          <h5 style={{ marginTop: '20px', marginBottom: '20px' }}>
            Select Data Sharing Default
          </h5>

          <DataSharingSection
            level={PrivacyLevel.None}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent privacyLevel={PrivacyLevel.None}></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection>
          <DataSharingSection
            level={PrivacyLevel.Partial}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent
              privacyLevel={PrivacyLevel.Partial}
            ></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection>
          <DataSharingSection
            level={PrivacyLevel.Full}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent privacyLevel={PrivacyLevel.Full}></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection>
        </div>
      </LightScrollbar>
      <div className="foot">
        <DotButton className="primary block" onClick={saveChanges}>
          Save Changes
        </DotButton>
      </div>
    </>
  );
};

EditDataSharing.propTypes = {
  organisation: organisationType,
  missionId: PropTypes.string,
  setDataSharing: PropTypes.func,
};

/**
 * Ally team list item
 *
 * @param {any} team team
 * @param {Function} setSelected callback to set selected
 * @param {Function} setTeam callback to set organisation
 * @param {Function} openChatThread callback to chat with user
 */
const TeamSection = ({ team, setTeam, openChatThread }) => {
  return (
    <div
      key={team.id}
      className="item-container clickable has-hover"
      style={{
        marginLeft: '-24px',
        marginRight: '-24px',
        paddingLeft: '20px',
        paddingRight: '24px',
      }}
      onClick={() => setTeam(team)}
    >
      <div className="bar" style={{ backgroundColor: team.color }}></div>
      <div className="img">
        <Avatar entity={team} />
      </div>
      <div className="text">{team.team}</div>
      {!!openChatThread && (
        <DotButton
          ariaLabel="Chat with Team"
          onClick={async (e) => {
            await openChatThread(null, team);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon icon="comment-alt" />
        </DotButton>
      )}
      <DotButton ariaLabel="Team Info">
        <FontAwesomeIcon icon="chevron-right" />
      </DotButton>
    </div>
  );
};

TeamSection.propTypes = {
  team: teamType.isRequired,
  setTeam: PropTypes.func.isRequired,
  openChatThread: PropTypes.func,
};

/**
 * Team details
 *
 * @param {any} team team
 * @param {any[]} users team users
 * @param {any[]} roles roles list
 * @param {Function} setUser callback to set user
 * @param {Function} openChatThread callback to chat with user
 */
export const SelectedTeam = ({
  team,
  users,
  setUser,
  openChatThread,
  roles,
}) => {
  return (
    <div className="content">
      <div className="blocks" style={{ marginBottom: '20px' }}>
        <h4>Team Details</h4>

        <div className="item-container">
          <div>
            <h5>Team Name</h5>
            <p>{team.team}</p>
          </div>
          <Avatar entity={team} />
        </div>
      </div>

      <h4>Team Users</h4>
      <PersonnelList
        teams={[team]}
        users={users}
        setUser={setUser}
        openChatThread={openChatThread}
        roles={roles}
      />
    </div>
  );
};

SelectedTeam.propTypes = {
  team: teamType,
  users: PropTypes.arrayOf(userType),
  setUser: PropTypes.func.isRequired,
  openChatThread: PropTypes.func,
  roles: PropTypes.arrayOf(orgRoleType),
};
