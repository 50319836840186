/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import * as timeago from 'timeago.js';
import { v4 as uuid } from 'uuid';
import { removeLocation } from '../../api/missions';
import activeSVG from '../../assets/images/status/active.svg';
import archivedSVG from '../../assets/images/status/archived.svg';
import planningSVG from '../../assets/images/status/planning.svg';
import { endpointConfig } from '../../config/endpoint_config.js';
import { useLocation } from '../../context/LocationContext';
import { useNotification } from '../../context/NotificationContext';
import { useUser } from '../../context/UserContext';
import { historyType, missionType } from '../../enums/propTypes';
import { isMobile } from '../../utils/device';
import { getClassNames } from '../../utils/string';
import { Avatar } from '../common/Avatar';
import './missionPanel.scss';

/**
 * Mission card on home screen
 *
 * @param {any} mission mission
 * @param {any} history react router
 * @param {string} invitationId invitation Id
 * @param {bool} isAdmin is admin
 * @param {bool} disabled is disabled
 */
export const MissionPanel = ({
  mission,
  history,
  invitationId,
  isAdmin,
  disabled,
  setMissionDisabled,
}) => {
  const id = useRef(uuid());
  const panelMap = useRef(null);
  const { user } = useUser();
  const notification = useNotification();
  const locationServices = useLocation();

  const getStatus = () => {
    if (mission.archived) {
      return (
        <>
          <img src={archivedSVG} alt="archived" />
          <span>archived</span>
        </>
      );
    } else if (mission.published) {
      return (
        <>
          <img src={activeSVG} alt="active" />
          <span>active</span>
        </>
      );
    } else {
      return (
        <>
          <img src={planningSVG} alt="planning" />
          <span>planning</span>
        </>
      );
    }
  };

  const viewMission = async (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (invitationId) {
      history.push(
        endpointConfig.frontendEndpoints.missionAcceptInvitation.replace(
          ':id',
          invitationId
        )
      );
      return;
    }

    log.debug('view mission', mission);

    // Reroute to the selected mission page
    // log out of last mission
    if (
      locationServices.allowDesktopSharing &&
      notification?.lastMissionId &&
      notification?.lastMissionId !== mission.id
    ) {
      await removeLocation(notification.lastMissionId, user.id);
    }

    history.push(
      endpointConfig.frontendEndpoints.mission.replace(':missionId', mission.id)
    );
  };

  // const editMission = (page) => {
  //   if (invitationId) {
  //     history.push(
  //       endpointConfig.frontendEndpoints.missionAcceptInvitation.replace(
  //         ':id',
  //         invitationId
  //       )
  //     );
  //     return;
  //   }

  //   log.debug('edit mission', mission);

  //   // Reroute to the selected mission page
  //   if (page === 'teams') {
  //     history.push(
  //       endpointConfig.frontendEndpoints.editMissionTeams.replace(
  //         ':missionId',
  //         mission.id
  //       )
  //     );
  //   } else if (page === 'view') {
  //     history.push(
  //       endpointConfig.frontendEndpoints.mission.replace(
  //         ':missionId',
  //         mission.id
  //       )
  //     );
  //   } else {
  //     history.push(
  //       endpointConfig.frontendEndpoints.editMission.replace(
  //         ':missionId',
  //         mission.id
  //       )
  //     );
  //   }
  // };

  if (!mission) {
    return <></>;
  }

  return (
    <div
      className={getClassNames({
        card: true,
        disabled,
      })}
      onClick={async (e) => {
        if (!disabled) {
          await viewMission(e);
        }
      }}
    >
      <div className="image" ref={(el) => (panelMap.current = el)}>
        {mission.location ? (
          <img src={mission.staticMapUrl} alt="mission map" />
        ) : (
          // <BasicMap style={mapSettings.panelMapStyle} loc={mission.location} />
          <span>Undefined Map Area</span>
        )}
      </div>
      <div className="status">{getStatus()}</div>
      {/* {!invitationId && !!isAdmin && (
        <MenuButton
          className="action"
          position="bottom"
          options={
            disabled
              ? []
              : [
                  !mission.archived && mission.published
                    ? {
                        text: 'View Mission',
                        icon: 'eye',
                        onClick: viewMission,
                      }
                    : null,
                  {
                    text: 'Edit Mission',
                    icon: 'pencil-alt',
                    onClick: () => editMission(''),
                  },
                  {
                    text: 'Edit Teams',
                    icon: 'project-diagram',
                    onClick: () => editMission('teams'),
                  },
                ]
          }
          ariaLabel="Mission Menu"
        >
          <FontAwesomeIcon icon="bars" />
        </MenuButton>
      )} */}
      <div className="content">
        <div
          className="title"
          data-tip={mission.missionName}
          data-for={id.current}
        >
          {mission.missionName}
        </div>
        <div className="sub-title logos">
          <Avatar entity={mission.organisation} size="1.5rem" />
          <span>{mission.organisation?.organisationName}</span>
        </div>
        {/* TODO: Project SS */}
        {/* <div className="logos">
          {allies?.length ? (
            allies.map((a) => (
              <Avatar entity={a} key={a.organisationId} size="1.5rem" />
            ))
          ) : (
            <></>
          )}
        </div> */}
      </div>
      <div className="footer">
        Last saved: {timeago.format(mission.updated || mission._ts * 1000)}
      </div>
      <ReactTooltip id={id.current} disable={isMobile()}></ReactTooltip>
    </div>
  );
};

MissionPanel.propTypes = {
  mission: missionType,
  history: historyType,
  invitationId: PropTypes.string,
  isAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
};
