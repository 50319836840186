import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import React from 'react';
import { withRouter } from 'react-router';
import { releases } from '../../release';
import { formatDate } from '../../utils/time';
import { NavBar } from '../common/Navbar';
import { LightScrollbar } from '../common/Scrollbars';
import '../missions/view-mission/Page.scss';
import { PropTypes } from 'prop-types';
import { historyType } from '../../enums/propTypes';

/**
 * Changelog
 *
 * @param {string} message message
 */
export const Versions = withRouter(({ history }) => {
  const mostRecent = {
    ...releases[Object.keys(releases)[0]],
    version: Object.keys(releases)[0],
  };

  return (
    <div className="page">
      <NavBar history={history}></NavBar>
      <LightScrollbar className="content">
        <div className="container">
          <div className="header ">
            <FontAwesomeIcon icon="clock"></FontAwesomeIcon>
            <h1>Version History</h1>
            <div>
              <div style={{ letterSpacing: 0 }}>
                <span>
                  {pluralize('version', Object.keys(releases).length, true)}
                </span>
                <span>Updated {formatDate(mostRecent.date)}</span>
              </div>
            </div>
          </div>
          <div className="body">
            {Object.keys(releases).map((version) => (
              <Version
                key={version}
                version={version}
                release={releases[version]}
                previousVersion={
                  Object.keys(releases)[
                    Object.keys(releases).indexOf(version) + 1
                  ]
                }
              ></Version>
            ))}
          </div>
        </div>
      </LightScrollbar>
    </div>
  );
});

Versions.propTypes = {
  history: historyType,
};

/**
 * Release version
 *
 * @param {string} version current version number
 * @param {string} previousVersion previous version number
 * @param {any} release release notes
 */
const Version = ({ version, previousVersion, release }) => {
  let releaseType = 'maintenance';

  if (!previousVersion) {
    releaseType = 'major';
  } else {
    const bits = version.split('.');
    const previousBits = previousVersion.split('.');

    const majorDiff = parseInt(bits[0]) - parseInt(previousBits[0]);
    const minorDiff = parseInt(bits[1]) - parseInt(previousBits[1]);

    if (majorDiff) {
      releaseType = 'major';
    } else if (minorDiff) {
      releaseType = 'minor';
    }
  }

  const summary = [];
  if (release.newFeatures?.length) {
    summary.push('new features');
  }
  if (release.bugs?.length) {
    summary.push('bug fixes');
  }
  if (release.enhancements?.length) {
    summary.push('enhancements');
  }
  if (release.housekeeping?.length) {
    summary.push('housekeeping');
  }
  if (release.tools?.length) {
    summary.push('tooling');
  }
  if (release.documentation?.length) {
    summary.push('documentation');
  }

  const _summaryText = summary.join(', ');
  const summaryText =
    summary.length > 1
      ? `${_summaryText.substring(
          0,
          _summaryText.lastIndexOf(',')
        )} and ${_summaryText.substring(_summaryText.lastIndexOf(',') + 2)}`
      : summary[0];

  return (
    <section className="expandable expanded">
      <div className="section-header noinset">
        <span>v{version}</span>
      </div>
      <div className="section-body">
        <h3>
          v{version} ({formatDate(release.date)})
        </h3>
        <p>{`v${version} is a ${releaseType} release that includes ${summaryText}.`}</p>
        {!!release.description && <p>{release.description}</p>}

        {!!release.newFeatures?.length && (
          <>
            <h3>📢 New Features</h3>
            <ul>
              {release.newFeatures.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          </>
        )}
        {!!release.bugs?.length && (
          <>
            <h3>🐛 Bug Fixes</h3>
            <ul>
              {release.bugs.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          </>
        )}
        {!!release.enhancements?.length && (
          <>
            <h3>⭐ Enhancements</h3>
            <ul>
              {release.enhancements.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          </>
        )}
        {!!release.housekeeping?.length && (
          <>
            <h3>🧹 Housekeeping</h3>
            <ul>
              {release.housekeeping.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          </>
        )}
        {!!release.tools?.length && (
          <>
            <h3>🔨 Tooling</h3>
            <ul>
              {release.tools.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          </>
        )}
        {!!release.documentation?.length && (
          <>
            <h3>📑 Documentation</h3>
            <ul>
              {release.documentation.map((i, idx) => (
                <li key={idx}>{i}</li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="border"></div>
    </section>
  );
};

Version.propTypes = {
  version: PropTypes.string.isRequired,
  previousVersion: PropTypes.string,
  release: PropTypes.shape({
    date: PropTypes.string.isRequired,
    newFeatures: PropTypes.arrayOf(PropTypes.string),
    bugs: PropTypes.arrayOf(PropTypes.string),
    enhancements: PropTypes.arrayOf(PropTypes.string),
    housekeeping: PropTypes.arrayOf(PropTypes.string),
    tools: PropTypes.arrayOf(PropTypes.string),
    documentation: PropTypes.arrayOf(PropTypes.string),
  }),
};
