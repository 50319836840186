/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * Return string that is a max of n chars long
 *
 * @param {string} string source string
 * @param {number} n max number of chars
 * @param {string} [append='...'] append this to the end of the string
 * @return {string} new string
 */
export const firstN = (string, n, append = '...') => {
  const trunc = string.substring(0, n);
  return trunc + (trunc.length !== string.length ? append : '');
};

/**
 * Get display name of entity. Entity is a personnel, unit, organisation, or POI.
 *
 * @param {Entity} entity Personnel, unit, organisation, or POI.
 * @return {string} display name
 */
export const displayName = (entity) => {
  if (!entity) {
    return '';
  }

  if (entity.displayName) {
    return entity.displayName;
  }

  if (entity.firstname) {
    return `${entity.firstname} ${entity.lastname}`;
  }

  if (entity.user?.firstname) {
    return `${entity.user.firstname} ${entity.user.lastname}`;
  }

  if (!!entity?.teamsFull?.length) {
    return entity?.teamsFull[0]?.team;
  }

  if (entity?.team) {
    return entity?.team;
  }

  return (
    entity.role ||
    entity.team ||
    entity.organisationName ||
    entity.threadName ||
    entity.name ||
    ''
  );
};

/**
 * Get thumbnail url from image URL
 *
 * @param {string} url image url
 * @return {string} thumbnail url
 */
export const getThumbUrl = (url) => {
  try {
    const lastIndexOfDot = url.lastIndexOf('.');
    const ext = url.substring(lastIndexOfDot);
    return `${url.substring(0, lastIndexOfDot)}_thumb${ext}`;
  } catch {
    return null;
  }
};

/**
 * Validate email address. Uses RFC 5322.
 *
 * @param {*} email
 * @return {*}
 */
export const validateEmail = (email) => {
  //Email validation was failing with this regex for some users
  //const rfc =/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  const rfc = /^\S+@\S+\.\S+$/;
  // eslint-disable-next-line
  return email.match(rfc);
};

/**
 * Returns the diff of a string as markdown.
 *
 * @param {string} a string A
 * @param {string} b string B
 * @return {string} markdown
 */
export const diff = (a, b) => {
  const words1 = a.split(' ');
  const words2 = b.split(' ');

  const diff = [];

  let ptr1 = 0;
  let ptr2 = 0;

  let index = 0;

  while (ptr1 < words1.length || ptr2 < words2.length) {
    if (words1[ptr1] === words2[ptr2]) {
      diff.push(
        <span key={index}>
          {words1[ptr1]}
          <> </>
        </span>
      );
      ptr1++;
      ptr2++;
    } else if (!!words1[ptr1] && words2.indexOf(words1[ptr1]) === -1) {
      // not in 2
      diff.push(
        <span key={index} style={{ textDecoration: 'line-through' }}>
          {words1[ptr1]}
          <> </>
        </span>
      );
      ptr1++;
    } else if (!!words2[ptr2] && words1.indexOf(words2[ptr2]) === -1) {
      // not in 1
      diff.push(
        <span key={index}>
          {words2[ptr2]}
          <> </>
        </span>
      );
      ptr2++;
    }
    index++;
  }

  return diff;
};

/**
 * Returns the diff of a slate document as another slate document.
 *
 * @param {Document} a document A
 * @param {Document} b document B
 * @return {string} diff document
 */
export const documentDiff = (a, b) => {
  const stringified1 = a.map((x) => JSON.stringify(x));
  const stringified2 = b.map((x) => JSON.stringify(x));

  const diff = [];

  let ptr1 = 0;
  let ptr2 = 0;

  let bail = 0;

  while ((ptr1 < a.length || ptr2 < b.length) && bail < 1000) {
    if (stringified1[ptr1] === stringified2[ptr2]) {
      diff.push(a[ptr1]);
      ptr1++;
      ptr2++;
    } else if (
      !!stringified1[ptr1] &&
      stringified2.indexOf(stringified1[ptr1]) === -1
    ) {
      // not in 2
      const clone = JSON.parse(JSON.stringify(a[ptr1]));

      // apply strike through
      for (const t of clone.children) {
        t.strikethrough = true;
      }

      diff.push(clone);
      ptr1++;
    } else if (
      !!stringified2[ptr2] &&
      stringified1.indexOf(stringified2[ptr2]) === -1
    ) {
      // not in 1
      diff.push(b[ptr2]);
      ptr2++;
    }
    bail++;
  }

  return diff;
};

/**
 * Get two char string representation of another string
 *
 * @param {string} string string
 * @return {string} two letter string representation
 */
export const getInitials = (string) => {
  const rgx = /(\p{L}{1})\p{L}*/gu;

  let initials = [...string.matchAll(rgx)] || [];
  initials = (initials.shift()?.[1] || "").toUpperCase() + (initials.pop()?.[1] || "").toUpperCase(); //prettier-ignore
  return initials;
};

/**
 * Get two char representation of entity AS PNG
 *
 * @param {Entity} entity Personnel, unit, organisation, or POI.
 * @param {number} [size=48] size of image
 * @return {string} base64 PNG image
 */
export const avatarPNG = (entity, size = 48) => {
  const initials = getInitials(displayName(entity));
  const color = entity?.color || '#2c7691';

  let canvas = document.createElement('canvas');

  canvas.width = size;
  canvas.height = size;

  const ctx = canvas.getContext('2d');

  ctx.fillStyle = color;

  ctx.beginPath();
  ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, true); // Outer circle
  ctx.fill('nonzero');

  ctx.fillStyle = '#ffffff';

  ctx.font = `600 ${size / 2}px Barlow,sans-serif`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  // accommodate for descenders
  ctx.fillText(initials, size / 2, (size * 9) / 16);

  const url = canvas.toDataURL('image/png');

  canvas = null;

  return url;
};

/**
 * Implementation of ngClass - can optionally add additional styles
 *
 * @param {*} classNames
 * @param {*} additional
 * @return {*}
 */
export const getClassNames = (classNames, ...additional) => {
  const classSet = new Set();

  const keys = Object.keys(classNames);
  for (const key of keys) {
    if (!!classNames[key]) {
      classSet.add(key);
    }
  }

  if (additional?.length) {
    for (const a of additional) {
      if (a) {
        classSet.add(a);
      }
    }
  }

  return [...classSet].join(' ');
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const latLngToMGRS = (Lat, Long) => {
  if (Lat < -80) return 'Too far South';
  if (Lat > 84) return 'Too far North';
  var c = 1 + Math.floor((Long + 180) / 6);
  var e = c * 6 - 183;
  var k = (Lat * Math.PI) / 180;
  var l = (Long * Math.PI) / 180;
  var m = (e * Math.PI) / 180;
  var n = Math.cos(k);
  var o = 0.006739496819936062 * Math.pow(n, 2);
  var p = 40680631590769 / (6356752.314 * Math.sqrt(1 + o));
  var q = Math.tan(k);
  var r = q * q;
  //var s = r * r * r - Math.pow(q, 6);
  var t = l - m;
  var u = 1.0 - r + o;
  var v = 5.0 - r + 9 * o + 4.0 * (o * o);
  var w = 5.0 - 18.0 * r + r * r + 14.0 * o - 58.0 * r * o;
  var x = 61.0 - 58.0 * r + r * r + 270.0 * o - 330.0 * r * o;
  var y = 61.0 - 479.0 * r + 179.0 * (r * r) - r * r * r;
  var z = 1385.0 - 3111.0 * r + 543.0 * (r * r) - r * r * r;
  var aa =
    p * n * t +
    (p / 6.0) * Math.pow(n, 3) * u * Math.pow(t, 3) +
    (p / 120.0) * Math.pow(n, 5) * w * Math.pow(t, 5) +
    (p / 5040.0) * Math.pow(n, 7) * y * Math.pow(t, 7);
  var ab =
    6367449.14570093 *
      (k -
        0.00251882794504 * Math.sin(2 * k) +
        0.00000264354112 * Math.sin(4 * k) -
        0.00000000345262 * Math.sin(6 * k) +
        0.000000000004892 * Math.sin(8 * k)) +
    (q / 2.0) * p * Math.pow(n, 2) * Math.pow(t, 2) +
    (q / 24.0) * p * Math.pow(n, 4) * v * Math.pow(t, 4) +
    (q / 720.0) * p * Math.pow(n, 6) * x * Math.pow(t, 6) +
    (q / 40320.0) * p * Math.pow(n, 8) * z * Math.pow(t, 8);
  aa = aa * 0.9996 + 500000.0;
  ab = ab * 0.9996;
  if (ab < 0.0) ab += 10000000.0;
  var ad = 'CDEFGHJKLMNPQRSTUVWXX'.charAt(Math.floor(Lat / 8 + 10));
  var ae = Math.floor(aa / 100000);
  var af = ['ABCDEFGH', 'JKLMNPQR', 'STUVWXYZ'][(c - 1) % 3].charAt(ae - 1);
  var ag = Math.floor(ab / 100000) % 20;
  var ah = ['ABCDEFGHJKLMNPQRSTUV', 'FGHJKLMNPQRSTUVABCDE'][(c - 1) % 2].charAt(
    ag
  );
  function pad(val) {
    if (val < 10) {
      val = '0000' + val;
    } else if (val < 100) {
      val = '000' + val;
    } else if (val < 1000) {
      val = '00' + val;
    } else if (val < 10000) {
      val = '0' + val;
    }
    return val;
  }
  aa = Math.floor(aa % 100000);
  aa = pad(aa);
  ab = Math.floor(ab % 100000);
  ab = pad(ab);
  return c + ad + ' ' + af + ah + ' ' + aa + ' ' + ab;
};
