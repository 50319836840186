/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { fileConfig } from '../../config/fileConfig';
import { validateFile } from '../../utils/files';
import { compressImage, getImageDimensions } from '../../utils/image';
import { getClassNames } from '../../utils/string';
import './upload-image.scss';
import { PropTypes } from 'prop-types';
import { isMobile } from '../../utils/device';

/**
 * Image uploader
 *
 * @param {{url: string, error: string}} photoUrl image to crop
 * @param {Function} setImage callback to set cropped image
 */
export const UploadImage = ({ photoUrl, setImage }) => {
  const imgPreview = useRef();
  const id = useRef(uuid());
  const fileUpload = useRef();

  const handleImage = async (e) => {
    log.debug('handleImage', e);

    // convert image file to base64 string
    const file = e.target.files[0];

    if (file && imgPreview.current) {
      imgPreview.current.src = URL.createObjectURL(file);
      const { height, width } = await getImageDimensions(imgPreview.current);

      //if we resize by width, this is the max width of compressed image
      const MAX_SIZE = fileConfig.maxImageWidth;

      const widthRatio = MAX_SIZE / width;
      const heightRatio = MAX_SIZE / height;
      let minRatio = Math.min(widthRatio, heightRatio, 1);

      const compressed = await compressImage(
        imgPreview.current,
        minRatio,
        width,
        height,
        fileConfig.quality
      );

      if (compressed.blob) {
        compressed.error = validateFile({
          size: compressed.blob.size,
          name: file.name,
        });
      }

      URL.revokeObjectURL(imgPreview.current);

      setImage(compressed);
    }
  };

  return (
    <>
      <div className="upload-image">
        <div>
          <h4>{photoUrl?.url ? 'Image Uploaded' : 'Upload Image'}</h4>
          <label
            htmlFor="uploadCropImage"
            className="creat-org-upload-image-input"
          >
            {photoUrl?.url ? 'Replace image' : 'Select image to upload'}
            <input
              ref={fileUpload}
              id="uploadCropImage"
              type="file"
              onChange={handleImage}
            />
          </label>
        </div>
        <div
          className={getClassNames({
            photo: true,
            invalid: !!photoUrl?.error,
          })}
          onClick={() => {
            fileUpload.current.click();
          }}
        >
          {photoUrl?.url ? (
            <img src={photoUrl.url} alt="uploaded"></img>
          ) : (
            <FontAwesomeIcon icon="plus" width="24px" height="24px" />
          )}
          {!!photoUrl?.error && (
            <>
              <div
                className="warning"
                data-tip={photoUrl?.error}
                data-for={id.current}
              >
                <FontAwesomeIcon icon="exclamation" />
              </div>
              <ReactTooltip id={id.current} disable={isMobile()}></ReactTooltip>
            </>
          )}
        </div>
        <img ref={imgPreview} alt="preview" style={{ display: 'none' }} />
      </div>
    </>
  );
};

UploadImage.propTypes = {
  photoUrl: PropTypes.shape({
    url: PropTypes.string,
    error: PropTypes.string,
  }),
  setImage: PropTypes.func.isRequired,
};
