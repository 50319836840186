/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, { useEffect, useState } from 'react';
import { useSidebar } from '../../context/SidebarContext';
import { getClassNames } from '../../utils/string';
import './Sidebar.scss';
import { PropTypes } from 'prop-types';
import { CallState } from '../../enums/call';
import { useComms } from '../../context/CommsContext';

const SIDEBAR_EXPOSED_WIDTH = 26.25;
const SIDEBAR_HALF_SCREEN_WIDTH = 50;

/**
 * Pop out sidebar control
 *
 * @param {Jsx} children sidebar children
 * @param {string} id sidebar id
 * @param {boolean} halfScreen flag to determine sidebar width
 */
export const Sidebar = ({ children, id, halfScreen }) => {
  const sidebars = useSidebar();
  const [width, setWidth] = useState(0);
  const comms = useComms();

  useEffect(() => {
    if (sidebars.isOpen(id)) {
      // we're open
      if (halfScreen) {
        setWidth(SIDEBAR_HALF_SCREEN_WIDTH);
        sidebars.setHalfScreen(true);
      } else {
        setWidth(SIDEBAR_EXPOSED_WIDTH);
      }
    } else {
      setWidth(0);
    }
    // eslint-disable-next-line
  }, [sidebars.sidebars]);

  return (
    <div
      className={getClassNames({
        'pop-out-sidebar': true,
        'is-open': sidebars.isOpen(id),
        call: comms.callState !== CallState.Disconnected,
      })}
      id={id}
      style={{
        right: halfScreen
          ? `${0 - SIDEBAR_HALF_SCREEN_WIDTH + width}rem`
          : `${0 - SIDEBAR_EXPOSED_WIDTH + width}rem`,
        width: halfScreen && `${SIDEBAR_HALF_SCREEN_WIDTH}rem`,
      }}
    >
      {!!width && children}
    </div>
  );
};

Sidebar.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  halfScreen: PropTypes.bool,
};
