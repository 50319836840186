/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DotButton } from '../common/buttons/DotButton';
import { LightScrollbar } from '../common/Scrollbars';
import { Control1 } from './form-controls/Control1';
import { Control4 } from './form-controls/Control4';
import { Control8 } from './form-controls/Control8';
import { useUser } from '../../context/UserContext';
import { useSidebar } from '../../context/SidebarContext';
import { getClassNames } from '../../utils/string';

/**
 * CASEVAC Form
 *
 */
export const Casevac = ({ missionId, cancelForm }) => {
  const [hideContent, setHideContent] = useState(false);
  const [hidePanel, setHidePanel] = useState([]);
  const userValue = useUser();
  const sidebars = useSidebar();

  const panels = [
    {
      title: 'Serial and Destination',
      controls: [
        {
          type: '4',
          label: 'Enter Names and/or Units to send CASEVAC to',
          required: true,
        },
      ],
    },
    {
      title: '1. Location',
      controls: [],
    },
    {
      title: '2. Callsign/Frequency',
      controls: [
        {
          type: '1',
          required: true,
          tip: 'What is your radio callsign and can you be contacted by CASEVAC Dispatch',
        },
      ],
    },
    {
      title: '3. Patients by Precedence',
      tip: 'Priority 1 (< 1hr) Priority 2 (< 4hrs) Priority 3 (< 24hrs)',
      controls: [],
    },
    {
      title: '4. Special Equipment',
      controls: [],
    },
    {
      title: '5. Patients by Type',
      controls: [],
    },
    {
      title: '6. Security at Pickup Site',
      controls: [
        {
          values: [
            'N - No Enemy',
            'P - Possible Enemy',
            'E - Enemy in Area, Proceed with caution',
            'X - Enemy in Area, Armed Escort required',
          ],
        },
      ],
    },
    {
      title: '7. Method of Marking',
      controls: [],
    },
    {
      title: '8. Patient Nationality',
      controls: [],
    },
    {
      title: '9. NBC Contamination',
      controls: [
        {
          values: [
            'Nil - None',
            'N - Nuclear',
            'B - Biological',
            'C - Chemical',
          ],
        },
      ],
    },
  ];

  return (
    <div className="form">
      <div className={getClassNames({ header: true, important: true })}>
        <div className="left">
          <div className="avatar">CV</div>
          <div className="form-title">CASEVAC</div>
        </div>
        <div className="right">
          <div className="last-updated">Last updated:</div>
          <div className="chevron" onClick={() => setHideContent(!hideContent)}>
            <FontAwesomeIcon
              icon={!hideContent ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
      </div>
      <LightScrollbar>
        <div
          className="form-content"
          style={{ display: hideContent && 'none' }}
        >
          {!!panels &&
            panels.map((p, idx) => {
              return (
                <div className="panel" key={idx}>
                  <div className="panel-header">
                    <div className="left">
                      <div className="panel-title">{p.title}</div>
                      {!!p.tip && <div className="panel-tip">{p.tip}</div>}
                    </div>
                    <div className="right">
                      {/* <div className="last-updated">Last updated:</div> */}
                      <div
                        className="chevron"
                        onClick={(e) => {
                          e.preventDefault();
                          const hidePanelCopy = [...hidePanel];
                          hidePanelCopy[idx] = !hidePanel[idx];
                          setHidePanel(hidePanelCopy);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={!hidePanel[idx] ? 'chevron-up' : 'chevron-down'}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="panel-content"
                    style={{ display: hidePanel[idx] && 'none' }}
                  >
                    {idx === 0 && (
                      <Control4
                        control={p.controls[0]}
                        user={userValue}
                        missionId={missionId}
                      />
                    )}
                    {idx === 2 && <Control1 control={p.controls[0]} />}
                    {(idx === 6 || idx === 9) && (
                      <Control8
                        control={p.controls[0]}
                        twoActionButtons={true}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="footer">
          <div className="button-panel">
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                sidebars.closeAll();
                cancelForm();
              }}
            >
              DISCARD FORM
            </DotButton>
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              SAVE AS DRAFT
            </DotButton>
            <DotButton
              className="primary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              SEND FORM
            </DotButton>
          </div>
        </div>
      </LightScrollbar>
    </div>
  );
};

Casevac.propTypes = {
  missionId: PropTypes.any.isRequired,
  cancelForm: PropTypes.func.isRequired,
};
