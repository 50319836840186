/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import {
  AppInsightsErrorBoundary,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import * as log from 'loglevel';
import { React, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Anonymous } from './Anonymous';
import './App.scss';
// eslint-disable-next-line
import { reactPlugin } from './AppInsights';
import { Authenticated } from './Authenticated';
import { EditMapStyles } from './components/admin/EditMapStyles';
import { Versions } from './components/generic-components/Versions';
import { ForgotPassword } from './components/login/ForgotPassword';
import { Invitation } from './components/login/Invitation';
import { Login } from './components/login/Login';
import { SetPassword } from './components/login/SetPassword';
import { AcceptInvitation } from './components/missions/create-mission/invite/AcceptInvitation';
import { DeclineInvitation } from './components/missions/create-mission/invite/DeclineInvitation';
import { ExpiredInvitation } from './components/missions/create-mission/invite/ExpiredInvitation';
import { MissionNavigation } from './components/missions/MissionNavigation';
import { ActiveMissions } from './components/missions/view-mission/ActiveMissions';
import { Comms } from './components/missions/view-mission/Comms';
import { Mission } from './components/missions/view-mission/Mission';
import { Orders } from './components/missions/view-mission/Orders';
import { EditOrganisation } from './components/organisations-and-users/organisations/EditOrganisation';
import { Organisations } from './components/organisations-and-users/organisations/Organisations';
import { UsersAndGroups } from './components/organisations-and-users/users-and-groups/UsersAndGroups';
import { ThreadRedirect } from './components/redirects/ThreadRedirect';
import { Home } from './components/website/Home';
import { PrivacyPolicy } from './components/website/PrivacyPolicy';
import { TermsOfService } from './components/website/TermsOfService';
import { endpointConfig } from './config/endpoint_config.js';
import './config/fontawesome'; // App icons
import { secrets } from './config/secrets';
import { DarkModeProvider } from './context/DarkModeContext';
import { JoyrideProvider } from './context/JoyrideContext';
import { LoadingProvider } from './context/LoadingContext';
import { MediaProvider } from './context/MediaContext';
import { SidebarProvider } from './context/SidebarContext';
import { BottombarProvider } from './context/BottombarContext';
import { UserProvider } from './context/UserContext';

const routes = endpointConfig.frontendEndpoints;

if (secrets.ENVIRONMENT === 'dev') {
  log.enableAll(true);
} else if (secrets.ENVIRONMENT === 'test') {
  log.enableAll(true);
} else {
  log.setLevel('error', true);
}

/**
  Global application component.
  Handles routing and authentication.
*/
export const App = withAITracking(reactPlugin, () => {
  useEffect(() => {
    if (window.Notification) {
      log.debug('checking permissions', window.Notification.permission);
      if (
        window.Notification.permission !== 'denied' &&
        window.Notification.permission !== 'granted'
      ) {
        window.Notification.requestPermission();
      }
    }
  }, []);

  return (
    <AppInsightsErrorBoundary appInsights={reactPlugin}>
      <div className="App">
        <UserProvider>
          <LoadingProvider>
            <DarkModeProvider>
              <JoyrideProvider>
                <SidebarProvider>
                  <BottombarProvider>
                    <MediaProvider>
                      <BrowserRouter>
                        <Switch>
                          <Route
                            path={routes.home}
                            exact
                            render={(props) => (
                              <Anonymous>
                                <Home {...props} />
                              </Anonymous>
                            )}
                          />
                          <Route
                            path={routes.privacyPolicy}
                            exact
                            render={(props) => (
                              <Anonymous>
                                <PrivacyPolicy {...props} />
                              </Anonymous>
                            )}
                          />
                          <Route
                            path={routes.termsOfService}
                            exact
                            render={(props) => (
                              <Anonymous>
                                <TermsOfService {...props} />
                              </Anonymous>
                            )}
                          />
                          <Route
                            path={routes.login}
                            exact
                            render={(props) => (
                              <Anonymous>
                                <Login {...props} />
                              </Anonymous>
                            )}
                          />
                          <Route
                            path={routes.forgotPassword}
                            exact
                            render={(props) => (
                              <Anonymous>
                                <ForgotPassword {...props} />
                              </Anonymous>
                            )}
                          />
                          <Route
                            path={routes.setPassword}
                            exact
                            render={(props) => (
                              <Anonymous>
                                <SetPassword {...props} />
                              </Anonymous>
                            )}
                          />
                          <Route
                            path={routes.invitation}
                            render={(props) => (
                              <Anonymous>
                                <Invitation {...props} />
                              </Anonymous>
                            )}
                          />
                          <Route
                            path={routes.missionAcceptInvitation}
                            render={(props) => (
                              <Authenticated {...props}>
                                <AcceptInvitation {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.missionDeclineInvitation}
                            render={(props) => (
                              <Authenticated {...props}>
                                <DeclineInvitation {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.missionExpiredInvitation}
                            render={(props) => (
                              <Authenticated {...props}>
                                <ExpiredInvitation {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.missionNav}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <MissionNavigation {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.missionNavMap}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <ActiveMissions {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.mission}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <Mission {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.missionOrders}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <Orders {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.missionComms}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <Comms {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.organisations}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <Organisations {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.organisation}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <EditOrganisation {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.orgUsers}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <UsersAndGroups {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.editMapStyles}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <EditMapStyles {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.missionCommsThread}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <ThreadRedirect {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route
                            path={routes.versions}
                            exact
                            render={(props) => (
                              <Authenticated {...props}>
                                <Versions {...props} />
                              </Authenticated>
                            )}
                          />
                          <Route path="/">
                            <Anonymous>
                              <Redirect to={routes.login} />
                            </Anonymous>
                          </Route>
                        </Switch>
                      </BrowserRouter>
                    </MediaProvider>
                  </BottombarProvider>
                </SidebarProvider>
              </JoyrideProvider>
            </DarkModeProvider>
          </LoadingProvider>
        </UserProvider>
      </div>
    </AppInsightsErrorBoundary>
  );
});
