/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileSize from 'filesize';
import { useSelected, useFocused } from 'slate-react';
import { Avatar } from '../components/common/Avatar';
import { getFileIcon } from './files';
import { getClassNames } from './string';

/**
 * Render rich text elements
 *
 * @param {*} richtext rich text
 * @return {Jsx} rich text elements
 */
export const renderRichText = (richtext) => {
  return (
    <>
      {!!richtext?.length &&
        richtext.map((p, index) => <RenderedElement key={index} element={p} />)}
    </>
  );
};

/**
 * Rich text element. Renders element and then may have child elements.
 *
 * @param {*} { element } element
 * @return {Jsx} Rich text element
 */
const RenderedElement = ({ element }) => {
  if (!element.children?.length) {
    return <Leaf leaf={element}>{element.text}</Leaf>;
  } else {
    return (
      <Element element={element}>
        {element.children.map((c, index) => (
          <RenderedElement key={index} element={c} />
        ))}
      </Element>
    );
  }
};

/**
 * Element. Convert element to HTML
 *
 * @param {*} { attributes, children, element } Element params
 * @return {Jsx} HTML element
 */
export const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'image':
      return (
        <Image
          {...{
            attributes,
            children,
            element,
          }}
        />
      );
    case 'file':
      return (
        <Image
          {...{
            attributes,
            children,
            element,
          }}
        />
      );
    case 'mention':
      return (
        <Mention
          {...{
            attributes,
            children,
            element,
          }}
        />
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

/**
 * Leaf. Lowest point in element tree.
 *
 * @param {*} { attributes, children, leaf } Leaf params
 * @return {Jsx} HTML leaf
 */
export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  if (leaf.color) {
    if (!attributes) {
      attributes = {};
    }
    attributes.style = { color: leaf.color };
  }

  return <span {...attributes}>{children}</span>;
};

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <span
      {...attributes}
      contentEditable={false}
      className={getClassNames({ mention: true, selected, focused })}
      style={{}}
    >
      <Avatar entity={element.taggable} size="1.75rem" />
      {element.taggable.name}
      {children}
    </span>
  );
};

const Image = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <div
      {...attributes}
      contentEditable={false}
      className={getClassNames({ 'slate-image': true, selected, focused })}
    >
      <img src={element.url} alt={element.name} />
      <div>
        {element.name}
        <span>{fileSize(element.size)}</span>
      </div>
      {children}
    </div>
  );
};

// eslint-disable-next-line
const File = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  const icon = getFileIcon(element);

  return (
    <div
      {...attributes}
      contentEditable={false}
      className={getClassNames({
        'slate-file': true,
        selected,
        focused,
      })}
    >
      <div>
        <FontAwesomeIcon icon={icon} height={24} width={24} />
      </div>
      <div>
        {element.name}
        <span>{fileSize(element.size)}</span>
      </div>
      {children}
    </div>
  );
};
