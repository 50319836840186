/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { del, get, post } from './global';

/**
 * Get all current forms for a mission
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const getForms = async (missionId) => {
  const forms = await get(`/missions/${missionId}/forms`);

  return forms;
};

/**
 * Create a new mission form
 *
 * @param {string} missionId mission id
 * @param {any} form form to be saved
 * @return {any} API response
 */
export const createForm = async (missionId, form) => {
  return await post(`/missions/${missionId}/forms`, form);
};

/**
 * Get an existing form by id
 *
 * @param {string} missionId mission id
 * @param {string} formId form id
 * @return {any} API response
 */
export const getForm = async (missionId, formId) => {
  const form = await get(`/missions/${missionId}/forms/${formId}`);

  return form;
};

/**
 * Update a form by id
 *
 * @param {string} missionId mission id
 * @param {string} formId form id
 * @param {any} form form to be saved
 * @return {any} API response
 */
export const updateForm = async (missionId, formId, form) => {
  await post(`/missions/${missionId}/forms/${formId}`, form);
};

/**
 * Delete a form by id
 *
 * @param {string} missionId mission id
 * @param {string} formId form id
 * @return {any} API response
 */
export const deleteForm = async (missionId, formId) => {
  await del(`/missions/${missionId}/forms/${formId}`);
};

/**
 * Send a form
 *
 * @param {string} missionId mission id
 * @param {string} formId form id
 * @return {any} API response
 */
export const sendForm = async (missionId, formId) => {
  await post(`/missions/${missionId}/forms/${formId}/send`);
};

/**
 * Update a form state
 *
 * @param {string} missionId mission id
 * @param {string} formId form id
 * @param {string} state new form state
 * @return {any} API response
 */
export const updateFormState = async (missionId, formId, state) => {
  await post(`/missions/${missionId}/forms/${formId}/state`, { state: state });
};

/**
 * Update a form status
 *
 * @param {string} missionId mission id
 * @param {string} formId form id
 * @param {string} to recipient object
 * @return {any} API response
 */
export const updateFormStatus = async (missionId, formId, to) => {
  await post(`/missions/${missionId}/forms/${formId}/status`, to);
};
