/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

export const CallState = Object.freeze({
  Disconnected: 'Disconnected',
  Connected: 'Connected',
  Outgoing: 'Outgoing',
  Ringing: 'Ringing',
  Connecting: 'Connecting',
  Disconnecting: 'Disconnecting',
  None: 'None',
  LocalHold: 'LocalHold',
  RemoteHold: 'RemoteHold',
  InLobby: 'InLobby',
  EarlyMedia: 'EarlyMedia',
});
