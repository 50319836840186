/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

export const FeatureType = Object.freeze({
  Mission: 'mission-area',
  Geofence: 'geofence',
  Annotation: 'annotation',
  Point: 'point-of-interest',
  Line: 'line',
  Photo: 'photo',
  Waypoint: 'waypoint',
  Shape: 'shape',
  Symbol: 'symbol',
});
