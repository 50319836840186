/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import axios from 'axios';
import * as log from 'loglevel';
import { secrets } from '../config/secrets';

/** @type {number} global timeout for all API calls */
const TIMEOUT = 10000;

/** @type {any} Axios configuration */
const axiosConfig = {
  headers: { Authorization: null },
};

/**
 * Globally set auth token. BASIC auth token.
 *
 * @param {*} jwt Java Web Token
 */
export const configureJWT = (jwt) => {
  axiosConfig.headers.Authorization = jwt;
};

/**
 * Generic GET method call
 *
 * @param {string} endpoint RELATIVE API endponit
 * @param {any} headers Any additional headers
 * @param {string} api null or 'realtime'
 * @return {any} response data
 */
export const get = async (endpoint, headers, api) => {
  const source = axios.CancelToken.source();

  const timeout = setTimeout(() => {
    source.cancel();
    log.error('timeout', endpoint);
    // Timeout Logic
  }, TIMEOUT);

  const config = { ...axiosConfig };
  if (api === 'realtime') {
    config.baseURL = secrets.API_ENDPOINT_REALTIME;
  } else {
    config.baseURL = secrets.API_ENDPOINT;
  }
  if (headers) {
    config.headers = { ...config.headers, headers };
  }
  config.cancelToken = source.token;

  const res = await axios
    .get(endpoint, config)
    .then((rs) => {
      return rs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
  return res.data;
};

/**
 * Generic GET method call
 *
 * @param {string} endpoint RELATIVE API endponit
 * @param {any} headers Any additional headers
 * @param {string} api null or 'realtime'
 * @return {any} response data
 */
export const getBlob = async (endpoint, headers, api) => {
  const source = axios.CancelToken.source();

  const timeout = setTimeout(() => {
    source.cancel();
    log.error('timeout', endpoint);
    // Timeout Logic
  }, TIMEOUT);

  const config = { ...axiosConfig };
  if (api === 'realtime') {
    config.baseURL = secrets.API_ENDPOINT_REALTIME;
  } else {
    config.baseURL = secrets.API_ENDPOINT;
  }
  if (headers) {
    config.headers = { ...config.headers, headers };
  }
  config.cancelToken = source.token;
  config.responseType = 'blob';

  const res = await axios
    .get(endpoint, config)
    .then((rs) => {
      return rs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
  return res.data;
};

/**
 * Generic POST method call
 *
 * @param {string} endpoint RELATIVE API endponit
 * @param {any} data Request data
 * @param {any} headers Any additional headers
 * @param {string} api null or 'realtime'
 * @return {any} response data
 */
export const post = async (endpoint, data, headers, api) => {
  const source = axios.CancelToken.source();

  const timeout = setTimeout(() => {
    source.cancel();
    log.error('timeout', endpoint);
    // Timeout Logic
  }, TIMEOUT);

  const config = { ...axiosConfig };
  if (api === 'realtime') {
    config.baseURL = secrets.API_ENDPOINT_REALTIME;
  } else {
    config.baseURL = secrets.API_ENDPOINT;
  }
  if (headers) {
    config.headers = { ...config.headers, headers };
  }
  config.cancelToken = source.token;

  const res = await axios
    .post(endpoint, data, config)
    .then((rs) => {
      clearTimeout(timeout);
      return rs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
  return res.data;
};

export const patch = async (endpoint, data, headers, api) => {
  const source = axios.CancelToken.source();

  const timeout = setTimeout(() => {
    source.cancel();
    log.error('timeout', endpoint);
    // Timeout Logic
  }, TIMEOUT);

  const config = { ...axiosConfig };
  if (api === 'realtime') {
    config.baseURL = secrets.API_ENDPOINT_REALTIME;
  } else {
    config.baseURL = secrets.API_ENDPOINT;
  }
  if (headers) {
    config.headers = { ...config.headers, headers };
  }
  config.cancelToken = source.token;

  const res = await axios
    .patch(endpoint, data, config)
    .then((rs) => {
      clearTimeout(timeout);
      return rs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
  return res.data;
};

/**
 * Generic PUT method call
 *
 * @param {string} endpoint RELATIVE API endponit
 * @param {any} data Request data
 * @param {any} headers Any additional headers
 * @param {string} api null or 'realtime'
 * @return {any} response data
 */
export const put = async (endpoint, data, headers, api) => {
  const source = axios.CancelToken.source();

  const timeout = setTimeout(() => {
    source.cancel();
    log.error('timeout', endpoint);
    // Timeout Logic
  }, TIMEOUT);

  const config = { ...axiosConfig };
  if (api === 'realtime') {
    config.baseURL = secrets.API_ENDPOINT_REALTIME;
  } else {
    config.baseURL = secrets.API_ENDPOINT;
  }
  if (headers) {
    config.headers = { ...config.headers, headers };
  }
  config.cancelToken = source.token;

  const res = await axios
    .put(endpoint, data, config)
    .then((rs) => {
      clearTimeout(timeout);
      return rs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
  return res.data;
};

/**
 * Generic DELETE method call
 *
 * @param {string} endpoint RELATIVE API endponit
 * @param {any} headers Any additional headers
 * @param {string} api null or 'realtime'
 * @return {any} response data
 */
export const del = async (endpoint, headers, api) => {
  const source = axios.CancelToken.source();

  const timeout = setTimeout(() => {
    source.cancel();
    log.error('timeout', endpoint);
    // Timeout Logic
  }, TIMEOUT);

  const config = { ...axiosConfig };
  if (api === 'realtime') {
    config.baseURL = secrets.API_ENDPOINT_REALTIME;
  } else {
    config.baseURL = secrets.API_ENDPOINT;
  }
  if (headers) {
    config.headers = { ...config.headers, headers };
  }
  config.cancelToken = source.token;

  const res = await axios
    .delete(endpoint, config)
    .then((rs) => {
      return rs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
  return res.data;
};

/**
 * Generic DELETE method call, with body
 *
 * @param {string} endpoint RELATIVE API endponit
 * @param {any} data Request data
 * @param {any} headers Any additional headers
 * @param {string} api null or 'realtime'
 * @return {any} response data
 */
export const delWithBody = async (endpoint, data, headers, api) => {
  const source = axios.CancelToken.source();

  const timeout = setTimeout(() => {
    source.cancel();
    log.error('timeout', endpoint);
    // Timeout Logic
  }, TIMEOUT);

  const config = { ...axiosConfig };
  if (api === 'realtime') {
    config.baseURL = secrets.API_ENDPOINT_REALTIME;
  } else {
    config.baseURL = secrets.API_ENDPOINT;
  }
  if (headers) {
    config.headers = { ...config.headers, headers };
  }
  config.cancelToken = source.token;
  config.url = endpoint;
  config.method = 'DELETE';
  config.data = data;

  const res = await axios
    .request(config)
    .then((rs) => {
      return rs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
  return res.data;
};
