/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import * as timeago from 'timeago.js';
import { getThreadByUsers } from '../../../api/comms';
import { getElevation, what3words } from '../../../api/mapping';
import { getMissionPersonnel } from '../../../api/orgs_users';
import criticalWhite from '../../../assets/svg/critical-white.svg';
import { useComms } from '../../../context/CommsContext';
import { useEvent } from '../../../context/EventContext';
import { useNotification } from '../../../context/NotificationContext';
import { useSidebar } from '../../../context/SidebarContext';
import { useUser } from '../../../context/UserContext';
import { CallState } from '../../../enums/call';
import {
  locationType,
  personnelType,
  teamType,
  userType,
} from '../../../enums/propTypes';
import { Signal } from '../../../enums/signal';
import { handleError } from '../../../utils/error';
import { toLngLat } from '../../../utils/geo';
import { NotificationStatus } from '../../../utils/notifications';
import { cancellablePromise } from '../../../utils/promise';
import { displayName, getClassNames } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { Battery } from '../../common/Battery';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { Tabs } from '../../common/Tabs';
import { PersonnelMessages } from '../messages/PersonnelMessages';
import { PersonnelTasks } from '../tasks/PersonnelTasks';
import { LocationInfo } from './LocationInfo';
import svgDisconnected from '../../../assets/svg/offline.svg';
import badge from '../../../assets/svg/dot-badge.svg';
import './MissionSidebar.scss';

/**
 * Sidebar - show mission personnel
 *
 * @param {string} missionId mission id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {string} userId all orgs involved in mission
 * @param {any} location location data
 * @param {'overview' || 'messages' || 'tasks' || 'files'} startingTab all orgs involved in mission
 * @param {Boolean} isCurrentUser is logged in user
 * @param {Boolean} liveTracking live tracking enabled
 * @param {Boolean} isComms is being viewed from Comms page
 * @param {Function} openDM open DM with a user in the thread
 * @param {any[]} users users data
 * @param {any[]} teams teams data
 * @param {any} bearing current bearing of the map
 */
export const MissionPersonnel = ({
  closeSidebar,
  missionId,
  userId,
  startingTab,
  isCurrentUser,
  liveTracking,
  setLiveTracking,
  isComms = false,
  openDM,
  users,
  teams,
  bearing,
}) => {
  const [tab, setTab] = useState(startingTab || 'overview');
  const userValue = useUser();
  const notification = useNotification();
  const sidebars = useSidebar();
  const comms = useComms();
  const [loading, setLoading] = useState(true);

  const [personnel, setPersonnel] = useState(null);
  const [location, setLocation] = useState(null);

  const [selectedOrg, setSelectedOrg] = useState(null);

  useEffect(() => {
    setLoading(true);
    setTab('overview');

    log.debug('get', userId);
    const stub = async () => {
      if (userId) {
        const user = await getMissionPersonnel(missionId, userId);
        user.critical = notification.notifications.find(
          (n) =>
            n.target?.id === userId && n.status === NotificationStatus.critical
        );
        user.teams = teams.filter(
          (t) => t.users && t.users.includes(user?.user.id)
        );
        setPersonnel(user);
        if (user?.location) {
          log.debug('set location,', user?.location);
          setLocation({ ...user?.location });
        } else {
          setLocation(null);
        }
        setLoading(false);
      }
    };
    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (personnel) {
      setPersonnel({
        ...personnel,
        critical: notification.notifications.find(
          (n) =>
            n.target?.id === userId && n.status === NotificationStatus.critical
        ),
      });
    }
    // eslint-disable-next-line
  }, [notification.notifications]);

  useEffect(() => {
    setTab(startingTab || 'overview');
  }, [startingTab]);

  const exit = () => {
    if (selectedOrg) {
      setSelectedOrg(null);
    } else {
      closeSidebar();
    }
  };

  const locationUpdate = useCallback(
    async (loc) => {
      log.debug('locationUpdate', loc);
      if (!!loc?.location?.coords) {
        const _w3wrs = await what3words(
          loc.location.coords.latitude,
          loc.location.coords.longitude
        );

        loc.address = _w3wrs.address;
        loc.w3w = _w3wrs.w3w;

        if (!!loc?.location?.coords?.altitude) {
          loc.location.coords.altitude = await getElevation(
            loc.location.coords.latitude,
            loc.location.coords.longitude
          );
        }

        loc.lastSeen = loc._ts;
        if (!!loc?.location?.activity) {
          loc.location.activity = location.location.activity;
        }
        if (!!loc?.location?.battery && loc?.location?.battery !== 0) {
          loc.location.battery = location.location.battery;
        }
      }

      setLocation(loc);
    },
    [location]
  );

  useEvent(Signal.LocationUpdate, (loc) => {
    if (loc.userId === userId) {
      locationUpdate(loc).then(() => {});
    }
  });

  let activity = 'Stationary';
  let activityIcon = 'street-view';

  let battery = null;

  const user = {
    ...personnel?.user,
    role: personnel?.role,
    organisation: personnel?.organisation,
    coords: location?.location?.coords,
  };

  let timestamp = null;
  log.debug('timestamp', location, user);
  if (location) {
    if (location.lastSeen) {
      timestamp = dayjs(location.lastSeen * 1000);
    }

    if (location?.location) {
      battery = location?.location?.battery;
      switch (location?.location?.activity?.type) {
        case 'on_foot':
          activity = 'On Foot';
          activityIcon = 'street-view';
          break;
        case 'walking':
          activity = 'Walking';
          activityIcon = 'walking';
          break;
        case 'running':
          activity = 'Running';
          activityIcon = 'running';
          break;
        case 'in_vehicle':
          activity = 'In Vehicle';
          activityIcon = 'car';
          break;
        case 'on_bicycle':
          activity = 'On Bicycle';
          activityIcon = 'bicycle';
          break;
        default:
          break;
      }
    }

    if (activity === 'Stationary' && location?.location?.coords?.speed > 150) {
      activity = 'In Aircraft';
      activityIcon = 'plane';
    }
  } else if (user?.timestamp) {
    timestamp = dayjs(user.timestamp * 1000);
  }

  const disconnectedDur = 60 * 2 * 1000;
  const now = Date.now();
  const timeDifference = now - timestamp;
  const isDisconnected = timeDifference > disconnectedDur;

  if (isDisconnected) {
    activity = 'No Signal';
  }

  const active = !!timestamp && !isDisconnected && !location?.location?.offline;

  const showBack = !!selectedOrg;

  return !selectedOrg ? (
    <div
      className={getClassNames({
        'mission-sidebar': true,
        personnel: true,
        'has-personnel-notification': !!user?.critical,
      })}
    >
      {loading ? (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            top: '150px',
          }}
        >
          <div style={{ animation: 'rotation 2s infinite linear' }}>
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Loading details</div>
        </div>
      ) : (
        <>
          <div className="top has-border">
            <div className="heading">
              <div className="status">
                {!!sidebars.previous && (
                  <DotButton
                    className="back"
                    style={{ marginRight: '15px' }}
                    onClick={() => {
                      sidebars.open(sidebars.previous);
                    }}
                    ariaLabel="Back"
                  >
                    <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
                  </DotButton>
                )}
                <div className="title">
                  {isDisconnected ? (
                    <img
                      src={svgDisconnected}
                      style={{
                        height: '24px',
                        width: '24px',
                      }}
                      alt="disconnected"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={activityIcon}
                      style={{ height: '24px', width: '24px' }}
                    />
                  )}

                  <span>{activity}</span>
                </div>
                <Battery
                  data={battery}
                  style={{ height: '24px', width: '24px' }}
                />
                <DotButton className="exit" onClick={exit} ariaLabel="Close">
                  <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
                </DotButton>
              </div>
            </div>
          </div>
          {!!user?.critical && (
            <div className="critical">
              <img src={criticalWhite} alt="critical" />
              <ReactMarkdown>{user.critical.message}</ReactMarkdown>
            </div>
          )}

          <div className="top personnel-info">
            <div
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Avatar entity={user} />
                <div>
                  {displayName(user)}{' '}
                  {active && (
                    <span>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="7" cy="7" r="4" fill="#6FCF97" />
                        <circle
                          cx="7"
                          cy="7"
                          r="5.5"
                          stroke="#6FCF97"
                          strokeOpacity="0.3"
                          strokeWidth="3"
                        />
                      </svg>
                      Active {timeago.format(timestamp.toDate())}
                    </span>
                  )}
                  {!active && !!timestamp && (
                    <span>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="7" cy="7" r="4" fill="#B8BDD4" />
                        <circle
                          cx="7"
                          cy="7"
                          r="5.5"
                          stroke="#B8BDD4"
                          strokeOpacity="0.2"
                          strokeWidth="3"
                        />
                      </svg>
                      Last seen {timeago.format(timestamp.toDate())}
                    </span>
                  )}
                  {!active && !timestamp && (
                    <span>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="7" cy="7" r="4" fill="#B8BDD4" />
                        <circle
                          cx="7"
                          cy="7"
                          r="5.5"
                          stroke="#B8BDD4"
                          strokeOpacity="0.2"
                          strokeWidth="3"
                        />
                      </svg>
                      Inactive
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              {user.id !== userValue.user.id && user?.communicationServices && (
                <>
                  {comms.callState === CallState.Disconnected && (
                    <DotButton
                      className="secondary call-button"
                      onClick={async () => {
                        await comms.makePhoneCall(user);
                      }}
                      ariaLabel="Call"
                    >
                      Call
                      <FontAwesomeIcon icon="phone-flip" />
                    </DotButton>
                  )}
                  {(comms.callState === CallState.Connected ||
                    comms.callState === CallState.Connecting ||
                    comms.callState === CallState.Ringing ||
                    comms.callState === CallState.Disconnecting) && (
                    <DotButton
                      className="secondary call-button"
                      onClick={async () => {
                        if (
                          comms.callState !== CallState.Disconnecting ||
                          comms.callState !== CallState.Disconnected ||
                          comms.callState !== CallState.Connecting
                        ) {
                          await comms.hangUp();
                        }
                      }}
                      ariaLabel="End Call"
                      noSpin={true}
                      disabled={
                        comms.callState === CallState.Disconnecting ||
                        comms.callState === CallState.Connecting
                      }
                    >
                      {comms.callState === CallState.Disconnecting
                        ? 'Call Ended'
                        : 'End Call'}
                      <FontAwesomeIcon icon="phone-slash" />
                    </DotButton>
                  )}
                </>
              )}
              {!!missionId &&
                !isComms &&
                !isCurrentUser &&
                location?.location?.coords?.latitude &&
                location?.location?.coords?.longitude &&
                !location?.location?.offline && (
                  <DotButton
                    className="secondary call-button"
                    onClick={() =>
                      liveTracking?.id === user.id
                        ? setLiveTracking(null)
                        : setLiveTracking({
                            id: user.id,
                          })
                    }
                  >
                    {liveTracking?.id && liveTracking?.id === user.id
                      ? 'Unfollow Location'
                      : 'Follow Location'}
                  </DotButton>
                )}
            </div>
          </div>
          {!isComms && (
            <Tabs
              items={[
                {
                  text: `Overview`,
                  active: tab === 'overview',
                  onClick: () => setTab('overview'),
                },
                {
                  text: `Messages`,
                  active: tab === 'messages',
                  hidden: !missionId || isCurrentUser,
                  onClick: () => setTab('messages'),
                },
                {
                  text: `Tasks`,
                  active: tab === 'tasks',
                  hidden: !missionId,
                  onClick: () => setTab('tasks'),
                },
              ]}
            />
          )}
          <LightScrollbar className="content tab-content">
            {tab === 'overview' && (
              <div style={{ padding: '10px 20px' }}>
                <h3>User Information</h3>
                <div className="list">
                  <div
                    className="list-item"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedOrg(personnel?.organisation);
                    }}
                  >
                    <Avatar entity={personnel?.organisation} />
                    <div className="text">
                      Organisation
                      <span>{personnel?.organisation?.organisationName}</span>
                    </div>
                    {isComms && (
                      <DotButton className="button">
                        <FontAwesomeIcon
                          icon="chevron-right"
                          style={{ height: '24px', width: '24px' }}
                        ></FontAwesomeIcon>
                      </DotButton>
                    )}
                  </div>
                  {!!personnel?.role?.role && (
                    <div className="list-item">
                      <Avatar
                        entity={personnel?.role}
                        color={personnel?.user?.guest ? 'black' : null}
                      />
                      <div className="text">
                        Role
                        <span>{personnel?.role?.role}</span>
                      </div>
                    </div>
                  )}
                  {!!personnel?.teams?.length && <h3>Teams</h3>}
                  {personnel?.teams.map((team) => (
                    <div className="list-item">
                      <Avatar entity={team} />
                      <div className="text">{team.team}</div>
                    </div>
                  ))}
                  {isComms && !!personnel?.organisationRole && (
                    <div className="list-item no-border">
                      <FontAwesomeIcon icon="user" />
                      <div className="text">
                        Role
                        <span>
                          {personnel?.organisationRole
                            ? personnel?.organisationRole
                            : 'No Role'}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                {isComms && (!!user?.email || !!user?.mobile) && (
                  <>
                    <h3>Contact Information</h3>
                    {!!user?.email && (
                      <div className="list">
                        <div className="list-item no-border">
                          <FontAwesomeIcon
                            icon="envelope"
                            style={{ height: '24px', width: '24px' }}
                          ></FontAwesomeIcon>
                          <div className="text">
                            Email
                            <span>{user?.email}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {!!user?.mobile && (
                      <div className="list">
                        <div className="list-item no-border">
                          <FontAwesomeIcon
                            icon="phone"
                            style={{ height: '24px', width: '24px' }}
                          ></FontAwesomeIcon>
                          <div className="text">
                            Email
                            <span>{user?.mobile}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {!!location?.location && (
                  <LocationInfo
                    title="Location Information"
                    location={
                      location?.location?.coords
                        ? toLngLat(location?.location?.coords)
                        : location
                    }
                    personnelLocation={location}
                    bearing={bearing}
                    activity={activity}
                  />
                )}
              </div>
            )}
            {tab === 'messages' && (
              <MissionDBMessages
                missionId={missionId}
                you={userValue.user}
                user={user}
              ></MissionDBMessages>
            )}
            {tab === 'tasks' && (
              <PersonnelTasks
                missionId={missionId}
                userId={user?.id}
                users={users}
                teams={teams}
              ></PersonnelTasks>
            )}
          </LightScrollbar>
        </>
      )}
    </div>
  ) : (
    <div className="mission-sidebar">
      {loading ? (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            top: '150px',
          }}
        >
          <div style={{ animation: 'rotation 2s infinite linear' }}>
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Loading details</div>
        </div>
      ) : (
        <>
          <div
            className={getClassNames({
              top: true,
              'has-back': showBack,
            })}
          >
            <div className="heading">
              <div className="text">Organisation Info</div>
              <DotButton className="exit" onClick={exit} ariaLabel="Close">
                <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
              </DotButton>
            </div>
          </div>
          {showBack && (
            <DotButton className="back" onClick={exit} ariaLabel="Back">
              <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
            </DotButton>
          )}
          <LightScrollbar className="content">
            <div className="blocks">
              <div className="item-container">
                <div>
                  <h5>Organisation Name</h5>
                  <p>{personnel?.organisation.organisationName}</p>
                </div>
                <Avatar entity={personnel?.organisation} />
              </div>

              {personnel?.organisation.organisationDescription && (
                <div className="item-container">
                  <div>
                    <h5>Organisation Description</h5>
                    <p>{personnel?.organisation.organisationDescription}</p>
                  </div>
                </div>
              )}
            </div>
          </LightScrollbar>
        </>
      )}
    </div>
  );
};

MissionPersonnel.propTypes = {
  closeSidebar: PropTypes.func,
  missionId: PropTypes.string,
  userId: PropTypes.string,
  location: locationType,
  startingTab: PropTypes.oneOf(['', 'overview', 'messages', 'tasks', 'files']),
  isCurrentUser: PropTypes.bool,
  liveTracking: PropTypes.bool,
  setLiveTracking: PropTypes.func,
  isComms: PropTypes.bool,
  openDM: PropTypes.func,
  teams: PropTypes.arrayOf(teamType),
  bearing: PropTypes.any,
};

/**
 * Wraps Personnel messages in a component that first looks for the correct thread.
 *
 * @param {string} missionId mission id
 * @param {any} you the current user
 * @param {any} user the user
 */
const MissionDBMessages = ({ missionId, you, user }) => {
  const [threadId, setThreadId] = useState('');

  const findThreadId = useCallback(async () => {
    if (you?.id && user?.id) {
      const thread = await getThreadByUsers(
        missionId,
        [you.id, user.id],
        []
      ).catch(handleError);
      return thread?.id;
    }
    return null;
  }, [missionId, you, user]);

  // we need to locate the chat id
  useEffect(() => {
    if (you && user) {
      log.debug('finding thread');

      const { promise, cancel } = cancellablePromise(findThreadId());
      promise
        .then((id) => {
          if (id) {
            log.debug('thread found', id);
            setThreadId(id);
          }
        })
        .catch((e) => {});
      return cancel;
    }
    return () => {};
    // eslint-disable-next-line
  }, [you, user]);

  return !!threadId ? (
    <PersonnelMessages missionId={missionId} id={threadId}></PersonnelMessages>
  ) : (
    <div style={{ padding: '20px' }}>
      <FontAwesomeIcon icon="spinner" spin />
    </div>
  );
};

MissionDBMessages.propTypes = {
  missionId: PropTypes.string,
  you: userType,
  user: personnelType,
};
