/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import microphoneSvg from '../../../assets/svg/microphone.svg';
import lockSvg from '../../../assets/svg/lock.svg';
import saveSvg from '../../../assets/svg/save.svg';
import crosshairSvg from '../../../assets/svg/crosshair.svg';
import gridSvg from '../../../assets/svg/grid.svg';
import atSvg from '../../../assets/svg/at.svg';
import './FormControls.scss';

/**
 * Control1 - Text input and 6 action buttons
 *
 */
export const Control1 = ({
  control,
  handleSetFormData,
  propertyToUpdate,
  value,
  handleSaveSingleProperty,
  halfWidth = false,
}) => {
  const [locked, setLocked] = useState(false);
  const [details, setDetails] = useState(value);
  const localDetails = useRef(details);

  const handleLock = () => {
    setLocked(!locked);
  };

  const handleOnChange = async (e) => {
    setDetails(e.target.value);
    localDetails.current = e.target.value;
    await handleSetFormData(propertyToUpdate, localDetails.current);
  };

  const handleSaveClick = async (e) => {
    await handleSaveSingleProperty(propertyToUpdate, localDetails.current);
  };

  return (
    <div className="control-1">
      <div className="left">{control.ref}</div>
      <div className="details" style={{ width: halfWidth ? '35%' : '100%' }}>
        <div className="label">
          {control.label}{' '}
          {!!control.required && <div className="required"> * Required</div>}{' '}
        </div>
        <textarea
          onChange={(e) => handleOnChange(e)}
          value={localDetails.current}
          disabled={locked}
        />
        <div className="info">
          <div className="tip">{control.tip}</div>
          {/* <div className="last-saved">Last saved X time ago</div> */}
        </div>
      </div>
      <div className="action-buttons">
        <div className="row">
          <button disabled={true}>
            <img src={microphoneSvg} alt="microphone" />
          </button>
          <button onClick={handleLock}>
            <img src={lockSvg} alt="lock" />
          </button>
          <button onClick={async () => await handleSaveClick()}>
            <img src={saveSvg} alt="save" />
          </button>
        </div>
        <div className="row">
          <button disabled={true}>
            <img src={crosshairSvg} alt="crosshair" />
          </button>
          <button disabled={true}>
            <img src={gridSvg} alt="grid" />
          </button>
          <button disabled={true}>
            <img src={atSvg} alt="@" />
          </button>
        </div>
      </div>
    </div>
  );
};

Control1.propTypes = {
  control: PropTypes.any.isRequired,
  handleSetFormData: PropTypes.func,
  propertyToUpdate: PropTypes.string,
  value: PropTypes.string,
  handleSaveSingleProperty: PropTypes.func,
  halfWidth: PropTypes.bool,
};
