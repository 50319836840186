/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import dayjs from 'dayjs';
import * as timeago from 'timeago.js';

/**
 * format date as string
 *
 * @param {Date} d
 * @return {string}
 */
export const formatDate = (d) => {
  switch (dayjs().diff(d, 'day')) {
    case 0:
      return 'Today';
    case 1:
      return 'Yesterday';
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return dayjs(d).format('dddd');
    default:
      return dayjs(d).format('MMM DD, YYYY');
  }
};

/**
 * format timespan as string
 *
 * @param {number} ts timespan (s)
 * @return {string}
 */
export const formatTime = (ts) => {
  if (ts) {
    const diff = dayjs().diff(ts * 1000, 'day');
    switch (diff) {
      case 0:
        return timeago.format(ts * 1000);
      case 1:
        return `Yesterday ${dayjs(ts * 1000).format('HH:mm')}`;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return dayjs(ts * 1000).format('dddd HH:mm');
      default:
        if (diff >= 365) {
          return dayjs(ts * 1000).format('MMM DD, YYYY HH:mm');
        } else {
          return dayjs(ts * 1000).format('MMM DD HH:mm');
        }
    }
  }
  return '-';
};
