/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import deviceImage from '../../assets/svg/device-image.svg';
import dotLogo from '../../assets/svg/dot-badge.svg';
import bgShape from '../../assets/svg/dot-bg-shape.svg';
import chatIcon from '../../assets/svg/chat.svg';
import broadcastIcon from '../../assets/svg/broadcast.svg';
import { appConfig } from '../../config/appConfig';
import { secrets } from '../../config/secrets';
import { historyType } from '../../enums/propTypes';
import { DarkScrollbar } from '../common/Scrollbars';
import { ContentFoot, ContentMenu } from '../generic-components/ContentPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * WEBSITE - DOT Home Page
 *
 * @param {any} history react router
 */
export const Home = withRouter(({ history, location }) => {
  const sb = useRef();
  const [showTop, setShowTop] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      if (location.state?.target === 'about') {
        about();
      } else if (location.state?.target === 'why') {
        why();
      }
    }, 500);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line
  }, []);

  const about = () => {
    document.getElementById('about').scrollIntoView();
  };
  const why = () => {
    document.getElementById('why').scrollIntoView();
  };

  return (
    <div className="content-page home">
      <DarkScrollbar
        ref={sb}
        onUpdate={(e) => {
          setShowTop(!!e.scrollTop);

          // homepage footer settings
          if (document.getElementsByClassName('footer-bg').length) {
            document.getElementsByClassName('footer-bg')[0].style.height =
              document.getElementsByClassName('foot-body')[0].offsetHeight +
              180 +
              'px';
          }
        }}
      >
        <div className="hero">
          <ContentMenu history={history} about={about} why={why} />

          <section>
            <h1>
              Rapidly plan, deploy, collaborate with other agencies, and protect
              your teams
              <br /> in the line of duty.
            </h1>
            <a
              className="enquire"
              href={`mailto:${secrets.ENQUIRY_EMAIL}?subject=${appConfig.enquirySubject}&body=${appConfig.enquiryBody}`}
            >
              Enquire Now
            </a>

            <img src={deviceImage} alt="" />
          </section>
        </div>

        <div id="about" className="body">
          <div className="shape">
            <img src={bgShape} alt="bg shape" />
          </div>
          <div className="details-section">
            <div className="left">
              The AUSTAC platform is designed to empower first responders to
              rapidly plan and execute efficient responses to emergencies -
              whether working independently, or in collaboration with other
              agencies.
            </div>
            <div className="right">
              <h3>Real time situational awareness</h3>
              <div className="details">
                Driven by real time situational awareness tools, it tracks and
                displays the locations, workflows, and statuses of everyone
                involved in an operation in real time thereby removing any
                uncertainty that could previously compromise a commanders
                ability to respond effectively to a rapidly changing situation.
              </div>
              <h3>Interagency collaboration</h3>
              <div className="details">
                AUSTAC is designed for collaboration - both for teams within the
                same organisation and for organisations working together to
                combat a large scale emergency. The platform provides easy
                intuitive tools for {secrets.EVENT_NAME} commanders to share
                information seamlessly, whilst also controlling access to
                sensitive or personal data.
              </div>
              <h3>Remove uncertainty</h3>
              <div className="details">
                Being comfortable with confusion and uncertainty are hallmarks
                of emergency personnel. We put these workers in tough, dangerous
                situations because we train and equip them to deal with the
                worst.
              </div>
              <div className="details">
                But, when confusion reigns mistakes happen and personnel are
                left unaccounted for, even if only for crucial seconds.
                Situational awareness, provided unobtrusively by AUSTAC, gives
                us back those crucial seconds, allowing us to immediately plan
                and act.
              </div>
            </div>
          </div>
        </div>

        <h2 id="why">Why {appConfig.name}?</h2>

        <div className="details-box-section">
          <div className="section">
            <div className="box">
              <div className="dotted-border">
                <div className="bordered">
                  <div className="circle-container">
                    <FontAwesomeIcon
                      icon="circle-check"
                      style={{
                        color: '#ffffff',
                        borderRadius: '50%',
                        height: '24px',
                        margin: '18px',
                        width: '24px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3>A Single Source of Truth</h3>
              <div className="contents">
                All operational data in one place: Personnel, PLI, Tasks, Comms,
                Weather, and more.
              </div>
            </div>
            <div className="box">
              <div className="dotted-border">
                <div className="bordered">
                  <div className="circle-container">
                    <FontAwesomeIcon
                      icon="map-location-dot"
                      style={{
                        color: '#ffffff',
                        borderRadius: '50%',
                        height: '24px',
                        margin: '18px',
                        width: '24px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3>Reduce Confusion</h3>
              <div className="contents">
                A clear picture of where everyone is and what they're doing.
              </div>
            </div>
            <div className="box">
              <div className="dotted-border">
                <div className="bordered">
                  <div className="circle-container">
                    <img
                      src={chatIcon}
                      alt="chat"
                      style={{
                        height: '24px',
                        margin: '18px',
                        width: '24px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3>Reduce Radio Chatter</h3>
              <div className="contents">
                Greater situational awareness across all personnel means less
                unnecessary radio chatter.​
              </div>
            </div>
            <div className="box">
              <div className="dotted-border">
                <div className="bordered">
                  <div className="circle-container">
                    <FontAwesomeIcon
                      icon="triangle-exclamation"
                      style={{
                        color: '#ffffff',
                        borderRadius: '50%',
                        height: '24px',
                        margin: '18px',
                        width: '24px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3>Keep your team away from high risk areas</h3>
              <div className="contents">
                High risk zones can be marked and linked to alarms triggered
                automatically if personnel move within range.
              </div>
            </div>
          </div>

          <div className="section">
            <div className="box">
              <div className="dotted-border">
                <div className="bordered">
                  <div className="circle-container">
                    <FontAwesomeIcon
                      icon="clipboard"
                      style={{
                        color: '#ffffff',
                        borderRadius: '50%',
                        height: '24px',
                        margin: '18px',
                        width: '24px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3>Simple Onboarding</h3>
              <div className="contents">
                Onboard your organisation in days, not weeks.
              </div>
            </div>
            <div className="box">
              <div className="dotted-border">
                <div className="bordered">
                  <div className="circle-container">
                    <img
                      src={broadcastIcon}
                      alt="chat"
                      style={{
                        height: '24px',
                        margin: '18px',
                        width: '24px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3>Versatile</h3>
              <div className="contents">
                Works on any computer or mobile device with support for P25
                radios coming soon.
              </div>
            </div>
            <div className="box">
              <div className="dotted-border">
                <div className="bordered">
                  <div className="circle-container">
                    <FontAwesomeIcon
                      icon="message"
                      style={{
                        color: '#ffffff',
                        borderRadius: '50%',
                        height: '24px',
                        margin: '18px',
                        width: '24px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3>Easier communication</h3>
              <div className="contents">
                Communicate via instant messaging, Voice Calls, and PushToTalk.
              </div>
            </div>
            <div className="box">
              <div className="dotted-border">
                <div className="bordered">
                  <div className="circle-container">
                    <FontAwesomeIcon
                      icon="cloud-sun-rain"
                      style={{
                        color: '#ffffff',
                        borderRadius: '50%',
                        height: '24px',
                        margin: '18px',
                        width: '24px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <h3>External data feeds</h3>
              <div className="contents">
                Connect your own data sources using our API or select from our
                range of data feeds.
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="footer-bg"></div>
          <div className="foot-container">
            <div className="foot-body">
              <img src={dotLogo} alt="logo" />
              <h3>Empower your team today</h3>
              <p>Send through an enquiry today and our team will reach out.</p>
              <a
                className="enquire"
                href={`mailto:${secrets.ENQUIRY_EMAIL}?subject=${appConfig.enquirySubject}&body=${appConfig.enquiryBody}`}
              >
                Enquire Now
              </a>

              <ContentFoot
                history={history}
                showTop={showTop}
                scrollToTop={() => {
                  sb.current.scrollToTop();
                }}
              ></ContentFoot>
            </div>
          </div>
        </div>
      </DarkScrollbar>
    </div>
  );
});

Home.propTypes = { history: historyType };
