/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState } from 'react';
import { PropTypes } from 'prop-types';
import lockSvg from '../../../assets/svg/lock.svg';
import saveSvg from '../../../assets/svg/save.svg';
import microphoneSvg from '../../../assets/svg/microphone.svg';
import atSvg from '../../../assets/svg/at.svg';
import { Select } from '../../common/Select';

/**
 * Control8 - Single dropdown and 2 or 4 action buttons
 *
 */
export const Control8 = ({ control, twoActionButtons = false }) => {
  const [locked, setLocked] = useState(false);
  const [selected, setSelected] = useState();

  const handleLock = () => {
    setLocked(!locked);
  };

  return (
    <div className="control-8">
      <div className="details">
        <div className="row">
          <div className="body">
            <div
              className="dropdown-select"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="middle">
                <Select
                  onChange={(e) => {
                    setSelected(e.currentTarget.value);
                  }}
                  placeholder={''}
                  value={selected}
                  disabled={locked}
                >
                  <option key={0} value={null}>
                    {''}
                  </option>
                  {!!control.values &&
                    control.values.map((v, idx) => {
                      return (
                        <option key={idx + 1} value={v}>
                          {v}
                        </option>
                      );
                    })}
                </Select>
              </div>
            </div>
            {twoActionButtons ? (
              <div className="action-buttons">
                <div className="row">
                  <button onClick={handleLock}>
                    <img src={lockSvg} alt="lock" />
                  </button>
                  <button>
                    <img src={saveSvg} alt="save" />
                  </button>
                </div>
              </div>
            ) : (
              <div className="action-buttons">
                <div className="row">
                  <button disabled={true}>
                    <img src={microphoneSvg} alt="microphone" />
                  </button>
                  <button>
                    <img src={saveSvg} alt="save" />
                  </button>
                </div>
                <div className="row">
                  <button disabled={true}>
                    <img src={atSvg} alt="@" />
                  </button>
                  <button onClick={handleLock}>
                    <img src={lockSvg} alt="lock" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Control8.propTypes = {
  control: PropTypes.any.isRequired,
  missionId: PropTypes.any.isRequired,
  twoActionButtons: PropTypes.bool,
};
