/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { renderRichText } from '../../../utils/richtext';
import { displayName } from '../../../utils/string';
import { formatDate } from '../../../utils/time';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import './Tasks.scss';
import { LightScrollbar } from '../../common/Scrollbars';
import { useUser } from '../../../context/UserContext';
import { getThreadByUsers } from '../../../api/comms';
import { navigateToMapTargets } from '../../../utils/nav';
import { handleError } from '../../../utils/error';
import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import { historyType } from '../../../enums/propTypes';

/**
 * Sidebar - Task details
 *
 * @param {any} history react router
 * @param {string} missionId mission id
 * @param {any} task current task
 * @param {Function} closeSidebar callback to close sidebar
 */
export const TaskSidebar = ({ history, missionId, task, closeSidebar }) => {
  const { user } = useUser();

  const exit = () => {
    closeSidebar();
  };

  const navigateToUserThread = async (entities) => {
    if (user && entities.length) {
      log.debug('finding thread', entities);

      let thread = null;

      const users = entities.filter((u) => u.type === 'user').map((u) => u.id);
      const teams = entities.filter((u) => u.type === 'team').map((u) => u.id);

      if (users.length && !teams.length) {
        thread = await getThreadByUsers(
          missionId,
          [user.id, ...users],
          []
        ).catch(handleError);
      } else if (!users.length && teams.length === 1) {
        thread = await getThreadByUsers(missionId, [], teams).catch(
          handleError
        );
      } else {
        handleError(`Can't message groups of teams and users`);
      }

      if (thread) {
        log.debug('found thread', missionId, thread);
        // navigateToThread(history, thread);
        navigateToMapTargets(history, missionId, [
          { threadId: thread.id, type: 'thread' },
        ]);
      }
    }
  };

  return (
    <div className="task-sidebar">
      <div className="top has-border">
        <div className="heading">
          <div className="text">Task Details</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        <div className="sub-heading">
          <div className="text">
            {task?.name}
            <span>Last Updated: {formatDate(task?._ts * 1000)}</span>
          </div>
          <div className="icons">
            {!!task?.assigned?.length && (
              <DotButton
                className="button"
                onClick={(e) => {
                  e.preventDefault();
                  closeSidebar();
                  const targets = task.assigned
                    .filter((a) => a.type === 'user')
                    .map((a) => {
                      return { type: a.type, id: a.id };
                    });
                  navigateToMapTargets(history, missionId, targets);
                }}
                tip="View on Map"
              >
                <FontAwesomeIcon icon="map-marker-alt" />
              </DotButton>
            )}
            <DotButton
              className="button"
              onClick={(e) => {
                e.preventDefault();
                closeSidebar();

                navigateToUserThread(
                  task.assigned.map((a) => {
                    return { id: a.id, type: a.type };
                  })
                );
              }}
              tip="Message all assigned users"
            >
              <FontAwesomeIcon icon="comment-alt" />
            </DotButton>
          </div>
        </div>
      </div>
      {task?.timespanType !== 'none' && (
        <div className="top has-border">
          <div className="sub-heading time">
            {task?.timespanType === 'deadline' ? (
              <>
                <FontAwesomeIcon icon="clock" />
                {dayjs(task.end).format('h:mma, D MMM YYYY')}
              </>
            ) : (
              <>
                <FontAwesomeIcon icon="clock" />
                {dayjs(task?.start).format('h:mma, D MMM YYYY')} -
                {dayjs(task?.end).format('h:mma, D MMM YYYY')}
              </>
            )}
          </div>
        </div>
      )}
      <LightScrollbar className="content">
        <div className="blocks">
          <h5>Description</h5>
          <p>{renderRichText(task?.description)}</p>

          {!!task?.entity && (
            <>
              <h5>Created by</h5>
              <div className="list">
                <div className="list-item no-border">
                  <Avatar entity={task?.entity} />
                  <div className="text">
                    {displayName(task?.entity)}{' '}
                    <span>{task?.entity?.breadcrumb}</span>
                  </div>
                  {!!task?.userId && (
                    <DotButton
                      className="button"
                      onClick={(e) => {
                        e.preventDefault();
                        closeSidebar();
                        const targets = [{ type: 'user', id: task.userId }];
                        navigateToMapTargets(history, missionId, targets);
                      }}
                      tip="View on Map"
                    >
                      <FontAwesomeIcon
                        icon="map-marker-alt"
                        style={{ height: '24px', width: '24px' }}
                      ></FontAwesomeIcon>
                    </DotButton>
                  )}
                  <DotButton
                    className="button"
                    onClick={(e) => {
                      e.preventDefault();
                      closeSidebar();
                      navigateToUserThread([
                        {
                          id: task.userId,
                        },
                      ]);
                    }}
                    tip="Message user"
                  >
                    <FontAwesomeIcon
                      icon=" comment-alt"
                      style={{ height: '24px', width: '24px' }}
                    ></FontAwesomeIcon>
                  </DotButton>
                </div>
              </div>
            </>
          )}

          <h5>Assigned</h5>
          <div className="list" style={{ paddingTop: '1em' }}>
            {!!task?.assigned?.length &&
              task.assigned.map((entity) => (
                <div
                  key={entity.id}
                  className="list-item no-border"
                  style={{ padding: 0 }}
                >
                  <Avatar entity={entity.entity} />
                  <div className="text" style={{ marginLeft: '1em' }}>
                    {displayName(entity.entity)}{' '}
                    <span>{entity.entity?.breadcrumb}</span>
                  </div>
                  <DotButton
                    className="button"
                    onClick={(e) => {
                      e.preventDefault();
                      closeSidebar();
                      if (entity.type === 'user') {
                        const targets = [{ type: 'user', id: entity.id }];
                        navigateToMapTargets(history, missionId, targets);
                      } else {
                        const targets = entity.entity.users.map((u) => {
                          return {
                            id: u.id,
                            type: 'user',
                          };
                        });
                        navigateToMapTargets(history, missionId, targets);
                      }
                    }}
                    tip="View on Map"
                  >
                    <FontAwesomeIcon
                      icon="map-marker-alt"
                      style={{ height: '24px', width: '24px' }}
                    ></FontAwesomeIcon>
                  </DotButton>
                  <DotButton
                    className="button"
                    disabled={entity.id === user.id}
                    onClick={(e) => {
                      e.preventDefault();
                      closeSidebar();
                      navigateToUserThread([entity]);
                    }}
                    tip={`Message ${entity.type}`}
                  >
                    <FontAwesomeIcon
                      icon="comment-alt"
                      style={{ height: '24px', width: '24px' }}
                    ></FontAwesomeIcon>
                  </DotButton>
                </div>
              ))}
          </div>
        </div>
      </LightScrollbar>
    </div>
  );
};

TaskSidebar.propTypes = {
  history: historyType,
  missionId: PropTypes.string.isRequired,
  task: PropTypes.object,
  closeSidebar: PropTypes.func.isRequired,
};
