/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Expandable
 *
 * @param {any} title section title
 * @param {any} children children to display when expanded
 * @param {boolean} defaultOpen flag for open state
 */
export const Expandable = ({ title, children, defaultOpen = false, style }) => {
  const [expanded, setExpanded] = useState(defaultOpen);

  return (
    <div className="list has-border controls" style={style}>
      <div
        className="heading bold"
        onClick={() => {
          if (!defaultOpen) {
            setExpanded(!expanded);
          }
        }}
      >
        {title}
        {!defaultOpen && (
          <FontAwesomeIcon icon={expanded ? 'chevron-up' : 'chevron-down'} />
        )}
      </div>
      {expanded && children}
    </div>
  );
};

Expandable.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  style: PropTypes.any,
};
