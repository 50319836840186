/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import filesize from 'filesize';
import log from 'loglevel';
import { PropTypes } from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import {
  threadRead,
  threadSendMessage,
  threadTyping,
} from '../../../api/comms';
import { postImage, postFile } from '../../../api/other';
import typingLight from '../../../assets/svg/typingLight.svg';
import typingDark from '../../../assets/svg/typingDark.svg';
import { commsConfig } from '../../../config/commsConfig';
import { useComms } from '../../../context/CommsContext';
import { useNotification } from '../../../context/NotificationContext';
import { useUser } from '../../../context/UserContext';
import { ImageType } from '../../../enums/image';
import { sortArray, sortBy, unique } from '../../../utils/array';
import { handleError } from '../../../utils/error';
import { getFileIcon } from '../../../utils/files';
import { useLocalStorage } from '../../../utils/localStorage';
import { NotificationStatus } from '../../../utils/notifications';
import { displayName, getClassNames } from '../../../utils/string';
import { formatDate, formatTime } from '../../../utils/time';
import { AudioPlayer } from '../../common/AudioPlayer';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { EventManager } from '../../common/EventManager';
import { LightBox } from '../../common/Lightbox';
import { NotificationIcon } from '../../common/NotificationsPanel';
import { LightScrollbar } from '../../common/Scrollbars';
import './Messages.scss';

const PLACEHOLDER = 'Send a message...';
const BOTTOM_THRESHOLD = 420;

/**
 * Comms message thread component used in:
 * Comms tab on personnel sidebar
 * Active thread in mission comms
 * Mission broadcast sidebar
 *
 * @param {string} missionid mission id
 * @param {string} id thread id
 */
export const PersonnelMessages = ({ missionId, id, readOnly }) => {
  const comms = useComms();
  const { user } = useUser();
  const notification = useNotification();

  const [previewImages, setPreviewImages] = useState([]);
  const [image, setImage] = useState(null);

  const [iAmTyping, setIAmTyping] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const scrollRef = useRef();
  const focusRef = useRef(true);
  const youTypingRef = useRef();

  const refChatMode = useRef(comms.chatMode);

  const textArea = useRef();

  const [readReceipts, setReadReceipts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [failed, setFailed] = useState('');
  const [newMessages, setNewMessages] = useState(false);

  const refMessages = useRef(messages);

  // eslint-disable-next-line
  const [_focusedThreads, setFocusedThreads] = useLocalStorage(
    'focused-threads',
    []
  );

  // this runs every render to update ref messages
  // this is so we can use them in the callbacks
  useEffect(() => {
    refChatMode.current = comms.chatMode;
  });

  // when text area is loaded
  useEffect(() => {
    if (textArea.current) {
      textArea.current.innerText = PLACEHOLDER;
    }
    // eslint-disable-next-line
  }, []);

  const scrollToBottom = (force = false) => {
    if (scrollRef.current) {
      const diff =
        scrollRef.current.scrollHeight -
        scrollRef.current.clientHeight -
        scrollRef.current.scrollTop;

      if (diff < BOTTOM_THRESHOLD || force) {
        scrollRef.current.scrollToBottom();
      } else {
        setNewMessages(true);
      }
    }
  };

  // when thread or messages are updated
  useEffect(() => {
    log.debug('useEffect', id);
    if (comms.threads[id]) {
      const participants = comms.threads[id]?.participants || [];

      // get critical messages
      const criticalNotifications = notification.notifications
        .filter(
          (n) =>
            n.status === NotificationStatus.critical &&
            (comms.threads[id]?.missionWide ||
              participants.indexOf(n.target.id) !== -1)
        )
        .map((n) => {
          n.user = n.author;
          n.isSelf = n.user.id === user.id;
          return n;
        });

      if (comms.messages[id]) {
        setReadReceipts(
          unique(
            comms.messages[id]
              .filter(
                (m) =>
                  comms.threads[id].readReceipts.indexOf(m.user.userId) !==
                    -1 && m.user.userId !== user.id
              )
              .map((m) => m.user)
          )
        );

        setMessages([...criticalNotifications, ...(comms.messages[id] || [])]);
      } else {
        setReadReceipts([]);
        setMessages([...criticalNotifications, ...[]]);
      }
    }
    // eslint-disable-next-line
  }, [id, comms.threads, comms.messages, notification.notifications]);

  // when comms data is updated that may require the user's attention
  useEffect(() => {
    const force = !refMessages.current?.length;
    setTimeout(() => {
      // give images some time to load
      scrollToBottom(force);
    }, 200);
    refMessages.current = messages;
    // eslint-disable-next-line
  }, [messages, failed]);

  // when thread is switched scroll to bottom
  useEffect(() => {
    setTimeout(() => {
      // give images some time to load
      scrollToBottom(true);
    }, 200);
    setFocusedThreads([{ id, _ts: new Date().getTime() }]);
    // eslint-disable-next-line
  }, [id]);

  const sendTypingNotification = async () => {
    await threadTyping(missionId, id, user);
  };

  const handleTyping = () => {
    if (!iAmTyping) {
      setIAmTyping(true);
      sendTypingNotification();
    }
    clearTimeout(youTypingRef.current);
    youTypingRef.current = setTimeout(() => {
      setIAmTyping(false);
    }, commsConfig.iAmTypingTimeout);
  };

  const windowFocus = useCallback(
    (e) => {
      focusRef.current = true;

      // only send RR if we haven't read it
      if (comms.threads[id]) {
        threadRead(missionId, id, user);
      }

      scrollToBottom(true);

      // mark this thread as in focus
      setFocusedThreads([{ id, _ts: new Date().getTime() }]);
    },
    [comms.threads, id, missionId, setFocusedThreads, user]
  );

  useEffect(() => {
    // mark this thread as in focus
    setFocusedThreads([{ id, _ts: new Date().getTime() }]);

    return () => {
      log.debug(`DESTROY personnel messages`);

      // remove thread from in focus
      setFocusedThreads([]);

      clearTimeout(youTypingRef.current);
    };
    // eslint-disable-next-line
  }, []);

  const dates = unique(
    sortArray(
      messages.map((m) => m._ts),
      'asc'
    ).map((t) => dayjs(t * 1000).format('YYYY-MM-DD'))
  );

  const dateMessages = (d) => {
    return sortBy(
      messages.filter((m) => d === dayjs(m._ts * 1000).format('YYYY-MM-DD')),
      ['ts'],
      ['asc']
    );
  };

  const isSomeoneTyping = comms.typingIndicators.filter(
    (ti) => ti.threadId === id
  );

  const sendMessage = async (message) => {
    log.debug('send message', message, selectedFiles);

    if (selectedFiles?.length) {
      // validate files
      // eslint-disable-next-line
      for (const file of selectedFiles) {
        if (!file.compressed) {
          // Disabled validation until file size becomes a problem
          // const err = validateFile(file);
          // if (err) {
          //   toast.error(err);
          //   return;
          // }
        } else if (file.compressed.error) {
          toast.error(file.compressed.error);
          return;
        }
      }
    }

    if (message) {
      textArea.current.innerText = PLACEHOLDER;
    }

    if (
      (!message || message.trim() === PLACEHOLDER) &&
      !selectedFiles?.length
    ) {
      handleError(
        `Enter a message then click send, or press ${
          comms.chatMode ? 'SHIFT + ↵' : '↵'
        }`
      );
    } else {
      if (!!selectedFiles.length) {
        selectedFiles.forEach(async (f, index) => {
          if (f) {
            //Only send text once for multiple attachments
            let messageToSend = {
              text: message.replace(PLACEHOLDER, '').trim(),
            };
            if (index > 0) {
              messageToSend = { text: '' };
            }

            if (f.type.startsWith('image')) {
              const rs = await postImage(ImageType.Photo, f.compressed.blob);
              if (rs.success) {
                messageToSend.attachment = {
                  mimetype: f.type,
                  url: rs.url,
                  size: f.size,
                  meta: rs.meta,
                };
              } else {
                setFailed({
                  text: messageToSend.text,
                });
                handleError('Error uploading media attachment');
                return;
              }
            } else {
              const rs = await postFile(`/utils/upload`, f.blob);
              if (rs.success) {
                messageToSend.attachment = {
                  mimetype: f.type,
                  url: rs.url,
                  size: f.size,
                  filename: f.name,
                  meta: rs.meta,
                };
              } else {
                setFailed({
                  text: messageToSend.text,
                });
                handleError('Error uploading file attachment');
                return;
              }
            }
            await threadSendMessage(missionId, id, messageToSend).catch(
              (err) => {
                setFailed(messageToSend);
                handleError(err);
              }
            );
          }
        });
        setSelectedFiles([]);
      } else {
        let messageToSend = { text: message.replace(PLACEHOLDER, '').trim() };
        await threadSendMessage(missionId, id, messageToSend).catch((err) => {
          setFailed(messageToSend);
          handleError(err);
        });
      }
      // stop typing when message sent
      setIAmTyping(false);
    }
  };

  const selectFiles = async (files) => {
    log.debug('files', files);

    if (!files?.length) {
      handleError(`Browse and select a file to send`);
    } else {
      setSelectedFiles([...selectedFiles, ...files]);

      // Disabled validation until filesize becomes a problem
      // for (const file of files) {
      //   if (file.type.startsWith('image') && !file.compressed) {
      //     const err = validateFile(file);
      //     if (err) {
      //       toast.error(err);
      //     }
      //   }
      // }
    }
  };

  const _setImage = (img) => {
    const allImages = messages
      .filter(
        (m) => !!m.attachment && getFileIcon(m.attachment) === 'file-image'
      )
      .map((a) => {
        return {
          photoUrl: a.attachment.url,
          name: 'attachment',
          meta: a.attachment.meta || null,
        };
      });

    setPreviewImages(allImages);
    setImage(img);
  };

  const _openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const _switchFileIcon = (file) => {
    switch (getFileIcon(file)) {
      case 'file-pdf':
      case 'file-powerpoint':
      case 'file-excel':
      case 'file-word':
      case 'file-csv':
      case 'file-video':
      case 'file-audio':
        return getFileIcon(file);
      default:
        return 'file';
    }
  };

  return (
    <div className="chat" onClick={windowFocus}>
      <EventManager element={window} event="focus" handler={windowFocus} />
      <EventManager
        element={window}
        event="blur"
        handler={(e) => {
          focusRef.current = false;

          // remove thread from in focus
          setFocusedThreads([]);
        }}
      />
      <LightScrollbar
        ref={scrollRef}
        className="messages"
        onScrollStop={(e) => {
          const diff = e.scrollHeight - e.clientHeight - e.scrollTop;
          if (diff < 5) {
            setNewMessages(false);
          }
        }}
      >
        {!!!dates?.length && <div style={{ padding: '20px' }}>No messages</div>}
        {!!dates?.length &&
          dates.map((d) => (
            <div key={d}>
              <div className="date">{formatDate(d)}</div>
              {dateMessages(d).map((m) => (
                <div key={m.id} className="message">
                  {!m.isSelf &&
                    (m.user?.displayName !== 'Unknown' || !m.systemMessage) && (
                      <Avatar
                        entity={m.user}
                        text={!m.user ? '?' : ''}
                        size="36px"
                      />
                    )}

                  <ChatMessage
                    message={m}
                    setImage={_setImage}
                    openInNewTab={_openInNewTab}
                  />

                  {/* {m.isSelf && (
                    <Avatar
                      text="YOU"
                      size="36px"
                      color={'var(--colour-grey-1)'}
                    ></Avatar>
                  )} */}
                </div>
              ))}
            </div>
          ))}

        {/* if no read receipts then add a cheeky spacer so we don't have to
        interupt the flow of the app with a scroll to bottom */}
        {!!readReceipts?.length ? (
          <div className="read-receipts">
            {readReceipts.map((user, index) => (
              <Avatar key={index} entity={user} size="1rem"></Avatar>
            ))}
          </div>
        ) : (
          <div style={{ height: '26px' }}></div>
        )}
        {!!isSomeoneTyping?.length && (
          <>
            <div className="typing light">
              <img src={typingLight} alt="typing indicator" />
            </div>{' '}
            <div className="typing dark">
              <img src={typingDark} alt="typing indicator" />
            </div>
          </>
        )}
        {!!failed && (
          <div>
            <div className="message failed">
              <ChatMessage
                failed
                message={failed}
                sendMessage={sendMessage}
                setImage={_setImage}
                openInNewTab={_openInNewTab}
              />

              {failed ? (
                <Avatar
                  icon="exclamation"
                  size="36px"
                  color={'var(--colour-negative-status-a)'}
                ></Avatar>
              ) : (
                // <Avatar
                //   text="YOU"
                //   size="36px"
                //   color={'var(--colour-grey-1)'}
                // ></Avatar>
                <></>
              )}
            </div>
          </div>
        )}
      </LightScrollbar>
      {!readOnly && (
        <div className="compose">
          {textArea.current?.height}
          <div className="compose-container">
            <div className="buttons">
              <DotButton
                className="button"
                file
                accept="image/*"
                onClick={async (e, files) => {
                  e.preventDefault();
                  if (e.error) {
                    toast.error(e.error);
                  } else {
                    selectFiles(files);
                  }
                }}
                ariaLabel="Attach Photo"
              >
                <FontAwesomeIcon icon="image"></FontAwesomeIcon>
              </DotButton>
              <DotButton
                className="button"
                file
                accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.csv,video/*"
                postProcess={false}
                onClick={async (e, files) => {
                  e.preventDefault();
                  if (e.error) {
                    toast.error(e.error);
                  } else {
                    selectFiles(files);
                  }
                }}
                ariaLabel="Attach File"
              >
                <FontAwesomeIcon icon="paperclip"></FontAwesomeIcon>
              </DotButton>
              <DotButton
                className="button"
                onClick={async (e) => {
                  e.preventDefault();

                  await sendMessage(textArea.current.innerText);
                }}
                ariaLabel="Send Message"
              >
                <FontAwesomeIcon icon="paper-plane"></FontAwesomeIcon>
              </DotButton>
            </div>
            <div
              className="grow-textarea"
              role="textbox"
              contentEditable="true"
              onClick={(e) => {
                const text = e.target.innerText.trim();
                if (text.indexOf(PLACEHOLDER) !== -1) {
                  e.target.innerText = text.replace(PLACEHOLDER, '');
                }
              }}
              onBlur={(e) => {
                const text = e.target.innerText.trim();
                if (!text) {
                  e.target.innerText = PLACEHOLDER;
                  e.target.classList.add('empty');
                }
              }}
              onFocus={(e) => {
                const text = e.target.innerText.trim();
                if (text === PLACEHOLDER) {
                  e.target.classList.remove('empty');
                  e.target.innerText = '';
                }
              }}
              onKeyPress={(e) => {
                if (refChatMode.current) {
                  if (e.code === 'Enter') {
                    if (e.shiftKey) {
                      e.preventDefault();
                      sendMessage(e.target.innerText);
                      return;
                    } else {
                    }
                  }
                } else {
                  if (e.code === 'Enter') {
                    if (e.shiftKey) {
                    } else {
                      e.preventDefault();
                      sendMessage(e.target.innerText);
                      return;
                    }
                  }
                }
                const text = e.target.innerText.trim();
                if (text.indexOf(PLACEHOLDER) !== -1) {
                  e.target.innerText = text.replace(PLACEHOLDER, '');
                }
                handleTyping();
              }}
              onPaste={(e) => {
                e.preventDefault();
                var text = e.clipboardData.getData('text/plain');
                document.execCommand('insertHTML', false, text);
              }}
              ref={textArea}
            ></div>
            {!!selectedFiles.length && (
              <div className="files grow-textarea">
                {selectedFiles.map((file, i) => (
                  <div
                    key={i}
                    className={getClassNames({
                      file: true,
                      invalid:
                        (file.type.startsWith('image') && !file.compressed) ||
                        file.compressed?.error,
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      const selectedImages = selectedFiles
                        .filter((f) => !!f.compressed)
                        .map((f) => {
                          return {
                            photoUrl: f.compressed.url,
                            name: f.name,
                          };
                        });

                      if (selectedImages?.length) {
                        setPreviewImages(selectedImages);
                        setImage(selectedImages[0]);
                      }
                    }}
                  >
                    {file.compressed && getFileIcon(file) === 'file-image' && (
                      <img src={file.compressed.url} alt={file.name} />
                    )}
                    {!file.compressed && getFileIcon(file) === 'file-image' && (
                      <img src={file.url} alt={file.name} />
                    )}
                    {/* {getFileIcon(file) === 'file-video' && (
                      <video src={file.url} alt={file.name}></video>
                    )} */}
                    {getFileIcon(file) !== 'file-image' && (
                      <div className="upload">
                        <div className="left">
                          <FontAwesomeIcon
                            icon={_switchFileIcon(file)}
                          ></FontAwesomeIcon>
                        </div>
                        <div className="right">
                          <div className="text">{file.name}</div>
                        </div>
                      </div>
                    )}
                    <div className="warning">
                      <FontAwesomeIcon icon="exclamation" />
                    </div>
                    <DotButton
                      className="exit"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        selectedFiles.splice(i, 1);
                        setSelectedFiles([...selectedFiles]);
                      }}
                      ariaLabel="Remove"
                    >
                      <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
                    </DotButton>
                  </div>
                ))}
              </div>
            )}
            <div className="compose-help">
              <div>
                {!isSomeoneTyping?.length
                  ? ''
                  : isSomeoneTyping.length === 1
                  ? `${displayName(isSomeoneTyping[0])} is typing...`
                  : `${isSomeoneTyping.length} people are typing...`}
              </div>
              <div>
                <span>
                  {comms.chatMode ? 'Shift + ↵ to send' : '↵ to send'}
                </span>
                <span>
                  {comms.chatMode
                    ? '↵ to add a new line'
                    : 'Shift + ↵ to add a new line'}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {newMessages && (
        <DotButton
          className="new-messages-button"
          onClick={() => scrollToBottom(true)}
        >
          <FontAwesomeIcon icon="arrow-down" />
        </DotButton>
      )}

      {!!previewImages?.length && (
        <LightBox
          exit={() => {
            setPreviewImages([]);
          }}
          images={previewImages}
          image={image}
          selectImage={setImage}
        ></LightBox>
      )}
    </div>
  );
};

PersonnelMessages.propTypes = {
  missionId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

/**
 * Component for a chat message
 *
 * @param {any} message message
 * @param {Boolean} failed message failed to send
 * @param {Function} sendMessage callback to send message (retry failed)
 * @param {Function} setImage callback to set image
 * @param {Function} openInNewTab callback to open file in new tab
 */
const ChatMessage = ({
  message,
  failed,
  sendMessage,
  setImage,
  openInNewTab,
}) => {
  let jsx = <></>;
  let bubble = true;
  if (message.attachment) {
    jsx = (
      <>
        <AttachmentMessage
          attachment={message.attachment}
          setImage={setImage}
          openInNewTab={openInNewTab}
        />
        {!!message.text && <TextMessage>{message.text}</TextMessage>}
      </>
    );
  } else if (message.user?.displayName === 'Unknown' || message.systemMessage) {
    jsx = <TextMessage>{message.text}</TextMessage>;
    bubble = false;
  } else if (message.type?.toLowerCase() === 'text') {
    jsx = <TextMessage>{message.content.message}</TextMessage>;
  } else if (message.type?.toLowerCase() === 'participantadded') {
    bubble = false;
    jsx =
      message.content.participants.length === 1 ? (
        <TextMessage>
          <FontAwesomeIcon icon="user-plus" />
          {message.content.participants[0].displayName} has been added to the
          chat
        </TextMessage>
      ) : (
        <TextMessage>
          <FontAwesomeIcon icon="user-plus" />
          {message.content.participants.length} members have been added to the
          chat
        </TextMessage>
      );
  } else if (message.type?.toLowerCase() === 'participantremoved') {
    bubble = false;
    jsx =
      message.content.participants.length === 1 ? (
        <TextMessage>
          <FontAwesomeIcon icon="user-minus" />
          {message.content.participants[0].displayName} has been removed from
          the chat
        </TextMessage>
      ) : (
        <TextMessage>
          <FontAwesomeIcon icon="user-minus" />
          {message.content.participants.length} members have been removed from
          the chat
        </TextMessage>
      );
  } else if (!!message.target && !!message.status) {
    bubble = false;
    jsx = (
      <div className="critical-notification">
        <div className="critical-message">
          <NotificationIcon
            icon={
              message.status === NotificationStatus.critical
                ? 'status-critical'
                : message.type
            }
          >
            {message.author && (
              <Avatar entity={message.author} size="1.25rem" />
            )}
          </NotificationIcon>
          <ReactMarkdown>{message.message}</ReactMarkdown>
          <span className="time">{formatTime(message._ts)}</span>
        </div>
        {!!message.media && (
          <div className="message-player">
            <AudioPlayer
              file={message.media}
              entity={message.author}
            ></AudioPlayer>
          </div>
        )}
      </div>
    );
  } else if (!!message.text) {
    jsx = <TextMessage>{message.text}</TextMessage>;
  }

  return bubble ? (
    <div className={getClassNames({ bubble: true, you: message.isSelf })}>
      <div className="sender">
        <span>
          {failed || message.isSelf ? 'YOU' : displayName(message.user)}
        </span>
        {failed ? (
          <span
            style={{
              color: 'var(--colour-negative-status-a)',
              textTransform: 'none',
            }}
          >
            Message failed to send
          </span>
        ) : (
          <span style={{ fontWeight: 400 }}>{formatTime(message._ts)}</span>
        )}
      </div>
      <div className="bubble-message">{jsx}</div>
      {!!failed && (
        <DotButton
          className="link"
          style={{ alignSelf: 'flex-end' }}
          onClick={async () => {
            await sendMessage(message.text);
          }}
        >
          retry
        </DotButton>
      )}
    </div>
  ) : (
    <div className={getClassNames({ notification: true, you: message.isSelf })}>
      {jsx}
    </div>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.object,
  failed: PropTypes.bool,
  sendMessage: PropTypes.func,
  setImage: PropTypes.func.isRequired,
  openInNewTab: PropTypes.func.isRequired,
};

/**
 * Component for an attachment message
 *
 * @param {any} attachment
 */
const AttachmentMessage = ({ attachment, setImage, openInNewTab }) => {
  let icon = getFileIcon(attachment);

  let onClick = null;

  if (attachment.url && attachment.mimetype.startsWith('image')) {
    onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setImage({
        photoUrl: attachment.url,
        name: 'attachment',
      });
    };
  } else {
    onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      openInNewTab(attachment.url);
    };
  }

  const sensitiveContent = attachment.meta?.adult?.isAdultContent
    ? 'Adult Content'
    : attachment.meta?.adult?.isRacyContent
    ? 'Racy Content'
    : attachment.meta?.adult?.isGoryContent
    ? 'Contains Gore'
    : '';

  return (
    <div
      className={getClassNames({
        attachment: true,
        censored: sensitiveContent,
      })}
      style={{ cursor: onClick ? 'pointer' : '' }}
      onClick={onClick}
    >
      {icon === 'file-image' ? (
        <>
          <img
            src={attachment.url}
            alt={attachment.meta?.description || 'message attachment'}
            title={attachment.meta?.description || ''}
          />
          <div className="censored-text">
            <FontAwesomeIcon icon="eye-slash" size="2x"></FontAwesomeIcon>
            <div>Sensitive Content</div>
            <span>{sensitiveContent}</span>
          </div>
        </>
      ) : (
        <DotButton className="button">
          <div className="icon">
            <FontAwesomeIcon icon={icon} />
          </div>
          {!!attachment.filename && (
            <div className="text">
              <div className="filename">
                {attachment.filename || 'attachment'}
              </div>
              {!!attachment.size && <span>{filesize(attachment.size)}</span>}
            </div>
          )}
        </DotButton>
      )}
    </div>
  );
};

AttachmentMessage.propTypes = {
  attachment: PropTypes.object,
  setImage: PropTypes.func.isRequired,
  openInNewTab: PropTypes.func.isRequired,
};

/**
 * Component for a text message
 *
 * @param {Jsx} children
 */
const TextMessage = ({ children }) => {
  return <>{children}</>;
};

TextMessage.propTypes = {
  children: PropTypes.any,
};
