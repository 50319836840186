/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { forwardRef } from 'react';
import Scrollbar from 'react-scrollbars-custom';

import { PropTypes } from 'prop-types';

/**
 * Light themed scrollbar
 *
 * Scrollbar documentation
 * https://www.npmjs.com/package/react-scrollbars-custom
 *
 * @param {any} props forwarded props onto scrollbar control
 * @param {any} ref forwarded ref onto scrollbar control
 */
export const LightScrollbar = forwardRef((props, ref) => {
  return (
    <Scrollbar
      ref={ref}
      {...props}
      disableTrackYWidthCompensation
      trackYProps={{
        style: {
          background: 'transparent',
          width: '4px',
          right: '4px',
        },
      }}
      thumbYProps={{
        style: {
          background: 'var(--colour-grey-1)',
          opacity: 0.5,
        },
      }}
      trackXProps={{
        style: {
          background: 'transparent',
          height: '4px',
          bottom: '4px',
        },
      }}
      thumbXProps={{
        style: {
          background: 'var(--colour-grey-1)',
          opacity: 0.5,
        },
      }}
    >
      {props.children}
    </Scrollbar>
  );
});

/**
 * Dark themed scrollbar
 *
 * Scrollbar documentation
 * https://www.npmjs.com/package/react-scrollbars-custom
 *
 * @param {any} props forwarded props onto scrollbar control
 * @param {any} ref forwarded ref onto scrollbar control
 */
export const DarkScrollbar = forwardRef((props, ref) => {
  return (
    <Scrollbar
      ref={ref}
      {...props}
      disableTrackYWidthCompensation
      trackYProps={{
        style: {
          background: 'transparent',
          width: '4px',
          right: '4px',
        },
      }}
      thumbYProps={{
        style: {
          background: 'var(--colour-white)',
          opacity: 0.3,
        },
      }}
      trackXProps={{
        style: {
          background: 'transparent',
          height: '4px',
          bottom: '4px',
        },
      }}
      thumbXProps={{
        style: {
          background: 'var(--colour-white)',
          opacity: 0.3,
        },
      }}
    >
      {props.children}
    </Scrollbar>
  );
});

const propTypes = {
  createContext: PropTypes.bool,
  rtl: PropTypes.bool,
  native: PropTypes.bool,
  mobileNative: PropTypes.bool,
  momentum: PropTypes.bool,
  noDefaultStyles: PropTypes.bool,
  disableTracksMousewheelScrolling: PropTypes.bool,
  disableTrackXMousewheelScrolling: PropTypes.bool,
  disableTrackYMousewheelScrolling: PropTypes.bool,
  disableTracksWidthCompensation: PropTypes.bool,
  disableTrackXWidthCompensation: PropTypes.bool,
  disableTrackYWidthCompensation: PropTypes.bool,
  minimalThumbSize: PropTypes.number,
  maximalThumbSize: PropTypes.number,
  minimalThumbXSize: PropTypes.number,
  maximalThumbXSize: PropTypes.number,
  minimalThumbYSize: PropTypes.number,
  maximalThumbYSize: PropTypes.number,
  noScrollX: PropTypes.bool,
  noScrollY: PropTypes.bool,
  noScroll: PropTypes.bool,
  permanentTrackX: PropTypes.bool,
  permanentTrackY: PropTypes.bool,
  permanentTracks: PropTypes.bool,
  translateContentSizesToHolder: PropTypes.bool,
  translateContentSizeYToHolder: PropTypes.bool,
  translateContentSizeXToHolder: PropTypes.bool,
  removeTracksWhenNotUsed: PropTypes.bool,
  removeTrackYWhenNotUsed: PropTypes.bool,
  removeTrackXWhenNotUsed: PropTypes.bool,
  trackClickBehavior: PropTypes.oneOf(['jump', 'step']),
  scrollbarWidth: PropTypes.number,
  fallbackScrollbarWidth: PropTypes.number,
  scrollDetectionThreshold: PropTypes.number,
  scrollTop: PropTypes.number,
  scrollLeft: PropTypes.number,
  className: PropTypes.string,
  wrapperProps: PropTypes.object,
  contentProps: PropTypes.object,
  trackXProps: PropTypes.object,
  trackYProps: PropTypes.object,
  thumbXProps: PropTypes.object,
  thumbYProps: PropTypes.object,
  onUpdate: PropTypes.func,
  onScroll: PropTypes.func,
  onScrollStart: PropTypes.func,
  onScrollStop: PropTypes.func,
};

LightScrollbar.propTypes = propTypes;
DarkScrollbar.propTypes = propTypes;
