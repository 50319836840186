/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import React, { useEffect, useRef, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getTeams, getTeamUsers } from '../../../api/missions';
import dragSVG from '../../../assets/svg/assignable-units.svg';
import { SIDEBARS, useSidebar } from '../../../context/SidebarContext';
import { sortBy } from '../../../utils/array';
import { addEventHandler, removeEventHandler } from '../../../utils/handlers';
import { cancellablePromise } from '../../../utils/promise';
import { displayName, getClassNames, capitalize } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { MenuButton } from '../../common/Menu';
import { PleaseRotate } from '../../common/PleaseRotate';
import { PortalAware } from '../../common/PortalAware';
import { DarkScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import { Sidebar } from '../../common/Sidebar';
import { CreateEditTeam } from '../../sidebars/mission/CreateEditTeam';
import { UnitInformation } from '../../sidebars/mission/UnitInformation';
import './AssignedTeams.scss';
import { LoadingUnitPanel } from './MissionUnitsAndAllies';
import { secrets } from '../../../config/secrets';

// const MAX_ALLIES = 2;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'rgba(255,255,255,0.07)' : null,
});

export const AssignedTeams = ({
  missionId,
  updateContext,
  readOnly,
  isOwner,
  removeFromMission,
  removeFromTeam,
  unit,
  setUnit,
  personnelInMission,
  triggerRefreshUsers,
  draggingFrom,
  loading,
  archived,
}) => {
  const sidebars = useSidebar();
  const isDragging = useRef(false);
  const isScaling = useRef(false);
  const lastTouchPosition = useRef(null);
  const lastDistance = useRef(null);
  const [translate, setTranslate] = useState([0, 0]);
  const [scale, setScale] = useState(1.0);

  const localTranslate = useRef(translate);
  const localScale = useRef(scale);

  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);

  const _teamUsers = useRef([]);
  const [teamUsers, setTeamUsers] = useState([]);

  const listeners = useRef({});

  const canvas = useRef();

  // eslint-disable-next-line
  const getRemoveStyle = (isDraggingOver) => ({
    position: 'fixed',
    bottom: '30px',
    left: 'calc(83px + 298px + 30px)',
    height: '72px',
    width: '325px',
    borderRadius: '12px',
    background: isDraggingOver ? 'rgba(255,255,255,0.07)' : 'transparent',
  });

  const refreshTeams = async () => {
    const _teams = await getTeams(missionId);
    setTeams(_teams);
  };

  useEffect(() => {
    localTranslate.current = translate;
    localScale.current = scale;
  });

  const onTouchMove = (e) => {
    const dragPortal = document.getElementById('drag-portal');
    if (dragPortal.children.length) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    if (isScaling.current) {
      const distance = Math.hypot(
        e.touches[0].clientX - e.touches[1].clientX,
        e.touches[0].clientY - e.touches[1].clientY
      );
      const diffDist = distance - lastDistance.current;
      let newScale = localScale.current + diffDist / 300;
      if (newScale <= 0.1) {
        newScale = 0.1;
      }
      setScale(newScale);
      lastDistance.current = distance;
    }

    if (lastTouchPosition.current) {
      const delta = [
        e.touches[0].clientX - lastTouchPosition.current[0],
        e.touches[0].clientY - lastTouchPosition.current[1],
      ];

      const x = localTranslate.current[0];
      const y = localTranslate.current[1];
      setTranslate([x + delta[0], y + delta[1]]);

      lastTouchPosition.current = [e.touches[0].clientX, e.touches[0].clientY];
    }
  };

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(refreshTeams());
    promise.then(() => {}).catch((e) => {});

    removeEventHandler(listeners.current, canvas.current, 'touchmove');
    addEventHandler(
      listeners.current,
      canvas.current,
      'touchmove',
      onTouchMove
    );

    return () => {
      // eslint-disable-next-line
      removeEventHandler(listeners.current, canvas.current, 'touchmove');
      cancel();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refreshTeams();
    // eslint-disable-next-line
  }, [triggerRefreshUsers]);

  return (
    <div className="assigned-teams">
      <div
        ref={canvas}
        className="canvas"
        style={{
          backgroundSize: `${50 * scale}px ${50 * scale}px`,
          backgroundPosition: `${translate[0] / scale}px ${
            translate[1] / scale
          }px`,
        }}
        onMouseDown={(e) => {
          e.target.classList.add('grabbing');
          isDragging.current = true;
        }}
        onMouseUp={(e) => {
          e.target.classList.remove('grabbing');
          isDragging.current = false;
        }}
        onMouseMove={(e) => {
          if (isDragging.current) {
            const x = translate[0];
            const y = translate[1];
            setTranslate([x + e.movementX, y + e.movementY]);
          }
        }}
        onWheel={(e) => {
          const newScale = scale + e.deltaY * -0.001;
          if (newScale <= 0.1) {
            return;
          }
          setScale(newScale);
        }}
        onTouchStart={(e) => {
          const dragPortal = document.getElementById('drag-portal');
          if (dragPortal.children.length) {
            return;
          }

          isScaling.current = e.touches.length === 2;

          lastTouchPosition.current = [
            e.touches[0].clientX,
            e.touches[0].clientY,
          ];
          if (isScaling.current) {
            lastDistance.current = Math.hypot(
              e.touches[0].clientX - e.touches[1].clientX,
              e.touches[0].clientY - e.touches[1].clientY
            );
          }
        }}
        onTouchEnd={(e) => {
          lastTouchPosition.current = null;
          lastDistance.current = null;
          e.target.classList.remove('grabbing');
          isDragging.current = false;
          isScaling.current = false;
        }}
      >
        <div
          className="container"
          style={{
            transform: `scale(${scale}) translate(${translate
              .map((t) => `${t / scale}px`)
              .join(',')})`,
          }}
        >
          {isOwner && !readOnly && (
            <div className="team new">
              <div
                className="body new"
                onClick={() => {
                  setTeam(null);
                  sidebars.open(SIDEBARS.editTeam);
                }}
              >
                <FontAwesomeIcon icon="plus" />
                <span>Create New Team</span>
              </div>
            </div>
          )}
          {teams?.map((team, index) => (
            <MissionTeamPanel
              key={index}
              missionId={missionId}
              team={team}
              onEdit={(t) => {
                setTeam(t);
                sidebars.open(SIDEBARS.editTeam);
              }}
              setRole={setUnit}
              setUser={setUnit}
              removeFromTeam={removeFromTeam}
              triggerRefreshUsers={triggerRefreshUsers[team.id]}
              setTeamUsers={(id, _users) => {
                _teamUsers.current = [
                  ..._teamUsers.current.filter((u) => u.teamId !== id),
                  ..._users,
                ];
                setTeamUsers(_teamUsers.current);
              }}
              archived={archived}
            />
          ))}
        </div>
        <div
          className="info"
          onClick={() => {
            setScale(1);
            setTranslate([0, 0]);
          }}
        >
          x{scale.toFixed(1)}
        </div>
      </div>
      <MissionUsersPanel
        users={personnelInMission}
        removeFromMission={removeFromMission}
        draggingFrom={draggingFrom}
        teamUsers={teamUsers}
        loading={loading}
      ></MissionUsersPanel>
      <Sidebar id={SIDEBARS.unitInformation}>
        <UnitInformation
          closeSidebar={() => {
            setUnit(null);
            sidebars.close(SIDEBARS.unitInformation);
          }}
          unit={unit}
          teams={teams}
          missionId={missionId}
        />
      </Sidebar>
      <Sidebar id={SIDEBARS.editTeam}>
        <CreateEditTeam
          closeSidebar={() => {
            refreshTeams();
            setTeam(null);
            sidebars.close(SIDEBARS.editTeam);
          }}
          team={team}
          missionId={missionId}
        />
      </Sidebar>
      <PleaseRotate></PleaseRotate>
    </div>
  );
};

const MissionUsersPanel = ({
  users,
  setUser,
  removeFromMission,
  draggingFrom,
  teamUsers,
  loading,
}) => {
  const [search, setSearch] = useState('');
  const [usersFilter, setUsersFilter] = useState(null);

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    // styles we need to apply on draggables
    ...{ ...draggableStyle },
    width: isDragging ? '250px' : '',
  });

  const isUserInTeam = (id) => {
    return !!teamUsers.find((u) => u.id === id);
  };

  const filteredUsers = sortBy(
    users.filter(
      (g) =>
        `${g.firstname} ${g.lastname}`
          .toLowerCase()
          .includes(search.toLowerCase()) &&
        (!usersFilter ||
          (usersFilter === 'in-team' && isUserInTeam(g.id)) ||
          (usersFilter === 'no-team' && !isUserInTeam(g.id)))
    ),
    ['guest', 'firstName', 'lastName'],
    ['asc', 'asc', 'asc']
  );

  return (
    <Droppable droppableId={`mission`}>
      {(provided, snapshot) => (
        <div
          className="mission"
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          <div className="heading">
            <div className="search-bar">
              <div>
                <MenuButton
                  options={[
                    {
                      text: `Users In ${capitalize(secrets.EVENT_NAME)}`,
                      ariaLabel: `Users In ${capitalize(secrets.EVENT_NAME)}`,
                      onClick: () => {
                        setUsersFilter(null);
                      },
                    },
                    {
                      text: 'Users In A Team',
                      ariaLabel: 'Users In A Team',
                      onClick: () => {
                        setUsersFilter('in-team');
                      },
                    },
                    {
                      text: 'Users Without A Team',
                      ariaLabel: 'Users Without A Team',
                      onClick: () => {
                        setUsersFilter('no-team');
                      },
                    },
                  ]}
                >
                  <span>
                    {usersFilter === 'no-team'
                      ? 'Users Without A Team'
                      : usersFilter === 'in-team'
                      ? 'Users In A Team'
                      : `Users In ${capitalize(secrets.EVENT_NAME)}`}
                  </span>
                  <FontAwesomeIcon icon="chevron-down" />
                </MenuButton>
              </div>
              <SearchInput
                placeholder="Search for a user"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <DarkScrollbar noScrollX>
            <div className="body">
              {loading ? (
                <LoadingUnitPanel />
              ) : (
                <>
                  {!users.length && (
                    <div className="drag-empty">
                      <img src={dragSVG} alt="Drag Icon" />
                      <span>
                        Drag a role or user here to assign them to the{' '}
                        {secrets.EVENT_NAME}.
                      </span>
                    </div>
                  )}
                  {filteredUsers.map((user, index) => (
                    <Draggable
                      key={user.id}
                      draggableId={`mission_${user.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <React.Fragment>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className={getClassNames({
                              'assigned-user': true,
                              dragging: snapshot.isDragging,
                            })}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <MissionUserPanel
                              draggable={true}
                              user={user}
                              removeFromMission={removeFromMission}
                              setUser={setUser}
                            />
                          </div>
                          {snapshot.isDragging && (
                            <div className="clone">
                              <MissionUserPanel draggable={true} user={user} />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                </>
              )}
              {provided.placeholder}
            </div>
          </DarkScrollbar>
          <div
            className={getClassNames({
              remove: true,
              highlight:
                draggingFrom?.includes('team-') ||
                draggingFrom?.includes('guest'),
              dim:
                draggingFrom &&
                !(
                  draggingFrom?.includes('team-') ||
                  draggingFrom?.includes('guest')
                ),
            })}
          >
            <div>
              <FontAwesomeIcon icon="minus-circle" />
            </div>
            <div>Drag a user here to remove them from a team.</div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

export const MissionTeamPanel = ({
  missionId,
  team,
  onEdit,
  setRole,
  setUser,
  removeFromTeam,
  removeFromMission,
  triggerRefreshUsers,
  setTeamUsers,
  archived,
}) => {
  // const fixTransform = (style) => {
  //   const offsetX = 80 + 300 + 30;
  //   const offsetY = 80 + 90;

  //   if (style.transform) {
  //     const t = style.transform.replace('translate(', '').replace(')', '');
  //     const x = t.split(',')[0].trim().replace('px', '');
  //     const y = t.split(',')[1].trim().replace('px', '');

  //     const newX = x - translate[0] / scale - offsetX;
  //     const newY = y - translate[1] / scale - offsetY;

  //     return `translate(${newX}px, ${newY}px)`;
  //   }
  //   return '';
  // };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
    width: isDragging ? '250px' : '',
  });

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const localTouchStartListeners = useRef([]);

  const onTouchStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const refreshUsers = async () => {
    setLoading(true);
    const _users = await getTeamUsers(missionId, team.id);
    setUsers(
      sortBy(_users, ['guest', 'firstName', 'lastName'], ['asc', 'asc', 'asc'])
    );
    if (setTeamUsers) {
      setTeamUsers(
        team.id,
        _users?.map((u) => {
          return {
            id: u.id,
            teamId: team.id,
          };
        }) || []
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      localTouchStartListeners.current.push(onTouchStart);
      for (const au of document.getElementsByClassName('assigned-user')) {
        au.addEventListener('touchstart', onTouchStart, {
          passive: false,
        });
      }
    }, 500);

    // const { promise, cancel } = cancellablePromise(refreshUsers());
    // promise.then(() => {}).catch((e) => {});

    return () => {
      for (const au of document.getElementsByClassName('assigned-user')) {
        for (const l of localTouchStartListeners.current) {
          au.removeEventListener('touchstart', l);
        }
      }
      localTouchStartListeners.current = [];
      // cancel();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    log.debug('triggerRefreshUsers', triggerRefreshUsers);

    const { promise, cancel } = cancellablePromise(refreshUsers());
    promise.then(() => {}).catch((e) => {});
    return cancel();
    // eslint-disable-next-line
  }, [triggerRefreshUsers]);

  return (
    <Droppable droppableId={`team-${team.id}`}>
      {(provided, snapshot) => (
        <div
          className="team"
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          <div className="heading">
            <div>
              <Avatar entity={team} />
              <span>{team.team}</span>
            </div>
            {loading ? (
              <FontAwesomeIcon icon="spinner" spin />
            ) : (
              <>
                {!archived && (
                  <DotButton tip="Edit Team" onClick={() => onEdit(team)}>
                    <FontAwesomeIcon icon="pencil-alt" />
                  </DotButton>
                )}
              </>
            )}
          </div>
          <div className="body">
            {loading ? (
              <LoadingUnitPanel />
            ) : (
              <>
                {!users?.length && (
                  <div
                    className="drag-empty"
                    style={{
                      textAlign: 'center',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <img src={dragSVG} alt="Drag Icon" />
                    <div>
                      Drag a role or user here to assign them to this team.
                    </div>
                  </div>
                )}
                {!!users?.length &&
                  users.map((user, index) => (
                    <Draggable
                      key={`${team.id}_${user.id}`}
                      draggableId={`${team.id}_${user.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <React.Fragment>
                          <PortalAware provided={provided} snapshot={snapshot}>
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className={getClassNames({
                                'assigned-user': true,
                                dragging: snapshot.isDragging,
                              })}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <MissionUserPanel
                                team={team}
                                draggable={true}
                                user={user}
                                removeFromTeam={removeFromTeam}
                                removeFromMission={removeFromMission}
                                setUser={setUser}
                              />
                            </div>
                          </PortalAware>
                          {snapshot.isDragging && (
                            <div className="clone">
                              <MissionUserPanel draggable={true} user={user} />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                {!!users?.length && (
                  <div
                    style={{
                      border: 'dashed 1px white',
                      userSelect: 'none',
                      maxWidth: 'calc(12.5rem - 34px)',
                      width: 'calc(12.5rem - 34px)',
                      marginTop: '1rem',
                      marginLeft: '1rem',
                      height: '36.25px',
                      borderRadius: '6px',
                      opacity: '0.5',
                      padding: '0.25em 0.5em',
                      fontSize: '0.8em',
                    }}
                  >
                    Drag a role or user here to assign them to this team.
                  </div>
                )}
              </>
            )}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

const MissionUserPanel = ({
  team,
  user,
  removeFromMission,
  removeFromTeam,
  setUser,
  draggable,
}) => {
  const sidebars = useSidebar();

  const options = [];

  if (setUser) {
    options.push({
      text: 'User Info',
      icon: 'info',
      ariaLabel: 'User Info',
      onClick: () => {
        setUser(user);
        sidebars.open(SIDEBARS.unitInformation);
      },
    });
  }

  if (team && removeFromTeam) {
    options.push({
      text: 'Remove from team',
      icon: 'minus-circle',
      ariaLabel: 'Remove from team',
      onClick: () => {
        removeFromTeam(team.id, [user.id]);
      },
    });
  }

  if (removeFromMission) {
    options.push({
      text: `Remove from ${secrets.EVENT_NAME}`,
      icon: 'minus-circle',
      ariaLabel: `Remove from ${secrets.EVENT_NAME}`,
      onClick: () => {
        removeFromMission([user.id]);
      },
    });
  }

  return (
    <div
      className={getClassNames({
        'unit-panel-container': true,
        draggable,
        guest: user.guest,
      })}
    >
      <div className="body">
        {/* <div className="breadcrumb assigned">{user.breadcrumb}</div> */}
        <div className="unit">
          <div className="img">
            <Avatar entity={user} size={'var(--team-size)'} />
          </div>
          <div className="text">
            {displayName(user)}
            <span>{user.role?.role}</span>
          </div>
          <div className="more">
            {!!options.length && (
              <MenuButton
                buttonTheme="light"
                position="right"
                options={options}
                ariaLabel="Team Menu"
              >
                <FontAwesomeIcon icon="bars" />
              </MenuButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
