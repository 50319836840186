/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactSwitch from 'react-switch';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import './MissionIntegrations.scss';
import { PropTypes } from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getClassNames } from '../../../utils/string';
import svgGrip from '../../../assets/svg/grip-dots.svg';
import { MenuButton } from '../../common/Menu';
import { CheckBox } from '../../common/CheckBox';
import {
  deleteMissionIntegration,
  getMissionIntegrations,
} from '../../../api/missions';
import toast from 'react-hot-toast';
import { getIntegrationOptions } from '../../../api/orgs_users';
import pluralize from 'pluralize';
import { getLocalStorage } from '../../../utils/localStorage';
import { secrets } from '../../../config/secrets';

/**
 * Map Sidebar - mission integrations
 * Lists all integrations added for the mission
 *
 * @param {Function} closeSidebar callback to close sidebar
 * @param {Function} addIntegration to add Integration
 * @param {any[]} allIntegrations mission integrations list
 * @param {Function} setAllIntegrations callback to set integrations
 * @param {Number} MAX_INTEGRATIONS visible integrations
 * @param {string} missionId current mission Id
 * @param {Boolean} isDarkMode isDarkMode
 * @param {Function} removeWeatherLayer callback to remove weather layer
 */

export const MissionAddedIntegrations = ({
  closeSidebar,
  addIntegration,
  allIntegrations,
  setAllIntegrations,
  missionId,
  isDarkMode,
  MAX_INTEGRATIONS,
  removeWeatherLayer,
}) => {
  const [visibleIntegrations, setVisibleIntegrations] = useState([]);
  const [hiddenIntegrations, setHiddenIntegrations] = useState([]);
  const [integration, setIntegration] = useState(null);
  const [selectAll, setSelectAll] = useState(integration?.allOptions);
  const [selectedOptions, setSelectedOptions] = useState(
    integration?.options || []
  );
  const [integrationOptions, setIntegrationOptions] = useState(null);

  // eslint-disable-next-line
  const removeIntegration = async (id) => {
    try {
      const _integrations = [...allIntegrations];
      const selectedIntegration = _integrations.find((_i) => _i.id === id);

      await deleteMissionIntegration(missionId, selectedIntegration.id);

      if (selectedIntegration.organisationIntegration.code === `BOM_WEATHER`) {
        removeWeatherLayer();
      }

      toast.success(`
        ${selectedIntegration.organisationIntegration.name} was successfully removed from the ${secrets.EVENT_NAME}`);
      const newIntegrations = (await getMissionIntegrations(missionId)).map(
        (i) => {
          i.active =
            getLocalStorage(`integrations-${missionId}`).find(
              (mi) => mi.id === i.id
            )?.active || false;
          i.visibleInToolbar =
            getLocalStorage(`integrations-${missionId}`).find(
              (mi) => mi.id === i.id
            )?.visibleInToolbar || false;
          return i;
        }
      );
      setAllIntegrations(newIntegrations);
      setIntegration(null);
    } catch (err) {
      toast.error('Error removing integration');
    }
  };

  useEffect(() => {
    const length = allIntegrations.length;
    if (length) {
      if (allIntegrations.some((i) => i.visibleInToolbar)) {
        setVisibleIntegrations(
          allIntegrations.filter((i) => i.visibleInToolbar)
        );
      } else {
        setVisibleIntegrations([]);
      }
      setHiddenIntegrations(allIntegrations.filter((i) => !i.visibleInToolbar));
    }

    if (!length) {
      setVisibleIntegrations([]);
      setHiddenIntegrations([]);
    }
    // eslint-disable-next-line
  }, [allIntegrations]);

  useEffect(() => {
    if (!integration) return;
    setSelectAll(integration?.allOptions);
    setSelectedOptions(integration?.options || []);
  }, [integration]);

  const moveIntegration = (fromIndex, droppableId, destination, id) => {
    let integrations = [...allIntegrations];

    if (droppableId === destination?.droppableId) {
      if (droppableId === 'hidden') {
        fromIndex += integrations.filter((i) => i.visibleInToolbar).length - 1;
      }
      const [reorderedItem] = integrations.splice(fromIndex, 1);
      integrations.splice(destination.index, 0, reorderedItem);
      setAllIntegrations([...integrations]);
      return;
    }

    if (id) {
      fromIndex = integrations.findIndex((_i) => _i.id === id);
    }

    if (droppableId === 'hidden' && !!destination) {
      const elementIndex = id
        ? fromIndex
        : fromIndex + integrations.filter((i) => i.visibleInToolbar).length;
      const element = integrations[elementIndex];
      integrations.splice(elementIndex, 1);
      element.visibleInToolbar = true;
      const destinationIndex = destination?.index ? destination.index : 0;
      integrations.splice(destinationIndex, 0, element);

      if (
        integrations.filter((i) => i.visibleInToolbar).length >=
        MAX_INTEGRATIONS
      ) {
        integrations.forEach((i, index) => {
          if (index >= MAX_INTEGRATIONS) i.visibleInToolbar = false;
        });
      }
    }
    if (droppableId === 'visible' && !!destination) {
      if (integrations.length === 1) {
        fromIndex = Math.max(0, fromIndex);
        const element = integrations[fromIndex];
        element.visibleInToolbar = false;
      } else {
        fromIndex = Math.max(0, fromIndex);
        const element = integrations[fromIndex];
        integrations.splice(fromIndex, 1);
        element.visibleInToolbar = false;
        integrations.push(element);
      }
    }

    setAllIntegrations([...integrations]);
  };

  const setSelected = (id, active) => {
    let integrations = [...allIntegrations];
    integrations.find((_i) => _i.id === id).active = !active;
    setAllIntegrations([...integrations]);
  };

  return integration === null ? (
    <div className="mission-integrations shadow">
      <div className="top">
        <div className="heading">
          <div className="text">Integrations</div>
          <DotButton
            className="exit"
            onClick={() => {
              closeSidebar();
            }}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        <div className="count">
          {pluralize('Integration', allIntegrations.length, true)}{' '}
        </div>
      </div>
      <LightScrollbar className="content">
        <DragDropContext
          onDragEnd={(result) => {
            moveIntegration(
              result.source.index,
              result.source.droppableId,
              result.destination
            );
          }}
        >
          <Droppable droppableId="visible">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="blocks gap pad integration-list">
                  <div className="integration-title">
                    <FontAwesomeIcon
                      icon="eye"
                      style={{
                        width: '16px',
                        color: isDarkMode ? '#ffffff' : '#191e1f',
                      }}
                    />{' '}
                    Visible in toolbar
                  </div>
                  {visibleIntegrations.map((integration, index) => (
                    <Draggable
                      key={integration.id}
                      draggableId={integration.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <React.Fragment>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={getClassNames({
                              dragging: snapshot.isDragging,
                            })}
                          >
                            <IntegrationSection
                              key={index}
                              setSelected={setSelected}
                              integration={integration}
                              setIntegration={setIntegration}
                              setIntegrationOptions={setIntegrationOptions}
                              removeIntegration={removeIntegration}
                              isDarkMode={isDarkMode}
                              moveIntegration={() => {
                                moveIntegration(index, 'visible');
                              }}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
          <Droppable droppableId="hidden">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ paddingBottom: '200px' }}
              >
                <div className="blocks gap pad integration-list">
                  <div className="integration-title">
                    <FontAwesomeIcon
                      icon="eye-slash"
                      style={{
                        width: '16px',
                        color: isDarkMode ? '#ffffff' : '#191e1f',
                      }}
                    />{' '}
                    Hidden in toolbar
                  </div>
                  {hiddenIntegrations.map((integration, index) => (
                    <Draggable
                      key={integration.id}
                      draggableId={integration.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <React.Fragment>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={getClassNames({
                              dragging: snapshot.isDragging,
                            })}
                          >
                            <IntegrationSection
                              key={index}
                              setSelected={setSelected}
                              integration={integration}
                              setIntegration={setIntegration}
                              setIntegrationOptions={setIntegrationOptions}
                              removeIntegration={removeIntegration}
                              isDarkMode={isDarkMode}
                              moveIntegration={() => {
                                moveIntegration(index, 'hidden');
                              }}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </LightScrollbar>
      <div className="foot">
        <DotButton
          onClick={(e) => {
            addIntegration();
          }}
          className="primary block"
        >
          ADD INTEGRATION TO {secrets.EVENT_NAME.toUpperCase()} +
        </DotButton>
      </div>
    </div>
  ) : (
    <div className="mission-integrations">
      <div className="top has-back">
        <div className="heading">
          <div className="text">Integration Details</div>
          <DotButton
            className="exit"
            onClick={() => {
              closeSidebar();
            }}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        <DotButton
          className="back"
          onClick={() => {
            setIntegration(null);
          }}
          ariaLabel="Back"
        >
          <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
        </DotButton>
        <div className="heading-container">
          <div className="img width-60">
            <img
              src={integration.organisationIntegration.photoUrl}
              alt="integration logo"
              style={{
                height: '60px',
                width: '60px',
                borderRadius: '50%',
              }}
            />
          </div>
          <div className="v-center margin-r10">
            <div className="text">Visible in toolbar</div>
            <label>
              <ReactSwitch
                height={28}
                width={56}
                checkedIcon={false}
                uncheckedIcon={false}
                checked={integration.visibleInToolbar || false}
                onColor={'#2c7691'}
                offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                onChange={(checked) => {
                  if (integration.visibleInToolbar !== checked)
                    moveIntegration(
                      '',
                      integration.visibleInToolbar ? 'visible' : 'hidden',
                      '',
                      integration.id
                    );
                }}
              ></ReactSwitch>
            </label>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '45px',
          }}
        >
          <div style={{ alignSelf: 'flex-start' }}>
            <div
              className="text integration-name"
              style={{ fontWeight: '600', marginBottom: '30px' }}
            >
              {integration.organisationIntegration.name}
            </div>
          </div>
        </div>
        <hr />
        {!!integration.description && (
          <div className="text" style={{ marginTop: '15px' }}>
            {integration.description}
          </div>
        )}

        <div
          className="text integration-options"
          style={{ fontWeight: '600', marginTop: '30px' }}
        >
          Integration Options
        </div>
      </div>
      <LightScrollbar className="content">
        {integrationOptions && (
          <div className="blocks">
            <div
              className="item-container"
              style={{
                alignItems: 'center',
                maxHeight: '5px',
                flexDirection: 'row-reverse',
              }}
            >
              <div className="check">
                <CheckBox
                  id={`checkbox-all`}
                  value={'selectAll'}
                  onChange={(event) => {
                    setSelectAll(event.target.checked);
                    if (event.target.checked) {
                      setSelectedOptions([
                        ...integrationOptions.map((o) => o.id),
                      ]);
                    } else {
                      setSelectedOptions([]);
                    }
                  }}
                  checked={selectAll}
                ></CheckBox>
              </div>
              <label
                htmlFor={`checkbox-all`}
                className="text"
                style={{ cursor: 'pointer' }}
              >
                Select All
              </label>
            </div>
            {integrationOptions.map((option, index) => (
              <div
                key={index}
                className="item-container"
                style={{ alignItems: 'center' }}
              >
                <div className="check">
                  <CheckBox
                    id={`checkbox-${index}`}
                    value={option.id}
                    onChange={(event) => {
                      let options = [...selectedOptions];
                      if (event.target.checked) {
                        options.push(option.id);
                      } else {
                        options = options.filter(
                          (optionId) => optionId !== option.id
                        );
                      }
                      setSelectedOptions(options);

                      let allSelected =
                        options.length >= integrationOptions.length;
                      for (const io of integrationOptions) {
                        if (!options.find((o) => o !== io.id)) {
                          allSelected = false;
                          break;
                        }
                      }
                      setSelectAll(allSelected);
                    }}
                    checked={!!selectedOptions.find((o) => o === option.id)}
                  ></CheckBox>
                </div>
                <label
                  htmlFor={`checkbox-${index}`}
                  className="text"
                  style={{ cursor: 'pointer' }}
                >
                  {option.deviceFriendlyName}
                </label>
              </div>
            ))}
          </div>
        )}
      </LightScrollbar>
      <div className="foot">
        <DotButton
          onClick={(e) => {
            removeIntegration(integration.id);
          }}
          className="primary block"
        >
          REMOVE FROM {secrets.EVENT_NAME.toUpperCase()}
        </DotButton>
      </div>
    </div>
  );
};

MissionAddedIntegrations.propTypes = {
  closeSidebar: PropTypes.func,
  addIntegration: PropTypes.func,
  allIntegrations: PropTypes.array,
  setAllIntegrations: PropTypes.func,
  missionId: PropTypes.string,
  MAX_INTEGRATIONS: PropTypes.number,
  removeWeatherLayer: PropTypes.func,
};

/**
 * Integration list item
 *
 * @param {object} integration integration
 * @param {Function} setIntegration set Integration
 * @param {Function} setSelected set active
 * @param {Function} setIntegrationOptions set Integration Options
 * @param {Function} removeIntegration set remove Integration
 * @param {Function} moveIntegration set move Integration
 * @param {Boolean} isDarkMode isDarkMode
 */
const IntegrationSection = ({
  integration,
  setIntegration,
  setSelected,
  setIntegrationOptions,
  removeIntegration,
  moveIntegration,
  isDarkMode,
}) => {
  const handleCheckElement = (checked) => {
    setSelected(integration.id, integration.active);
  };

  const lableForMoveOption = integration.visibleInToolbar
    ? 'Remove from toolbar'
    : 'Add to toolbar';

  const options = [
    {
      text: 'Integration Details',
      ariaLabel: 'Integration Details',
      onClick: async () => {
        setIntegration(integration);
        setIntegrationOptions(
          await getIntegrationOptions(integration.organisationIntegrationId)
        );
      },
    },
    {
      text: lableForMoveOption,
      ariaLabel: lableForMoveOption,
      onClick: () => {
        moveIntegration();
      },
    },
    {
      text: `Remove from ${secrets.EVENT_NAME}`,
      ariaLabel: `Remove from ${secrets.EVENT_NAME}`,
      onClick: () => {
        removeIntegration(integration.id);
      },
    },
  ];

  return (
    <div className="item-container">
      <img src={svgGrip} alt="grip" className="grip" />
      <div className="img">
        <img
          src={integration.organisationIntegration.photoUrl}
          alt="integration logo"
          style={{
            height: '35px',
            width: '35px',
            borderRadius: '50%',
            padding: '2px',
            border: integration.active
              ? 'solid #56B8DB 3px'
              : 'solid #E5E8F4 3px',
          }}
        />
      </div>
      <div className="text">{integration.organisationIntegration.name}</div>
      <div className="v-center margin-r10">
        <label>
          <ReactSwitch
            height={28}
            width={56}
            checkedIcon={false}
            uncheckedIcon={false}
            checked={integration.active || false}
            onColor={'#2c7691'}
            offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
            onChange={handleCheckElement}
          ></ReactSwitch>
        </label>
      </div>
      <div className="v-center">
        <MenuButton position="left" options={options} ariaLabel="Menu">
          <FontAwesomeIcon icon="ellipsis-v"></FontAwesomeIcon>
        </MenuButton>
      </div>
    </div>
  );
};

IntegrationSection.propTypes = {
  integration: PropTypes.object,
  setIntegration: PropTypes.func,
  setSelected: PropTypes.func,
  setIntegrationOptions: PropTypes.func,
  removeIntegration: PropTypes.func,
  moveIntegration: PropTypes.func,
  isDarkMode: PropTypes.bool,
};
