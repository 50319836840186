/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

// External Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { postRole } from '../../api/roles';
import { orgRoleType } from '../../enums/propTypes';
import { handleError } from '../../utils/error';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { ColorPicker } from '../common/ColorPicker';
import { ShapeColors } from '../../enums/geofenceLevel';

/**
 * Sidebar - Edit role details
 *
 * @param {any} role role to edit
 * @param {any} orgSelected selected organisation
 * @param {Function} closeSidebar callback to close sidebar
 */
export const CreateEditRole = ({ role, orgSelected, closeSidebar }) => {
  const getColorCode = (value) => {
    if (!value) return 'Black';
    let colorCode =
      Object.keys(ShapeColors).find((key) => ShapeColors[key] === value) ||
      'Black';
    return colorCode;
  };

  const [inputValues, setInputValues] = useState(
    role
      ? {
          role: role.role,
          color: getColorCode(role.color),
        }
      : { role: '', color: 'Black' }
  );
  const [touched, setTouched] = useState(false);

  const handleSubmitRole = async (e) => {
    e.preventDefault();

    const organisationId = role
      ? role.id
      : orgSelected.organisationId
      ? orgSelected.organisationId
      : orgSelected.id;

    // Handle missing parameters
    if (inputValues.role === '') {
      toast.error(`Role field required`);
      return;
    }
    if (!inputValues.color) {
      toast.error(`Theme colour field required`);
      return;
    }

    // Create new (or edited) group and send to api
    let newRole = { ...inputValues, organisationId };
    if (role) {
      newRole.id = role.id;
    }

    newRole.color = ShapeColors[inputValues.color] || inputValues.color;

    try {
      await postRole(organisationId, newRole);
    } catch (err) {
      handleError(err);
    }

    closeSidebar(); // close side panel
  };

  const _setInputValues = (value) => {
    setInputValues(value);
    setTouched(true);
  };

  const isValid = () => {
    return !!inputValues.role && !!inputValues.color;
  };

  return (
    <div className="create-group">
      <div className="top">
        <div className="heading">
          <div className="text">{role ? 'Edit Role' : 'New Role'}</div>
          <DotButton className="exit" onClick={closeSidebar} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <div className="content">
        <h3>General Information</h3>
        <form>
          <div className="controls">
            <Input
              label="Role"
              placeholder="Enter role name."
              onChange={(e) =>
                _setInputValues({
                  ...inputValues,
                  role: e.currentTarget.value,
                })
              }
              value={inputValues.role || ''}
              required={true}
            />
            <ColorPicker
              changeColor={(color) => {
                _setInputValues({ ...inputValues, color });
              }}
              selectedColor={inputValues.color}
            ></ColorPicker>
          </div>
        </form>
      </div>
      <div className="foot">
        <DotButton
          className="primary block"
          type="submit"
          disabled={!touched || !isValid()}
          onClick={handleSubmitRole}
        >
          {role ? 'Save Changes' : 'Create Role'}
        </DotButton>
      </div>
    </div>
  );
};

CreateEditRole.propTypes = {
  role: orgRoleType,
  orgSelected: PropTypes.any,
  closeSidebar: PropTypes.func.isRequired,
};
