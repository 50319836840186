/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import './Tasks.scss';
import { PropTypes } from 'prop-types';
import { cancellablePromise } from '../../../utils/promise';
import { getOrders, getTasksForUser } from '../../../api/orders';
import { useEffect, useState } from 'react';
import './PersonnelTasks.scss';
import { renderRichText } from '../../../utils/richtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Avatar } from '../../common/Avatar';

/**
 * Display tasks for a user
 *
 * @param {string} userId
 * @param {string} missionId
 * @param {any[]} users
 * @param {any[]} teams
 */
export const PersonnelTasks = ({ missionId, userId, users, teams }) => {
  const [tasks, setTasks] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setOrders(await getOrders(missionId));
    return await getTasksForUser(missionId, userId);
  };

  useEffect(() => {
    setLoading(true);

    const { promise, cancel } = cancellablePromise(getData());
    promise
      .then((_tasks) => {
        for (const t of _tasks) {
          for (const a of t.assigned) {
            if (a.type === 'team') {
              a.entity = teams.find((t) => t.id === a.id);
            } else if (a.type === 'user') {
              a.entity = users.find((u) => u.id === a.id);
            }
          }
        }

        setTasks(_tasks);
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });

    return cancel;
    // eslint-disable-next-line
  }, [missionId, userId, users, teams]);

  if (loading) {
    return (
      <div style={{ padding: '1rem' }}>
        <FontAwesomeIcon icon="spinner" spin />
      </div>
    );
  }

  return tasks.length ? (
    <div className="tasks">
      {tasks.map((t, idx) => {
        return (
          <div className="task">
            <h5>
              {orders.sections.find((o) => o.id === t.orderSectionId)?.name}
            </h5>
            <h4>{t.name}</h4>
            {renderRichText(t?.description)}
            <div className="section-foot">
              {!!t?.assigned?.length && (
                <div className="units">
                  {t.assigned.map((unit, index) => (
                    <Avatar key={index} entity={unit.entity} size="1.5rem" />
                  ))}
                </div>
              )}
              {!!t?.assigned?.length && (
                <span className="assigned">
                  {`${t.assigned.length} Assigned`}
                </span>
              )}
            </div>
            <div className="section-foot">
              {t?.timespanType === 'deadline' ? (
                <div className="time">
                  <FontAwesomeIcon icon="clock" />
                  <span>{dayjs(t.end).format('h:mma, D MMM YYYY')}</span>
                </div>
              ) : t?.timespanType === 'timeframe' ? (
                <div className="time">
                  <FontAwesomeIcon icon="clock" />
                  <span>
                    {dayjs(t.start).format('h:mma, D MMM YYYY')} -
                    {dayjs(t.end).format('h:mma, D MMM YYYY')}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <p style={{ padding: '1rem' }}>No tasks have been assigned to this user.</p>
  );
};

PersonnelTasks.propTypes = {
  userId: PropTypes.string,
  missionId: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.any),
  teams: PropTypes.arrayOf(PropTypes.any),
};
