/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClassNames } from '../../utils/string';
import { DotButton } from './buttons/DotButton';
import './ContextMenu.scss';
import { PropTypes } from 'prop-types';
import svgShapes from '../../assets/svg/shapes.svg';
import svgM2525 from '../../assets/svg/m2525.svg';
import ms from 'milsymbol';

/**
 * Context menu for use on map.
 *
 * @param {{icon: string, onClick: Function, text: string, disabled: boolean, hasSubMenu: boolean}[]} options menu options
 * @param {'light' || 'dark'} buttonTheme button theme
 */
export const ContextMenu = ({ options, buttonTheme = 'dark' }) => {
  const airSymbol = new ms.Symbol('10030100000000000000')
    .asCanvas()
    .toDataURL();
  const seaSurfaceSymbol = new ms.Symbol('10033000000000000000')
    .asCanvas()
    .toDataURL();
  const landUnitSymbol = new ms.Symbol('10031000000000000000')
    .asCanvas()
    .toDataURL();

  const landEquipmentSymbol = new ms.Symbol('10031500000000000000')
    .asCanvas()
    .toDataURL();

  const landInstallationSymbol = new ms.Symbol('10032000000000000000')
    .asCanvas()
    .toDataURL();

  return (
    <div style={{ display: 'flex' }}>
      <div className={getClassNames({ 'context-menu': true }, buttonTheme)}>
        {!!options?.length &&
          options.map((o, index) => (
            <DotButton
              key={index}
              onClick={() => {
                o.onClick();
              }}
              disabled={o.disabled}
            >
              <div className={getClassNames({ icon: true })}>
                {!!o.icon && o.icon !== 'shapes' && (
                  <FontAwesomeIcon icon={o.icon}></FontAwesomeIcon>
                )}
                {!!o.icon && o.icon === 'shapes' && (
                  <img
                    className={getClassNames({ light: true })}
                    src={svgShapes}
                    alt="shapes"
                  />
                )}
                {!!o.icon && o.icon === 'svgM2525' && (
                  <img
                    className={getClassNames({ light: true })}
                    src={svgM2525}
                    alt="m2525"
                  />
                )}
                {!!o.icon && o.icon === 'air' && (
                  <img src={airSymbol} alt="air" />
                )}
                {!!o.icon && o.icon === 'sea-surface' && (
                  <img src={seaSurfaceSymbol} alt="sea surface" />
                )}
                {!!o.icon && o.icon === 'land-unit' && (
                  <img src={landUnitSymbol} alt="land unit" />
                )}
                {!!o.icon && o.icon === 'land-equipment' && (
                  <img src={landEquipmentSymbol} alt="land equipment" />
                )}
                {!!o.icon && o.icon === 'land-installation' && (
                  <img src={landInstallationSymbol} alt="land installation" />
                )}
              </div>
              <span>{o.text}</span>
              {!!o.hasSubMenu && (
                <FontAwesomeIcon icon={'chevron-right'}></FontAwesomeIcon>
              )}
            </DotButton>
          ))}
      </div>
    </div>
  );
};

ContextMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      hasSubMenu: PropTypes.bool,
    })
  ),
  buttonTheme: PropTypes.oneOf(['light', 'dark']),
};
