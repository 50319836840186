/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { getUsersInMission } from '../../../api/missions';
import { useUser } from '../../../context/UserContext';
import { historyType } from '../../../enums/propTypes';
import { navigateToMapTargets } from '../../../utils/nav';
import { cancellablePromise } from '../../../utils/promise';
import { displayName } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { MenuButton } from '../../common/Menu';
import { LightScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import './ChatParticipants.scss';

/**
 * list of chat thread participants
 *
 * @param {any} history react router
 * @param {any} thread thread
 * @param {Function} openUnitInformation open the personnel info sidebar
 * @param {Function} createChatWithUser create chat group with specified user and open thread
 * @param {Function} createChatWithTeam create chat group with specified team and open thread
 */
export const ChatParticipants = ({
  history,
  thread,
  openUnitInformation,
  createChatWithUser,
  createChatWithTeam,
  readOnly,
}) => {
  const [search, setSearch] = useState('');
  const [participants, setParticipants] = useState([]);
  const [teams, setTeams] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    const stub = async () => {
      if (thread.missionWide) {
        const users = await getUsersInMission(thread.missionId);
        setParticipants(users);
      } else {
        setParticipants(thread?.participantsFull || []);
        setTeams(thread?.teamsFull || []);
      }
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  const filteredParticipants = participants?.filter((p) =>
    displayName(p)?.toLowerCase().includes(search?.toLowerCase())
  );
  const filteredTeams = teams?.filter((p) =>
    p.team.toLowerCase().includes(search?.toLowerCase())
  );

  return (
    <div className="chat-participants-sidebar" style={{ height: '100%' }}>
      <div>
        <div style={{ padding: '20px 20px 10px 20px' }}>
          <SearchInput
            placeholder="Search chat members"
            light
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="has-border">
        {filteredParticipants?.length ? (
          <span style={{ marginRight: '1rem' }}>
            {pluralize('user', filteredParticipants?.length, true)} listed
          </span>
        ) : (
          <></>
        )}
        {filteredTeams?.length ? (
          <span>{pluralize('team', filteredTeams?.length, true)} listed</span>
        ) : (
          <></>
        )}
      </div>
      <LightScrollbar className="content">
        <div className="list" style={{ padding: '0px 20px' }}>
          {!!filteredParticipants?.length &&
            filteredParticipants.map((h, idx) => (
              <div
                key={idx}
                className="list-item"
                style={{ boxShadow: 'none', marginTop: '0' }}
              >
                <Avatar entity={h}></Avatar>
                <div className="text">
                  {displayName(h)}
                  <span>{h.group}</span>
                </div>
                {user.id !== h.id && (
                  <DotButton
                    onClick={() => {
                      navigateToMapTargets(history, thread.missionId, [
                        { id: h.id, type: 'user' },
                      ]);
                    }}
                    tip="View on map"
                  >
                    <FontAwesomeIcon icon="map-marker" />
                  </DotButton>
                )}
                <MenuButton
                  buttonTheme="light"
                  position="left"
                  options={[
                    {
                      text: 'View User Info',
                      ariaLabel: 'View User Info',
                      icon: 'info',
                      onClick: () => {
                        openUnitInformation(h.id);
                      },
                    },
                    !!createChatWithUser && !readOnly && user.id !== h.id
                      ? {
                          text: 'Create Chat Group',
                          ariaLabel: 'Create Chat Group',
                          icon: 'plus',
                          onClick: () => {
                            createChatWithUser(h.id);
                          },
                        }
                      : null,
                  ]}
                  ariaLabel="Participant Menu"
                >
                  <FontAwesomeIcon icon="bars" />
                </MenuButton>
              </div>
            ))}

          {!!filteredTeams?.length &&
            filteredTeams.map((h, idx) => (
              <div
                key={idx}
                className="list-item"
                style={{ boxShadow: 'none', marginTop: '0' }}
              >
                <Avatar entity={h}></Avatar>
                <div className="name">{h.team}</div>
                <DotButton
                  onClick={() => {
                    navigateToMapTargets(history, thread.missionId, [
                      { id: h.id, type: 'team' },
                    ]);
                  }}
                  tip="View on map"
                >
                  <FontAwesomeIcon icon="map-marker" />
                </DotButton>
                {!!createChatWithTeam && (
                  <MenuButton
                    buttonTheme="light"
                    position="left"
                    options={[
                      !!createChatWithTeam
                        ? {
                            text: 'Create Chat Group',
                            icon: 'plus',
                            onClick: () => {
                              createChatWithTeam(h.id);
                            },
                          }
                        : null,
                    ]}
                    ariaLabel="Team Menu"
                  >
                    <FontAwesomeIcon icon="bars" />
                  </MenuButton>
                )}
              </div>
            ))}
        </div>
      </LightScrollbar>
    </div>
  );
};

ChatParticipants.propTypes = {
  history: historyType,
  thread: PropTypes.any,
  closeSidebar: PropTypes.func,
  openUnitInformation: PropTypes.func,
  createChatWithUser: PropTypes.func,
  createChatWithTeam: PropTypes.func,
};
