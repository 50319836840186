/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { configureJWT } from '../api/global';
import { EventManager } from '../components/common/EventManager';
import { verifyJWT } from '../utils/auth';
import { useLocalStorage } from '../utils/localStorage';
import { cancellablePromise } from '../utils/promise';

const DEVICE_LARGE_MIN_HEIGHT = 1000;
const DEVICE_LARGE_MIN_WIDTH = 1280;
const DEVICE_MEDIUM_MIN_WIDTH = 840;

/**
 * User state.
 *
 * @param {any} user user state
 * @param {Function} setUser set user state
 */
export const UserContext = createContext({
  user: {},
  setUser: (user) => {},
  deviceSize: '',
  devicePixelRatio: 1,
});

/**
 * User provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useLocalStorage('user', null);
  const [deviceSize, setDeviceSize] = useState(
    window.innerWidth <= DEVICE_MEDIUM_MIN_WIDTH
      ? 'small'
      : window.innerHeight <= DEVICE_LARGE_MIN_HEIGHT ||
        window.innerWidth <= DEVICE_LARGE_MIN_WIDTH
      ? 'medium'
      : 'large'
  );
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const contextValue = {
    user,
    setUser,
    deviceSize,
    devicePixelRatio,
  };

  const resize = (e) => {
    const height = window.innerHeight;
    const width = window.innerWidth;

    if (width <= DEVICE_MEDIUM_MIN_WIDTH) {
      setDeviceSize('small');
    } else if (
      height <= DEVICE_LARGE_MIN_HEIGHT ||
      width <= DEVICE_LARGE_MIN_WIDTH
    ) {
      setDeviceSize('medium');
    } else {
      setDeviceSize('large');
    }
    setDevicePixelRatio(window.devicePixelRatio);
  };

  useEffect(() => {
    const authenticate = async () => {
      await verifyUserAsync(setUser);
    };

    const { promise, cancel } = cancellablePromise(authenticate());
    promise
      .then(() => {})
      .catch((e) => {})
      .finally(() => setLoading(false));

    return () => {
      cancel();
    };
    // eslint-disable-next-line
  }, []);

  return (
    !loading && (
      <UserContext.Provider value={contextValue}>
        <EventManager element={window} event="resize" handler={resize} />
        <EventManager
          element={window}
          event="orientationchange"
          handler={resize}
        />
        {children}
      </UserContext.Provider>
    )
  );
};

UserProvider.propTypes = {
  children: PropTypes.any,
};

// Uses verfifyJWT() to determine if a user posesses an active jwt token
// If yes then, sets the user context, else prevents the user from accessing certain pages.p-
export const verifyUserAsync = async (setUser) => {
  const res = await verifyJWT();
  if (res.status) {
    // Set user data (later to be made global)
    setUser(res.userDetails);

    // Set jwt in api config
    configureJWT(res.jwt);

    return true;
  } else if (
    res.errorDetails.errorMsg === 1 ||
    res.errorDetails.errorMsg === 2
  ) {
    // Requires another login, jwt found but failed or not found
    setUser(null);
    return false;
  } else {
    setUser(null);
    // Unknown error
    log.error('Error in verfication:', res.errorDetails.errorMsg);
    return false;
  }
};

/**
 * Helper hook for consuming user context.
 *
 * @returns UserContext
 */
export const useUser = () => {
  return useContext(UserContext);
};
