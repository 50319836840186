/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Constants, fixPolygon } from './draw_utils';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';

const ConcaveSelect = MapboxDraw.modes.direct_select;

function createVertex(parentId, coordinates, path, selected) {
  return {
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      meta: Constants.meta.VERTEX,
      parent: parentId,
      coord_path: path,
      active: selected
        ? Constants.activeStates.ACTIVE
        : Constants.activeStates.INACTIVE,
    },
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates,
    },
  };
}

function createMidpoint(parent, startVertex, endVertex) {
  const startCoord = startVertex.geometry.coordinates;
  const endCoord = endVertex.geometry.coordinates;

  // If a coordinate exceeds the projection, we can't calculate a midpoint,
  // so run away
  if (
    startCoord[1] > Constants.LAT_RENDERED_MAX ||
    startCoord[1] < Constants.LAT_RENDERED_MIN ||
    endCoord[1] > Constants.LAT_RENDERED_MAX ||
    endCoord[1] < Constants.LAT_RENDERED_MIN
  ) {
    return null;
  }

  const mid = {
    lng: (startCoord[0] + endCoord[0]) / 2,
    lat: (startCoord[1] + endCoord[1]) / 2,
  };

  return {
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      meta: Constants.meta.MIDPOINT,
      parent,
      lng: mid.lng,
      lat: mid.lat,
      coord_path: endVertex.properties.coord_path,
    },
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: [mid.lng, mid.lat],
    },
  };
}

/* eslint-disable no-unused-vars */
function createSupplementaryPoints(geojson, options = {}, basePath = null) {
  const { type, coordinates } = geojson.geometry;
  const featureId = geojson.properties && geojson.properties.id;

  let supplementaryPoints = [];

  if (type === Constants.geojsonTypes.POINT) {
    // For points, just create a vertex
    supplementaryPoints.push(
      createVertex(featureId, coordinates, basePath, isSelectedPath(basePath))
    );
  } else if (type === Constants.geojsonTypes.POLYGON) {
    // Cycle through a Polygon's rings and
    // process each line
    coordinates.forEach((line, lineIndex) => {
      processLine(
        line,
        basePath !== null ? `${basePath}.${lineIndex}` : String(lineIndex)
      );
    });
  } else if (type === Constants.geojsonTypes.LINE_STRING) {
    processLine(coordinates, basePath);
  } else if (type.indexOf(Constants.geojsonTypes.MULTI_PREFIX) === 0) {
    processMultiGeometry();
  }

  function processLine(line, lineBasePath) {
    let firstPointString = '';
    let lastVertex = null;
    line.forEach((point, pointIndex) => {
      const pointPath =
        lineBasePath !== undefined && lineBasePath !== null
          ? `${lineBasePath}.${pointIndex}`
          : String(pointIndex);
      const vertex = createVertex(
        featureId,
        point,
        pointPath,
        isSelectedPath(pointPath)
      );

      // If we're creating midpoints, check if there was a
      // vertex before this one. If so, add a midpoint
      // between that vertex and this one.
      if (options.midpoints && lastVertex) {
        const midpoint = createMidpoint(featureId, lastVertex, vertex);
        if (midpoint) {
          supplementaryPoints.push(midpoint);
        }
      }
      lastVertex = vertex;

      // A Polygon line's last point is the same as the first point. If we're on the last
      // point, we want to draw a midpoint before it but not another vertex on it
      // (since we already a vertex there, from the first point).
      const stringifiedPoint = JSON.stringify(point);
      if (firstPointString !== stringifiedPoint) {
        supplementaryPoints.push(vertex);
      }
      if (pointIndex === 0) {
        firstPointString = stringifiedPoint;
      }
    });
  }

  function isSelectedPath(path) {
    if (!options.selectedPaths) return false;
    return options.selectedPaths.indexOf(path) !== -1;
  }

  // Split a multi-geometry into constituent
  // geometries, and accumulate the supplementary points
  // for each of those constituents
  function processMultiGeometry() {
    const subType = type.replace(Constants.geojsonTypes.MULTI_PREFIX, '');
    coordinates.forEach((subCoordinates, index) => {
      const subFeature = {
        type: Constants.geojsonTypes.FEATURE,
        properties: geojson.properties,
        geometry: {
          type: subType,
          coordinates: subCoordinates,
        },
      };
      supplementaryPoints = supplementaryPoints.concat(
        createSupplementaryPoints(subFeature, options, index)
      );
    });
  }

  return supplementaryPoints;
}
/* eslint-enable no-unused-vars */

ConcaveSelect.onMouseOut = function (state) {
  // As soon as you mouse leaves the canvas, update the feature
  if (state.dragMoving) {
    fixPolygon(state.feature, true);

    this.fireUpdate();
  }

  // Skip render
  return true;
};

ConcaveSelect.onTouchEnd = ConcaveSelect.onMouseUp = function (state) {
  if (state.dragMoving) {
    fixPolygon(state.feature, true);

    this.fireUpdate();
  }
  this.stopDragging(state);
};

ConcaveSelect.onTrash = function (state) {
  // Uses number-aware sorting to make sure '9' < '10'. Comparison is reversed because we want them
  // in reverse order so that we can remove by index safely.
  state.selectedCoordPaths
    .sort((a, b) => b.localeCompare(a, 'en', { numeric: true }))
    .forEach((id) => state.feature.removeCoordinate(id));
  if (state.feature.isValid()) {
    fixPolygon(state.feature, true);
  }
  this.fireUpdate();
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  this.fireActionable(state);
  if (state.feature.isValid() === false) {
    this.deleteFeature([state.featureId]);
    this.changeMode(Constants.modes.SIMPLE_SELECT, {});
  }
};

ConcaveSelect.onSetup = function (opts) {
  const featureId = opts.featureId;
  const feature = this.getFeature(featureId);

  if (!feature) {
    throw new Error('You must provide a featureId to enter direct_select mode');
  }

  if (feature.type === Constants.geojsonTypes.POINT) {
    throw new TypeError("direct_select mode doesn't handle point features");
  }

  const state = {
    featureId,
    feature,
    dragMoveLocation: opts.startPos || null,
    dragMoving: false,
    canDragMove: false,
    selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
    history: opts.history?.length
      ? [...opts.history]
      : [feature.getCoordinates()],
  };

  this.setSelectedCoordinates(
    this.pathsToCoordinates(featureId, state.selectedCoordPaths)
  );
  this.setSelected(featureId);
  doubleClickZoom.disable(this);

  this.setActionableState({
    trash: true,
  });

  return state;
};

ConcaveSelect.fireUpdate = function () {
  // this fires on every update, so push new coords to history
  this._ctx.api.combineFeatures();
  console.log('fireUpdate');

  this.map.fire(Constants.events.UPDATE, {
    action: Constants.updateActions.CHANGE_COORDINATES,
    features: this.getSelected().map((f) => f.toGeoJSON()),
  });
};

// unused in this draw mode so use to save
// this is triggered internally in fireUpdate
ConcaveSelect.onCombineFeatures = function (state) {
  // console.log('saving...');
  state.history.push(state.feature.getCoordinates());
};

// unused in this draw mode so use to recover
ConcaveSelect.onUncombineFeatures = function (state) {
  // if we have history
  if (state.history?.length && state.history?.length - 2 >= 0) {
    // console.log('recovering...', state.history);
    state.feature.setCoordinates(state.history[state.history?.length - 2]);

    // change mode to reset the drawing - pass existing history to new instance of drawing mode
    this.changeMode(Constants.modes.DIRECT_SELECT, {
      featureId: state.feature.id,
      history: state.history.slice(0, state.history?.length - 1),
    });
  } else {
    // no history
    // eslint-disable-next-line
    throw 'no history';
  }
};

export default ConcaveSelect;
