/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DotButton } from '../common/buttons/DotButton';
import moment from 'moment';
import { Avatar } from '../common/Avatar';

/**
 * SitrepPreview - Preview of a sitrep form
 *
 */
export const SitrepPreview = ({
  form,
  handleViewDetails,
  handleScrollToTop,
  users,
}) => {
  const [hidePanel, setHidePanel] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [fromTime, setFromTime] = useState();
  const [toDate, setToDate] = useState();
  const [toTime, setToTime] = useState();
  const [fromDtg, setFromDtg] = useState();
  const [toDtg, setToDtg] = useState();
  const [utcFromDate, setUtcFromDate] = useState(form.content.fromDtg);
  const [utcToDate, setUtcToDate] = useState(form.content.toDtg);

  useEffect(() => {
    if (utcFromDate) {
      let _fromDate = moment(utcFromDate)._d;
      let _fromTime = moment(utcFromDate).format('hh:mm');
      setFromDate(_fromDate);
      setFromTime(_fromTime);
    }
    if (utcToDate) {
      let _toDate = moment(utcToDate)._d;
      let _toTime = moment(utcToDate).format('hh:mm');
      setToDate(_toDate);
      setToTime(_toTime);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fromDate && fromTime) {
      let _utcFromDate = new Date(moment(fromDate, 'YYYY-MM-DDTHH:mm').utc());
      _utcFromDate.setHours(fromTime.substring(0, 2));
      _utcFromDate.setMinutes(fromTime.substring(3, 5));
      setUtcFromDate(_utcFromDate);
      let _fromDtg = moment(fromDate).format('DD HHmm MMMYY');
      _fromDtg = _fromDtg.replaceAt(
        3,
        fromTime.substring(0, 2) + fromTime.substring(3, 5)
      );
      _fromDtg =
        _fromDtg.slice(0, 7) + 'Z ' + _fromDtg.slice(8, _fromDtg.length);
      setFromDtg(_fromDtg.toUpperCase());
    }
    if (toDate && toTime) {
      let _utcToDate = new Date(moment(toDate, 'YYYY-MM-DDTHH:mm').utc());
      _utcToDate.setHours(toTime.substring(0, 2));
      _utcToDate.setMinutes(toTime.substring(3, 5));
      setUtcToDate(_utcToDate);
      let _toDtg = moment(toDate).format('DD HHmm MMMYY');
      _toDtg = _toDtg.replaceAt(
        3,
        toTime.substring(0, 2) + toTime.substring(3, 5)
      );
      _toDtg = _toDtg.slice(0, 7) + 'Z ' + _toDtg.slice(8, _toDtg.length);
      setToDtg(_toDtg.toUpperCase());
    }
  }, [fromDate, fromTime, toDate, toTime]);

  return (
    <div id={form.id} className="form-preview">
      <div className="form-panel">
        <div className="panel-header">
          <div>Serial and Destination</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              const hidePanelCopy = [...hidePanel];
              hidePanelCopy[0] = !hidePanel[0];
              setHidePanel(hidePanelCopy);
            }}
          >
            <FontAwesomeIcon
              icon={!hidePanel[0] ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        {!hidePanel[0] && (
          <div className="panel-content">
            <div className="row">
              <div className="label-left">
                <div className="label">Serial:</div>
                <div className="value" style={{ fontWeight: '600' }}>
                  {form.serial.toUpperCase()}
                </div>
              </div>
              <div className="label-left">
                <div className="label">From:</div>
                <div className="value">
                  <div className="avatar"></div>
                  <div
                    className="avatar-container"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '5px',
                    }}
                  >
                    <Avatar
                      entity={users.find((u) => u.id === form.from)}
                      size="1.5rem"
                    ></Avatar>
                    &nbsp;
                    {users.find((u) => form.from === u.id).firstname +
                      ' ' +
                      users.find((u) => form.from === u.id).lastname}
                  </div>
                </div>
              </div>
              <div className="label-left">
                <div className="label">To:</div>
                <div className="value">
                  {!!form.to && form.to.length >= 1
                    ? form.to.map((t) => {
                        const entity = users.find((u) => u.id === t.id);
                        return (
                          <div
                            className="avatar-container"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              margin: '5px',
                            }}
                          >
                            <Avatar entity={entity} size="1.5rem"></Avatar>
                            &nbsp;
                            {entity.firstname + ' ' + entity.lastname}
                          </div>
                        );
                      })
                    : '-'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-panel">
        <div className="panel-header">
          <div>1. Period Covered (DTG to DTG)</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              const hidePanelCopy = [...hidePanel];
              hidePanelCopy[1] = !hidePanel[1];
              setHidePanel(hidePanelCopy);
            }}
          >
            <FontAwesomeIcon
              icon={!hidePanel[1] ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        {!hidePanel[1] && (
          <div className="panel-content">
            <div className="row">
              <div className="label-left">
                <div className="value">
                  {!!fromDtg ? fromDtg : 'Not Selected'}
                </div>
              </div>
              <div className="ref">TO</div>
              <div className="label-left">
                <div className="value">{!!toDtg ? toDtg : 'Not Selected'}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-panel">
        <div className="panel-header">
          <div>2. Threat</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              const hidePanelCopy = [...hidePanel];
              hidePanelCopy[2] = !hidePanel[2];
              setHidePanel(hidePanelCopy);
            }}
          >
            <FontAwesomeIcon
              icon={!hidePanel[2] ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        {!hidePanel[2] && (
          <div className="panel-content">
            <div className="row">
              <div className="ref">A.</div>
              <div className="label-top">
                <div className="label">Identity (Threat / Element)</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.threatIdentity}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ref">B.</div>
              <div className="label-top">
                <div className="label">Summary of Threat Activity</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.threatSummary}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ref">C.</div>
              <div className="label-top">
                <div className="label">
                  Threat Location, Strength and Combat Effectiveness
                </div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.threatLocation}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ref">D.</div>
              <div className="label-top">
                <div className="label"> Conclusions of Threat COA</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.threatConclusion}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-panel">
        <div className="panel-header">
          <div>3. Own Situation</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              const hidePanelCopy = [...hidePanel];
              hidePanelCopy[3] = !hidePanel[3];
              setHidePanel(hidePanelCopy);
            }}
          >
            <FontAwesomeIcon
              icon={!hidePanel[3] ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        {!hidePanel[3] && (
          <div className="panel-content">
            <div className="row">
              <div className="ref">A.</div>
              <div className="label-top" style={{ width: '600px' }}>
                <div className="label">Location of Forward Elements</div>
                {!!form.content.forwardElementsLocation.length ? (
                  form.content.forwardElementsLocation?.map((fl) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div className="value">{fl.description}</div>
                      <div style={{ fontWeight: '600', margin: '10px' }}>
                        at
                      </div>
                      <div className="value" style={{ width: '200px' }}>
                        {fl.latitude + ', ' + fl.longitude}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div className="value"></div>
                    <div style={{ fontWeight: '600', margin: '10px' }}>at</div>
                    <div className="value" style={{ width: '200px' }}></div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="ref">B.</div>
              <div className="label-top" style={{ width: '600px' }}>
                <div className="label">Location of Units and HQ</div>
                {!!form.content.unitsLocation.length ? (
                  form.content.unitsLocation?.map((ul) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div className="value">{ul.description}</div>
                      <div style={{ fontWeight: '600', margin: '10px' }}>
                        at
                      </div>
                      <div className="value" style={{ width: '200px' }}>
                        {ul.latitude + ', ' + ul.longitude}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div className="value"></div>
                    <div style={{ fontWeight: '600', margin: '10px' }}>at</div>
                    <div className="value" style={{ width: '200px' }}></div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="ref">C.</div>
              <div className="label-top" style={{ width: '600px' }}>
                <div className="label">Location of Adjacent Units</div>
                {!!form.content.adjacentUnitsLocation.length ? (
                  form.content.adjacentUnitsLocation?.map((al) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div className="value">{al.description}</div>
                      <div style={{ fontWeight: '600', margin: '10px' }}>
                        at
                      </div>
                      <div className="value" style={{ width: '200px' }}>
                        {al.latitude + ', ' + al.longitude}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div className="value"></div>
                    <div style={{ fontWeight: '600', margin: '10px' }}>at</div>
                    <div className="value" style={{ width: '200px' }}></div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="ref">D.</div>
              <div className="label-top">
                <div className="label">
                  Brief Description and Results of Ops
                </div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.description}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ref">E.</div>
              <div className="label-top">
                <div className="label">Current Activity</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.currentActivity}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-panel">
        <div className="panel-header">
          <div>4. Civilian</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              const hidePanelCopy = [...hidePanel];
              hidePanelCopy[4] = !hidePanel[4];
              setHidePanel(hidePanelCopy);
            }}
          >
            <FontAwesomeIcon
              icon={!hidePanel[4] ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        {!hidePanel[4] && (
          <div className="panel-content">
            <div className="row">
              <div className="ref">A.</div>
              <div className="label-top">
                <div className="label">Summary of Civilian Situation in A0</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.summaryOfCivilianSituation}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ref">B.</div>
              <div className="label-top">
                <div className="label">
                  Number and Flow of Displaced Persons
                </div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.displacedPersons}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ref">C.</div>
              <div className="label-top">
                <div className="label">
                  Significant Civilian Events in past 24 hours
                </div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.significantCivilianEvents}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ref">D.</div>
              <div className="label-top">
                <div className="label">Location of Displaced Persons</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.locationOfDisplacedPersons}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-panel">
        <div className="panel-header">
          <div>5. Admin (General Statements)</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              const hidePanelCopy = [...hidePanel];
              hidePanelCopy[5] = !hidePanel[5];
              setHidePanel(hidePanelCopy);
            }}
          >
            <FontAwesomeIcon
              icon={!hidePanel[5] ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        {!hidePanel[5] && (
          <div className="panel-content">
            <div className="row">
              <div className="ref"></div>
              <div className="label-top">
                <div className="label">Admin (General Statements)</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.generalStatements}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-panel">
        <div className="panel-header">
          <div>6. General and Future Intentions</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              const hidePanelCopy = [...hidePanel];
              hidePanelCopy[6] = !hidePanel[6];
              setHidePanel(hidePanelCopy);
            }}
          >
            <FontAwesomeIcon
              icon={!hidePanel[6] ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        {!hidePanel[6] && (
          <div className="panel-content">
            <div className="row">
              <div className="ref"></div>
              <div className="label-top">
                <div className="label">General and Future Intentions</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.generalIntentions}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-panel">
        <div className="panel-header">
          <div>7. Commander's Evaluation</div>
          <div
            onClick={(e) => {
              e.preventDefault();
              const hidePanelCopy = [...hidePanel];
              hidePanelCopy[7] = !hidePanel[7];
              setHidePanel(hidePanelCopy);
            }}
          >
            <FontAwesomeIcon
              icon={!hidePanel[7] ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
        {!hidePanel[7] && (
          <div className="panel-content">
            <div className="row">
              <div className="ref"></div>
              <div className="label-top">
                <div className="label">Commander's Evaluation</div>
                <div className="value" style={{ width: '600px' }}>
                  {form.content.commandersEvaluation}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="form-panel">
        <div className="panel-content">
          <div className="row">
            <div style={{ width: '160px' }}>
              <DotButton
                className="secondary block"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleScrollToTop(form.id);
                }}
              >
                BACK TO TOP
              </DotButton>
            </div>
            <div style={{ width: '160px', marginLeft: '20px' }}>
              <DotButton
                className="secondary block"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleViewDetails(form.id);
                }}
              >
                CLOSE
              </DotButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SitrepPreview.propTypes = {
  form: PropTypes.any,
  handleViewDetails: PropTypes.func,
  handleScrollToTop: PropTypes.func,
  users: PropTypes.any,
};
