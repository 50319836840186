/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { ImageType } from '../enums/image';
import { except, only } from '../utils';
import { getThumbUrl } from '../utils/string';
import { del, get, post, put } from './global';
import { postImage } from './other';

export /** @type {string} Default breadcrumb until we have wider API support */
const DEFAULT_CRUMB = '';

/**
 * Get currently logged in user
 *
 * @return {any} user
 */
export const getUser = async () => {
  const user = await get(`/users`);
  if (user) {
    user.breadcrumb = DEFAULT_CRUMB;
  }
  return user;
};

/**
 * Get data for mission personnel
 *
 * @param {string} missionId mission id
 * @param {string} userId personnel id
 * @return {any} API response
 */
export const getMissionPersonnel = async (missionId, userId) => {
  return await get(`/missions/${missionId}/users/${userId}`);
};

/**
 * Log in
 *
 * @param {string} email email
 * @param {string} password password
 * @return {any} API response
 */
export const postLogin = async (email, password) => {
  return await post(`/users/login`, { email, password });
};

/**
 * Create or update user
 *
 * @param {any} userData user data
 * @return {any} API response
 */
export const postUser = async (userId, userData) => {
  if (userData.blob?.size) {
    const rs = await postImage(ImageType.User, userData.blob);
    userData.photoUrl = rs.url;
    userData.meta = rs.meta;
    delete userData.blob;
  }
  if (userData.deleteImage) {
    userData.photoUrl = null;
    userData.meta = null;
  }
  if (userId) {
    return await post(`/users/${userId}`, userData);
  } else {
    return await post(`/users/create`, userData);
  }
};

/**
 * Update current user
 *
 * @param {any} userData user data
 * @return {any} API response
 */
export const postCurrentUser = async (userData) => {
  if (userData.blob?.size) {
    const rs = await postImage(ImageType.User, userData.blob);
    userData.photoUrl = rs.url;
    userData.meta = rs.meta;
    delete userData.blob;
  }
  if (userData.deleteImage) {
    userData.photoUrl = null;
    userData.meta = null;
  }
  return await post(`/users`, userData);
};

/**
 * Forgot password
 *
 * @param {string} email email address
 * @return {any} API response
 */
export const postForgotPassword = async (email) => {
  return await post(`/users/forgotpassword`, { email });
};

/**
 * Delete user
 *
 * @param {string} userId invitation id
 * @return {any} API response
 */
export const deleteUser = async (userId) => {
  return await del(`/users/${userId}`);
};

/**
 * Validate invitation
 *
 * @param {string} id invitation id
 * @return {any} API response
 */
export const getInvitationValidation = async (id) => {
  return await get(`/users/validateinvitation/${id}`);
};

/**
 * Accept invitation
 *
 * @param {string} id invitation id
 * @param {any} userData user initialisation data
 * @return {any} API response
 */
export const postAcceptInvitation = async (id, userData) => {
  return await post(`/users/acceptinvitation/${id}`, userData);
};

/**
 * Reset password invitation
 *
 * @param {string} resetPasswordCode reset id
 * @param {string} password user initialisation data
 * @return {any} API response
 */
export const postResetPassword = async (resetPasswordCode, password) => {
  return await post(`/users/resetpassword`, {
    resetPasswordCode,
    password,
  });
};

/**
 * Resend invitation
 *
 * @param {string} id invitation id
 * @return {any} API response
 */
export const postResendInvitation = async (id) => {
  return await post(`/users/resendinvitation/${id}`);
};

/**
 * List all organisations the current user is permitted to view.
 *
 * @return {any} API response
 */
export async function getOrganisations() {
  return await get(`/organisations`);
}

/**
 * List all users for an organisation the user is permitted to view.
 *
 * @param {string} id organisation id
 * @return {any} API response
 */
export const getOrgUsers = async (id) => {
  const users = await get(`/organisations/${id}/users`);
  const org = await get(`/organisations/${id}`);
  if (users) {
    users.forEach((u) => {
      u.breadcrumb = DEFAULT_CRUMB;
      u.thumb = getThumbUrl(u.photoUrl);
      u.organisation = { ...org };
    });
  }
  return users;
};

/**
 * Get single organisation
 *
 * @param {string} id organisation id
 * @return {any} API response
 */
export const getOrgSingle = async (id) => {
  return await get(`/organisations/${id}`);
};

/**
 * Post organisation
 *
 * @param {string} id organisation id
 * @param {any} orgData organisation data
 * @return {any} API response
 */
export const postOrganisation = async (id, orgData) => {
  const rq = only(orgData, [
    'organisationName',
    'organisationDescription',
    'address',
    'photoUrl',
    'blob',
    'deleteImage',
    'color',
    'privacy',
    // todo: desktop-sharing 'allowDesktopSharing',
  ]);

  if (rq.blob?.size) {
    const rs = await postImage(ImageType.Organisation, rq.blob);
    rq.photoUrl = rs.url;
    rq.meta = rs.meta;
  }
  if (rq.deleteImage) {
    rq.photoUrl = null;
    rq.meta = null;
  }
  if (!rq.photoUrl) {
    rq.photoUrl = '';
  }
  if (!rq.color) {
    rq.color = null;
  }
  if (id) {
    return await post(`/organisations/${id}`, rq);
  } else {
    return await post(`/organisations`, rq);
  }
};

/**
 * Delete organisation
 *
 * @param {string} id organisation id
 * @return {any} API response
 */
export const deleteOrganisation = async (id) => {
  return await del(`/organisations/${id}`);
};

/**
 * Get organisation groups/units
 *
 * @param {string} orgId organisation id
 * @return {any} API response
 * @deprecated
 */
export const getGroups = async (orgId) => {
  const groups = await get(`/organisations/${orgId}/groups`);
  if (groups) {
    groups.forEach((x) => (x.breadcrumb = DEFAULT_CRUMB));
  }
  return groups;
};

/**
 * Get organisation group/unit
 *
 * @param {string} orgId organisation id
 * @param {string} groupId organisation id
 * @return {any} API response
 * @deprecated
 */
export const getGroupSingle = async (orgId, groupId) => {
  const group = (await get(`/organisations/${orgId}/groups`)).find(
    (g) => g.id === groupId
  );
  if (group) {
    group.breadcrumb = DEFAULT_CRUMB;
  }
  return group;
};

/**
 * Get parent of organisation group/unit
 *
 * @param {string} orgId organisation id
 * @param {string} groupId organisation id
 * @return {any} API response
 * @deprecated
 */
export const getParentGroups = async (orgId, groupId) => {
  const groups = await get(
    `/organisations/${orgId}/groups/${groupId}/parentgroups`
  );
  if (groups) {
    groups.forEach((x) => (x.breadcrumb = DEFAULT_CRUMB));
  }
  return groups;
};

/**
 * Get users/personnel of organisation group/unit
 *
 * @param {string} orgId organisation id
 * @param {string} groupId organisation id
 * @return {any} API response
 * @deprecated
 */
export const getGroupUsers = async (orgId, groupId) => {
  const users = await get(`/organisations/${orgId}/groups/${groupId}/users`);

  users.forEach((u) => {
    u.breadcrumb = DEFAULT_CRUMB;
  });

  return users;
};

/**
 * Get child groups/units of organisation group/unit
 *
 * @param {string} orgId organisation id
 * @param {string} groupId organisation id
 * @return {any} API response
 * @deprecated
 */
export const getChildGroups = async (orgId, groupId) => {
  const groups = await get(
    `/organisations/${orgId}/groups/${groupId}/childgroups`
  );
  if (groups) {
    groups.forEach((x) => (x.breadcrumb = DEFAULT_CRUMB));
  }
  return groups;
};

/**
 * Post unit/group
 *
 * @param {string} orgId organisation id
 * @param {string} groupId organisation id
 * @param {any} groupData organisation id
 * @return {any} API response
 * @deprecated
 */
export const postGroup = async (orgId, groupId, groupData) => {
  if (groupData.blob?.size) {
    const rs = await postImage(ImageType.OrganisationGroup, groupData.blob);
    groupData.photoUrl = rs.url;
    groupData.meta = rs.meta;
  }
  if (groupData.deleteImage) {
    groupData.photoUrl = null;
    groupData.meta = null;
  }
  if (!groupData.color) {
    groupData.color = null;
  }
  if (groupId) {
    return await post(`/organisations/${orgId}/groups/${groupId}`, groupData);
  } else {
    return await post(`/organisations/${orgId}/groups`, groupData);
  }
};

/**
 * Delete unit/group
 *
 * @param {string} orgId organisation id
 * @param {string} groupId organisation id
 * @return {any} API response
 * @deprecated
 */
export const deleteGroup = async (orgId, groupId) => {
  return await del(`/organisations/${orgId}/groups/${groupId}`);
};

/**
 * Get datasharing options for an organisation
 *
 * @param {string} orgId organisation id
 * @return {any} API response
 * @deprecated
 */
export const getOrgDataSharing = async (orgId) => {
  return await get(`/organisations/${orgId}/datasharing`);
};

/**
 * Update datasharing options for an organisation
 *
 * @param {string} orgId organisation id
 * @param {any} sharing sharing options
 * @return {any} API response
 * @deprecated
 */
export const postOrgDataSharing = async (orgId, sharing) => {
  return await post(
    `/organisations/${orgId}/datasharing`,
    except(sharing, ['id', 'organisationId'])
  );
};

/**
 * Get org integrations
 *
 * @return {any} API response
 */
export const getOrgIntegrations = async (orgId) => {
  return await get(`/organisations/${orgId}/integrations`);
};

/**
 * Save org integration
 *
 * @return {any} API response
 */
export const saveOrgIntegration = async (
  id,
  code,
  organisationId,
  configurationItems
) => {
  if (id) {
    return await post(`/integrations/${id}`, {
      code,
      organisationId,
      configurationItems,
    });
  } else {
    return await put(`/integrations`, {
      code,
      organisationId,
      configurationItems,
    });
  }
};

/**
 * Delete org integration
 *
 * @return {any} API response
 */
export const deleteOrgIntegration = async (id) => {
  return await del(`/integrations/${id}`);
};

/**
 * Get integration options
 *
 * @param {string} intId integration id
 * @return {any} API response
 */
export const getIntegrationOptions = async (intId) => {
  return await get(`/integrations/${intId}/options`);
};
