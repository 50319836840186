/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';

/**
 * SymbolPreview
 *
 * @param {any} symbolSvg svg symbol to display
 */
export const SymbolPreview = ({ symbolSvg }) => {
  const [symbol, setSymbol] = useState(symbolSvg);

  useEffect(() => {
    setSymbol(symbolSvg);
  }, [symbolSvg]);

  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          overflowX: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <img
            key={symbol}
            src={symbol}
            style={{ padding: '10px' }}
            alt={'Symbol'}
          />
        </div>
      </div>
      <hr />
    </>
  );
};

SymbolPreview.propTypes = {
  symbolSvg: PropTypes.any,
};
