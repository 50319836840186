/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// External
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getOrgIntegrations } from '../../../../api/orgs_users';
import { SIDEBARS, useSidebar } from '../../../../context/SidebarContext';
import {
  historyType,
  organisationType,
  sharingType,
} from '../../../../enums/propTypes';
import { cancellablePromise } from '../../../../utils/promise';
import { Avatar } from '../../../common/Avatar';
import { DotButton } from '../../../common/buttons/DotButton';
import { Sidebar } from '../../../common/Sidebar';
import { CreateEditOrg } from '../../../sidebars/organisation/CreateEditOrg';
import { OrgIntegrations } from '../../../sidebars/organisation/OrgIntegrations';
// Internal
import './EditOrgContainer.scss';
import ripples from '../../../../assets/svg/ripples.svg';

/**
 * Container/page containing the editing panels
 * Each panel (EditOrgPanel) contains a functional component, which
 * renders the content contained under the title.
 *
 * @param {any} org selected org
 * @param {any} history react router
 * @param {Function} setSelectedOrg callback to set selected org
 * @param {Function} refreshOrganisations callback to refresh orgs list
 */
export const EditOrgContainer = ({
  org,
  history,
  setSelectedOrg,
  refreshOrganisations,
}) => {
  // const [sharing, setSharing] = useState(null);

  // eslint-disable-next-line
  const [integrations, setIntegrations] = useState();

  useEffect(() => {
    const promiseInt = cancellablePromise(refreshIntegrations());
    promiseInt.promise.then(() => {}).catch((e) => {});

    const promiseSharing = cancellablePromise(refreshDataSharing());
    promiseSharing.promise.then(() => {}).catch((e) => {});

    return () => {
      promiseInt.cancel();
      promiseSharing.cancel();
    };
    // eslint-disable-next-line
  }, []);

  const refreshIntegrations = async () => {
    setIntegrations(await getOrgIntegrations(org.organisationId || org.id));
  };

  const refreshDataSharing = async () => {
    // const s = await getOrgDataSharing(org.id);
    // setSharing(s);
  };

  return (
    <div className="org-edit">
      <EditOrgPanel
        title="Organisation Details"
        org={org}
        history={history}
        refreshOrganisations={refreshOrganisations}
        integrations={integrations}
        refreshIntegrations={refreshIntegrations}
        setSelectedOrg={setSelectedOrg}
      />
    </div>
  );
};

EditOrgContainer.propTypes = {
  history: historyType,
  org: organisationType,
  setSelectedOrg: PropTypes.func.isRequired,
  refreshOrganisations: PropTypes.func,
};

/**
 * Integration panel section
 *
 * @param {string} photoUrl selected org
 * @param {string} code react router
 * @param {string} description callback to set selected org
 */
// eslint-disable-next-line
const IntegrationSection = ({ photoUrl, code, description }) => {
  return (
    <div className="item-container">
      <div className="img">
        <Avatar photoUrl={photoUrl} fallback={[code]} />
      </div>
      <div className="text">
        {code} <span>{description}</span>
      </div>
      <div className="more">
        <FontAwesomeIcon
          icon="ellipsis-v"
          style={{ height: '16px', width: '16px' }}
        />
      </div>
    </div>
  );
};

IntegrationSection.propTypes = {
  photoUrl: PropTypes.string,
  code: PropTypes.string,
  description: PropTypes.string,
};

/**
 * Wrapper component for each panel section
 *
 * @param {string} title panel title
 * @param {any} org current org
 * @param {any} sharing sharing settings
 * @param {Function} refreshOrganisations callback to refresh orgs
 * @param {Function} refreshIntegrations callback to refresh integrations
 * @param {Function} refreshDataSharing callback to refresh integrations
 * @param {string} setSelectedOrg callback to set selected org
 * @param {string} history react router
 * @param {Function} onEdit callback when edit button click
 * @param {Boolean} disableSidebar sidebar disabled
 * @param {Jsx} children child panel
 */
const EditOrgPanel = ({
  title,
  org,
  sharing,
  refreshOrganisations,
  refreshIntegrations,
  refreshDataSharing,
  setSelectedOrg,
  history,
  onEdit,
  disableSidebar,
  integrations,
  children,
}) => {
  const sidebars = useSidebar();

  const [selectedIntegration, setSelectedIntegration] = useState();

  console.log('org', org);

  return (
    <>
      <div className="org-edit-panel">
        <div className="header">
          <div className="banner">
            <img src={ripples} alt="ripples" style={{ width: '100%' }} />
          </div>
          <Avatar entity={org} size="125px" />
          <DotButton
            className="secondary"
            onClick={() => {
              if (!disableSidebar) {
                if (!sidebars.isOpen(SIDEBARS.editOrg)) {
                  sidebars.closeAll();
                  sidebars.open(SIDEBARS.editOrg);
                } else {
                  sidebars.close(SIDEBARS.editOrg);
                }
              }

              if (onEdit) {
                onEdit();
              }
            }}
          >
            {'Edit'}
            <FontAwesomeIcon
              icon="pen"
              style={{ height: '12px', width: '12px', marginLeft: '5px' }}
            />
          </DotButton>
        </div>
        <div className="content">
          <h2>{org?.organisationName}</h2>
          <div className="location">
            <FontAwesomeIcon icon="map-marker-alt" />
            <span>{org?.address || 'Not Specified'}</span>
          </div>

          <hr />

          <div className="row">
            <div className="col-60">
              <h3>Overview</h3>
              <p>{org?.organisationDescription || 'Not Specified'}</p>
            </div>
            <div className="col">
              <div className="row">
                <FontAwesomeIcon icon="user" />
                <span>{org?.users || 0} Users</span>
              </div>
              {/* todo: desktop-sharing */}
              {/* {!!org?.allowDesktopSharing && (
                <div className="row">
                  <FontAwesomeIcon icon="crosshairs" />
                  <span>Desktop Location Tracking Enabled</span>
                </div>
              )} */}
              {/* <div className="row">
              <FontAwesomeIcon icon="user" />
              <span>50 Roles</span>
            </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="org-edit-integrations">
        <div className="header">
          <div className="icon">
            <FontAwesomeIcon icon="th-large" />
          </div>
          <h2>Integrations</h2>
        </div>
        <div className="content">
          <NewIntegration
            onClick={() => {
              if (!disableSidebar) {
                if (!sidebars.isOpen(SIDEBARS.orgIntegrations)) {
                  sidebars.closeAll();
                  setSelectedIntegration(null);
                  sidebars.open(SIDEBARS.orgIntegrations);
                } else {
                  sidebars.close(SIDEBARS.orgIntegrations);
                }
              }
            }}
          />

          {!!integrations?.length &&
            integrations?.map((int) => (
              <OrgIntegration
                integration={int}
                onClick={() => {
                  if (!disableSidebar) {
                    if (!sidebars.isOpen(SIDEBARS.orgIntegrations)) {
                      sidebars.closeAll();
                      setSelectedIntegration(int);
                      sidebars.open(SIDEBARS.orgIntegrations);
                    } else {
                      sidebars.close(SIDEBARS.orgIntegrations);
                    }
                  }
                }}
              />
            ))}
        </div>
      </div>

      <Sidebar id={SIDEBARS.editOrg}>
        <CreateEditOrg
          closeSidebar={() => {
            sidebars.closeAll();
            refreshOrganisations().then(() => {});
          }}
          org={org}
          setSelectedOrg={setSelectedOrg}
          history={history}
        />
      </Sidebar>
      <Sidebar id={SIDEBARS.orgIntegrations}>
        <OrgIntegrations
          organisationId={org.organisationId || org.id}
          selected={selectedIntegration}
          closeSidebar={() => {
            sidebars.closeAll();
            setSelectedIntegration(null);
            refreshIntegrations().then(() => {});
          }}
        />
      </Sidebar>
    </>
  );
};

export const NewIntegration = ({ onClick }) => {
  return (
    <div className="integration new" onClick={onClick}>
      <FontAwesomeIcon icon="plus" />
      <span>Add Integration</span>
    </div>
  );
};

export const OrgIntegration = ({ integration, onClick }) => {
  return (
    <div className="integration" onClick={onClick}>
      <div className="header">
        <div>
          <img
            className="logo"
            src={integration.integration.photoUrl}
            alt="integration icon"
          />
        </div>
        <div className="name">
          {integration.configurationItems?.find((i) => i.key === 'name')?.value}
        </div>
      </div>
      <div className="body">
        <div className="heading">{integration.integration.name}</div>
        <p className="description">{integration.integration.description}</p>
      </div>
    </div>
  );
};

EditOrgPanel.propTypes = {
  title: PropTypes.string.isRequired,
  org: organisationType.isRequired,
  sharing: sharingType,
  refreshOrganisations: PropTypes.func,
  refreshIntegrations: PropTypes.func,
  refreshDataSharing: PropTypes.func,
  setSelectedOrg: PropTypes.func,
  history: historyType,
  children: PropTypes.any,
};
