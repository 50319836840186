/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// External Components

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { DragCircleMode } from 'mapbox-gl-draw-circle';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import { SRMode, SRCenter } from 'mapbox-gl-draw-scale-rotate-mode';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import area from '@turf/area';
import bbox from '@turf/bbox';
import bearing from '@turf/bearing';
import center from '@turf/center';
import difference from '@turf/difference';
import inside from '@turf/inside';
import * as TWEEN from '@tweenjs/tween.js';
import * as log from 'loglevel';
import mapboxgl from 'mapbox-gl';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ReactDom from 'react-dom';
import toast from 'react-hot-toast';
import * as timeago from 'timeago.js';
import { v4 as uuid } from 'uuid';
import { getThreadByUsers, postThread, renameThread } from '../../../api/comms';
import {
  getMapStyles,
  integrationsInterface,
  removeIntegration,
  getMilspecFormData,
  getSymbolSet,
  getEchelonFormData,
  getIconModifiersFormData,
} from '../../../api/integrations';
import {
  addUsersToMission,
  addUsersToTeam,
  deleteAnnotation,
  deleteGeofence,
  deletePointOfInterest,
  deleteUsersFromMission,
  deleteUsersFromTeam,
  getAnnotations,
  getGeofences,
  getMissionIntegrations,
  getPointsOfInterest,
  getAllSymbols,
  getTeams,
  getUserLocations,
  postAnnotation,
  postGeofence,
  postMission,
  updateLocation,
  updateMissionStatus,
  updatePointOfInterest,
  getUserTracks,
  deleteSymbol,
  updateSymbol,
} from '../../../api/missions';
import {
  getOrgIntegrations,
  getOrgSingle,
  getOrgUsers,
} from '../../../api/orgs_users';
import { getRoles } from '../../../api/roles';
import svg3d from '../../../assets/images/general/3d.svg';
import svgMission from '../../../assets/images/general/mission.svg';
import svgMissionBroadcast from '../../../assets/svg/mission-broadcast-icon.svg';
import svgShapes from '../../../assets/svg/shapes.svg';
import svgUnion from '../../../assets/svg/union.svg';
import svgDisconnected from '../../../assets/svg/offline.svg';
import scaleCloud from '../../../assets/images/weather-icons/scale_cloud.svg';
import scaleRain from '../../../assets/images/weather-icons/scale_rain.svg';
import scaleTemp from '../../../assets/images/weather-icons/scale_temp.svg';
import scaleWind from '../../../assets/images/weather-icons/scale_wind.svg';
import formsSvg from '../../../assets/svg/formsIcon.svg';
import createFormsSvg from '../../../assets/svg/createFormsIcon.svg';
import { animationConfig } from '../../../config/animationConfig';
import { getColour, getDrawStyles } from '../../../config/drawConfig';
import { mapConfig } from '../../../config/mapConfig';
import { useComms } from '../../../context/CommsContext';
import { useEvent } from '../../../context/EventContext';
import { useJoyride } from '../../../context/JoyrideContext';
import { useLocation } from '../../../context/LocationContext';
import { useNotification } from '../../../context/NotificationContext';
import { useDarkMode } from '../../../context/DarkModeContext';
import { SIDEBARS, useSidebar } from '../../../context/SidebarContext';
import { BOTTOMBARS, useBottombar } from '../../../context/BottombarContext';
import { useUser } from '../../../context/UserContext';
import { MISSION_FIELDS } from '../../../enums/missionFields';
import { POI } from '../../../enums/poi';
import { historyType, missionType } from '../../../enums/propTypes';
import { Role } from '../../../enums/role';
import { Tours } from '../../../enums/tours';
import { FormType } from '../../../enums/forms';
import { only, splitIcon } from '../../../utils';
import { sortBy } from '../../../utils/array';
import { canDeletePoi } from '../../../utils/auth';
import { getUnreadMessages, getUnreadThread } from '../../../utils/chat';
import { getRandomColor } from '../../../utils/colour';
import ConcaveSelect from '../../../utils/draw_modes/concave_select';
import DrawConcave from '../../../utils/draw_modes/draw_concave';
import { handleError } from '../../../utils/error';
import {
  bboxPad,
  clusterMarker,
  getClusterLeavesById,
  getPixelsPerMeter,
  imageMarker,
  missionClusterMarker,
  personnelMarker,
  selfMarker,
  toArray,
  toLngLat,
  toLongitudeLatitude,
  waypointMarker,
  weatherMarker,
  searchMarker,
  symbolMarker,
} from '../../../utils/geo';
import { offEventHandler, onEventHandler } from '../../../utils/handlers';
import { getLocalStorage, useLocalStorage } from '../../../utils/localStorage';
import {
  NotificationStatus,
  NotificationType,
} from '../../../utils/notifications';
import { cancellablePromise } from '../../../utils/promise';
import {
  displayName,
  getClassNames,
  capitalize,
  latLngToMGRS,
} from '../../../utils/string';
import { secrets } from '../../../config/secrets';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { ContextMenu } from '../../common/ContextMenu';
import { EventManager } from '../../common/EventManager';
import { LightBox } from '../../common/Lightbox';
import { MenuButton } from '../../common/Menu';
import { DarkScrollbar } from '../../common/Scrollbars';
import { Sidebar } from '../../common/Sidebar';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { NewChatModal } from '../../modals/NewChatModal';
import { Weather } from '../../bottombars/weather/Weather';
import { CreateEditTeam } from '../../sidebars/mission/CreateEditTeam';
import { EditMissionIntegrations } from '../../sidebars/mission/EditMissionIntegrations';
import { MissionActiveIntegrations } from '../../sidebars/mission/MissionActiveIntegrations';
import { MissionAddedIntegrations } from '../../sidebars/mission/MissionAddedIntegrations';
import { MissionDetails } from '../../sidebars/mission/MissionDetails';
import { MissionImage } from '../../sidebars/mission/MissionImage';
import { MissionIncludedMarkers } from '../../sidebars/mission/MissionIncludedMarkers';
import { MissionIntegrations } from '../../sidebars/mission/MissionIntegrations';
import { MissionLayerSettings } from '../../sidebars/mission/MissionLayerSettings';
import { MissionPersonnel } from '../../sidebars/mission/MissionPersonnel';
import { MissionRoles } from '../../sidebars/mission/MissionRoles';
import { MissionTeams } from '../../sidebars/mission/MissionTeams';
import { MissionThread } from '../../sidebars/mission/MissionThread';
import { MissionUsers } from '../../sidebars/mission/MissionUsers';
import { MissionWaypoint } from '../../sidebars/mission/MissionWaypoint';
import { MissionSymbol } from '../../sidebars/mission/MissionSymbol';
import { ShapeDetails } from '../../sidebars/mission/ShapeDetails';
import { MissionTeamPanel } from '../create-mission/AssignedTeams';
import { MissionInfo } from './MissionInfo';
import './MissionMap.scss';
import SimpleSelect from '../../../utils/draw_modes/simple_select';
import DrawLineString from '../../../utils/draw_modes/draw_line_string';
import arrowBlack from '../../../assets/images/map-icons/arrow-#000000.png';
import { FeatureType } from '../../../enums/featureType';
import { SymbolType } from '../../../enums/symbolType';
import { AnnotationType } from '../../../enums/annotationType';
import { GeofenceLevel } from '../../../enums/geofenceLevel';
import polylabel from 'polylabel';
import { Signal } from '../../../enums/signal';
import { Bottombar } from '../../common/Bottombar';
import dayjs from 'dayjs';
import { CallState } from '../../../enums/call';
import debounce from 'lodash.debounce';
import {
  faFire,
  faArrowsUpDownLeftRight,
  faCloudRain,
  faTemperatureHalf,
  faWind,
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import badge from '../../../assets/svg/dot-badge.svg';
import { reverseGeocode } from '../../../api/mapping';
import svgM2525 from '../../../assets/svg/m2525.svg';
import { SymbolSet } from '../../../enums/symbolSet';
import ms from 'milsymbol';
import RulerControl from 'mapbox-gl-ruler-control';
import { encodeMilspecSymbolCode } from '../../../utils/milspec';
import { Sitrep } from '../../forms/Sitrep';
import { Movreq } from '../../forms/Movreq';
import { Maintdem } from '../../forms/Maintdem';
import { Contact } from '../../forms/Contact';
import { ContactDetailed } from '../../forms/ContactDetailed';
import { Casevac } from '../../forms/Casevac';
import { Forms } from './Forms';

// eslint-disable-next-line
const selfColour = '#3b9fc2';
const SINGLE_ZOOM_THRESHOLD = 1.0;
const MULTI_ZOOM_THRESHOLD = 8.0;
const LABEL_ZOOM_THRESHOLD = 14.0;
const SYMBOL_ZOOM_THRESHOLD = 16.0;
const MAX_TEAMS = 5;
const MAX_ROLES = 5;
// eslint-disable-next-line
const MAX_INTEGRATIONS = 4;
const MAX_THREADS = 5;
// 512 is size of map tile
const CLUSTER_RADIUS = 32;

/**
 * Mission view page - map component.
 *
 * @param {any[]} missions all missions to render on map
 * @param {Boolean} isMultiple is all missions map (some orgs only have one mission so can't rely on mission count)
 * @param {any} history react router
 * @param {any[]} targets personnel/chats to locate on load
 * @param {Function} updateMission refresh mission
 */
export const MissionMap = ({
  missions,
  isMultiple,
  history,
  targets,
  updateMission,
}) => {
  // contexts
  const sidebars = useSidebar();
  const bottombars = useBottombar();
  const notification = useNotification();
  const locationServices = useLocation();
  const userValue = useUser();
  const comms = useComms();

  // debugging
  const [DEBUG, setDEBUG] = useState(false);
  const [ANIMATE, setANIMATE] = useLocalStorage('ANIMATE', true);
  const [SIMULATE, setSIMULATE] = useState(false);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [triggerRefreshUsers, setTriggerRefreshUsers] = useState(false);

  // eslint-disable-next-line
  const [myOrg, setMyOrg] = useState(null);
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);
  const [roles, setRoles] = useState([]);

  const [pointsOfInterest, setPointsOfInterest] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [geofences, setGeofences] = useState([]);
  const [annotations, setAnnotations] = useState([]);

  const [waypoint, setWaypoint] = useState(null);
  const [image, setImage] = useState(null);
  const [symbol, setSymbol] = useState(null);
  const [milspec, setMilspec] = useState(null);
  const [milspecFormData, setMilspecFormData] = useState(null);

  const [formType, setFormType] = useState('');
  const [formToEdit, setFormToEdit] = useState();
  const [manageForms, setManageForms] = useState(false);

  const [includedMarkers, setIncludedMarkers] = useState([]);
  const [user, setUser] = useState(null);
  const [userTab, setUserTab] = useState('');
  const [mode, setMode] = useState(null);
  const [selectedMission, setSelectedMission] = useState(null);
  const [features, setFeatures] = useState([]);
  const [settingWeatherLocation, setSettingWeatherLocation] = useState(false);
  const [weatherLocation, setWeatherLocation] = useState();
  const [weatherLayer, setWeatherLayer] = useState(null);
  const [windDirection, setWindDirection] = useState(null);
  const [threadToEdit, setThreadToEdit] = useState(null);
  const [poiToDelete, setPoiToDelete] = useState(null);
  const [symbolToDelete, setSymbolToDelete] = useState(null);
  const [deleteMissionArea, setDeleteMissionArea] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // eslint-disable-next-line
  const [showTeams, setShowTeams] = useState(true);
  // eslint-disable-next-line
  const [showRoles, setShowRoles] = useState(true);
  const [unreadThreads, setUnreadThreads] = useState(0);
  const [showComms, setShowComms] = useState(false);
  const [currentThread, setCurrentThread] = useState(null);
  const [personnel, setPersonnel] = useState([]);
  const [offlinePersonnel, setOfflinePersonnel] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);

  const [showTeamsEditor, setShowTeamsEditor] = useState(false);
  const teamsEditor = useRef();

  // drag and drop
  const [draggingFrom, setDraggingFrom] = useState(null);

  const [loc, setLocation] = useState(null);
  const [activeThreads, setActiveThreads] = useLocalStorage(
    'active-threads',
    []
  );
  const [rolesExpanded, setRolesExpanded] = useState(false);
  const [teamsExpanded, setTeamsExpanded] = useState(false);
  // eslint-disable-next-line
  const [integrationsExpanded, setIntegrationsExpanded] = useState(false);

  const [liveTracking, setLiveTracking] = useState(null);
  const [showOnlyTeam, setShowOnlyTeam] = useState([]);
  const [showOnlyRole, setShowOnlyRole] = useState([]);
  const [styles, setStyles] = useState([]);
  const [selectedStyle, setSelectedStyle] = useLocalStorage(
    `selected-style-${missions[0].id}`,
    null
  );

  const [integrations, setIntegrations] = useLocalStorage(
    `integrations-${missions[0].id}`,
    []
  );
  const [labelZoomThreshold, setLabelZoomThreshold] = useLocalStorage(
    `label-zoom-threshold-${missions[0].id}`,
    LABEL_ZOOM_THRESHOLD
  );
  const [symbolDetailsZoomThreshold, setSymbolDetailsZoomThreshold] =
    useLocalStorage(
      `symbol-details-zoom-threshold-${missions[0].id}`,
      SYMBOL_ZOOM_THRESHOLD
    );
  const [orgIntegrations, setOrgIntegrations] = useState([]);
  const [integration, setIntegration] = useState(null);
  const [integrationOptions, setIntegrationOptions] = useState(null);
  const [mapBearing, setMapBearing] = useState(null);
  const globalDarkMode = useDarkMode();
  const joyride = useJoyride();

  //Spark demo
  const [ignitionSeconds, setIgnitionSeconds] = useState(0);
  const [ignitionDisplay, setIgnitionDisplay] = useState('00:00');
  const [windBearingDisplay, setWindbBearingDisplay] =
    useState('300° Speed 30km/h');
  const [spark, setSpark] = useState(false);
  const [hoveredStateId, setHoveredStateId] = useState(0);
  const prevHoverStateId = usePrevious(hoveredStateId);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const prevSelectedStateId = usePrevious(selectedStateId);
  const [trackData, setTrackData] = useState(null);

  //Shape icon/latest used icon
  const [shapeIcon, setShapeIcon] = useState('shapes');
  const [poiIcon, setPoiIcon] = useState('map-pin');
  const [symbolIcon, setSymbolIcon] = useState(svgM2525);

  // cache hack so events know what the state is
  // https://github.com/alex3165/react-mapbox-gl/issues/748
  const localGeofences = useRef(geofences);
  const localAnnotations = useRef(annotations);
  const localShowTeams = useRef(showTeams);
  const localShowRoles = useRef(showRoles);
  const localShowOnlyTeam = useRef(showOnlyTeam);
  const localShowOnlyRole = useRef(showOnlyRole);
  const localMissions = useRef(missions);
  const localPersonnel = useRef(personnel);
  const localOfflinePersonnel = useRef(offlinePersonnel);
  const localUser = useRef(user);
  const localTeams = useRef(teams);
  const localRoles = useRef(roles);
  const localMode = useRef(mode);
  const localShapeIcon = useRef(shapeIcon);
  const localPointsOfInterest = useRef(pointsOfInterest);
  const localSymbols = useRef(symbols);
  const localImage = useRef(image);
  const localWaypoint = useRef(waypoint);
  const localSymbol = useRef(symbol);
  const localBroadcasting = useRef(locationServices.broadcasting);
  const localLiveTracking = useRef(liveTracking);
  const localIntegrations = useRef(integrations);
  const localFeatures = useRef(features);
  const localMapLoaded = useRef(mapLoaded);
  const localAnimate = useRef(ANIMATE);
  const localDebug = useRef(DEBUG);
  const localSettingWeatherLocation = useRef(settingWeatherLocation);
  const localWeatherLocation = useRef(weatherLocation);
  const localWeatherMarker = useRef([]);
  const localWeatherLayer = useRef(weatherLayer);
  const localWindDirection = useRef(windDirection);
  const localSearchMarker = useRef([]);
  const localSearchResult = useRef(null);
  const localDarkMode = useRef(globalDarkMode.darkMode);
  const localSelectedStyle = useRef(selectedStyle);
  const localFormType = useRef(formType);
  const editingFeatures = useRef([]);

  const organisationsCache = useRef([]);

  const isPitching = useRef(false);
  const showSearch = useRef(false);

  // Div reference to construct map
  const mapContainer = useRef();
  const mapMarkers = useRef({});
  const mapMarkersOnScreen = useRef({});

  const map = useRef();
  const draw = useRef();
  const popups = useRef({});

  const paused = useRef(false);

  const animationRef = useRef();
  const trackingTimer = useRef();
  const plottingTimer = useRef();
  const touchTimer = useRef();
  const simTimer = useRef();
  const integrationsTimers = useRef([]);
  const plottingIntegrations = useRef(false);

  const teamsPanel = useRef(null);
  const rolesPanel = useRef(null);
  const drawToolsPanel = useRef(null);
  const integrationsPanel = useRef(null);

  const pixelsPerMeter = useRef(1);

  // various settings
  const [_3dView, set3dView] = useState(false);
  // eslint-disable-next-line

  const [milStdIconSize, setMilStdIconSize] = useLocalStorage(
    'milStdIconSize',
    25
  );
  const [showClustering, setShowClustering] = useLocalStorage(
    'showClustering',
    false
  );
  const [showMilStdIcon, setShowMilStdIcon] = useLocalStorage(
    'showMilStdIcon',
    false
  );
  const [showMilStdInfo, setShowMilStdInfo] = useLocalStorage(
    'showMilStdInfo',
    false
  );
  const [showMissionArea, setShowMissionArea] = useLocalStorage(
    'showMisionArea',
    true
  );
  const [showGeofences, setShowGeofences] = useLocalStorage(
    'showGeofences',
    true
  );
  const [showAnnotations, setShowAnnotations] = useLocalStorage(
    'showAnnotations',
    true
  );
  const [showGeofenceLabels, setShowGeofenceLabels] = useLocalStorage(
    'showGeofenceLabels',
    true
  );
  const [showAnnotationLabels, setShowAnnotationLabels] = useLocalStorage(
    'showAnnotationLabels',
    true
  );
  const [showPersonnelLabels, setShowPersonnelLabels] = useLocalStorage(
    'showPersonnelLabels',
    false
  );
  const [showTracks, setShowTracks] = useLocalStorage('showTracks', false);
  const [showWaypoints, setShowWaypoints] = useLocalStorage(
    'showWaypoints',
    true
  );
  const [showPhotos, setShowPhotos] = useLocalStorage('showPhotos', true);
  const [showSymbols, setShowSymbols] = useLocalStorage('showSymbols', true);
  const [showMGRS, setShowMGRS] = useLocalStorage('showMGRS', false);

  const localShowMissionArea = useRef(showMissionArea);
  const localShowGeofences = useRef(showGeofences);
  const localShowWaypoints = useRef(showWaypoints);
  const localShowPhotos = useRef(showPhotos);
  const localShowSymbols = useRef(showSymbols);
  const localShowAnnotations = useRef(showAnnotations);
  const localShowGeofenceLabels = useRef(showGeofenceLabels);
  const localShowAnnotationLabels = useRef(showAnnotationLabels);
  const localShowPersonnelLabels = useRef(showPersonnelLabels);
  const localShowTracks = useRef(showTracks);
  const localTrackData = useRef(trackData);
  const localShowMGRS = useRef(showMGRS);
  const localShowMilStdInfo = useRef(showMilStdInfo);
  const localShowMilStdIcon = useRef(showMilStdIcon);

  //needed to pass parameters to callback function
  const mouseEnterParameters = useRef();
  const mouseLeaveParameters = useRef();
  const onClickParameters = useRef();

  // event listener tracking
  const mapListeners = useRef({});

  // performance handling
  const criticalUserIds = useRef([]);
  const allUsersInATeam = useRef([]);
  const teamUsersSelected = useRef([]);

  const isOwner = !isMultiple
    ? userValue.user.organisationId === missions[0].organisationId
    : false;
  const canEdit =
    userValue.user.accessRoles.includes(Role.SysAdmin) ||
    (userValue.user.accessRoles.includes(Role.OrgAdmin) &&
      userValue.user.organisationId === missions[0].organisationId);

  const calculatePPM = () => {
    pixelsPerMeter.current = getPixelsPerMeter(
      map.current,
      document.getElementById('map')
    );
  };

  const handleSettingWeatherLocation = (isSetting) => {
    setSettingWeatherLocation(isSetting);
    localSettingWeatherLocation.current = isSetting;
  };

  const closeAllSidebars = () => {
    if (localFormType.current === '' || !!!localFormType.current) {
      setMode('');
      setUser(null);
      setImage(null);
      setWaypoint(null);
      setLocation(null);
      setSymbol(null);
      sidebars.closeAll();
    }
  };

  const _setWaypoint = (w, l, sb) => {
    setWaypoint(w);
    setLocation(l);
    sidebars.open(SIDEBARS.missionWaypoint, sb);
  };

  const _setImage = (i, l, sb) => {
    setImage(i);
    if (!sb) {
      setIncludedMarkers([]);
    }
    setLocation(l);
    sidebars.open(SIDEBARS.missionImage, sb);
  };

  const _setSymbol = (s, l, sb) => {
    setSymbol(s);
    setLocation(l);
    sidebars.open(SIDEBARS.missionSymbol, sb);
  };

  let ZOOM_THRESHOLD =
    missions?.length > 1 ? MULTI_ZOOM_THRESHOLD : SINGLE_ZOOM_THRESHOLD;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
  }

  useEffect(() => {
    localGeofences.current = geofences;
    localAnnotations.current = annotations;
    localShowTeams.current = showTeams;
    localShowRoles.current = showRoles;
    localShowOnlyTeam.current = showOnlyTeam;
    localShowOnlyRole.current = showOnlyRole;
    localUser.current = user;
    localTeams.current = teams;
    localRoles.current = roles;
    localMode.current = mode;
    localMissions.current = missions;
    localPersonnel.current = personnel;
    localPointsOfInterest.current = pointsOfInterest;
    localSymbols.current = symbols;
    localImage.current = image;
    localWaypoint.current = waypoint;
    localBroadcasting.current = locationServices.broadcasting;
    localShowPersonnelLabels.current = showPersonnelLabels;
    localShowTracks.current = showTracks;
    localIntegrations.current = integrations;
    localLiveTracking.current = liveTracking;
    localShowMissionArea.current = showMissionArea;
    localShowGeofences.current = showGeofences;
    localShowWaypoints.current = showWaypoints;
    localShowPhotos.current = showPhotos;
    localShowSymbols.current = showSymbols;
    localFeatures.current = features;
    localShowAnnotations.current = showAnnotations;
    localShowGeofenceLabels.current = showGeofenceLabels;
    localShowAnnotationLabels.current = showAnnotationLabels;
    localMapLoaded.current = mapLoaded;
    localWindDirection.current = windDirection;

    localAnimate.current = ANIMATE;
    localDebug.current = DEBUG;

    doLiveTracking();
  });

  const updateFenceAlerts = useCallback(
    (user, newLocation) => {
      const lastLocation = user?.coords;

      if (!newLocation || !lastLocation) {
        // const testLocation = newLocation || lastLocation;
        // if (testLocation) {
        //   log.debug('updateFenceAlerts', 'firstRender', testLocation);
        //   for (const gf of geofences.filter((f) => f.notifyAdmin)) {
        //     if (
        //       gf.alertOnEntry &&
        //       gf.bounds &&
        //       inside(toArray(testLocation), gf.bounds)
        //     ) {
        //       log.debug('updateFenceAlerts', 'inside', gf);
        //       notification.addNotification(
        //         gf.missionId,
        //         NotificationType.geofence,
        //         gf.alertLevel,
        //         `Entered ${gf.description}`,
        //         user,
        //         gf,
        //         null
        //       );
        //     }
        //     if (
        //       gf.alertOnExit &&
        //       gf.bounds &&
        //       !inside(toArray(testLocation), gf.bounds)
        //     ) {
        //       log.debug('updateFenceAlerts', 'outside', gf);
        //       // notification.addNotification(
        //       //   gf.missionId,
        //       //   NotificationType.geofence,
        //       //   gf.alertLevel,
        //       //   `Exited ${gf.description}`,
        //       //   user,
        //       //   gf,
        //       //   null
        //       // );
        //     }
        //   }
        // }
      } else {
        log.debug('updateFenceAlerts', 'transition', lastLocation, newLocation);

        // moved from out to in
        for (const gf of geofences.filter((f) => f.notifyAdmin)) {
          if (
            gf.alertOnEntry &&
            gf.bounds &&
            !inside(toArray(lastLocation), gf.bounds) &&
            inside(toArray(newLocation), gf.bounds)
          ) {
            notification.addNotification(
              gf.missionId,
              NotificationType.geofence,
              gf.alertLevel,
              `Entered ${gf.description}`,
              user,
              gf,
              null
            );
          }

          if (
            gf.alertOnExit &&
            gf.bounds &&
            inside(toArray(lastLocation), gf.bounds) &&
            !inside(toArray(newLocation), gf.bounds)
          ) {
            notification.addNotification(
              gf.missionId,
              NotificationType.geofence,
              gf.alertLevel,
              `Exited ${gf.description}`,
              user,
              gf,
              null
            );
          }
        }
      }
    },
    [geofences, notification]
  );

  const closeTeamsEditor = useCallback(() => {
    if (teamsEditor.current && showTeamsEditor) {
      teamsEditor.current.addEventListener('webkitAnimationEnd', () => {
        teamsEditor.current.classList.remove('close-editor');
        setShowTeamsEditor(false);
      });
      teamsEditor.current.classList.add('close-editor');
    }
  }, [showTeamsEditor]);

  const setCircleMode = () => {
    if (draw.current.getMode() !== 'drag_circle') {
      draw.current.changeMode('drag_circle');
    }
    editingFeatures.current = [{ id: 'new', properties: {} }];
    setFeatures([{ id: 'new', properties: {} }]);
  };

  const setPolygonMode = () => {
    if (draw.current.getMode() !== 'draw_polygon') {
      draw.current.changeMode('draw_polygon');
    }
    editingFeatures.current = [{ id: 'new', properties: {} }];
    setFeatures([{ id: 'new', properties: {} }]);
  };

  const setRectangleMode = () => {
    if (draw.current.getMode() !== 'draw_rectangle') {
      draw.current.changeMode('draw_rectangle');
    }
    editingFeatures.current = [{ id: 'new', properties: {} }];
    setFeatures([{ id: 'new', properties: {} }]);
  };

  const setLineMode = () => {
    if (draw.current.getMode() !== 'draw_line_string') {
      draw.current.changeMode('draw_line_string');
    }
    editingFeatures.current = [
      {
        id: 'new',
        properties: {
          annotationType: AnnotationType.Line,
        },
      },
    ];
    setFeatures([
      {
        id: 'new',
        properties: {
          annotationType: AnnotationType.Line,
        },
      },
    ]);
  };

  const setArrowMode = () => {
    if (draw.current.getMode() !== 'draw_line_string') {
      draw.current.changeMode('draw_line_string');
    }
    editingFeatures.current = [
      {
        id: 'new',
        properties: {
          annotationType: AnnotationType.Arrow,
        },
      },
    ];
    setFeatures([
      {
        id: 'new',
        properties: {
          annotationType: AnnotationType.Arrow,
        },
      },
    ]);
  };

  const resetDrawMode = useCallback(() => {
    if (localMode.current === FeatureType.Shape) {
      //setFeatures([]);
      //editingFeatures.current = [];
      if (draw.current) {
        if (localShapeIcon.current.includes('circle')) {
          setCircleMode();
        } else if (localShapeIcon.current.includes('square')) {
          setRectangleMode();
        } else if (localShapeIcon.current === 'draw-polygon') {
          setPolygonMode();
        } else if (localShapeIcon.current === 'minus') {
          setLineMode();
        } else if (localShapeIcon.current === 'long-arrow-alt-right') {
          setArrowMode();
        } else {
          if (
            !!editingFeatures.current[0]?.id &&
            editingFeatures.current[0]?.id !== 'new'
          ) {
            draw.current.changeMode('direct_select', {
              featureId: editingFeatures.current[0]?.id,
            });
          } else {
            editingFeatures.current = [];
            setFeatures([]);
            localFeatures.current = [];
          }
        }
      }
    }
    if (localMode.current === '') {
      editingFeatures.current = [];
      setFeatures([]);
      localFeatures.current = [];
    }
  }, []);

  const undoDraw = useCallback(() => {
    if (draw.current) {
      const current = draw.current.getAll();
      const drawing = current.features[current.features.length - 1];

      if (drawing.geometry.type !== 'LineString') {
        const points = drawing.geometry.coordinates[0].length - 2;

        // if we're the last point then don't undo
        if (points < 1) {
          toast.error('Nothing to undo');
          return;
        }

        if (mode === FeatureType.Mission) {
          if (!missions[0].bounds) {
            draw.current.trash();
          } else {
            try {
              // call 'undo' functions
              draw.current.uncombineFeatures();
            } catch {
              toast.error('Nothing to undo');
            }
          }
        } else {
          if (features[0]?.id === 'new') {
            draw.current.trash();
            resetDrawMode();
          } else {
            try {
              // call 'undo' functions
              draw.current.uncombineFeatures();
            } catch {
              toast.error('Nothing to undo');
            }
          }
        }
      } else {
        const points = drawing.geometry.coordinates.length - 1;
        // if we're the last point then don't undo
        if (points < 1) {
          toast.error('Nothing to undo');
          return;
        }

        if (features[0]?.id === 'new') {
          draw.current.trash();
          resetDrawMode();
        } else {
          try {
            // call 'undo' functions
            draw.current.uncombineFeatures();
          } catch {
            toast.error('Nothing to undo');
          }
        }
      }
    }
  }, [missions, mode, features, resetDrawMode]);

  const cancelDraw = useCallback(() => {
    if (draw.current) {
      editingFeatures.current = [];
      if (mode === FeatureType.Mission) {
        if (!missions[0].bounds) {
          draw.current.changeMode('simple_select');
          draw.current.changeMode('draw_polygon');
        } else {
          draw.current.changeMode('draw_polygon');
          draw.current.changeMode('simple_select');
        }
      } else if (
        mode === FeatureType.Shape &&
        (localShapeIcon.current === 'draw-polygon' ||
          localShapeIcon.current.includes('square') ||
          localShapeIcon.current.includes('circle'))
      ) {
        draw.current.changeMode('draw_polygon');
        draw.current.changeMode('simple_select');
      } else if (
        mode === FeatureType.Shape &&
        (localShapeIcon.current === 'minus' ||
          localShapeIcon.current === 'long-arrow-alt-right')
      ) {
        draw.current.changeMode('draw_line_string');
        draw.current.changeMode('simple_select');
      }
      setFeatures([]);
    }
  }, [missions, mode]);

  const cancelShape = useCallback(() => {
    setMode('');
    editingFeatures.current = [];
  }, []);

  const cancelForm = useCallback(() => {
    setFormType('');
    setFormToEdit(null);
    localFormType.current = '';
  }, []);

  const handleEditForm = (e, i) => {
    setManageForms(false);
    setFormToEdit(i);
    setFormType(FormType.SR);
    localFormType.current = FormType.SR;
    sidebars.open(SIDEBARS.form);
  };

  const addWeatherLayer = (layerName) => {
    const id = `integration-OPENWEATHER`;
    if (!map.current.getLayer(id) && !!layerName) {
      map.current.addLayer(
        {
          id,
          type: 'raster',
          source: {
            type: 'raster',
            tiles: [
              `https://tile.openweathermap.org/map/${layerName}/{z}/{x}/{y}.png?appid=b13366e2a8c302f0125e53101f280f45`,
            ],
            tileSize: 256,
          },
          minzoom: 0,
          maxzoom: 22,
        },
        'land-structure-polygon'
      );
    }
    localWeatherLayer.current = layerName;
    setWeatherLayer(layerName);
  };

  const removeWeatherLayer = () => {
    const id = `integration-OPENWEATHER`;
    if (map.current.getLayer(id)) {
      map.current.removeLayer(id);
    }
    if (map.current.getSource(id)) {
      map.current.removeSource(id);
    }
    localWeatherLayer.current = null;
    setWeatherLayer(null);
    setSettingWeatherLocation(false);
    setWeatherLocation(null);
    if (localWeatherMarker.current.length > 0) {
      for (const i of localWeatherMarker.current) {
        i.remove();
      }
    }
    const _integrations = [...integrations];
    const active = _integrations.find(
      (_i) => _i.organisationIntegration.code === `BOM_WEATHER`
    ).active;
    _integrations.find(
      (_i) => _i.organisationIntegration.code === `BOM_WEATHER`
    ).active = !active;

    bottombars.close();
  };

  const handleLayerChange = useCallback((layerName, windDirection = null) => {
    const id = `integration-OPENWEATHER`;
    if (map.current.getLayer(id)) {
      map.current.removeLayer(id);
    }
    if (map.current.getSource(id)) {
      map.current.removeSource(id);
    }
    if (!map.current.getLayer(id) && !!layerName) {
      map.current.addLayer(
        {
          id,
          type: 'raster',
          source: {
            type: 'raster',
            tiles: [
              `https://tile.openweathermap.org/map/${layerName}/{z}/{x}/{y}.png?appid=b13366e2a8c302f0125e53101f280f45`,
            ],
            tileSize: 256,
          },
          minzoom: 0,
          maxzoom: 22,
        },
        'land-structure-polygon'
      );
    }
    localWeatherLayer.current = layerName;
    setWeatherLayer(layerName);
  }, []);

  const animate = (time) => {
    TWEEN.update(time);

    const activeIntegrations = localIntegrations.current.filter(
      (i) => i.active
    );
    for (const integration of activeIntegrations) {
      const impl =
        integrationsInterface[integration.organisationIntegration.code];
      if (impl) {
        try {
          if (impl.enableAnimations && impl.animate) {
            impl.animate(
              map.current,
              integration.id,
              symbolDetailsZoomThreshold
            );
          }
        } catch {}
      }
    }

    animationRef.current = requestAnimationFrame(animate);
  };

  const easeMarkerTo = (id, dest, sourceId) => {
    if (!sourceId) {
      sourceId = id;
    }

    if (localDebug.current) {
      log.debug('easeTo', id, dest, sourceId);
    }

    // normal mode is move your location
    const marker = mapMarkers.current[id];
    if (marker) {
      // if marker isn't on screen then don't animate
      // if (!mapMarkersOnScreen.current[id]) {
      //   runAnimation = false;
      //   // marker.addTo(map.current);
      //   // mapMarkersOnScreen.current[id] = marker;
      // } else {
      //   if (marker.getElement()) {
      //     marker.getElement().style.visibility = 'visible';
      //   }
      // }

      const markerLngLat = marker.getLngLat();
      const _dest = toLngLat(dest);

      const hasMoved =
        markerLngLat.lat - _dest.lat !== 0 ||
        markerLngLat.lng - _dest.lng !== 0;

      if (localDebug.current) {
        log.debug('hasMoved', hasMoved, markerLngLat.lat - _dest.lat);
      }

      // disable animations when editing mission-area and geofence as mapbox gets flooded with draw requests
      if (
        hasMoved &&
        localAnimate.current &&
        localMode.current !== FeatureType.Mission &&
        localMode.current !== FeatureType.Shape
      ) {
        // a
        // if (map.current) {
        //   const data = map.current.getSource(sourceId)?._data;
        //   if (data) {
        //     const feature = data.features.find((f) =>
        //       id.includes(f.properties.id)
        //     );
        //     if (feature) {
        //       feature.geometry.coordinates = toArray(_dest);
        //       map.current.getSource(sourceId).setData({ ...data });
        //     }
        //   }
        // }

        if (localDebug.current) {
          log.debug('ease', markerLngLat, _dest);
        }

        // We use TWEEN because if we relied on CSS transitions then the markers will animate whenever we scale
        // the map.
        new TWEEN.Tween(markerLngLat)
          .to(_dest, animationConfig.personnelMarkerInterval)
          .onUpdate((e) => {
            // a
            // marker.setLngLat(e);

            // b
            if (map.current) {
              const data = map.current.getSource(sourceId)?._data;
              if (data) {
                const feature = data.features.find((f) =>
                  id.includes(f.properties.id)
                );
                if (feature) {
                  feature.geometry.coordinates = toArray(e);
                  map.current.getSource(sourceId).setData({ ...data });
                }
              }
            }
          })
          .start();
      } else if (hasMoved) {
        // b
        if (map.current) {
          const data = map.current.getSource(sourceId)?._data;
          if (data) {
            const feature = data.features.find((f) =>
              id.includes(f.properties.id)
            );
            if (feature) {
              feature.geometry.coordinates = toArray(_dest);
              map.current.getSource(sourceId).setData({ ...data });
            }
          }
        }
      }
    }
  };

  const removeMarkerAsync = async (id, sourceId) => {
    if (!sourceId) {
      sourceId = id;
    }

    // log.debug('remove marker', id, sourceId);

    // update the source
    const data = map.current?.getSource(sourceId)?._data;
    if (data) {
      const features = [
        ...data.features.filter((f) => !id.includes(f.properties.id)),
      ];
      map.current.getSource(sourceId).setData({ ...data, features });
    }

    // normal mode is move your location
    const newMarkers = { ...mapMarkersOnScreen.current };
    if (newMarkers[id]) {
      log.debug('deleting marker on screen', id);
      newMarkers[id].remove();
      delete newMarkers[id];
      mapMarkersOnScreen.current = { ...newMarkers };
    }
    // log.debug('new markers on screen', { ...mapMarkersOnScreen.current });

    const newAllMarkers = { ...mapMarkers.current };
    if (newAllMarkers[id]) {
      log.debug('deleting marker', id);
      delete newAllMarkers[id];
      mapMarkers.current = { ...newAllMarkers };
    }
    // log.debug('new markers', { ...mapMarkers.current });
  };

  const removeMarker = (id, sourceId) => {
    if (!sourceId) {
      sourceId = id;
    }

    // log.debug('remove marker', id, sourceId);

    // update the source
    const data = map.current.getSource(sourceId)?._data;
    if (data) {
      const features = [
        ...data.features.filter((f) => !id.includes(f.properties.id)),
      ];
      map.current.getSource(sourceId).setData({ ...data, features });
    }

    // normal mode is move your location
    const newMarkers = { ...mapMarkersOnScreen.current };
    if (newMarkers[id]) {
      log.debug('deleting marker on screen', id);
      newMarkers[id].remove();
      delete newMarkers[id];
      mapMarkersOnScreen.current = { ...newMarkers };
    }
    // log.debug('new markers on screen', { ...mapMarkersOnScreen.current });

    const newAllMarkers = { ...mapMarkers.current };
    if (newAllMarkers[id]) {
      log.debug('deleting marker', id);
      delete newAllMarkers[id];
      mapMarkers.current = { ...newAllMarkers };
    }
    // log.debug('new markers', { ...mapMarkers.current });
  };

  const getCachedOrg = async (organisationId) => {
    let org = organisationsCache.current.find(
      (o) => o.organisationId === organisationId
    );
    if (org) {
      return org;
    } else {
      org = await getOrgSingle(organisationId);
      organisationsCache.current.push(org);
      return org;
    }
  };

  const refreshPois = async (waypoint, refreshAll) => {
    if (!isMultiple) {
      if (waypoint) {
        await removeMarkerAsync(`${waypoint.id}`, `markers`);
      }
      const _pois = await getPointsOfInterest(localMissions.current[0].id);
      if (refreshAll) {
        for (const p of _pois) {
          await removeMarkerAsync(`${p.id}`, `markers`);
        }
      }
      let waypoints = showWaypoints
        ? _pois.filter((poi) => poi.type === POI.Waypoint)
        : [];
      let photos = showPhotos
        ? _pois.filter((poi) => poi.type === POI.Photo)
        : [];
      setPointsOfInterest(waypoints.concat(photos));
    }
  };

  const refreshSymbols = async (symbol, refreshAll) => {
    if (!isMultiple) {
      if (symbol) {
        await removeMarkerAsync(`${symbol.id}`, `markers`);
      }
      const _symbols = await getAllSymbols();
      if (refreshAll) {
        for (const s of _symbols) {
          await removeMarkerAsync(`${s.id}`, `markers`);
        }
      }
      setSymbols(_symbols);
    }
  };

  const symbolUpdate = async (symbol) => {
    const existingSymbol = symbols.find((s) => s.id === symbol.id);
    if (existingSymbol) {
      // Remove symbol marker if archived
      if (symbol.archived) {
        removeMarkerAsync(`${symbol.id}`, `markers`);
        let newSymbolArray = symbols.filter((s) => s.id !== symbol.id);
        localSymbols.current = newSymbolArray;
        setSymbols(newSymbolArray);
      } else {
        // Refresh symbol marker
        await removeMarkerAsync(`${symbol.id}`, `markers`);
        let newSymbolArray = symbols.filter((s) => s.id !== symbol.id);
        newSymbolArray.push(symbol);
        localSymbols.current = newSymbolArray;
        setSymbols(newSymbolArray);
      }
    } else {
      // Add new symbol marker
      let newSymbolArray = symbols.filter((s) => s.id !== symbol.id);
      newSymbolArray.push(symbol);
      localSymbols.current = newSymbolArray;
      setSymbols(newSymbolArray);
    }
  };

  const loadUsers = async () => {
    const userLocations = await getUserLocations(localMissions.current[0].id);
    setPersonnel(userLocations);
    localPersonnel.current = userLocations;
    const offlineUsers = userLocations.filter((u) => !u.location || u.offline);
    setOfflinePersonnel(offlineUsers);
    localOfflinePersonnel.current = offlineUsers;
  };

  const loadRoles = async () => {
    const _roles = [];
    _roles.push({ id: 'unassigned', role: 'No Role', color: selfColour });
    _roles.push({ id: 'guest', role: 'Guest', color: 'black' });
    for (const mission of missions) {
      const missionRoles = await getRoles(mission.organisationId).catch((e) => {
        log.error(e);
      });
      if (missionRoles?.length) {
        missionRoles.forEach((r) => {
          if (!_roles.find((t) => t.id === r.id)) {
            r.color = r.color || getRandomColor();
            _roles.push(r);
          }
        });
      }
    }
    setRoles(_roles);
  };

  const loadTeams = async () => {
    const _teams = new Set();
    _teams.add({ id: 'unassigned', team: 'No Team', color: selfColour });
    for (const mission of missions) {
      const missionTeams = await getTeams(mission.id).catch((e) => {
        log.error(e);
      });
      if (missionTeams?.length) {
        missionTeams.forEach((t) => _teams.add(t));
      }
    }
    _teams.forEach((a) => {
      if (!a.color) {
        a.color = getRandomColor();
      }
    });
    setTeams(Array.from(_teams));
  };

  const loadMissionData = async () => {
    const _myOrg = await getOrgSingle(userValue.user.organisationId);

    if (canEdit) {
      setOrgUsers(await getOrgUsers(userValue.user.organisationId));
    }

    await loadUsers();
    await loadRoles();
    await loadTeams();

    const _orgIntegrations =
      missions.length === 1
        ? await getOrgIntegrations(missions[0].organisationId)
        : [];
    _orgIntegrations.forEach((i) => {
      if (missions[0].integrations?.length) {
        i.selected = !!missions[0].integrations.find((code) => code === i.code);
      } else {
        i.selected = false;
      }
    });

    const _integrations =
      missions.length === 1
        ? (await getMissionIntegrations(localMissions.current[0].id)).map(
            (i) => {
              i.active =
                getLocalStorage(
                  `integrations-${localMissions.current[0].id}`
                )?.find((mi) => mi.id === i.id)?.active || false;
              i.visibleInToolbar =
                getLocalStorage(
                  `integrations-${localMissions.current[0].id}`
                )?.find((mi) => mi.id === i.id)?.visibleInToolbar || false;
              return i;
            }
          )
        : [];

    const _geofences = [];
    for (const mission of missions) {
      const missionGeofences = await getGeofences(mission.id);
      for (const g of missionGeofences) {
        _geofences.push(g);
      }
    }

    const _annotations = [];
    for (const mission of missions) {
      const missionAnnotations = await getAnnotations(mission.id);
      for (const a of missionAnnotations) {
        _annotations.push(a);
      }
    }

    const _styles = sortBy(await getMapStyles(null), ['default'], ['desc']);

    await refreshPois();
    await refreshSymbols();

    setGeofences(_geofences);
    setAnnotations(_annotations);
    setMyOrg(_myOrg);
    setStyles(_styles);
    setOrgIntegrations(_orgIntegrations);
    setIntegrations(_integrations);
  };

  const initChats = async () => {
    await comms.initChats(localMissions.current[0].id);
  };

  // run first time
  useEffect(() => {
    const { promise, cancel } = cancellablePromise(loadMissionData());
    promise
      .then(() => {})
      .finally(() => {
        setDataLoaded(true);
      });

    return () => {
      cancel();
    };
    // eslint-disable-next-line
  }, []);

  // run after map data loaded
  useEffect(() => {
    notification.setIsMap(true);

    if (dataLoaded) {
      const stub = async () => {
        log.debug('runonce', localMissions.current);

        // init map here
        await initMap();
        initMapEvents();

        await redraw(true);

        await centreMapOnMissionArea();

        drawMarkers();

        if (
          locationServices.currentLocation &&
          localMissions.current.length === 1
        ) {
          locationUpdate({
            missionId: localMissions.current[0].id,
            userId: userValue.user.id,
            location: locationServices.currentLocation,
          });
        }

        await locationServices.sendOutLocation();

        animate();
      };

      // always turn the buttons back on
      const { promise, cancel } = cancellablePromise(stub());
      promise
        .then(() => {})
        .finally(() => {
          calculatePPM();

          setMapLoaded(true);

          toast.success('Map loaded');
        });

      return () => {
        log.debug('DESTROY', 'MissionMap');

        // don't remove location
        // for (const m of missions) {
        //   removeLocation(m.id, userValue.user.id);
        // }

        clearTimeout(simTimer.current);

        cancel();

        destroyMapEvents();

        notification.setIsMap(false);

        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
          animationRef.current = null;
        }

        TWEEN.removeAll();

        if (trackingTimer.current) {
          clearTimeout(trackingTimer.current);
          trackingTimer.current = null;
        }
        if (plottingTimer.current) {
          clearTimeout(plottingTimer.current);
          plottingTimer.current = null;
        }
        for (const t of integrationsTimers.current) {
          clearTimeout(t);
        }
        if (map.current) {
          // turn off all integrations
          for (const integration of integrations) {
            const impl =
              integrationsInterface[integration.organisationIntegration.code];
            if (impl?.cleanup) {
              impl.cleanup(integration.id, map.current);
            }
            if (integration.organisationIntegration.code === 'BOM_WEATHER') {
              removeWeatherLayer();
            } else {
              removeIntegration(map.current, integration.id);
            }
          }
          map.current.remove();
          map.current = null;
        }
      };
    }
    // eslint-disable-next-line
  }, [dataLoaded]);

  useEffect(() => {
    if (SIMULATE) {
      simTimerFunc();
    } else {
      clearTimeout(simTimer.current);
    }
    // eslint-disable-next-line
  }, [SIMULATE]);

  useEffect(() => {
    if (map.current && mapLoaded && mapContainer.current) {
      mapContainer.current.classList.remove('hidden');
      map.current.resize();
    }
  }, [mapLoaded, comms.callState]);

  // run when map finished initial load
  useEffect(() => {
    if (mapLoaded && dataLoaded) {
      drawMarkers();
    }
    // eslint-disable-next-line
  }, [mapLoaded, dataLoaded]);

  // run when mission area and geofence settings change
  useEffect(() => {
    if (map.current && mapLoaded) {
      redraw().catch(() => {});
    }

    if (mapContainer.current) {
      if (mode === FeatureType.Mission || mode === FeatureType.Shape) {
        mapContainer.current.classList.add('map-is-being-edited');
      } else {
        mapContainer.current.classList.remove('map-is-being-edited');
      }
    }
    // eslint-disable-next-line
  }, [mode]);

  // run when mission area visibility changes
  useEffect(() => {
    if (map.current) {
      drawMissionArea();
    }
    // eslint-disable-next-line
  }, [showMissionArea]);

  // run when geofence or annotation visibility changes
  useEffect(() => {
    if (map.current && localMode.current !== FeatureType.Shape) {
      if (map.current?.getLayer(`geofence-labels`)) {
        map.current?.removeLayer(`geofence-labels`);
      }
      if (map.current?.getLayer(`annotation-labels`)) {
        map.current?.removeLayer(`annotation-labels`);
      }
      drawShapes();
    }
    // eslint-disable-next-line
  }, [
    showGeofences,
    showGeofenceLabels,
    showAnnotations,
    showAnnotationLabels,
    labelZoomThreshold,
  ]);

  useEffect(() => {
    const stub = async () => {
      await plotMarkers();
    };
    const { promise, cancel } = cancellablePromise(stub());
    promise
      .then(() => {
        setTimeout(() => {
          drawMarkers();
        }, 100);
      })
      .catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [
    showTeams,
    showOnlyTeam,
    showRoles,
    showOnlyRole,
    user,
    image,
    waypoint,
    pointsOfInterest,
    symbols,
    locationServices.broadcasting,
  ]);

  // run when waypoint or photo visibility changes
  useEffect(() => {
    const stub = async () => {
      await refreshPois(null, true);
    };
    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [showWaypoints, showPhotos]);

  // run when symbol visibility changes
  useEffect(() => {
    const stub = async () => {
      await refreshSymbols(null, true);
    };
    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [showSymbols]);

  // run when milstd specific toggles are pressed
  useEffect(() => {
    const stub = async () => {
      await refreshIntegrations();
      await refreshSymbols(null, true);
    };
    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;

    // eslint-disable-next-line
  }, [showMilStdInfo, milStdIconSize, showMilStdIcon, selectedStyle, showMGRS]);

  // run when offline users change
  useEffect(() => {
    debouncedPlotMarkers();
    // eslint-disable-next-line
  }, [offlinePersonnel]);

  // run when personnel label visibility changes
  useEffect(() => {
    drawMarkers();
    // eslint-disable-next-line
  }, [showPersonnelLabels]);

  // run when user tracks visibility changes
  useEffect(() => {
    if (map.current) {
      plotTracks();
    }
    // eslint-disable-next-line
  }, [showTracks]);

  useEffect(() => {
    resetDrawMode();
    // eslint-disable-next-line
  }, [mode, shapeIcon]);

  // run when notification change
  useEffect(() => {
    criticalUserIds.current = notification.notifications
      .filter((n) => n.status === NotificationStatus.critical && n.target)
      .map((n) => n.target.id);
  }, [notification.notifications]);

  useEffect(() => {
    // these users are all in teams
    allUsersInATeam.current = [];
    for (const team of teams) {
      allUsersInATeam.current.push(...(team.users || []));
    }

    teamUsersSelected.current = [];
    if (showOnlyTeam.length) {
      for (const tid of showOnlyTeam) {
        const team = teams.find((t) => t.id === tid);
        if (team?.users) {
          teamUsersSelected.current.push(...team.users);
        }
      }
    } else {
      teamUsersSelected.current.push(...personnel.map((u) => u.id));
    }
  }, [teams, showOnlyTeam, personnel]);

  // run when messages change
  useEffect(() => {
    if (missions?.length) {
      setUnreadThreads(
        getUnreadMessages(userValue.user.id, missions[0].id, comms.threads) || 0
      );
    }
  }, [userValue.user, missions, comms.threads, comms.messages]);

  useEffect(() => {
    if (selectedStyle && map.current) {
      map.current.setStyle(selectedStyle);
    }
    // eslint-disable-next-line
  }, [selectedStyle]);

  useEffect(() => {
    const stub = async () => {
      await plotIntegrations();
    };
    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;

    // eslint-disable-next-line
  }, [integrations]);

  useEffect(() => {
    joyride.setTour(Tours.Map);
    joyride.setStepIndex(0);
    joyride.setChecked(false);
    var tourDate = joyride.getTourDate(Tours.Map);
    joyride.setRunning(
      tourDate != null && tourDate > joyride.dateEdited ? false : true
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !!features &&
      features.length > 0 &&
      features[0]?.id !== 'new' &&
      (localMode.current === FeatureType.Shape ||
        localMode.current === null ||
        localMode.current === '')
    ) {
      sidebars.open(SIDEBARS.shapeDetails);
    } else {
      sidebars.close(SIDEBARS.shapeDetails);
    }
    // eslint-disable-next-line
  }, [mode, features]);

  useEffect(() => {
    const stub = async () => {
      if (milspec) {
        const formData = await getMilspecFormData();
        const symbolSetId = milspec.symbol.slice(4, 6);
        const symbolSet = await getSymbolSet(symbolSetId);
        const echelonFormData = await getEchelonFormData(symbolSetId);
        const { modifier1, modifier2 } = await getIconModifiersFormData(
          symbolSetId
        );
        setMilspecFormData({
          formData: formData,
          symbolSet: symbolSet,
          symbolSetId: symbolSetId,
          echelonFormData: echelonFormData,
          mod1FormData: modifier1,
          mod2FormData: modifier2,
        });
      }
    };
    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [milspec]);

  // broadcasting changed
  // useEffect(() => {
  //   log.debug(
  //     'MissionMap',
  //     'Broadcast updated',
  //     broadcasting,
  //     navigator.geolocation
  //   );
  //   if (broadcasting && navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(getCurrentUserLocation);
  //   }
  // }, [broadcasting]);

  const handleStyleChange = () => {
    const waiting = async () => {
      if (!map.current.isStyleLoaded()) {
        setTimeout(waiting, 200);
      } else {
        redraw();
        map.current.loadImage(arrowBlack, (error, image) => {
          if (error) throw error;
          if (!map.current.hasImage('arrow-head')) {
            // Add the image to the map style.
            map.current.addImage('arrow-head', image, { sdf: true });
          }
        });
        addWeatherLayer(localWeatherLayer.current);
      }
    };
    waiting();
  };

  const plotUsers = async () => {
    if (map.current) {
      log.debug('MissionMap', 'plotUsers');

      const newPersonnel = [];

      for (const mission of localMissions.current) {
        // don't include yourself
        const personnelLocations = localPersonnel.current?.length
          ? localPersonnel.current.map((p) => {
              p.coords = p.location
                ? toLongitudeLatitude(p.location.coords)
                : null;
              p.timestamp = p._ts;
              return p;
            })
          : [];
        // log.debug('plot units', personnelLocations);

        for (const p of personnelLocations) {
          const personnel = newPersonnel.find((l) => l.id === p.id);
          if (!personnel) {
            updateFenceAlerts(p, null);
            newPersonnel.push(p);
          }
        }
      }

      for (const lp of [...localPersonnel.current]) {
        const personnel = newPersonnel.find((l) => l.id === lp.id);
        if (!personnel) {
          lp.offline = true;
          removeMarker(lp.id, 'markers');

          localPersonnel.current = [
            ...localPersonnel.current.filter((l) => l.id !== lp.id),
          ];
        }
      }

      for (const p of newPersonnel) {
        const personnel = localPersonnel.current.find((l) => l.id === p.id);
        if (personnel) {
          easeMarkerTo(personnel.userId, personnel.coords, 'markers');
        } else {
          localPersonnel.current = [...localPersonnel.current, p];
        }
      }

      setPersonnel(localPersonnel.current);

      if (localPointsOfInterest.current) {
        for (const poi of localPointsOfInterest.current) {
          easeMarkerTo(`${poi.id}`, poi.coords, `markers`);
        }
      }

      centreOnTargets();
    }
  };

  //Needed to be able to remove event listener
  const handleMouseEnterCallback = useCallback((e) => {
    mouseEnterParameters.current.handler(
      e,
      mouseEnterParameters.current.func,
      localShowMilStdInfo.current
    );
  }, []);

  //Needed to be able to remove event listener
  const handleMouseLeaveCallback = useCallback((e) => {
    mouseLeaveParameters.current.handler(e, mouseLeaveParameters.current.func);
  }, []);

  //Needed to be able to remove event listener
  const handleOnClickCallback = useCallback((e) => {
    onClickParameters.current.handler(
      e,
      onClickParameters.current.func,
      localShowMGRS.current
    );
  }, []);

  const plotIntegrations = async () => {
    if (!plottingIntegrations.current) {
      log.debug('plotIntegrations', plottingIntegrations.current);

      plottingIntegrations.current = true;

      for (const t of integrationsTimers.current) {
        clearTimeout(t);
      }

      if (map.current) {
        const activeIntegrations = integrations.filter((i) => i.active);

        for (const integration of integrations.filter((i) => !i.active)) {
          const impl =
            integrationsInterface[integration.organisationIntegration.code];

          removeIntegration(map.current, integration.id);
          if (integration.organisationIntegration.code === `BOM_WEATHER`) {
            const id = `integration-OPENWEATHER`;
            if (map.current.getLayer(id)) {
              map.current.removeLayer(id);
            }
            if (map.current.getSource(id)) {
              map.current.removeSource(id);
            }
            localWeatherLayer.current = null;
            setWeatherLayer(null);
            setSettingWeatherLocation(false);
            setWeatherLocation(null);
            if (localWeatherMarker.current.length > 0) {
              for (const i of localWeatherMarker.current) {
                i.remove();
              }
            }
          }

          const layers = impl?.layers(integration.id);

          if (impl?.mouseEnter) {
            for (const f of layers) {
              map.current.off('mouseenter', f, impl.mouseEnter);
            }
          }
          if (impl?.mouseLeave) {
            for (const f of layers) {
              map.current.off('mouseleave', f, impl.mouseLeave);
            }
          }
          if (impl?.sidebar) {
            for (const f of layers) {
              map.current.off('click', f, impl.sidebar);
            }
          }
          if (impl?.cleanup) {
            //SG 16 Aug: hard-coded for spark demo we need to remove this...
            if (integration?.organisationIntegration.code === `SPARK`) {
              setSpark(false);
            }
            impl.cleanup(null, map.current);
          }
        }

        integrationsTimers.current = [];

        document.sidebars = sidebars;
        document.bottombars = bottombars;

        for (const integration of activeIntegrations) {
          const impl =
            integrationsInterface[integration.organisationIntegration.code];
          if (impl && integration.active) {
            log.debug('plotIntegrations', plottingIntegrations.current);

            try {
              if (integration.polling) {
                const pollIntegration = () => {
                  if (map.current) {
                    impl
                      .draw(
                        map.current,
                        integration.id,
                        integration.polling.endpoint,
                        integration.organisationIntegration.name,
                        integration.organisationIntegration.photoUrl,
                        localSelectedStyle.current,
                        symbolDetailsZoomThreshold
                      )
                      .then(() => {
                        if (!impl.enableAnimations && impl.animate) {
                          impl.animate(
                            map.current,
                            integration.id,
                            symbolDetailsZoomThreshold
                          );
                        }
                        integrationsTimers.current.push(
                          setTimeout(
                            pollIntegration,
                            integration.polling.interval
                          )
                        );
                      });
                  }
                };
                pollIntegration();
              } else {
                // bottom sheets only for now this will need to be fefactored..

                // new integration implementations that aren't polling

                //draw integration
                await impl.draw(map.current);

                //fill with data
                //SG 16 Aug: hard-coded for spark demo we need to remove this...
                if (integration?.organisationIntegration.code === `SPARK`) {
                  setIgnitionSeconds(0);
                  setIgnitionDisplay('00:00');
                  setSpark(true);
                }
              }
            } catch {}

            const layers = impl.layers(integration.id);

            if (impl.mouseEnter) {
              for (const f of layers) {
                mouseEnterParameters.current = {
                  handler: impl.mouseEnter,
                  func: addPopup,
                };
                map.current.off('mouseenter', f, handleMouseEnterCallback);
                map.current.on('mouseenter', f, handleMouseEnterCallback);
              }
            }
            if (impl.mouseLeave) {
              for (const f of layers) {
                mouseLeaveParameters.current = {
                  handler: impl.mouseLeave,
                  func: clearPopup,
                };
                map.current.off('mouseleave', f, handleMouseLeaveCallback);
                map.current.on('mouseleave', f, handleMouseLeaveCallback);
              }
            }
            if (impl.sidebar) {
              for (const f of layers) {
                onClickParameters.current = {
                  handler: impl.sidebar,
                  func: refreshIntegrations,
                };
                map.current.off('click', f, handleOnClickCallback);
                map.current.on('click', f, handleOnClickCallback);
              }
            }
          }
        }
      }
    }

    plottingIntegrations.current = false;
  };

  const refreshIntegrations = async () => {
    if (map.current) {
      const activeIntegrations = integrations.filter((i) => i.active);
      for (const integration of activeIntegrations) {
        const impl =
          integrationsInterface[integration.organisationIntegration.code];
        if (impl && integration.active) {
          log.debug('refreshIntegrations', plottingIntegrations.current);

          try {
            if (integration.polling) {
              const pollIntegration = () => {
                if (map.current) {
                  impl
                    .draw(
                      map.current,
                      integration.id,
                      integration.polling.endpoint,
                      integration.organisationIntegration.name,
                      integration.organisationIntegration.photoUrl,
                      localSelectedStyle.current,
                      symbolDetailsZoomThreshold
                    )
                    .then(() => {
                      if (!impl.enableAnimations && impl.animate) {
                        impl.animate(
                          map.current,
                          integration.id,
                          symbolDetailsZoomThreshold
                        );
                      }
                      integrationsTimers.current.push(
                        setTimeout(
                          pollIntegration,
                          integration.polling.interval
                        )
                      );
                    });
                }
              };
              pollIntegration();
            } else {
              // bottom sheets only for now this will need to be fefactored..

              // new integration implementations that aren't polling

              //draw integration
              await impl.draw(map.current);

              //fill with data
              //SG 16 Aug: hard-coded for spark demo we need to remove this...
              if (integration?.organisationIntegration.code === `SPARK`) {
                setIgnitionSeconds(0);
                setIgnitionDisplay('00:00');
                setSpark(true);
              }
            }
          } catch {}
        }
      }
    }
  };

  const poiUpdate = (poi) => {
    if (
      map.current &&
      localMissions.current.find((m) => m.id === poi.missionId)
    ) {
      log.debug('poi:', poi);
      const existingPoi = pointsOfInterest.find((p) => p.id === poi.id);
      if (existingPoi) {
        // Remove poi marker if archived
        if (poi.archived) {
          removeMarkerAsync(`${poi.id}`, `markers`);
          let newPoiArray = pointsOfInterest.filter((p) => p.id !== poi.id);
          localPointsOfInterest.current = newPoiArray;
          setPointsOfInterest(newPoiArray);
        } else {
          // Refresh poi marker
          removeMarkerAsync(`${poi.id}`, `markers`);
          let newPoiArray = pointsOfInterest.filter((p) => p.id !== poi.id);
          newPoiArray.push(poi);
          localPointsOfInterest.current = newPoiArray;
          setPointsOfInterest(newPoiArray);
        }
      } else {
        // Add new poi marker
        let newPoiArray = pointsOfInterest.filter((p) => p.id !== poi.id);
        newPoiArray.push(poi);
        localPointsOfInterest.current = newPoiArray;
        setPointsOfInterest(newPoiArray);
      }
    }
  };

  const locationUpdate = (loc) => {
    let personnel = localPersonnel.current.find((u) => u.id === loc.userId);
    if (personnel) {
      log.debug('MissionMap', 'locationUpdate');
      if (loc.offline || !loc.location) {
        // if location removed then set to offline
        personnel.offline = loc.offline;
        const newOfflinePersonnel =
          localOfflinePersonnel.current.push(personnel);
        setOfflinePersonnel(newOfflinePersonnel);
      } else {
        updateFenceAlerts(
          { ...personnel },
          toLongitudeLatitude(loc.location.coords)
        );
        personnel.coords = toLongitudeLatitude(loc.location.coords);
        personnel.offline = false;
        personnel.location = { ...loc.location };
        personnel.timestamp = loc._ts;
        let wasOffline = localOfflinePersonnel.current.filter(
          (lp) => lp.id === loc.userId
        );
        if (wasOffline) {
          let newOfflinePersonnel = localOfflinePersonnel.current.filter(
            (lp) => lp.id !== loc.userId
          );
          setOfflinePersonnel(newOfflinePersonnel);
        }

        const sourceId =
          personnel.id === userValue.user.id ? 'self' : 'markers';
        easeMarkerTo(loc.userId, loc.location.coords, sourceId);
      }
    }
  };

  const refreshMapCache = async () => {
    if (map.current) {
      mapboxgl.clearStorage();
      toast.success('Map cache refreshed');
    }
  };
  const missionTeam = (team) => {
    if (missions.map((m) => m.id).includes(team.missionId)) {
      log.debug('teamUpdate', team);
      loadTeams();
    }
  };

  const missionUser = async (user) => {
    if (missions.map((m) => m.id).includes(user.missionId)) {
      log.debug('missionUser', user);
      const userLocations = await getUserLocations(localMissions.current[0].id);
      setPersonnel(userLocations);
      localPersonnel.current = userLocations;
      plotUsers().then(plotMarkers);
    }
  };

  useEvent(Signal.PoiUpdate, poiUpdate);
  useEvent(Signal.LocationUpdate, locationUpdate);
  useEvent(Signal.MissionUser, missionUser);
  useEvent(Signal.MissionTeam, missionTeam);
  useEvent(Signal.RefreshMapCache, refreshMapCache);
  useEvent(Signal.SymbolUpdate, symbolUpdate);

  //useEvent(Signal.GeofenceUpdate, geofenceUpdate);
  //useEvent(Signal.AnnotationUpdate, annotationUpdate);

  const doLiveTracking = async () => {
    if (trackingTimer.current) {
      clearTimeout(trackingTimer.current);
      trackingTimer.current = null;
    }
    if (localLiveTracking.current) {
      const trackedPersonnel = localPersonnel.current.find(
        (p) => p.id === localLiveTracking.current.id
      );
      if (trackedPersonnel?.coords && map.current) {
        log.debug('tracking', trackedPersonnel);

        centreMapOnPersonnel({
          coords: toLngLat(trackedPersonnel.coords),
        });
      } else {
        setLiveTracking(null);
      }

      trackingTimer.current = setTimeout(
        doLiveTracking,
        animationConfig.liveTrackingMarkerInterval
      );
    }
  };

  const plotCluster = async (id, icon, cluster, clusterMarkers = true) => {
    if (localDebug.current) {
      log.debug('plot cluster', id, cluster);
    }

    const baseColor = 'var(--colour-interactions-a)';

    const data = {
      type: 'FeatureCollection',
      properties: {
        color: baseColor,
      },
      features: cluster
        .filter((c) => (c.coords || c.location) && !c.offline)
        .map((c) => {
          return {
            // feature for Mapbox DC
            id: c.id,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [
                c?.coords?.longitude
                  ? c.coords.longitude
                  : c.location.longitude,
                c?.coords?.latitude ? c.coords.latitude : c.location.latitude,
              ],
            },
            properties: {
              id: c.id,
              markerType: c.type || 'User',
              name: c.name,
              color: c.guest ? 'black' : c.color,
              photoUrl: c.photoUrl,
              fallback: c.fallback,
              callsign: c.callsign,
              firstname: c.firstname,
              lastname: c.lastname,
              roleId: c.roleId,
              guest: c.guest,
              location: c.location,
              poiType: c.type,
              reference: c.reference,
              count: 1,
              description:
                c.description ||
                c.meta?.description ||
                (c.roleId
                  ? localRoles.current.find((r) => r.id === c.roleId)?.role
                  : ''),
              timestamp: c.timestamp,
              _ts: c._ts,
              userId: c.userId,
              milspec: c.milspec,
              symbol: c.symbol,
            },
          };
        }),
    };

    if (map.current) {
      try {
        if (clusterMarkers) {
          if (!map.current.getSource(id)) {
            if (localDebug.current) {
              log.debug('new source', id);
            }
            map.current.addSource(id, {
              type: 'geojson',
              data,
              cluster: true,
              clusterRadius: CLUSTER_RADIUS,
              clusterProperties: {
                user_count: [
                  '+',
                  ['case', ['==', ['get', 'markerType'], 'User'], 1, 0],
                ],
                photo_count: [
                  '+',
                  ['case', ['==', ['get', 'markerType'], POI.Photo], 1, 0],
                ],
                waypoint_count: [
                  '+',
                  ['case', ['==', ['get', 'markerType'], POI.Waypoint], 1, 0],
                ],
              },
            });
          } else {
            if (localDebug.current) {
              log.debug('updating source', id, data);
            }
            map.current.getSource(id).setData(data);
          }

          // this is just a hidden layer so we can search for features
          if (!map.current.getLayer(id)) {
            map.current.addLayer({
              id: `${id}`,
              type: 'circle',
              source: id,
              filter: [
                'all',
                ['!', ['has', 'point_count']],
                ['>=', ['zoom'], ZOOM_THRESHOLD],
              ],
              paint: {
                'circle-color': ['get', 'color'],
                'circle-radius': 24,
                'circle-opacity': 0,
              },
            });
          }

          if (!map.current.getLayer(`${id}-cluster`)) {
            map.current.addLayer({
              id: `${id}-cluster`,
              type: 'circle',
              source: id,
              filter: [
                'all',
                ['has', 'point_count'],
                ['>=', ['zoom'], ZOOM_THRESHOLD],
              ],
              paint: {
                'circle-color': ['get', 'color'],
                'circle-radius': 36,
                'circle-opacity': 0,
              },
            });
          }
        } else {
          if (!map.current.getSource(id)) {
            if (localDebug.current) {
              log.debug('new source', id);
            }
            map.current.addSource(id, {
              type: 'geojson',
              data,
              cluster: false,
            });
          } else {
            if (localDebug.current) {
              log.debug('updating source', id, data);
            }
            map.current.getSource(id).setData(data);
          }

          // this is just a hidden layer so we can search for features
          if (!map.current.getLayer(id)) {
            map.current.addLayer({
              id: `${id}`,
              type: 'circle',
              source: id,
              filter: ['all'],
              paint: {
                'circle-color': ['get', 'color'],
                'circle-radius': 24,
                'circle-opacity': 0,
              },
            });
          }
        }
      } catch {}
    }
  };

  const drawSymbolMarker = (newMarkers, feature) => {
    const id = `${feature.properties.id}`;

    const mission = localMissions.current[0];

    // marker editable if the owner
    // sysadmin
    // org admin of mission owner
    const editable =
      feature.properties.userId === userValue.user.id ||
      userValue.user.accessRoles.includes(Role.SysAdmin) ||
      (mission?.organisationId === userValue.user.organisationId &&
        userValue.user.accessRoles.includes(Role.OrgAdmin));

    let marker = mapMarkers.current[id];
    if (!marker) {
      log.debug(`creating new symbol marker`);
      if (localDebug.current) {
        log.debug(`creating new symbol marker`);
      }

      marker = mapMarkers.current[id] = symbolMarker(
        map.current,
        `markers`,
        feature,
        editable,
        localShowMilStdInfo.current,
        localShowMilStdIcon.current,
        localSelectedStyle.current,
        symbolDetailsZoomThreshold
      );

      // must be editable
      if (editable) {
        marker.on('dragend', (e) => {
          log.debug('dragend', e);
          var lngLat = e.target.getLngLat();

          const symbol = localSymbols.current.find(
            (s) => s.id === e.target.getElement().id
          );
          if (symbol) {
            symbol.location = toLongitudeLatitude(lngLat);
            easeMarkerTo(`${symbol.id}`, symbol.location, `markers`);
            refreshSymbols(symbol).then(() => {
              setSymbols([...localSymbols.current]);
              updateSymbol(symbol.id, {
                location: symbol.location,
                milspec: symbol.milspec,
              });
              toast.success('Symbol updated', {
                id: 'poi-updated',
              });
            });
            setLocation({
              lat: symbol.location.latitude,
              lng: symbol.location.longitude,
            });
          }
        });
      }

      marker
        .getElement()
        .addEventListener('click', (e) => handleSymbolClick(e, marker));

      marker.getElement().addEventListener('mouseenter', handleSymbolHoverIn);
      marker
        .getElement()
        .addEventListener('mouseleave', debounce(handleSymbolHoverOut, 150));
    } else {
      if (localDebug.current) {
        log.debug(`reusing symbol marker`);
      }
      marker.setLngLat(feature.geometry.coordinates);
    }

    newMarkers[id] = marker;

    if (!mapMarkersOnScreen.current[id]) {
      marker.addTo(map.current);
    }

    const el = marker.getElement();
    if (el) {
      el.style.visibility = 'visible';
      el.classList.remove('selected');

      if (el.id === localSymbol.current?.id) {
        el.classList.add('selected');
      }
    }
  };

  const drawPoiMarker = (newMarkers, feature) => {
    const id = `${feature.properties.id}`;
    const type = feature.properties.poiType;

    const mission = localMissions.current[0];

    // marker editable if the owner
    // sysadmin
    // org admin of mission owner
    const editable =
      feature.properties.userId === userValue.user.id ||
      userValue.user.accessRoles.includes(Role.SysAdmin) ||
      (mission?.organisationId === userValue.user.organisationId &&
        userValue.user.accessRoles.includes(Role.OrgAdmin));

    let marker = mapMarkers.current[id];
    if (!marker) {
      if (localDebug.current) {
        log.debug(`creating new ${type} marker`);
      }

      if (type === POI.Photo) {
        marker = mapMarkers.current[id] = imageMarker(
          map.current,
          `markers`,
          feature,
          editable
        );
      } else if (type === POI.Waypoint) {
        marker = mapMarkers.current[id] = waypointMarker(
          map.current,
          `markers`,
          feature,
          editable,
          localShowMilStdInfo.current,
          localShowMilStdIcon.current,
          localSelectedStyle.current,
          symbolDetailsZoomThreshold
        );
      }

      // must be editable
      if (editable) {
        marker.on('dragend', (e) => {
          log.debug('dragend', e);
          var lngLat = e.target.getLngLat();

          const poi = localPointsOfInterest.current.find(
            (p) => p.id === e.target.getElement().id
          );
          if (poi) {
            poi.coords = toLongitudeLatitude(lngLat);
            easeMarkerTo(`${poi.id}`, poi.coords, `markers`);
            setLocation(poi.coords);
            updatePointOfInterest(poi).then(() => {
              setPointsOfInterest([...localPointsOfInterest.current]);

              if (poi.type === POI.Waypoint) {
                toast.success('Waypoint updated', {
                  id: 'poi-updated',
                });
              } else if (poi.type === POI.Photo) {
                toast.success('Image updated', {
                  id: 'poi-updated',
                });
              }
            });
          }
        });
      }

      marker
        .getElement()
        .addEventListener('contextmenu', (e) => handlePOIContext(e, marker));

      marker
        .getElement()
        .addEventListener('touchstart', (e) => handlePOITouch(e, marker));

      // ALL of these are to remove the touch event #fml
      marker.getElement().addEventListener('touchend', clearTouch);
      marker.getElement().addEventListener('touchcancel', clearTouch);
      marker.getElement().addEventListener('touchmove', clearTouch);
      marker.getElement().addEventListener('pointerdrag', clearTouch);
      marker.getElement().addEventListener('pointermove', clearTouch);
      marker.getElement().addEventListener('moveend', clearTouch);

      if (!!!feature.properties.milspec) {
        marker.getElement().addEventListener('mouseenter', handlePOIHoverIn);
        marker
          .getElement()
          .addEventListener('mouseleave', debounce(handlePOIHoverOut, 150));
      }

      marker
        .getElement()
        .addEventListener('click', (e) => handlePOIClick(e, marker));
    } else {
      if (localDebug.current) {
        log.debug(`reusing ${type} marker`);
      }

      marker.setLngLat(feature.geometry.coordinates);
      if (
        type === POI.Waypoint &&
        marker.getElement() &&
        marker.getElement().getElementsByClassName('inner')?.length
      ) {
        marker
          .getElement()
          .getElementsByClassName('inner')[0].children[0].innerText =
          feature.properties.reference;
      }
    }

    newMarkers[id] = marker;

    if (!mapMarkersOnScreen.current[id]) {
      marker.addTo(map.current);
    }

    const el = marker.getElement();
    if (el) {
      el.style.visibility = 'visible';
      el.classList.remove('selected');

      if (el.id === localImage.current?.id) {
        el.classList.add('selected');
      } else if (el.id === localWaypoint.current?.id) {
        el.classList.add('selected');
      }
    }
  };

  const drawUserMarker = (newMarkers, feature) => {
    let id = `${feature.properties.id}`;
    const isSelfMarker = false;

    let marker = mapMarkers.current[id];
    if (!marker) {
      if (localDebug.current) {
        log.debug(`creating ${isSelfMarker ? 'self' : 'user'} marker`);
      }

      // specific to your own marker
      if (isSelfMarker) {
        marker = mapMarkers.current[id] = selfMarker(
          map.current,
          'self',
          feature
        );

        marker.on('dragend', (e) => {
          log.debug('dragend', e);
          var lngLat = e.target.getLngLat();

          if (map.current) {
            const data = map.current.getSource('self')?._data;
            if (data && data.features[0]) {
              data.features[0].geometry.coordinates = toArray(lngLat);
              map.current.getSource('self').setData({ ...data });
            }
          }

          updateLocation(
            localMissions.current[0].id,
            userValue.user.id,
            lngLat,
            null
          );

          // disable broadcasting when you move the cursor
          if (localBroadcasting.current) {
            toast.success('Stopped Sharing Location');
          }
          locationServices.setBroadcasting(false);
        });
      } else {
        // all other user merkers
        marker = mapMarkers.current[id] = personnelMarker(
          map.current,
          'markers',
          feature
        );
      }

      marker
        .getElement()
        .lastChild.addEventListener('mouseenter', (e) =>
          handlePersonnelHoverIn(e, marker)
        );
      marker
        .getElement()
        .lastChild.addEventListener('mouseleave', handlePersonnelHoverOut);

      marker
        .getElement()
        .addEventListener('contextmenu', (e) =>
          handlePersonnelContext(e, marker)
        );

      marker
        .getElement()
        .addEventListener('touchstart', (e) => handlePersonnelTouch(e, marker));

      // ALL of these are to remove the touch event #fml
      marker.getElement().addEventListener('touchend', clearTouch);
      marker.getElement().addEventListener('touchcancel', clearTouch);
      marker.getElement().addEventListener('touchmove', clearTouch);
      marker.getElement().addEventListener('pointerdrag', clearTouch);
      marker.getElement().addEventListener('pointermove', clearTouch);
      marker.getElement().addEventListener('moveend', clearTouch);

      marker.getElement().addEventListener('gesturestart', clearTouch);
      marker.getElement().addEventListener('gesturechange', clearTouch);
      marker.getElement().addEventListener('gestureend', clearTouch);

      marker
        .getElement()
        .lastChild.addEventListener('click', (e) =>
          handlePersonnelClick(e, marker)
        );
    } else {
      if (localDebug.current) {
        log.debug(`reusing ${isSelfMarker ? 'self' : 'user'} marker`);
      }

      marker.setLngLat(feature.geometry.coordinates);
    }

    newMarkers[id] = marker;

    if (!mapMarkersOnScreen.current[id]) {
      marker.addTo(map.current);
    }

    const el = marker.getElement();

    if (el) {
      el.style.visibility = 'visible';

      if (isSelfMarker) {
        el.classList.remove('selected');
        el.classList.remove('critical');
        el.classList.remove('animated');

        if (el.id === localUser.current?.id) {
          el.classList.add('selected');
        }

        if (criticalUserIds.current.indexOf(el.id) !== -1) {
          el.classList.add('critical');
        }

        if (localBroadcasting.current) {
          el.classList.add('animated');
        }
      } else {
        el.classList.remove('selected');
        el.classList.remove('critical');
        el.classList.remove('hidden');

        if (!el.classList.contains('cluster')) {
          if (!localShowRoles.current) {
            el.classList.add('hidden');
          } else if (localShowOnlyRole.current?.length) {
            if (
              !localShowOnlyRole.current.includes(
                el.dataset.roleId || 'unassigned'
              )
            ) {
              el.classList.add('hidden');
            }
          }

          if (!localShowTeams.current) {
            el.classList.add('hidden');
          } else if (localShowOnlyTeam.current.includes('unassigned')) {
            // hide users that are in a team and not in selected users list
            if (
              allUsersInATeam.current.includes(el.id) &&
              !teamUsersSelected.current.includes(el.id)
            ) {
              el.classList.add('hidden');
            }
          } else if (!teamUsersSelected.current.includes(el.id)) {
            el.classList.add('hidden');
          }

          if (el.id === localUser.current?.id) {
            el.classList.add('selected');
          }

          if (criticalUserIds.current.indexOf(el.id) !== -1) {
            el.classList.add('critical');
          }

          const _user = localPersonnel.current.find((u) => u.id === el.id);
          if (_user && _user.location?.coords) {
            if (_user.location?.coords?.accuracy) {
              const halo = el.getElementsByClassName('halo');
              // accuracy is in m - need to convert to px
              const accuracyInPx =
                _user.location?.coords?.accuracy * pixelsPerMeter.current;

              if (halo.length) {
                halo[0].style.width = `${accuracyInPx}px`;
                halo[0].style.height = `${accuracyInPx}px`;
              }
            }
            if (
              (_user.location?.coords?.heading ||
                _user.location?.coords?.heading >= 0) &&
              _user.location?.coords?.heading_accuracy
            ) {
              const coneEl = el.getElementsByClassName('heading')[0];
              if (coneEl) {
                const bearing = map.current.getBearing();
                coneEl.style.background = `conic-gradient(rgba(86, 184, 219, 0) 0deg, rgba(86, 184, 219, 0.9) ${
                  _user.location.coords.heading_accuracy / 2.0 -
                  _user.location.coords.heading_accuracy / 4.0
                }deg, rgba(86, 184, 219, 0.9) ${
                  _user.location.coords.heading_accuracy / 2.0 +
                  _user.location.coords.heading_accuracy / 4.0
                }deg, rgba(86, 184, 219, 0) ${
                  _user.location.coords.heading_accuracy
                }deg, transparent ${
                  _user.location.coords.heading_accuracy
                }deg, transparent 360deg)`;
                coneEl.style.transform = `rotate(${
                  -(_user.location.coords.heading_accuracy / 2.0) +
                  _user.location.coords.heading -
                  bearing
                }deg)`;
              }
            }
          }
        }
      }
    }

    // add labels to markers
    if (localShowPersonnelLabels.current) {
      let entity = null;
      let name = '';
      if (id === 'you') {
        entity = userValue.user;
      } else {
        const unit = localPersonnel.current.find((p) => p.id === id);
        if (unit) {
          entity = unit;
        }
      }

      if (entity) {
        const popupId = `unit-label-${entity.id}`;

        if (!Object.keys(popups.current).find((k) => k.includes(popupId))) {
          if (localDebug.current) {
            log.debug('adding popup to marker', entity);
          }

          name = displayName(entity);

          let activity = 'Stationary';
          let activityIcon = 'street-view';

          if (entity?.location) {
            switch (entity?.location.activity?.type) {
              case 'on_foot':
                activity = 'On Foot';
                activityIcon = 'street-view';
                break;
              case 'walking':
                activity = 'Walking';
                activityIcon = 'walking';
                break;
              case 'running':
                activity = 'Running';
                activityIcon = 'running';
                break;
              case 'in_vehicle':
                activity = 'In Vehicle';
                activityIcon = 'car';
                break;
              case 'on_bicycle':
                activity = 'On Bicycle';
                activityIcon = 'bicycle';
                break;
              default:
                break;
            }
          }

          if (
            activity === 'Stationary' &&
            entity?.location?.coords?.speed > 150
          ) {
            activity = 'In Aircraft';
            activityIcon = 'plane';
          }

          //const disconnectedDur = 60 * 2 * 1000;
          //const now = Date.now();
          const timestamp = dayjs(entity?.timestamp * 1000);
          //const timeDifference = now - timestamp; //const isDisconnected = timeDifference > disconnectedDur;
          //const isDisconnected = timeDifference > disconnectedDur;
          const isDisconnected = false;

          addPopupToMarker(
            popupId,
            <div className="user-tooltip">
              {isDisconnected && (
                <div
                  className={getClassNames({
                    row: true,
                    'unit-disconnected': true,
                  })}
                >
                  <div className="disconnected-icon">
                    <img src={svgDisconnected} alt="disconnected" />
                  </div>
                  <div className="disconnected-text">NO SIGNAL</div>
                  <div
                    style={{
                      color: 'lightgrey',
                      fontWeight: 'normal',
                    }}
                  >
                    ({timeago.format(timestamp.toDate())})
                  </div>
                </div>
              )}
              <div className="row">
                <div className="user-icon">
                  <FontAwesomeIcon
                    icon={activityIcon}
                    style={{ height: '16px', width: '16px' }}
                  />
                </div>
                <div className="user-name">{name}</div>
                {!!entity.guest && (
                  <div
                    style={{
                      color: 'lightgrey',
                      fontWeight: 'normal',
                    }}
                  >
                    (Guest)
                  </div>
                )}
              </div>
              {!!entity?.role?.role && (
                <div className="row">
                  <div className="fa-layers fa-fw role-icon">
                    <FontAwesomeIcon icon="diamond" size="sm" color="white" />
                    <FontAwesomeIcon
                      icon="diamond"
                      size="sm"
                      transform="shrink-6"
                      color={
                        !!entity.role.color ? entity.role.color : '#000000'
                      }
                    />
                  </div>
                  <div className="role-name">{entity.role.role}</div>
                </div>
              )}
            </div>,
            marker,
            'bottom-left',
            isDisconnected
          );
        } else {
          // open popup if not open
          if (!marker.getPopup()?.isOpen()) {
            marker.togglePopup();
          }
        }
      }
    } else if (!localShowPersonnelLabels.current) {
      clearPopup('unit-label');
    }
  };

  const drawClusterMarker = (newMarkers, feature) => {
    let id = `${feature.properties.cluster_id}`;

    let marker = mapMarkers.current[id];
    if (!marker) {
      if (localDebug.current) {
        log.debug(`creating new cluster marker`);
      }

      marker = mapMarkers.current[id] = clusterMarker(
        map.current,
        `markers`,
        feature
      );

      const userElement = marker
        .getElement()
        .getElementsByClassName('user-cluster')[0];
      const photoElement = marker
        .getElement()
        .getElementsByClassName('photo-cluster')[0];
      const waypointElement = marker
        .getElement()
        .getElementsByClassName('waypoint-cluster')[0];

      if (userElement) {
        userElement.addEventListener('mouseenter', handleClusterHoverIn);
        userElement.addEventListener('mouseleave', handlePersonnelHoverOut);
        userElement.addEventListener('click', (e) =>
          handleClusterClick(e, marker, feature.properties.cluster_id, '')
        );
      }
      if (photoElement) {
        photoElement.addEventListener('mouseenter', handleClusterHoverIn);
        photoElement.addEventListener('mouseleave', handlePersonnelHoverOut);
        photoElement.addEventListener('click', (e) =>
          handleClusterClick(
            e,
            marker,
            feature.properties.cluster_id,
            POI.Photo
          )
        );
      }
      if (waypointElement) {
        waypointElement.addEventListener('mouseenter', (e) =>
          handleClusterHoverIn(e)
        );
        waypointElement.addEventListener('mouseleave', handlePersonnelHoverOut);
        waypointElement.addEventListener('click', (e) =>
          handleClusterClick(
            e,
            marker,
            feature.properties.cluster_id,
            POI.Waypoint
          )
        );
      }
    } else {
      if (localDebug.current) {
        log.debug(`reusing cluster marker`);
      }
      marker.setLngLat(feature.geometry.coordinates);
    }

    newMarkers[id] = marker;

    if (!mapMarkersOnScreen.current[id]) {
      marker.addTo(map.current);
    }

    const el = marker.getElement();

    if (el) {
      el.style.visibility = 'visible';

      if (feature.properties.point_count && marker._pos) {
        el.dataset.pointCount = feature.properties.point_count;

        const numUsers = feature.properties.user_count || 0;
        const numPhotos = feature.properties.photo_count || 0;
        const numWaypoints = feature.properties.waypoint_count || 0;

        const userC = el.getElementsByClassName('user-cluster')[0];
        if (userC) {
          userC.firstElementChild.dataset.count = numUsers;
          userC.dataset.count = numUsers;
          userC.dataset.users = numUsers;
          userC.dataset.photos = numPhotos;
          userC.dataset.waypoints = numWaypoints;
        }

        const photoC = el.getElementsByClassName('photo-cluster')[0];
        if (photoC) {
          photoC.firstElementChild.dataset.count = numPhotos;
          photoC.dataset.count = numPhotos;
          photoC.dataset.users = numUsers;
          photoC.dataset.photos = numPhotos;
          photoC.dataset.waypoints = numWaypoints;
        }

        const waypointC = el.getElementsByClassName('waypoint-cluster')[0];
        if (waypointC) {
          waypointC.firstElementChild.dataset.count = numWaypoints;
          waypointC.dataset.count = numWaypoints;
          waypointC.dataset.users = numUsers;
          waypointC.dataset.photos = numPhotos;
          waypointC.dataset.waypoints = numWaypoints;
        }
      }
    }
  };

  const drawMarker = (newMarkers, feature) => {
    if (feature.properties.cluster_id) {
      drawClusterMarker(newMarkers, feature);
    } else if (feature.properties.id === userValue.user.id) {
      drawUserMarker(newMarkers, feature);
    } else if (!!feature.properties.poiType) {
      if (showWaypoints && feature.properties.poiType === POI.Waypoint) {
        drawPoiMarker(newMarkers, feature);
      }
      if (showPhotos && feature.properties.poiType === POI.Photo) {
        drawPoiMarker(newMarkers, feature);
      }
    } else if (!!feature.properties.milspec) {
      if (showSymbols) {
        drawSymbolMarker(newMarkers, feature);
      }
    } else {
      drawUserMarker(newMarkers, feature);
    }
  };

  const drawMarkers = () => {
    if (localDebug.current) {
      console.time('render');
    }

    if (!paused.current && map.current) {
      let newMarkers = {};

      // only render markers if at correct zoom
      if (map.current.getZoom() >= ZOOM_THRESHOLD) {
        const roleIds = ['self', 'markers'];

        for (const role of roleIds) {
          const features = map.current.querySourceFeatures(role, {});

          for (const feature of features) {
            drawMarker(newMarkers, feature);
          }
        }
      } else {
        const features = map.current.querySourceFeatures(
          'mission-area-points',
          {}
        );
        for (const feature of features) {
          const id = feature.properties.cluster_id || feature.properties.id;

          let marker = mapMarkers.current[id];
          if (!marker) {
            marker = mapMarkers.current[id] = missionClusterMarker(
              map.current,
              'mission-area-points',
              feature
            );
          } else {
            // for (const el of document.getElementsByClassName('html-marker')) {
            //   el.className = el.className.replace(' selected', '');
            //   if (el.id === id) {
            //     el.className = el.className + ' selected';
            //   }
            // }
          }

          newMarkers[id] = marker;

          if (!mapMarkersOnScreen.current[id]) {
            marker.addTo(map.current);
          }

          if (marker.getElement()) {
            marker.getElement().style.visibility = 'visible';
          }
        }
      }

      for (const id in mapMarkersOnScreen.current) {
        if (!newMarkers[id]) {
          // ensure marker popups are destroyed when they leave the screen
          for (const k in popups.current) {
            if (k.includes(`unit-label-${id}`)) {
              const p = popups.current[k];
              if (p) {
                p.remove();
                delete popups.current[k];
              }
            }
          }

          const el = mapMarkersOnScreen.current[id].getElement();
          if (el) {
            el.style.visibility = 'hidden';
          }

          mapMarkersOnScreen.current[id].remove();
        }
      }

      mapMarkersOnScreen.current = newMarkers;
    }

    if (localDebug.current) {
      console.timeEnd('render');
    }
  };

  const setSelectedUser = (userToSelect, location) => {
    if (!isMultiple) {
      clearPopup();
      setUser(userToSelect);
      setLocation(location);
      sidebars.open(SIDEBARS.missionUser);
    }
  };

  const updateSparkDetails = () => {
    if (ignitionSeconds < 30 * 60) {
      setWindbBearingDisplay('300° Speed 30km/h');
    } else if (ignitionSeconds < 60 * 60) {
      setWindbBearingDisplay('285 Speed 42km/h');
    } else {
      setWindbBearingDisplay('273 Speed 50km/h');
    }
  };

  const onChangeIgnitionMinutes = (e) => {
    const sec = parseInt(e.target.value);
    const min = Math.floor(sec / 60);
    const sec2 = sec % 60;

    var filterMin = ['<=', ['number', ['get', 'value']], sec];
    var filterSec = ['<=', ['number', ['get', 'Arrival Time']], sec];

    map.current.setFilter('isochrones', ['all', filterMin]);
    map.current.setFilter('fireSpread', ['all', filterSec]);

    setIgnitionSeconds(sec);

    //straight from Kavs code... refactor this to use moment
    if (min < 10) {
      if (sec2 < 10) {
        setIgnitionDisplay('0' + min + ':' + '0' + sec2 + ' minutes');
      } else {
        setIgnitionDisplay('0' + min + ':' + sec2 + ' minutes');
      }
    } else {
      if (sec2 < 10) {
        setIgnitionDisplay(min + ':' + '0' + sec2 + ' minutes');
      } else {
        setIgnitionDisplay(min + ':' + sec2 + ' minutes');
      }
    }
  };

  //temporary tracks feature
  const addTrace = (id) => {
    if (trackData) {
      var selected = trackData.features.find((x) => x.id === id);
      const source = map.current.getSource('startFinishPoints');
      if (selected) {
        var start = selected.geometry.coordinates[0];
        var end = selected.geometry.coordinates[selected.geometry.coordinates.length - 1]; //prettier-ignore

        source.setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: start,
              },
            },
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: end,
              },
            },
          ],
        });

        //fix to bounds

        var bounds = selected.geometry.coordinates.reduce(function (
          bounds,
          coord
        ) {
          return bounds.extend(coord);
        },
        new mapboxgl.LngLatBounds(selected.geometry.coordinates[0], selected.geometry.coordinates[0]));

        map.current.fitBounds(bounds, {
          padding: 20,
        });
      } else {
        source.setData({
          type: 'FeatureCollection',
          features: [],
        });
      }
    }
  };

  useEffect(() => {
    console.log('selectedStateId', selectedStateId);
    console.log('prevSelectedStateId', prevSelectedStateId);

    if (map.current) {
      if (prevSelectedStateId !== 0) {
        map.current.setFeatureState(
          { source: 'tracks', id: prevSelectedStateId },
          { selected: false }
        );
      }
      map.current.setFeatureState(
        { source: 'tracks', id: selectedStateId },
        { selected: true }
      );
    }
    addTrace(selectedStateId);
    // eslint-disable-next-line
  }, [selectedStateId, prevSelectedStateId]);

  useEffect(() => {
    console.log('hoveredStateId', hoveredStateId);
    console.log('prevHoverStateId', prevHoverStateId);

    if (map.current) {
      if (prevHoverStateId !== 0) {
        map.current.setFeatureState(
          { source: 'tracks', id: prevHoverStateId },
          { hover: false }
        );
      }
      map.current.setFeatureState(
        { source: 'tracks', id: hoveredStateId },
        { hover: true }
      );
    }
  }, [hoveredStateId, prevHoverStateId]);

  const plotTracks = async () => {
    if (localShowTracks.current) {
      //check if it exists.. redraw probably needs some refactoring...
      if (map.current) {
        if (!!!localTrackData.current) {
          const data = await getUserTracks(localMissions.current[0].id, false);
          localTrackData.current = data;
          setTrackData(data);
          // save full coordinate list for later
        }

        // Always remove the layer first to avoid an error
        if (map.current.getSource('startFinishPoints')) {
          map.current.removeSource('startFinishPoints');
        }
        if (map.current.getSource('tracks')) {
          map.current.removeSource('tracks');
        }

        if (map.current.getLayer('tracks-layer')) {
          map.current.removeLayer('tracks-layer');
        }
        if (map.current.getLayer('selected-track')) {
          map.current.removeLayer('selected-track');
        }

        if (!map.current.getSource('startFinishPoints')) {
          map.current.addSource('startFinishPoints', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          });
        }

        if (!map.current.getSource('tracks')) {
          map.current.addSource('tracks', {
            type: 'geojson',
            data: localTrackData.current,
          });
        }

        if (!map.current.getLayer('tracks-layer')) {
          map.current.addLayer({
            id: 'tracks-layer',
            type: 'line',
            source: 'tracks',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                '#bf39bf',
                '#BF93E4',
              ],
              'line-gap-width': 0,
              //prettier-ignore
              'line-width': [
                'case', ['boolean', ['feature-state', 'hover'], false], 8,
                        ['boolean', ['feature-state', 'selected'], false], 8,
                4,
              ],
            },
          });
        }

        if (!map.current.getLayer('selected-track')) {
          map.current.addLayer({
            id: 'selected-track',
            type: 'circle',
            source: 'startFinishPoints',
            paint: {
              'circle-radius': 8,
              'circle-color': '#000000',
              'circle-stroke-width': 3,
              'circle-stroke-color': '#ffffff',
            },
          });
        }

        map.current.on('mousemove', 'tracks-layer', (e) => {
          if (e.features.length > 0) {
            var id = e.features[0].id;
            setHoveredStateId(id);
          }
        });

        map.current.on('mouseleave', 'tracks-layer', (e) => {
          setHoveredStateId(0);
        });

        map.current.on('click', 'tracks-layer', (e) => {
          var id = e.features[0].id;
          var uid = e.features[0].properties.uid;

          //open the side bar
          const entity = localPersonnel.current.find((p) => p.id === uid);

          console.log(entity);
          log.debug('unit click', entity);

          setSelectedUser(entity, toLngLat(entity?.coords), true);

          setSelectedStateId(id);
        });
      }
    } else {
      if (map.current.getLayer('tracks-layer')) map.current.removeLayer('tracks-layer'); //prettier-ignore
      if (map.current.getLayer('selected-track')) map.current.removeLayer('selected-track'); //prettier-ignore
      if (map.current.getSource('startFinishPoints')) map.current.removeSource('startFinishPoints'); //prettier-ignore
      if (map.current.getSource('tracks')) map.current.removeSource('tracks'); //prettier-ignore
    }
  };

  const plotMarkers = async () => {
    log.debug('plotMarkers', localPersonnel.current);

    const currentUser =
      localPersonnel.current.find((u) => u.id === userValue.user.id) ||
      userValue.user;

    const allMarkers = [];

    plotCluster('self', '', [currentUser], false);

    // get all users in each team
    const allUsers = localPersonnel.current
      .filter((u) => u.id !== userValue.user.id)
      .map((u) => {
        u.name = displayName(u);
        u.fallback = [u.firstname, u.lastname];
        u.color = u.role?.color || selfColour;
        return u;
      });

    allMarkers.push(...allUsers);

    if (localPointsOfInterest.current) {
      allMarkers.push(...localPointsOfInterest.current);
    }

    if (localSymbols.current) {
      allMarkers.push(...localSymbols.current);
    }

    plotCluster('markers', '', allMarkers, showClustering);
  };

  const debouncedPlotMarkers = useCallback(
    debounce(() => plotMarkers(), 1000),
    []
  );

  const removeGeocoder = () => {
    const el = document.getElementsByClassName('mapboxgl-ctrl-geocoder')[0];
    if (el) {
      const ev = () => {
        map.current.removeControl(showSearch.current);
        showSearch.current = null;
        el.removeEventListener('webkitAnimationEnd', ev);
      };
      el.addEventListener('webkitAnimationEnd', ev);
      el.classList.add('pending-remove');
    }

    const el2 = document.getElementsByClassName('top-right-1')[0];
    if (el2) {
      el2.classList.remove('hide');
      el2.classList.remove('pending-remove');
    }

    const el3 = document.getElementsByClassName('top-right-1')[1];
    if (el3) {
      const ev = () => {
        el3.classList.add('hide');
        el3.removeEventListener('webkitAnimationEnd', ev);
      };
      el3.addEventListener('webkitAnimationEnd', ev);
      el3.classList.add('pending-remove');
    }

    if (localSearchMarker.current.length > 0) {
      for (const i of localSearchMarker.current) {
        i.remove();
        localSearchMarker.current = [];
      }
    }
    for (const k in popups.current) {
      if (k.includes(`search`)) {
        const p = popups.current[k];
        if (p) {
          p.remove();
          delete popups.current[k];
        }
      }
    }
    clearPopup('search');
    localSearchResult.current = null;
  };

  const addGeocoder = () => {
    const el = document.getElementsByClassName('top-right-1')[0];
    if (el) {
      const ev = () => {
        el.classList.add('hide');
        el.removeEventListener('webkitAnimationEnd', ev);
      };
      el.addEventListener('webkitAnimationEnd', ev);
      el.classList.add('pending-remove');
    }

    const el3 = document.getElementsByClassName('top-right-1')[1];
    if (el3) {
      el3.classList.remove('hide');
      el3.classList.remove('pending-remove');
    }

    const geocoder = new MapboxGeocoder({
      accessToken: mapConfig.mapBoxKey,
      mapboxgl: mapboxgl,
      marker: false,
      countries: 'au',
      limit: 20,
      localGeocoder: (txt) => {
        const results = [];

        const ltxt = txt.toLowerCase().trim();
        log.debug('searching...', ltxt);

        if ('you'.includes(ltxt)) {
          const unit = localPersonnel.current.find(
            (u) => u.id === userValue.user.id
          );
          if (unit && unit.coords) {
            results.push({
              type: 'Feature',
              id: 'you',
              text: 'You',
              place_name: 'Your Current Location',
              place_type: ['place'],
              // bbox: [
              //   -97.9383829999999, 30.098659, -97.5614889999999, 30.516863,
              // ],
              center: [unit.coords.longitude, unit.coords.latitude],
              geometry: {
                type: 'Point',
                coordinates: [unit.coords.longitude, unit.coords.latitude],
              },
              properties: {
                title: 'You',
                type: 'user',
                score: 1,
              },
            });
          }
        }

        const foundGeofences = localGeofences.current.filter(
          (u) => u.bounds && u.description.toLowerCase().includes(ltxt)
        );
        log.debug('geofences', foundGeofences);
        results.push(
          ...foundGeofences.map((u) => {
            return {
              type: 'Feature',
              id: u.id,
              text: u.description,
              place_name: `${u.description}, Shape`,
              place_type: ['place'],
              bbox: bboxPad(bbox(u.bounds), 1),
              geometry: u.bounds,
              properties: {
                title: displayName(u),
                type: FeatureType.Geofence,
                score: 1,
              },
            };
          })
        );

        const foundPersonnel = localPersonnel.current.filter(
          (u) =>
            u.coords &&
            `${u.firstname} ${u.lastname}`.toLowerCase().includes(ltxt)
        );
        log.debug('personnel', foundPersonnel);
        results.push(
          ...foundPersonnel.map((u) => {
            return {
              type: 'Feature',
              id: u.id,
              text: displayName(u),
              place_name: `${displayName(u)}, ${u.role?.role || 'User'}`,
              place_type: ['place'],
              // bbox: [
              //   -97.9383829999999, 30.098659, -97.5614889999999, 30.516863,
              // ],
              center: toArray(u.coords),
              geometry: {
                type: 'Point',
                coordinates: toArray(u.coords),
              },
              properties: {
                title: displayName(u),
                type: 'personnel',
                score: 1,
              },
            };
          })
        );

        const foundRoles = [...localRoles.current].filter(
          (u) => u.role && u.role.toLowerCase().includes(ltxt)
        );
        log.debug('roles', foundRoles);
        results.push(
          ...foundRoles
            .filter((u) => {
              const unitPersonnel = localPersonnel.current.filter(
                (p) => p.coords && p.roleId === u.id
              );
              return unitPersonnel.length;
            })
            .map((u) => {
              const bounds = {
                coordinates: [
                  localPersonnel.current
                    .filter((p) => p.coords && p.roleId === u.id)
                    .map((p) => toArray(p.coords)),
                ],
                type: 'Polygon',
              };
              return {
                type: 'Feature',
                id: u.id,
                text: u.role,
                place_name: `${u.role}, Role`,
                place_type: ['place'],
                bbox: bboxPad(bbox(bounds), 1),
                properties: {
                  title: u.role,
                  type: 'role',
                  score: 1,
                },
              };
            })
        );

        const allUsersInTeam = [];
        for (const team of localTeams.current) {
          allUsersInTeam.push(...(team.users || []));
        }
        const foundTeams = [...localTeams.current].filter(
          (u) => u.team && u.team.toLowerCase().includes(ltxt)
        );
        log.debug('teams', foundTeams);
        results.push(
          ...foundTeams
            .filter((u) => {
              const unitPersonnel = localPersonnel.current.filter(
                (p) =>
                  p.coords &&
                  (u.users
                    ? u.users.includes(p.id)
                    : !allUsersInTeam.includes(p.id))
              );
              return unitPersonnel.length;
            })
            .map((u) => {
              const bounds = {
                coordinates: [
                  localPersonnel.current
                    .filter(
                      (p) =>
                        p.coords &&
                        (u.users
                          ? u.users.includes(p.id)
                          : !allUsersInTeam.includes(p.id))
                    )
                    .map((p) => toArray(p.coords)),
                ],
                type: 'Polygon',
              };
              return {
                type: 'Feature',
                id: u.id,
                text: u.team,
                place_name: `${u.team}, Team`,
                place_type: ['place'],
                bbox: bboxPad(bbox(bounds), 1),
                properties: {
                  title: u.team,
                  type: 'team',
                  score: 1,
                },
              };
            })
        );

        const foundPoi = [...localPointsOfInterest.current].filter(
          (u) => u.name && u.name.toLowerCase().includes(ltxt)
        );
        log.debug('poi', foundPoi);
        results.push(
          ...foundPoi.map((u) => {
            return {
              type: 'Feature',
              id: u.id,
              text: u.name,
              place_name: `${u.name}, ${u.type}`,
              place_type: ['place'],
              center: toArray(u.location),
              geometry: {
                type: 'Point',
                coordinates: toArray(u.location),
              },
              properties: {
                title: u.name,
                type: u.type,
                score: 1,
              },
            };
          })
        );

        return results;
      },
      collapsed: false,
      placeholder: 'Search location...',
    });

    geocoder.on('result', (e) => {
      addSearchMarker(
        e.result.geometry.coordinates,
        e.result.place_name.substring(0, e.result.place_name.indexOf(','))
      );
      localSearchResult.current = e.result;
    });

    map.current.addControl(geocoder, 'top-right');
    geocoder._inputEl.focus();

    showSearch.current = geocoder;
  };

  const initMap = async () => {
    const firstWithBounds =
      localMissions.current.length === 1
        ? localMissions.current[0]
        : localMissions.current.filter((m) => !!m.bounds)[0];
    log.debug('firstWithBounds', firstWithBounds);

    if (!firstWithBounds?.location && navigator.geolocation) {
      log.debug('no location, locating...');
      toast.promise(
        new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              if (map.current && position?.coords) {
                map.current.flyTo({
                  center: toLngLat(position.coords),
                });
              }
              resolve(position?.coords);
            },
            (err) => {
              reject(err.message);
            }
          );
        }),
        {
          loading: 'Finding your location...',
          success: 'Location found',
          error: 'Could not find your location',
        }
      );
    }

    return new Promise((resolve, reject) => {
      log.debug(
        mapContainer.current,
        selectedStyle || styles[0]?.url,
        firstWithBounds
      );
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: selectedStyle || styles[0]?.url,
        center: [
          firstWithBounds.location?.longitude || 0,
          firstWithBounds.location?.latitude || 0,
        ],
        zoom: firstWithBounds.location?.zoom || 14,
        accessToken: mapConfig.mapBoxKey,
        attributionControl: false,
        interactive: true,
      });

      map.current.once('load', () => {
        log.debug('on load');

        const ruler = new RulerControl();
        map.current.addControl(ruler, 'bottom-right');

        const scale = new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: 'imperial',
        });
        map.current.addControl(scale, 'bottom-right');

        scale.setUnit('metric');

        map.current.addControl(
          new mapboxgl.NavigationControl(),
          'bottom-right'
        );

        map.current.loadImage(arrowBlack, (error, image) => {
          if (error) throw error;
          if (!map.current.hasImage('arrow-head')) {
            // Add the image to the map style.
            map.current.addImage('arrow-head', image, { sdf: true });
          }
        });
        resolve();
      });
    });
  };

  const handlePersonnelHoverIn = (e, marker) => {
    if (!localShowPersonnelLabels.current) {
      const id = marker._element.id;
      if (marker) {
        let entity = null;
        let name = '';
        if (id === 'you') {
          entity = userValue.user;
        } else {
          const unit = localPersonnel.current.find((p) => p.id === id);
          if (unit) {
            entity = unit;
          }
        }

        if (entity) {
          name = displayName(entity);
        }

        let popupId = 'unit-hover';
        if (entity?.id === userValue.user.id) {
          popupId += '-self';
        }

        let activity = 'Stationary';
        let activityIcon = 'street-view';

        if (entity?.location) {
          switch (entity?.location.activity?.type) {
            case 'on_foot':
              activity = 'On Foot';
              activityIcon = 'street-view';
              break;
            case 'walking':
              activity = 'Walking';
              activityIcon = 'walking';
              break;
            case 'running':
              activity = 'Running';
              activityIcon = 'running';
              break;
            case 'in_vehicle':
              activity = 'In Vehicle';
              activityIcon = 'car';
              break;
            case 'on_bicycle':
              activity = 'On Bicycle';
              activityIcon = 'bicycle';
              break;
            default:
              break;
          }
        }

        if (
          activity === 'Stationary' &&
          entity?.location?.coords?.speed > 150
        ) {
          activity = 'In Aircraft';
          activityIcon = 'plane';
        }

        //const disconnectedDur = 60 * 2 * 1000;
        //const now = Date.now();
        const timestamp = dayjs(entity?.timestamp * 1000);
        //const timeDifference = now - timestamp;
        //const isDisconnected = timeDifference > disconnectedDur;
        const isDisconnected = false;

        addPopupToMarker(
          popupId,
          <div className="user-tooltip">
            {isDisconnected && (
              <div
                className={getClassNames({
                  row: true,
                  'unit-disconnected': true,
                })}
              >
                <div className="disconnected-icon">
                  <img src={svgDisconnected} alt="disconnected" />
                </div>
                <div className="disconnected-text">NO SIGNAL</div>
                <div
                  style={{
                    color: 'lightgrey',
                    fontWeight: 'normal',
                  }}
                >
                  ({timeago.format(timestamp.toDate())})
                </div>
              </div>
            )}
            <div className="row">
              <div className="user-icon">
                <FontAwesomeIcon
                  icon={activityIcon}
                  style={{ height: '16px', width: '16px' }}
                />
              </div>
              <div className="user-name">{name}</div>
              {!!entity.guest && (
                <div
                  style={{
                    color: 'lightgrey',
                    fontWeight: 'normal',
                  }}
                >
                  (Guest)
                </div>
              )}
            </div>
            {!!entity?.role?.role && (
              <div className="row">
                <div className="fa-layers fa-fw role-icon">
                  <FontAwesomeIcon icon="diamond" size="sm" color="white" />
                  <FontAwesomeIcon
                    icon="diamond"
                    size="sm"
                    transform="shrink-6"
                    color={!!entity.role.color ? entity.role.color : '#000000'}
                  />
                </div>
                <div className="role-name">{entity.role.role}</div>
              </div>
            )}
          </div>,
          marker,
          'bottom-left',
          isDisconnected
        );
      }

      // eslint-disable-next-line
      // const groupId = e.features[0].properties.groupId;
      // const coordinates = e.features[0].geometry.coordinates.slice();
      // const name = e.features[0].properties.name;
      // const photoUrl = e.features[0].properties.photoUrl;
      // const fallback = JSON.parse(e.features[0].properties.fallback || '[]');
    }
  };

  const handleClusterHoverIn = (e) => {
    const id = parseInt(e.target.parentElement.id);
    const marker = mapMarkers.current[id];

    const type = e.target.classList.contains('waypoint-cluster')
      ? POI.Waypoint
      : e.target.classList.contains('photo-cluster')
      ? POI.Photo
      : 'user';

    const coordinates = marker.getLngLat();

    const users = e.target.dataset.users;
    const photos = e.target.dataset.photos;
    const waypoints = e.target.dataset.waypoints;

    let clusters = 0;
    if (users > 0) clusters++;
    if (photos > 0) clusters++;
    if (waypoints > 0) clusters++;

    const point_count = marker.getElement()?.dataset?.pointCount || 10;

    log.debug('cluster hover', e, marker, e.point, point_count);

    getClusterLeavesById(map.current, 'markers', id, point_count)
      .then((features) => {
        const _included = sortBy(
          features
            .filter((f) =>
              type
                ? f.properties.poiType === type ||
                  f.properties.markerType.toLowerCase() === type
                : !f.properties.poiType
            )
            .map((f) => f.properties),
          ['name'],
          ['asc']
        );
        log.debug('included', _included);
        addPopup(
          'cluster-hover',
          <div className="cluster-tooltip">
            {type === 'user' && (
              <div
                className="row"
                style={{
                  padding: '0px 5px 0px 5px',
                  justifyContent: 'flex-start',
                  backgroundColor: 'rgba(80, 83, 102, 1)',
                }}
              >
                <div>
                  <FontAwesomeIcon icon="user-group" />
                </div>
                <div>USERS</div>
              </div>
            )}
            {type === 'user' &&
              _included.slice(0, 3).map((i) => {
                let activity = 'Stationary';
                let activityIcon = 'street-view';

                if (i?.location) {
                  switch (i?.location.activity?.type) {
                    case 'on_foot':
                      activity = 'On Foot';
                      activityIcon = 'street-view';
                      break;
                    case 'walking':
                      activity = 'Walking';
                      activityIcon = 'walking';
                      break;
                    case 'running':
                      activity = 'Running';
                      activityIcon = 'running';
                      break;
                    case 'in_vehicle':
                      activity = 'In Vehicle';
                      activityIcon = 'car';
                      break;
                    case 'on_bicycle':
                      activity = 'On Bicycle';
                      activityIcon = 'bicycle';
                      break;
                    default:
                      break;
                  }
                }
                if (
                  activity === 'Stationary' &&
                  i?.location?.coords?.speed > 150
                ) {
                  activity = 'In Aircraft';
                  activityIcon = 'plane';
                }

                return (
                  <div className="row">
                    <div className="user-icon">
                      <FontAwesomeIcon icon={activityIcon} />
                    </div>
                    <div className="user-name">{i.name}</div>
                  </div>
                );
              })}
            {type === POI.Photo && (
              <div
                className="row"
                style={{
                  padding: '0px 5px 0px 5px',
                  justifyContent: 'flex-start',
                  backgroundColor: 'rgba(80, 83, 102, 1)',
                }}
              >
                <div>
                  <FontAwesomeIcon icon="image" />
                </div>
                <div>IMAGES</div>
              </div>
            )}
            {type === POI.Photo &&
              _included.slice(0, 3).map((i) => {
                return (
                  <div className="row">
                    <div className="image-icon">
                      <img src={i.reference} alt={i.name} />
                    </div>
                    <div className="image-name">{i.name}</div>
                  </div>
                );
              })}
            {type === POI.Waypoint && (
              <div
                className="row"
                style={{
                  padding: '0px 5px 0px 5px',
                  justifyContent: 'flex-start',
                  backgroundColor: 'rgba(80, 83, 102, 1)',
                }}
              >
                <div>
                  <FontAwesomeIcon icon="map-pin" />
                </div>
                <div>WAYPOINTS</div>
              </div>
            )}
            {type === POI.Waypoint &&
              _included.slice(0, 3).map((i) => {
                return (
                  <div className="row">
                    <div className="waypoint-icon">{i.reference}</div>
                    <div className="waypoint-name">{i.name}</div>
                  </div>
                );
              })}
            {_included.length > 3 && (
              <div className="row">
                <div className="more-icon">
                  {type === POI.Waypoint && <FontAwesomeIcon icon="map-pin" />}
                  {type === POI.Photo && <FontAwesomeIcon icon="image" />}
                  {type === 'user' && <FontAwesomeIcon icon="user-group" />}
                </div>
                <div>{'+ ' + (_included.length - 3) + ' more'}</div>
              </div>
            )}
          </div>,
          coordinates,
          'bottom-left',
          [0, clusters > 2 ? -64 : -36],
          _included.length
        );
      })
      .catch();
  };

  const handleMissionClusterHoverIn = (e) => {
    // only do anything if single mission
    if (localMissions.current.length > 1) {
      if (e.features[0].properties.id) {
        const mission = localMissions.current.find(
          (m) => m.id === e.features[0].properties.id
        );

        if (mission) {
          getCachedOrg(mission.organisationId).then((organisation) => {
            if (organisation) {
              const coordinates = e.features[0].geometry.coordinates.slice();
              const name = mission.missionName;
              const photoUrl = organisation.photoUrl;
              const fallback = [organisation.name];

              addPopup(
                'mission-cluster-hover',
                <div className="mission-tooltip" style={{ width: '180px' }}>
                  {!!mission.staticMapUrl && (
                    <img
                      src={mission.staticMapUrl}
                      style={{ width: '100%', height: '100%' }}
                      alt="mission map"
                    />
                  )}
                  <div>
                    <div>
                      {name}
                      <span>{mission.locationDescription}</span>
                    </div>
                    {!!(photoUrl || fallback.length) && (
                      <Avatar entity={organisation} size="1rem" />
                    )}
                  </div>
                </div>,
                coordinates,
                'bottom-left'
              );
            }
          });
        }
      } else {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const name = pluralize(
          secrets.EVENT_NAME,
          e.features[0].properties.point_count,
          true
        );

        addPopup(
          'mission-cluster-hover',
          <div className="mission-tooltip">
            <div>
              <div>{name}</div>
            </div>
          </div>,
          coordinates,
          'bottom-left'
        );
      }
    }
  };

  const handlePersonnelHoverOut = (e) => {
    clearPopup('mission-cluster-hover');
    clearPopup('cluster-hover');
    if (!localShowPersonnelLabels.current) {
      clearPopup('unit-hover');
      clearPopup('unit-hover-self');
    }
  };

  const handleMissionClusterHoverOut = (e) => {
    clearPopup('mission-cluster-hover');
  };

  const clearTouch = () => {
    clearTimeout(touchTimer.current);
  };

  const handlePersonnelTouch = (e, marker) => {
    if ((e.originalEvent?.touches || e.touches)?.length > 1) {
      return;
    }
    clearTouch();
    touchTimer.current = setTimeout(() => {
      handlePersonnelContext(e, marker);
    }, 500);
  };

  const handlePersonnelContext = (e, marker) => {
    if (!localFormType.current) {
      e.preventDefault();
      e.stopPropagation();

      log.debug('unit context menu', e, marker);

      const entity = localPersonnel.current.find(
        (p) => p.id === marker.getElement().id
      );

      let id = 'unit-context';
      if (entity?.id === userValue.user.id) {
        id += '-self';
      }

      addPopup(
        id,
        UnitContextMenu(entity),
        toLngLat(entity?.coords),
        'top-left'
      );
    }
  };

  const handlePersonnelClick = (e, marker) => {
    e.preventDefault();
    e.stopPropagation();

    log.debug('unit click', e, marker);

    const entity = localPersonnel.current.find(
      (p) => p.id === marker.getElement().id
    );

    log.debug('unit click', entity);

    setSelectedUser(entity, toLngLat(entity?.coords));
  };

  const handleClusterClick = (e, marker, clusterId, poiType) => {
    e.preventDefault();
    e.stopPropagation();

    const point_count = marker.getElement()?.dataset?.pointCount || 10;

    log.debug('cluster click', e, marker, e.point, point_count);

    getClusterLeavesById(map.current, 'markers', clusterId, point_count)
      .then((features) => {
        clearPopup();
        const _included = features
          .filter((f) =>
            poiType ? f.properties.poiType === poiType : !f.properties.poiType
          )
          .map((f) => f.properties);

        let coords = [];
        for (const i of _included) {
          if (i.markerType?.toLowerCase() === 'user') {
            coords.push([
              i.location.coords.longitude,
              i.location.coords.latitude,
            ]);
          } else {
            coords.push([i.location.longitude, i.location.latitude]);
          }
        }

        const bounds = coords.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

        map.current.fitBounds(bounds, { padding: 400 });
      })
      .catch();
  };

  const handleContextMenuClick = (subContext, loc, offset) => {
    const keys = Object.keys(popups.current);
    for (const k of keys) {
      if (k.includes('sub-menu')) {
        clearPopup('sub-menu');
      }
    }
    addPopup('sub-menu', subContext, loc, 'top-left', offset);
  };

  const handleSymbolHoverIn = (e) => {
    if (
      localMode.current === FeatureType.Shape ||
      localMode.current === FeatureType.Mission
    ) {
      map.current.getCanvas().style.cursor = 'crosshair';
    }
    const id = e.target.id;
    const marker = mapMarkers.current[`${id}`];
    log.debug('poi hover in', e, marker);
    if (marker) {
      const coordinates = marker.getLngLat();
      const symbol = localSymbols.current.find((s) => s.id === id);
      addSymbolPopup(symbol, coordinates);
    }
  };

  const addSymbolPopup = (symbol, coordinates) => {
    log.debug('addSymbolPopup', symbol);

    if (
      symbol?.milspec?.options?.uniqueDesignation ||
      symbol?.milspec?.options?.higherFormation ||
      symbol?.milspec?.options?.dtg ||
      symbol?.milspec?.options?.dtg ||
      symbol?.milspec?.options?.speed ||
      symbol?.milspec?.options?.direction
    ) {
      addPopup(
        `symbol-hover`,
        <div
          className={getClassNames({
            'symbol-tooltip': true,
          })}
        >
          <div className="desc">
            {symbol?.milspec?.options?.uniqueDesignation && (
              <div className="desc-content">
                {symbol.milspec.options.uniqueDesignation}
              </div>
            )}
            {symbol?.milspec?.options?.higherFormation && (
              <div className="desc-content">
                {symbol.milspec.options.higherFormation}
              </div>
            )}
            {symbol?.milspec?.options?.dtg && (
              <div className="desc-content">{symbol.milspec.options.dtg}</div>
            )}
            {(symbol?.milspec?.options?.speed ||
              symbol?.milspec?.options?.direction) && (
              <div className="desc-content">
                {symbol?.milspec?.options?.speed &&
                  symbol?.milspec?.options?.direction &&
                  `${symbol.milspec.options.speed}km/h / ${symbol.milspec.options.direction}°`}
                {!!!symbol?.milspec?.options?.speed &&
                  symbol?.milspec?.options?.direction &&
                  `${symbol.milspec.options.direction}°`}
                {symbol?.milspec?.options?.speed &&
                  !!!symbol?.milspec?.options?.direction &&
                  `${symbol.milspec.options.speed}km/h`}
              </div>
            )}
          </div>
        </div>,
        coordinates,
        'left'
      );
    }
  };

  const handleSymbolHoverOut = (e) => {
    clearPopup('symbol-hover');
  };

  const handlePOIHoverIn = (e) => {
    if (
      localMode.current === FeatureType.Shape ||
      localMode.current === FeatureType.Mission
    ) {
      map.current.getCanvas().style.cursor = 'crosshair';
    }
    const id = e.target.id;
    const marker = mapMarkers.current[`${id}`];
    log.debug('poi hover in', e, marker);
    if (marker) {
      const coordinates = marker.getLngLat();
      const poi = localPointsOfInterest.current.find((p) => p.id === id);

      getCachedOrg(localMissions.current[0].organisationId).then(
        (organisation) => {
          addPOIPopup(poi, coordinates, organisation);
        }
      );
    }
  };

  const addPOIPopup = (poi, coordinates, organisation) => {
    log.debug('addPOIPopup', poi);

    const sensitiveContent = poi.meta?.adult?.isAdultContent
      ? 'Adult Content'
      : poi.meta?.adult?.isRacyContent
      ? 'Racy Content'
      : poi.meta?.adult?.isGoryContent
      ? 'Contains Gore'
      : '';

    addPopup(
      `poi-${poi.type}-hover`,
      <div
        className={getClassNames({
          'poi-tooltip': true,
          censored: sensitiveContent,
        })}
      >
        {poi.type === POI.Photo && poi.reference && (
          <>
            <img
              className="photo"
              src={poi.thumb || poi.reference}
              alt={poi.name}
            />
            <div className="censored-text">
              <FontAwesomeIcon icon="eye-slash" size="2x"></FontAwesomeIcon>
              <div>Sensitive Content</div>
              <span>{sensitiveContent}</span>
            </div>
          </>
        )}
        <div className="desc">
          <div className="desc-content">
            {poi.name}
            <span>
              {poi.type === POI.Photo ? 'Uploaded' : 'Set'}{' '}
              {timeago.format(poi._ts * 1000)}
            </span>
          </div>
          {!!organisation && <Avatar entity={organisation} size="1.875rem" />}
        </div>
      </div>,
      coordinates,
      'bottom-left'
    );
  };

  const handlePOIHoverOut = (e) => {
    clearPopup('poi-photo-hover');
    clearPopup('poi-waypoint-hover');
  };

  const handlePOITouch = (e, marker) => {
    if (
      localMode.current === FeatureType.Shape ||
      localMode.current === FeatureType.Mission
    ) {
      map.current.getCanvas().style.cursor = 'crosshair';
    }

    if ((e.originalEvent?.touches || e.touches)?.length > 1) {
      return;
    }
    clearTouch();
    touchTimer.current = setTimeout(() => {
      handlePOIContext(e, marker);
    }, 500);
  };

  const handlePOIContext = (e, marker) => {
    if (!localFormType.current) {
      e.preventDefault();
      e.stopPropagation();

      log.debug('poi context menu', e, marker);

      const entity = localPointsOfInterest.current.find(
        (p) => p.id === marker.getElement().id
      );

      addPopup(
        'poi-context',
        POIContextMenu(entity),
        toLngLat(entity?.coords ? entity?.coords : entity?.location),
        'top-left'
      );
    }
  };

  const handlePOIClick = (e, marker) => {
    if (
      localMode.current === '' ||
      localMode.current === FeatureType.Point ||
      localMode.current === POI.Photo ||
      localMode.current === POI.Waypoint
    ) {
      e.preventDefault();
      e.stopPropagation();

      log.debug('poi click', e, marker);

      const entity = localPointsOfInterest.current.find(
        (poi) => poi.id === marker.getElement().id
      );

      closeAllSidebars();
      if (entity.type === POI.Photo) {
        _setImage(entity, toLngLat(entity?.location));
      } else if (entity.type === POI.Waypoint) {
        _setWaypoint(entity, toLngLat(entity?.location));
      }
      clearPopup();
    }
  };

  const handleSymbolClick = (e, marker) => {
    if (
      localMode.current === '' ||
      localMode.current === FeatureType.Symbol ||
      localMode.current === SymbolType.Air ||
      localMode.current === SymbolType.LandEquipment ||
      localMode.current === SymbolType.LandInstallation ||
      localMode.current === SymbolType.LandUnit ||
      localMode.current === SymbolType.SeaSurface
    ) {
      e.preventDefault();
      e.stopPropagation();

      log.debug('symbol click', e, marker);

      const entity = localSymbols.current.find(
        (symbol) => symbol.id === marker.getElement().id
      );

      closeAllSidebars();
      const symbolCode = encodeMilspecSymbolCode(entity.milspec);
      entity.milspec.symbol = symbolCode;
      setMilspec(entity.milspec);
      _setSymbol(entity, toLngLat(entity?.location));

      clearPopup();
    }
  };

  const handleMapTouch = (e) => {
    if ((e.originalEvent?.touches || e.touches)?.length > 1) {
      return;
    }
    clearTouch();
    touchTimer.current = setTimeout(() => {
      handleMapContext(e);
    }, 500);
  };

  const handleMouseMove = (e) => {
    document.getElementById('cursor-location-info').innerHTML =
      localShowMGRS.current
        ? latLngToMGRS(e.lngLat.lat, e.lngLat.lng)
        : e.lngLat.lat.toFixed(5) + ', ' + e.lngLat.lng.toFixed(5);
  };

  const handleMapContext = (e) => {
    if (!isPitching.current && !localFormType.current) {
      log.debug('contextmenu', e);
      if (
        !isMultiple &&
        !localMissions.current[0]?.archived &&
        localMode.current !== FeatureType.Mission &&
        localMode.current !== FeatureType.Shape
      ) {
        const anbbox = [
          [e.point.x - 10, e.point.y - 10],
          [e.point.x + 10, e.point.y + 10],
        ];
        const gfbbox = [
          [e.point.x, e.point.y],
          [e.point.x, e.point.y],
        ];
        const gf = map.current
          .queryRenderedFeatures(gfbbox)
          .filter(
            (l) =>
              l.source === FeatureType.Geofence &&
              l.properties.type === FeatureType.Geofence
          );
        const an = map.current
          .queryRenderedFeatures(anbbox)
          .filter(
            (l) =>
              l.source === FeatureType.Annotation &&
              l.layer.type === FeatureType.Line &&
              l.properties.annotationType !== 'arrow-head'
          );

        const localGf = [];
        for (const f of gf) {
          localGf.push(
            map.current
              ?.getSource(FeatureType.Geofence)
              ?._data.features.find((d) => d.id === f.properties.id)
          );
        }

        const localAn = [];
        for (const f of an) {
          localAn.push(
            map.current
              ?.getSource(FeatureType.Annotation)
              ?._data.features.find((d) => d.id === f.properties.id)
          );
        }

        const uniqueAn = [
          ...new Map(localAn.map((item) => [item.id, item])).values(),
        ];

        const uniqueGf = [
          ...new Map(localGf.map((item) => [item.id, item])).values(),
        ];

        const insideMissionArea =
          localMissions.current[0]?.bounds &&
          inside(toArray(e.lngLat), localMissions.current[0].bounds);

        log.debug('standard', e.lngLat, insideMissionArea, uniqueGf, uniqueAn);

        if (
          e.originalEvent?.target?.parentElement['ariaLabel'] ===
            'Map marker' ||
          e.originalEvent?.target?.parentElement?.parentElement['ariaLabel'] ===
            'Map marker'
        ) {
          addPopup(
            'standard-context',
            SearchMarkerContextMenu(e.lngLat),
            e.lngLat,
            'top-left'
          );
        } else {
          addPopup(
            'standard-context',
            StandardContextMenu(
              e.lngLat,
              insideMissionArea,
              uniqueGf,
              uniqueAn
            ),
            e.lngLat,
            'top-left'
          );
        }
      }
    }
  };

  const redraw = async (units = false) => {
    log.debug('plot redraw', units);
    await drawMap();
    await plotTracks();
    if (units) {
      await plotUsers();
      log.debug('done plot units');
    }
    await plotMarkers();
    await doLiveTracking();
    await plotIntegrations();
  };

  const doSubmitGeofence = async (m) => {
    try {
      const gf = localGeofences.current.find((g) => g.id === m.id);
      if (gf) {
        await postGeofence(localMissions.current[0]?.id, gf.id, {
          description: m.name,
          notes: m.notes,
          bounds: gf.bounds,
          alertLevel: m.alertLevel || GeofenceLevel.Advisory,
          alertOnEntry: m.alertOnEntry || false,
          alertOnExit: m.alertOnExit || false,
          notifyAdmin: m.notifyAdmin || false,
          notifyUser: m.notifyUser || false,
          color: m.color || '',
        });

        // update local dataset
        gf.description = m.name;
        gf.notes = m.notes;
        gf.alertLevel = m.alertLevel;
        gf.alertOnEntry = m.alertOnEntry;
        gf.alertOnExit = m.alertOnExit;
        gf.notifyAdmin = m.notifyAdmin;
        gf.notifyUser = m.notifyUser;
        gf.color = m.color;

        toast.success(`${m.name} updated`);
        setGeofences([...localGeofences.current]);
      }
    } catch (err) {
      handleError(err);
    }
    drawShapes();
  };

  const doDeleteGeofence = async (geofence) => {
    if (!geofence) {
      return;
    }

    try {
      await deleteGeofence(localMissions.current[0]?.id, geofence.id);

      localGeofences.current = localGeofences.current.filter(
        (g) => g.id !== geofence.id
      );
      setGeofences(localGeofences.current);
      setFeatures([]);

      toast.success(
        `${
          geofence.description ? geofence.description : geofence.properties.name
        } deleted`
      );
    } catch (err) {
      handleError(err);
    }
  };

  const doSubmitAnnotation = async (m) => {
    try {
      log.debug(`updating`, m);

      const anno = localAnnotations.current.find((g) => g.id === m.id);
      if (anno) {
        await postAnnotation(localMissions.current[0]?.id, anno.id, {
          description: m.name,
          notes: m.notes,
          color: m.color,
          annotationType: m.annotationType,
          geometry: anno.geometry,
        });

        // update local dataset
        anno.description = m.name;
        anno.notes = m.notes;
        anno.color = m.color;
        anno.annotationType = m.annotationType;

        toast.success(`${m.name} updated`);
        setAnnotations([...localAnnotations.current]);
      }
    } catch (err) {
      handleError(err);
    }
    drawShapes();
  };

  const doDeleteAnnotation = async (annotation) => {
    if (!annotation) {
      return;
    }

    try {
      await deleteAnnotation(localMissions.current[0]?.id, annotation.id);

      localAnnotations.current = localAnnotations.current.filter(
        (g) => g.id !== annotation.id
      );
      setAnnotations(localAnnotations.current);
      setFeatures([]);

      toast.success(
        `${
          annotation.description
            ? annotation.description
            : annotation.properties.name
        } deleted`
      );
    } catch (err) {
      handleError(err);
    }
  };

  const handleWeatherHoverIn = (
    e,
    lngLat,
    time,
    temp,
    high,
    low,
    chanceOfRain,
    windDirection,
    windSpeed
  ) => {
    addPopup(
      'weather',
      <div className="weather-tooltip">
        <div
          className={getClassNames({
            row: true,
            header: true,
          })}
        >
          <div className="icon">
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
          </div>
          <div>DRAG PIN TO CHANGE LOCATION</div>
        </div>
        <div
          className={getClassNames({
            row: true,
            summary: true,
          })}
        >
          <div className="summary-icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div>{time}</div>
        </div>
        <div
          className={getClassNames({
            row: true,
            summary: true,
          })}
        >
          <div className="summary-icon">
            <FontAwesomeIcon icon={faTemperatureHalf} />
          </div>
          <div>{temp + '° (H:' + high + ', L:' + low + ')'}</div>
        </div>
        <div
          className={getClassNames({
            row: true,
            summary: true,
          })}
        >
          <div className="summary-icon">
            <FontAwesomeIcon icon={faCloudRain} />
          </div>
          <div>{chanceOfRain}%</div>
        </div>
        <div
          className={getClassNames({
            row: true,
            summary: true,
          })}
        >
          <div className="summary-icon">
            <FontAwesomeIcon icon={faWind} />
          </div>
          <div>{windDirection + ', ' + windSpeed + 'km/h'}</div>
        </div>
      </div>,
      lngLat,
      'bottom-left'
    );
  };

  const handleSearchHoverIn = (lngLat, location) => {
    addPopup(
      'search',
      <div className="search-tooltip">
        <div
          className={getClassNames({
            row: true,
          })}
        >
          <div>{location}</div>
        </div>
      </div>,
      lngLat,
      'bottom'
    );
  };

  const addSearchMarker = (lngLat, location) => {
    if (localSearchMarker.current.length > 0) {
      for (const i of localSearchMarker.current) {
        i.remove();
        localSearchMarker.current = [];
      }
    }
    for (const k in popups.current) {
      if (k.includes(`search`)) {
        const p = popups.current[k];
        if (p) {
          p.remove();
          delete popups.current[k];
        }
      }
    }

    let sm = searchMarker(map.current, `markers`, true, 'red', lngLat);

    sm.on('dragend', (e) => {
      log.debug('dragend', e);
      if (localSearchMarker.current.length > 0) {
        for (const i of localSearchMarker.current) {
          i.remove();
          localSearchMarker.current = [];
        }
      }
      for (const k in popups.current) {
        if (k.includes(`search`)) {
          const p = popups.current[k];
          if (p) {
            p.remove();
            delete popups.current[k];
          }
        }
      }
      reverseGeocode(e.target._lngLat.lat, e.target._lngLat.lng).then(
        (result) => {
          addSearchMarker(
            e.target._lngLat,
            result.data.features[0].place_name.substring(
              0,
              result.data.features[0].place_name.indexOf(',')
            )
          );
        }
      );
    });

    sm.getElement().lastChild.addEventListener('mouseenter', (e) =>
      handleSearchHoverIn(lngLat, location)
    );
    sm.getElement().lastChild.addEventListener('mouseleave', (e) =>
      clearPopup('search')
    );

    localSearchMarker.current.push(sm);
    sm.addTo(map.current);
  };

  const addWeatherMarker = (
    lngLat,
    time,
    temp,
    high,
    low,
    chanceOfRain,
    windDirection,
    windSpeed
  ) => {
    if (localWeatherMarker.current.length > 0) {
      for (const i of localWeatherMarker.current) {
        i.remove();
        localWeatherMarker.current = [];
      }
    }
    for (const k in popups.current) {
      if (k.includes(`weather`)) {
        const p = popups.current[k];
        if (p) {
          p.remove();
          delete popups.current[k];
        }
      }
    }

    let wm = weatherMarker(map.current, `markers`, true, lngLat);
    wm.on('dragend', (e) => {
      log.debug('dragend', e);
      if (localWeatherMarker.current.length > 0) {
        for (const i of localWeatherMarker.current) {
          i.remove();
          localWeatherMarker.current = [];
        }
      }

      for (const k in popups.current) {
        if (k.includes(`weather`)) {
          const p = popups.current[k];
          if (p) {
            p.remove();
            delete popups.current[k];
          }
        }
      }
      var lngLat = e.target.getLngLat();
      setWeatherLocation(lngLat);
      localWeatherLocation.current = lngLat;
    });

    wm.getElement().lastChild.addEventListener('mouseenter', (e) =>
      handleWeatherHoverIn(
        e,
        lngLat,
        time,
        temp,
        high,
        low,
        chanceOfRain,
        windDirection,
        windSpeed
      )
    );
    wm.getElement().lastChild.addEventListener('mouseleave', (e) =>
      clearPopup('weather')
    );
    localWeatherMarker.current.push(wm);

    wm.addTo(map.current);
  };

  const mapClick = (e) => {
    log.debug('map global click', e);

    if (localSettingWeatherLocation.current) {
      setMode('');
      setSettingWeatherLocation(false);
      localSettingWeatherLocation.current = false;
      setWeatherLocation(e.lngLat);
      localWeatherLocation.current = e.lngLat;
      return;
    }

    clearPopup();
    clearPopup('context');
    if (
      localMode.current === '' ||
      localMode.current === null ||
      localMode.current === FeatureType.Point
    ) {
      closeAllSidebars();
    }

    if (localMode.current === POI.Photo) {
      sidebars.close(SIDEBARS.missionImage);
      _setImage(null, e.lngLat);
    } else if (localMode.current === POI.Waypoint) {
      sidebars.close(SIDEBARS.missionWaypoint);
      _setWaypoint(null, e.lngLat);
    } else if (
      localMode.current === SymbolType.Air ||
      localMode.current === SymbolType.SeaSurface ||
      localMode.current === SymbolType.LandUnit ||
      localMode.current === SymbolType.LandEquipment ||
      localMode.current === SymbolType.LandInstallation
    ) {
      sidebars.close(SIDEBARS.missionSymbol);
      _setSymbol(null, e.lngLat);
    }

    if (localMode.current === SymbolType.Air) {
      setMilspec({ options: {}, symbol: SymbolSet.Air });
    } else if (localMode.current === SymbolType.SeaSurface) {
      setMilspec({ options: {}, symbol: SymbolSet.SeaSurface });
    } else if (localMode.current === SymbolType.LandUnit) {
      setMilspec({ options: {}, symbol: SymbolSet.LandUnit });
    } else if (localMode.current === SymbolType.LandEquipment) {
      setMilspec({ options: {}, symbol: SymbolSet.LandEquipment });
    } else if (localMode.current === SymbolType.LandInstallation) {
      setMilspec({ options: {}, symbol: SymbolSet.LandInstallation });
    }

    if (
      !localFormType.current &&
      (localMode.current === '' || localMode.current === null)
    ) {
      const bbox = [
        [e.point.x - 10, e.point.y - 10],
        [e.point.x + 10, e.point.y + 10],
      ];
      const f = map.current
        .queryRenderedFeatures(bbox)
        .filter(
          (l) =>
            (l.source === FeatureType.Annotation &&
              l.layer.type === FeatureType.Line &&
              l.properties.annotationType !== 'arrow-head') ||
            (l.source === FeatureType.Geofence &&
              l.properties.type === FeatureType.Geofence)
        );

      if (!!f.length) {
        if (
          f[0].source === FeatureType.Annotation &&
          f[0].layer.type === FeatureType.Line
        ) {
          const localAn = [];
          for (const an of f) {
            localAn.push(
              map.current
                ?.getSource(FeatureType.Annotation)
                ?._data.features.find((d) => d.id === an.properties.id)
            );
          }
          setFeatures(localAn);
        }

        if (
          f[0].source === FeatureType.Geofence &&
          f[0].properties.type === FeatureType.Geofence
        ) {
          const localGf = [];
          for (const gf of f) {
            localGf.push(
              map.current
                ?.getSource(FeatureType.Geofence)
                ?._data.features.find((d) => d.id === gf.properties.id)
            );
          }
          setFeatures(localGf);
        }
      }
    }

    setSelectedStateId(0);
  };

  const mapPitchStart = (e) => {
    isPitching.current = true;
  };

  const mapPitchEnd = (e) => {
    isPitching.current = false;
    set3dView(!!e.target.getPitch());
  };

  const mapRotate = (e) => {
    const bearing = map.current.getBearing();
    setMapBearing(bearing);
  };

  const mapDrawCreate = async (e) => {
    log.debug('draw.create', localMode.current);

    let featureType = localMode.current;

    if (!editingFeatures.current.length) {
      // no editing feature so cancel
      log.debug('cancelling', featureType);
      if (featureType === FeatureType.Mission) {
        setMode('');
      } else if (featureType === FeatureType.Shape) {
        await refreshGeofences();
        await refreshAnnotations();
        resetDrawMode();
      }
      return;
    }

    if (localMode.current !== FeatureType.Mission) {
      for (const f of e.features) {
        if (!!!f.geometry) {
          return;
        }

        if (e.features[0]?.geometry?.type === 'Polygon') {
          featureType = FeatureType.Geofence;
        } else {
          featureType = FeatureType.Annotation;
        }

        // check feature
        if (
          localMissions.current[0]?.bounds?.coordinates?.length &&
          f.geometry?.type === 'Polygon'
        ) {
          log.debug('checking new poly', f.geometry.coordinates);
          const diff = difference(f.geometry, localMissions.current[0].bounds);
          if (diff) {
            handleError(`Shape must be inside the ${secrets.EVENT_NAME} area`);
            editingFeatures.current = f;
            return;
          }
        }

        log.debug(
          `${featureType} added`,
          f,
          localGeofences.current,
          localAnnotations.current
        );

        const tempName =
          featureType === FeatureType.Geofence
            ? `Shape ${localGeofences.current.length + 1}`
            : `Annotation ${localAnnotations.current.length + 1}`;
        try {
          const newFence = {
            description: tempName,
          };

          if (featureType === FeatureType.Annotation) {
            newFence.color = getColour(FeatureType.Annotation);
            newFence.annotationType =
              editingFeatures.current[0]?.properties?.annotationType ||
              AnnotationType.Line;
            newFence.geometry = f.geometry;
          } else {
            newFence.bounds = f.geometry;
          }

          const rq =
            featureType === FeatureType.Geofence
              ? await postGeofence(localMissions.current[0]?.id, null, newFence)
              : await postAnnotation(
                  localMissions.current[0]?.id,
                  null,
                  newFence
                );

          newFence.id = rq.id;
          newFence.userId = rq.userId;

          if (featureType === FeatureType.Geofence) {
            localGeofences.current.push(newFence);
            setGeofences(localGeofences.current);
          } else {
            localAnnotations.current.push(newFence);
            setAnnotations(localAnnotations.current);
          }

          if (featureType === FeatureType.Annotation) {
            draw.current.setFeatureProperty(f.id, 'name', tempName);
            draw.current.setFeatureProperty(f.id, 'color', newFence.color);
            draw.current.setFeatureProperty(
              f.id,
              'annotationType',
              newFence.annotationType
            );
            draw.current.delete(f.id);
            const _feature = {
              type: 'Feature',
              properties: {
                id: rq.id,
                userId: rq.userId,
                name: tempName,
                color: newFence.color,
                annotationType: newFence.annotationType,
                _ts: f._ts,
              },
              id: rq.id,
              geometry: f.geometry,
            };
            draw.current.add(_feature);
          } else {
            draw.current.setFeatureProperty(f.id, 'name', tempName);
            draw.current.delete(f.id);
            const _feature = {
              type: 'Feature',
              properties: {
                id: rq.id,
                userId: rq.userId,
                name: tempName,
                notes: '',
                alertLevel: GeofenceLevel.Advisory,
                alertOnEntry: false,
                alertOnExit: false,
                notifyAdmin: false,
                notifyUser: false,
                _ts: rq._ts,
              },
              id: rq.id,
              geometry: f.geometry,
            };
            draw.current.add(_feature);
          }

          draw.current.changeMode('direct_select', {
            featureId: rq.id,
          });
          toast.success(`${tempName} created`);
        } catch (err) {
          handleError(err);
        }
      }
    }

    if (localMode.current === FeatureType.Mission) {
      try {
        const c = center(e.features[0]);

        localMissions.current[0].bounds = e.features[0].geometry;

        if (localMissions.current[0].location?.longitude) {
          localMissions.current[0].location = {
            ...localMissions.current[0].location,
            ...toLongitudeLatitude(c.geometry.coordinates),
          };
        } else {
          localMissions.current[0].location = {
            ...toLongitudeLatitude(c.geometry.coordinates),
            radius: 0,
            zoom: 14,
          };
        }

        await postMission(
          localMissions.current[0].id,
          only(localMissions.current[0], MISSION_FIELDS)
        );
        await updateMission(localMissions.current[0].id);

        toast.success(`${capitalize(secrets.EVENT_NAME)} area created`);
      } catch {}
    }
  };

  const mapDrawUpdate = async (e) => {
    editingFeatures.current = [...e.features];
  };

  const mapDrawDelete = async (e) => {
    clearPopup();

    for (const f of e.features) {
      const diff = difference(f.geometry, localMissions.current[0].bounds);
      let featureType = localGeofences.current.find((g) => g.id === f.id)
        ? FeatureType.Geofence
        : localAnnotations.current.find((g) => g.id === f.id)
        ? FeatureType.Annotation
        : !!diff
        ? null
        : FeatureType.Mission;
      log.debug(`${featureType} deleted`, e, localGeofences.current);

      if (featureType === FeatureType.Geofence) {
        await doDeleteGeofence({
          id: f.id,
          description: f.properties.name,
        });
      } else if (featureType === FeatureType.Annotation) {
        await doDeleteAnnotation({
          id: f.id,
          description: f.properties.name,
        });
      } else if (featureType === FeatureType.Mission) {
        setDeleteMissionArea(true);
      } else {
        editingFeatures.current = [];
        setFeatures([]);
        resetDrawMode();
      }
    }
  };

  const mapDrawSelectionChange = async (e) => {
    log.debug(
      'draw.selectionchange',
      e,
      localMode.current === FeatureType.Mission &&
        !localMissions.current[0].bounds
    );
    if (localMissions.current[0]?.archived) {
      setFeatures([]);
      return false;
    }
    if (e?.features?.length >= 1) {
      // reset history
      // multiple features
      editingFeatures.current = e.features;
      setFeatures(e.features);
    } else {
      let featureType = localMode.current;

      if (!!!editingFeatures.current.length) {
        // no editing feature so cancel
        log.debug('cancelling', featureType);
        if (featureType === FeatureType.Mission) {
          setMode('');
        } else if (featureType === FeatureType.Shape) {
          await refreshGeofences();
          await refreshAnnotations();
        }
        return;
      }

      log.debug(`${featureType} updated`, e, localGeofences.current);

      if (featureType === FeatureType.Shape) {
        for (const f of editingFeatures.current) {
          if (f?.geometry?.type === 'Polygon') {
            featureType = FeatureType.Geofence;
          } else {
            featureType = FeatureType.Annotation;
          }

          const currentFeature = (
            featureType === FeatureType.Geofence
              ? localGeofences.current
              : localAnnotations.current
          ).find((lf) => lf.id === f.id);

          if (!!!currentFeature) {
            mapDrawCreate({ features: editingFeatures.current });
            return;
          }

          if (featureType === FeatureType.Geofence) {
            if (!currentFeature?.bounds) {
              return;
            }
          } else if (featureType === FeatureType.Annotation) {
            if (!currentFeature?.geometry) {
              return;
            }
          }

          log.debug('Checking', f);

          // check feature
          if (
            f.geometry?.type === 'Polygon' &&
            localMissions.current[0].bounds &&
            localMissions.current[0].bounds.coordinates?.length
          ) {
            const diff = difference(
              f.geometry,
              localMissions.current[0].bounds
            );
            if (diff) {
              handleError(
                `Shape must be inside the ${secrets.EVENT_NAME} area`
              );
              f.geometry = currentFeature.bounds;
              draw.current.delete(f.id);
              draw.current.add(f);
              await refreshGeofences();
              // re-select the feature
              draw.current.changeMode('direct_select', {
                featureId: f.id,
              });
              continue;
            }
          }

          // see if geometry has changed
          if (featureType === FeatureType.Geofence) {
            if (
              JSON.stringify(f.geometry) ===
              JSON.stringify(currentFeature.bounds)
            ) {
              log.debug('geometry unchanged, skipping');
              continue;
            }
          } else if (featureType === FeatureType.Annotation) {
            if (
              JSON.stringify(f.geometry) ===
              JSON.stringify(currentFeature.geometry)
            ) {
              log.debug('geometry unchanged, skipping');
              continue;
            }
          }

          try {
            const updatedFence = {
              description: f.properties.name,
              notes: f.properties.notes,
            };

            if (featureType === FeatureType.Geofence) {
              updatedFence.bounds = f.geometry;
              updatedFence.alertLevel = f.properties.alertLevel;
              updatedFence.alertOnEntry = f.properties.alertOnEntry;
              updatedFence.alertOnExit = f.properties.alertOnExit;
              updatedFence.notifyAdmin = f.properties.notifyAdmin;
              updatedFence.notifyUser = f.properties.notifyUser;
              updatedFence._ts = f.properties._ts;

              await postGeofence(
                localMissions.current[0]?.id,
                f.id,
                updatedFence
              );
            } else {
              updatedFence.geometry = f.geometry;
              updatedFence.annotationType = f.properties.annotationType;
              updatedFence.color = f.properties.color;
              updatedFence._ts = f.properties._ts;

              await postAnnotation(
                localMissions.current[0]?.id,
                f.id,
                updatedFence
              );
            }

            toast.success(`${updatedFence.description} updated`, {
              id: `geofence-${f.id}-updated`,
            });

            updatedFence.id = f.id;
            if (featureType === FeatureType.Geofence) {
              localGeofences.current = localGeofences.current.filter(
                (g) => g.id !== f.id
              );
              localGeofences.current.push(updatedFence);
            } else {
              localAnnotations.current = localAnnotations.current.filter(
                (g) => g.id !== f.id
              );
              localAnnotations.current.push(updatedFence);
            }
          } catch (err) {
            handleError(err);
          }
        }
        if (featureType === FeatureType.Geofence) {
          setGeofences(localGeofences.current);
        } else {
          setAnnotations(localAnnotations.current);
        }
        editingFeatures.current = [];
      } else {
        const f = editingFeatures.current[0];

        for (const geofence of localGeofences.current) {
          if (geofence.bounds?.type === 'Polygon') {
            const diff = difference(geofence.bounds, f.geometry);
            if (diff) {
              log.debug(diff);
              handleError(
                `Shape must be inside the ${secrets.EVENT_NAME} area`
              );

              f.geometry.coordinates =
                localMissions.current[0].bounds.coordinates;
              draw.current.delete(f.id);
              draw.current.add(f);
              return;
            }
          }
        }

        // see if geometry has changed
        if (
          JSON.stringify(localMissions.current[0].bounds) ===
          JSON.stringify(f.geometry)
        ) {
          log.debug('geometry unchanged, skipping');
        } else {
          try {
            const c = center(f);

            localMissions.current[0].bounds = f.geometry;

            if (localMissions.current[0].location?.longitude) {
              localMissions.current[0].location = {
                ...localMissions.current[0].location,
                ...toLongitudeLatitude(c.geometry.coordinates),
              };
            } else {
              localMissions.current[0].location = {
                ...toLongitudeLatitude(c.geometry.coordinates),
                radius: 0,
                zoom: 14,
              };
            }

            await postMission(
              localMissions.current[0].id,
              only(localMissions.current[0], MISSION_FIELDS)
            );
            await updateMission(localMissions.current[0].id);

            toast.success(`${capitalize(secrets.EVENT_NAME)} area updated`, {
              id: 'mission-area-updated',
            });

            setMode('');
            editingFeatures.current = [];
          } catch {}
        }
      }
      resetDrawMode();
    }
  };

  const mapDrawModeChange = (e) => {
    log.debug('draw.modechange');
    if (draw.current) {
      // simple select and nothing selected
      if (
        draw.current.getMode() === 'simple_select' &&
        !draw.current.getSelectedIds().length
      ) {
        setFeatures([]);

        // no mission bounds
        if (
          localMode.current === FeatureType.Mission &&
          !localMissions.current[0].bounds
        ) {
          draw.current.changeMode('draw_polygon');
        }
      }
    }
  };

  const missionAreaClick = (e) => {
    log.debug('mission area click', e);

    if (localMissions.current.length > 1) {
      const features = map.current
        .queryRenderedFeatures(e.point)
        .filter(
          (l) =>
            l.source === FeatureType.Mission &&
            l.properties.type === FeatureType.Mission
        );
      if (features.length) {
        const missionId = features[0].properties.id;
        log.debug('mission click', missionId);

        setSelectedMission(
          localMissions.current.find((m) => m.id === missionId)
        );
        sidebars.open(SIDEBARS.mission);
      }
    }
  };

  const missionAreaPointsClick = (e) => {
    log.debug('mission area points click', e);

    if (localMissions.current.length > 1) {
      const features = map.current
        .queryRenderedFeatures(e.point)
        .filter((l) => l.source === 'mission-area-points');
      log.debug('features', features);
      if (features.length) {
        const missionId = features[0].properties.id;
        if (missionId) {
          log.debug('mission click', missionId);

          map.current.flyTo({
            center: e.lngLat,
            zoom: ZOOM_THRESHOLD,
          });
          setSelectedMission(
            localMissions.current.find((m) => m.id === missionId)
          );
        } else {
          // zoom to mission level
          map.current.flyTo({
            center: e.lngLat,
            zoom: ZOOM_THRESHOLD,
          });
        }
      }
    }
  };

  const destroyMapEvents = () => {
    offEventHandler(mapListeners.current, map.current, 'style.load');
    offEventHandler(mapListeners.current, map.current, 'click');
    offEventHandler(mapListeners.current, map.current, 'contextmenu');
    offEventHandler(mapListeners.current, map.current, 'pitchstart');
    offEventHandler(mapListeners.current, map.current, 'pitchend');
    offEventHandler(mapListeners.current, map.current, 'zoomend');
    offEventHandler(mapListeners.current, map.current, 'zoom');
    offEventHandler(mapListeners.current, map.current, 'touchstart');
    offEventHandler(mapListeners.current, map.current, 'touchend');
    offEventHandler(mapListeners.current, map.current, 'touchcancel');
    offEventHandler(mapListeners.current, map.current, 'touchmove');
    offEventHandler(mapListeners.current, map.current, 'pointerdrag');
    offEventHandler(mapListeners.current, map.current, 'pointermove');
    offEventHandler(mapListeners.current, map.current, 'moveend');
    offEventHandler(mapListeners.current, map.current, 'gesturestart');
    offEventHandler(mapListeners.current, map.current, 'gesturechange');
    offEventHandler(mapListeners.current, map.current, 'gestureend');

    offEventHandler(mapListeners.current, map.current, 'click', `mission-area`);
    offEventHandler(
      mapListeners.current,
      map.current,
      'click',
      `mission-area-points`
    );

    offEventHandler(
      mapListeners.current,
      map.current,
      'mouseenter',
      `mission-area-points`
    );
    offEventHandler(
      mapListeners.current,
      map.current,
      'mouseleave',
      `mission-area-points`
    );

    offEventHandler(mapListeners.current, map.current, 'mouseenter', `weather`);
    offEventHandler(mapListeners.current, map.current, 'mouseleave', `weather`);
    offEventHandler(mapListeners.current, map.current, 'mousemove');

    offEventHandler(mapListeners.current, map.current, 'draw.create');
    offEventHandler(mapListeners.current, map.current, 'draw.update');
    offEventHandler(mapListeners.current, map.current, 'draw.delete');
    offEventHandler(mapListeners.current, map.current, 'draw.selectionchange');
    offEventHandler(mapListeners.current, map.current, 'draw.modechange');
  };

  const initMapEvents = () => {
    if (map.current) {
      log.debug('init map events');
      onEventHandler(
        mapListeners.current,
        map.current,
        'style.load',
        handleStyleChange
      );
      onEventHandler(mapListeners.current, map.current, 'click', mapClick);
      onEventHandler(
        mapListeners.current,
        map.current,
        'contextmenu',
        handleMapContext
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'pitchstart',
        mapPitchStart
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'pitchend',
        mapPitchEnd
      );
      onEventHandler(mapListeners.current, map.current, 'rotate', mapRotate);
      onEventHandler(mapListeners.current, map.current, 'zoomend', () => {
        calculatePPM();
        refreshIntegrations();
        drawMarkers();
      });
      onEventHandler(mapListeners.current, map.current, 'zoom', () => {
        if (!!document.getElementById('zoom-level-info')) {
          document.getElementById('zoom-level-info').innerHTML =
            'Zoom: ' + map.current.getZoom().toFixed(1);
        }
      });
      onEventHandler(
        mapListeners.current,
        map.current,
        'touchstart',
        handleMapTouch
      );
      onEventHandler(mapListeners.current, map.current, 'touchend', clearTouch);
      onEventHandler(
        mapListeners.current,
        map.current,
        'touchcancel',
        clearTouch
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'touchmove',
        clearTouch
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'pointerdrag',
        clearTouch
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'pointermove',
        clearTouch
      );
      onEventHandler(mapListeners.current, map.current, 'moveend', clearTouch);
      onEventHandler(
        mapListeners.current,
        map.current,
        'gesturestart',
        clearTouch
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'gesturechange',
        clearTouch
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'gestureend',
        clearTouch
      );

      onEventHandler(
        mapListeners.current,
        map.current,
        'click',
        missionAreaClick,
        `mission-area`
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'click',
        missionAreaPointsClick,
        `mission-area-points`
      );

      onEventHandler(
        mapListeners.current,
        map.current,
        'mouseenter',
        handleMissionClusterHoverIn,
        `mission-area-points`
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'mouseleave',
        handleMissionClusterHoverOut,
        `mission-area-points`
      );

      onEventHandler(
        mapListeners.current,
        map.current,
        'draw.create',
        mapDrawCreate
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'draw.update',
        mapDrawUpdate
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'draw.delete',
        mapDrawDelete
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'draw.selectionchange',
        mapDrawSelectionChange
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'draw.modechange',
        mapDrawModeChange
      );
      onEventHandler(
        mapListeners.current,
        map.current,
        'mousemove',
        handleMouseMove
      );
    }
  };

  const drawMissionArea = () => {
    log.debug(
      'drawMissionArea',
      localShowMissionArea.current,
      localMode.current,
      localMissions.current
    );
    if (
      localShowMissionArea.current &&
      localMode.current !== FeatureType.Mission
    ) {
      const data = {
        id: FeatureType.Mission,
        type: 'FeatureCollection',
        properties: {},
        features: localMissions.current
          .filter((c) => c.bounds)
          .map((c) => {
            return {
              // feature for Mapbox DC
              id: c.id,
              type: 'Feature',
              geometry: c.bounds,
              properties: {
                type: FeatureType.Mission,
                id: c.id,
                name: c.missionName,
                color: getColour(FeatureType.Mission),
              },
            };
          }),
      };

      const pointData = {
        id: 'mission-area-points',
        type: 'FeatureCollection',
        properties: {},
        features: localMissions.current
          .filter((c) => c.location)
          .map((c) => {
            return {
              // feature for Mapbox DC
              id: c.id,
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [c.location.longitude, c.location.latitude],
              },
              properties: {
                type: 'mission-area-point',
                id: c.id,
                name: c.missionName,
                color: getColour(FeatureType.Mission),
              },
            };
          }),
      };

      if (!map.current?.getSource(`mission-area`)) {
        map.current?.addSource(`mission-area`, {
          type: 'geojson',
          data,
        });
      } else {
        map.current?.getSource(`mission-area`).setData(data);
      }

      if (!map.current?.getSource(`mission-area-points`)) {
        map.current?.addSource(`mission-area-points`, {
          type: 'geojson',
          data: pointData,
          cluster: true,
        });
      } else {
        map.current?.getSource(`mission-area-points`).setData(pointData);
      }

      if (isMultiple) {
        if (!map.current?.getLayer(`mission-area`)) {
          map.current?.addLayer({
            id: `mission-area`,
            type: 'fill',
            source: `mission-area`, // reference the data source
            layout: {},
            filter: ['>=', ['zoom'], ZOOM_THRESHOLD],
            paint: {
              'fill-color': ['get', 'color'],
              'fill-opacity': 0.2,
            },
          });
        }
      }

      if (!map.current?.getLayer(`mission-area-outline`)) {
        map.current?.addLayer({
          id: `mission-area-outline`,
          type: 'line',
          source: `mission-area`,
          layout: {},
          filter: ['>=', ['zoom'], ZOOM_THRESHOLD],
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 3,
          },
        });
      }

      // this is just a hidden layer so we can search for features
      if (!map.current?.getLayer(`mission-area-points`)) {
        map.current?.addLayer({
          id: `mission-area-points`,
          type: 'circle',
          source: `mission-area-points`,
          filter: ['<', ['zoom'], ZOOM_THRESHOLD],
          paint: {
            'circle-color': ['get', 'color'],
            'circle-radius': 24,
            'circle-opacity': 0,
          },
        });
      }
    } else {
      if (map.current?.getLayer(`mission-area`)) {
        map.current?.removeLayer(`mission-area`);
      }
      if (map.current?.getLayer(`mission-area-points`)) {
        map.current?.removeLayer(`mission-area-points`);
      }
      if (map.current?.getLayer(`mission-area-outline`)) {
        map.current?.removeLayer(`mission-area-outline`);
      }
    }

    // draw
    if (localMode.current === FeatureType.Mission) {
      if (map.current) {
        if (draw.current) {
          try {
            map.current.removeControl(draw.current);
          } catch {
            log.error(
              'handled error removing current draw - probs due to event handlers still being bound'
            );
          }
        }

        let drawMode = 'simple_select';
        if (!localMissions.current[0].bounds) {
          drawMode = 'draw_polygon';
        }

        log.debug('init draw', localMissions.current[0].bounds, drawMode);

        // need to do this in a temp variable or else the map freaks out
        const _draw = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: true,
            trash: true,
          },
          modes: Object.assign(MapboxDraw.modes, {
            draw_polygon: DrawConcave,
            direct_select: ConcaveSelect,
            simple_select: SimpleSelect,
            drag_circle: DragCircleMode,
            draw_rectangle: DrawRectangle,
            rotate_mode: SRMode,
          }),
          defaultMode: drawMode,
          userProperties: true,
          styles: getDrawStyles(getColour(FeatureType.Mission)),
        });

        map.current.addControl(_draw);

        map.current.getCanvas().style.cursor = 'crosshair';

        if (drawMode === 'simple_select') {
          _draw.add({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                id: FeatureType.Mission,
                properties: {
                  color: getColour(FeatureType.Mission),
                },
                geometry: localMissions.current[0].bounds,
              },
            ],
          });

          _draw.changeMode('direct_select', {
            // The id of the feature that will be directly selected (required)
            featureId: FeatureType.Mission,
          });
        }

        draw.current = _draw;
      }
    } else if (localMode.current === '' || localMode.current === null) {
      if (map.current) {
        map.current.getCanvas().style.cursor = 'unset';

        if (draw.current) {
          try {
            map.current.removeControl(draw.current);
          } catch {
            log.error(
              'handled error removing current draw - probs due to event handlers still being bound'
            );
          }
        }
      }
    }
  };

  const refreshGeofences = async () => {
    const data = {
      id: FeatureType.Geofence,
      type: 'FeatureCollection',
      properties: {},
      features: localGeofences.current
        .filter((c) => c.bounds)
        .map((c) => {
          return {
            // feature for Mapbox DC
            id: c.id,
            type: 'Feature',
            geometry: c.bounds,
            properties: {
              type: FeatureType.Geofence,
              id: c.id,
              name: c.description,
              color:
                c.color && c.alertLevel === 'none'
                  ? c.color
                  : getColour(
                      FeatureType.Geofence,
                      c.alertLevel,
                      selectedStyle
                    ),
            },
          };
        }),
    };

    log.debug('refreshing shapes', localGeofences.current);

    const src = map.current?.getSource(FeatureType.Geofence);
    if (src) {
      src.setData(data);
    }
    if (draw.current) {
      draw.current.deleteAll();
      draw.current.add(data);
    }
  };

  const refreshAnnotations = async () => {
    const data = {
      id: FeatureType.Annotation,
      type: 'FeatureCollection',
      properties: {},
      features: localAnnotations.current
        .filter((c) => c.geometry)
        .map((c) => {
          return {
            // feature for Mapbox DC
            id: c.id,
            type: 'Feature',
            geometry: c.geometry,
            properties: {
              type: FeatureType.Annotation,
              id: c.id,
              name: c.description,
              color: c.color,
              annotationType: c.annotationType,
              _ts: c._ts,
            },
          };
        }),
    };

    log.debug('refreshing annotations', localAnnotations.current);

    const src = map.current?.getSource(FeatureType.Annotation);
    if (src) {
      src.setData(data);
    }
    // delete anything new
    if (draw.current) {
      draw.current.deleteAll();
      draw.current.add(data);
    }
  };

  const drawShapes = () => {
    log.debug(
      'drawShapes',
      localShowGeofences.current,
      localShowAnnotations.current,
      localMode.current,
      localGeofences.current,
      localAnnotations.current
    );

    if (localMode.current !== FeatureType.Shape && localShowGeofences.current) {
      const geofenceData = {
        id: FeatureType.Geofence,
        type: 'FeatureCollection',
        properties: {},
        features: localGeofences.current
          .filter((c) => c.bounds && !c.archived)
          .map((c) => {
            return {
              // feature for Mapbox DC
              id: c.id,
              type: 'Feature',
              geometry: c.bounds,
              properties: {
                type: FeatureType.Geofence,
                id: c.id,
                userId: c.userId,
                name: c.description,
                notes: c.notes || '',
                alertLevel: c.alertLevel,
                alertOnEntry: c.alertOnEntry || false,
                alertOnExit: c.alertOnExit || false,
                notifyAdmin: c.notifyAdmin || false,
                notifyUser: c.notifyUser || false,
                _ts: c._ts,
                color:
                  c.color && c.alertLevel === 'none'
                    ? c.color
                    : getColour(
                        FeatureType.Geofence,
                        c.alertLevel,
                        selectedStyle
                      ),
              },
            };
          }),
      };

      if (!map.current?.getSource(FeatureType.Geofence)) {
        map.current?.addSource(FeatureType.Geofence, {
          type: 'geojson',
          data: geofenceData,
        });
      } else {
        map.current?.getSource(FeatureType.Geofence).setData(geofenceData);
      }

      if (!map.current?.getLayer(FeatureType.Geofence)) {
        map.current?.addLayer({
          id: FeatureType.Geofence,
          type: 'fill',
          source: FeatureType.Geofence, // reference the data source
          layout: {},
          filter: [
            'all',
            ['>=', ['zoom'], ZOOM_THRESHOLD],
            ['!', ['has', 'line-type']],
          ],
          paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.2,
          },
        });
      }
      if (!map.current?.getLayer(`${FeatureType.Geofence}-outline`)) {
        map.current?.addLayer({
          id: `${FeatureType.Geofence}-outline`,
          type: 'line',
          source: FeatureType.Geofence,
          layout: {},
          filter: ['>=', ['zoom'], ZOOM_THRESHOLD],
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 3,
          },
        });
      }
      if (localShowGeofenceLabels.current) {
        const geofenceLabels = {
          id: `${FeatureType.Geofence}-labels`,
          type: 'FeatureCollection',
          features: localGeofences.current
            .filter((c) => c.bounds)
            .map((g) => {
              return {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: polylabel(g.bounds.coordinates, 1),
                },
                properties: {
                  name: g.description,
                  alertLevel: g.alertLevel,
                  color:
                    g.color && g.alertLevel === 'none'
                      ? g.color
                      : getColour(
                          FeatureType.Geofence,
                          g.alertLevel,
                          selectedStyle
                        ),
                },
              };
            }),
        };

        if (!map.current?.getSource(`${FeatureType.Geofence}-labels`)) {
          map.current?.addSource(`${FeatureType.Geofence}-labels`, {
            type: 'geojson',
            data: geofenceLabels,
          });
        } else {
          map.current
            ?.getSource(`${FeatureType.Geofence}-labels`)
            .setData(geofenceLabels);
        }

        if (!map.current?.getLayer(`${FeatureType.Geofence}-labels`)) {
          map.current?.addLayer({
            id: `${FeatureType.Geofence}-labels`,
            type: 'symbol',
            source: `${FeatureType.Geofence}-labels`, // reference the data source
            filter: [
              'all',
              ['>=', ['zoom'], labelZoomThreshold],
              ['!', ['has', 'line-type']],
            ],
            layout: {
              'text-field': ['get', 'name'],
              'text-size': 16,
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-letter-spacing': 0.05,
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-color': '#000000',
              'text-halo-width': 1.5,
              'text-opacity': 1,
            },
          });
        }
      } else {
        if (map.current?.getLayer(`geofence-labels`)) {
          map.current?.removeLayer(`geofence-labels`);
        }
      }
    }

    if (
      localMode.current !== FeatureType.Shape &&
      localShowAnnotations.current
    ) {
      const annotationData = {
        id: FeatureType.Shape,
        type: 'FeatureCollection',
        properties: {},
        features: localAnnotations.current
          .filter((c) => c.geometry)
          .map((c) => {
            return {
              // feature for Mapbox DC
              id: c.id,
              type: 'Feature',
              geometry: c.geometry,
              properties: {
                type: FeatureType.Annotation,
                id: c.id,
                userId: c.userId,
                name: c.description,
                notes: c.notes,
                annotationType: c.annotationType,
                color: c.color,
                _ts: c._ts,
              },
            };
          }),
      };

      if (localShowAnnotationLabels.current) {
        const annotationLabels = {
          id: `${FeatureType.Annotation}-labels`,
          type: 'FeatureCollection',
          features: localAnnotations.current
            .filter((c) => c.geometry)
            .map((g) => {
              const latitude =
                (g.geometry.coordinates[0][0] + g.geometry.coordinates[1][0]) /
                2;
              const longitude =
                (g.geometry.coordinates[0][1] + g.geometry.coordinates[1][1]) /
                2;
              return {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [latitude, longitude],
                },
                properties: {
                  name: g.description,
                  color: g.color,
                },
              };
            }),
        };

        if (!map.current?.getSource(`${FeatureType.Annotation}-labels`)) {
          map.current?.addSource(`${FeatureType.Annotation}-labels`, {
            type: 'geojson',
            data: annotationLabels,
          });
        } else {
          map.current
            ?.getSource(`${FeatureType.Annotation}-labels`)
            .setData(annotationLabels);
        }

        for (const f of [...annotationData.features]) {
          if (f.geometry.type === 'LineString') {
            // control here the type of arrow we'll render

            // arrow at end so calc rotation
            if (f.properties['annotationType'] === AnnotationType.Arrow) {
              const rotate =
                bearing(
                  f.geometry.coordinates[f.geometry.coordinates.length - 2],
                  f.geometry.coordinates[f.geometry.coordinates.length - 1]
                ) - 90;
              annotationData.features.push({
                // feature for Mapbox DC
                id: f.id + '-point',
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates:
                    f.geometry.coordinates[f.geometry.coordinates.length - 1],
                },
                properties: {
                  type: FeatureType.Annotation,
                  annotationType: 'arrow-head',
                  rotate,
                  color: f.properties.color,
                },
              });
            }
          }
        }

        if (!map.current?.getSource(FeatureType.Annotation)) {
          map.current?.addSource(FeatureType.Annotation, {
            type: 'geojson',
            data: annotationData,
          });
        } else {
          map.current
            ?.getSource(FeatureType.Annotation)
            .setData(annotationData);
        }

        if (!map.current?.getLayer(FeatureType.Annotation)) {
          map.current?.addLayer({
            id: FeatureType.Annotation,
            type: 'fill',
            source: FeatureType.Annotation, // reference the data source
            layout: {},
            filter: [
              'all',
              ['>=', ['zoom'], ZOOM_THRESHOLD],
              ['!', ['has', 'annotationType']],
            ],
            paint: {
              'fill-color': ['get', 'color'],
              'fill-opacity': 0.2,
            },
          });
        }

        if (!map.current?.getLayer(`${FeatureType.Annotation}-outline`)) {
          map.current?.addLayer({
            id: `${FeatureType.Annotation}-outline`,
            type: 'line',
            source: FeatureType.Annotation,
            layout: {},
            filter: ['>=', ['zoom'], ZOOM_THRESHOLD],
            paint: {
              'line-color': ['get', 'color'],
              'line-width': 3,
            },
          });
        }
        // arrow at end so just render arrow icon at last coord
        if (!map.current?.getLayer(`${FeatureType.Annotation}-arrow`)) {
          map.current.addLayer({
            id: `${FeatureType.Annotation}-arrow`,
            type: 'symbol',
            source: FeatureType.Annotation,
            filter: [
              'all',
              ['>=', ['zoom'], ZOOM_THRESHOLD],
              ['match', ['get', 'annotationType'], 'arrow-head', true, false],
            ],
            layout: {
              'icon-image': 'arrow-head', // reference the image
              'icon-size': 0.4,
              'icon-rotate': ['get', 'rotate'],
              'icon-rotation-alignment': 'map',
            },
            paint: {
              'icon-color': ['get', 'color'],
            },
          });
        }

        if (!map.current?.getLayer(`${FeatureType.Annotation}-labels`)) {
          map.current?.addLayer({
            id: `${FeatureType.Annotation}-labels`,
            type: 'symbol',
            source: `${FeatureType.Annotation}-labels`, // reference the data source
            filter: [
              'all',
              ['>=', ['zoom'], labelZoomThreshold],
              ['!', ['has', 'line-type']],
            ],
            layout: {
              'text-field': ['get', 'name'],
              'text-size': 12,
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-letter-spacing': 0,
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-color': '#000000',
              'text-halo-width': 1.25,
              'text-opacity': 1,
            },
          });
        }
      } else {
        if (map.current?.getLayer(`annotation-labels`)) {
          map.current?.removeLayer(`annotation-labels`);
        }
      }
    }

    if (localMode.current === FeatureType.Shape) {
      if (map.current?.getLayer(FeatureType.Geofence)) {
        map.current?.removeLayer(FeatureType.Geofence);
      }
      if (map.current?.getLayer(`geofence-outline`)) {
        map.current?.removeLayer(`geofence-outline`);
      }
      if (map.current?.getLayer(`geofence-labels`)) {
        map.current?.removeLayer(`geofence-labels`);
      }
      if (map.current?.getLayer(FeatureType.Annotation)) {
        map.current?.removeLayer(FeatureType.Annotation);
      }
      if (map.current?.getLayer(`annotation-outline`)) {
        map.current?.removeLayer(`annotation-outline`);
      }
      if (map.current?.getLayer(`annotation-arrow`)) {
        map.current?.removeLayer(`annotation-arrow`);
      }
      if (map.current?.getLayer(`annotation-labels`)) {
        map.current?.removeLayer(`annotation-labels`);
      }
    } else {
      if (!localShowGeofences.current) {
        if (map.current?.getLayer(FeatureType.Geofence)) {
          map.current?.removeLayer(FeatureType.Geofence);
        }
        if (map.current?.getLayer(`geofence-outline`)) {
          map.current?.removeLayer(`geofence-outline`);
        }
        if (map.current?.getLayer(`geofence-labels`)) {
          map.current?.removeLayer(`geofence-labels`);
        }
      }
      if (!localShowAnnotations.current) {
        if (map.current?.getLayer(FeatureType.Annotation)) {
          map.current?.removeLayer(FeatureType.Annotation);
        }
        if (map.current?.getLayer(`annotation-outline`)) {
          map.current?.removeLayer(`annotation-outline`);
        }
        if (map.current?.getLayer(`annotation-arrow`)) {
          map.current?.removeLayer(`annotation-arrow`);
        }
        if (map.current?.getLayer(`annotation-labels`)) {
          map.current?.removeLayer(`annotation-labels`);
        }
      }
    }

    // draw
    if (localMode.current === FeatureType.Shape) {
      if (map.current) {
        if (draw.current) {
          try {
            map.current.removeControl(draw.current);
          } catch {
            log.error(
              'handled error removing current draw - probs due to event handlers still being bound'
            );
          }
        }

        let drawMode = 'draw_polygon';
        if (localGeofences.current.length || localAnnotations.current.length) {
          drawMode = 'simple_select';
        }
        log.debug('init draw', drawMode, localGeofences.current);

        // need to do this in a temp variable or else the map freaks out
        const _draw = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: true,
            trash: true,
          },
          modes: Object.assign(MapboxDraw.modes, {
            draw_polygon: DrawConcave,
            direct_select: ConcaveSelect,
            simple_select: SimpleSelect,
            draw_line_string: DrawLineString,
            drag_circle: DragCircleMode,
            draw_rectangle: DrawRectangle,
            rotate_mode: SRMode,
          }),
          defaultMode: drawMode,
          userProperties: true,
          styles: getDrawStyles(getColour(FeatureType.Geofence)),
        });

        map.current.addControl(_draw);

        map.current.getCanvas().style.cursor = 'crosshair';

        _draw.add({
          type: 'FeatureCollection',
          features: localGeofences.current
            .map((g) => {
              return {
                type: 'Feature',
                properties: {
                  id: g.id,
                  userId: g.userId,
                  name: g.description,
                  notes: g.notes,
                  color:
                    g.color && g.alertLevel === 'none'
                      ? g.color
                      : getColour(FeatureType.Geofence, g.alertLevel),
                  alertLevel: g.alertLevel,
                  alertOnEntry: g.alertOnEntry || false,
                  alertOnExit: g.alertOnExit || false,
                  notifyAdmin: g.notifyAdmin || false,
                  notifyUser: g.notifyUser || false,
                  _ts: g._ts,
                },
                id: g.id,
                geometry: g.bounds,
              };
            })
            .concat(
              localAnnotations.current.map((a) => {
                return {
                  type: 'Feature',
                  properties: {
                    id: a.id,
                    userId: a.userId,
                    name: a.description,
                    notes: a.notes,
                    color: a.color,
                    annotationType: a.annotationType,
                    _ts: a._ts,
                  },
                  id: a.id,
                  geometry: a.geometry,
                };
              })
            ),
        });

        if (localFeatures.current.length === 1) {
          _draw.changeMode('direct_select', {
            featureId: localFeatures.current[0].id,
          });
        }

        draw.current = _draw;
      }
    } else if (localMode.current === '' || localMode.current === null) {
      if (map.current) {
        map.current.getCanvas().style.cursor = 'unset';

        if (draw.current) {
          try {
            map.current.removeControl(draw.current);
          } catch {
            log.error(
              'handled error removing current draw - probs due to event handlers still being bound'
            );
          }
        }
      }
    }
  };

  const drawMap = async () => {
    setFeatures([]);
    localFeatures.current = [];
    drawMissionArea();
    drawShapes();
    comms.setCentreMapOnPersonnel(() => centreMapOnPersonnel);
    comms.setOpenUserDetails(() => openUserDetails);
  };

  // eslint-disable-next-line
  const positionToolbar = (toolbarType) => {
    if (!document.querySelector('.mapboxgl-popup')) {
      return;
    }
    if (!document.querySelector(`.${toolbarType}-toolbar`)) {
      return;
    }

    const [match, x, y] = /translate\((\d+)px, (\d+)px\)/.exec(
      document.querySelector('.mapboxgl-popup').style.transform
    );
    log.debug('match', match);

    const offsetX = 140;
    const offsetY = 20;

    document.querySelector(
      `.${toolbarType}-toolbar`
    ).style.transform = `translate(-50%, -100%) translate(${
      parseInt(x, 10) + offsetX
    }px, ${parseInt(y, 10) + offsetY}px)`;
  };

  const centreMapOnMissionArea = async () => {
    // turn off live tracking
    setLiveTracking(null);
    if (localLiveTracking.current) {
      toast.success('Live Tracking Disabled', { id: 'live-tracking-updated' });
    }

    try {
      if (localMissions.current.length > 0) {
        log.debug('fit to all bounds');
        // fit to all missions
        let allBounds = null;
        for (const mission of localMissions.current) {
          if (mission.bounds) {
            if (allBounds == null) {
              allBounds = { ...mission.bounds };
            } else {
              allBounds.coordinates.push(mission.bounds.coordinates[0]);
            }
          }
        }
        log.debug('fit to', allBounds);
        if (allBounds) {
          const bounds = bbox(allBounds);
          // padding of 30 plus size of search
          // plus another 30
          map.current.fitBounds(bounds, { padding: 120, bearing: 0, pitch: 0 });
        }
      }
    } catch (ex) {
      log.error(ex);
    }
  };

  const zoomToAllUsers = async () => {
    // turn off live tracking
    setLiveTracking(null);
    if (localLiveTracking.current) {
      toast.success('Live Tracking Disabled', { id: 'live-tracking-updated' });
    }

    try {
      if (personnel.length > 0) {
        log.debug('fit to all users');
        // fit to all missions
        const allCoordinates = [];
        for (const user of personnel) {
          if (user.location) {
            allCoordinates.push(toArray(user.location.coords));
          }
        }
        log.debug('fit to', allCoordinates);

        if (allCoordinates.length) {
          const bounds = bbox({
            type: 'Polygon',
            coordinates: [allCoordinates],
          });
          log.debug('fit to', bounds);

          map.current.fitBounds(bounds, { padding: 120, bearing: 0, pitch: 0 });
        }
      }
    } catch (ex) {
      log.error(ex);
    }
  };

  const centreMapOnPersonnel = async (unit) => {
    try {
      if (unit) {
        // only exec if still
        if (
          !map.current.isEasing() &&
          !map.current.isMoving() &&
          !map.current.isZooming() &&
          !map.current.isRotating()
        ) {
          map.current.flyTo({
            zoom: 18,
            center: toLngLat(unit.coords),
            bearing: 0,
          });
        }
      } else {
        // turn off live tracking
        setLiveTracking(null);
        if (localLiveTracking.current) {
          toast.success('Live Tracking Disabled', {
            id: 'live-tracking-updated',
          });
        }

        const yourOrg = map.current.getSource('self');
        const you = yourOrg._data.features[0];
        if (you) {
          log.debug('you', you);
          map.current.flyTo({
            center: you.geometry.coordinates,
            bearing: 0,
          });
        }
      }
    } catch (ex) {
      log.error(ex);
    }
  };

  const openUserDetails = async (unit) => {
    try {
      if (unit) {
        setSelectedUser(unit, toLngLat(unit.coords));
        setUserTab('messages');
      }
    } catch (ex) {
      log.error(ex);
    }
  };

  // eslint-disable-next-line
  const openTeams = () => {
    setTeamsExpanded(true);
    setRolesExpanded(false);
    sidebars.close(SIDEBARS.MissionRoles);
  };
  const closeTeams = () => {
    teamsPanel.current.addEventListener('webkitAnimationEnd', () => {
      setTeamsExpanded(false);
    });
    teamsPanel.current.classList += ' pending-close';
    sidebars.close(SIDEBARS.MissionTeams);
  };
  // eslint-disable-next-line
  const openRoles = () => {
    setRolesExpanded(true);
    setTeamsExpanded(false);
    setIntegrationsExpanded(false);
    sidebars.close(SIDEBARS.MissionTeams);
  };
  const closeRoles = () => {
    rolesPanel.current.addEventListener('webkitAnimationEnd', () => {
      setRolesExpanded(false);
    });
    rolesPanel.current.classList += ' pending-close';
    sidebars.close(SIDEBARS.MissionRoles);
  };
  // eslint-disable-next-line
  const openIntegrations = () => {
    setIntegrationsExpanded(true);
    setRolesExpanded(false);
    setTeamsExpanded(false);
  };
  // eslint-disable-next-line
  const closeIntegrations = () => {
    integrationsPanel.current.addEventListener('webkitAnimationEnd', () => {
      setIntegrationsExpanded(false);
    });
    integrationsPanel.current.classList += ' pending-close';
  };

  const clearPopup = (id) => {
    if (!id) {
      const keys = Object.keys(popups.current);
      for (const k of keys) {
        if (localShowPersonnelLabels.current && k.includes('unit')) {
          continue;
        }
        if (popups.current[k]) {
          const el = popups.current[k].getElement();
          if (el) {
            el.addEventListener('webkitAnimationEnd', () => {
              const p = popups.current[k];
              if (p) {
                p.remove();
                delete popups.current[k];
              }
            });
            el.classList.add('pending-remove');
          }
        }
      }
    } else {
      const keys = Object.keys(popups.current);
      for (const k of keys) {
        if (k.includes(id)) {
          const el = popups.current[k].getElement();
          if (el) {
            el.addEventListener('webkitAnimationEnd', () => {
              const p = popups.current[k];
              if (p) {
                p.remove();
                delete popups.current[k];
              }
            });
            el.classList.add('pending-remove');
          }
        }
      }
    }
  };

  const addPopup = (
    id,
    jsx,
    coordinates,
    anchor = 'bottom-left',
    specificOffset,
    markerCount
  ) => {
    const _id = `${id}-${uuid()}`;

    let offset = [0, 0];

    let className = '';

    if (id.endsWith('-self')) {
      offset[1] = -24;
      className = 'popup-self';
    } else if (id.startsWith('poi')) {
      offset[1] = -42;
      if (id.includes(POI.Photo)) {
        className = 'popup-photo';
      } else {
        className = 'popup-waypoint';
      }
    } else if (id.startsWith('unit')) {
      offset[1] = -24;
      className = 'popup-unit';
    } else if (id.includes('mission-cluster')) {
      offset[1] = -42;
      className = 'popup-mission-cluster';
    } else if (id.includes('cluster')) {
      if (markerCount === 1) {
        className = 'popup-cluster-1';
      }
      if (markerCount === 2) {
        className = 'popup-cluster-2';
      }
      if (markerCount === 3) {
        className = 'popup-cluster-3';
      }
      if (markerCount > 3) {
        className = 'popup-cluster-4';
      }
    }

    if (id.includes('context')) {
      if (id.startsWith('poi')) {
        offset[0] = 36;
        offset[1] = -81;
        className = 'context-poi';
      } else if (id.startsWith('unit')) {
        offset[0] = 12;
        className = 'context-unit';
      } else if (id.startsWith('standard')) {
        offset[0] = 12;
        offset[1] = -8;
        className = 'context-standard';
      }
    }

    if (id === 'weather') {
      offset[1] = -36;
      className = 'popup-weather';
    }

    if (id === 'search') {
      offset[1] = -36;
      className = 'popup-weather';
    }

    if (id.includes('ais')) {
      offset[0] = 0;
      offset[1] = -12;
      className = 'popup-weather';
    }

    if (id.includes('adsb')) {
      offset[0] = 0;
      offset[1] = -12;
      className = 'popup-weather';
    }

    if (specificOffset) {
      offset = specificOffset;
    }

    if (id.includes('context')) {
      clearPopup('context');
    }

    const placeholder = document.createElement('div');
    ReactDom.render(jsx, placeholder);

    popups.current[_id] = new mapboxgl.Popup({
      closeOnClick: false,
      offset,
      anchor,
    })
      .setLngLat(coordinates)
      .setDOMContent(placeholder)
      .addTo(map.current);

    if (className) {
      popups.current[_id].getElement().classList.add(className);
    }
  };

  const addPopupToMarker = (
    id,
    jsx,
    marker,
    anchor = 'bottom-left',
    isDisconnected
  ) => {
    const _id = `${id}-${uuid()}`;

    let offset = [0, 0];

    let className = '';

    if (id.endsWith('-self')) {
      offset[1] = -42;
      className = 'popup-self';
    } else if (id.startsWith('poi')) {
      offset[1] = -81;
      if (id.includes('photo')) {
        className = 'popup-photo';
      } else {
        className = 'popup-waypoint';
      }
    } else if (id.startsWith('unit')) {
      offset[1] = -12;
      if (isDisconnected) {
        className = 'popup-unit-disconnected';
      } else {
        className = 'popup-unit';
      }
    } else if (id.includes('mission-cluster')) {
      offset[1] = -42;
      className = 'popup-mission-cluster';
    } else if (id.includes('cluster')) {
      offset[1] = -42;
      className = 'popup-cluster';
    }

    if (id.includes('context')) {
      if (id.startsWith('poi')) {
        offset[0] = 36;
        offset[1] = -81;
        className = 'context-poi';
      } else if (id.startsWith('unit')) {
        offset[0] = 24;
        className = 'context-unit';
      } else if (id.startsWith('standard')) {
        offset[0] = 12;
        offset[1] = -8;
        className = 'context-standard';
      }
    }

    if (id.includes('context')) {
      clearPopup('context');
    }

    const placeholder = document.createElement('div');
    ReactDom.render(jsx, placeholder);

    popups.current[_id] = new mapboxgl.Popup({
      closeOnClick: false,
      offset,
      anchor,
    }).setDOMContent(placeholder);

    marker.setPopup(popups.current[_id]);
    if (!marker.getPopup().isOpen()) {
      marker.togglePopup();
    }

    if (className) {
      popups.current[_id].getElement().classList.add(className);
    }
  };

  const editMissionArea = useCallback(async () => {
    clearPopup();
    localShapeIcon.current = 'shapes';
    setShapeIcon('shapes');

    if (mode === FeatureType.Mission) {
      setMode('');
      editingFeatures.current = [];
    } else {
      if (map.current && !map.current.isMoving()) {
        setMode(FeatureType.Mission);
        setLiveTracking(null);
        editingFeatures.current = [{ id: 'new', properties: {} }];
        if (localLiveTracking.current) {
          toast.success('Live Tracking Disabled', {
            id: 'live-tracking-updated',
          });
        }
      } else {
        toast.error(
          `Can't edit ${secrets.EVENT_NAME} area while map is in motion`,
          {
            id: 'motion-error',
          }
        );
      }
    }
    setFeatures([]);
  }, [mode]);

  const editGeofences = async (geofence) => {
    clearPopup();
    if (map.current && !map.current.isMoving()) {
      if (!!geofence) {
        editingFeatures.current = [];
        const f = map.current
          ?.getSource(FeatureType.Geofence)
          ?._data.features.find((d) => d.id === geofence.id);
        localShapeIcon.current = 'shapes';
        setShapeIcon('shapes');
        if (localMode.current !== FeatureType.Shape) {
          setMode(FeatureType.Shape);
        }
        setFeatures([f]);
        editingFeatures.current = [f];
      } else {
        editingFeatures.current = [{ id: 'new', properties: {} }];
        setFeatures([]);
      }

      setLiveTracking(null);
      if (localLiveTracking.current) {
        toast.success('Live Tracking Disabled', {
          id: 'live-tracking-updated',
        });
      }
    } else {
      toast.error(`Can't edit shapes while map is in motion`, {
        id: 'motion-error',
      });
    }
  };

  const createPointOfInterest = useCallback(
    (geofence) => {
      clearPopup();
      setFeatures([]);
      setMilspec(null);
      if (
        mode === FeatureType.Point ||
        mode === POI.Waypoint ||
        mode === POI.Photo
      ) {
        setMode('');
      } else {
        if (map.current && !map.current.isMoving()) {
          map.current.getCanvas().style.cursor = 'crosshair';
          if (poiIcon === 'map-pin') {
            setMode(FeatureType.Point);
          } else if (poiIcon === 'location-dot') {
            setMode(POI.Waypoint);
          } else if (poiIcon === 'image') {
            setMode(POI.Photo);
          }
        } else {
          toast.error(`Can't create point of interest while map is in motion`, {
            id: 'motion-error',
          });
        }
      }
    },
    [mode, poiIcon]
  );

  const insertSymbol = useCallback(() => {
    clearPopup();
    if (
      mode === FeatureType.Symbol ||
      mode === SymbolType.Air ||
      mode === SymbolType.SeaSurface ||
      mode === SymbolType.LandUnit ||
      mode === SymbolType.LandEquipment ||
      mode === SymbolType.LandInstallation
    ) {
      setMode('');
    } else {
      setMode(FeatureType.Symbol);
      map.current.getCanvas().style.cursor = 'crosshair';
      if (map.current && !map.current.isMoving()) {
        if (symbolIcon === svgM2525) {
          setMode(FeatureType.Symbol);
        } else if (
          symbolIcon === new ms.Symbol(SymbolSet.Air).asCanvas().toDataURL()
        ) {
          setMode(SymbolType.Air);
        } else if (
          symbolIcon ===
          new ms.Symbol(SymbolSet.SeaSurface).asCanvas().toDataURL()
        ) {
          setMode(SymbolType.SeaSurface);
        } else if (
          symbolIcon ===
          new ms.Symbol(SymbolSet.LandUnit).asCanvas().toDataURL()
        ) {
          setMode(SymbolType.LandUnit);
        } else if (
          symbolIcon ===
          new ms.Symbol(SymbolSet.LandEquipment).asCanvas().toDataURL()
        ) {
          setMode(SymbolType.LandEquipment);
        } else if (
          symbolIcon ===
          new ms.Symbol(SymbolSet.LandInstallation).asCanvas().toDataURL()
        ) {
          setMode(SymbolType.LandInstallation);
        }
      } else {
        toast.error(`Can't insert symbol while map is in motion`, {
          id: 'motion-error',
        });
      }
    }
  }, [mode, symbolIcon]);

  const editAnnotations = async (anno) => {
    clearPopup();
    if (map.current && !map.current.isMoving()) {
      if (!!anno) {
        editingFeatures.current = [];
        const f = map.current
          ?.getSource(FeatureType.Annotation)
          ?._data.features.find((d) => d.id === anno.id);
        setFeatures([f]);
        editingFeatures.current = [f];
        if (localMode.current !== FeatureType.Shape) {
          setMode(FeatureType.Shape);
        }
        if (localShapeIcon.current !== 'shapes') {
          setShapeIcon('shapes');
        }
        if (localMode.current === FeatureType.Shape) {
          resetDrawMode();
        }
      } else {
        editingFeatures.current = [{ id: 'new', properties: {} }];
        setFeatures([]);
      }

      setLiveTracking(null);
      if (localLiveTracking.current) {
        toast.success('Live Tracking Disabled', {
          id: 'live-tracking-updated',
        });
      }
    } else {
      toast.error(`Can't edit annotations while map is in motion`, {
        id: 'motion-error',
      });
    }
  };

  const SearchMarkerContextMenu = (loc) => {
    const options = [];
    const poiSubMenuOptions = [];
    poiSubMenuOptions.push({
      icon: 'location-dot',
      text: 'Waypoint',
      ariaLabel: 'Waypoint',
      disabled: !showWaypoints,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setWaypoint(null, {
          lng: localSearchResult.current.geometry.coordinates[0],
          lat: localSearchResult.current.geometry.coordinates[1],
        });
        clearPopup();
      },
    });
    poiSubMenuOptions.push({
      icon: 'image',
      text: 'Image',
      ariaLabel: 'Image',
      disabled: !showPhotos,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setImage(null, {
          lng: localSearchResult.current.geometry.coordinates[0],
          lat: localSearchResult.current.geometry.coordinates[1],
        });
        clearPopup();
      },
    });
    const poiSubMenu = <ContextMenu options={poiSubMenuOptions} />;
    options.push({
      icon: 'map-pin',
      text: 'Create Point of Interest',
      ariaLabel: 'Create Point of Interest',
      hasSubMenu: !!poiSubMenuOptions.length,
      onClick: async () => {
        handleContextMenuClick(poiSubMenu, loc, [253, -8]);
      },
    });

    const symbolSubMenuOptions = [];
    symbolSubMenuOptions.push({
      icon: 'air',
      text: 'Air',
      ariaLabel: 'Air',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setSymbol(null, {
          lng: localSearchResult.current.geometry.coordinates[0],
          lat: localSearchResult.current.geometry.coordinates[1],
        });
        setMilspec({ options: {}, symbol: SymbolSet.Air });
        clearPopup();
      },
    });
    symbolSubMenuOptions.push({
      icon: 'sea-surface',
      text: 'Sea Surface',
      ariaLabel: 'Sea Surface',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setSymbol(null, {
          lng: localSearchResult.current.geometry.coordinates[0],
          lat: localSearchResult.current.geometry.coordinates[1],
        });
        setMilspec({ options: {}, symbol: SymbolSet.SeaSurface });
        clearPopup();
      },
    });
    symbolSubMenuOptions.push({
      icon: 'land-unit',
      text: 'Land Unit',
      ariaLabel: 'Land Unit',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setSymbol(null, {
          lng: localSearchResult.current.geometry.coordinates[0],
          lat: localSearchResult.current.geometry.coordinates[1],
        });
        setMilspec({ options: {}, symbol: SymbolSet.LandUnit });
        clearPopup();
      },
    });
    symbolSubMenuOptions.push({
      icon: 'land-equipment',
      text: 'Land Equipment',
      ariaLabel: 'Land Equipment',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setSymbol(null, {
          lng: localSearchResult.current.geometry.coordinates[0],
          lat: localSearchResult.current.geometry.coordinates[1],
        });
        setMilspec({ options: {}, symbol: SymbolSet.LandEquipment });
        clearPopup();
      },
    });
    symbolSubMenuOptions.push({
      icon: 'land-installation',
      text: 'Land Installation',
      ariaLabel: 'Land Installation',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setSymbol(null, {
          lng: localSearchResult.current.geometry.coordinates[0],
          lat: localSearchResult.current.geometry.coordinates[1],
        });
        setMilspec({ options: {}, symbol: SymbolSet.LandInstallation });
        clearPopup();
      },
    });
    const symbolSubMenu = <ContextMenu options={symbolSubMenuOptions} />;
    options.push({
      icon: 'svgM2525',
      text: 'Insert MIL-STD-2525 Symbol',
      ariaLabel: 'Insert MIL-STD-2525 Symbol',
      hasSubMenu: true,
      onClick: async () => {
        handleContextMenuClick(symbolSubMenu, loc, [253, -8]);
      },
    });

    return <ContextMenu options={options}></ContextMenu>;
  };

  const StandardContextMenu = (loc, insideMissionArea, gf, an) => {
    const options = [];

    if (!!gf.length && canEdit) {
      for (const geofence of gf) {
        const subMenuOptions = [];
        subMenuOptions.push({
          icon: 'pencil-alt',
          text: 'Edit ' + geofence.properties.name,
          ariaLabel: 'Edit ' + geofence.properties.name,
          onClick: async () => {
            clearPopup();
            await editGeofences(geofence);
          },
        });
        subMenuOptions.push({
          icon: 'trash',
          text: 'Delete ' + geofence.properties.name,
          ariaLabel: 'Delete ' + geofence.properties.name,
          onClick: async () => {
            clearPopup();
            await doDeleteGeofence(geofence);
            drawShapes();
          },
        });
        const subContext = <ContextMenu options={subMenuOptions} />;
        options.push({
          icon: 'shapes',
          text: geofence.properties.name,
          ariaLabel: geofence.properties.name,
          hasSubMenu: !!subMenuOptions.length,
          onClick: async () => {
            handleContextMenuClick(subContext, loc, [253, -8]);
          },
        });
      }
    }

    if (!!an.length && canEdit) {
      for (const annotation of an) {
        const subMenuOptions = [];
        subMenuOptions.push({
          icon: 'pencil-alt',
          text: 'Edit ' + annotation.properties.name,
          ariaLabel: 'Edit ' + annotation.properties.name,
          onClick: async () => {
            clearPopup();
            await editAnnotations(annotation);
          },
        });
        subMenuOptions.push({
          icon: 'trash',
          text: 'Delete ' + annotation.properties.name,
          ariaLabel: 'Delete ' + annotation.properties.name,
          onClick: async () => {
            clearPopup();
            await doDeleteAnnotation(annotation);
            drawShapes();
          },
        });
        const subContext = <ContextMenu options={subMenuOptions} />;
        options.push({
          icon: 'shapes',
          text: annotation.properties.name,
          ariaLabel: annotation.properties.name,
          hasSubMenu: !!subMenuOptions.length,
          onClick: async () => {
            handleContextMenuClick(subContext, loc, [253, -8]);
          },
        });
      }
    }

    const shapeSubMenuOptions = [];
    shapeSubMenuOptions.push({
      icon: 'draw-polygon',
      text: 'Polygon',
      ariaLabel: 'Polygon',
      onClick: () => {
        setShapeIcon('draw-polygon');
        localShapeIcon.current = 'draw-polygon';
        setMode(FeatureType.Shape);
        clearPopup();
      },
    });
    shapeSubMenuOptions.push({
      icon: ['far', 'circle'],
      text: 'Circle',
      ariaLabel: 'Circle',
      onClick: () => {
        setShapeIcon(['far', 'circle']);
        localShapeIcon.current = ['far', 'circle'];
        setMode(FeatureType.Shape);
        clearPopup();
      },
    });
    shapeSubMenuOptions.push({
      icon: ['far', 'square'],
      text: 'Rectangle',
      ariaLabel: 'Rectangle',
      onClick: () => {
        setShapeIcon(['far', 'square']);
        localShapeIcon.current = ['far', 'square'];
        setMode(FeatureType.Shape);
        clearPopup();
      },
    });
    shapeSubMenuOptions.push({
      icon: 'minus',
      text: 'Line',
      ariaLabel: 'Line',
      onClick: () => {
        setShapeIcon('minus');
        localShapeIcon.current = 'minus';
        setMode(FeatureType.Shape);
        clearPopup();
      },
    });
    shapeSubMenuOptions.push({
      icon: 'long-arrow-alt-right',
      text: 'Arrow',
      ariaLabel: 'Arrow',
      onClick: () => {
        setShapeIcon('long-arrow-alt-right');
        localShapeIcon.current = 'long-arrow-alt-right';
        setMode(FeatureType.Shape);
        clearPopup();
      },
    });

    const shapeSubMenu = <ContextMenu options={shapeSubMenuOptions} />;

    options.push({
      icon: 'pencil-alt',
      text: 'Create Shape',
      ariaLabel: 'Create Shape',
      hasSubMenu: !!shapeSubMenuOptions.length,
      onClick: async () => {
        handleContextMenuClick(shapeSubMenu, loc, [253, -8]);
      },
    });

    const poiSubMenuOptions = [];
    poiSubMenuOptions.push({
      icon: 'location-dot',
      text: 'Waypoint',
      ariaLabel: 'Waypoint',
      disabled: !showWaypoints,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setWaypoint(null, loc);
        clearPopup();
      },
    });
    poiSubMenuOptions.push({
      icon: 'image',
      text: 'Image',
      ariaLabel: 'Image',
      disabled: !showPhotos,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        _setImage(null, loc);
        clearPopup();
      },
    });
    const poiSubMenu = <ContextMenu options={poiSubMenuOptions} />;
    options.push({
      icon: 'map-pin',
      text: 'Create Point of Interest',
      ariaLabel: 'Create Point of Interest',
      hasSubMenu: !!poiSubMenuOptions.length,
      onClick: async () => {
        handleContextMenuClick(poiSubMenu, loc, [253, -8]);
      },
    });

    const symbolSubMenuOptions = [];
    symbolSubMenuOptions.push({
      icon: 'air',
      text: 'Air',
      ariaLabel: 'Air',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        setMilspec({ options: {}, symbol: SymbolSet.Air });
        _setSymbol(null, loc);
        clearPopup();
      },
    });
    symbolSubMenuOptions.push({
      icon: 'sea-surface',
      text: 'Sea Surface',
      ariaLabel: 'Sea Surface',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        setMilspec({ options: {}, symbol: SymbolSet.SeaSurface });
        _setSymbol(null, loc);
        clearPopup();
      },
    });

    symbolSubMenuOptions.push({
      icon: 'land-unit',
      text: 'Land Unit',
      ariaLabel: 'Land Unit',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        setMilspec({ options: {}, symbol: SymbolSet.LandUnit });
        _setSymbol(null, loc);
        clearPopup();
      },
    });
    symbolSubMenuOptions.push({
      icon: 'land-equipment',
      text: 'Land Equipment',
      ariaLabel: 'Land Equipment',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        setMilspec({ options: {}, symbol: SymbolSet.LandEquipment });
        _setSymbol(null, loc);
        clearPopup();
      },
    });
    symbolSubMenuOptions.push({
      icon: 'land-installation',
      text: 'Land Installation',
      ariaLabel: 'Land Installation',
      disabled: !showSymbols,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        setMilspec({ options: {}, symbol: SymbolSet.LandInstallation });
        _setSymbol(null, loc);
        clearPopup();
      },
    });
    const symbolSubMenu = <ContextMenu options={symbolSubMenuOptions} />;

    options.push({
      icon: 'svgM2525',
      text: 'Insert MIL-STD-2525 Symbol',
      ariaLabel: 'Insert MIL-STD-2525 Symbol',
      hasSubMenu: true,
      onClick: async () => {
        handleContextMenuClick(symbolSubMenu, loc, [253, -8]);
      },
    });

    if (insideMissionArea && canEdit) {
      const subMenuOptions = [];
      subMenuOptions.push({
        icon: 'pencil-alt',
        text: `Edit ${capitalize(secrets.EVENT_NAME)} Area`,
        ariaLabel: `Edit ${capitalize(secrets.EVENT_NAME)} Area`,
        onClick: async () => {
          clearPopup();
          await editMissionArea();
        },
      });
      subMenuOptions.push({
        icon: 'trash',
        text: `Delete ${capitalize(secrets.EVENT_NAME)} Area`,
        ariaLabel: `Delete ${capitalize(secrets.EVENT_NAME)} Area`,
        onClick: () => {
          clearPopup();
          setDeleteMissionArea(true);
        },
      });
      const subContext = <ContextMenu options={subMenuOptions} />;
      options.push({
        icon: 'draw-polygon',
        text: `${capitalize(secrets.EVENT_NAME)} Area`,
        ariaLabel: `${capitalize(secrets.EVENT_NAME)} Area`,
        hasSubMenu: !!subMenuOptions.length,
        onClick: async () => {
          handleContextMenuClick(subContext, loc, [253, -8]);
        },
      });
    }

    return <ContextMenu options={options}></ContextMenu>;
  };

  const UnitContextMenu = (unit) => {
    const options = [];

    if (!isMultiple && !localMissions.current[0].archived) {
      options.push({
        icon: 'user',
        text: 'User Info',
        ariaLabel: 'User Info',
        onClick: async () => {
          clearPopup();
          await setSelectedUser(unit, toLngLat(unit.coords));
          setUserTab('overview');
        },
      });
      if (!isMultiple) {
        if (unit.id !== userValue.user.id) {
          options.push({
            icon: 'comment-alt',
            text: 'Send Message',
            ariaLabel: 'Send Message',
            onClick: async () => {
              await setSelectedUser(unit, toLngLat(unit.coords));
              setUserTab('messages');
            },
          });
          if (unit?.communicationServices) {
            options.push({
              icon: 'phone-flip',
              text: 'Call User',
              ariaLabel: 'Call User',
              onClick: async () => {
                await comms.makePhoneCall(unit);
              },
            });
          }
        }
        options.push({
          icon: 'clipboard-list',
          text: 'View Tasks',
          ariaLabel: 'View Tasks',
          onClick: async () => {
            await setSelectedUser(unit, toLngLat(unit.coords));
            setUserTab('tasks');
          },
        });
      }

      if (localLiveTracking.current?.id === unit.id) {
        options.push({
          icon: 'crosshairs',
          text: 'Stop Tracking',
          ariaLabel: 'Stop Tracking',
          onClick: () => {
            setLiveTracking(null);
            toast.success('Live Tracking Disabled', {
              id: 'live-tracking-updated',
            });
            clearPopup();
          },
        });
      } else {
        options.push({
          icon: 'crosshairs',
          text: 'Live Track',
          ariaLabel: 'Live Track',
          onClick: () => {
            setLiveTracking(unit);
            toast.success('Live Tracking Enabled', {
              id: 'live-tracking-updated',
            });
            clearPopup();
          },
        });
      }

      const subMenuOptions = [];
      subMenuOptions.push({
        icon: 'location-dot',
        text: 'Waypoint',
        ariaLabel: 'Waypoint',
        disabled: !showWaypoints,
        onClick: () => {
          if (localMissions.current[0]?.archived) {
            toast.error(
              `You can't make changes to an archived ${secrets.EVENT_NAME}`
            );
            return;
          }
          _setWaypoint(null, toLngLat(unit.coords));
          clearPopup();
        },
      });
      subMenuOptions.push({
        icon: 'image',
        text: 'Image',
        ariaLabel: 'Image',
        disabled: !showPhotos,
        onClick: () => {
          if (localMissions.current[0]?.archived) {
            toast.error(
              `You can't make changes to an archived ${secrets.EVENT_NAME}`
            );
            return;
          }
          _setImage(null, toLngLat(unit.coords));
          clearPopup();
        },
      });
      const subContext = <ContextMenu options={subMenuOptions} />;
      options.push({
        icon: 'map-pin',
        text: 'Create Point of Interest',
        ariaLabel: 'Create Point of Interest',
        hasSubMenu: !!subMenuOptions.length,
        onClick: async () => {
          handleContextMenuClick(
            subContext,
            { lat: unit.coords.latitude, lng: unit.coords.longitude },
            [253, -24]
          );
        },
      });

      const symbolSubMenuOptions = [];
      symbolSubMenuOptions.push({
        icon: 'air',
        text: 'Air',
        ariaLabel: 'Air',
        disabled: !showSymbols,
        onClick: () => {
          if (localMissions.current[0]?.archived) {
            toast.error(
              `You can't make changes to an archived ${secrets.EVENT_NAME}`
            );
            return;
          }
          setMilspec({ options: {}, symbol: SymbolSet.Air });
          _setSymbol(null, toLngLat(unit.coords));
          clearPopup();
        },
      });
      symbolSubMenuOptions.push({
        icon: 'sea-surface',
        text: 'Sea Surface',
        ariaLabel: 'Sea Surface',
        disabled: !showSymbols,
        onClick: () => {
          if (localMissions.current[0]?.archived) {
            toast.error(
              `You can't make changes to an archived ${secrets.EVENT_NAME}`
            );
            return;
          }
          setMilspec({ options: {}, symbol: SymbolSet.SeaSurface });
          _setSymbol(null, toLngLat(unit.coords));
          clearPopup();
        },
      });
      symbolSubMenuOptions.push({
        icon: 'land-unit',
        text: 'Land Unit',
        ariaLabel: 'Land Unit',
        disabled: !showSymbols,
        onClick: () => {
          if (localMissions.current[0]?.archived) {
            toast.error(
              `You can't make changes to an archived ${secrets.EVENT_NAME}`
            );
            return;
          }
          setMilspec({ options: {}, symbol: SymbolSet.LandUnit });
          _setSymbol(null, toLngLat(unit.coords));
          clearPopup();
        },
      });
      symbolSubMenuOptions.push({
        icon: 'land-equipment',
        text: 'Land Equipment',
        ariaLabel: 'Land Equipment',
        disabled: !showSymbols,
        onClick: () => {
          if (localMissions.current[0]?.archived) {
            toast.error(
              `You can't make changes to an archived ${secrets.EVENT_NAME}`
            );
            return;
          }
          setMilspec({ options: {}, symbol: SymbolSet.LandEquipment });
          _setSymbol(null, toLngLat(unit.coords));
          clearPopup();
        },
      });
      symbolSubMenuOptions.push({
        icon: 'land-installation',
        text: 'Land Installation',
        ariaLabel: 'Land Installation',
        disabled: !showSymbols,
        onClick: () => {
          if (localMissions.current[0]?.archived) {
            toast.error(
              `You can't make changes to an archived ${secrets.EVENT_NAME}`
            );
            return;
          }
          setMilspec({ options: {}, symbol: SymbolSet.LandInstallation });
          _setSymbol(null, toLngLat(unit.coords));
          clearPopup();
        },
      });
      const symbolSubMenu = <ContextMenu options={symbolSubMenuOptions} />;
      options.push({
        icon: 'svgM2525',
        text: 'Insert MIL-STD-2525 Symbol',
        ariaLabel: 'Insert MIL-STD-2525 Symbol',
        hasSubMenu: true,
        onClick: async () => {
          handleContextMenuClick(
            symbolSubMenu,
            { lat: unit.coords.latitude, lng: unit.coords.longitude },
            [253, -24]
          );
        },
      });
    }

    return <ContextMenu options={options}></ContextMenu>;
  };

  const POIContextMenu = (poi) => {
    const _canDeletePoi = canDeletePoi(userValue.user, poi);

    const options = [];

    log.debug('poi', poi);

    options.push({
      icon: 'pencil-alt',
      text: `Edit ${poi.type === POI.Photo ? 'Image' : 'Waypoint'}`,
      onClick: () => {
        if (localMissions.current[0]?.archived) {
          toast.error(
            `You can't make changes to an archived ${secrets.EVENT_NAME}`
          );
          return;
        }
        if (poi.type === POI.Photo) {
          _setWaypoint(null);
          _setImage(poi, toLngLat(poi.coords ? poi.coords : poi.location));
        } else if (poi.type === POI.Waypoint) {
          _setImage(null);
          _setWaypoint(poi, toLngLat(poi.coords ? poi.coords : poi.location));
        }
        clearPopup();
      },
    });
    if (!isMultiple && _canDeletePoi) {
      options.push({
        icon: 'trash',
        text: `Delete ${poi.type === POI.Photo ? 'Image' : 'Waypoint'}`,
        onClick: () => {
          if (localMissions.current[0]?.archived) {
            toast.error(
              `You can't make changes to an archived ${secrets.EVENT_NAME}`
            );
            return;
          }
          if (setPoiToDelete) {
            setPoiToDelete(poi);
          }
          clearPopup();
        },
      });
    }

    return <ContextMenu options={options}></ContextMenu>;
  };

  const centreOnTargets = () => {
    // log.debug('active targets changed', targets, localPersonnel.current);

    if (targets && targets.length) {
      const coords = [];

      for (const t of targets) {
        switch (t.type) {
          case 'user': {
            const unit = localPersonnel.current.find((p) => p.id === t.id);
            if (unit && unit.coords) {
              coords.push(toArray(unit.coords));
            }
            break;
          }

          default:
            break;
        }
      }

      if (coords.length) {
        log.debug('target centering on coords', coords);

        const bounds = bbox({
          type: 'Polygon',
          coordinates: [coords],
        });
        if (map.current) {
          if (coords.length > 1) {
            map.current.fitBounds(bounds, { padding: 300 });
          } else {
            map.current.flyTo({ center: coords[0], zoom: 18, bearing: 0 });
          }
        }
      }
    }
  };

  useEffect(() => {
    centreOnTargets();
    // eslint-disable-next-line
  }, [targets]);

  useEffect(() => {
    updateSparkDetails();
    // eslint-disable-next-line
  }, [ignitionSeconds]);

  const isDarkMode = globalDarkMode.darkMode;

  //const broadcastOptions = [];
  // let broadcastTip = '';

  // if (locationServices.allowDesktopSharing) {
  //   if (locationServices.currentLocation) {
  //     broadcastTip = 'Location tracking enabled.';

  //     broadcastOptions.push({
  //       text: 'Centre on your location',
  //       onClick: () => {
  //         centreMapOnPersonnel(null);
  //       },
  //     });
  //     if (locationServices.broadcasting) {
  //       broadcastTip = `Your browser's location is being shared with the mission.`;

  //       broadcastOptions.push({
  //         text: 'Stop sharing location',
  //         onClick: () => {
  //           locationServices.setBroadcasting(false);
  //           toast.success('Stopped Sharing Location');
  //         },
  //       });
  //     } else {
  //       broadcastTip = `Your location has been manually set / your browser's location is not being shared with the mission.`;

  //       broadcastOptions.push({
  //         text: 'Start sharing location',
  //         ariaLabel: 'Start sharing location',
  //         onClick: async () => {
  //           locationServices.setBroadcasting(true);
  //         },
  //       });
  //     }
  //   } else {
  //     broadcastTip = 'Location tracking disabled.';

  //     broadcastOptions.push({
  //       text: 'Start sharing location',
  //       ariaLabel: 'Start sharing location',
  //       onClick: async () => {
  //         locationServices.setBroadcasting(true);
  //       },
  //     });
  //   }
  // } else {
  //   if (
  //     localPersonnel.current.find((u) => u.id === userValue.user.id && u.coords)
  //   ) {
  //     broadcastTip = 'Centre on your location';
  //   } else {
  //     broadcastTip = 'You are not active in this mission';
  //   }
  // }

  const filteredComms = sortBy(
    Object.values(comms.threads)
      .map((t) => {
        t.unread = getUnreadThread(
          userValue.user.id,
          localMissions.current[0].id,
          t.id,
          comms.threads,
          comms.threadMessages
        );
        return t;
      })
      .filter(
        (t) =>
          t.missionId === localMissions.current[0].id &&
          (t.missionWide ||
            t.unread ||
            activeThreads.find((at) => at.id === t.id))
      ),
    ['missionWide', 'lastMessage.timestamp'],
    ['desc', 'desc']
  );

  const isSysAdmin = () => {
    return (
      userValue?.user?.accessRoles?.length &&
      userValue?.user?.accessRoles.includes(Role.SysAdmin)
    );
  };

  const openChatThread = async (user, team, thread) => {
    if (!thread) {
      if (team) {
        thread = await getThreadByUsers(
          localMissions.current[0].id,
          [userValue.user.id],
          [team.id]
        ).catch(() => {});
      } else if (user) {
        thread = await getThreadByUsers(
          localMissions.current[0].id,
          [userValue.user.id, user.id],
          []
        ).catch(() => {});
      }
    }

    if (thread) {
      if (!activeThreads.find((g) => g.id === thread.id)) {
        const otherMissions = activeThreads.filter(
          (g) => g.missionId !== localMissions.current[0].id
        );
        const thisMission = activeThreads.filter(
          (g) => g.missionId === localMissions.current[0].id && !g.missionWide
        );
        if (thisMission.length >= MAX_THREADS) {
          setActiveThreads([
            ...otherMissions,
            ...thisMission.splice(0, MAX_THREADS - 1),
            { id: thread.id, missionId: thread.missionId },
          ]);
        } else {
          setActiveThreads([
            ...activeThreads,
            { id: thread.id, missionId: thread.missionId },
          ]);
        }
      }

      setCurrentThread(thread.id);
      comms.loadThread(thread);

      sidebars.open(SIDEBARS.missionBroadcast);
    }
  };

  const handleWarnings = (warnings, isMission = false) => {
    const allUsers = canEdit
      ? [...orgUsers, ...localPersonnel.current.filter((u) => u.guest)]
      : localPersonnel.current;

    const messages = [];
    if (warnings?.length) {
      for (const w of warnings) {
        const userId = w.includes(`'`) ? /'(.+?)'/.exec(w)[1] : w;
        const user = allUsers.find((p) => p.id === userId);
        if (user) {
          messages.push(`${displayName(user)}`);
        }
      }
    }

    if (messages.length) {
      if (messages.length > 1) {
        toast.success(
          messages.length +
            ' users already in ' +
            (isMission ? secrets.EVENT_NAME : 'team')
        );
      } else {
        toast.success(
          messages[0] +
            ' already in ' +
            (isMission ? secrets.EVENT_NAME : 'team')
        );
      }
    }
  };

  const addToMission = async (_users) => {
    if (localMissions.current[0]?.archived) {
      toast.error(
        `You can't make changes to an archived ${secrets.EVENT_NAME}`
      );
      return;
    }

    if (_users?.length) {
      for (const _user of _users) {
        if (
          _user?.organisationId &&
          _user?.organisationId !== localMissions.current[0].organisationId
        ) {
          toast.error(`You can't add users from another organisation`);
          return;
        }
      }
    }

    if (_users[0].type === 'role') {
      const rs = await addUsersToMission(
        localMissions.current[0].id,
        [],
        [_users[0].id]
      );

      setTriggerRefreshUsers(!triggerRefreshUsers);

      // await refreshMission();
      if (rs?.warnings?.length) {
        handleWarnings(rs?.warnings, true);
      } else {
        toast.success(
          `${displayName(_users[0])} added to ${secrets.EVENT_NAME}`
        );
      }
    } else {
      const rs = await addUsersToMission(
        localMissions.current[0].id,
        _users.map((u) => u.id),
        []
      );

      setTriggerRefreshUsers(!triggerRefreshUsers);

      // await refreshMission();
      if (rs?.warnings?.length) {
        handleWarnings(rs?.warnings, true);
      } else {
        toast.success(
          `${displayName(_users[0])} added to ${secrets.EVENT_NAME}`
        );
      }
    }
  };

  const removeFromMission = async (_users) => {
    const allUsers = canEdit
      ? [...orgUsers, ...localPersonnel.current.filter((u) => u.guest)]
      : localPersonnel.current;

    if (localMissions.current[0]?.archived) {
      toast.error(
        `You can't make changes to an archived ${secrets.EVENT_NAME}`
      );
      return;
    }

    // sanitise user ids
    const saneUsers = (_users || []).map((u) =>
      u.includes('_') ? u.split('_')[1] : u
    );
    const missionUser = allUsers.find((p) => p.id === saneUsers[0]);

    if (!missionUser) {
      const role = localRoles.current.find((r) => r.id === saneUsers[0]);
      if (role) {
        await deleteUsersFromMission(
          localMissions.current[0].id,
          [],
          [role.id]
        );

        setTriggerRefreshUsers(!triggerRefreshUsers);

        toast.success(`${role.role} removed from the ${secrets.EVENT_NAME}`);
      }
    } else {
      if (missionUser?.guest) {
        toast.error(`You can't remove a guest from the ${secrets.EVENT_NAME}`);
        return;
      }

      await deleteUsersFromMission(localMissions.current[0].id, saneUsers, []);

      setTriggerRefreshUsers(!triggerRefreshUsers);

      if (saneUsers?.length) {
        if (saneUsers.length > 1) {
          toast.success(
            `${saneUsers.length} users removed from the ${secrets.EVENT_NAME}`
          );
        } else {
          if (missionUser) {
            toast.success(
              `${displayName(missionUser)} removed from the ${
                secrets.EVENT_NAME
              }`
            );
          } else {
            toast.success(`User removed from the ${secrets.EVENT_NAME}`);
          }
        }
      }
    }
  };

  const addToTeam = async (teamId, users) => {
    const allUsers = canEdit
      ? [...orgUsers, ...localPersonnel.current.filter((u) => u.guest)]
      : localPersonnel.current;

    if (localMissions.current[0]?.archived) {
      toast.error(
        `You can't make changes to an archived ${secrets.EVENT_NAME}`
      );
      return;
    }

    // sanitise user ids
    let saneUsers = (users || []).map((u) =>
      u.includes('_') ? u.split('_')[1] : u
    );

    if (saneUsers?.length) {
      for (const uid of saneUsers) {
        const _user = allUsers.find((r) => r.id === uid);
        if (
          _user?.organisationId &&
          _user?.organisationId !== localMissions.current[0].organisationId
        ) {
          toast.error(`You can't add users from another organisation`);
          return;
        }
      }
    }
    const missionUser = allUsers.find((p) => p.id === saneUsers[0]);
    if (!missionUser) {
      saneUsers = allUsers
        .filter((au) => au.roleId === saneUsers[0])
        .map((au) => au.id);
    }

    const rs = await addUsersToTeam(
      localMissions.current[0].id,
      teamId,
      saneUsers
    );

    setTriggerRefreshUsers(!triggerRefreshUsers);

    if (rs?.warnings?.length) {
      handleWarnings(rs?.warnings);
    } else {
      if (saneUsers?.length) {
        if (saneUsers.length > 1) {
          toast.success(`${saneUsers.length} users added to team`);
        } else {
          const user = allUsers.find((p) => p.id === saneUsers[0]);
          if (user) {
            toast.success(`${displayName(user)} added to team`);
          } else {
            toast.success('User added to team');
          }
        }
      }
    }
  };

  const removeFromTeam = async (teamId, users) => {
    const allUsers = canEdit
      ? [...orgUsers, ...localPersonnel.current.filter((u) => u.guest)]
      : localPersonnel.current;

    if (localMissions.current[0]?.archived) {
      toast.error(`You can't make changes to an archived mission`);
      return;
    }

    // sanitise user ids
    const saneUsers = (users || []).map((u) =>
      u.includes('_') ? u.split('_')[1] : u
    );

    const rs = await deleteUsersFromTeam(
      localMissions.current[0].id,
      teamId,
      saneUsers
    );

    setTriggerRefreshUsers(!triggerRefreshUsers);

    if (rs?.warnings?.length) {
      handleWarnings(rs?.warnings);
    } else {
      if (saneUsers?.length) {
        if (saneUsers.length > 1) {
          toast.success(`${saneUsers.length} users removed from team`);
        } else {
          const user = allUsers.find((p) => p.id === saneUsers[0]);
          if (user) {
            toast.success(`${displayName(user)} removed from team`);
          } else {
            toast.success('User removed from team');
          }
        }
      }
    }
  };

  const createChatGroup = async (topic, unitsOrPersonnel) => {
    // thread created so add members
    const users = [userValue.user.id];
    const teams = [];
    log.debug('checking units', unitsOrPersonnel);
    for (const uop of unitsOrPersonnel) {
      if (uop.type === 'user') {
        users.push(uop.id);
      } else {
        teams.push(uop.id);
      }

      log.debug('creating thread with participants', users);

      const thread = await postThread(localMissions.current[0].id, null, {
        users,
        teams,
      }).catch((ex) => {
        handleError(ex);
        throw ex;
      });

      if (thread) {
        openChatThread(null, null, thread);
      }
    }
  };

  const updateChatGroup = async (id, topic, unitsOrPersonnel) => {
    // thread created so add members
    const users = [];
    const teams = [];

    log.debug('checking units', unitsOrPersonnel);
    for (const uop of unitsOrPersonnel) {
      if (uop.type === 'user') {
        users.push(uop.id);
      } else {
        teams.push(uop.id);
      }
    }

    log.debug('current thread', threadToEdit);

    const toRemove = [];
    const toAdd = [];

    for (const p of users) {
      if (!threadToEdit.participants.find((pp) => pp.id === p)) {
        toAdd.push(p);
      }
    }

    for (const p of threadToEdit.participants) {
      if (!users.find((pp) => pp === p.id)) {
        toRemove.push(p);
      }
    }

    if (topic !== threadToEdit.threadName) {
      log.debug('updating topic');
      await renameThread(localMissions.current[0].id, threadToEdit.id, topic);
    }
  };

  const handleDeleteShape = async () => {
    if (features[0]?.geometry?.type === 'Polygon') {
      const diff = difference(
        features[0]?.geometry,
        localMissions.current[0].bounds
      );
      if (!!!diff) {
        await doDeleteGeofence({
          id: features[0].properties.id,
          description: features[0].properties.name,
        });
      }
    } else if (features[0]?.geometry?.type === 'LineString') {
      await doDeleteAnnotation({
        id: features[0].properties.id,
        description: features[0].properties.name,
      });
    }

    editingFeatures.current = [];
    drawShapes();
    resetDrawMode();
    setConfirmDelete(false);
  };

  const handleDeleteMissionArea = async () => {
    if (!!localGeofences.current.length) {
      for (const f of localGeofences.current) {
        try {
          await deleteGeofence(localMissions.current[0]?.id, f.id);
          setDeleteMissionArea(null);
          localMissions.current[0].bounds = null;
        } catch (err) {
          handleError(err);
        }
      }
    } else {
      setDeleteMissionArea(null);
      localMissions.current[0].bounds = null;
    }
    toast.success(`${capitalize(secrets.EVENT_NAME)} area deleted`);
    await postMission(
      localMissions.current[0].id,
      only(localMissions.current[0], MISSION_FIELDS)
    );
    await updateMissionStatus(localMissions.current[0].id, false);
    await updateMission(localMissions.current[0].id);

    localGeofences.current = [];
    setGeofences([]);
    setFeatures([]);
    setMode('');

    drawMissionArea();
    drawShapes();
  };

  const randomLocation = (bounds) => {
    const [minX, minY, maxX, maxY] = bounds;
    return [
      Math.random() * (maxX - minX) + minX,
      Math.random() * (maxY - minY) + minY,
    ];
  };

  const generateRandomNumberBetween = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const simTimerFunc = () => {
    for (let p of localPersonnel.current) {
      const newLocation = randomLocation(bbox(localMissions.current[0].bounds));
      locationUpdate({
        userId: p.id,
        missionId: localMissions.current[0].id,
        location: {
          coords: {
            latitude: newLocation[1],
            longitude: newLocation[0],
            accuracy: generateRandomNumberBetween(5, 100),
            heading: generateRandomNumberBetween(0, 360),
            heading_accuracy: generateRandomNumberBetween(0, 180),
            speed: generateRandomNumberBetween(0, 110),
            speed_accuracy: generateRandomNumberBetween(0, 10),
          },
          battery: null,
        },
      });
    }

    clearTimeout(simTimer.current);
    simTimer.current = setTimeout(
      simTimerFunc,
      animationConfig.personnelMarkerInterval
    );
  };

  const getSymbolName = () => {
    let symbolSetId = milspec?.symbol.slice(4, 6);
    switch (symbolSetId) {
      case '01':
        return 'Air Symbol';
      case '30':
        return 'Sea Surface Symbol';
      case '10':
        return 'Land Unit Symbol';
      case '15':
        return 'Land Equipment Symbol';
      case '20':
        return 'Land Installation Symbol';
      default:
        return 'Symbol';
    }
  };

  return (
    <>
      <EventManager element={window} event="resize" handler={calculatePPM()} />
      <EventManager
        element={window}
        event="keydown"
        handler={(e) => {
          if (e.code === 'Escape') {
            // ESC now cancels
            cancelDraw();
          }
        }}
      />
      {showSearch.current && (
        <EventManager
          element={showSearch.current._inputEl}
          event="keypress"
          handler={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();

              const lastSelected = showSearch.current.lastSelected
                ? JSON.parse(showSearch.current.lastSelected)
                : null;

              if (lastSelected?.bbox) {
                map.current.fitBounds(lastSelected.bbox);
              } else if (lastSelected?.center) {
                map.current.flyTo({ center: lastSelected.center });
              }
            }
          }}
        />
      )}

      {!isSysAdmin() ? (
        <div style={{ position: 'absolute' }}>{joyride.joyrideComponent}</div>
      ) : (
        <></>
      )}

      {DEBUG && (
        <div
          style={{
            position: 'absolute',
            top: '90px',
            left: '120px',
            color: 'white',
            backgroundColor: 'black',
            zIndex: '100',
          }}
        >
          <div>
            Markers on screen: {Object.keys(mapMarkersOnScreen.current).length}
          </div>
          <div>Total markers: {Object.keys(mapMarkers.current).length}</div>
        </div>
      )}
      {!mapLoaded && (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ animation: 'rotation 2s infinite linear' }}>
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20, color: 'white' }}>
            Loading map
          </div>
        </div>
      )}
      <div
        id="map"
        className={getClassNames({
          'mission-map-container': true,
          readOnly: !isOwner,
          'dark-mode': isDarkMode,
          hidden: !mapLoaded,
          call:
            comms.callState !== CallState.Disconnected &&
            comms.callState !== CallState.None,
        })}
        ref={mapContainer}
      >
        <div
          className={getClassNames({
            'mission-info-container': true,
            'dark-mode': isDarkMode,
            'step-1': true,
          })}
        >
          <MissionInfo
            missions={missions}
            history={history}
            centreMap={centreMapOnMissionArea}
            updateMission={updateMission}
            enableMissionCreation={true}
            localMissions={localMissions}
            FeatureType={FeatureType}
            editMissionArea={editMissionArea}
            mapLoaded={mapLoaded}
            mode={mode}
            form={formType}
          />
          {!isMultiple && (
            <div
              className="extra-map-controls top-left-1"
              style={{
                width: `calc(${
                  showComms ? filteredComms.length + 4 : 3
                } * var(--toolbar-size))`,
              }}
            >
              <DotButton
                tip="Manage Forms"
                place="bottom"
                className={getClassNames({
                  'map-styles-bar-button': true,
                })}
                style={{
                  opacity: formType ? 0.3 : 1,
                  borderRight: 'solid 1px #c7cce6',
                }}
                disabled={!!formType}
                onClick={() => {
                  setManageForms(true);
                }}
              >
                <img
                  src={formsSvg}
                  alt={'formsSvg'}
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                />
              </DotButton>
              <MenuButton
                position="bottom"
                options={[
                  {
                    text: FormType.SR,
                    ariaLabel: FormType.SR,
                    onClick: () => {
                      setFormType(FormType.SR);
                      localFormType.current = FormType.SR;
                      // open half screen sidebar
                      sidebars.open(SIDEBARS.form);
                      clearPopup();
                    },
                  },
                  {
                    text: FormType.MR,
                    ariaLabel: FormType.MR,
                    onClick: () => {
                      setFormType(FormType.MR);
                      localFormType.current = FormType.MR;
                      // open half screen sidebar
                      sidebars.open(SIDEBARS.form);
                    },
                    disabled: true,
                  },
                  {
                    text: FormType.OD,
                    ariaLabel: FormType.OD,
                    onClick: () => {
                      setFormType(FormType.OD);
                      localFormType.current = FormType.OD;
                      // open half screen sidebar
                      sidebars.open(SIDEBARS.form);
                    },
                    disabled: true,
                  },
                  {
                    text: FormType.CT,
                    ariaLabel: FormType.CT,
                    onClick: () => {
                      setFormType(FormType.CT);
                      localFormType.current = FormType.CT;
                      // open half screen sidebar
                      sidebars.open(SIDEBARS.form);
                    },
                    disabled: true,
                  },
                  {
                    text: FormType.CTD,
                    ariaLabel: FormType.CTD,
                    onClick: () => {
                      setFormType(FormType.CTD);
                      localFormType.current = FormType.CTD;
                      // open half screen sidebar
                      sidebars.open(SIDEBARS.form);
                    },
                    disabled: true,
                  },
                  {
                    text: FormType.CV,
                    ariaLabel: FormType.CV,
                    onClick: () => {
                      setFormType(FormType.CV);
                      localFormType.current = FormType.CV;
                      // open half screen sidebar
                      sidebars.open(SIDEBARS.form);
                    },
                    disabled: true,
                  },
                ]}
                ariaLabel="Create Form"
                tip={`Create Form`}
                place="bottom"
                className={getClassNames({
                  'map-styles-bar-button': true,
                  action: true,
                })}
              >
                <img
                  src={createFormsSvg}
                  alt={'formsSvg'}
                  style={{ height: '25px', width: '25px' }}
                />
              </MenuButton>
              <DotButton
                tip="Comms"
                place="bottom"
                className={getClassNames({
                  'map-styles-bar-button': true,
                  'step-14': true,
                  active: showComms,
                })}
                style={{
                  opacity: formType ? 0.3 : 1,
                  borderTop: 'none',
                  borderLeft: '1px solid rgb(199, 204, 230)',
                  borderBottomLeftRadius: '0px',
                }}
                disabled={!!formType}
                onClick={async () => {
                  if (showComms) {
                    setShowComms(false);
                    setCurrentThread(null);
                  } else {
                    await initChats();
                    setShowComms(true);
                  }
                }}
                badge={unreadThreads}
              >
                <FontAwesomeIcon icon="comment-alt" />
              </DotButton>
              {showComms &&
                filteredComms &&
                filteredComms.map((thread, index) => {
                  return (
                    <div
                      key={index}
                      className={getClassNames({
                        thread: true,
                        'map-styles-bar-button': true,
                      })}
                    >
                      <DotButton
                        tip={thread.threadName}
                        place="bottom"
                        badge={thread.unread}
                        className={getClassNames({
                          disabled:
                            currentThread && currentThread !== thread.id,
                        })}
                        onClick={() => {
                          if (currentThread === thread.id) {
                            setCurrentThread(null);
                            sidebars.close(SIDEBARS.missionBroadcast);
                          } else {
                            setCurrentThread(thread.id);
                            sidebars.open(SIDEBARS.missionBroadcast);
                          }
                        }}
                      >
                        {thread.missionWide ? (
                          <div
                            className={getClassNames({
                              'thread-icon': true,
                              'dark-mode': isDarkMode,
                            })}
                          >
                            <img
                              src={svgMissionBroadcast}
                              alt="mission broadcast"
                              className="mission-broadcast"
                            />
                          </div>
                        ) : (
                          <Avatar entity={thread} size={'2.5rem'} />
                        )}
                      </DotButton>
                    </div>
                  );
                })}
              {showComms && (
                <DotButton
                  tip="Create a new chat"
                  place="bottom"
                  className={getClassNames({
                    thread: true,
                    'map-styles-bar-button': true,
                  })}
                  style={{ borderTop: 'none' }}
                  onClick={() => {
                    setThreadToEdit({ id: '' });
                  }}
                >
                  <FontAwesomeIcon icon="plus" />
                </DotButton>
              )}
            </div>
          )}
        </div>
        {/* Spark Demo */}
        {spark && (
          <div className="mission-info-container integration-slider-container">
            <div className="integration-slider">
              {/* <div className="integration-slider-title">CFU Training Exercise</div> */}
              <p>Simulation of fire spread created in Spark</p>
              <div className="session">
                {/* <h5>Parameters</h5> */}
                <div className="parameters">
                  <div className="parameter">
                    <div>
                      <FontAwesomeIcon
                        icon={faWind}
                        width="20px"
                        height="20px"
                      />{' '}
                      Wind Bearing
                    </div>
                    <span>{windBearingDisplay}</span>
                  </div>
                  <div className="parameter">
                    <div>
                      <FontAwesomeIcon
                        icon={faFire}
                        width="20px"
                        height="20px"
                      />{' '}
                      Ignition Point
                    </div>
                    <span>-33.72523, 150.27682</span>
                  </div>
                </div>
              </div>
              <div className="session">
                <h5>Minutes after ignition</h5>
                <div className="row colors"></div>
                <div className="row labels">
                  <div className="label">5</div>
                  <div className="label">10</div>
                  <div className="label">15</div>
                  <div className="label">20</div>
                  <div className="label">25</div>
                  <div className="label">30</div>
                  <div className="label">35</div>
                  <div className="label">40</div>
                  <div className="label">45</div>
                  <div className="label">50</div>
                  <div className="label">55</div>
                  <div className="label">60</div>
                </div>
              </div>
              <div className="session">
                <h5>
                  <label id="active-min">{ignitionDisplay}</label>
                </h5>
                <input
                  id="slider"
                  className="row"
                  type="range"
                  min="0"
                  max="3600"
                  step="1"
                  onChange={onChangeIgnitionMinutes}
                  value={ignitionSeconds}
                />
              </div>
            </div>
          </div>
        )}
        {/* Spark Demo Ends */}
        <div className="extra-map-controls right">
          <DotButton
            tip="Zoom to All Users"
            className="map-styles-bar-button"
            onClick={() => zoomToAllUsers()}
          >
            <FontAwesomeIcon
              icon="vector-square"
              style={{
                height: '15px',
                width: '15px',
              }}
            />
          </DotButton>
          <DotButton
            tip={`Centre on ${capitalize(secrets.EVENT_NAME)} Area`}
            className="map-styles-bar-button"
            onClick={() => centreMapOnMissionArea()}
            disabled={!localMissions.current[0].bounds}
          >
            <img
              src={svgMission}
              alt={`Centre on ${capitalize(secrets.EVENT_NAME)} Area`}
            />
          </DotButton>
          {/* {locationServices.allowDesktopSharing ? (
            <MenuButton
              tip={broadcastTip}
              className={getClassNames({
                active: locationServices.broadcasting,
                inactive: !locationServices.currentLocation,
              })}
              buttonTheme="light"
              options={broadcastOptions}
              position="left"
            >
              <FontAwesomeIcon
                icon="location-arrow"
                style={{
                  height: '15px',
                  width: '15px',
                }}
              />
            </MenuButton>
          ) : (
            <DotButton
              tip={broadcastTip}
              className="map-styles-bar-button"
              onClick={() => centreMapOnPersonnel(null)}
              disabled={
                !localPersonnel.current.find(
                  (u) => u.id === userValue.user.id && u.coords
                )
              }
            >
              <FontAwesomeIcon
                icon="location-arrow"
                style={{
                  height: '15px',
                  width: '15px',
                }}
              />
            </DotButton>
          )} */}
        </div>

        {!isMultiple && (
          <>
            {canEdit && !localMissions.current[0].archived && (
              <>
                <div
                  className={getClassNames({
                    'extra-map-controls': true,
                    'left top': canEdit,
                    'left toptop': !canEdit,
                  })}
                >
                  <DotButton
                    tip={
                      localMissions.current[0].bounds
                        ? 'Create Shape'
                        : `Create ${secrets.EVENT_NAME} area before creating geofences`
                    }
                    place="right"
                    className={getClassNames({
                      'map-styles-bar-button': true,
                      active: mode === FeatureType.Shape,
                      'step-12': true,
                    })}
                    onClick={async () => {
                      if (mode !== FeatureType.Shape) {
                        setMode('');
                        editingFeatures.current = [];
                        setFeatures([]);
                        localFeatures.current = [];
                        setMode(FeatureType.Shape);
                      } else {
                        setMode('');
                        setFeatures([]);
                      }
                      await refreshGeofences();
                      await refreshAnnotations();
                    }}
                    disabled={
                      !localMissions.current[0].bounds ||
                      !mapLoaded ||
                      formType ||
                      !showGeofences
                    }
                  >
                    {shapeIcon === 'shapes' ? (
                      <img
                        src={svgShapes}
                        alt="shapes"
                        className={getClassNames({
                          'light-color':
                            mode === FeatureType.Shape || isDarkMode,
                        })}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={shapeIcon}
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                      />
                    )}
                  </DotButton>
                  <DotButton
                    tip={'Create Point of Interest'}
                    place="right"
                    className={getClassNames({
                      'map-styles-bar-button': true,
                      active:
                        mode === POI.Photo ||
                        mode === POI.Waypoint ||
                        mode === FeatureType.Point,
                      'step-25': true,
                    })}
                    disabled={
                      !mapLoaded || formType || (!showWaypoints && !showPhotos)
                    }
                    onClick={createPointOfInterest}
                  >
                    <FontAwesomeIcon
                      icon={poiIcon}
                      style={{
                        height: '15px',
                        width: '15px',
                      }}
                    />
                  </DotButton>
                  <DotButton
                    tip={'Insert MIL-STD-2525 Symbol'}
                    place="right"
                    className={getClassNames({
                      'map-styles-bar-button': true,
                      active:
                        mode === FeatureType.Symbol ||
                        mode === SymbolType.Air ||
                        mode === SymbolType.SeaSurface ||
                        mode === SymbolType.LandUnit ||
                        mode === SymbolType.LandEquipment ||
                        mode === SymbolType.LandInstallation,
                      'step-26': true,
                    })}
                    disabled={!mapLoaded || formType || !showSymbols}
                    onClick={insertSymbol}
                  >
                    <img
                      className={getClassNames({
                        'light-color': mode === FeatureType.Symbol,
                        'avatar-img': symbolIcon !== svgM2525,
                      })}
                      src={symbolIcon}
                      alt="symbol"
                      style={{
                        height: '15px',
                        width: '15px',
                        verticalAlign: 'bottom',
                      }}
                    />
                  </DotButton>
                </div>
                {mode === FeatureType.Mission && (
                  <div
                    ref={drawToolsPanel}
                    className="extra-map-controls draw-toolbar"
                    style={{
                      boxShadow: 'none',
                      minWidth: !!missions[0].bounds
                        ? 'calc(var(--toolbar-size) * 8)'
                        : 'calc(var(--toolbar-size) * 6)',
                      zIndex: 1,
                    }}
                  >
                    <DotButton
                      className={getClassNames({
                        'ally-icon': true,
                        disabled: false,
                      })}
                      style={{ color: '#85898c' }}
                      onClick={editMissionArea}
                      tip="Cancel"
                      place="right"
                    >
                      <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
                    </DotButton>
                    <span
                      style={{
                        color: !!missions[0].bounds
                          ? isDarkMode
                            ? 'white'
                            : 'black'
                          : '#85898c',
                      }}
                    >
                      {!!missions[0].bounds
                        ? `${capitalize(secrets.EVENT_NAME)} Area`
                        : 'Drawing...'}
                    </span>

                    {!!missions[0].bounds && (
                      <>
                        <DotButton
                          className={getClassNames({
                            'ally-icon': true,
                            disabled: false,
                          })}
                          onClick={() => {
                            if (draw.current) {
                              const drawMode = draw.current.getMode();
                              if (drawMode !== 'rotate_mode') {
                                draw.current.changeMode('rotate_mode', {
                                  canScale: true,
                                  canRotate: true, // only rotation enabled
                                  canTrash: false,
                                  rotatePivot: SRCenter.Center, // rotate around center
                                  scaleCenter: SRCenter.Opposite,
                                });
                              } else {
                                draw.current.changeMode('direct_select', {
                                  featureId: editingFeatures.current[0]?.id,
                                });
                              }
                            }
                          }}
                          tip="Rotate"
                          place="right"
                        >
                          <FontAwesomeIcon icon="rotate" />
                        </DotButton>
                        <DotButton
                          className={getClassNames({
                            'ally-icon': true,
                            disabled: false,
                          })}
                          onClick={() => {
                            setDeleteMissionArea(true);
                          }}
                          tip={`Delete ${capitalize(secrets.EVENT_NAME)} Area`}
                          place="right"
                        >
                          <FontAwesomeIcon icon="trash" />
                        </DotButton>
                      </>
                    )}
                    <DotButton
                      className={getClassNames({
                        'ally-icon': true,
                        disabled: false,
                      })}
                      onClick={undoDraw}
                      tip="Undo"
                      place="right"
                    >
                      <FontAwesomeIcon icon="undo" />
                    </DotButton>
                    <DotButton
                      className={getClassNames({
                        'ally-icon': true,
                        disabled: false,
                      })}
                      onClick={() => {
                        if (!!!localMissions.current[0].bounds) {
                          const features = (
                            draw.current.getAll()?.features || []
                          ).filter(
                            (f) => f.geometry.coordinates[0]?.length > 4
                          );

                          const incomplete = (
                            draw.current.getAll()?.features || []
                          ).filter(
                            (f) =>
                              f.geometry.coordinates[0]?.length < 5 &&
                              f.geometry.coordinates[0]?.length > 2
                          );

                          log.debug(features);
                          if (features.length) {
                            draw.current.changeMode('simple_select');
                            setMode('');
                            editingFeatures.current = [];
                            setFeatures([]);
                          } else if (incomplete.length) {
                            toast.error('You must place at least three dots');
                          } else {
                            toast.error(
                              `Click/tap on the map to start creating the ${secrets.EVENT_NAME} area`
                            );
                          }
                        } else {
                          mapDrawSelectionChange(null);
                          setMode('');
                          editingFeatures.current = [];
                          setFeatures([]);
                        }
                      }}
                      tip="Finish and Save"
                      place="right"
                    >
                      <FontAwesomeIcon icon="save" />
                    </DotButton>
                  </div>
                )}
                {mode === FeatureType.Shape && (
                  <>
                    <div
                      ref={drawToolsPanel}
                      className="extra-map-controls draw-panel"
                      style={{
                        backgroundColor: 'transparent',
                        alignItems: 'flex-end',
                        boxShadow: 'none',
                        minHeight: 'calc(var(--toolbar-size) * 2)',
                        zIndex: 1,
                      }}
                    >
                      <div
                        className="category-panel"
                        style={{
                          width: `var(--toolbar-size)`,
                        }}
                      >
                        <>
                          <DotButton
                            className={getClassNames({
                              'ally-icon': true,
                              disabled: false,
                            })}
                            onClick={async () => {
                              setMode(FeatureType.Shape);
                              setShapeIcon('draw-polygon');
                              localShapeIcon.current = 'draw-polygon';
                            }}
                            tip="Polygon"
                            place="right"
                          >
                            <FontAwesomeIcon icon="draw-polygon" />
                          </DotButton>
                          <DotButton
                            className={getClassNames({
                              'ally-icon': true,
                              disabled: false,
                            })}
                            onClick={async () => {
                              setMode(FeatureType.Shape);
                              setShapeIcon(['far', 'circle']);
                              localShapeIcon.current = ['far', 'circle'];
                            }}
                            tip="Circle"
                            place="right"
                          >
                            <FontAwesomeIcon icon={['far', 'circle']} />
                          </DotButton>
                          <DotButton
                            className={getClassNames({
                              'ally-icon': true,
                              disabled: false,
                            })}
                            onClick={async () => {
                              setMode(FeatureType.Shape);
                              setShapeIcon(['far', 'square']);
                              localShapeIcon.current = ['far', 'square'];
                            }}
                            tip="Rectangle"
                            place="right"
                          >
                            <FontAwesomeIcon icon={['far', 'square']} />
                          </DotButton>
                          <DotButton
                            className={getClassNames({
                              'ally-icon': true,
                              disabled: false,
                            })}
                            onClick={async () => {
                              setMode(FeatureType.Shape);
                              setShapeIcon('minus');
                              localShapeIcon.current = 'minus';
                            }}
                            tip="Line"
                            place="right"
                          >
                            <FontAwesomeIcon icon="minus" />
                          </DotButton>
                          <DotButton
                            className={getClassNames({
                              'ally-icon': true,
                              disabled: false,
                            })}
                            onClick={async () => {
                              setMode(FeatureType.Shape);
                              setShapeIcon('long-arrow-alt-right');
                              localShapeIcon.current = 'long-arrow-alt-right';
                            }}
                            tip="Arrow"
                            place="right"
                          >
                            <FontAwesomeIcon icon="long-arrow-alt-right" />
                          </DotButton>
                          <DotButton
                            className={getClassNames({
                              'ally-icon': true,
                              disabled: false,
                            })}
                            style={{ color: '#85898c' }}
                            onClick={() => {
                              cancelShape();
                              localShapeIcon.current = 'shapes';
                              setShapeIcon('shapes');
                            }}
                            tip="Cancel"
                            place="right"
                          >
                            <FontAwesomeIcon
                              icon="times"
                              style={{ color: 'grey' }}
                            />
                          </DotButton>
                        </>
                      </div>
                    </div>
                  </>
                )}
                {mode === FeatureType.Shape &&
                  !!editingFeatures.current.length &&
                  !!features.length && (
                    <div
                      ref={drawToolsPanel}
                      className="extra-map-controls draw-toolbar"
                      style={{
                        boxShadow: 'none',
                        minWidth: !!editingFeatures.current[0]?.geometry
                          ? 'calc(var(--toolbar-size) * 8)'
                          : 'calc(var(--toolbar-size) * 6)',
                        zIndex: 1,
                      }}
                    >
                      <DotButton
                        className={getClassNames({
                          'ally-icon': true,
                          disabled: false,
                        })}
                        style={{ color: '#85898c' }}
                        onClick={() => {
                          if (localShapeIcon.current === 'shapes') {
                            setMode('');
                          } else {
                            const current = draw.current.getAll();
                            const drawing =
                              current.features[current.features.length - 1];
                            if (localShapeIcon.current === 'draw-polygon') {
                              const points =
                                drawing.geometry.coordinates[0].length - 2;
                              for (let i = 0; i < points; i++) {
                                draw.current.trash();
                              }
                            } else {
                              draw.current.trash();
                            }
                            editingFeatures.current = [];
                            localShapeIcon.current = 'shapes';
                            setShapeIcon('shapes');
                          }
                        }}
                        tip="Cancel"
                        place="right"
                      >
                        <FontAwesomeIcon
                          icon="times"
                          style={{ color: 'grey' }}
                        />
                      </DotButton>
                      <span
                        style={{
                          color: !!editingFeatures.current[0]?.properties?.name
                            ? isDarkMode
                              ? 'white'
                              : 'black'
                            : '#85898c',
                        }}
                      >
                        {editingFeatures.current[0]?.properties?.name ||
                          'Drawing shape...'}
                      </span>
                      {!!editingFeatures.current.length &&
                        editingFeatures.current[0]?.id !== 'new' && (
                          <DotButton
                            className={getClassNames({
                              'ally-icon': true,
                              disabled:
                                editingFeatures.current[0]?.geometry.type !==
                                  'LineString' &&
                                !!difference(
                                  editingFeatures.current[0]?.geometry,
                                  localMissions.current[0].bounds
                                ),
                            })}
                            tip="Delete"
                            place="right"
                            onClick={async () => {
                              if (
                                editingFeatures.current[0]?.geometry.type ===
                                  'LineString' ||
                                !!!difference(
                                  editingFeatures.current[0]?.geometry,
                                  localMissions.current[0].bounds
                                )
                              ) {
                                setConfirmDelete(true);
                              }
                            }}
                          >
                            <FontAwesomeIcon icon="trash" />
                          </DotButton>
                        )}
                      {!!editingFeatures.current.length &&
                        editingFeatures.current[0]?.id !== 'new' && (
                          <DotButton
                            className={getClassNames({
                              'ally-icon': true,
                              disabled: false,
                            })}
                            onClick={() => {
                              if (draw.current) {
                                const drawMode = draw.current.getMode();
                                if (drawMode !== 'rotate_mode') {
                                  draw.current.changeMode('rotate_mode', {
                                    canScale: true,
                                    canRotate: true, // only rotation enabled
                                    canTrash: false,
                                    rotatePivot: SRCenter.Center, // rotate around center
                                    scaleCenter: SRCenter.Opposite,
                                  });
                                } else {
                                  draw.current.changeMode('direct_select', {
                                    featureId: editingFeatures.current[0]?.id,
                                  });
                                }
                              }
                            }}
                            tip="Rotate"
                            place="right"
                          >
                            <FontAwesomeIcon icon="rotate" />
                          </DotButton>
                        )}
                      <DotButton
                        className={getClassNames({
                          'ally-icon': true,
                          disabled: false,
                        })}
                        onClick={undoDraw}
                        tip="Undo"
                        place="right"
                      >
                        <FontAwesomeIcon icon="undo" />
                      </DotButton>
                      <DotButton
                        className={getClassNames({
                          'ally-icon': true,
                          disabled: false,
                        })}
                        onClick={() => {
                          const current = draw.current.getAll();
                          const drawing =
                            current.features[current.features.length - 1];
                          if (!!!drawing.properties.id) {
                            if (drawing.geometry?.type === 'Polygon') {
                              const points =
                                drawing.geometry.coordinates[0].length;
                              if (points === 1) {
                                toast.error('Nothing to save');
                                return;
                              }
                              if (points < 5) {
                                toast.error(
                                  'You must place at least three dots'
                                );
                                return;
                              }
                            }
                            if (drawing.geometry?.type === 'LineString') {
                              const points =
                                drawing.geometry.coordinates.length - 1;
                              if (points < 2) {
                                toast.error('You must place at least two dots');
                                return;
                              }
                            }
                          }
                          mapDrawSelectionChange(null);
                          draw.current.changeMode('simple_select');
                        }}
                        tip="Save"
                        place="right"
                      >
                        <FontAwesomeIcon icon="save" />
                      </DotButton>
                    </div>
                  )}
                {(mode === FeatureType.Point ||
                  mode === POI.Waypoint ||
                  mode === POI.Photo) && (
                  <div
                    ref={drawToolsPanel}
                    className="extra-map-controls poi-panel"
                    style={{
                      backgroundColor: 'transparent',
                      alignItems: 'flex-end',
                      boxShadow: 'none',
                      zIndex: 1,
                    }}
                  >
                    <div
                      className="category-panel"
                      style={{
                        width: `var(--toolbar-size)`,
                      }}
                    >
                      <DotButton
                        tip="Waypoint"
                        place="right"
                        className={getClassNames({
                          'map-styles-bar-button': true,
                          'step-20': true,
                        })}
                        disabled={!showWaypoints}
                        onClick={() => {
                          if (localMissions.current[0]?.archived) {
                            toast.error(
                              `You can't make changes to an archived ${secrets.EVENT_NAME}`
                            );
                            return;
                          }
                          setMode(POI.Waypoint);
                          setPoiIcon('location-dot');
                        }}
                      >
                        <FontAwesomeIcon
                          icon="location-dot"
                          style={{
                            height: '15px',
                            width: '15px',
                          }}
                        />
                      </DotButton>
                      <DotButton
                        tip="Image"
                        place="right"
                        className={getClassNames({
                          'map-styles-bar-button': true,
                          'step-21': true,
                        })}
                        disabled={!showPhotos}
                        onClick={() => {
                          if (localMissions.current[0]?.archived) {
                            toast.error(
                              `You can't make changes to an archived ${secrets.EVENT_NAME}`
                            );
                            return;
                          }
                          setMode(POI.Photo);
                          setPoiIcon('image');
                        }}
                      >
                        <FontAwesomeIcon
                          icon="image"
                          style={{
                            height: '15px',
                            width: '15px',
                          }}
                        />
                      </DotButton>
                      <DotButton
                        className={getClassNames({
                          'ally-icon': true,
                          disabled: false,
                        })}
                        onClick={() => {
                          setMode('');
                          setFeatures([]);
                          setPoiIcon('map-pin');
                        }}
                        tip="Cancel"
                        place="right"
                      >
                        <FontAwesomeIcon
                          icon="times"
                          style={{ color: 'grey' }}
                        />
                      </DotButton>
                    </div>
                  </div>
                )}
                {(mode === FeatureType.Symbol ||
                  mode === SymbolType.Air ||
                  mode === SymbolType.SeaSurface ||
                  mode === SymbolType.LandUnit ||
                  mode === SymbolType.LandEquipment ||
                  mode === SymbolType.LandInstallation) && (
                  <div
                    ref={drawToolsPanel}
                    className="extra-map-controls symbol-panel"
                    style={{
                      backgroundColor: 'transparent',
                      alignItems: 'flex-end',
                      boxShadow: 'none',
                      zIndex: 1,
                    }}
                  >
                    <div
                      className="category-panel"
                      style={{
                        width: `var(--toolbar-size)`,
                      }}
                    >
                      <DotButton
                        tip="Air"
                        place="right"
                        className={getClassNames({
                          'map-styles-bar-button': true,
                        })}
                        onClick={() => {
                          setMode(SymbolType.Air);
                          setSymbolIcon(
                            new ms.Symbol(SymbolSet.Air).asCanvas().toDataURL()
                          );
                        }}
                      >
                        <img
                          src={new ms.Symbol(SymbolSet.Air)
                            .asCanvas()
                            .toDataURL()}
                          alt={'Air'}
                          style={{
                            height: '15px',
                            width: '15px',
                          }}
                          className="avatar-img"
                        />
                      </DotButton>
                      <DotButton
                        tip="Sea Surface"
                        place="right"
                        className={getClassNames({
                          'map-styles-bar-button': true,
                        })}
                        onClick={() => {
                          setMode(SymbolType.SeaSurface);
                          setSymbolIcon(
                            new ms.Symbol(SymbolSet.SeaSurface)
                              .asCanvas()
                              .toDataURL()
                          );
                        }}
                      >
                        <img
                          src={new ms.Symbol(SymbolSet.SeaSurface)
                            .asCanvas()
                            .toDataURL()}
                          alt="SeaSurface"
                          style={{
                            height: '15px',
                            width: '15px',
                          }}
                          className="avatar-img"
                        />
                      </DotButton>
                      <DotButton
                        tip="Land Unit"
                        place="right"
                        className={getClassNames({
                          'map-styles-bar-button': true,
                        })}
                        onClick={() => {
                          setMode(SymbolType.LandUnit);
                          setSymbolIcon(
                            new ms.Symbol(SymbolSet.LandUnit)
                              .asCanvas()
                              .toDataURL()
                          );
                        }}
                      >
                        <img
                          src={new ms.Symbol(SymbolSet.LandUnit)
                            .asCanvas()
                            .toDataURL()}
                          alt="LandUnit"
                          style={{
                            height: '15px',
                            width: '15px',
                          }}
                          className="avatar-img"
                        />
                      </DotButton>
                      <DotButton
                        tip="Land Equipment"
                        place="right"
                        className={getClassNames({
                          'map-styles-bar-button': true,
                        })}
                        onClick={() => {
                          setMode(SymbolType.LandEquipment);
                          setSymbolIcon(
                            new ms.Symbol(SymbolSet.LandEquipment)
                              .asCanvas()
                              .toDataURL()
                          );
                        }}
                      >
                        <img
                          src={new ms.Symbol(SymbolSet.LandEquipment)
                            .asCanvas()
                            .toDataURL()}
                          alt="LandEquipment"
                          style={{
                            height: '15px',
                            width: '15px',
                          }}
                          className="avatar-img"
                        />
                      </DotButton>
                      <DotButton
                        tip="Land Installation"
                        place="right"
                        className={getClassNames({
                          'map-styles-bar-button': true,
                        })}
                        onClick={() => {
                          setMode(SymbolType.LandInstallation);
                          setSymbolIcon(
                            new ms.Symbol(SymbolSet.LandInstallation)
                              .asCanvas()
                              .toDataURL()
                          );
                        }}
                      >
                        <img
                          src={new ms.Symbol(SymbolSet.LandInstallation)
                            .asCanvas()
                            .toDataURL()}
                          alt="LandInstallation"
                          style={{
                            height: '15px',
                            width: '15px',
                          }}
                          className="avatar-img"
                        />
                      </DotButton>
                      <DotButton
                        className={getClassNames({
                          'ally-icon': true,
                          disabled: false,
                        })}
                        onClick={() => {
                          setMode('');
                          setSymbolIcon(svgM2525);
                        }}
                        tip="Cancel"
                        place="right"
                      >
                        <FontAwesomeIcon
                          icon="times"
                          style={{ color: 'grey' }}
                        />
                      </DotButton>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        <div
          className={getClassNames({
            'extra-map-controls': true,
            'bottom-left': true,
          })}
        >
          <DotButton
            tip={'3D View'}
            place="right"
            className={getClassNames({
              'map-styles-bar-button': true,
              active: _3dView,
              'step-22': true,
            })}
            onClick={() => {
              if (!map.current.getPitch()) {
                map.current.flyTo({ pitch: 75 });
              } else {
                map.current.flyTo({ pitch: 0 });
              }
            }}
            style={{ borderBottom: 'solid 1px #c7cce6' }}
          >
            <img
              className={getClassNames({ 'light-color': _3dView })}
              src={svg3d}
              alt={'3D View'}
            />
          </DotButton>
          <MenuButton
            className="map-styles-bar-button step-23"
            position="top-right"
            buttonTheme="light"
            tip="Map Styles"
            place="right"
            disabled={!!formType}
            options={styles.map((s) => {
              return {
                text: (
                  <>
                    <FontAwesomeIcon
                      icon={splitIcon(s.icon)}
                      style={{ marginRight: '10px' }}
                    />{' '}
                    {s.title}
                  </>
                ),
                onClick: () => {
                  setSelectedStyle(s.url);
                  localSelectedStyle.current = s.url;
                },
              };
            })}
          >
            {' '}
            <FontAwesomeIcon
              icon={
                splitIcon(styles.find((s) => s.url === selectedStyle)?.icon) ||
                'road'
              }
            />
          </MenuButton>
          <DotButton
            place="right"
            className={getClassNames({
              'map-styles-bar-button': true,
              active: sidebars.isOpen(SIDEBARS.missionLayers),
              'step-24': true,
            })}
            disabled={formType}
            onClick={() => {
              if (sidebars.isOpen(SIDEBARS.missionLayers)) {
                sidebars.close(SIDEBARS.missionLayers);
              } else {
                sidebars.open(SIDEBARS.missionLayers);
              }
            }}
            tip={'Settings'}
          >
            <FontAwesomeIcon icon="gear" />
          </DotButton>
        </div>
        <div
          className={getClassNames({
            'extra-map-controls': true,
            'integration-panel': true,
          })}
          style={{
            width:
              (integrations?.filter((i) => i.visibleInToolbar).length +
                (integrations?.filter((i) => !i.visibleInToolbar).length &&
                integrations?.filter((i) => i.visibleInToolbar).length
                  ? 1
                  : 0) +
                1) *
              52.5,
          }}
        >
          <DotButton
            tip={'Integrations'}
            place="right"
            className={getClassNames({
              'map-styles-bar-button': true,
              'has-integrations': integrations?.length > 0 ? true : false,
              active: sidebars.isOpen(SIDEBARS.missionActiveIntegrations),
              'step-27': true,
            })}
            disabled={formType}
            onClick={() => {
              sidebars.isOpen(SIDEBARS.missionActiveIntegrations)
                ? sidebars.close(SIDEBARS.missionActiveIntegrations)
                : sidebars.open(SIDEBARS.missionActiveIntegrations);
            }}
          >
            <img
              src={svgUnion}
              alt={'Integrations'}
              className={getClassNames({
                'light-color':
                  isDarkMode ||
                  sidebars.isOpen(SIDEBARS.missionActiveIntegrations)
                    ? true
                    : false,
              })}
            />
          </DotButton>
          {integrations?.length
            ? integrations
                ?.filter((i) => i.visibleInToolbar)
                .map((i, index) => {
                  return (
                    <>
                      <DotButton
                        key={index}
                        tip={i.organisationIntegration.name}
                        place="right"
                        className={getClassNames({
                          'map-styles-bar-button': true,
                          active: [...integrations].find((_i) => _i.id === i.id)
                            .active,
                          'has-hidden-integrations':
                            integrations?.filter((i) => !i.visibleInToolbar)
                              .length &&
                            integrations?.filter((i) => i.visibleInToolbar)
                              .length
                              ? true
                              : false,
                        })}
                        onClick={() => {
                          const _integrations = [...integrations];
                          const active = _integrations.find(
                            (_i) => _i.id === i.id
                          ).active;
                          _integrations.find((_i) => _i.id === i.id).active =
                            !active;
                          setIntegrations(_integrations);

                          if (
                            active === true &&
                            i.organisationIntegration.code === `BOM_WEATHER`
                          ) {
                            const id = `integration-OPENWEATHER`;
                            if (map.current.getLayer(id)) {
                              map.current.removeLayer(id);
                            }
                            if (map.current.getSource(id)) {
                              map.current.removeSource(id);
                            }
                            localWeatherLayer.current = null;
                            setWeatherLayer(null);
                            setSettingWeatherLocation(false);
                            if (localWeatherMarker.current.length > 0) {
                              for (const i of localWeatherMarker.current) {
                                i.remove();
                              }
                            }
                          }
                        }}
                      >
                        <img
                          src={i.organisationIntegration.photoUrl}
                          alt="integration logo"
                          style={{
                            height: '35px',
                            width: '35px',
                            borderRadius: '50%',
                            border: isDarkMode
                              ? 'solid white 3px'
                              : 'solid #c7cce6 3px',
                          }}
                          className="avatar-img"
                        />
                      </DotButton>
                    </>
                  );
                })
            : null}
          {integrations?.some((i) => !i.visibleInToolbar) &&
            integrations?.some((i) => i.visibleInToolbar) && (
              <span
                className="integrations-count"
                onClick={() => {
                  sidebars.open(SIDEBARS.missionActiveIntegrations);
                }}
              >
                <span>
                  + {integrations?.filter((i) => !i.visibleInToolbar).length}
                </span>
              </span>
            )}
        </div>
        {<div id={'cursor-location-info'} className="cursor-location"></div>}
        {<div id={'zoom-level-info'} className="zoom-level"></div>}
        {localWeatherLayer.current === 'temp_new' && (
          <div className="weather-label">Temperature (&deg;C)</div>
        )}
        {localWeatherLayer.current === 'precipitation_new' && (
          <div className="weather-label">Rainfall (mm)</div>
        )}
        {localWeatherLayer.current === 'wind_new' && (
          <div className="weather-label">
            Wind {localWindDirection.current} (km/h)
          </div>
        )}
        {localWeatherLayer.current === 'clouds_new' && (
          <div className="weather-label">Cloudiness (%)</div>
        )}
        {!!localWeatherLayer.current && (
          <div
            className={getClassNames({
              'weather-scale': true,
            })}
          >
            {localWeatherLayer.current === 'temp_new' && (
              <>
                <img src={scaleTemp} alt="Temp" />
                <div className="numbers">
                  <span>{'<-10'}</span>
                  <span>0</span>
                  <span>10</span>
                  <span>20</span>
                  <span>25</span>
                  <span>30</span>
                  <span>40</span>
                  <span>50+</span>
                </div>
              </>
            )}
            {localWeatherLayer.current === 'precipitation_new' && (
              <>
                <img src={scaleRain} alt="Rain" />
                <div className="numbers">
                  <span>0</span>
                  <span>0.1</span>
                  <span>0.2</span>
                  <span>0.5</span>
                  <span>1</span>
                  <span>10</span>
                  <span>140</span>
                </div>
              </>
            )}
            {localWeatherLayer.current === 'wind_new' && (
              <>
                <img src={scaleWind} alt="Wind" />
                <div className="numbers">
                  <span>0</span>
                  <span>15</span>
                  <span>30</span>
                  <span>50</span>
                  <span>70+</span>
                </div>
              </>
            )}
            {localWeatherLayer.current === 'clouds_new' && (
              <>
                <img
                  style={{
                    backgroundColor: `rgba(0,0,0,0.5)`,
                    borderRadius: '25px',
                  }}
                  src={scaleCloud}
                  alt="Cloud"
                />
                <div className="numbers">
                  <span>0</span>
                  <span>25</span>
                  <span>50</span>
                  <span>75</span>
                  <span>100</span>
                </div>
              </>
            )}
          </div>
        )}

        <div className="extra-map-controls top-right-1">
          <div>
            <DotButton
              disabled={!mapLoaded}
              tip="Search Location"
              className="map-styles-bar-button step-15"
              onClick={() => {
                if (showSearch.current) {
                  removeGeocoder();
                } else {
                  if (rolesExpanded) {
                    closeRoles();
                  }
                  if (teamsExpanded) {
                    closeTeams();
                  }
                  addGeocoder();
                }
              }}
            >
              <FontAwesomeIcon icon="search" />
            </DotButton>
          </div>
        </div>
        <div className="extra-map-controls top-right-1 close hide">
          <div>
            <DotButton
              tip="Close Search"
              className="map-styles-bar-button"
              onClick={() => {
                if (showSearch.current) {
                  removeGeocoder();
                } else {
                  addGeocoder();
                }
              }}
              ariaLabel="Close"
            >
              <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
            </DotButton>
          </div>
        </div>
        {rolesExpanded && (
          <div
            ref={rolesPanel}
            className="extra-map-controls roles-panel"
            style={{
              backgroundColor: 'transparent',
              alignItems: 'flex-end',
              boxShadow: 'none',
            }}
          >
            <div
              className="category-panel"
              style={{
                width: `calc(${Math.ceil(
                  (roles.length + 1) / MAX_ROLES
                )} * var(--toolbar-size))`,
              }}
            >
              {roles.map((role) => (
                <div key={role.id} className="ally">
                  <DotButton
                    style={{
                      border: `solid 3px ${role.color}`,
                    }}
                    className={getClassNames({
                      'ally-icon': true,
                      disabled:
                        (showOnlyRole.length &&
                          !showOnlyRole.includes(role.id)) ||
                        !showOnlyRole,
                    })}
                    onClick={() => {
                      if (showOnlyRole.includes(role.id)) {
                        setShowOnlyRole(
                          showOnlyRole.filter((r) => r !== role.id)
                        );
                      } else {
                        setShowOnlyRole([...showOnlyRole, role.id]);
                      }
                    }}
                    onLongPress={() => {
                      setShowOnlyRole([role.id]);
                    }}
                    tip={role.role}
                  >
                    <Avatar entity={role} size={'1.625rem'} />
                  </DotButton>
                </div>
              ))}
              <div>
                <DotButton
                  style={{
                    border: `none`,
                    background: 'transparent',
                  }}
                  onClick={() => {
                    if (sidebars.isOpen(SIDEBARS.missionRoles)) {
                      sidebars.close(SIDEBARS.missionRoles);
                    } else {
                      sidebars.open(SIDEBARS.missionRoles);
                    }
                  }}
                  tip={
                    (!sidebars.isOpen(SIDEBARS.missionRoles)
                      ? 'View '
                      : 'Close ') + 'Mission Roles'
                  }
                  disabled={formType}
                >
                  <FontAwesomeIcon
                    icon={
                      !sidebars.isOpen(SIDEBARS.missionRoles) ? 'info' : 'times'
                    }
                    size={'1.625rem'}
                  />
                </DotButton>
              </div>
            </div>
          </div>
        )}
        {teamsExpanded && (
          <div
            ref={teamsPanel}
            className="extra-map-controls teams-panel"
            style={{
              backgroundColor: 'transparent',
              alignItems: 'flex-end',
              boxShadow: 'none',
            }}
          >
            <div
              className="category-panel"
              style={{
                width: `calc(${Math.ceil(
                  (teams.length + 1) / MAX_TEAMS
                )} * var(--toolbar-size))`,
              }}
            >
              {teams.map((team) => (
                <div key={team.id} className="ally">
                  <DotButton
                    style={{
                      border: `solid 3px ${team.color}`,
                    }}
                    className={getClassNames({
                      'ally-icon': true,
                      disabled:
                        (showOnlyTeam.length &&
                          !showOnlyTeam.includes(team.id)) ||
                        !showTeams,
                    })}
                    onClick={() => {
                      if (showOnlyTeam.includes(team.id)) {
                        setShowOnlyTeam(
                          showOnlyTeam.filter((t) => t !== team.id)
                        );
                      } else {
                        setShowOnlyTeam([...showOnlyTeam, team.id]);
                      }
                    }}
                    onLongPress={() => {
                      setShowOnlyTeam([team.id]);
                    }}
                    tip={team.team}
                  >
                    <Avatar entity={team} size={'1.625rem'} />
                  </DotButton>
                </div>
              ))}
              <div>
                <DotButton
                  onClick={() => {
                    if (sidebars.isOpen(SIDEBARS.missionTeams)) {
                      sidebars.close(SIDEBARS.missionTeams);
                    } else {
                      sidebars.open(SIDEBARS.missionTeams);
                    }
                  }}
                  tip={
                    (!sidebars.isOpen(SIDEBARS.missionTeams)
                      ? 'View '
                      : 'Close ') + `${capitalize(secrets.EVENT_NAME)} Teams`
                  }
                  style={{ border: 'none', background: 'transparent' }}
                >
                  <FontAwesomeIcon
                    icon={
                      !sidebars.isOpen(SIDEBARS.missionTeams) ? 'info' : 'times'
                    }
                    size={'1.625rem'}
                  />
                </DotButton>
              </div>
            </div>
          </div>
        )}
        <div
          className="extra-map-controls top-right-2"
          style={{
            width: `calc(var(--toolbar-size))`,
            backgroundColor: 'transparent',
            alignItems: 'flex-end',
          }}
        >
          <div
            className="category-toolbar step-16"
            style={{
              width: `calc(var(--toolbar-size))`,
            }}
          >
            <DotButton
              className={getClassNames({
                ally: true,
                active: rolesExpanded,
              })}
              style={rolesExpanded ? { border: 'none' } : {}}
              onClick={() => {
                if (rolesExpanded) {
                  closeRoles();
                } else {
                  openRoles();
                }
              }}
              place="left"
              tip={
                showOnlyRole.length
                  ? `Showing ${pluralize('role', showOnlyRole.length, true)}`
                  : 'Roles'
              }
              badge={showOnlyRole.length || ''}
              disabled={formType}
            >
              <FontAwesomeIcon icon="users" />
            </DotButton>
          </div>
          <div
            className="category-toolbar step-17"
            style={{
              width: `calc(var(--toolbar-size))`,
            }}
          >
            <DotButton
              className={getClassNames({
                ally: true,
                active: teamsExpanded,
              })}
              style={teamsExpanded ? { border: 'none' } : {}}
              onClick={() => {
                if (teamsExpanded) {
                  closeTeams();
                } else {
                  openTeams();
                }
              }}
              place="left"
              tip={
                showOnlyTeam.length
                  ? `Showing ${pluralize('team', showOnlyTeam.length, true)}`
                  : 'Teams'
              }
              badge={showOnlyTeam.length || ''}
              disabled={formType}
            >
              <FontAwesomeIcon icon="project-diagram" />
            </DotButton>
          </div>
          <div
            className="category-toolbar step-18"
            style={{
              width: `calc(var(--toolbar-size))`,
            }}
          >
            <DotButton
              className={getClassNames({
                ally: true,
                active: sidebars.isOpen(SIDEBARS.missionUsers),
              })}
              style={
                sidebars.isOpen(SIDEBARS.missionUsers) ? { border: 'none' } : {}
              }
              onClick={() => {
                if (sidebars.isOpen(SIDEBARS.missionUsers)) {
                  sidebars.close();
                } else {
                  sidebars.open(SIDEBARS.missionUsers);
                  setShowTeamsEditor(
                    canEdit && !localMissions.current[0].archived && !isMultiple
                  );
                }
              }}
              place="left"
              tip={'Users'}
              disabled={formType}
            >
              <FontAwesomeIcon icon="user-friends" />
            </DotButton>
          </div>
        </div>
        {/* <StylesBar
          styles={styles}
          selectedStyle={selectedStyle}
          setSelectedStyle={setSelectedStyle}
        /> */}
        {mode === POI.Waypoint && (
          <div className="help bottom">
            <span>Click/tap on the map to create waypoint</span>
          </div>
        )}
        {mode === POI.Photo && (
          <div className="help bottom">
            <span>Click/tap on the map to insert an image</span>
          </div>
        )}
        {(mode === SymbolType.Air ||
          mode === SymbolType.SeaSurface ||
          mode === SymbolType.LandUnit ||
          mode === SymbolType.LandEquipment ||
          mode === SymbolType.LandInstallation) && (
          <div className="help bottom">
            <span>Click/tap on the map to insert a symbol</span>
          </div>
        )}
        {mode === FeatureType.Mission && (
          <div className="help bottom">
            <span>
              <div style={{ display: 'flex' }}>
                <strong>{capitalize(secrets.EVENT_NAME)} Area</strong>
              </div>
              <div>
                <table style={{ width: '20rem' }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>Square Kilometers</td>
                      <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {localMissions.current[0].bounds
                          ? Math.round(
                              (area(localMissions.current[0].bounds) /
                                (1000 * 1000.0)) *
                                100.0
                            ) / 100.0
                          : 0}
                        km<sup>2</sup>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left' }}>Shape Areas</td>
                      <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {localGeofences.current.length}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left' }}>Assigned Teams</td>
                      <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {localTeams.current.length - 1}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left' }}>Users</td>
                      <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {localPersonnel.current.length}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  fontStyle: 'italic',
                  fontSize: '0.9em',
                  maxWidth: '30rem',
                }}
              >
                {localMissions.current[0].bounds ? (
                  <>
                    Click and drag the centre of the {secrets.EVENT_NAME} area
                    to move it. Click and drag the points to modify the{' '}
                    {secrets.EVENT_NAME} area. Click a point and press DEL to
                    remove the point. Click on the map or the Save button to
                    complete drawing. Press ESC to cancel.
                  </>
                ) : (
                  <>
                    Click on the map to start creating the {secrets.EVENT_NAME}{' '}
                    area. Click on the final point or the Save button to
                    complete drawing. Press ESC to cancel drawing.
                  </>
                )}
              </div>
            </span>
          </div>
        )}
        {mode === FeatureType.Shape &&
          (localShapeIcon.current === 'draw-polygon' ||
            localShapeIcon.current.includes('square') ||
            localShapeIcon.current.includes('circle')) && (
            <div className="help bottom">
              {/* <span>
              // Click/tap on the map to start creating the geofence. Click on
              the // starting dot again to finish or press ESC. //{' '}
            </span> */}
              <span>
                {!!features ? (
                  <>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <strong>
                          {features.length > 1
                            ? 'Multiple Shapes Selected'
                            : features[0]?.properties.name || 'New Shape'}
                        </strong>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    fontStyle: 'italic',
                    fontSize: '0.9em',
                    maxWidth: '30rem',
                  }}
                >
                  {features.length > 1 ? (
                    <>
                      Multiple shapes selected. Click and drag the body of one
                      shape to move all selections. Click on the map to finish
                      editing. Press ESC to cancel drawing.
                    </>
                  ) : !!editingFeatures.current.length &&
                    editingFeatures.current[0]?.id !== 'new' ? (
                    <>
                      Click and drag the centre of a shape to move it.
                      Double-click the shape to edit it then click and drag the
                      points to modify the shape or click a point and press DEL
                      to remove the point. Click on the map or the Save button
                      to complete drawing. Press ESC to cancel drawing.
                    </>
                  ) : editingFeatures.current[0]?.id === 'new' &&
                    !!!localShapeIcon.current.includes('circle') &&
                    !!!localShapeIcon.current.includes('square') ? (
                    <>
                      Click on the map to start drawing. Click the final point
                      or the Save button to complete drawing. Press ESC to
                      cancel drawing.
                    </>
                  ) : editingFeatures.current[0]?.id === 'new' &&
                    localShapeIcon.current.includes('circle') ? (
                    <>
                      Click and drag on the map to start drawing. Release the
                      click to complete drawing. Press ESC to cancel drawing.
                    </>
                  ) : editingFeatures.current[0]?.id === 'new' &&
                    localShapeIcon.current.includes('square') ? (
                    <>
                      Click on the map and move the mouse to start drawing.
                      Click again to complete drawing. Press ESC to cancel
                      drawing.
                    </>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Select a shape to draw or click on an existing shape to
                      select it.
                    </div>
                  )}
                </div>
              </span>
            </div>
          )}
        {mode === FeatureType.Shape &&
          (localShapeIcon.current === 'minus' ||
            localShapeIcon.current === 'long-arrow-alt-right') && (
            <div className="help bottom">
              {/* <span>
              // Click/tap on the map to start creating the geofence. Click on
              the // starting dot again to finish or press ESC. //{' '}
            </span> */}
              <span>
                {!!features ? (
                  <>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <strong>
                          {features.length > 1
                            ? 'Multiple Annotations Selected'
                            : features[0]?.properties.name || 'New Annotation'}
                        </strong>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    fontStyle: 'italic',
                    fontSize: '0.9em',
                    maxWidth: '30rem',
                  }}
                >
                  {features.length > 1 ? (
                    <>
                      Multiple annotations selected. Click and drag the body of
                      one annotation to move all selections. Click on the map to
                      finish editing. Press ESC to cancel.
                    </>
                  ) : editingFeatures.current[0]?.id !== 'new' ? (
                    <>
                      Click and drag the body of an annotation to move it. Click
                      and drag the points to change the direction or length.
                      Click a point and press DEL to remove it. Press ESC to
                      cancel.
                    </>
                  ) : editingFeatures.current[0]?.id === 'new' ? (
                    <>
                      Click on the map to start drawing an annotation. Click the
                      final point or the Save button to complete drawing. Press
                      ESC to cancel.
                    </>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Click an annotation to select it. Click and drag the body
                      of the annotation to move it once selected. Double click
                      an annotation to edit it.
                    </div>
                  )}
                </div>
              </span>
            </div>
          )}
        {!!liveTracking && !mode && (
          <div
            className="help bottom"
            style={{ cursor: 'pointer', pointerEvents: 'visible' }}
            onClick={() => {
              setLiveTracking(null);
              toast.success('Live Tracking Disabled', {
                id: 'live-tracking-updated',
              });
            }}
          >
            <span>
              Currently tracking{' '}
              {displayName(
                localPersonnel.current.find((p) => p.id === liveTracking.id)
              )}
              . Click here to stop tracking.
            </span>
          </div>
        )}
      </div>
      <DragDropContext
        onDragStart={(e) => {
          log.debug('onDragStart', e);
          const user = localPersonnel.current.find(
            (u) =>
              u.id ===
              (e.draggableId.includes('_')
                ? e.draggableId.split('_')[1]
                : e.draggableId)
          );
          if (!user?.guest) {
            setDraggingFrom(e.source.droppableId);
          } else {
            setDraggingFrom('guest');
          }
        }}
        onDragEnd={(result) => {
          log.debug('onDragEnd', result);
          const { source, destination } = result;

          const teamId = destination?.droppableId?.startsWith('team-')
            ? destination?.droppableId?.replace('team-', '')
            : '';

          log.debug('dropped', result.draggableId, teamId);

          if (source?.index >= 0) {
            switch (destination?.droppableId) {
              case 'inventory':
                break;
              case 'remove-team':
                if (source?.droppableId?.includes('team-')) {
                  removeFromTeam(source?.droppableId?.replace('team-', ''), [
                    result.draggableId,
                  ]);
                }
                break;
              case 'remove-mission':
                removeFromMission([result.draggableId]);
                break;
              default:
                if (teamId) {
                  if (source.droppableId.includes('inventory')) {
                    addToTeam(teamId, [result.draggableId]);
                  } else if (source.droppableId.includes('team')) {
                    addToTeam(teamId, [result.draggableId]);
                  }
                }
                break;
            }
          }
        }}
      >
        {showTeamsEditor && (
          <div
            ref={teamsEditor}
            className={getClassNames({
              'teams-editor': true,
              call:
                comms.callState !== CallState.Disconnected &&
                comms.callState !== CallState.None,
            })}
          >
            <DarkScrollbar>
              {canEdit && !localMissions.current[0].archived && (
                <div className="team new">
                  <div
                    className="body new"
                    onClick={() => {
                      setTeam(null);
                      sidebars.open(SIDEBARS.editTeam);
                    }}
                  >
                    <FontAwesomeIcon icon="plus" />
                    <span>Create New Team</span>
                  </div>
                </div>
              )}
              {teams
                ?.filter((t) => t.id !== 'unassigned')
                ?.map((t, index) => (
                  <MissionTeamPanel
                    key={index}
                    missionId={localMissions.current[0].id}
                    team={t}
                    onEdit={(_t) => {
                      setTeam(_t);
                      sidebars.open(SIDEBARS.editTeam);
                    }}
                    removeFromTeam={removeFromTeam}
                    removeFromMission={removeFromMission}
                    triggerRefreshUsers={triggerRefreshUsers}
                    archived={localMissions.current[0].archived}
                  />
                ))}
            </DarkScrollbar>
            <Droppable droppableId={`remove-team`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={getClassNames({
                    remove: true,
                    highlight:
                      draggingFrom &&
                      (draggingFrom?.includes('team-') ||
                        draggingFrom?.includes('guest')),
                    dim:
                      draggingFrom &&
                      !draggingFrom?.includes('team-') &&
                      !draggingFrom?.includes('guest'),
                  })}
                >
                  <div>
                    <FontAwesomeIcon icon="minus-circle" />
                  </div>
                  <div>Drag a user here to remove them from a team.</div>
                </div>
              )}
            </Droppable>
          </div>
        )}
        <div
          className={getClassNames({
            'dark-mode': isDarkMode,
          })}
        >
          <Sidebar id={SIDEBARS.missionUsers}>
            <MissionUsers
              closeSidebar={() => {
                sidebars.close(SIDEBARS.missionUsers);
                closeTeamsEditor();
              }}
              canEdit={canEdit && !isMultiple}
              isMultiple={isMultiple}
              orgUsers={orgUsers}
              users={personnel}
              setUser={(user) => {
                if (isMultiple) {
                  toast.error(
                    `No user details available for multi-${secrets.EVENT_NAME} view`
                  );
                  return;
                }
                setUser(user);
                setUserTab('');
                sidebars.open(SIDEBARS.missionUser, SIDEBARS.missionUsers);
              }}
              chatWithUser={async (user) => {
                closeTeamsEditor();
                await openChatThread(user, null);
              }}
              roles={!localMissions.current[0]?.archived ? roles : []}
              mission={localMissions.current[0]}
              draggingFrom={draggingFrom}
              addToMission={addToMission}
              removeFromMission={removeFromMission}
              centreMapOnPersonnel={centreMapOnPersonnel}
            />
          </Sidebar>
        </div>
      </DragDropContext>
      <div
        className={getClassNames({
          'dark-mode': isDarkMode,
        })}
      >
        <Sidebar id={SIDEBARS.integrationInfo}></Sidebar>
        <Sidebar id={SIDEBARS.editTeam}>
          <CreateEditTeam
            closeSidebar={() => {
              setTeam(null);
              sidebars.open(SIDEBARS.missionUsers);
              setShowTeamsEditor(canEdit && !isMultiple);
              loadTeams();
            }}
            team={team}
            missionId={localMissions.current[0].id}
          />
        </Sidebar>
        <Sidebar id={SIDEBARS.missionActiveIntegrations}>
          <MissionAddedIntegrations
            closeSidebar={() =>
              sidebars.close(SIDEBARS.missionActiveIntegrations)
            }
            addIntegration={() =>
              sidebars.open(
                SIDEBARS.missionIntegrations,
                SIDEBARS.missionActiveIntegrations
              )
            }
            allIntegrations={integrations}
            setAllIntegrations={setIntegrations}
            MAX_INTEGRATIONS={MAX_INTEGRATIONS}
            missionId={localMissions.current[0].id}
            isDarkMode={isDarkMode}
            removeWeatherLayer={removeWeatherLayer}
          />
        </Sidebar>
        <Sidebar id={SIDEBARS.missionIntegrations}>
          <MissionActiveIntegrations
            closeSidebar={() => sidebars.close(SIDEBARS.missionIntegrations)}
            allIntegrations={integrations}
            setAllIntegrations={setIntegrations}
          />
        </Sidebar>
        <Sidebar id={SIDEBARS.missionTeams}>
          <MissionTeams
            closeSidebar={() => {
              // update list
              setTeamsExpanded(false);
              sidebars.close(SIDEBARS.missionTeams);
            }}
            teams={teams}
            roles={roles}
            isOwner={isOwner}
            showOnlyTeam={showOnlyTeam}
            setShowOnlyTeam={setShowOnlyTeam}
            users={personnel}
            setUser={(user) => {
              setUser(user);
              sidebars.open(SIDEBARS.missionUser, SIDEBARS.missionTeams);
            }}
            openChatThread={openChatThread}
          />
        </Sidebar>
        <Sidebar id={SIDEBARS.missionRoles}>
          <MissionRoles
            closeSidebar={() => {
              // update list
              setRolesExpanded(false);
              sidebars.close(SIDEBARS.missionRoles);
            }}
            teams={teams}
            roles={roles}
            isOwner={isOwner}
            showOnlyRole={showOnlyRole}
            setShowOnlyRole={setShowOnlyRole}
            users={personnel}
            setUser={(user) => {
              setUser(user);
              sidebars.open(SIDEBARS.missionUser, SIDEBARS.missionRoles);
            }}
          />
        </Sidebar>
        <Sidebar id={SIDEBARS.missionLayers}>
          <MissionLayerSettings
            closeSidebar={() => sidebars.close(SIDEBARS.missionLayers)}
            layerSettings={{
              missionId: missions[0].id,
              darkMode: {
                get: globalDarkMode.darkMode,
                set: (value) => {
                  globalDarkMode.setDarkMode(value);
                  localDarkMode.current = value;
                },
              },
              clustering: {
                get: showClustering,
                set: setShowClustering,
              },
              missionArea: {
                get: showMissionArea,
                set: setShowMissionArea,
              },
              missionGeofences: {
                get: showGeofences,
                set: setShowGeofences,
              },
              missionAnnotations: {
                get: showAnnotations,
                set: setShowAnnotations,
              },
              missionGeofenceLabels: {
                get: showGeofenceLabels,
                set: setShowGeofenceLabels,
              },
              missionAnnotationLabels: {
                get: showAnnotationLabels,
                set: setShowAnnotationLabels,
              },
              personnelLabels: {
                get: showPersonnelLabels,
                set: setShowPersonnelLabels,
              },
              tracks: {
                get: showTracks,
                set: setShowTracks,
              },
              waypoints: {
                get: showWaypoints,
                set: setShowWaypoints,
              },
              photos: {
                get: showPhotos,
                set: setShowPhotos,
              },
              symbols: {
                get: showSymbols,
                set: setShowSymbols,
              },
              mgrs: {
                get: showMGRS,
                set: (checked) => {
                  setShowMGRS(checked);
                  localShowMGRS.current = checked;
                },
              },
              debug: {
                get: DEBUG,
                set: setDEBUG,
              },
              animate: {
                get: ANIMATE,
                set: setANIMATE,
              },
              simulate: {
                get: SIMULATE,
                set: setSIMULATE,
              },
              integrations: {
                integrations: integrations,
                editIntegration: (integration, options) => {
                  setIntegration(integration);
                  setIntegrationOptions(options);

                  sidebars.open(
                    SIDEBARS.editMissionIntegrations,
                    SIDEBARS.missionLayers
                  );
                },
                setIntegration: (integrationId, active) => {
                  const _integrations = [...integrations];
                  _integrations.find((i) => i.id === integrationId).active =
                    active;
                  setIntegrations(_integrations);
                },
                removeIntegration: (integrationId) => {
                  const _integrations = [
                    ...integrations.filter((i) => i.id !== integrationId),
                  ];
                  setIntegrations(_integrations);
                },
              },
              displayInfo: {
                get: showMilStdInfo,
                set: (value) => {
                  setShowMilStdInfo(value);
                  localShowMilStdInfo.current = value;
                },
              },
              displayIcon: {
                get: showMilStdIcon,
                set: (value) => {
                  setShowMilStdIcon(value);
                  localShowMilStdIcon.current = value;
                },
              },
              labelZoomThreshold: {
                get: labelZoomThreshold,
                set: (value) => {
                  setLabelZoomThreshold(value);
                },
              },
              symbolDetailsZoomThreshold: {
                get: symbolDetailsZoomThreshold,
                set: (value) => {
                  setSymbolDetailsZoomThreshold(value);
                },
              },
            }}
            canEdit={canEdit}
            milStdIconSize={milStdIconSize}
            setMilStdIconSize={setMilStdIconSize}
          ></MissionLayerSettings>
        </Sidebar>
        {!isMultiple && (
          <>
            {
              <Sidebar id={SIDEBARS.shapeDetails}>
                <ShapeDetails
                  closeSidebar={() => {
                    sidebars.close(SIDEBARS.shapeDetails);
                  }}
                  shapeData={features[0]}
                  doSubmitGeofence={doSubmitGeofence}
                  doSubmitAnnotation={doSubmitAnnotation}
                  missionId={localMissions.current[0].id}
                />
              </Sidebar>
            }
            <Sidebar id={SIDEBARS.missionWaypoint}>
              <MissionWaypoint
                closeSidebar={() => {
                  // update list
                  setMilspec(null);
                  setWaypoint(null);
                  sidebars.close(SIDEBARS.missionWaypoint);
                }}
                location={loc}
                waypoint={waypoint}
                missionId={localMissions.current[0].id}
                setUnit={(u) => {
                  setWaypoint(null);
                  setSelectedUser(u, u.location);
                }}
                isOwner={!waypoint || waypoint?.userId === userValue.user.id}
                refreshWaypoints={refreshPois}
                setPoiToDelete={setPoiToDelete}
                searchResult={localSearchResult.current}
                removeMarker={removeMarkerAsync}
              />
            </Sidebar>
            <Sidebar id={SIDEBARS.missionSymbol}>
              <MissionSymbol
                closeSidebar={() => {
                  // update list
                  setMilspec(null);
                  setSymbol(null);
                  sidebars.close(SIDEBARS.missionSymbol);
                }}
                milspec={milspec}
                formData={milspecFormData?.formData}
                symbolSet={milspecFormData?.symbolSet}
                symbolSetId={milspec?.symbol.slice(4, 6)}
                echelonFormData={milspecFormData?.echelonFormData}
                mod1FormData={milspecFormData?.mod1FormData}
                mod2FormData={milspecFormData?.mod2FormData}
                location={loc}
                symbol={symbol}
                missionId={localMissions.current[0].id}
                setUnit={(u) => {
                  setSymbol(null);
                  setSelectedUser(u, u.location);
                }}
                isOwner={!symbol}
                refreshSymbols={refreshSymbols}
                setSymbolToDelete={setSymbolToDelete}
                setMilspec={setMilspec}
                setLocation={setLocation}
              />
            </Sidebar>
            <Sidebar id={SIDEBARS.missionImage}>
              <MissionImage
                closeSidebar={() => {
                  // update list
                  setImage(null);
                  setIncludedMarkers([]);
                  sidebars.close(SIDEBARS.missionImage);
                }}
                location={loc}
                image={image}
                missionId={localMissions.current[0].id}
                setUnit={(u) => {
                  setImage(null);
                  setIncludedMarkers([]);
                  setSelectedUser(u, u.location);
                }}
                isOwner={!image || image?.userId === userValue.user.id}
                updateImages={refreshPois}
                setPoiToDelete={setPoiToDelete}
                searchResult={localSearchResult.current}
              />
            </Sidebar>
            <Sidebar id={SIDEBARS.missionUser}>
              <MissionPersonnel
                closeSidebar={() => {
                  // update list
                  setUser(null);
                  sidebars.close(SIDEBARS.missionUser);
                  closeTeamsEditor();
                }}
                userId={user?.id}
                startingTab={userTab}
                missionId={localMissions.current[0].id}
                isCurrentUser={userValue.user.id === user?.id}
                liveTracking={liveTracking}
                setLiveTracking={(lt) => {
                  setLiveTracking(lt);
                  if (lt) {
                    toast.success('Live Tracking Enabled', {
                      id: 'live-tracking-updated',
                    });
                  } else {
                    toast.success('Live Tracking Disabled', {
                      id: 'live-tracking-updated',
                    });
                  }
                }}
                users={personnel}
                teams={teams}
                bearing={mapBearing}
              />
            </Sidebar>
            <Sidebar id={SIDEBARS.missionBroadcast}>
              <MissionThread
                closeSidebar={() => {
                  setCurrentThread(null);
                  sidebars.close(SIDEBARS.missionBroadcast);
                }}
                missionId={localMissions.current[0].id}
                threadId={currentThread}
                readOnly={localMissions.current[0]?.archived}
                users={personnel}
                openUnitInformation={(uid) => {
                  const unit = localPersonnel.current.find((u) => u.id === uid);
                  setUser(unit);
                  setUserTab('');
                  sidebars.open(
                    SIDEBARS.missionUser,
                    SIDEBARS.missionBroadcast
                  );
                }}
                createChatWithUser={async (uid) => {
                  // thread created so add members
                  const users = [userValue.user.id, uid];
                  const teams = [];

                  const thread = await postThread(
                    localMissions.current[0].id,
                    null,
                    {
                      users,
                      teams,
                    }
                  ).catch((ex) => {
                    handleError(ex);
                    throw ex;
                  });

                  if (thread) {
                    openChatThread(null, null, thread);
                  }
                }}
                createChatWithTeam={async (tid) => {
                  // thread created so add members
                  const users = [userValue.user.id];
                  const teams = [tid];

                  const thread = await postThread(
                    localMissions.current[0].id,
                    null,
                    {
                      users,
                      teams,
                    }
                  ).catch((ex) => {
                    handleError(ex);
                    throw ex;
                  });

                  if (thread) {
                    openChatThread(null, null, thread);
                  }
                }}
              />
            </Sidebar>
            <Sidebar id={SIDEBARS.missionIntegrations}>
              <MissionIntegrations
                missionId={missions[0].id}
                closeSidebar={() => {
                  if (sidebars.previous) {
                    sidebars.open(sidebars.previous);
                  } else {
                    sidebars.close();
                  }
                }}
                orgIntegrations={orgIntegrations}
                missionIntegrations={integrations}
                setMissionIntegrations={setIntegrations}
              />
            </Sidebar>
            <Sidebar id={SIDEBARS.editMissionIntegrations}>
              <EditMissionIntegrations
                missionId={missions[0].id}
                closeSidebar={() => {
                  if (sidebars.previous) {
                    sidebars.open(sidebars.previous);
                  } else {
                    sidebars.close();
                  }
                }}
                orgIntegrations={orgIntegrations}
                missionIntegrations={integrations}
                setMissionIntegrations={setIntegrations}
                integration={integration}
                integrationOptions={integrationOptions}
              />
            </Sidebar>
          </>
        )}
        <Sidebar id={SIDEBARS.missionIncludedMarkers}>
          <MissionIncludedMarkers
            closeSidebar={() => {
              // update list
              setUser(null);
              sidebars.close(SIDEBARS.missionIncludedMarkers);
            }}
            includedMarkers={includedMarkers}
            setMarker={(marker) => {
              if (marker.poiType === POI.Photo) {
                _setImage(
                  marker,
                  marker.location,
                  SIDEBARS.missionIncludedMarkers
                );
              } else if (marker.poiType === POI.Waypoint) {
                _setWaypoint(
                  marker,
                  marker.location,
                  SIDEBARS.missionIncludedMarkers
                );
              } else {
                setUser(marker);
                setUserTab('');
                sidebars.open(
                  SIDEBARS.missionUser,
                  SIDEBARS.missionIncludedMarkers
                );
              }
            }}
            chatWithUser={async (user) => {
              closeTeamsEditor();
              await openChatThread(user, null);
            }}
          />
        </Sidebar>
        <Sidebar id={SIDEBARS.mission}>
          <MissionDetails
            closeSidebar={() => {
              sidebars.close(SIDEBARS.mission);
            }}
            selectedMission={selectedMission}
            selectedTeams={[...teams]}
            selectedRoles={[...roles]}
            selectedPersonnel={[...personnel]}
            isOwner={isOwner}
            history={history}
          />
        </Sidebar>
        <Sidebar id={SIDEBARS.form} halfScreen={true}>
          {formType === FormType.SR && (
            <Sitrep
              missionId={localMissions.current[0].id}
              cancelForm={cancelForm}
              formToEdit={formToEdit}
            />
          )}
          {formType === FormType.MR && (
            <Movreq
              missionId={localMissions.current[0].id}
              cancelForm={cancelForm}
            />
          )}
          {formType === FormType.OD && (
            <Maintdem
              missionId={localMissions.current[0].id}
              cancelForm={cancelForm}
            />
          )}
          {formType === FormType.CT && (
            <Contact
              missionId={localMissions.current[0].id}
              cancelForm={cancelForm}
            />
          )}
          {formType === FormType.CTD && (
            <ContactDetailed
              missionId={localMissions.current[0].id}
              cancelForm={cancelForm}
            />
          )}
          {formType === FormType.CV && (
            <Casevac
              missionId={localMissions.current[0].id}
              cancelForm={cancelForm}
            />
          )}
        </Sidebar>
      </div>
      <div
        className={getClassNames({
          'dark-mode': isDarkMode,
        })}
      >
        <Bottombar id={BOTTOMBARS.weatherPanel}>
          <Weather
            position={map.current}
            handleLayerChange={handleLayerChange}
            settingWeatherLocation={localSettingWeatherLocation.current}
            handleSettingWeatherLocation={handleSettingWeatherLocation}
            addWeatherMarker={addWeatherMarker}
            weatherLocation={weatherLocation}
            weatherLayer={localWeatherLayer.current}
            setWindDirection={setWindDirection}
            removeWeatherLayer={removeWeatherLayer}
          />
        </Bottombar>
      </div>

      {!!image && sidebars.isOpen(SIDEBARS.missionImage) && (
        <LightBox
          images={
            includedMarkers?.filter((m) => m.poiType === POI.Photo)?.length
              ? includedMarkers.filter((m) => m.poiType === POI.Photo)
              : [image]
          }
          image={image}
          selectImage={(i) => {
            setImage(i);
          }}
          close={() => {
            setImage(null);
            setIncludedMarkers([]);
            sidebars.close(SIDEBARS.missionImage);
          }}
        ></LightBox>
      )}
      <div className={isDarkMode ? 'dark-mode' : ''}>
        <NewChatModal
          isActive={!!threadToEdit}
          exit={() => {
            setThreadToEdit(null);
          }}
          modalId="create-chat"
          thread={threadToEdit}
          onSubmit={(name, unitsOrPersonnel) => {
            const promise = threadToEdit?.id
              ? updateChatGroup(threadToEdit.id, name, unitsOrPersonnel)
              : createChatGroup(name, unitsOrPersonnel);

            toast.promise(promise, {
              loading: 'Loading...',
              success: threadToEdit?.id
                ? `${name} chat group has been successfully saved`
                : `${name} chat group has been successfully created`,
              error: threadToEdit?.id
                ? `${name} chat group could not be saved`
                : `${name} chat group could not be created`,
            });
          }}
          missionId={localMissions.current[0].id}
        ></NewChatModal>
      </div>
      <div className={isDarkMode ? 'dark-mode' : ''}>
        <ConfirmModal
          isActive={!!poiToDelete}
          exit={() => {
            setPoiToDelete(null);
          }}
          icon="exclamation-circle"
          modalId="delete-poi"
          title={[
            `Delete the ${
              poiToDelete?.type === POI.Photo ? 'image' : 'waypoint'
            } `,
            <strong>{poiToDelete?.name}</strong>,
            `?`,
          ]}
          buttons={[
            {
              text: `Yes, Delete the ${
                poiToDelete?.type === POI.Photo ? 'Image' : 'Waypoint'
              }`,
              type: 'primary',
              callback: async () => {
                try {
                  await deletePointOfInterest(poiToDelete);
                  toast.success(
                    `${
                      poiToDelete?.type === POI.Photo ? 'Image' : 'Waypoint'
                    } deleted`
                  );
                  setPoiToDelete(null);
                  setImage(null);
                  if (sidebars.isOpen(SIDEBARS.missionWaypoint)) {
                    sidebars.close(SIDEBARS.missionWaypoint);
                  }
                  if (sidebars.isOpen(SIDEBARS.missionImage)) {
                    sidebars.close(SIDEBARS.missionImage);
                  }
                } catch (err) {
                  handleError(err);
                }
              },
            },
            {
              text: 'No, Go Back',
              type: 'secondary',
              callback: () => setPoiToDelete(null),
            },
          ]}
        >
          This {poiToDelete?.type === POI.Photo ? 'image' : 'waypoint'} will be
          removed from the {secrets.EVENT_NAME}.
        </ConfirmModal>
      </div>
      <div className={isDarkMode ? 'dark-mode' : ''}>
        <ConfirmModal
          isActive={!!symbolToDelete}
          exit={() => {
            setSymbolToDelete(null);
          }}
          icon="exclamation-circle"
          modalId="delete-poi"
          title={[`Delete the `, <strong>{getSymbolName()}</strong>, `?`]}
          buttons={[
            {
              text: `Yes, Delete the symbol`,
              type: 'primary',
              callback: async () => {
                try {
                  await deleteSymbol(symbolToDelete.id);
                  toast.success(`Symbol deleted`);
                  setSymbolToDelete(null);
                  if (sidebars.isOpen(SIDEBARS.missionSymbol)) {
                    sidebars.close(SIDEBARS.missionSymbol);
                  }
                } catch (err) {
                  handleError(err);
                }
              },
            },
            {
              text: 'No, Go Back',
              type: 'secondary',
              callback: () => setSymbolToDelete(null),
            },
          ]}
        >
          This symbol will be removed from the {secrets.EVENT_NAME}.
        </ConfirmModal>
      </div>
      <div className={isDarkMode ? 'dark-mode' : ''}>
        <ConfirmModal
          isActive={!!deleteMissionArea}
          exit={() => {
            setDeleteMissionArea(null);
            drawMissionArea();
          }}
          icon="exclamation-circle"
          modalId="delete-mission-area"
          title={[
            'Delete the ',
            <strong>{secrets.EVENT_NAME} area</strong>,
            '?',
          ]}
          buttons={[
            {
              text: `Yes, Delete ${capitalize(secrets.EVENT_NAME)} Area`,
              type: 'primary',
              callback: handleDeleteMissionArea,
            },
            {
              text: 'No, Go Back',
              type: 'secondary',
              callback: () => {
                setDeleteMissionArea(false);
                drawMissionArea();
              },
            },
          ]}
        >
          <p>
            The status of this {secrets.EVENT_NAME} will be changed to{' '}
            <strong>Planning Phase</strong> since there will be no{' '}
            {secrets.EVENT_NAME} area created. All shapes created within this
            area will also be deleted.
          </p>
        </ConfirmModal>
      </div>
      <div className={isDarkMode ? 'dark-mode' : ''}>
        <ConfirmModal
          isActive={!!confirmDelete}
          exit={() => {
            setConfirmDelete(false);
            drawShapes();
          }}
          icon="exclamation-circle"
          modalId="delete-shape"
          title={
            editingFeatures.current[0]?.geometry?.type === 'Polygon'
              ? ['Delete shape?']
              : ['Delete annotation?']
          }
          buttons={[
            {
              text:
                editingFeatures.current[0]?.geometry?.type === 'Polygon'
                  ? 'Yes, Delete Shape'
                  : 'Yes, Delete Annotation',
              type: 'primary',
              callback: handleDeleteShape,
            },
            {
              text: 'No, Go Back',
              type: 'secondary',
              callback: () => {
                setConfirmDelete(false);
                drawMissionArea();
                drawShapes();
              },
            },
          ]}
        ></ConfirmModal>
      </div>
      {!!manageForms && (
        <div
          className={isDarkMode ? 'dark-mode' : ''}
          style={{ zIndex: 10, position: 'absolute' }}
        >
          <Forms
            setManageForms={setManageForms}
            setFormToEdit={setFormToEdit}
            setFormType={setFormType}
            handleEditForm={handleEditForm}
          />
        </div>
      )}
    </>
  );
};

MissionMap.propTypes = {
  missions: PropTypes.arrayOf(missionType),
  isMultiple: PropTypes.bool,
  history: historyType,
  targets: PropTypes.arrayOf(PropTypes.any),
  updateMission: PropTypes.func,
};
