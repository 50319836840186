/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// Styling
import { useRef, useState } from 'react';
import { getClassNames } from '../../utils/string';
import './Select.scss';
import { PropTypes } from 'prop-types';
import { v4 as uuid } from 'uuid';

/**
 * Pop out sidebar control
 *
 * @param {Jsx} children sidebar children
 * @param {string} label input label (form field set label)
 * @param {string} placeholder input placeholder text
 * @param {string} value input value
 * @param {EventHandler} onChange on value changed
 * @param {string} theme input control theme
 * @param {Boolean} required input is required?
 * @param {Boolean} readOnly input is read only?
 * @param {Boolean} disabled input disabled?
 */
export const Select = ({
  label,
  placeholder,
  value,
  onChange,
  children,
  theme = '',
  required = false,
  readOnly = false,
  disabled = false,
}) => {
  const id = useRef(uuid());
  const [touched, setTouched] = useState(false);

  const clicked = (e) => {
    e.preventDefault();
    setTouched(true);
  };

  return (
    <div className={getClassNames({ 'dot-select': true }, theme)}>
      <select
        id={id.current}
        className={getClassNames({ touched })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        required={required}
        onClick={clicked}
        disabled={disabled}
      >
        {children}
      </select>
      <label htmlFor={id.current}>
        {label}
        {required ? ' *' : ''}
      </label>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.any,
  theme: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
