/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import {
  addUsersToMission,
  addUsersToTeam,
  deleteUsersFromMission,
  deleteUsersFromTeam,
  getUsersInMission,
} from '../../../api/missions';
import { getOrgUsers } from '../../../api/orgs_users';
import { getRoles, getUsersInRole } from '../../../api/roles';
import { useEvent } from '../../../context/EventContext';
import { Signal } from '../../../enums/signal';
import { sortBy } from '../../../utils/array';
import { cancellablePromise } from '../../../utils/promise';
import { displayName, getClassNames } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { MenuButton } from '../../common/Menu';
import { DarkScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import { MissionUnitPanel } from '../../sidebars/mission/MissionUsers';
import { AssignedTeams } from './AssignedTeams';
import './MissionUnitsAndAllies.scss';

/**
 * The units and allies tab of the Create mission page
 *
 * @param {any} mission mission
 * @param {string} organisationId organisation id
 * @param {Boolean} isOwner is mission owner
 */
export const MissionUnitsAndAllies = ({ mission, organisationId, isOwner }) => {
  const [search, setSearch] = useState('');

  // available groups
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();

  // available personnel
  const [personnel, setPersonnel] = useState([]);
  const [personnelInMission, setPersonnelInMission] = useState([]);
  const [triggerRefreshUsers, setTriggerRefreshUsers] = useState({});

  const [usersFilter, setUsersFilter] = useState(null);

  // sidebar is in AssignedUnits
  const [unit, setUnit] = useState();

  const [draggingFrom, setDraggingFrom] = useState(null);
  const [loading, setLoading] = useState(false);

  const missionUser = (e) => {
    log.debug('missionUser', e);

    if (e.missionId === mission.id) {
      refreshMission();
    }
  };

  const missionTeam = (e) => {
    log.debug('missionTeam', e);

    if (e.missionId === mission.id) {
      log.debug('refreshTeam', e.teamId);

      // refresh users
      triggerRefreshUsers[e.teamId] = !triggerRefreshUsers[e.teamId]
        ? true
        : false;
      setTriggerRefreshUsers({ ...triggerRefreshUsers });
    }
  };

  useEffect(() => {
    const stub = async () => {
      await refreshRoles();
      await refreshMission();
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return () => {
      cancel();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    refreshRoles();
    // eslint-disable-next-line
  }, [organisationId, selectedRole]);

  useEvent(Signal.MissionUser, missionUser);
  useEvent(Signal.MissionTeam, missionTeam);

  const refreshRoles = async () => {
    //const units = await getMissionUnits(missionId);

    // const orgUnits = units.filter(
    //   (u) => u.organisationId === userValue?.user?.organisationId
    // );

    if (organisationId) {
      const _roles = await getRoles(organisationId);
      setRoles(_roles);

      if (selectedRole) {
        const _users = await getUsersInRole(organisationId, selectedRole.id);
        setPersonnel(_users);
      } else {
        const _users = await getOrgUsers(organisationId);
        setPersonnel(_users);
      }
    }

    setLoading(false);
  };

  const refreshMission = async () => {
    const _users = await getUsersInMission(mission.id);
    setPersonnelInMission(_users);
  };

  const handleWarnings = (warnings, isMission = false) => {
    const messages = [];
    if (warnings?.length) {
      for (const w of warnings) {
        const userId = w.includes(`'`) ? /'(.+?)'/.exec(w)[1] : w;
        const user = personnel.find((p) => p.id === userId);
        if (user) {
          messages.push(`${displayName(user)}`);
        }
      }
    }

    if (messages.length) {
      if (messages.length > 1) {
        toast.success(
          messages.length +
            ' users already in ' +
            (isMission ? 'mission' : 'team')
        );
      } else {
        toast.success(
          messages[0] + ' already in ' + (isMission ? 'mission' : 'team')
        );
      }
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    transform: 'rotate(-1deg)',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    setDraggingFrom(null);

    // eslint-disable-next-line
    const { source, destination } = result;

    const type = !!roles.find((r) => r.id === result.draggableId)
      ? 'role'
      : 'personnel';

    const teamId = destination?.droppableId
      ? destination?.droppableId?.replace('team-', '')
      : '';

    log.debug('dropped', result.draggableId, teamId, type, roles);

    if (source?.index >= 0) {
      switch (destination?.droppableId) {
        case 'inventory':
          if (!source.droppableId.includes('inventory')) {
            removeFromMission([result.draggableId], null);
          }
          break;
        case 'mission':
          if (source.droppableId.includes('inventory')) {
            if (type === 'role') {
              addToMission(null, [result.draggableId]);
            } else {
              addToMission([result.draggableId], null);
            }
          } else if (source.droppableId.includes('team')) {
            removeFromTeam(source?.droppableId?.replace('team-', ''), [
              result.draggableId,
            ]);
          }
          break;
        case 'removed':
          break;
        default:
          if (teamId) {
            if (source.droppableId.includes('inventory')) {
              if (type === 'role') {
                // get users
                const userIds = personnel
                  .filter((p) => p.roleId === result.draggableId)
                  .map((u) => u.id);
                addToTeam(teamId, userIds);
              } else {
                addToTeam(teamId, [result.draggableId]);
              }
            } else if (source.droppableId.includes('mission')) {
              addToTeam(teamId, [result.draggableId]);
            } else if (source.droppableId.includes('team')) {
              addToTeam(teamId, [result.draggableId]);
            }
          }
          break;
      }
    }
  };

  const addToMission = async (users, _roles) => {
    if (mission && mission.archived) {
      toast.error(`You can't make changes to an archived mission`);
      return;
    }

    if (users?.length) {
      for (const uid of users) {
        const _user = personnel.find((r) => r.id === uid);
        if (_user?.organisationId && _user?.organisationId !== organisationId) {
          toast.error(`You can't add users from another organisation`);
          return;
        }
      }
    }

    if (_roles?.length) {
      for (const rid of _roles) {
        const role = roles.find((r) => r.id === rid);
        if (role.organisationId !== organisationId) {
          toast.error(`You can't add roles from another organisation`);
          return;
        }
      }
    }

    const rs = await addUsersToMission(mission.id, users, _roles);
    // await refreshMission();
    if (rs?.warnings?.length) {
      handleWarnings(rs?.warnings, true);
    } else {
      if (users?.length) {
        if (users.length > 1) {
          toast.success(`${users.length} users added to mission`);
        } else {
          const user = personnel.find((p) => p.id === users[0]);
          if (user) {
            toast.success(`${displayName(user)} added to mission`);
          } else {
            toast.success('User added to mission');
          }
        }
      }
      if (_roles?.length) {
        const role = roles.find((p) => p.id === _roles[0]);
        if (role) {
          toast.success(`${role.role} added to mission`);
        } else {
          toast.success('Role added to mission');
        }
      }
    }
  };

  const removeFromMission = async (users, roles) => {
    if (mission && mission.archived) {
      toast.error(`You can't make changes to an archived mission`);
      return;
    }

    // sanitise user ids
    const saneUsers = (users || []).map((u) =>
      u.includes('_') ? u.split('_')[1] : u
    );
    const missionUser = personnelInMission.find((p) => p.id === saneUsers[0]);
    if (missionUser?.guest) {
      toast.error(`You can't remove a guest from the mission`);
      return;
    }

    await deleteUsersFromMission(mission.id, saneUsers, roles);
    // await refreshMission();

    if (saneUsers?.length) {
      if (saneUsers.length > 1) {
        toast.success(`${saneUsers.length} users removed from mission`);
      } else {
        const user = personnel.find((p) => p.id === saneUsers[0]);
        if (user) {
          toast.success(`${displayName(user)} removed from mission`);
        } else {
          toast.success(`User removed from mission`);
        }
      }
    }
  };

  const addToTeam = async (teamId, users) => {
    if (mission && mission.archived) {
      toast.error(`You can't make changes to an archived mission`);
      return;
    }

    // sanitise user ids
    const saneUsers = (users || []).map((u) =>
      u.includes('_') ? u.split('_')[1] : u
    );

    if (saneUsers?.length) {
      for (const uid of saneUsers) {
        const _user = personnel.find((r) => r.id === uid);
        if (_user?.organisationId && _user?.organisationId !== organisationId) {
          toast.error(`You can't add users from another organisation`);
          return;
        }
      }
    }

    const rs = await addUsersToTeam(mission.id, teamId, saneUsers);

    if (rs?.warnings?.length) {
      handleWarnings(rs?.warnings);
    } else {
      if (saneUsers?.length) {
        if (saneUsers.length > 1) {
          toast.success(`${saneUsers.length} users added to team`);
        } else {
          const user = personnel.find((p) => p.id === saneUsers[0]);
          if (user) {
            toast.success(`${displayName(user)} added to team`);
          } else {
            toast.success('User added to team');
          }
        }
      }
    }
  };

  const removeFromTeam = async (teamId, users) => {
    if (mission && mission.archived) {
      toast.error(`You can't make changes to an archived mission`);
      return;
    }

    // sanitise user ids
    const saneUsers = (users || []).map((u) =>
      u.includes('_') ? u.split('_')[1] : u
    );

    const rs = await deleteUsersFromTeam(mission.id, teamId, saneUsers);

    if (rs?.warnings?.length) {
      handleWarnings(rs?.warnings);
    } else {
      if (saneUsers?.length) {
        if (saneUsers.length > 1) {
          toast.success(`${saneUsers.length} users removed from team`);
        } else {
          const user = personnel.find((p) => p.id === saneUsers[0]);
          if (user) {
            toast.success(`${displayName(user)} removed from team`);
          } else {
            toast.success('User removed from team');
          }
        }
      }
    }
  };

  const selectRole = async (role) => {
    setPersonnel([]);
    setSelectedRole(role);
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'rgba(255,255,255,0.07)' : null,
  });

  const filteredPersonnel = sortBy(
    (selectedRole
      ? personnel.map((p) => {
          p.role = selectedRole;
          return p;
        })
      : personnel
    ).filter(
      (g) =>
        `${g.firstname} ${g.lastname}`
          .toLowerCase()
          .includes(search.toLowerCase()) &&
        (selectedRole ||
          usersFilter === 'all' ||
          !personnelInMission.find((p) => p.id === g.id))
    ),
    ['firstName', 'lastName'],
    ['asc', 'asc']
  );

  const filteredRoles = sortBy(
    roles.filter((r) => r.role.toLowerCase().includes(search.toLowerCase())),
    ['role'],
    ['asc']
  );

  return (
    <DragDropContext
      onDragStart={(e) => {
        log.debug('onDragStart', e);
        const user = personnelInMission.find(
          (u) =>
            u.id ===
            (e.draggableId.includes('_')
              ? e.draggableId.split('_')[1]
              : e.draggableId)
        );
        log.debug('onDragStart', user);
        if (!user?.guest) {
          setDraggingFrom(e.source.droppableId);
        } else {
          setDraggingFrom('guest');
        }
      }}
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId="inventory">
        {(provided, snapshot) => (
          <div
            className="create-mission-units"
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <div className="create-mission-units-title">
              <div className="search-bar">
                <MenuButton
                  options={[
                    {
                      text: 'Available Users',
                      ariaLabel: 'Available Users',
                      onClick: () => {
                        setUsersFilter(null);
                      },
                    },
                    {
                      text: 'All Users',
                      ariaLabel: 'All Users',
                      onClick: () => {
                        setUsersFilter('all');
                      },
                    },
                  ]}
                >
                  <span>
                    {usersFilter === 'all' ? 'All Users' : 'Available Users'}
                  </span>
                  <FontAwesomeIcon icon="chevron-down" />
                </MenuButton>
                <SearchInput
                  placeholder="Search for a role or user"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <DarkScrollbar noScrollX>
              {!selectedRole && (
                <>
                  {loading ? (
                    <LoadingUnitPanel />
                  ) : (
                    <>
                      <div className="create-mission-units-subtitle">Roles</div>
                      {!filteredRoles?.length ? (
                        <p style={{ color: 'var(--colour-white)' }}>
                          No unassigned roles found.
                        </p>
                      ) : (
                        <div className="create-mission-units-panel-container">
                          {filteredRoles.map((g, index) => (
                            <Draggable
                              key={g.id}
                              draggableId={g.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <React.Fragment>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    className={getClassNames({
                                      dragging: snapshot.isDragging,
                                    })}
                                  >
                                    <MissionUnitPanel
                                      onClick={() => {
                                        selectRole(g);
                                      }}
                                      group={g}
                                      addToMission={addToMission}
                                      setUnit={setUnit}
                                    />
                                  </div>
                                  {snapshot.isDragging && (
                                    <div className="clone">
                                      <MissionUnitPanel group={g} />
                                    </div>
                                  )}
                                </React.Fragment>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              <div className="create-mission-units-subtitle">
                {selectedRole ? (
                  <div className="breadcrumbs">
                    <DotButton
                      className="link"
                      onClick={() => {
                        selectRole(null);
                      }}
                    >
                      All Roles
                    </DotButton>
                    <FontAwesomeIcon icon="chevron-right" />
                    <span>{selectedRole.role}</span>
                    <FontAwesomeIcon icon="chevron-right" />
                    <span>Users</span>
                  </div>
                ) : (
                  'Users'
                )}
              </div>
              {loading ? (
                <LoadingUnitPanel />
              ) : (
                <>
                  {!filteredPersonnel?.length ? (
                    <p style={{ color: 'var(--colour-white)' }}>
                      No unassigned users found.
                    </p>
                  ) : (
                    <div className="create-mission-units-panel-container">
                      {filteredPersonnel.map((g, index) => (
                        <Draggable key={g.id} draggableId={g.id} index={index}>
                          {(provided, snapshot) => (
                            <React.Fragment>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                className={getClassNames({
                                  dragging: snapshot.isDragging,
                                })}
                              >
                                <MissionUnitPanel
                                  onClick={() => {
                                    selectRole(g);
                                  }}
                                  group={g}
                                  addToMission={addToMission}
                                  setUnit={setUnit}
                                  active={personnelInMission.find(
                                    (p) => p.id === g.id
                                  )}
                                />
                              </div>
                              {snapshot.isDragging && (
                                <div className="clone">
                                  <MissionUnitPanel
                                    group={g}
                                    addToMission={addToMission}
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </>
              )}
            </DarkScrollbar>
            <div
              className={getClassNames({
                remove: true,
                highlight:
                  draggingFrom?.includes('team-') ||
                  draggingFrom?.includes('mission'),
                dim:
                  (draggingFrom &&
                    !(
                      draggingFrom?.includes('team-') ||
                      draggingFrom?.includes('mission')
                    )) ||
                  draggingFrom?.includes('guest'),
              })}
            >
              <div>
                <FontAwesomeIcon icon="minus-circle" />
              </div>
              <div>Drag a user here to remove them from the mission.</div>
            </div>
          </div>
        )}
      </Droppable>
      <AssignedTeams
        missionId={mission.id}
        updateContext={() => {
          refreshRoles();
        }}
        isOwner={isOwner}
        readOnly={mission.archived}
        unit={unit}
        setUnit={setUnit}
        personnelInMission={personnelInMission}
        removeFromMission={async (user) => {
          if (user?.id) {
            await removeFromMission([user.id], null);
          }
        }}
        removeFromTeam={removeFromTeam}
        triggerRefreshUsers={triggerRefreshUsers}
        draggingFrom={draggingFrom}
        loading={loading}
        archived={mission?.archived}
      />
    </DragDropContext>
  );
};

MissionUnitsAndAllies.propTypes = {
  missionId: PropTypes.string,
  organisationId: PropTypes.string,
  isOwner: PropTypes.bool,
};

export const LoadingUnitPanel = () => {
  return (
    <div
      className={getClassNames({
        'unit-panel-container': true,
        draggable: false,
        clickable: false,
        active: false,
        loading: true,
      })}
    >
      <div className="body">
        <div className="unit">
          <div className="img">
            <Avatar entity={null} size={'var(--team-size)'} />
          </div>
          <div className="text">
            ... <span>...</span>
          </div>
        </div>
      </div>
    </div>
  );
};
