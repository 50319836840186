import { appConfig } from '../../config/appConfig';
import { historyType } from '../../enums/propTypes';
import { ContentPage } from '../generic-components/ContentPage';
import bgShape from '../../assets/svg/dot-bg-shape.svg';

/**
 * WEBSITE - DOT Privacy Page
 *
 * @param {any} history react router
 */
export const PrivacyPolicy = ({ history }) => {
  return (
    <ContentPage history={history}>
      <h1>Privacy Policy</h1>
      <div className="shape">
        <img src={bgShape} alt="bg shape" />
      </div>
      <h2>1. Purpose of this policy</h2>
      <p>
        Insitec Pty Ltd (ACN 097 025 968), Insitec MIS Systems Pty Ltd (ACN 161
        023 856) and their respective subsidiaries (collectively
        <strong> ‘Insitec’</strong> and <strong>‘we’</strong>) are bound by the
        Privacy Act 1988 (Cth) (Act). We are committed to complying with the Act
        and other relevant laws in relation to the management and protection of
        personal information. This Privacy Policy describes how Insitec
        collects, holds, uses and discloses your personal information.
      </p>
      <p>
        By providing your personal information to Insitec, you consent to our
        collection, use and disclosure of your personal information in
        accordance with this Privacy Policy (including its schedule of Special
        Terms for users of the AUSTAC Platform (<strong>‘AUSTAC’</strong>) where
        appropriate) and any other arrangements that apply between us.
      </p>
      <p>
        This Privacy Policy applies to clients, independent contractors,
        suppliers and job applicants, users of our software applications,
        including the AUSTAC Platform (and other individuals) who may provide
        Insitec with their personal information or otherwise make that
        information available.
      </p>

      <h2>2. Employment information</h2>
      <p>
        This Privacy Policy does not apply to acts and practices in relation to
        employee records of our current and former employees, which are outside
        the scope of the privacy laws. When you apply for a role with Insitec,
        certain information may be collected from you (including your name,
        contact details, working history and relevant background checks) from
        your previous employer and others who may be able to provide information
        to assist Insitec in deciding whether to offer you employment, or to
        engage you under a contract.
      </p>
      <h2>3. What personal information do we collect?</h2>
      <p>
        Personal information has the meaning given under the Act and it means
        any information or an opinion about an identified individual, or an
        individual who is reasonably identifiable. This may include the
        following types of personal information:
      </p>
      <ul>
        <li>name; </li>
        <li>age or date of birth;</li>
        <li>mailing or street address;</li>
        <li>telephone, fax numbers and other contact details; </li>
        <li>email address;</li>
        <li>service or educational institution details;</li>
        <li>health information such as medical history;</li>
        <li>bank account details;</li>
        <li>geographic location;</li>
        <li>
          any additional information relating to you that you provide Insitec
          directly or indirectly through your use of Insitec’s website or
          {appConfig.name};
        </li>
        <li>
          information you provide Insitec when you communicate with Insitec by
          email, telephone or other means; or
        </li>
        <li>
          any other personal information that may be required to facilitate your
          interactions with Insitec including its software and platforms, such
          as {appConfig.name}.
        </li>
      </ul>
      <h2>4. How and when do we collect personal information? </h2>
      <p>
        Where possible, we collect your personal information directly from you
        rather than from another person or source, unless it is unreasonable or
        unpracticable to do so.
      </p>
      <p>
        We collect personal information in circumstances including the
        following:
      </p>
      <ul>
        <li>
          when you, as an individual in your own capacity or on behalf of your
          employer become a customer of our business (including when you become
          a subscriber of our software, including {appConfig.name} as set out in
          the Schedule);
        </li>
        <li>
          when you use {appConfig.name}, in accordance with the specific terms
          below;
        </li>
        <li>
          when you telephone us, or visit our website and provide feedback, or
          you make an enquiry to which a later response is requested and to do
          so requires your contact details;
        </li>
        <li>
          through written correspondence from you, including letters and emails;
        </li>
        <li>
          when you ask to be included on marketing distribution lists, sign up
          to our website or interact with us on social media;
        </li>
        <li>when you take advantage of a promotional offer we may run;</li>
        <li>
          when you otherwise register for or subscribe to one of our online
          services;
        </li>
        <li>
          when as a supplier, your contact and other details are provided;
        </li>
        <li>
          when we enter into an agreement with you for the provision or
          acquisition of goods or services; and
        </li>
        <li>
          when we receive applications for employment and when evaluating job
          applicants and personnel, which may include collection of details such
          as employment history and educational qualifications.
        </li>
      </ul>
      <p>
        We may use ‘cookies’ to collect data (typically not personal
        information) relating to your general internet usage. This data may
        include IP addresses, browser versions, number of visits and similar
        such data relating to your navigation of the internet and our site. A
        cookie is a small text file that is placed on your computer or device’s
        hard drive. Cookies help us to improve our site and to deliver a better
        and more tailored service, for instance by storing information about
        your preferences and allowing us to recognise you when you return to our
        site.
      </p>
      <p>
        You may refuse to accept cookies by activating settings on your internet
        browser. However, please note that if your select such settings you may
        be unable to access certain parts of our website.
      </p>
      <h2>
        5. How do we use and disclose the personal information you provide?
      </h2>
      <p>
        Insitec may collect, hold, use and disclose your personal information
        for the following purposes:
      </p>
      <ul>
        <li>for any of the purposes of collection described in (4) above;</li>
        <li>to deliver the products and services you requested;</li>
        <li>
          to enable you (and others with whom you agree to interact) to use{' '}
          {appConfig.name}, where applicable;
        </li>
        <li>
          to provide you with further information about the products and
          services you requested;
        </li>
        <li>to personalise and customise your experiences with Insitec; </li>
        <li>to help Insitec manage and enhance its services; </li>
        <li>
          to communicate with you, including answering your questions and
          providing you with information or services;
        </li>
        <li>
          to provide your information to third parties that assist Insitec in
          providing the services you have requested;
        </li>
        <li>
          to carry out administration (such as invoicing, debt collection,
          receiving and making payments), marketing, fraud and loss prevention
          activities;
        </li>
        <li>to consider and respond to complaints made by you;</li>
        <li>
          to comply with laws or regulations, or to comply with any directions
          given by governmental regulators or authorities;
        </li>
        <li>
          to operate, protect, improve and optimise Insitec’s website,{' '}
          {appConfig.name} and users’ experience, such as perform analytics,
          conduct research and for marketing;
        </li>
        <li>
          to send you service, support and administrative messages, reminders,
          technical notices, updates, security alerts and information requested
          by you;
        </li>
        <li>
          to send you news, announcements and other information that may be of
          interest to you;
        </li>
        <li>to consider your employment application; or</li>
        <li>
          in other circumstances, with your prior express consent, or where it
          can be reasonably inferred from the circumstances that you consent.
        </li>
      </ul>
      <h2>
        6. What happens if you choose not to provide your personal information?
      </h2>
      <p>
        You are not obliged to give us your personal information, however
        Insitec may be unable to deliver the range or quality of services you
        require, should you choose not to do so.
      </p>
      <p>
        Where possible, you have the option of interacting with us anonymously
        (for example, as a visitor of the website), or using a pseudonym if you
        feel more comfortable dealing with us that way. For example, if you
        contact us directly by telephone with a general question, we will not
        ask for your full name unless we need it to answer your question.
      </p>
      <h2>7. When do we disclose your personal information?</h2>
      <p>
        We limit the information we provide to third parties to the information
        they need to help us provide or facilitate the provision of goods and
        services to you. We deal with third parties who are required to meet the
        privacy standards required by law in handling your personal information
        and use your personal information only for the purposes that we give it
        to them
      </p>
      <p>Insitec will not your sell personal information to third parties.</p>
      <p>
        Personal information will only be disclosed to third parties in
        accordance with this Privacy Policy. Information may be provided to
        third parties where services relating to the purpose for which the
        personal information is collected are outsourced or you would reasonably
        expect us to disclose it to a third party for a particular purpose, or
        we have obtained your prior consent. For example, we may disclose your
        personal information to:
      </p>
      <ul>
        <li>our related bodies corporate;</li>
        <li>
          third party suppliers and service providers (where necessary and
          subject to the qualification mentioned above);
        </li>
        <li>our professional advisers;</li>
        <li>our payment system operators;</li>
        <li>debt collection companies;</li>
        <li>
          anyone to whom our assets or businesses (or any part of them) are
          transferred;
        </li>
        <li>
          specific third parties authorised by you to receive information held
          by Insitec;
        </li>
        <li>
          third parties who utilise {appConfig.name} in circumstances where you
          or your employer organisation has consented to that disclosure; and/or
        </li>
        <li>
          other persons, including government agencies, regulatory bodies and
          law enforcement agencies, or as required, authorised or permitted by
          law.
        </li>
      </ul>
      <p>Insitec may also disclose your personal information if:</p>
      <ul>
        <li>you have consented to the disclosure;</li>
        <li>
          where disclosure is necessary to prevent injury to life or health; or
        </li>
        <li>
          it is required or authorised by or under an Australian law or a
          court/tribunal order.
        </li>
      </ul>
      <h2>8. Accessing your personal information </h2>
      <p>
        You have a right to access your personal information, subject to certain
        exceptions provided for in the Privacy Act. If you require access to
        your personal information, please contact Insitec’s Privacy Officer and
        you should receive a response within 30 days. For security reasons, you
        will be required to put your request in writing and provide proof of
        your identity.
      </p>
      <p>
        If Insitec refuses to give you access to your personal information,
        Insitec will notify you in writing setting out the reasons.
      </p>
      <h2>9. Correcting your personal information</h2>
      <p>
        Insitec will take all reasonable steps to ensure that the personal
        information that we hold is accurate. If you feel that information about
        you is not accurate, you can ask for correction by contacting Insitec’s
        Privacy Officer and you should receive a response within 30 days.
      </p>
      <p>
        If Insitec refuses to give correct or update your personal information,
        Insitec will notify you in writing setting out the reasons.
      </p>
      <h2>10. Security of your personal information </h2>
      <p>
        Insitec will take all reasonable steps to keep your personal information
        safe and stored in a secure environment which can only be accessed by
        authorised personnel who have a need to do so. Electronic information is
        protected by various security measures and access to information and
        databases is restricted, by password protection and physical security
        measures. However, no data transmission over the internet or information
        stored in servers accessible through the internet can be guaranteed to
        be fully secure.
      </p>
      <p>
        In addition, Insitec will take reasonable steps to destroy or
        de-identify personal information once it is no longer needed for our
        record retention purposes.
      </p>
      <h2>11. Disclosure and transfer of information overseas</h2>
      <p>
        Insitec does not disclose or transfer personal information outside
        Australia.{' '}
      </p>
      <h2>12. Notifiable data breaches</h2>
      <p>
        In the event of any unauthorised access or unauthorised disclosure or
        loss of your personal information that is likely to result in serious
        harm to you, Insitec will investigate and notify you and the Office of
        the Australian Information Commissioner in accordance with privacy laws.{' '}
      </p>
      <h2>13. Questions and Complaints </h2>
      <p>
        If you believe your privacy has been breached by Insitec, or if you wish
        to make a complaint about the way Insitec has handled your privacy
        information, you can contact Insitec’s Privacy Officer using the contact
        details below.
      </p>
      <p>
        Please include your name, email address and/or telephone number and
        clearly describe your complaint. Insitec will acknowledge your complaint
        and respond to your complaint with a reasonable period of time (usually
        within 30 days). If you are not satisfied with Insitec’s response to
        your complaint, you may lodge a formal compliant with the Office of the
        Australian Information Commissioner.
      </p>
      <h2>14. How to contact us </h2>
      <p>
        If you would like more information or have any questions in relation to
        this Privacy Policy, or to access or correct your personal information,
        or make a complaint, please contact:
      </p>
      <p>
        Privacy Officer <br />
        Insitec Pty Ltd
        <br />
        PO Box 1686
        <br />
        Fyshwick ACT 2609
        <br />
        Telephone: <a href="tel:0262324541">(02) 6232 4541</a>
        <br />
        Email:{' '}
        <a href="mailto:privacy@insitec.com.au">privacy@insitec.com.au</a>
      </p>
      <h2>15. Changes to this policy</h2>
      <p>
        Insitec may change this Privacy Policy from time to time. Any updated
        versions of this Privacy Policy will be posted on our website{' '}
        <a href="https://www.insitec.com.au">www.insitec.com.au</a> and will be
        effective from the date of posting.
      </p>
      <p>
        This Privacy Policy was last reviewed and updated on 30 August 2021.
      </p>

      <h2>
        <center>
          SPECIFIC PRIVACY TERMS FOR USERS OF AUSTAC APPLICATION AND PLATFORM
        </center>
      </h2>
      <div className="shape">
        <img src={bgShape} alt="bg shape" />
      </div>
      <h2>Who does this privacy policy relate to?</h2>
      <p>
        This policy covers Insitec MIS Systems Pty Ltd (ACN 161 023 856) and its
        parent companies, subsidiaries and related entities (collectively{' '}
        <strong>‘Insitec’</strong> and <strong>‘we’</strong>) and deals with
        personal information of users of Insitec’s “AUSTAC” platform including
        the mobile device application associated with it (collectively{' '}
        <strong>“the App”</strong>)
      </p>
      <h2>Personal Information Collected</h2>
      <p>
        App Users are subject to the Insitec privacy policy located at{' '}
        <a href="https://www.insitec.com.au">www.insitec.com.au</a> and to the
        specific terms of this policy.
      </p>
      <h2>Who does the Specific Terms apply to?</h2>
      <p>
        For these purposes, an App User includes any individual user who may
        employed or engaged by an organisation where that organisation is the
        primary subscriber to the App and platform, under an agreement with
        Insitec.
      </p>
      <h2>What personal information is collected from App Users?</h2>
      <p>
        We only collect from you the minimum personal information relevant to
        the core functionality of the App.
      </p>
      <p>
        We collect location data only when necessary for your use of the App.{' '}
      </p>
      <p>
        App Users can select the level of disclosure of personal information
        that occurs when using AUSTAC. However, to be able to use and enjoy the
        functionality of AUSTAC, a certain base level of disclosure will be
        required.
      </p>
      <p>
        As an App User you will provide (and Insitec will collect) some or all
        of the following personal information and data to Insitec, through use
        of the App:
      </p>
      <ul>
        <li>Your personal name;</li>
        <li>A pseudonym or “call sign” if you choose to use one;</li>
        <li>Your log-in details;</li>
        <li>Your photograph or image (optional) if you upload one;</li>
        <li>
          Details of your geographical location and location data (as it changes
          over time, when using the App);
        </li>
        <li>Your log-in history to the App;</li>
        <li>
          Any health metrics, or health sensor data you allow the App to access
          or choose to share with the App; and
        </li>
        <li>
          Communication metadata and chat logs, and details of the time and
          length of calls.{' '}
        </li>
      </ul>

      <h2>
        Why is this information collected and how does Insitec use or disclose
        this information?
      </h2>
      <p>
        The above information is collected primarily to enable Insitec to
        operate the App and associated platform, to track its use for
        administrative and billing purposes and for the App User to obtain the
        benefit of its functionality.
      </p>
      <p>
        If and to the extent that any third party with whom the App shares any
        App User data (in accordance with guidelines of the Apple App Store and
        similar third party policies), such as analytics tools, advertising
        networks and third party SDKs, those third parties will provide the same
        or equal protection as stated in this policy.
      </p>
      <p>
        Except as stated in this policy, Insitec does not share data obtained
        from the use of the App or your subscription to it, outside the AUSTAC
        platform.
      </p>
      <p>
        No personal information will be sold to third parties for commercial
        purposes, such as marketing or advertising.
      </p>
      <p>
        Where you as the App User (or, more likely the organisation on whose
        behalf you use the App) partner with a third party in any session or
        “mission”, some of your personal information may be shared with others
        within that third party organisation for the purposes of obtaining the
        benefits of the functionality of AUSTAC (for example, to help locate you
        in a geographical area).
      </p>
      <p>
        Where you are an individual who is engaged by or is otherwise acting for
        or on behalf of an organisation who has the primary subscription to the
        App and platform with Insitec, some of the above personal information
        may be provided to the primary subscriber (such as your employer). This
        would include, but not necessarily be limited to, your log-in details,
        your log-in history, your health metrics and health sensor data (if
        provided), photographs and call signs, any audio-visual material you may
        upload through AUSTAC (if and where such functionality exists), your
        location data, your communication metadata, such as chat logs and
        details of times and durations of calls and sessions using the App.
      </p>
      <p>
        Insitec does not transfer your personal information outside Australia.
      </p>
      <h2>Retention of data</h2>
      <p>
        Insitec will generally retain personal information for 7 years from the
        date of its collection. If you request us to delete or destroy personal
        information we hold about you before this date, you can contact us with
        your request.
      </p>
      <p>
        Please include your name, email address and/or telephone number and
        clearly describe your request. Insitec will acknowledge your request and
        respond to within a reasonable period of time (usually within 30 days).
        If you are not satisfied with Insitec’s response to your request, you
        may lodge a formal compliant with the Office of the Australian
        Information Commissioner.
      </p>
      <h2>How to contact us</h2>
      <p>
        You have a right to access your personal information, subject to certain
        exceptions provided for in the Privacy Act. If you require access to
        your personal information, please contact Insitec’s Privacy Officer and
        you should receive a response within 30 days. For security reasons, you
        will be required to put your request in writing and provide proof of
        your identity.
      </p>
      <p>
        If Insitec refuses to give you access to your personal information,
        Insitec will notify you in writing setting out the reasons.
      </p>
      <p>
        If you would like more information or have any questions in relation to
        this Privacy Policy, or to access or correct your personal information,
        or make a complaint, please contact:
      </p>
      <p>
        Privacy Officer <br />
        Insitec Pty Ltd
        <br />
        PO Box 1686
        <br />
        Fyshwick ACT 2609
        <br />
        Telephone: <a href="tel:0262324541">(02) 6232 4541</a>
        <br />
        Email:{' '}
        <a href="mailto:privacy@insitec.com.au">privacy@insitec.com.au</a>
      </p>
      <h2>Other privacy questions not covered by this policy</h2>
      <p>
        Other terms relating to how Insitec generally collects, uses, discloses,
        secures, provides access to and deals with queries and complaints about
        personal information may be located at our general privacy policy at{' '}
        <a href="https://www.insitec.com.au">www.insitec.com.au</a>
      </p>
    </ContentPage>
  );
};

PrivacyPolicy.propTypes = { history: historyType };
