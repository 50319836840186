/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as concaveman from 'concaveman';

// constants stolen from @mapbox/mapboxgl_draw
export const Constants = {
  classes: {
    CONTROL_BASE: 'mapboxgl-ctrl',
    CONTROL_PREFIX: 'mapboxgl-ctrl-',
    CONTROL_BUTTON: 'mapbox-gl-draw_ctrl-draw-btn',
    CONTROL_BUTTON_LINE: 'mapbox-gl-draw_line',
    CONTROL_BUTTON_POLYGON: 'mapbox-gl-draw_polygon',
    CONTROL_BUTTON_POINT: 'mapbox-gl-draw_point',
    CONTROL_BUTTON_TRASH: 'mapbox-gl-draw_trash',
    CONTROL_BUTTON_COMBINE_FEATURES: 'mapbox-gl-draw_combine',
    CONTROL_BUTTON_UNCOMBINE_FEATURES: 'mapbox-gl-draw_uncombine',
    CONTROL_GROUP: 'mapboxgl-ctrl-group',
    ATTRIBUTION: 'mapboxgl-ctrl-attrib',
    ACTIVE_BUTTON: 'active',
    BOX_SELECT: 'mapbox-gl-draw_boxselect',
  },

  sources: {
    HOT: 'mapbox-gl-draw-hot',
    COLD: 'mapbox-gl-draw-cold',
  },

  cursors: {
    ADD: 'add',
    MOVE: 'move',
    DRAG: 'drag',
    POINTER: 'pointer',
    NONE: 'none',
  },

  types: {
    POLYGON: 'polygon',
    LINE: 'line_string',
    POINT: 'point',
  },

  geojsonTypes: {
    FEATURE: 'Feature',
    POLYGON: 'Polygon',
    LINE_STRING: 'LineString',
    POINT: 'Point',
    FEATURE_COLLECTION: 'FeatureCollection',
    MULTI_PREFIX: 'Multi',
    MULTI_POINT: 'MultiPoint',
    MULTI_LINE_STRING: 'MultiLineString',
    MULTI_POLYGON: 'MultiPolygon',
  },

  modes: {
    DRAW_LINE_STRING: 'draw_line_string',
    DRAW_POLYGON: 'draw_polygon',
    DRAW_POINT: 'draw_point',
    SIMPLE_SELECT: 'simple_select',
    DIRECT_SELECT: 'direct_select',
    STATIC: 'static',
  },

  events: {
    CREATE: 'draw.create',
    DELETE: 'draw.delete',
    UPDATE: 'draw.update',
    SELECTION_CHANGE: 'draw.selectionchange',
    MODE_CHANGE: 'draw.modechange',
    ACTIONABLE: 'draw.actionable',
    RENDER: 'draw.render',
    COMBINE_FEATURES: 'draw.combine',
    UNCOMBINE_FEATURES: 'draw.uncombine',
  },

  updateActions: {
    MOVE: 'move',
    CHANGE_COORDINATES: 'change_coordinates',
  },

  meta: {
    FEATURE: 'feature',
    MIDPOINT: 'midpoint',
    VERTEX: 'vertex',
  },

  activeStates: {
    ACTIVE: 'true',
    INACTIVE: 'false',
  },

  interactions: [
    'scrollZoom',
    'boxZoom',
    'dragRotate',
    'dragPan',
    'keyboard',
    'doubleClickZoom',
    'touchZoomRotate',
  ],

  LAT_MIN: -90,
  LAT_RENDERED_MIN: -85,
  LAT_MAX: 90,
  LAT_RENDERED_MAX: 85,
  LNG_MIN: -270,
  LNG_MAX: 270,
};

// function stolen from @mapbox/mapboxgl_draw
export const isEventAtCoordinates = (event, coordinates) => {
  if (!event.lngLat) return false;
  return (
    event.lngLat.lng === coordinates[0] && event.lngLat.lat === coordinates[1]
  );
};

/**
 * Get array of each line in a polygon.
 *
 * @param {Polygon} polygon
 */
export const getPaths = (polygon, invert = false) => {
  const paths = [];
  let prev = null;
  for (let coord of polygon) {
    if (prev != null) {
      if (invert) {
        paths.push({
          from: { lng: coord[0], lat: coord[1] },
          to: { lng: prev[0], lat: prev[1] },
        });
      } else {
        paths.push({
          from: { lng: prev[0], lat: prev[1] },
          to: { lng: coord[0], lat: coord[1] },
        });
      }
    }
    prev = coord;
  }
  return paths;
};

/**
 * Test if both polygons have the same paths
 */
export const isSamePoly = (polygon1, polygon2) => {
  const originalPaths = getPaths(polygon1);
  const newPaths = getPaths(polygon2);
  const inverted = getPaths(polygon2, true);
  let count = 0;
  for (const t of originalPaths) {
    if (
      newPaths.find((tt) => JSON.stringify(tt) === JSON.stringify(t)) ||
      inverted.find((tt) => JSON.stringify(tt) === JSON.stringify(t))
    )
      count++;
  }
  return count === originalPaths.length;
};

/**
 * Take the polygon and ensure it is a concave hull. Uses mapbox's concaveman library.
 *
 * @param {Polygon} polygon
 */
export const fixPolygon = (polygon, isEdit = false) => {
  // is a relative measure of concavity. 1 results in a relatively detailed shape, Infinity results in a convex hull. You can use values lower than 1, but they can produce pretty crazy shapes.
  const concavity = 0.5;

  const verticies = polygon.coordinates[0];
  // only do this for a shape
  if (verticies.length > 2) {
    const concave = concaveman(verticies, concavity);
    if (isEdit) {
      if (!isValidPolygon(verticies) && !isSamePoly(verticies, concave)) {
        for (let i = 0; i < verticies.length; i++) {
          if (i >= concave.length) {
            polygon.removeCoordinate(`0.${i}`);
          } else {
            polygon.updateCoordinate(`0.${i}`, concave[i][0], concave[i][1]);
          }
        }
        return concave.length - 1;
      }
    } else {
      // in progress - isn't complete
      if (
        !isValidPolygon([...verticies, verticies[0]]) &&
        !isSamePoly([...verticies, verticies[0]], concave)
      ) {
        // the concave poly will always have an extra vertex
        for (let i = 0; i < concave.length - 1; i++) {
          polygon.updateCoordinate(`0.${i}`, concave[i][0], concave[i][1]);
        }
        return concave.length - 2;
      }
    }
  }
  return verticies.length - 1;
};

export const doLinesIntersect = (
  line1,
  line2,
  ignoreIntersectionAtEnds = true
) => {
  // solution is a modified version of this algorithm
  // https://stackoverflow.com/questions/3838329/how-can-i-check-if-two-segments-of-a-straight-line-intersect
  // added a test to see if the lines join at their ends.
  // for polygons the lines need to join

  if (ignoreIntersectionAtEnds) {
    if (line1.from === line2.from) return false;
    if (line1.to === line2.from) return false;
    if (line1.from === line2.to) return false;
    if (line1.to === line2.to) return false;
  }

  const [a, b] = line1.from;
  const [c, d] = line1.to;
  const [p, q] = line2.from;
  const [r, s] = line2.to;

  let det, gamma, lambda;
  det = (c - a) * (s - q) - (r - p) * (d - b);
  if (det === 0) {
    return false;
  } else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;

    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
};

/**
 * is a polygon valid?
 * It shouldn't have any line segments that intersect
 */
export const isValidPolygon = (verticies) => {
  // only do this for a shape
  if (verticies.length > 2) {
    const lines = [];

    let start = null;
    for (const v of verticies) {
      if (start != null) {
        lines.push({
          from: start,
          to: v,
        });
      }
      start = v;
    }
    lines.push({
      from: start,
      to: verticies[0],
    });

    // get all combinations of line segments
    for (const line1 of lines) {
      for (const line2 of lines) {
        if (line1 !== line2) {
          if (doLinesIntersect(line1, line2)) {
            return false;
          }
        }
      }
    }

    return true;
  }
  return false;
};
