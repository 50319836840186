/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endpointConfig } from '../../../config/endpoint_config';
import { useUser } from '../../../context/UserContext';
import { Role } from '../../../enums/role';
import { getClassNames } from '../../../utils/string';
import { DotButton } from '../buttons/DotButton';
import { PropTypes } from 'prop-types';
import { historyType } from '../../../enums/propTypes';
import { useSidebar } from '../../../context/SidebarContext';

/**
 * Admin Menu (left SideNav)
 * @param {any} history react router
 * @param {'missions' || 'org' || 'map-styles' || 'create-mission' || 'view-mission' || 'org-users'} activeRoute active button
 */
export const AdminMenu = ({ history, activeRoute }) => {
  const { user } = useUser();
  const { closeAll } = useSidebar();

  return (
    <>
      {/*<DotButton
        className={getClassNames({
          active: activeRoute === 'missions' || activeRoute === 'map-view',
        })}
        onClick={() => {
          closeAll();
          history.push(endpointConfig.frontendEndpoints.missionNav);
        }}
      >
        <FontAwesomeIcon
          icon={'map-marked-alt'}
          style={{
            height: '24px',
            width: '24px',
          }}
        />
        <span>Missions</span>
        </DotButton>*/}
      {user.accessRoles.includes(Role.SysAdmin) && (
        <DotButton
          className={getClassNames({
            active: activeRoute.includes('org'),
          })}
          onClick={() => {
            closeAll();
            history.push(endpointConfig.frontendEndpoints.organisations);
          }}
        >
          <FontAwesomeIcon icon={'users'} />
          <span>Org & Users</span>
        </DotButton>
      )}
      {user.accessRoles.includes(Role.OrgAdmin) && (
        <>
          <DotButton
            className={getClassNames({
              active: activeRoute === 'org',
            })}
            onClick={() => {
              closeAll();
              history.push(
                endpointConfig.frontendEndpoints.organisation.replace(
                  ':id',
                  user.organisationId
                )
              );
            }}
          >
            <FontAwesomeIcon icon={'users'} />
            <span>Organisation</span>
          </DotButton>
          <DotButton
            className={getClassNames(
              {
                active: activeRoute === 'org-users',
              },
              'step-2'
            )}
            onClick={() => {
              closeAll();
              history.push(
                endpointConfig.frontendEndpoints.orgUsers.replace(
                  ':id',
                  user.organisationId
                )
              );
            }}
          >
            <FontAwesomeIcon icon={'user-friends'} />
            <span>Users</span>
          </DotButton>
        </>
      )}
      {user.accessRoles.includes(Role.SysAdmin) && (
        <DotButton
          className={getClassNames({
            active: activeRoute === 'map-styles',
          })}
          onClick={() => {
            closeAll();
            history.push(endpointConfig.frontendEndpoints.editMapStyles);
          }}
        >
          <FontAwesomeIcon icon={'paint-brush'} />
          <span>Map Styles</span>
        </DotButton>
      )}
    </>
  );
};

AdminMenu.propTypes = {
  history: historyType.isRequired,
  activeRoute: PropTypes.oneOf([
    'missions',
    'org',
    'map-styles',
    'map-view',
    'create-mission',
    'view-mission',
    'org-users',
  ]),
};
