/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// External Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { deleteTeam, postTeam } from '../../../api/missions';
import { teamType } from '../../../enums/propTypes';
import { handleError } from '../../../utils/error';
import { DotButton } from '../../common/buttons/DotButton';
import { Input } from '../../common/Input';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { ColorPicker } from '../../common/ColorPicker';
import { ShapeColors } from '../../../enums/geofenceLevel';

/**
 * Sidebar - Edit team details
 *
 * @param {any} team team to edit
 * @param {any} orgSelected selected organisation
 * @param {Function} closeSidebar callback to close sidebar
 */
export const CreateEditTeam = ({ team, missionId, closeSidebar }) => {
  const getColorCode = (value) => {
    if (!value) return 'Black';
    let colorCode =
      Object.keys(ShapeColors).find((key) => ShapeColors[key] === value) ||
      'Black';
    return colorCode;
  };

  const [inputValues, setInputValues] = useState(
    team
      ? {
          team: team.team,
          color: getColorCode(team.color),
        }
      : { team: '', color: 'Black' }
  );
  const [deleteTeamModal, setDeleteTeamModal] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleSubmitTeam = async (e) => {
    e.preventDefault();

    // Handle missing parameters
    if (inputValues.team === '') {
      toast.error(`Name field required`);
      return;
    }
    if (!inputValues.color) {
      toast.error(`Theme colour field required`);
      return;
    }

    const param = {
      ...inputValues,
    };
    param.color = ShapeColors[inputValues.color] || inputValues.color;

    // Create new (or edited) group and send to api
    let newTeam = { ...param, missionId };
    if (team) {
      newTeam.id = team.id;
    }

    try {
      await postTeam(missionId, newTeam);
    } catch (err) {
      handleError(err);
    }

    // Reset for next new group
    setInputValues({
      team: '',
      color: 'Black',
    });

    closeSidebar(); // close side panel
  };

  const _setInputValues = (val) => {
    setInputValues(val);
    setTouched(true);
  };

  const isValid = () => {
    return !!inputValues.team && !!inputValues.color;
  };

  return (
    <div className="create-group">
      <div className="top">
        <div className="heading">
          <div className="text">{team ? 'Edit Team' : 'New Team'}</div>
          <DotButton className="exit" onClick={closeSidebar} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <div className="content">
        <h3>General Information</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (touched && isValid()) {
              handleSubmitTeam(e);
            }
          }}
        >
          <div className="controls">
            <Input
              label="Name"
              placeholder="Enter team name."
              onChange={(e) =>
                _setInputValues({
                  ...inputValues,
                  team: e.currentTarget.value,
                })
              }
              value={inputValues.team || ''}
              required={true}
            />
            <ColorPicker
              changeColor={(color) => {
                _setInputValues({ ...inputValues, color });
              }}
              selectedColor={inputValues.color}
            ></ColorPicker>
          </div>
        </form>
      </div>
      <div className="foot">
        <DotButton
          className="primary block"
          type="submit"
          disabled={!touched || !isValid()}
          onClick={(e) => {
            handleSubmitTeam(e);
          }}
        >
          {team ? 'Save Changes' : 'Create Team'}
        </DotButton>
        {!!team && (
          <DotButton
            className="secondary block"
            type="submit"
            onClick={(e) => {
              setDeleteTeamModal(true);
            }}
          >
            Delete Team
          </DotButton>
        )}
      </div>
      <ConfirmModal
        isActive={deleteTeamModal}
        exit={() => {
          setDeleteTeamModal(false);
        }}
        icon="exclamation-circle"
        modalId="delete-team"
        title={['Delete the ', <strong>{team?.name}</strong>, ' team?']}
        buttons={[
          {
            text: 'Yes, Delete the Team',
            type: 'primary',
            callback: async () => {
              await deleteTeam(missionId, team.id);
              setDeleteTeamModal(false);
              closeSidebar();
            },
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setDeleteTeamModal(false),
          },
        ]}
      >
        <p>
          All users in this team will be removed from this team but remain in
          the mission.
        </p>
      </ConfirmModal>
    </div>
  );
};

CreateEditTeam.propTypes = {
  team: teamType,
  missionId: PropTypes.string,
  closeSidebar: PropTypes.func.isRequired,
};
