/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getAllIntegrations } from '../../../api/integrations';
import {
  deleteOrgIntegration,
  saveOrgIntegration,
} from '../../../api/orgs_users';
import { handleError } from '../../../utils/error';
import { cancellablePromise } from '../../../utils/promise';
import { getClassNames } from '../../../utils/string';
import { DotButton } from '../../common/buttons/DotButton';
import { Input } from '../../common/Input';
import { ConfirmModal } from '../../modals/ConfirmModal';
import './OrgIntegrations.scss';

/**
 * Sidebar - org integrations sidebar
 * # not currently in use
 *
 * @param {string} organisationId
 * @param {Function} closeSidebar
 */
export const OrgIntegrations = ({ organisationId, closeSidebar, selected }) => {
  // set initial data
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({});
  const [formRequired, setFormRequired] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    const stub = async () => {
      log.debug('getting integrations for org', organisationId);
      setLoading(true);
      const all = await getAllIntegrations();
      setIntegrations(all);
      if (selected) {
        selectIntegration(selected);
      } else {
        selectIntegration(null);
      }
      setLoading(false);
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
  }, [organisationId, selected]);

  const exit = () => {
    if (selectedIntegration && !selectedIntegration.id) {
      setSelectedIntegration(null);
      setShowDelete(null);
    } else {
      closeSidebar(); // re-use function to revert width/padding
      setSelectedIntegration(null);
      setShowDelete(null);
    }
  };

  const selectIntegration = (integration) => {
    const _formData = {};
    const _formRequired = {};
    if (integration?.configurationItems?.length) {
      for (const ci of integration.configurationItems) {
        _formData[ci.key] = ci.value || ci.default || '';
        _formRequired[ci.key] =
          ci.required ||
          integration?.integration?.configurationItems?.find(
            (i) => i.key === ci.key
          )?.required;
      }
    }
    setSelectedIntegration(integration);
    setFormData(_formData);
    setFormRequired(_formRequired);
  };

  const validate = () => {
    for (const key of Object.keys(formData)) {
      if (formRequired[key] && !formData[key]) {
        return false;
      }
    }

    return true;
  };

  const handleUpdate = async (e) => {
    if (!selectedIntegration) {
      handleError('No integration selected');
      return;
    }

    try {
      const configurationItems = [];
      for (const key of Object.keys(formData)) {
        configurationItems.push({ key, value: formData[key] });
        if (formRequired[key] && !formData[key]) {
          const cis = selectedIntegration.integration
            ? selectedIntegration.integration.configurationItems
            : selectedIntegration.configurationItems;
          handleError(
            `${cis.find((i) => i.key === 'name').name || key} required`
          );
          return;
        }
      }

      setLoading(true);
      try {
        await saveOrgIntegration(
          selectedIntegration.id,
          selectedIntegration.code,
          organisationId,
          configurationItems
        );
        toast.success('Integration added');
      } catch (ex) {
        handleError(ex);
        return;
      } finally {
        setLoading(false);
      }

      setSelectedIntegration(null);
      closeSidebar();
    } catch (e) {
      handleError(e);
    }
  };

  const handleDelete = async () => {
    if (!selectedIntegration?.id) {
      handleError('No integration selected');
      return;
    }

    await deleteOrgIntegration(selectedIntegration.id);

    try {
      toast.success('Integration deleted');

      setSelectedIntegration(null);
      setShowDelete(false);
      closeSidebar();
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <div className="org-integrations">
      <div
        className={getClassNames({
          top: true,
          'has-back': !!selectedIntegration && !selectedIntegration.id,
        })}
      >
        <div className="heading">
          <div className="text">Select Integrations</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      {!!selectedIntegration && !selectedIntegration.id && (
        <DotButton className="back" onClick={exit} ariaLabel="Back">
          <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
        </DotButton>
      )}
      <div className="content">
        <div className="blocks">
          {selectedIntegration ? (
            <>
              <div className="item-container">
                <h2>Integration Details</h2>
              </div>
              <div className="item-container">
                <div>
                  <h5>Name</h5>
                  <p>
                    {selectedIntegration.name ||
                      selectedIntegration.integration.name}
                  </p>
                </div>
                <img
                  src={
                    selectedIntegration.photoUrl ||
                    selectedIntegration.integration.photoUrl
                  }
                  alt="integration logo"
                  style={{ height: '3.75rem' }}
                />
              </div>
              <div className="item-container">
                <div>
                  <h5>Description</h5>
                  <p>
                    {selectedIntegration.description ||
                      selectedIntegration.integration.description}
                  </p>
                </div>
              </div>

              {!!(
                selectedIntegration.integration
                  ? selectedIntegration.integration.configurationItems
                  : selectedIntegration.configurationItems
              ).length &&
                (selectedIntegration.integration
                  ? selectedIntegration.integration.configurationItems
                  : selectedIntegration.configurationItems
                ).map((ci) => (
                  <Input
                    label={ci.name}
                    placeholder={ci.name}
                    value={formData[ci.key]}
                    onChange={(e) => {
                      const _d = { ...formData };
                      _d[ci.key] = e.target.value;
                      setFormData(_d);
                    }}
                    required={formRequired[ci.key]}
                  />
                ))}
            </>
          ) : (
            <>
              {integrations.map((integration) => (
                <IntegrationSection
                  {...integration}
                  onSelect={
                    integration.disabled
                      ? null
                      : () => selectIntegration(integration)
                  }
                />
              ))}
            </>
          )}
        </div>
      </div>
      {!!selectedIntegration && (
        <div className="foot">
          <DotButton
            disabled={!validate() || loading}
            onClick={handleUpdate}
            className="primary block"
          >
            Save Changes
          </DotButton>
          {!!selectedIntegration?.id && (
            <DotButton
              onClick={() => setShowDelete(true)}
              className="secondary block"
            >
              Delete
            </DotButton>
          )}
        </div>
      )}

      <ConfirmModal
        isActive={!!selectedIntegration && showDelete}
        exit={() => {
          setShowDelete(false);
        }}
        icon="exclamation-circle"
        modalId="delete-style"
        title={['Delete ', selectedIntegration?.name, '?']}
        buttons={[
          {
            text: 'Yes, Delete the Integration',
            type: 'primary',
            callback: handleDelete,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setShowDelete(null),
          },
        ]}
      >
        <p>All missions will no longer be able to use this integration.</p>
      </ConfirmModal>
    </div>
  );
};

OrgIntegrations.propTypes = {
  organisationId: PropTypes.string.isRequired,
  closeSidebar: PropTypes.func.isRequired,
};

/**
 * integrations section
 *
 * @param {string} photoUrl photo url
 * @param {string} code code
 * @param {string} description description
 * @param {func} onSelect callback
 */
const IntegrationSection = ({
  photoUrl,
  name,
  description,
  icon,
  onSelect,
}) => {
  return (
    <div
      className={getClassNames({
        'item-container': true,
        disabled: !onSelect,
      })}
      style={{ cursor: onSelect ? 'pointer' : '' }}
      onClick={onSelect}
    >
      <div className="img">
        {icon ? (
          <FontAwesomeIcon
            icon={icon}
            style={{ width: '3.75rem', fontSize: '3.75rem' }}
          />
        ) : (
          <img
            src={photoUrl}
            alt="integration icon"
            style={{ width: '3.75rem' }}
          />
        )}
      </div>
      <div className="text">
        {name} <span>{description}</span>
      </div>
      {!!onSelect && (
        <div className="button">
          <FontAwesomeIcon icon="chevron-right" />
        </div>
      )}
    </div>
  );
};

IntegrationSection.propTypes = {
  photoUrl: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  description: PropTypes.string,
};
