/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { secrets } from './secrets';

if (!firebase.apps.length) {
  log.debug('fb init');
  firebase.initializeApp(secrets.firebase);
} else {
  log.debug('fb already initialised', secrets.webCert);
}

let messaging = null;
try {
  if (window.Notification) {
    messaging = firebase.messaging();
  }
} catch (ex) {
  log.error(ex);
}

export const getToken = (setToken) => {
  if (!messaging) {
    return;
  }
  return messaging
    .getToken(secrets.webCert)
    .then((currentToken) => {
      if (currentToken) {
        log.debug('current token for client: ', currentToken);
        setToken(currentToken);
      } else {
        log.debug(
          'No registration token available. Request permission to generate one.'
        );
        setToken(null);
      }
    })
    .catch((err) => {
      log.error('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (!messaging) {
      resolve(null);
    }
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
