/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  deleteMission,
  postMission,
  updateMissionStatus,
} from '../../../api/missions';
import activeSVG from '../../../assets/images/status/active.svg';
import archivedSVG from '../../../assets/images/status/archived.svg';
import planningSVG from '../../../assets/images/status/planning.svg';
import { endpointConfig } from '../../../config/endpoint_config';
import { useNotification } from '../../../context/NotificationContext';
import { useUser } from '../../../context/UserContext';
import { MISSION_FIELDS } from '../../../enums/missionFields';
import { historyType, missionType } from '../../../enums/propTypes';
import { Role } from '../../../enums/role';
import { only } from '../../../utils';
import { handleError } from '../../../utils/error';
import { DotButton } from '../../common/buttons/DotButton';
import { MenuButton } from '../../common/Menu';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { EditMissionModal } from '../../modals/EditMissionModal';
import { getClassNames, capitalize } from '../../../utils/string';
import { secrets } from '../../../config/secrets';
import './MissionInfo.scss';

/**
 * The mission toolbar widget that's on the top left of most mission screens.
 *
 * @param {any} history react router
 * @param {any[]} missions one or more mission in an array
 * @param {Function} centreMap centre map so you can see whole mission area
 * @param {Boolean} showDate show the mission start date
 * @param {Boolean} readOnly is the mission read only?
 * @param {Function} updateMission refresh mission
 */
export const MissionInfo = ({
  history,
  missions,
  centreMap,
  showDate = false,
  readOnly = false,
  updateMission,
  localMissions,
  FeatureType,
  editMissionArea,
  mapLoaded,
  mode,
  enableMissionCreation = false,
  enableEditMenu = true,
  form,
}) => {
  const { user } = useUser();
  const { setLastMissionId, setMissionName } = useNotification();
  const [missionToDeactivate, setMissionToDeactivate] = useState(null);
  const [missionToArchive, setMissionToArchive] = useState(null);
  const [editMission, setEditMission] = useState(null);

  // eslint-disable-next-line
  const isAdmin =
    user?.accessRoles?.includes(Role.SysAdmin) ||
    user?.accessRoles?.includes(Role.OrgAdmin);

  const canEdit =
    user?.accessRoles.includes(Role.SysAdmin) ||
    (user?.accessRoles.includes(Role.OrgAdmin) &&
      user?.organisationId === missions[0].organisationId);

  useEffect(() => {
    if (missions.length === 1) {
      setLastMissionId(missions[0].id);
      setMissionName(missions[0].missionName);
    }
  }, [missions, setLastMissionId, setMissionName]);

  const getStatus = () => {
    if (missions.length === 1) {
      if (missions[0]?.archived) {
        return <img src={archivedSVG} alt="archived" className="step-9" />;
      } else if (missions[0]?.published) {
        return <img src={activeSVG} alt="active" className="step-9" />;
      } else {
        return <img src={planningSVG} alt="planning" className="step-9" />;
      }
    } else {
      if (readOnly) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <img src={activeSVG} alt="active" className="step-9" />
            <span>All Missions</span>
          </div>
        );
      } else {
        return (
          <DotButton
            onClick={() =>
              history.push(endpointConfig.frontendEndpoints.missionNav)
            }
          >
            <FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>
            <span>Back</span>
          </DotButton>
        );
      }
    }
  };

  const missionOptions = [
    {
      text: 'Edit Name & Description',
      ariaLabel: 'Edit Name & Description',
      icon: 'pencil-alt',
      onClick: () => {
        // open modal
        setEditMission(true);
      },
    },
    {
      disabled: !missions[0].bounds,
      text: missions[0].published
        ? `Deactivate ${capitalize(secrets.EVENT_NAME)}`
        : `Activate ${capitalize(secrets.EVENT_NAME)}`,
      ariaLabel: missions[0].published
        ? `Deactivate ${capitalize(secrets.EVENT_NAME)}`
        : `Activate ${capitalize(secrets.EVENT_NAME)}`,
      icon: 'power-off',
      onClick: async () => {
        if (!missions[0].published) {
          if (missions[0]?.bounds) {
            try {
              await updateMissionStatus(missions[0].id, true);
              await updateMission(missions[0].id);

              toast.success(
                `${missions[0].missionName} has been successfully activated`
              );
            } catch (err) {
              handleError(err);
            }
          } else {
            toast.error(
              `Create a ${secrets.EVENT_NAME} area to activate this ${secrets.EVENT_NAME}.`
            );
          }
        } else {
          setMissionToDeactivate(missions[0]);
        }
      },
    },
    {
      text: `Archive ${capitalize(secrets.EVENT_NAME)}`,
      ariaLabel: `Archive ${capitalize(secrets.EVENT_NAME)}`,
      icon: 'trash',
      onClick: () => {
        setMissionToArchive(missions[0]);
      },
    },
  ];

  return (
    <div className="mission-info">
      <div className="left">
        {getStatus()}
        <DotButton
          tip={missions.length === 1 ? missions[0].missionName : ''}
          place="bottom"
          className={getClassNames({
            'map-styles-bar-button': true,
          })}
        >
          <span
            className={getClassNames({
              'mission-title': true,
            })}
            onClick={() => {
              if (centreMap) {
                centreMap();
              }
            }}
          >
            {missions.length === 1 ? missions[0].missionName : ''}
          </span>
        </DotButton>
        {enableMissionCreation && (
          <DotButton
            tip={
              !localMissions.current[0].bounds
                ? `Create ${capitalize(secrets.EVENT_NAME)} Area`
                : `Edit ${capitalize(secrets.EVENT_NAME)} Area`
            }
            place="bottom"
            className={getClassNames({
              'map-styles-bar-button': true,
              active: mode === FeatureType.Mission,
              blink:
                mode !== FeatureType.Mission &&
                !localMissions.current[0].bounds,
              'step-11': true,
              'map-mission-button': true,
            })}
            onClick={editMissionArea}
            disabled={!mapLoaded || form}
          >
            <FontAwesomeIcon
              icon={'draw-polygon'}
              style={{
                height: '15px',
                width: '15px',
              }}
            />
          </DotButton>
        )}
        {missions.length === 1 &&
          canEdit &&
          !missions[0].archived &&
          enableEditMenu && (
            <MenuButton
              position="bottom"
              options={missionOptions}
              ariaLabel="Mission Menu"
              tip={`${capitalize(secrets.EVENT_NAME)} Controls`}
              place="bottom"
              className={getClassNames({
                'map-styles-bar-button': true,
                action: true,
                'step-10': true,
              })}
              disabled={form}
            >
              <FontAwesomeIcon icon="bars" />
            </MenuButton>
          )}
      </div>
      {(missions.length > 1 || showDate) && !readOnly && (
        <div className="right">
          {missions.length === 1 && (
            <>
              <FontAwesomeIcon icon="calendar-alt"></FontAwesomeIcon>
              <span>{dayjs(missions[0].start).format('DD MMM YYYY')}</span>
            </>
          )}
          {missions.length > 1 && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (centreMap) {
                  centreMap();
                }
              }}
              className="multi"
            >
              Your Missions <strong>({missions.length})</strong>
            </span>
          )}
        </div>
      )}

      <ConfirmModal
        isActive={!!missionToDeactivate}
        exit={() => {
          setMissionToDeactivate(null);
        }}
        icon="exclamation-circle"
        modalId="deactivate-mission"
        title={[
          'Deactivate ',
          <strong>{missionToDeactivate?.missionName}</strong>,
          '?',
        ]}
        buttons={[
          {
            text: `Yes, Deactivate ${capitalize(secrets.EVENT_NAME)}`,
            type: 'primary',
            callback: async () => {
              try {
                await updateMissionStatus(missions[0].id, false);
                await updateMission(missions[0].id);

                toast.success(
                  `${missions[0].missionName} has been successfully deactivated`
                );

                setMissionToDeactivate(null);
              } catch (err) {
                handleError(err);
              }
            },
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setMissionToDeactivate(null),
          },
        ]}
      >
        <p>
          This {secrets.EVENT_NAME} will revert to the <strong>Planning</strong>{' '}
          status. You will still be able to access this {secrets.EVENT_NAME}{' '}
          from your {secrets.EVENT_NAME} list.
        </p>
      </ConfirmModal>
      <ConfirmModal
        isActive={!!missionToArchive}
        exit={() => {
          setMissionToArchive(null);
        }}
        icon="exclamation-circle"
        modalId="delete-mission"
        title={[
          'Archive ',
          <strong>{missionToArchive?.missionName}</strong>,
          '?',
        ]}
        buttons={[
          {
            text: `Yes, Archive ${capitalize(secrets.EVENT_NAME)}`,
            type: 'primary',
            callback: async () => {
              try {
                await deleteMission(missions[0].id);
                await updateMission(missions[0].id);

                toast.success(
                  `${missions[0].missionName} has been successfully archived`
                );

                setMissionToArchive(null);

                history.push(endpointConfig.frontendEndpoints.missionNav);
              } catch (err) {
                handleError(err);
              }
            },
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setMissionToArchive(null),
          },
        ]}
      >
        <p>
          This {secrets.EVENT_NAME} will no longer be available for access by
          your organisation and invited allies.
        </p>
      </ConfirmModal>
      <EditMissionModal
        modalId={'edit-mission-modal'}
        isActive={editMission}
        mission={missions[0]}
        exit={() => {
          setEditMission(false);
        }}
        onSubmit={async (m) => {
          try {
            await postMission(m.id, only(m, MISSION_FIELDS));
            await updateMission(m.id);
            setMissionName(m.missionName);

            toast.success(`${m.missionName} updated`);
          } catch (err) {
            handleError(err);
          }
        }}
      ></EditMissionModal>
    </div>
  );
};

MissionInfo.propTypes = {
  history: historyType,
  missions: PropTypes.arrayOf(missionType),
  centreMap: PropTypes.func,
  showDate: PropTypes.bool,
  readOnly: PropTypes.bool,
  updateMission: PropTypes.func,
  form: PropTypes.string,
};
