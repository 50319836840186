/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// External Components
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { getOrgSingle } from '../../../api/orgs_users';
import { endpointConfig } from '../../../config/endpoint_config.js';
import { useUser } from '../../../context/UserContext';
import { historyType } from '../../../enums/propTypes';
import { Role } from '../../../enums/role';
import { handleError } from '../../../utils/error';
import { cancellablePromise } from '../../../utils/promise';
import { AdminBar } from '../../common/AdminBar';
import { AdminMenu } from '../../common/menus/AdminMenu';
import { NavBar } from '../../common/Navbar';
import { DarkScrollbar } from '../../common/Scrollbars';
import { EditOrgContainer } from './edit-org/EditOrgContainer';
// Styling
import './Organisations.scss';

/**
 * Edit organisation page
 *
 * @param {any} history react router
 */
export const EditOrganisation = ({ history }) => {
  const { user } = useUser();
  const { id } = useParams();

  const [organisation, setOrganisation] = useState(null);

  const getOrganisation = async () => {
    if (
      user.accessRoles.includes(Role.SysAdmin) ||
      (user.accessRoles.includes(Role.OrgAdmin) && user.organisationId === id)
    ) {
      const org = await getOrgSingle(id).catch(handleError);
      setOrganisation(org);
    } else {
      // doesn't have access
      toast.error(`You don't have access to this organisation`);
      history.push(endpointConfig.frontendEndpoints.organisations);
    }
  };

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(getOrganisation());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  return (
    <div className="org-container">
      <NavBar
        activeRoute="org"
        history={history}
        showOnBack={user.accessRoles.includes(Role.SysAdmin)}
        onBack={() => {
          history.push(endpointConfig.frontendEndpoints.organisations);
        }}
      >
        <AdminMenu history={history} activeRoute="org"></AdminMenu>
      </NavBar>
      {organisation && (
        <div className="org-middle">
          <AdminBar
            title={
              user.accessRoles.includes(Role.SysAdmin)
                ? organisation?.organisationName || 'Organisation'
                : 'Your Organisation'
            }
            showOnBack={user.accessRoles.includes(Role.SysAdmin)}
            onBack={() => {
              history.push(endpointConfig.frontendEndpoints.organisations);
            }}
          />
          <div className="org-content">
            <DarkScrollbar className="org-content-row2 ">
              <EditOrgContainer
                key={organisation.organisationId || organisation.id}
                org={organisation}
                history={history}
                setSelectedOrg={setOrganisation}
              />
            </DarkScrollbar>
          </div>
        </div>
      )}
    </div>
  );
};

EditOrganisation.propTypes = {
  history: historyType,
};
