/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DotButton } from '../common/buttons/DotButton';
import { LightScrollbar } from '../common/Scrollbars';
import { Control1 } from './form-controls/Control1';
import { Control4 } from './form-controls/Control4';
import { Control7 } from './form-controls/Control7';
import { Control8 } from './form-controls/Control8';
import { useUser } from '../../context/UserContext';
import { useSidebar } from '../../context/SidebarContext';

/**
 * OPDEM/PRIDEM/MAINTDEM Form
 *
 */
export const Maintdem = ({ missionId, cancelForm }) => {
  const [hideContent, setHideContent] = useState(false);
  const [hidePanel, setHidePanel] = useState([]);
  const userValue = useUser();
  const sidebars = useSidebar();

  const panels = [
    {
      title: 'Serial and Destination',
      controls: [
        {
          label: 'Enter Names and/or Units to send OPDEM/PRIDEM/MAINTDEM to',
          required: true,
        },
      ],
    },
    {
      title: '1. Demand Type',
      tip: 'Select the type of demand, OPDEM - in 6 hours, PRIDEM - in 18 hours, MAINTDEM - in 72 hours',
      controls: [
        {
          values: ['OPDEM', 'PRIDEM', 'MAINTDEM'],
        },
      ],
    },
    {
      title: '2. Reason for Demand',
      controls: [
        {
          label: 'Reason for Demand',
          required: true,
          tip: 'Specify the reason for demand',
        },
      ],
    },
    {
      title: '3. Item X Quantity',
      tip: 'Enter the type of items and quantities to deliver',
      controls: [
        {
          label: ['Item', 'Quantity'],
          required: true,
        },
      ],
    },
    {
      title: '4. Delivery Requirements',
      tip: 'Enter the delivery requirements details',
      controls: [
        {
          ref: 'A.',
          label: 'Delivery Location Grid',
          required: true,
          tip: 'Enter MGRS using WGS84 of the delivery location',
        },
        {
          ref: 'B.',
          label: ['Date', 'Time'],
          required: true,
          tip: 'Enter the requested DTG of the delivery required',
        },
        {
          ref: 'C.',
          label: 'Preferred Method of Delivery',
          tip: 'Enter a preferred method of delivery',
        },
        {
          ref: 'D.',
          label: 'Frequency and Callsign for Marry Up If Required',
          tip: 'Enter the callsign and frequency for marry up if required for delivery',
        },
        {
          ref: 'E.',
          label: 'Other Instructions',
          tip: 'Enter any additional instructions regarding the delivery',
        },
      ],
    },
  ];

  return (
    <div className="form">
      <div className="header">
        <div className="left">
          <div className="avatar">OD</div>
          <div className="form-title">OPDEM/PRIDEM/MAINTDEM</div>
        </div>
        <div className="right">
          <div className="last-updated">Last updated:</div>
          <div className="chevron" onClick={() => setHideContent(!hideContent)}>
            <FontAwesomeIcon
              icon={!hideContent ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
      </div>
      <LightScrollbar>
        <div
          className="form-content"
          style={{ display: hideContent && 'none' }}
        >
          {!!panels &&
            panels.map((p, idx) => {
              return (
                <div className="panel" key={idx}>
                  <div className="panel-header">
                    <div className="left">
                      <div className="panel-title">{p.title}</div>
                      {!!p.tip && <div className="panel-tip">{p.tip}</div>}
                    </div>
                    <div className="right">
                      {/* <div className="last-updated">Last updated:</div> */}
                      <div
                        className="chevron"
                        onClick={(e) => {
                          e.preventDefault();
                          const hidePanelCopy = [...hidePanel];
                          hidePanelCopy[idx] = !hidePanel[idx];
                          setHidePanel(hidePanelCopy);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={!hidePanel[idx] ? 'chevron-up' : 'chevron-down'}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="panel-content"
                    style={{ display: hidePanel[idx] && 'none' }}
                  >
                    {idx === 0 && (
                      <Control4
                        control={p.controls[0]}
                        user={userValue}
                        missionId={missionId}
                      />
                    )}
                    {idx === 1 && <Control8 control={p.controls[0]} />}
                    {idx === 2 && <Control1 control={p.controls[0]} />}
                    {/* {idx === 3 && <Control9 control={p.controls[0]} />} */}
                    {idx === 4 &&
                      p.controls.map((c, idx) => {
                        if (idx === 0) {
                          return <Control1 control={c} halfWidth={true} />;
                        } else if (idx === 1) {
                          return <Control7 control={c} />;
                        } else {
                          return <Control1 control={c} />;
                        }
                      })}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="footer">
          <div className="button-panel">
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                sidebars.closeAll();
                cancelForm();
              }}
            >
              DISCARD FORM
            </DotButton>
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              SAVE AS DRAFT
            </DotButton>
            <DotButton
              className="primary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              SEND FORM
            </DotButton>
          </div>
        </div>
      </LightScrollbar>
    </div>
  );
};

Maintdem.propTypes = {
  missionId: PropTypes.any.isRequired,
  cancelForm: PropTypes.func.isRequired,
};
