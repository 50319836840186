/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { TagSelect } from '../common/TagSelect';
import { Modal } from './Modal';
import './Modals.scss';
import { PropTypes } from 'prop-types';
import toast from 'react-hot-toast';
/**
 * Modal for creating a new chat - has controls that don't really fit into the Prompt model.
 *
 * @param {Boolean} isActive is modal active
 * @param {Jsx} children modal body
 * @param {string} modalId modal id
 * @param {Function} exit close modal
 * @param {string} missionId mission id
 * @param {any} thread comms thread
 * @param {Function} onSubmit confirm action
 */
export const NewChatModal = ({
  isActive,
  children,
  modalId,
  exit,
  missionId,
  thread,
  onSubmit,
}) => {
  const [value, setValue] = useState('');
  const [unitsOrPersonnel, setUnitsOrPersonnel] = useState([]);

  // this will only run if initial value is changed
  useEffect(() => {
    if (thread?.id) {
      log.debug('prompt modal', 'setting value');
      let topic = thread.threadName;
      setValue(topic);

      if (thread?.participants?.length) {
        setUnitsOrPersonnel(
          thread?.participantsFull.map((p) => {
            p.type = 'user';
            return p;
          })
        );
      } else {
        setUnitsOrPersonnel([]);
      }
    } else {
      setUnitsOrPersonnel([]);
      setValue('');
    }
    // eslint-disable-next-line
  }, [thread]);

  const doExit = () => {
    setValue('');
    setUnitsOrPersonnel([]);
    exit();
  };

  const handleOnSubmit = async () => {
    await onSubmit(value, unitsOrPersonnel);
    doExit();
    setUnitsOrPersonnel([]);
  };

  return (
    <Modal {...{ isActive, modalId, exit: doExit }}>
      <div className="prompt-modal">
        <h2>{thread?.id ? 'Edit Chat Group' : 'Create a new chat'}</h2>
        {children}
        <form onSubmit={(e) => e.preventDefault()}>
          {!thread?.id && (
            <TagSelect
              missionId={missionId}
              label="Chat Member(s)"
              placeholder="Search for teams or users..."
              value={unitsOrPersonnel}
              canRemove={!thread}
              selectUnitOrPersonnel={(unit) => {
                log.debug('selectUnitOrPersonnel', unit);
                const _units = [...unitsOrPersonnel];

                log.debug('current units', _units);
                // todo: temporary - remove for prod
                if (unit.type === 'team') {
                  if (_units.filter((u) => u.type === 'user').length > 0) {
                    toast.error(
                      `Can't create a chat with both teams and users (for now)`
                    );
                    return;
                  }
                  if (_units.filter((u) => u.type === 'team').length > 0) {
                    toast.error(
                      `Can't create a chat with multiple teams (for now)`
                    );
                    return;
                  }
                }

                if (unit.type === 'user') {
                  if (_units.filter((u) => u.type === 'team').length > 0) {
                    toast.error(
                      `Can't create a chat with both teams and users (for now)`
                    );
                    return;
                  }
                }

                if (!_units.find((u) => u.id === unit.id)) {
                  _units.push(unit);
                  setUnitsOrPersonnel(_units);
                }
              }}
              removeUnitOrPersonnel={(unit) => {
                const _units = unitsOrPersonnel.filter((u) => u.id !== unit.id);
                setUnitsOrPersonnel(_units);
              }}
            ></TagSelect>
          )}
          {!!thread?.id && (
            <Input
              label="Chat Name"
              placeholder="Enter the name of the chat"
              value={value}
              onChange={(e) => {
                e.preventDefault();
                setValue(e.target.value);
              }}
            />
          )}
          <DotButton
            className="primary block"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();

              await handleOnSubmit();
            }}
            type="submit"
            disabled={unitsOrPersonnel.length === 0}
          >
            {thread?.id ? 'Save Changes' : 'Create Chat'}
          </DotButton>
        </form>
      </div>
    </Modal>
  );
};

NewChatModal.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.any,
  modalId: PropTypes.string,
  exit: PropTypes.func,
  missionId: PropTypes.string,
  thread: PropTypes.object,
  onSubmit: PropTypes.func,
};
