/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isHotkey from 'is-hotkey';
import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import {
  createEditor,
  Editor,
  Element as SlateElement,
  Transforms,
} from 'slate';
import { withDocxDeserializer } from 'slate-docx-deserializer';
import { withHistory } from 'slate-history';
import { jsx } from 'slate-hyperscript';
import { Editable, ReactEditor, Slate, useSlate, withReact } from 'slate-react';
import { Element, Leaf } from '../../utils/richtext';
import { getClassNames } from '../../utils/string';
import { Avatar } from './Avatar';
import { DotButton } from './buttons/DotButton';
import './RichText.scss';

/**
 * Hotkey definition for editor
 */
const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

/**
 * types of lists available in editor
 */
const LIST_TYPES = ['numbered-list', 'bulleted-list'];

/**
 * element tag translations from HTML to Slate. These tags can have children.
 */
const ELEMENT_TAGS = {
  A: (el) => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: (el) => ({ type: 'image', url: el.getAttribute('src') }),
  VIDEO: (el) => ({ type: 'file', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: (el) => ({ type: 'paragraph', color: el.getAttribute('color') }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
};

/**
 * text tag translations from HTML to Slate. These tags do not have children.
 *
 * compatibility note: `B` is omitted here because Google Docs uses `<b>` in weird ways.
 */
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  S: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

/**
 * Deserialise HTML fragment into Jsx
 *
 * @param {Element} el HTML element
 * @return {*}
 */
export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0];
  }
  const children = Array.from(parent.childNodes).map(deserialize).flat();

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx('text', attrs, child));
  } else if (nodeName === 'SPAN') {
    let color = el.color;
    if (!color) {
      const style = el.style;
      if (style.color) {
        color = style.color;
      }
    }
    const attrs = {
      color,
    };
    return children.map((child) => jsx('text', attrs, child));
  }

  return children;
};

/**
 * Rich Text Editor Control
 *
 * @param {string} label input label (form field set label)
 * @param {string} placeholder input placeholder text
 * @param {Descendant[]} value rich text value
 * @param {Function} onChange callback on rich text value changed
 * @param {string} theme input theme
 * @param {Boolean} required input is required?
 * @param {Boolean} readOnly input is read only?
 * @param {Boolean} disabled input disabled?
 * @param {any[]} taggables entities that can be tagged in rich text content
 */
export const RichText = ({
  label,
  placeholder,
  value,
  onChange,
  theme = '',
  required = false,
  readOnly = false,
  disabled = false,
  taggables = [],
}) => {
  const [touched, setTouched] = useState(false);
  const ref = useRef();

  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);

  // eslint-disable-next-line
  const [search, setSearch] = useState('');

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () =>
      withDocxDeserializer(
        withHtml(withMentions(withHistory(withReact(createEditor())))),
        jsx
      ),
    []
  );

  const searchResults = taggables.filter((t) =>
    t.name.toLowerCase().indexOf(search.toLowerCase() !== -1)
  );

  const onKeyDown = useCallback(
    (event) => {
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          toggleMark(editor, mark);
        }
      }

      if (target) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= searchResults.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? searchResults.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, searchResults[index]);
            setTarget(null);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
          default:
            break;
        }
      }
    },
    // eslint-disable-next-line
    [index, search, target]
  );

  useEffect(() => {
    if (target && searchResults.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [searchResults.length, editor, index, search, target]);

  const getType = (taggable) => {
    if (
      taggable.type === 'Unit' ||
      taggable.type === 'Personnel' ||
      taggable.type === 'User'
    ) {
      return '';
    }
    return ` (${taggable.type})`;
  };

  return (
    <div
      className={getClassNames(
        {
          'rich-text': true,
          touched,
        },
        theme
      )}
    >
      <Slate
        editor={editor}
        value={
          value || [
            {
              type: 'paragraph',
              children: [
                {
                  text: '',
                },
              ],
            },
          ]
        }
        onChange={(value) => {
          setTouched(true);
          onChange(value);

          log.debug('change', value);

          // const { selection } = editor;

          // uncomment for tagging
          // if (selection && Range.isCollapsed(selection)) {
          //   const [start] = Range.edges(selection);
          //   const wordBefore = Editor.before(editor, start, { unit: 'word' });
          //   const before = wordBefore && Editor.before(editor, wordBefore);
          //   const beforeRange = before && Editor.range(editor, before, start);
          //   const beforeText =
          //     beforeRange && Editor.string(editor, beforeRange);
          //   const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
          //   const after = Editor.after(editor, start);
          //   const afterRange = Editor.range(editor, start, after);
          //   const afterText = Editor.string(editor, afterRange);
          //   const afterMatch = afterText.match(/^(\s|$)/);

          //   if (beforeMatch && afterMatch) {
          //     setTarget(beforeRange);
          //     setSearch(beforeMatch[1]);
          //     setIndex(0);
          //     return;
          //   }
          // }

          setTarget(null);
        }}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          spellCheck
          autoFocus
          onKeyDown={onKeyDown}
          readOnly={disabled || false}
        />
        {target && searchResults.length > 0 && (
          <Portal>
            <div
              ref={ref}
              className="mention-menu"
              style={{
                top: '-9999px',
                left: '-9999px',
              }}
            >
              {searchResults.map((t, i) => (
                <div
                  key={t.id}
                  className={getClassNames({
                    'mention-item': true,
                    'is-active': i === index,
                  })}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    Transforms.select(editor, target);
                    insertMention(editor, t);
                    setTarget(null);
                  }}
                >
                  <Avatar size="3rem" entity={t} />
                  <div className="text">
                    <span>
                      <strong>{t.name}</strong>
                      {getType(t)}
                    </span>
                    <span>{t.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </Portal>
        )}
        <Toolbar className="toolbar">
          <StyleButton />
          <div className="seperator"></div>
          <MarkButton format="bold" icon="bold" />
          <MarkButton format="italic" icon="italic" />
          <MarkButton format="underline" icon="underline" />
          <MarkButton format="strikethrough" icon="strikethrough" />
          <FontColorButton />
          <div className="seperator"></div>
          <MarkButton format="code" icon="code" />
          <BlockButton format="block-quote" icon="quote-left" />
          <BlockButton format="numbered-list" icon="list-ol" />
          <BlockButton format="bulleted-list" icon="list-ul" />
          <div className="seperator"></div>
          <ImageButton />
          <FileButton />
        </Toolbar>
      </Slate>
      <label>
        {label}
        {required ? ' *' : ''}
      </label>
    </div>
  );
};

RichText.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  theme: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  taggables: PropTypes.arrayOf(PropTypes.object),
};

const withHtml = (editor) => {
  const { insertData, isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const html = data.getData('text/html');
    log.debug('insertData', html);

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};

const withMentions = (editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  return editor;
};

const insertMention = (editor, taggable) => {
  const mention = {
    type: 'mention',
    taggable,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

const insertImage = (editor, f) => {
  const image = {
    ...f,
    type: 'image',
    contentType: f.type,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, image);
  Transforms.move(editor);
};

const insertFile = (editor, f) => {
  const file = {
    ...f,
    type: 'file',
    contentType: f.type,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, file);
  Transforms.move(editor);
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  });
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const setColor = (editor, color) => {
  Editor.addMark(editor, 'color', color);
};

const getColor = (editor) => {
  const marks = Editor.marks(editor);
  return marks ? marks['color'] : '#000000';
};

/**
 * Style button
 */
const StyleButton = () => {
  const [active, setActive] = useState(false);

  const styles = [
    {
      text: 'H1',
      format: 'heading-one',
    },
    {
      text: 'H2',
      format: 'heading-two',
    },
    {
      text: 'H3',
      format: 'heading-three',
    },
    {
      text: 'H4',
      format: 'heading-four',
    },
    {
      text: 'H5',
      format: 'heading-five',
    },
    {
      text: 'H6',
      format: 'heading-six',
    },
    {
      text: 'Normal',
      format: 'p',
    },
  ];

  const editor = useSlate();
  return (
    <div
      className={getClassNames({
        dropdown: true,
        'is-active': active,
      })}
    >
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onMouseDown={(e) => {
            e.preventDefault();
            setActive(!active);
          }}
        >
          <span>Style</span>
          <span style={{ marginLeft: '5px' }} className="icon is-small">
            <FontAwesomeIcon icon="angle-down"></FontAwesomeIcon>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {styles.map((s) => (
            <button
              key={s.format}
              onMouseDown={(event) => {
                event.preventDefault();
                toggleBlock(editor, s.format);
                setActive(false);
              }}
              className={getClassNames({
                'dropdown-item': true,
                'is-active': isBlockActive(editor, s.format),
              })}
            >
              {s.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * Font colour button
 */
const FontColorButton = () => {
  const editor = useSlate();
  const picker = useRef();

  const value = getColor(editor);

  return (
    <label
      className="color-picker"
      onMouseDown={(e) => {
        e.preventDefault();
        picker.current.click();
      }}
    >
      <span className="icon main">
        <FontAwesomeIcon icon="font" color={value}></FontAwesomeIcon>
      </span>
      <span className="icon is-small">
        <FontAwesomeIcon icon="angle-down"></FontAwesomeIcon>
      </span>
      <input
        ref={picker}
        onChange={(e) => {
          log.debug('colour', e.target.value);
          setColor(editor, e.target.value);
        }}
        type="color"
        value={value || ''}
      ></input>
    </label>
  );
};

/**
 * Block button
 */
const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <DotButton
      className={getClassNames({
        'is-active': isBlockActive(editor, format),
      })}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </DotButton>
  );
};

/**
 * Markup button
 */
const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <DotButton
      className={getClassNames({
        'is-active': isMarkActive(editor, format),
      })}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </DotButton>
  );
};

/**
 * Image upload button
 */
const ImageButton = () => {
  const editor = useSlate();
  return (
    <DotButton
      onMouseDown={(event, file) => {
        event.preventDefault();
        insertImage(editor, file);
      }}
      file
      accept="image/*"
    >
      <FontAwesomeIcon icon="image" />
    </DotButton>
  );
};

/**
 * File upload button
 */
const FileButton = () => {
  const editor = useSlate();
  return (
    <DotButton
      onMouseDown={(event, file) => {
        event.preventDefault();
        insertFile(editor, file);
      }}
    >
      <FontAwesomeIcon icon="paperclip" />
    </DotButton>
  );
};

/**
 * Menu button
 */
export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={className} />
));

/**
 * Toolbar button
 */
export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu {...props} ref={ref} className={className} />
));

/**
 * Dom portal
 *
 * @param {Jsx} children
 * @returns
 */
export const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

Portal.propTypes = {
  children: PropTypes.any,
};
