/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { Modal } from './Modal';
import './Modals.scss';

/**
 * Display a message as a modal and ask for some user input.
 *
 * @param {Boolean} isActive is modal active
 * @param {Jsx} children modal body
 * @param {string} modalId modal id
 * @param {Function} exit close modal
 * @param {string} title title
 * @param {string} label input label
 * @param {string} placeholder input placeholder
 * @param {string} initialValue input initial value
 * @param {string} buttonText confirm button text
 * @param {Function} onSubmit confirm action
 */
export const PromptModal = ({
  isActive,
  children,
  modalId,
  exit,
  title,
  label,
  placeholder,
  initialValue,
  buttonText,
  onSubmit,
}) => {
  const [value, setValue] = useState('');

  // this will only run if initial value is changed
  useEffect(() => {
    if (!value && initialValue) {
      log.debug('prompt modal', 'setting value');
      setValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const doExit = () => {
    setValue('');
    exit();
  };

  const handleOnSubmit = async () => {
    await onSubmit('' + value);
    doExit();
  };

  return (
    <Modal {...{ isActive, modalId, exit }}>
      <div className="prompt-modal">
        <h2>{title || 'Prompt'}</h2>
        {children}
        <form onSubmit={(e) => e.preventDefault()}>
          <Input
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              e.preventDefault();
              setValue(e.target.value);
            }}
            required={true}
          />
          <DotButton
            className="primary block"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();

              await handleOnSubmit();
            }}
            type="submit"
            disabled={!value}
          >
            {buttonText || 'Submit'}
          </DotButton>
        </form>
      </div>
    </Modal>
  );
};

PromptModal.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.any,
  modalId: PropTypes.string,
  exit: PropTypes.func,
  title: PropTypes.any,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};
