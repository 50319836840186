/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Battery.scss';
import { PropTypes } from 'prop-types';

/**
 * Displays battery information.
 *
 * https://www.w3.org/TR/battery-status/
 * https://developer.android.com/reference/android/os/BatteryManager
 * https://developer.apple.com/documentation/uikit/uidevice/batterystate
 *
 * @param {any} style The org, group, or personnel
 * @param {{
    level: number,
    charging: boolean,
    is_charging: boolean,
    dischargingTime: number,
    chargingTime: number,
  }} data Battery data
 */
export const Battery = ({ style, data }) => {
  function format(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    //var secs = ~~time % 60;

    var ret = '';
    if (hrs > 0) {
      ret += '' + hrs + ' hrs ' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ' mins'; // + (secs < 10 ? "0" : "");
    // ret += "" + secs;
    return ret;
  }

  let icon = 'battery-empty';
  let status = `No battery`;

  if (data?.level) {
    if (data?.level >= 0.9) {
      icon = 'battery-full';
    } else if (data?.level >= 0.75) {
      icon = 'battery-three-quarters';
    } else if (data?.level >= 0.5) {
      icon = 'battery-half';
    } else if (data?.level >= 0.25) {
      icon = 'battery-quarter';
    }

    if (!(data.charging || data.is_charging)) {
      if (data.dischargingTime && data.dischargingTime !== Infinity) {
        status = `Discharging... ${format(data?.dischargingTime)} (${
          data?.level * 100
        }%) remaining`;
      } else {
        status = `Discharging... ${Math.floor(data?.level * 100)}% remaining`;
      }
    }

    if (data.charging || data.is_charging) {
      if (data.chargingTime && data.chargingTime !== Infinity) {
        status = `Charging... ${format(
          data?.chargingTime
        )} until fully charged`;
      } else {
        status = `On Charge`;
      }
    }
  }

  return (
    <div className="battery">
      <div className="fa-layers" style={style}>
        <FontAwesomeIcon icon={icon} title={status} style={style} />
        {(!data || data?.level === -1) && (
          <FontAwesomeIcon icon="slash" style={{ ...style, color: 'red' }} />
        )}
        {(data?.charging || data?.is_charging) && (
          <FontAwesomeIcon icon="bolt" style={{ ...style, color: 'yellow' }} />
        )}
      </div>
      <span>
        {!data?.level || data?.level === -1
          ? ''
          : Math.round(data?.level * 100) + '%'}
      </span>
    </div>
  );
};

Battery.propTypes = {
  style: PropTypes.any,
  data: PropTypes.shape({
    level: PropTypes.number,
    charging: PropTypes.bool,
    is_charging: PropTypes.bool,
    dischargingTime: PropTypes.number,
    chargingTime: PropTypes.number,
  }),
};
