/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

String.prototype.replaceAt = function (index, character) {
  return (
    this.substr(0, index) + character + this.substr(index + character.length)
  );
};

export const decodeMilspecSymbolCode = (symbolCode) => {
  if (symbolCode) {
    let milspec = {
      standardIdentity1: symbolCode.substring(2, 3),
      standardIdentity2: symbolCode.substring(3, 4),
      symbolSet: symbolCode.substring(4, 6),
      status: symbolCode.substring(6, 7),
      headquarters: symbolCode.substring(7, 8),
      echelon: symbolCode.substring(8, 10),
      entity: symbolCode.substring(10, 16),
      modifier1: symbolCode.substring(16, 18),
      modifier2: symbolCode.substring(18, 20),
    };
    return milspec;
  }
};

export const encodeMilspecSymbolCode = (symbolData) => {
  var symbolCode = '10000000000000000000';
  symbolCode = symbolCode.replaceAt(2, symbolData.standardIdentity1);
  symbolCode = symbolCode.replaceAt(3, symbolData.standardIdentity2);
  symbolCode = symbolCode.replaceAt(4, symbolData.symbolSet);
  symbolCode = symbolCode.replaceAt(6, symbolData.status);
  symbolCode = symbolCode.replaceAt(7, symbolData.headquarters);
  symbolCode = symbolCode.replaceAt(8, symbolData.echelon);
  symbolCode = symbolCode.replaceAt(10, symbolData.entity);
  symbolCode = symbolCode.replaceAt(16, symbolData.modifier1);
  symbolCode = symbolCode.replaceAt(18, symbolData.modifier2);
  return symbolCode;
};
