/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import { postResendInvitation } from '../../../api/orgs_users';
import { useUser } from '../../../context/UserContext';
import { userType } from '../../../enums/propTypes';
import { Role } from '../../../enums/role';
import { handleError } from '../../../utils/error';
import { getClassNames } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import './UserPanel.scss';
import { PropTypes } from 'prop-types';

/**
 * User list item / panel in users list
 *
 * @param {any} user user
 * @param {any} editUser edit user - opens sidebar
 * @param {any} setUserToDelete set user to delete - opens modal
 * @param {any} selectUser select user - for bulk operations
 * @param {any} selected is user selected - for bulk operations
 */
export const UserPanel = ({
  user,
  editUser,
  setUserToDelete,
  selectUser,
  selected,
}) => {
  const userValue = useUser();

  const canDelete =
    (userValue.user.accessRoles.includes(Role.SysAdmin) ||
      userValue.user.accessRoles.includes(Role.OrgAdmin)) &&
    userValue.user.id !== user.id;
  const canEdit =
    userValue.user.accessRoles.includes(Role.SysAdmin) ||
    userValue.user.accessRoles.includes(Role.OrgAdmin) ||
    userValue.user.id === user.id;

  return (
    <div
      className={getClassNames({
        block: true,
        'user-info': true,
        selectable: canDelete || canEdit,
      })}
      onClick={() => {
        if (canDelete || canEdit) {
          selectUser({
            userId: user.id,
            canEdit,
            canDelete,
            roleId: user.roleId,
          });
        }
      }}
    >
      {selected ? (
        <div className="selected">
          <FontAwesomeIcon
            icon="check-circle"
            color="var(--colour-white)"
          ></FontAwesomeIcon>
        </div>
      ) : (
        <Avatar entity={user} />
      )}
      <div className="user-info-name">
        {user.firstname} {user.lastname}
        {user.id === userValue?.user?.id ? ' (You)' : ''}
        {/* TODO: Project SS
        <FontAwesomeIcon
          icon="chevron-right"
          style={{ height: '10px', width: '10px', marginLeft: '5px' }}
        /> */}
        <span>{user.role?.role}</span>
      </div>
      {/* <div className="user-info-text" style={{ marginLeft: 'none' }}>
        {user.role}
      </div> */}
      <div className="user-info-text">{user.accessLevel || 'User'}</div>
      {/* <div className="user-info-text">{user.role?.role}</div> */}
      {/* <div className="user-info-text">{user.groupName}</div> */}
      <div className="user-info-email">{user.email}</div>
      <div className="user-info-icon">
        <span>
          {user.invitationAccepted
            ? 'Invitation Accepted'
            : 'Invitation Pending'}
        </span>
        <FontAwesomeIcon
          icon={user.invitationAccepted ? 'check' : 'hourglass'}
        />
      </div>
      {!user.invitationAccepted && (
        <DotButton
          className="user-info-button user-info-resend"
          onClick={async () => {
            // resend invite
            const rs = await postResendInvitation(user.id).catch(handleError);
            if (rs?.success) {
              toast.success(rs.message);
            }
          }}
        >
          <span>Resend Invite</span>
          <FontAwesomeIcon
            icon="envelope-square"
            style={{ height: '10px', width: '10px', marginLeft: '5px' }}
          />
        </DotButton>
      )}
      <DotButton
        className="user-info-button user-info-delete"
        style={{ visibility: canDelete ? '' : 'hidden' }}
        onClick={() => {
          setUserToDelete(user);
        }}
      >
        <span>Delete User</span>
        <FontAwesomeIcon
          icon="trash"
          style={{ height: '10px', width: '10px' }}
        />
      </DotButton>
      <DotButton
        className="user-info-button user-info-edit"
        style={{ visibility: canEdit ? '' : 'hidden' }}
        onClick={() => {
          editUser(user);
        }}
      >
        <span>Edit User</span>
        <FontAwesomeIcon
          icon="pencil-alt"
          style={{ height: '10px', width: '10px', marginLeft: '5px' }}
        />
      </DotButton>
    </div>
  );
};

UserPanel.propTypes = {
  user: userType,
  editUser: PropTypes.func,
  setUserToDelete: PropTypes.func,
  selectUser: PropTypes.func,
  selected: PropTypes.bool,
};
