/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
import { PropTypes } from 'prop-types';
import { useState, useCallback, useEffect, useRef } from 'react';
import './Forms.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DotButton } from '../common/buttons/DotButton';
import { LightScrollbar } from '../common/Scrollbars';
import { Control1 } from './form-controls/Control1';
import { Control2 } from './form-controls/Control2';
import { Control3 } from './form-controls/Control3';
import { Control4 } from './form-controls/Control4';
import { useUser } from '../../context/UserContext';
import { useSidebar } from '../../context/SidebarContext';
import { createForm, updateForm, sendForm, getForms } from '../../api/forms';
import toast from 'react-hot-toast';
import moment from 'moment';
import { cancellablePromise } from '../../utils/promise';
import { ConfirmModal } from '../modals/ConfirmModal';

/**
 * SITREP Form
 *
 */
export const Sitrep = ({ missionId, cancelForm, formToEdit }) => {
  const userValue = useUser();
  const sidebars = useSidebar();
  const [hideContent, setHideContent] = useState(false);
  const [hidePanel, setHidePanel] = useState([]);
  const [formId, setFormId] = useState(!!formToEdit ? formToEdit.id : null);
  const [isValid, setIsValid] = useState();
  const [confirmDiscard, setConfirmDiscard] = useState(false);
  const [form, setForm] = useState(
    !!formToEdit
      ? {
          _ts: formToEdit._ts,
          serial: formToEdit.serial,
          id: formToEdit.id,
          type: formToEdit.type,
          from: formToEdit.from,
          to: formToEdit.to,
          state: formToEdit.state,
          content: { ...formToEdit.content },
          missionId: formToEdit.missionId,
          archived: formToEdit.archived,
        }
      : {
          type: 'sitrep',
          to: [],
          state: 'draft',
          content: {
            threatIdentity: '',
            threatSummary: '',
            threatLocation: '',
            threatConclusion: '',
            forwardElementsLocation: [],
            unitsLocation: [],
            adjacentUnitsLocation: [],
            description: '',
            currentActivity: '',
            summaryOfCivilianSituation: '',
            displacedPersons: '',
            significantCivilianEvents: '',
            locationOfDisplacedPersons: '',
            generalStatements: '',
            generalIntentions: '',
            commandersEvaluation: '',
          },
          missionId: missionId,
          archived: false,
        }
  );
  const [formEdits, setFormEdits] = useState(form);

  const panels = [
    {
      title: 'Serial and Destination',
      controls: [
        {
          label: 'Enter Names and/or Units to send SITREP to',
          required: true,
        },
      ],
    },
    {
      title: '1. Period Covered (DTG to DTG)',
      tip: 'Enter the time period covered by this SITREP',
      controls: [],
    },
    {
      title: '2. Threat',
      tip: 'Enter details of a threat in this section',
      controls: [
        {
          ref: 'A.',
          label: 'Identity (Threat / Element)',
          tip: 'Enter the identity of threat/element',
          required: true,
        },
        {
          ref: 'B.',
          label: 'Summary of Threat Activity',
          tip: 'Enter a brief summary of the threat activity',
          required: true,
        },
        {
          ref: 'C.',
          label: 'Threat Location, Strength and Combat Effectiveness',
          tip: 'Describe any known locations, strengths, combat ability/effectiveness of threats',
          required: true,
        },
        {
          ref: 'D.',
          label: 'Conclusions of Threat COA',
          tip: 'Describe the most likely and most dangerous course of action that the threats can or will take',
          required: true,
        },
      ],
    },
    {
      title: '3. Own Situation',
      tip: 'Enter details of your own situation in this section',
      controls: [
        {
          ref: 'A.',
          label: 'Location of Forward Elements',
          tip: 'Enter the location(s) of forward elements in MGRS using WGS84',
          required: true,
        },
        {
          ref: 'B.',
          label: 'Location of Units and HQ',
          tip: 'Enter the location(s) of units and HQ in MGRS using WGS84',
          required: true,
        },
        {
          ref: 'C.',
          label: 'Location of Adjacent Units',
          tip: 'Enter the location(s) of adjacent units in MGRS using WGS84',
          required: true,
        },
        {
          ref: 'D.',
          label: 'Brief Description and Results of Ops',
          tip: 'Enter a summary and the results of operations that were carried out during the reporting period',
          required: true,
        },
        {
          ref: 'E.',
          label: 'Current Activity',
          tip: 'Enter a summary of operations that are currently ongoing',
          required: true,
        },
      ],
    },
    {
      title: '4. Civilian',
      tip: 'Enter details of civilian activities, situation and conditions in this section',
      controls: [
        {
          ref: 'A.',
          label: 'Summary of Civilian Situation in A0',
          tip: 'Enter a summart of the civilian situation in the area',
          required: true,
        },
        {
          ref: 'B.',
          label: 'Number and Flow of Displaced Persons',
          tip: 'Enter a summary of the number and movement of displaced civilians',
          required: true,
        },
        {
          ref: 'C.',
          label: 'Significant Civilian Events in the past 24 hours',
          tip: 'Enter a summary of any significant civilian events within the last 24 hours',
          required: true,
        },
        {
          ref: 'D.',
          label: 'Location of Displaced Persons',
          tip: 'Enter a summary of any locations of displaced civilians',
          required: true,
        },
      ],
    },
    {
      title: '5. Admin (General Statements)',
      controls: [
        {
          label: 'Admin (General Statements)',
          tip: 'Enter general statements of admin',
          required: true,
        },
      ],
    },
    {
      title: '6. General and Future Intentions',
      controls: [
        {
          label: 'General and Future Intentions',
          tip: 'Enter your general intentions (must include future intentions)',
          required: true,
        },
      ],
    },
    {
      title: "7. Commander's Evaluation",
      controls: [
        {
          label: "Commander's Evaluation",
          tip: "Enter the commander's evaluation of the situation",
          required: true,
        },
      ],
    },
  ];

  const handleSetFormData = async (key, value) => {
    const formCopy = formEdits;
    if (key === 'to') {
      let to = [];
      for (const v of value) {
        to.push({ id: v.id, type: 'user', status: 'unread' });
      }
      formCopy[key] = to;
    } else {
      formCopy.content[key] = value;
    }
    setFormEdits(formCopy);
    checkIsValid(formCopy);
  };

  const handleSaveForm = useCallback(async () => {
    if (!!!formId) {
      const response = await createForm(missionId, formEdits);
      if (response.id) {
        setFormId(response.id);
        toast.success('SITREP draft saved successfully');
        sidebars.closeAll();
        cancelForm();
      } else {
        toast.error('SITREP draft failed to save');
      }
    } else {
      await updateForm(missionId, formId, formEdits);
      toast.success('SITREP updated successfully');
      sidebars.closeAll();
      cancelForm();
    }
    // eslint-disable-next-line
  }, [formId, form]);

  const handleSaveSingleProperty = async (key, value) => {
    const formCopy = form;
    if (key === 'to') {
      let to = [];
      for (const v of value) {
        to.push({ id: v.id, type: 'user', status: 'unread' });
      }
      formCopy[key] = to;
    } else {
      formCopy.content[key] = value;
    }
    if (!!!formId) {
      const response = await createForm(missionId, formCopy);
      if (response.id) {
        setFormId(response.id);
        toast.success('SITREP draft saved successfully');
      } else {
        toast.error('SITREP draft failed to save');
      }
    } else {
      await updateForm(missionId, formId, formCopy);
      toast.success('Field updated successfully');
    }
  };

  const handleSaveMultipleProperty = async (keys, values) => {
    const formCopy = form;
    for (let i = 0; i < keys.length; i++) {
      formCopy.content[keys[i]] = values[i];
    }
    if (!!!formId) {
      const response = await createForm(missionId, formCopy);
      if (response.id) {
        setFormId(response.id);
        toast.success('SITREP draft saved successfully');
      } else {
        toast.error('SITREP draft failed to save');
      }
    } else {
      await updateForm(missionId, formId, formCopy);
      toast.success('Field updated successfully');
    }
  };

  const handleDiscardForm = useCallback(async () => {
    sidebars.closeAll();
    cancelForm();
    // eslint-disable-next-line
  }, [formId]);

  const handleSendForm = async () => {
    if (!!!formId && isValid) {
      const response = await createForm(missionId, form);
      await sendForm(missionId, response.id);
      toast.success('SITREP sent successfully');
      sidebars.closeAll();
      cancelForm();
    } else {
      if (isValid) {
        await sendForm(missionId, formId);
        toast.success('SITREP sent successfully');
        sidebars.closeAll();
        cancelForm();
      } else {
        toast.error('SITREP failed to send. Please check all required fields');
      }
    }
  };

  const checkIsValid = (form) => {
    if (
      !!form.to &&
      form.to.length > 0 &&
      !!form.content.threatIdentity &&
      !!form.content.threatSummary &&
      !!form.content.threatLocation &&
      !!form.content.threatConclusion &&
      !!form.content.forwardElementsLocation &&
      form.content.forwardElementsLocation.length > 0 &&
      !!form.content.unitsLocation &&
      form.content.unitsLocation.length > 0 &&
      !!form.content.adjacentUnitsLocation &&
      form.content.adjacentUnitsLocation.length > 0 &&
      !!form.content.description &&
      !!form.content.currentActivity &&
      !!form.content.summaryOfCivilianSituation &&
      !!form.content.displacedPersons &&
      !!form.content.significantCivilianEvents &&
      !!form.content.locationOfDisplacedPersons &&
      !!form.content.generalStatements &&
      !!form.content.generalIntentions &&
      !!form.content.commandersEvaluation &&
      !!missionId
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    const stub = async () => {
      const savedForms = await getForms(missionId);
      const savedForm = savedForms.filter((f) => f.id === formId)[0];
      if (!!savedForm) {
        const newFormToEdit = {
          _ts: savedForm._ts,
          serial: savedForm.serial,
          id: savedForm.id,
          type: savedForm.type,
          from: savedForm.from,
          to: savedForm.to,
          state: savedForm.state,
          content: { ...savedForm.content },
          missionId: savedForm.missionId,
          archived: savedForm.archived,
        };
        setForm(newFormToEdit);
      }
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
  }, [formId]);

  return (
    <div className="form">
      <div
        className="heading"
        style={{ marginBottom: '15px', marginRight: '15px' }}
      >
        <DotButton
          className="exit"
          onClick={() => setConfirmDiscard(true)}
          ariaLabel="Close"
        >
          <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
        </DotButton>
      </div>
      <div className="header">
        <div className="left">
          <div className="avatar">SR</div>
          <div className="form-title">SITREP</div>
        </div>
        <div className="right">
          {formToEdit?._ts && (
            <div className="last-updated">
              Last updated:{' '}
              {moment.unix(formToEdit?._ts).format('HH:mm A, DD MMM YYYY')}
            </div>
          )}
          <div className="chevron" onClick={() => setHideContent(!hideContent)}>
            <FontAwesomeIcon
              icon={!hideContent ? 'chevron-up' : 'chevron-down'}
            />
          </div>
        </div>
      </div>
      <LightScrollbar>
        <div
          className="form-content"
          style={{ display: hideContent && 'none' }}
        >
          {!!panels &&
            panels.map((p, idx) => {
              return (
                <div className="panel" key={idx}>
                  <div className="panel-header">
                    <div className="left">
                      <div className="panel-title">{p.title}</div>
                      {!!p.tip && <div className="panel-tip">{p.tip}</div>}
                    </div>
                    <div className="right">
                      {/* <div className="last-updated">Last updated:</div> */}
                      <div
                        className="chevron"
                        onClick={(e) => {
                          e.preventDefault();
                          const hidePanelCopy = [...hidePanel];
                          hidePanelCopy[idx] = !hidePanel[idx];
                          setHidePanel(hidePanelCopy);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={!hidePanel[idx] ? 'chevron-up' : 'chevron-down'}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: hidePanel[idx] && 'none' }}>
                    {idx === 0 && (
                      <Control4
                        control={p.controls[0]}
                        user={userValue}
                        missionId={missionId}
                        handleSetFormData={handleSetFormData}
                        propertyToUpdate={'to'}
                        value={formToEdit?.to}
                        handleSaveSingleProperty={handleSaveSingleProperty}
                      />
                    )}
                    {idx === 1 && (
                      <Control3
                        control={p.controls[0]}
                        handleSetFormData={handleSetFormData}
                        propertiesToUpdate={['fromDtg', 'toDtg']}
                        values={[
                          formToEdit?.content?.fromDtg,
                          formToEdit?.content?.toDtg,
                        ]}
                        handleSaveMultipleProperty={handleSaveMultipleProperty}
                      />
                    )}
                    {idx === 2 &&
                      p.controls.map((c, idx) => {
                        switch (idx) {
                          case 0:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'threatIdentity'}
                                value={formToEdit?.content?.threatIdentity}
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 1:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'threatSummary'}
                                value={formToEdit?.content?.threatSummary}
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 2:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'threatLocation'}
                                value={formToEdit?.content?.threatLocation}
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 3:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'threatConclusion'}
                                value={formToEdit?.content?.threatConclusion}
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          default:
                            return <></>;
                        }
                      })}
                    {idx === 3 &&
                      p.controls.map((c, idx) => {
                        switch (idx) {
                          case 0:
                            return (
                              <Control2
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'forwardElementsLocation'}
                                values={
                                  formToEdit?.content?.forwardElementsLocation
                                }
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 1:
                            return (
                              <Control2
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'unitsLocation'}
                                values={formToEdit?.content?.unitsLocation}
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 2:
                            return (
                              <Control2
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'adjacentUnitsLocation'}
                                values={
                                  formToEdit?.content?.adjacentUnitsLocation
                                }
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 3:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'description'}
                                value={formToEdit?.content?.description}
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 4:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'currentActivity'}
                                value={formToEdit?.content?.currentActivity}
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          default:
                            return <></>;
                        }
                      })}
                    {idx === 4 &&
                      p.controls.map((c, idx) => {
                        switch (idx) {
                          case 0:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'summaryOfCivilianSituation'}
                                value={
                                  formToEdit?.content
                                    ?.summaryOfCivilianSituation
                                }
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 1:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'displacedPersons'}
                                value={formToEdit?.content?.displacedPersons}
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 2:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'significantCivilianEvents'}
                                value={
                                  formToEdit?.content?.significantCivilianEvents
                                }
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          case 3:
                            return (
                              <Control1
                                control={c}
                                handleSetFormData={handleSetFormData}
                                propertyToUpdate={'locationOfDisplacedPersons'}
                                value={
                                  formToEdit?.content
                                    ?.locationOfDisplacedPersons
                                }
                                handleSaveSingleProperty={
                                  handleSaveSingleProperty
                                }
                              />
                            );
                          default:
                            return <></>;
                        }
                      })}
                    {idx === 5 && (
                      <Control1
                        control={p.controls[0]}
                        handleSetFormData={handleSetFormData}
                        propertyToUpdate={'generalStatements'}
                        value={formToEdit?.content?.generalStatements}
                        handleSaveSingleProperty={handleSaveSingleProperty}
                      />
                    )}
                    {idx === 6 && (
                      <Control1
                        control={p.controls[0]}
                        handleSetFormData={handleSetFormData}
                        propertyToUpdate={'generalIntentions'}
                        value={formToEdit?.content?.generalIntentions}
                        handleSaveSingleProperty={handleSaveSingleProperty}
                      />
                    )}
                    {idx === 7 && (
                      <Control1
                        control={p.controls[0]}
                        handleSetFormData={handleSetFormData}
                        propertyToUpdate={'commandersEvaluation'}
                        value={formToEdit?.content?.commandersEvaluation}
                        handleSaveSingleProperty={handleSaveSingleProperty}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="footer">
          <div className="button-panel">
            <DotButton
              className="secondary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await handleSaveForm();
              }}
            >
              {!!formId ? 'SAVE CHANGES' : 'SAVE FORM'}
            </DotButton>
            <DotButton
              className="primary block"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await handleSendForm();
              }}
              disabled={!isValid}
            >
              SEND FORM
            </DotButton>
          </div>
        </div>
      </LightScrollbar>
      <ConfirmModal
        isActive={!!confirmDiscard}
        exit={() => {
          setConfirmDiscard(false);
        }}
        icon="exclamation-circle"
        modalId="delete-form"
        title={'Close form? Any unsaved changes will be lost'}
        buttons={[
          {
            text: 'Yes, Close Form',
            type: 'primary',
            callback: handleDiscardForm,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => {
              setConfirmDiscard(false);
            },
          },
        ]}
      ></ConfirmModal>
    </div>
  );
};

Sitrep.propTypes = {
  missionId: PropTypes.any.isRequired,
  cancelForm: PropTypes.func.isRequired,
  formToEdit: PropTypes.any,
};
