/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * @file Context to handle Joyride state
 * @author Matthew Holdsworth
 */
import { PropTypes } from 'prop-types';
import { createContext, useContext, useState } from 'react';
import { Role } from '../enums/role';
import { useUser } from './UserContext';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { CheckBox } from '../components/common/CheckBox';
import { DotButton } from '../components/common/buttons/DotButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tours } from '../enums/tours';
import * as log from 'loglevel';
import { secrets } from '../config/secrets';
import { capitalize } from '../utils/string';

/**
 * Joyride state.
 *
 * @param {Array} steps is array of steps as objects
 */
export const JoyrideContext = createContext({
  steps: [],
});

/**
 * Joyride provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const JoyrideProvider = ({ children }) => {
  const userValue = useUser();
  const [tour, setTour] = useState();
  const getTourDate = (t) => {
    return window.localStorage.getItem(`tour-${t}`);
  };
  const setTourDate = () => {
    window.localStorage.setItem(`tour-${tour}`, new Date().getTime());
  };
  const steps = () => {
    switch (tour) {
      case Tours.Home:
        if (
          userValue?.user?.accessRoles?.includes(Role.OrgAdmin) &&
          !userValue?.user?.accessRoles?.includes(Role.User)
        ) {
          //Org admin steps
          return [
            {
              title: 'Welcome to AUSTAC!',
              target: '.step-1',
              content: '',
              placement: 'center',
              isFixed: true,
            },
            {
              title: 'Users',
              target: '.step-2',
              content: `This is where you can manage the roles and users of your organisation. Ensure you add users before you create a ${secrets.EVENT_NAME}.`,
              placement: 'right',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: `Create ${capitalize(secrets.EVENT_NAME)}`,
              target: '.step-3',
              content: `After you have added users, new ${secrets.EVENT_NAME}s are created here.`,
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: `Current ${capitalize(secrets.EVENT_NAME)}s`,
              target: '.step-4',
              content: `This section is where you can view all ${secrets.EVENT_NAME}s that you are currently assigned to.`,
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: `Archived ${capitalize(secrets.EVENT_NAME)}s`,
              target: '.step-5',
              content: `Here you can view past ${secrets.EVENT_NAME}s you have participated in that have been archived. Archived ${secrets.EVENT_NAME}s are read-only.`,
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: 'Profile Details / Logout',
              target: '.step-6',
              content:
                'Upload a profile picture, change your callsign or password, or logout.',
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: 'Notifications',
              target: '.step-7',
              content: 'Message alert notifications are found here.',
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: 'Take The Tour',
              target: '.step-8',
              content:
                'Press this button if you would like to take the tour again at any stage.',
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
          ];
        } else {
          //General user steps
          return [
            {
              title: 'Welcome to AUSTAC!',
              target: '.step-1',
              content: '',
              placement: 'center',
              isFixed: true,
            },
            {
              title: `Current ${capitalize(secrets.EVENT_NAME)}s`,
              target: '.step-4',
              content: `This section is where you can view all ${secrets.EVENT_NAME}s that you are currently assigned to.`,
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: `Archived ${capitalize(secrets.EVENT_NAME)}s`,
              target: '.step-5',
              content: `Here you can view past ${secrets.EVENT_NAME}s you have participated in that have been archived. Archived ${secrets.EVENT_NAME}s are read-only.`,
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: 'Profile Details / Logout',
              target: '.step-6',
              content:
                'Upload a profile picture, change your callsign or password, or logout.',
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: 'Notifications',
              target: '.step-7',
              content: 'Message alert notifications are found here.',
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
            {
              title: 'Take The Tour',
              target: '.step-8',
              content:
                'Press this button if you would like to take the tour again at any stage.',
              placement: 'bottom',
              isFixed: true,
              disableBeacon: true,
            },
          ];
        }
      case Tours.CreateMission:
        return [
          {
            title: `Create ${capitalize(secrets.EVENT_NAME)} Tour`,
            target: '.step-1',
            content: '',
            placement: 'center',
            isFixed: true,
          },
          {
            title: 'Planning Phase',
            target: '.step-9',
            content: `Every ${secrets.EVENT_NAME} has three states: planning, active and archived. Active is the only state with location tracking.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: `Create ${capitalize(secrets.EVENT_NAME)} Area`,
            target: '.step-10',
            content: `An ${secrets.EVENT_NAME} must have a boundary before it can be activated.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: `Archive ${capitalize(secrets.EVENT_NAME)}`,
            target: '.step-11',
            content: `Once an ${secrets.EVENT_NAME} has been archived, fields cannot be edited and location tracking has ceased.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: `Activate ${capitalize(secrets.EVENT_NAME)}`,
            target: '.step-12',
            content: `Once activated, the ${secrets.EVENT_NAME} can be deactivated again at any point.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
        ];
      case Tours.Map:
        return [
          {
            title: 'Map Tour',
            target: '.step-1',
            content: '',
            placement: 'center',
            isFixed: true,
          },

          {
            title: `${capitalize(secrets.EVENT_NAME)} Phase`,
            target: '.step-9',
            content: `Every ${secrets.EVENT_NAME} has three phases: planning (yellow), active (green) and archived (grey). Active is the only state with location tracking.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: `${capitalize(secrets.EVENT_NAME)} Area`,
            target: '.step-11',
            content: `An ${secrets.EVENT_NAME} must have a boundary before it can be activated. You can create and edit the ${secrets.EVENT_NAME} boundary from here.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: `${capitalize(secrets.EVENT_NAME)} Controls`,
            target: '.step-10',
            content: `From here you can edit the ${secrets.EVENT_NAME} name and description, activate and deactivate the ${secrets.EVENT_NAME}, and archive the ${secrets.EVENT_NAME}.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Chat Icon',
            target: '.step-14',
            content: 'Highlights recent chats.',
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Geofences',
            target: '.step-12',
            content: 'Create and edit geofenced areas.',
            placement: 'right',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Users and Teams',
            target: '.step-13',
            content: `Manage the users and teams for this ${secrets.EVENT_NAME}.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Create Point of Interest',
            target: '.step-25',
            content: 'Add a waypoint or image to a point on the map.',
            placement: 'right',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Insert MIL-STD-2525 Symbol',
            target: '.step-26',
            content: 'Add a military symbol to a point on the map.',
            placement: 'right',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Create Waypoint',
            target: '.step-20',
            content: 'Add a waypoint marker to a point on the map.',
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Insert Photo',
            target: '.step-21',
            content: 'Attach a photo to a point on the map.',
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: '3D View',
            target: '.step-22',
            content: 'Toggle 3D View on/off.',
            placement: 'right',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Map Styles',
            target: '.step-23',
            content: 'Switch map styles.',
            placement: 'right',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Settings',
            target: '.step-24',
            content: 'Manage your map settings from here.',
            placement: 'right',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Integrations',
            target: '.step-27',
            content: 'Manage your map integrations from here.',
            placement: 'right',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Search',
            target: '.step-15',
            content:
              'Search for addresses, locations, geofences, and all other map markers.',
            placement: 'left',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Roles side bar',
            target: '.step-16',
            content: 'Allows users with a particular role to be highlighted.',
            placement: 'left',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Teams side bar',
            target: '.step-17',
            content: 'Allows a particular team to be highlighted.',
            placement: 'left',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Users side bar',
            target: '.step-18',
            content: `List all users involved with this ${secrets.EVENT_NAME}.`,
            placement: 'left',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Self Marker',
            target: '.step-19',
            content:
              'As a desktop user your marker can be dragged to other locations.',
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
        ];
      case Tours.Users:
        return [
          {
            title: 'Organisation Members Tour',
            target: '.step-1',
            content: '',
            placement: 'center',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Create New User',
            target: '.step-17',
            content: `Administrators can create ${secrets.EVENT_NAME}s. As an administrator, you are able to invite new users to join. You can also select a users access level and role.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
          {
            title: 'Create New Role',
            target: '.step-18',
            content: `Creates a new role. A user can only belong to one role at a time. Users in a role can be highlighted on the ${secrets.EVENT_NAME} map.`,
            placement: 'bottom',
            isFixed: true,
            disableBeacon: true,
          },
        ];
      default:
        break;
    }
  };

  //IMPORTANT: Change this date to the date and time whenever a step is added, removed or modified so that the tour will run again
  const [dateEdited] = useState(Date.parse('2021-11-17T15:00'));
  const [running, setRunning] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [checked, setChecked] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunning(false);
    }
    setSpotlight();
  };

  const JoyrideTooltip = ({
    index,
    step,
    closeProps,
    tooltipProps,
    skipProps,
  }) => (
    <div className="jr-tooltip-container" {...tooltipProps}>
      {step.title && <div className="jr-tooltip-title">{step.title}</div>}
      {index !== 0 ? (
        <div className="jr-tooltip-content">{step.content}</div>
      ) : (
        <div {...closeProps} className="jr-tooltip-content">
          <div
            onClick={() => {
              if (checked) {
                setTourDate();
              }
            }}
          >
            <DotButton className="jr-start-button">
              Start Tour
              <FontAwesomeIcon
                icon={'arrow-right'}
                style={{
                  marginLeft: '1rem',
                  height: '24px',
                  width: '24px',
                }}
              />
            </DotButton>
          </div>
        </div>
      )}
      {index === 0 && (
        <div className="jr-tooltip-footer">
          <label className="jr-checkbox">
            <CheckBox
              value={"Don't help me again"}
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
            />
            <span className="jr-checkbox-label">Don't help me again</span>
          </label>
          <div {...skipProps} className="jr-skip">
            <div
              onClick={() => {
                if (checked) {
                  setTourDate();
                }
              }}
            >
              Skip Tour
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const joyrideComponent = (
    <Joyride
      continuous={true}
      disableScrolling={true}
      hideBackButton={true}
      hideCloseButton={true}
      run={running}
      showSkipButton={false}
      tooltipComponent={JoyrideTooltip}
      steps={steps()}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      styles={{
        options: {
          arrowColor: '#2c7691',
          overlayColor: 'rgba(0, 0, 0, 0.75)',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        },
      }}
    />
  );

  const contextValue = {
    joyrideComponent,
    dateEdited,
    steps,
    tour,
    setTour,
    running,
    setRunning,
    stepIndex,
    setStepIndex,
    checked,
    setChecked,
    getTourDate,
  };

  return (
    <JoyrideContext.Provider value={contextValue}>
      {children}
    </JoyrideContext.Provider>
  );
};

JoyrideProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming Joyride context.
 *
 * @returns JoyrideContext
 */
export const useJoyride = () => {
  return useContext(JoyrideContext);
};

export const setSpotlight = () => {
  log.debug('finding spotlights');
  const spotlights = document.getElementsByClassName(
    'react-joyride__spotlight'
  );
  if (spotlights.length) {
    const spotlight = spotlights[0];
    const left = spotlight.style.left;
    const top = spotlight.style.top;
    const height = spotlight.style.height;
    const width = spotlight.style.width;

    spotlight.addEventListener('webkitAnimationEnd', () => {
      document.documentElement.style.setProperty('--spotlight-left', left);
      document.documentElement.style.setProperty('--spotlight-top', top);
      document.documentElement.style.setProperty('--spotlight-height', height);
      document.documentElement.style.setProperty('--spotlight-width', width);
    });
  }
};
