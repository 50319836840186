/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import i01d from '../../../assets/images/weather-icons/01d.png';
import i01n from '../../../assets/images/weather-icons/01n.png';
import i02d from '../../../assets/images/weather-icons/02d.png';
import i02n from '../../../assets/images/weather-icons/02n.png';
import i03d from '../../../assets/images/weather-icons/03d.png';
import i03n from '../../../assets/images/weather-icons/03n.png';
import i04d from '../../../assets/images/weather-icons/04d.png';
import i04n from '../../../assets/images/weather-icons/04n.png';
import i09d from '../../../assets/images/weather-icons/09d.png';
import i09n from '../../../assets/images/weather-icons/09n.png';
import i10d from '../../../assets/images/weather-icons/10d.png';
import i10n from '../../../assets/images/weather-icons/10n.png';
import i11d from '../../../assets/images/weather-icons/11d.png';
import i11n from '../../../assets/images/weather-icons/11n.png';
import i13d from '../../../assets/images/weather-icons/13d.png';
import i13n from '../../../assets/images/weather-icons/13n.png';
import i50d from '../../../assets/images/weather-icons/50d.png';
import i50n from '../../../assets/images/weather-icons/50n.png';

export const rainColor = (rainVolume, isNight) => {
  var color = '#ffffff';
  if (rainVolume < 10) {
    color = !isNight ? '#ffffff' : 'rgba(242, 244, 250, 1)';
  } else if (rainVolume < 30) {
    color = 'rgba(193, 239, 255, 1)';
  } else if (rainVolume < 50) {
    color = 'rgba(133, 223, 255, 1)';
  } else if (rainVolume < 100) {
    color = 'rgba(65, 206, 255, 1)';
  } else {
    color = 'rgba(0, 189, 255, 1)';
  }
  return color;
};

export const degreeToCompass = (bearing) => {
  var value = Math.floor(bearing / 22.5 + 0.5);
  //prettier-ignore
  const values = [ "N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW" ];
  var index = Math.trunc(value);
  if (index > 15) index = 15;
  return values[index];
};

export const getWeatherIconImage = (icon) => {
  switch (icon) {
    case '01d':
      return i01d;
    case '01n':
      return i01n;
    case '02d':
      return i02d;
    case '02n':
      return i02n;
    case '03d':
      return i03d;
    case '03n':
      return i03n;
    case '04d':
      return i04d;
    case '04n':
      return i04n;
    case '09d':
      return i09d;
    case '09n':
      return i09n;
    case '10d':
      return i10d;
    case '10n':
      return i10n;
    case '11d':
      return i11d;
    case '11n':
      return i11n;
    case '13d':
      return i13d;
    case '13n':
      return i13n;
    case '50d':
      return i50d;
    case '50n':
      return i50n;
    default:
      break;
  }
};
