/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

import { PropTypes } from 'prop-types';
import React from 'react';
import { secrets } from '../../config/secrets';
import './Welcome.scss';

/**
 * Welcome Component
 *
 * @param {Jsx} children child elements
 */
export const Welcome = ({ children }) => {
  return (
    <div className="welcome">
      <div className="columns is-marginless">
        {LoginLeftCol()}
        {LoginRightCol({ children })}
      </div>
    </div>
  );
};

Welcome.propTypes = {
  children: PropTypes.any,
};

/**
 * Left side
 */
const LoginLeftCol = () => {
  return (
    <div className="column col-1 is-paddingless">
      {secrets.IS_PRODUCTION ? (
        <div className="text">
          Welcome to AUSTAC
          <p>
            You should have been given a username and password with which to log
            in to the application. Contact your admin if you have not yet been
            supplied with these details.
          </p>
        </div>
      ) : (
        <div className="text">
          Welcome to AUSTAC.
          <p>
            You should have been issued with a username and password for the
            application. Please contact your organisation’s administrator if you
            have not been provided with these details.
          </p>
        </div>
      )}
    </div>
  );
};

/**
 * Right side
 *
 * @param {Jsx} children child elements
 */
const LoginRightCol = ({ children }) => {
  return (
    <div className="column is-centered col-2 no-gaps is-paddingless">
      {children}
    </div>
  );
};

LoginRightCol.propTypes = {
  children: PropTypes.any,
};
