/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { PropTypes } from 'prop-types';

export const historyType = PropTypes.shape({
  push: PropTypes.func.isRequired,
});

export const privacyLevelType = PropTypes.oneOf(['none', 'partial', 'full']);
export const sharingType = PropTypes.shape({
  userFullname: PropTypes.bool,
  userEmail: PropTypes.bool,
  userCallSign: PropTypes.bool,
  userPhotoUrl: PropTypes.bool,
  orgDescription: PropTypes.bool,
  orgName: PropTypes.bool,
  orgGroupName: PropTypes.bool,
  orgGroupDescription: PropTypes.bool,
  orgGroupLocation: PropTypes.bool,
  userRole: PropTypes.bool,
  userStatus: PropTypes.bool,
  userOrders: PropTypes.bool,
  userTasks: PropTypes.bool,
  userTrackHistory: PropTypes.bool,
  incomingCalls: PropTypes.bool,
  incomingComms: PropTypes.bool,
  incomingFileTransfers: PropTypes.bool,
});

export const organisationType = PropTypes.shape({
  id: PropTypes.string,
  organisationName: PropTypes.string,
  organisationDescription: PropTypes.string,
  address: PropTypes.string,
  photoUrl: PropTypes.string,
  color: PropTypes.string,
  privacy: privacyLevelType,
});

export const missionType = PropTypes.shape({
  id: PropTypes.string,
  missionName: PropTypes.string,
  description: PropTypes.string,
  locationDescription: PropTypes.string,
  organisationId: PropTypes.string,
});

export const missionAreaType = PropTypes.shape({
  coordinates: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
  ),
  type: PropTypes.string,
});

export const routeType = PropTypes.shape({
  state: PropTypes.any,
});

export const locationType = PropTypes.shape({
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zoom: PropTypes.number,
  radius: PropTypes.number,
});

export const styleType = PropTypes.shape({
  icon: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
});

export const groupType = PropTypes.shape({
  unitId: PropTypes.string,
  address: PropTypes.string,
  users: PropTypes.number,
  color: PropTypes.string,
});

export const roleType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.string,
]);
export const orgRoleType = PropTypes.shape({
  id: PropTypes.string,
  role: PropTypes.string,
  color: PropTypes.string,
});
export const teamType = PropTypes.shape({
  id: PropTypes.string,
  team: PropTypes.string,
  color: PropTypes.string,
});

export const userType = PropTypes.shape({
  id: PropTypes.string,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  callSign: PropTypes.string,
  email: PropTypes.string,
  organisationRole: PropTypes.string,
  organisationId: PropTypes.string,
  photoUrl: PropTypes.string,
  userGroupId: PropTypes.string,
  accessRoles: PropTypes.arrayOf(roleType),
});

export const personnelType = PropTypes.shape({
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  photoUrl: PropTypes.string,
  color: PropTypes.string,
});

export const taggableType = PropTypes.oneOfType([groupType, personnelType]);

export const taskType = PropTypes.shape({
  assigned: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  description: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string,
  missionId: PropTypes.string,
  key: PropTypes.string,
  name: PropTypes.string,
  timespanType: PropTypes.oneOf(['deadline', 'timeframe', 'none']),
  start: PropTypes.string,
  end: PropTypes.string,
  _ts: PropTypes.number,
});

export const sectionType = PropTypes.shape({
  description: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string,
  missionId: PropTypes.string,
  name: PropTypes.string,
  tasks: PropTypes.arrayOf(taskType),
  userId: PropTypes.string,
  _ts: PropTypes.number,
});

export const ordersType = PropTypes.shape({
  sections: PropTypes.arrayOf(sectionType),
});

export const poiType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
  missionId: PropTypes.string,
  name: PropTypes.string,
  reference: PropTypes.string,
  meta: PropTypes.object,
  location: locationType,
  type: PropTypes.oneOf(['photo', 'waypoint']),
  userId: PropTypes.string,
  _ts: PropTypes.number,
});

export const integrationType = PropTypes.shape({
  code: PropTypes.string,
  description: PropTypes.string,
  photoUrl: PropTypes.string,
});
