/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';

// add the minMax plugin to dayJs
dayjs.extend(minMax);

/**
 * Get the number of unread messages.
 *
 * @param {*} missionId mission id
 * @param {*} threads threads from CommsContext
 * @param {*} threadMessages messages from CommsContext
 * @return {number} number of unread messages
 */
export const getUnreadMessages = (userId, missionId, threads) => {
  let unread = 0;
  const keys = Object.keys(threads);
  for (const key of keys) {
    const thread = threads[key];
    if (thread.missionId === missionId) {
      const threadRead = thread.readReceipts.includes(userId);
      if (!threadRead && thread.lastMessage?.timestamp) {
        unread++;
      }
    }
  }
  return unread;
};

/**
 * Get the number of unread messages in mission broadcast thread only.
 *
 * @param {*} missionId mission id
 * @param {*} threads threads from CommsContext
 * @param {*} threadMessages messages from CommsContext
 * @return {number} number of unread messages
 */
export const getUnreadBroadcast = (userId, missionId, threads) => {
  let unread = 0;
  const keys = Object.keys(threads);
  for (const key of keys) {
    const thread = threads[key];
    if (thread.missionId === missionId && thread.missionWide) {
      const threadRead = thread.readReceipts.includes(userId);
      if (!threadRead && thread.lastMessage?.timestamp) {
        unread++;
      }
    }
  }
  return unread;
};

/**
 * Get the number of unread messages in specific thread only.
 *
 * @param {*} missionId mission id
 * @param {*} threadId thread id
 * @param {*} threads threads from CommsContext
 * @param {*} threadMessages messages from CommsContext
 * @return {number} number of unread messages
 */
export const getUnreadThread = (
  userId,
  missionId,
  threadId,
  threads,
  threadMessages
) => {
  let unread = 0;
  const thread = threads[threadId];
  if (thread && thread.missionId === missionId) {
    const threadRead = thread.readReceipts.includes(userId);
    if (!threadRead && thread.lastMessage?.timestamp) {
      unread = -1;
    }
  }
  return unread;
};
