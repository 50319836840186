/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { fileConfig } from '../../../config/fileConfig';
import { validateFile } from '../../../utils/files';
import { compressImage, getImageDimensions } from '../../../utils/image';
import * as log from 'loglevel';
import './DotButton.scss';
import { getClassNames } from '../../../utils/string';
import { PropTypes } from 'prop-types';
import { isMobile } from '../../../utils/device';

/**
 * Button. Goes into a loading state when clicked.
 *
 * @param {Jsx} children child elements
 * @param {string} className additional className to add to HTML button element
 * @param {style} style additional styles to add to HTML button element
 * @param {EventHandler} onClick on click event handler (Promise)
 * @param {EventHandler} onLongPress on long press event handler (Promise)
 * @param {EventHandler} onMouseDown on mouse down event handler - to handle touch events (Promise)
 * @param {EventHandler} onMouseOver on mouse over event handler - to handle hover events
 * @param {EventHandler} onMouseOut on mouse out event handler - to handle hover events
 * @param {'' || 'submit'} type HTML button type
 * @param {boolean} disabled is button disabled
 * @param {string} tip tooltip
 * @param {string} place tooltip position
 * @param {boolean} file file upload
 * @param {boolean} multiple select multiple files
 * @param {boolean} accept accept files of type
 * @param {string} badge message counts etc.
 * @param {boolean} noSpin flag to remove spinner on loading
 */
export const DotButton = ({
  children,
  className,
  style,
  onClick,
  onMouseDown,
  type = '',
  disabled = false,
  tip = '',
  place = '',
  file = false,
  multiple = false,
  postProcess = true,
  accept = '',
  onMouseOver,
  onMouseOut,
  badge,
  ariaLabel,
  onLongPress,
  noSpin = false,
}) => {
  const thisClassName = getClassNames(
    {
      'dot-button': true,
      badge: !!badge,
    },
    className
  );

  const id = useRef(uuid());
  const buttonRef = useRef();
  const fileRef = useRef();
  const width = useRef();

  const imgPreview = useRef();

  const ac = new AbortController();

  const [loading, setLoading] = useState(false);

  const thisOnClick = async (e) => {
    if (loading || disabled) {
      return;
    }

    if (typeof onClick === 'function') {
      e.preventDefault();
      e.stopPropagation();
    }

    if (file) {
      const files = [];

      const rs = await getFiles();
      if (rs.length) {
        setLoading(true);
        for (const rsFile of rs) {
          const name = rsFile.name;
          const size = rsFile.size;
          const type = rsFile.type;

          if (postProcess) {
            const url = await readFile(rsFile);

            if (accept === 'image/*') {
              if (!RegExp(accept).test(type)) {
                e.error = 'Invalid file type';
                await onClick(e);
                return;
              } else {
                // process image
                imgPreview.current.src = url;
                const { height, width } = await getImageDimensions(
                  imgPreview.current
                );

                //if we resize by width, this is the max width of compressed image
                const MAX_SIZE = fileConfig.maxImageWidth;

                const widthRatio = MAX_SIZE / width;
                const heightRatio = MAX_SIZE / height;
                let minRatio = Math.min(widthRatio, heightRatio, 1);

                const compressed = await compressImage(
                  imgPreview.current,
                  minRatio,
                  width,
                  height,
                  fileConfig.quality
                );

                if (compressed.blob) {
                  compressed.error = validateFile({
                    size: compressed.blob.size,
                    name: file.name,
                  });
                }

                URL.revokeObjectURL(imgPreview.current);

                files.push({
                  compressed,
                  name,
                  size,
                  type,
                });
              }
            } else {
              files.push({
                url,
                name,
                size,
                type,
              });
            }
          } else {
            files.push({
              blob: rsFile,
              name,
              size,
              type,
            });
          }
        }
      }

      await onClick(e, files).finally(() => {
        setLoading(false);
      });
    } else {
      try {
        if (typeof onClick === 'function') {
          setLoading(true);
          await onClick(e).finally(() => {
            setLoading(false);
          });
        }
      } catch {}

      if (!ac.signal.aborted) {
        setLoading(false);
      }
    }
  };

  const thisOnMouseDown = async (e) => {
    if (loading || disabled) {
      return;
    }

    if (typeof onMouseDown === 'function') {
      if (file) {
        const rs = await getFiles();
        if (rs.length) {
          const url = await readFile(rs[0]);
          const name = rs[0].name;
          const size = rs[0].size;
          const type = rs[0].type;

          await onMouseDown(e, {
            url,
            name,
            size,
            type,
          });
        }
      } else {
        await onMouseDown(e);
      }
    }
  };

  useEffect(() => {
    width.current = buttonRef.current.offsetWidth;
    return () => {
      ac.abort();
    };
    // eslint-disable-next-line
  }, []);

  const getFiles = async () => {
    return new Promise((resolve, reject) => {
      fileRef.current.onchange = (e) => {
        log.debug('onchange', e);
        const files = [...e.target.files];
        e.target.value = '';
        resolve(files);
      };
      fileRef.current.click();
    });
  };

  const readFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <button
        ref={buttonRef}
        className={getClassNames(
          { disabled: loading || disabled },
          thisClassName
        )}
        onClick={thisOnClick}
        onContextMenu={(e) => {
          if (typeof onLongPress === 'function') {
            e.preventDefault();
            e.stopPropagation();
            onLongPress();
          }
          return false;
        }}
        onMouseDown={thisOnMouseDown}
        type={type}
        data-tip={tip}
        data-place={place}
        data-for={id.current}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        style={
          loading
            ? {
                ...style,
                width: width.current ? width.current : 'unset',
              }
            : style
        }
        aria-label={ariaLabel || tip}
      >
        {loading && !noSpin ? (
          <div className="loading">
            <FontAwesomeIcon icon="sync-alt" spin />
          </div>
        ) : (
          children
        )}
        {!!badge && (
          <div className="badge">{badge < 0 ? <>&nbsp;</> : badge}</div>
        )}
      </button>
      {file && (
        <input
          className="dot-button-file"
          ref={fileRef}
          type="file"
          accept={accept}
          multiple={multiple}
        />
      )}
      {!!tip && (
        <ReactTooltip id={id.current} disable={isMobile()}></ReactTooltip>
      )}
      <img ref={imgPreview} alt="preview" style={{ display: 'none' }} />
    </>
  );
};

DotButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  type: PropTypes.oneOf(['submit']),
  disabled: PropTypes.bool,
  tip: PropTypes.string,
  place: PropTypes.string,
  file: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ariaLabel: PropTypes.string,
};
