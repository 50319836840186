/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClassNames } from '../../../utils/string';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { formatDate } from '../../../utils/time';
import './MissionIntegrations.scss';
import { Expandable } from '../../common/Expandable';
import { MilspecOptions } from '../../common/MilspecOptions';
import ms from 'milsymbol';
import { latLngToMGRS } from '../../../utils/string';

/**
 * Map Sidebar - show more information about an integration marker.
 * Support varies from integration to integration.
 *
 * Implemented in IntegrationImplementation.[INTEGRATION].sidebar
 *
 * @param {any} title sidebar title
 * @param {string} titleStyle additional style to apply to title
 * @param {any} battery battery info - IoT devices may broadcast battery data
 * @param {Jsx} subHeader subheader content - can be any Jsx
 * @param {string} topClassName additional styles to add to header
 * @param {string} contentClassName additional styles to add to content
 * @param {Jsx} children child elements - content
 * @param {any} meta meta content
 * @param {any} raw raw content
 * @param {any} milspec pre existing milspec symbol and options
 * @param {any} formData data for the dropdown lists
 * @param {any} symbolSet symbol set for the given code
 * @param {string} symbolSetId id for the symbol set
 * @param {any} echelonFormData echelon form data specific to the symbol set
 * @param {any} mod1FormData icon modifier 1 form data specific to the symbol set
 * @param {any} mod2FormData icon modifier 2 form data specific to the symbol set
 * @param {string} id id of the marker
 * @param {boolean} showMGRS show mgrs instead of lat/lng
 * @param {function} refreshIntegrations function to redraw symbols
 */
export const IntegrationMeta = ({
  title,
  titleStyle,
  battery,
  subHeader,
  topClassName,
  contentClassName,
  children,
  meta,
  raw,
  milspec,
  formData,
  symbolSet,
  symbolSetId,
  echelonFormData,
  mod1FormData,
  mod2FormData,
  id,
  showMGRS,
  refreshIntegrations,
}) => {
  const [symbolSvg, setSymbolSvg] = useState();
  const [mgrs, setMGRS] = useState();

  const onChangeSymbol = (symbolCode, options) => {
    if (showMGRS) {
      const latUnformatted = options?.location?.split(',')[0];
      const lngUnformatted = options?.location?.split(',')[1];
      const lat = latUnformatted?.substring(0, latUnformatted?.length - 1);
      const lng = lngUnformatted?.substring(1, lngUnformatted?.length - 1);
      if (!!lat && !!lng) {
        options.location = latLngToMGRS(+lat, +lng);
        setMGRS(latLngToMGRS(+lat, +lng));
      }
    }
    options.size = 45;
    setSymbolSvg(new ms.Symbol(symbolCode, options).asCanvas().toDataURL());
  };

  useEffect(() => {
    if (showMGRS) {
      const latUnformatted = milspec?.options?.location?.split(',')[0];
      const lngUnformatted = milspec?.options?.location?.split(',')[1];
      const lat = latUnformatted?.substring(0, latUnformatted?.length - 1);
      const lng = lngUnformatted?.substring(1, lngUnformatted?.length - 1);
      if (!!lat && !!lng) {
        milspec.options.location = latLngToMGRS(+lat, +lng) + ' ';
        setMGRS(' ' + latLngToMGRS(+lat, +lng));
      }
      milspec.options.speed = ' ' + milspec?.options?.speed;
    }
    milspec.options.size = 45;
    const symbol = new ms.Symbol(milspec.symbol, milspec.options)
      .asCanvas()
      .toDataURL();
    setSymbolSvg(symbol);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mission-integrations meta shadow">
      <div
        className={getClassNames(
          {
            top: true,
          },
          topClassName
        )}
      >
        <div className="heading-alt">
          <div>
            <DotButton
              className="exit"
              onClick={() => {
                if (document.sidebars) {
                  document.sidebars.closeAll();
                }
              }}
              ariaLabel="Close"
            >
              <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
            </DotButton>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {(raw.FLAG_URL || raw.flagUrl) && (
              <img
                src={raw.FLAG_URL ? raw.FLAG_URL : raw.flagUrl}
                style={{ height: '65px', paddingRight: '20px' }}
                alt="Country of Origin"
              />
            )}
            {title}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'white',
        }}
        className={getClassNames(
          {
            top: true,
          },
          topClassName
        )}
      >
        <div
          style={{
            width: symbolSetId === '01' ? '130px' : '100%',
            marginBottom:
              symbolSetId === '01' && !!milspec?.options?.direction
                ? '-50px'
                : '',
          }}
        >
          <img src={symbolSvg} style={{ minWidth: '100%' }} alt={'Symbol'} />
        </div>
      </div>
      <LightScrollbar
        className={getClassNames(
          {
            content: true,
          },
          contentClassName
        )}
      >
        <div>
          <MilspecOptions
            milspec={milspec}
            formData={formData}
            symbolSet={symbolSet}
            symbolSetId={symbolSetId}
            echelonFormData={echelonFormData}
            mod1FormData={mod1FormData}
            mod2FormData={mod2FormData}
            id={id}
            onChangeSymbol={onChangeSymbol}
            refreshIntegrations={refreshIntegrations}
          />
        </div>
        {!!meta.length &&
          meta.map((m, idx) => {
            return (
              <div>
                <Expandable
                  style={{ paddingBottom: '10px' }}
                  key={idx}
                  title={m.title}
                  children={
                    <>
                      {m.items.map((i, idx) => {
                        if (idx > 0) {
                          return (
                            <div
                              key={idx}
                              className={getClassNames({
                                'list-item': true,
                                'no-border': true,
                                'no-padding': true,
                              })}
                              style={{
                                marginBottom: '16px',
                              }}
                            >
                              <h5>
                                {(i.name === 'Latitude/Longitude' ||
                                  i.name === 'Pos.') &&
                                showMGRS &&
                                mgrs
                                  ? 'MGRS'
                                  : i.name}
                                :
                              </h5>
                              <div
                                style={{
                                  textAlign: 'end',
                                  flexDirection: 'column',
                                }}
                              >
                                {i.name === 'Last Seen' && i.value ? (
                                  formatDate(new Date(i.value))
                                ) : (
                                  <>
                                    {(i.name === 'Latitude/Longitude' ||
                                      i.name === 'Pos.') &&
                                    showMGRS &&
                                    mgrs ? (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: mgrs,
                                        }}
                                      />
                                    ) : (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: i.value,
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                                {i.sub ? (
                                  <div style={{ fontSize: '0.8em' }}>
                                    {i.sub}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  }
                />
              </div>
            );
          })}
      </LightScrollbar>
    </div>
  );
};
