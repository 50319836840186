/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import './VoiceCall.scss';
import { DotButton } from './buttons/DotButton';
import { Avatar } from './Avatar';
import { displayName, getClassNames } from '../../utils/string';
import { PropTypes } from 'prop-types';
import { useComms } from '../../context/CommsContext';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { CallState } from '../../enums/call';
import svgPhoneHangUp from '../../assets/svg/phone.svg';
dayjs.extend(duration);

/**
 * Voice call display that shows when in a call. Floating in the UI.
 *
 * @param {string} entity user this call is with
 * @param {any} incoming is this an incoming call?
 * @param {any} remoteMuted is the remote party muted
 * @param {any} talking is the remote party talking
 */
export const VoiceCall = ({
  entity,
  incoming = null,
  remoteMuted = false,
  talking = false,
}) => {
  const comms = useComms();

  const timeout = useRef();

  const tick = () => {
    comms.setTime((prev) => prev + 1000);
    timeout.current = setTimeout(tick, 1000);
  };

  useEffect(() => {
    tick();
    return () => {
      clearTimeout(timeout.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (comms.callState === CallState.Connected) {
      comms.setTime(0);
    }
    // eslint-disable-next-line
  }, [comms.callState]);

  const CallIcon = ({ dialing, disconnecting }) => {
    return !disconnecting ? (
      <div className="call-button child first">
        <div className="call-button child second">
          <div
            className={getClassNames({
              dialing: dialing,
              talking: !dialing,
              'call-button': true,
              child: true,
              third: true,
            })}
          >
            <FontAwesomeIcon icon="phone-flip" />
          </div>
        </div>
      </div>
    ) : (
      <div className="call-button child transparent">
        <div className="call-button child end">
          <img src={svgPhoneHangUp} alt="phone" />
        </div>
      </div>
    );
  };

  return (
    <div className={getClassNames({ 'voice-call': true })}>
      <div
        className={getClassNames({
          toolbar: !incoming,
          'parent-container': true,
        })}
      >
        {!!!incoming && (
          <>
            <div className="left">
              {comms.callState === CallState.Connecting ||
              comms.callState === CallState.Ringing ? (
                <>
                  <CallIcon dialing={true} disconnecting={false} />
                  <div className="time right-border">Calling...</div>
                </>
              ) : (
                <>
                  {comms.callState === CallState.Disconnecting ? (
                    <CallIcon dialing={false} disconnecting={true} />
                  ) : (
                    <CallIcon dialing={false} disconnecting={false} />
                  )}
                  {comms.callState === CallState.Connected && (
                    <div className="time right-border">
                      {dayjs.duration(comms.time).format('HH:mm:ss')}
                    </div>
                  )}
                </>
              )}
              <DotButton
                style={{
                  background: 'none',
                }}
                onClick={() => {
                  comms.openUserDetails(comms.callUser);
                }}
              >
                <Avatar entity={entity} size="2rem"></Avatar>
              </DotButton>
              <span>{displayName(entity)}</span>
            </div>
            <div className="right">
              {comms.isMap && comms.callUser?.coords && (
                <>
                  <DotButton
                    tip="Centre on user"
                    ariaLabel={'Centre on user'}
                    className="circle-button"
                    style={{
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      comms.centreMapOnPersonnel(comms.callUser);
                    }}
                  >
                    <FontAwesomeIcon icon="location-dot" />
                  </DotButton>
                  <div className="border-right">
                    <DotButton
                      tip="Open Comms"
                      ariaLabel={'Open Comms'}
                      className="circle-button"
                      style={{
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        comms.openUserDetails(comms.callUser);
                      }}
                    >
                      <FontAwesomeIcon icon="message" />
                    </DotButton>
                  </div>
                </>
              )}

              {comms.muted ? (
                <DotButton
                  tip="Unmute"
                  ariaLabel={'Unmute'}
                  className="circle-button mute-button"
                  style={{
                    fontWeight: 600,
                  }}
                  onClick={comms.unmute}
                >
                  <FontAwesomeIcon icon="microphone-slash" />
                </DotButton>
              ) : (
                <>
                  <DotButton
                    tip="Mute"
                    ariaLabel={'Mute'}
                    className="circle-button mute-button"
                    style={{
                      fontWeight: 600,
                    }}
                    onClick={comms.mute}
                  >
                    <FontAwesomeIcon icon="microphone" />
                  </DotButton>
                </>
              )}
              {comms.callState === CallState.Disconnecting ? (
                <DotButton className="end-call ended">
                  CALL ENDED
                  <img src={svgPhoneHangUp} alt="phone" />
                </DotButton>
              ) : (
                <DotButton
                  tip="End Call"
                  ariaLabel={'End Call'}
                  onClick={comms.hangUp}
                  className="end-call"
                >
                  END CALL
                  <img src={svgPhoneHangUp} alt="phone" />
                </DotButton>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

VoiceCall.propTypes = {
  entity: PropTypes.any,
  incoming: PropTypes.any,
  remoteMuted: PropTypes.bool,
  talking: PropTypes.bool,
};
