/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Constants, fixPolygon, isEventAtCoordinates } from './draw_utils';

const DrawConcave = MapboxDraw.modes.draw_polygon;

DrawConcave.clickAnywhere = function (state, e) {
  if (
    state.currentVertexPosition > 0 &&
    isEventAtCoordinates(
      e,
      state.polygon.coordinates[0][state.currentVertexPosition - 1]
    )
  ) {
    return this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
  }
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat
  );
  state.currentVertexPosition = fixPolygon(state.polygon);
  state.currentVertexPosition++;
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat
  );
};

DrawConcave.onTrash = function (state) {
  if (state.currentVertexPosition <= 1) {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  } else {
    const ring = state.polygon.coordinates[0];
    if (ring) {
      state.polygon.changed();
      state.currentVertexPosition -= 1;
      ring.splice(state.currentVertexPosition, 1);
    }
  }
};

export default DrawConcave;
