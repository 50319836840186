/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { displayName, getInitials } from '../../utils/string';
import './Avatar.scss';
import { PropTypes } from 'prop-types';
import { isMobile } from '../../utils/device';

/**
 * Avatar icon that can represent an organisation, group, or personnel.
 *
 * @param {any} entity The org, group, or personnel
 * @param {string} photoUrl override the photo shown
 * @param {string[]} fallback override the fallback (initials) shown
 * @param {string} size the size of the icon
 * @param {string} tip tooltip
 * @param {string} color override the color of the icon
 * @param {string} text override the text in the icon
 * @param {string} icon override the text in the icon with an icon
 */
export const Avatar = ({
  entity,
  photoUrl,
  fallback,
  size = '3.5rem',
  tip = '',
  color = '',
  text = '',
  icon = '',
}) => {
  const id = useRef(uuid());

  let _photoUrl = null;
  if (entity?.threadPhotoUrl) {
    _photoUrl = entity?.threadPhotoUrl;
  }
  if (entity?.photoUrl) {
    _photoUrl = entity?.photoUrl;
  }
  if (photoUrl) {
    _photoUrl = photoUrl;
  }
  let _fallback = fallback;
  if (!fallback) {
    _fallback = [displayName(entity)];
  }

  let _color = color;
  if (entity?.color) {
    _color = entity.color;
  }
  if (entity?.role?.color) {
    _color = entity.role.color;
  }
  if (entity?.teamsFull) {
    _color = entity.teamsFull[0]?.color;
  }
  if (entity?.organisation?.color) {
    _color = entity.organisation.color;
  }

  const name = _fallback?.join(' ') ?? '';

  let _tip = '';
  if (tip) {
    _tip = tip;
  }

  return (
    <>
      <div
        data-tip={_tip}
        data-for={id.current}
        className="dot-avatar"
        style={{
          userSelect: 'none',
          fontSize: `${size}`,
          width: `${size}`,
          height: `${size}`,
        }}
      >
        {_photoUrl ? (
          <img
            style={{
              width: `${size}`,
              height: `${size}`,
              border: '2px solid white',
            }}
            className="avatar-img"
            src={_photoUrl}
            alt={name}
          />
        ) : (
          <div
            className="avatar"
            style={{
              width: `${size}`,
              height: `${size}`,
              border: '2px solid white',
              backgroundColor: _color ? _color : 'var(--colour-interactions-a)',
              color: `var(--colour-white)`,
            }}
          >
            {!!text ? text : getInitials(name)}
            {!!icon && <FontAwesomeIcon icon={icon} />}
          </div>
        )}
        {!!entity?.organisation && size > '40px' && (
          <Avatar entity={entity.organisation} size="0.42em"></Avatar>
        )}
      </div>
      {!!_tip && (
        <ReactTooltip id={id.current} disable={isMobile()}></ReactTooltip>
      )}
    </>
  );
};

Avatar.propTypes = {
  entity: PropTypes.any,
  photoUrl: PropTypes.string,
  fallback: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
  tip: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
};
