/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { getMissionPersonnel } from '../../../api/orgs_users';
import { useUser } from '../../../context/UserContext';
import { locationType } from '../../../enums/propTypes';
import { toLngLat } from '../../../utils/geo';
import { cancellablePromise } from '../../../utils/promise';
import { displayName } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import './MissionSidebar.scss';
import { PropTypes } from 'prop-types';
import { useSidebar } from '../../../context/SidebarContext';
import { useEvent } from '../../../context/EventContext';
import { Signal } from '../../../enums/signal';
import badge from '../../../assets/svg/dot-badge.svg';
import { MilspecOptions } from '../../common/MilspecOptions';
import ms from 'milsymbol';
import { SymbolPreview } from '../../common/SymbolPreview';
import moment from 'moment';

/**
 * Sidebar - show symbol info
 *
 * @param {Function} closeSidebar callback to close sidebar
 * @param {object} milspec milspec data
 * @param {object} formData data for the dropdown lists
 * @param {object} symbolSet symbol set for the given code
 * @param {string} symbolSetId symbol set id
 * @param {object} echelonFormData echelon form data specific to symbol set
 * @param {any} mod1FormData icon modifier 1 form data specific to symbol set
 * @param {any} mod2FormData icon modifier 2 form data specific to symbol set
 * @param {string} missionId mission id
 * @param {any} symbol symbol data
 * @param {any} location location data
 * @param {Boolean} isOwner is owner of this symbol
 * @param {Function} setUnit opens personnel sidebar
 * @param {Function} refreshSymbols refreshes the symbols
 * @param {Function} setSymbolToDelete delete symbol - opens modal
 * @param {Function} setMilspec callback to set milspec
 * @param {Function} setLocation callback to set location
 */
export const MissionSymbol = ({
  closeSidebar,
  milspec,
  formData,
  symbolSet,
  symbolSetId,
  echelonFormData,
  mod1FormData,
  mod2FormData,
  missionId,
  symbol,
  location,
  isOwner,
  setUnit,
  refreshSymbols,
  setSymbolToDelete,
  setMilspec,
  setLocation,
}) => {
  const userValue = useUser();

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const sidebars = useSidebar();
  const [symbolSvg, setSymbolSvg] = useState();
  const [symbolLocation, setSymbolLocation] = useState(location);
  const [symbolId, setSymbolId] = useState();

  const onChangeSymbol = async (symbolCode, options) => {
    options.size = 45;
    //Object restructuring for use in Symbol.
    let engagementBar =
      options.engagementState +
      options.remoteAndLocalEngagements +
      options.weaponAssignmentOrDeployment;
    options.engagementBar = engagementBar;
    if (options.dtg.length) {
      options.dtg = moment(options.dtg).format('DDHHmmSSZMMMYYYY');
    }

    const newSymbol = new ms.Symbol(symbolCode, options).asCanvas().toDataURL();

    setMilspec({
      options: options,
      symbol: symbolCode,
      engagement: {
        engagementState: options.engagementState,
        remoteAndLocalEngagements: options.remoteAndLocalEngagements,
        weaponAssignmentOrDeployment: options.weaponAssignmentOrDeployment,
      },
    });
    const lat = options.location.split(',')[0];
    const lng = options.location.split(',')[1].trim();
    setLocation({ lat: +lat, lng: +lng });
    setSymbolSvg(newSymbol);
  };

  useEffect(() => {
    setSymbolLocation(location);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (milspec && milspec.options) {
      //Update symbol preview.
      const newOptions = milspec.options;

      newOptions.size = 45;
      newOptions.engagementBar =
        milspec.engagement?.engagementState +
          milspec.engagement?.remoteAndLocalEngagements +
          milspec.engagement?.weaponAssignmentOrDeployment || '';
      newOptions.location =
        `${symbolLocation?.lat?.toFixed(5)}, ${symbolLocation?.lng?.toFixed(
          5
        )}` || '';
      newOptions.evaluationRating = newOptions.evaluationRating || 'A1';
      newOptions.combatEffectiveness = newOptions.combatEffectiveness || 'FO';

      if (milspec?.options?.dtg?.includes('-')) {
        newOptions.dtg = moment(milspec.options.dtg).format('DDHHmmSSZMMMYYYY');
      }

      const symbol = new ms.Symbol(milspec.symbol, newOptions)
        .asCanvas()
        .toDataURL();
      setSymbolSvg(symbol);
    }
    // eslint-disable-next-line
  }, [milspec, symbolLocation]);

  useEffect(() => {
    setLoading(true);
    const stub = async () => {
      if (symbol) {
        const _user = await getMissionPersonnel(missionId, symbol.userId);
        setUser(_user);
      } else if (!symbol) {
        setUser(userValue);
      }
      setLoading(false);
    };

    log.debug('symbol', symbol);

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [symbol, location]);

  const exit = () => {
    closeSidebar();
  };

  const getSymbolName = (symbolSetId) => {
    switch (symbolSetId) {
      case '01':
        return 'Air Symbol';
      case '30':
        return 'Sea Surface Symbol';
      case '10':
        return 'Land Unit Symbol';
      case '15':
        return 'Land Equipment Symbol';
      case '20':
        return 'Land Installation Symbol';
      default:
        return 'Symbol';
    }
  };

  let symbolName = getSymbolName(symbolSetId);
  let title = `Insert ${symbolName}`;
  if (symbol) {
    if (!isOwner) {
      title = `${symbolName} Details`;
    } else {
      title = `Edit ${symbolName}`;
    }
  }

  useEvent(Signal.SymbolUpdate, (sym) => {
    if (sym.id === symbol.id) {
      setSymbolLocation(toLngLat(sym.location));
    }
  });

  return (
    <>
      {(loading || !user) && (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              animation: 'rotation 2s infinite linear',
              marginTop: '150px',
            }}
          >
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Loading details</div>
        </div>
      )}

      <div className="mission-sidebar">
        <div className="top">
          <div className="heading">
            <div className="text">{title}</div>
            <DotButton className="exit" onClick={exit} ariaLabel="Close">
              <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
            </DotButton>
          </div>
          {!!sidebars.previous && (
            <DotButton
              className="back"
              style={{ marginRight: '15px' }}
              onClick={() => {
                sidebars.open(sidebars.previous);
              }}
              ariaLabel="Back"
            >
              <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
            </DotButton>
          )}
        </div>
        <div className="content">
          <div className="blocks">
            {user?.user && (
              <div className="group">
                <h5>Last Modified By</h5>
                <div
                  className="item-container"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (symbol) {
                      setUnit(user?.user);
                    }
                  }}
                >
                  <Avatar entity={user?.user} />
                  <div style={{ flexGrow: 1 }}>
                    <h4>{displayName(user?.user)}</h4>
                    <p>{user?.role?.role}</p>
                  </div>
                  {!!symbol && (
                    <DotButton>
                      <FontAwesomeIcon icon="chevron-right" />
                    </DotButton>
                  )}
                </div>
                <p>
                  {(symbol?._ts ? dayjs(symbol._ts * 1000) : dayjs())
                    .format('h:mmA, D MMM YYYY')
                    .toUpperCase()}
                </p>
              </div>
            )}

            <SymbolPreview symbolSvg={symbolSvg} />
          </div>
        </div>
        <LightScrollbar className="content">
          {
            <>
              {!!milspec?.options &&
                !!milspec?.options?.location &&
                !!formData && (
                  <MilspecOptions
                    milspec={milspec}
                    formData={formData}
                    symbolSet={symbolSet}
                    symbolSetId={symbolSetId}
                    echelonFormData={echelonFormData}
                    mod1FormData={mod1FormData}
                    mod2FormData={mod2FormData}
                    insertedSymbol={true}
                    onChangeSymbol={onChangeSymbol}
                    refreshSymbols={refreshSymbols}
                    symbol={symbol}
                    setId={setSymbolId}
                  />
                )}
            </>
          }
        </LightScrollbar>
        {(!!symbol || symbolId) && (
          <div className="foot">
            <DotButton
              className="secondary block"
              onClick={async () => {
                if (symbol) {
                  setSymbolToDelete(symbol);
                } else {
                  const symbolToDelete = {
                    id: symbolId,
                  };
                  setSymbolToDelete(symbolToDelete);
                }
              }}
            >
              {`Delete ${getSymbolName(symbolSetId)}`}
            </DotButton>
          </div>
        )}
      </div>
    </>
  );
};

MissionSymbol.propTypes = {
  closeSidebar: PropTypes.func,
  milspec: PropTypes.object,
  formData: PropTypes.object,
  symbolSet: PropTypes.object,
  symbolSetId: PropTypes.string,
  echelonFormData: PropTypes.object,
  mod1FormData: PropTypes.any,
  mod2FormData: PropTypes.any,
  missionId: PropTypes.string,
  symbol: PropTypes.object,
  location: locationType,
  isOwner: PropTypes.bool,
  setUnit: PropTypes.func,
  refreshSymbols: PropTypes.func,
  setSymbolToDelete: PropTypes.func,
  setMilspec: PropTypes.func,
  setLocation: PropTypes.func,
};
