/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PropTypes } from 'prop-types';
import { ShapeColors } from '../../enums/geofenceLevel';

/**
 * color-Picker
 *
 * @param {Function} changeColor callback to set picked color
 * @param {String} selectedColor selected color
 */
export const ColorPicker = ({ changeColor, selectedColor }) => {
  return (
    <div className="circle-picker dot-input">
      {Object.keys(ShapeColors).map((gl, idx) => (
        <span key={idx}>
          <div className="color-box">
            <span>
              <div
                className="colors"
                style={{
                  boxShadow: `${ShapeColors[gl]} 0px 0px 0px 14px inset`,
                  transition: 'box-shadow 100ms ease 0s',
                }}
                onMouseEnter={(e) => {
                  e.target.style.boxShadow = `${ShapeColors[gl]} 0px 0px 0px 6px inset`;
                }}
                onMouseLeave={(e) => {
                  e.target.style.boxShadow = `${ShapeColors[gl]} 0px 0px 0px 14px inset`;
                }}
                onClick={() => {
                  changeColor(gl);
                }}
              >
                {selectedColor === gl && (
                  <div
                    style={{
                      color: '#ffffff',
                      borderRadius: '50%',
                      height: '20px',
                      width: '20px',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      margin: '-10px 0px 0px -10px',
                    }}
                  >
                    <FontAwesomeIcon
                      icon="circle-check"
                      size="lg"
                      style={{
                        borderRadius: '50%',
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </div>
                )}
              </div>
            </span>
          </div>
        </span>
      ))}
    </div>
  );
};

ColorPicker.propTypes = {
  changeColor: PropTypes.func,
  selectedColor: PropTypes.string,
};
