/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { displayName } from '../utils/string';
import { get, post } from './global';

/**
 * get threads for mission
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const getThreads = async (missionId) => {
  return await get(`/chat/mission/${missionId}/threads`);
};

/**
 * get thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @return {any} API response
 */
export const getThread = async (missionId, threadId) => {
  return await get(`/chat/mission/${missionId}/thread/${threadId}`);
};

/**
 * Find/create thread that contains the given users
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {string[]} users array of user ids
 * @param {string[]} teams array of team ids
 * @return {any} API response
 */
export const getThreadByUsers = async (missionId, users, teams = []) => {
  return await post(`/chat/mission/${missionId}/thread/create`, {
    users,
    teams,
  });
};

/**
 * delete thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @return {any} API response
 */
export const deleteThread = async (missionId, threadId) => {
  return true;
};

/**
 * get messages for a thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @return {any} API response
 */
export const getThreadMessages = async (missionId, threadId) => {
  return await get(`/chat/mission/${missionId}/thread/${threadId}/messages`);
};

/**
 * Create thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {string} thread thread
 * @return {any} API response
 */
export const postThread = async (missionId, threadId, thread) => {
  if (threadId) {
    return true;
  } else {
    return await getThreadByUsers(missionId, thread.users, thread.teams || []);
  }
};
/**
 * Rename thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {string} threadName thread name
 * @return {any} API response
 */
export const renameThread = async (missionId, threadId, threadName) => {
  return await post(`/chat/mission/${missionId}/thread/${threadId}/rename`, {
    threadName,
  });
};

/**
 * Add participant to thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {string} userId user id
 * @return {any} API response
 */
export const addThreadParticipant = async (missionId, threadId, userId) => {
  return true;
};

/**
 * Remove participant to thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {string} userId user id
 * @return {any} API response
 */
export const removeThreadParticipant = async (missionId, threadId, userId) => {
  return true;
};

/**
 * Indicate that you are typing in a thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {string} user user that is typing - probably current user
 * @return {any} API response
 */
export const threadTyping = async (missionId, threadId, user) => {
  return await post(
    `/chatTyping`,
    {
      threadId,
      userId: user.id,
      displayName: displayName(user),
    },
    null,
    'realtime'
  ).catch((e) => {
    // if (e.message && e.message.includes('503')) {
    //   // eslint-disable-next-line
    //   throw 'Realtime service unavailable';
    // } else {
    //   throw e;
    // }
  });
};

/**
 * Indicate that you have read a thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {string} user user that read the thread - probably current user
 * @return {any} API response
 */
export const threadRead = async (missionId, threadId, user) => {
  return await post(
    `/chatRead`,
    {
      missionId,
      threadId,
      userId: user.id,
    },
    null,
    'realtime'
  ).catch((e) => {});
};

/**
 * Indicate that you have read a thread
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {any} message user that read the thread - probably current user
 * @return {any} API response
 */
export const threadSendMessage = async (missionId, threadId, message) => {
  return await post(
    `/chat/mission/${missionId}/thread/${threadId}/message`,
    message
  );
};

/**
 * get ACS token
 *
 * @return {any} API response
 */
export const getToken = async () => {
  return await get(`/comms/gettoken`);
};
