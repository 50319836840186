/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

/**
 * Basic event based messaging context.
 */
export const EventContext = createContext([
  (_event, _cb) => {}, // subscribe
  (_event, _cb) => {}, // unsubscribe
  (_event, _payload) => {}, // dispatch
]);

/**
 * Event provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const EventProvider = ({ children }) => {
  const [subscribers, dispatch] = useReducer(
    (state, action) => {
      const { type, event } = action;
      switch (type) {
        case 'subscribe': {
          const { callback } = action;
          if (event in state) {
            if (state[event].includes(callback)) {
              return state;
            }
            return { ...state, [event]: [...state[event], callback] };
          }
          return { ...state, [event]: [callback] };
        }
        case 'unsubscribe': {
          const { callback } = action;
          if (event in state && state[event].includes(callback)) {
            return {
              ...state,
              [event]: [...state[event].filter((cb) => cb !== callback)],
            };
          }
          return state;
        }

        default:
          throw new Error();
      }
    },
    {},
    () => ({})
  );

  const subscribersRef = React.useRef({});

  subscribersRef.current = React.useMemo(() => subscribers, [subscribers]);

  const subscribe = useCallback(
    (event, callback) => {
      dispatch({ type: 'subscribe', event, callback });
    },
    [dispatch]
  );

  const unsubscribe = useCallback(
    (event, callback) => {
      dispatch({ type: 'unsubscribe', event, callback });
    },
    [dispatch]
  );

  const dispatchEvent = useCallback(
    (event, payload) => {
      if (event in subscribersRef?.current) {
        subscribersRef?.current[event].forEach((cb) => cb(payload));
      }
    },
    [subscribersRef]
  );

  const eventPack = useMemo(
    () => [subscribe, unsubscribe, dispatchEvent],
    [subscribe, unsubscribe, dispatchEvent]
  );

  return (
    <EventContext.Provider value={eventPack}>{children}</EventContext.Provider>
  );
};

/**
 * Helper hook for consuming signal context.
 *
 * @returns EventContext
 */
export const useEvent = (event, callback) => {
  // eslint-disable-next-line
  const [subscribe, unsubscribe, _dispatch] = useContext(EventContext);

  useEffect(() => {
    subscribe(event, callback);

    return () => unsubscribe(event, callback);
  }, [subscribe, unsubscribe, event, callback]);
};

/**
 * Helper hook for consuming signal context.
 *
 * @returns EventContext
 */
export const useDispatch = () => {
  // eslint-disable-next-line
  const [_subscribe, _unsubscribe, dispatch] = useContext(EventContext);
  return dispatch;
};
