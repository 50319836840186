/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { del, get, post, put } from './global';

/**
 * Get mission orders
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const getOrders = async (missionId) => {
  const orders = await get(`/missions/${missionId}/orders`);

  for (const section of orders.sections) {
    let numAssigned = 0;

    if (!orders._ts || orders._ts > section._ts) {
      orders._ts = section._ts;
      orders.userId = section.userId;
      orders.entity = section.entity;
    }

    for (const task of section.tasks) {
      if (task.assigned) {
        numAssigned += task.assigned.length;
      }
    }

    section.assigned = numAssigned;
  }

  return orders;
};

/**
 * Save order section
 *
 * @param {string} missionId mission id
 * @param {any} section mission id
 * @return {any} API response
 */
export const saveOrderSection = async (missionId, section) => {
  if (section.id) {
    await post(`/missions/${missionId}/ordersections/${section.id}`, section);
    for (const task of section.tasks) {
      if (task.assigned) {
        task.assigned = task.assigned.map((u) => {
          return {
            type: u.type,
            id: u.id,
          };
        });
      }

      if (task.id) {
        await post(
          `/missions/${missionId}/ordersections/${section.id}/task/${task.id}`,
          task
        );
      } else {
        await post(
          `/missions/${missionId}/ordersections/${section.id}/task`,
          task
        );
      }
    }
  } else {
    const newSection = await post(
      `/missions/${missionId}/ordersections`,
      section
    );
    for (const task of section.tasks) {
      if (task.assigned) {
        task.assigned = task.assigned.map((u) => {
          return {
            type: u.type,
            id: u.id,
          };
        });
      }
      await post(
        `/missions/${missionId}/ordersections/${newSection.id}/task`,
        task
      );
    }
  }
};

/**
 * Delete order section
 *
 * @param {string} missionId mission id
 * @param {string} sectionId section id
 * @return {any} API response
 */
export const deleteOrderSection = async (missionId, sectionId) => {
  await del(`/missions/${missionId}/ordersections/${sectionId}`);
};

export const saveTask = async (missionId, sectionId, task) => {
  if (task.id) {
    if (task.assigned) {
      task.assigned = task.assigned.map((u) => {
        return {
          type: u.type,
          id: u.id,
        };
      });
    }
    await post(
      `/missions/${missionId}/ordersections/${sectionId}/task/${task.id}`,
      task
    );
  } else {
    await post(`/missions/${missionId}/ordersections/${sectionId}/task`, task);
  }
};

/**
 * Delete order section task
 *
 * @param {string} missionId mission id
 * @param {string} sectionId section id
 * @param {string} taskId task id
 * @return {any} API response
 */
export const deleteTask = async (missionId, sectionId, taskId) => {
  await del(`/missions/${missionId}/ordersections/${sectionId}/task/${taskId}`);
};

/**
 * Get order history for mission
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const getHistory = async (missionId) => {
  return await get(`/missions/${missionId}/orders/history`);
};

/**
 * Update completion status of task
 *
 * @param {string} missionId mission id
 * @param {string} orderSectionId order section id
 * @param {string} taskId task id
 * @param {bool} complete true to complete task
 * @return {any} API response
 */
export const updateTaskStatus = async (
  missionId,
  orderSectionId,
  taskId,
  complete
) => {
  return await put(
    `/missions/${missionId}/ordersections/${orderSectionId}/task/${taskId}/status`,
    { complete }
  );
};

/**
 * Get tasks for a user
 *
 * @param {string} missionId mission id
 * @param {string} userId user id
 * @return {any} API response
 */
export const getTasksForUser = async (missionId, userId) => {
  return await get(`/missions/${missionId}/orders/tasks/${userId}`);
};
