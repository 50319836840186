/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import log from 'loglevel';
import pluralize from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams, withRouter } from 'react-router';
import {
  deleteThread,
  getThreadByUsers,
  postThread,
  renameThread,
  threadRead,
} from '../../../api/comms';
import { getMission, getMissionUsers, getTeams } from '../../../api/missions';
import typingLight from '../../../assets/svg/typingLight.svg';
import typingDark from '../../../assets/svg/typingDark.svg';
import svgMissionBroadcast from '../../../assets/svg/mission-broadcast.svg';
import newItem from '../../../assets/svg/new-item.svg';
import { endpointConfig } from '../../../config/endpoint_config';
import { useComms } from '../../../context/CommsContext';
import { SIDEBARS, useSidebar } from '../../../context/SidebarContext';
import { useUser } from '../../../context/UserContext';
import { historyType, routeType } from '../../../enums/propTypes';
import { sortBy } from '../../../utils/array';
import { handleError } from '../../../utils/error';
import { useLocalStorage } from '../../../utils/localStorage';
import { cancellablePromise } from '../../../utils/promise';
import { getClassNames } from '../../../utils/string';
import { formatTime } from '../../../utils/time';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { MenuButton } from '../../common/Menu';
import { MissionMenu } from '../../common/menus/MissionMenu';
import { NavBar } from '../../common/Navbar';
import { LightScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import { Sidebar } from '../../common/Sidebar';
import { Tabs } from '../../common/Tabs';
import { NewChatModal } from '../../modals/NewChatModal';
import { ChatFiles } from '../../sidebars/messages/ChatFiles';
import { ChatParticipants } from '../../sidebars/messages/ChatParticipants';
import { PersonnelMessages } from '../../sidebars/messages/PersonnelMessages';
import { MissionPersonnel } from '../../sidebars/mission/MissionPersonnel';
import './Mission.scss';
import { MissionInfo } from './MissionInfo';

const MAX_THREADS = 10;

/**
 * Mission Comms page
 *
 * @param {any} history react router
 * @param {any} location react route
 */
export const Comms = withRouter(({ history, location }) => {
  const { missionId } = useParams();

  const userValue = useUser();
  const comms = useComms();

  const [mission, setMission] = useState();
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [thread, setThread] = useState(null);
  const [chatParticipantsThread, setChatParticipantsThread] = useState(null);

  const [search, setSearch] = useState('');
  const [activeChatGroups, setActiveChatGroups] = useLocalStorage(
    'active-threads',
    []
  );

  const sidebars = useSidebar();

  const [userId, setUserId] = useState();

  const refActiveChatGroups = useRef(activeChatGroups);

  useEffect(() => {
    refActiveChatGroups.current = activeChatGroups;
  });

  // when kill threads is updated
  useEffect(() => {
    let activeGroups = [...refActiveChatGroups.current];
    for (const threadId of comms.killThreads) {
      activeGroups = activeGroups.filter((a) => a.id !== threadId);
    }
    setActiveChatGroups(activeGroups);
    // eslint-disable-next-line
  }, [comms.killThreads]);

  useEffect(() => {
    const stub = async () => {
      const savedMission = await getMission(missionId);
      const _teams = await getTeams(missionId);
      const _users = await getMissionUsers(missionId);

      setMission(savedMission);
      setTeams(_teams);
      setUsers(_users);

      if (!savedMission?.published) {
        history.push(
          endpointConfig.frontendEndpoints.editMission.replace(
            ':missionId',
            savedMission?.id || ''
          )
        );
      }
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    log.debug('state', location.state);
    if (location.state) {
      const group = comms.threads[location.state];
      if (group) {
        log.debug('nav group', group);
        selectGroup(group);
      }
    }
    // eslint-disable-next-line
  }, [location.state]);

  const selectGroup = (group) => {
    threadRead(missionId, group.id, userValue.user);

    if (!activeChatGroups.find((g) => g.id === group.id)) {
      const otherMissions = activeChatGroups.filter(
        (g) => g.missionId !== missionId
      );
      const thisMission = activeChatGroups.filter(
        (g) => g.missionId === missionId
      );
      if (thisMission.length >= MAX_THREADS) {
        setActiveChatGroups([
          ...otherMissions,
          ...thisMission.splice(0, MAX_THREADS - 1),
          { id: group.id, missionId: group.missionId },
        ]);
      } else {
        setActiveChatGroups([
          ...activeChatGroups,
          { id: group.id, missionId: group.missionId },
        ]);
      }
    }
  };

  const deleteChatThread = async (thread) => {
    toast.promise(deleteThread(missionId, thread.id), {
      loading: 'Loading...',
      success: `${thread.threadName} chat group has been successfully deleted`,
      error: `${thread.threadName} chat group could not be deleted`,
    });
  };

  const filteredChatGroups = sortBy(
    Object.values(comms.threads).filter((g) =>
      (g?.threadName || '').toLowerCase().includes(search.toLowerCase())
    ),
    ['missionWide', 'lastMessage.timestamp'],
    ['desc', 'desc']
  ).map((g) => {
    g.unread = !g.readReceipts.includes(userValue.user.id);
    return g;
  });

  const createChatGroup = async (topic, unitsOrPersonnel) => {
    // thread created so add members
    const users = [userValue.user.id];
    const teams = [];
    log.debug('checking units', unitsOrPersonnel);
    for (const uop of unitsOrPersonnel) {
      if (uop.type === 'user') {
        users.push(uop.id);
      } else {
        teams.push(uop.id);
      }

      log.debug('creating thread with participants', users);

      const thread = await postThread(missionId, null, {
        users,
        teams,
      }).catch((ex) => {
        handleError(ex);
        throw ex;
      });

      if (thread) {
        selectGroup(thread);
      }
    }
  };

  const updateChatGroup = async (id, topic, unitsOrPersonnel) => {
    // thread created so add members
    const users = [];
    const teams = [];
    log.debug('checking units', unitsOrPersonnel);
    for (const uop of unitsOrPersonnel) {
      if (uop.type === 'user') {
        users.push(uop.id);
      } else {
        teams.push(uop.id);
      }
    }

    log.debug('current thread', thread);

    const toRemove = [];
    const toAdd = [];

    for (const p of users) {
      if (!thread.participants.find((pp) => pp.id === p)) {
        toAdd.push(p);
      }
    }

    for (const p of thread.participants) {
      if (!users.find((pp) => pp === p.id)) {
        toRemove.push(p);
      }
    }

    // log.debug('participant changes', toAdd, toRemove);

    if (topic !== thread.threadName) {
      log.debug('updating topic');
      await renameThread(missionId, thread.id, topic);
    }

    //   if (toAdd.length) {
    //     log.debug('adding participants', toAdd);
    //     for (const p of toAdd) {
    //       await addThreadParticipant(missionId, thread.id, p);
    //     }
    //   }

    //   if (toRemove.length) {
    //     log.debug('removing participants', toRemove);
    //     for (const p of toRemove) {
    //       await removeThreadParticipant(missionId, thread.id, p);
    //     }
    //   }
    // }
  };

  // override setModalOpen for close behaviour
  const dismiss = async () => {
    setThread(null);
    setModalOpen(false);
  };

  return (
    <div className="page">
      <NavBar history={history}>
        <MissionMenu
          history={history}
          missionId={missionId}
          active="comms"
          published={mission?.published}
          archived={mission?.archived}
        ></MissionMenu>
      </NavBar>
      {mission && (
        <div className="mission-info-container">
          <MissionInfo missions={[mission]} history={history} />
        </div>
      )}
      <div className="content-columns">
        <section className="chat-list">
          <div className="header">
            <h1>
              <FontAwesomeIcon icon="comment-alt" /> <span>Comms</span>
            </h1>
            <div>
              <SearchInput
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search comms..."
              />
              {!mission?.archived && (
                <DotButton
                  className="button search-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalOpen(true);
                  }}
                  ariaLabel="Create New Chat"
                >
                  <FontAwesomeIcon icon="plus" />
                </DotButton>
              )}
            </div>
          </div>
          <LightScrollbar className="list">
            {!!filteredChatGroups?.length &&
              filteredChatGroups.map((g) => {
                return (
                  <div
                    key={g.id}
                    className={getClassNames({
                      'list-item': true,
                      active: !!activeChatGroups.find((a) => a.id === g.id),
                    })}
                    onClick={() => selectGroup(g)}
                  >
                    {!!g.unread ? (
                      <img
                        style={{
                          marginLeft: '8px',
                          marginRight: '-8px',
                        }}
                        src={newItem}
                        alt="new item"
                      />
                    ) : (
                      <div
                        style={{
                          minWidth: '14px',
                          maxWidth: '14px',
                          flexShink: 0,
                        }}
                      ></div>
                    )}
                    {g.missionWide ? (
                      <img
                        className="mission-broadcast"
                        src={svgMissionBroadcast}
                        alt="mission broadcast"
                        height="48px"
                        width="48px"
                      />
                    ) : (
                      <Avatar entity={g} />
                    )}
                    <div
                      className="chat"
                      style={{
                        textTransform: g.missionWide ? 'uppercase' : null,
                      }}
                    >
                      <span>{g.threadName}</span>
                      {comms.typingIndicators.filter(
                        (ti) => ti.threadId === g.id
                      )?.length ? (
                        <>
                          <div className="typing light">
                            <img src={typingLight} alt="typing indicator" />
                          </div>{' '}
                          <div className="typing dark">
                            <img src={typingDark} alt="typing indicator" />
                          </div>
                        </>
                      ) : (
                        <span>{g.lastMessage?.message}</span>
                      )}
                    </div>
                    <div className="time">
                      {formatTime(g.lastMessage?.timestamp)}
                    </div>
                    <div
                      style={{
                        backgroundColor: activeChatGroups.find(
                          (a) => a.id === g.id
                        )
                          ? 'var(--colour-interactions-c)'
                          : '',
                        marginTop: '-6px',
                        marginBottom: '-6px',
                        alignSelf: 'stretch',
                        marginLeft: '9px',
                        marginRight: '-11px',
                        width: '3px',
                      }}
                    >
                      &nbsp;
                    </div>
                  </div>
                );
              })}

            {comms.commsLoading && (
              <div className="list-loading">
                <div className="bubble">Loading</div>
              </div>
            )}
          </LightScrollbar>
        </section>
        <LightScrollbar className="chat-groups" noScrollY={true}>
          {activeChatGroups.map((a, idx) => {
            const thread = comms.threads[a.id];
            if (thread) {
              return (
                <ChatGroup
                  key={a.id}
                  thread={thread}
                  missionId={missionId}
                  closeGroup={(group) => {
                    setActiveChatGroups(
                      activeChatGroups.filter((a) => a.id !== group.id)
                    );
                  }}
                  grow={
                    activeChatGroups.filter((a) => a.missionId === missionId)
                      .length > 2 &&
                    activeChatGroups.filter((a) => a.missionId === missionId)
                      .length -
                      1 ===
                      idx
                  }
                  deleteChatThread={deleteChatThread}
                  editChatThread={(thread) => {
                    log.debug('edit thread', thread);
                    setThread(thread);
                    setModalOpen(true);
                  }}
                  setUserId={(id) => {
                    sidebars.open(SIDEBARS.unitInformation);
                    setUserId(id);
                  }}
                  setChatParticipantsThread={(thread) => {
                    sidebars.open(SIDEBARS.chatParticipants);
                    setChatParticipantsThread(thread);
                  }}
                  readOnly={mission?.archived}
                ></ChatGroup>
              );
            } else {
              return <></>;
            }
          })}
        </LightScrollbar>
      </div>

      <NewChatModal
        isActive={modalOpen}
        exit={dismiss}
        modalId="create-chat"
        thread={thread}
        onSubmit={(name, unitsOrPersonnel) => {
          const promise = thread?.id
            ? updateChatGroup(thread.id, name, unitsOrPersonnel)
            : createChatGroup(name, unitsOrPersonnel);

          toast.promise(promise, {
            loading: 'Loading...',
            success: thread?.id
              ? `${name} chat group has been successfully saved`
              : `${name} chat group has been successfully created`,
            error: thread?.id
              ? `${name} chat group could not be saved`
              : `${name} chat group could not be created`,
          });
        }}
        missionId={missionId}
      ></NewChatModal>

      <Sidebar id={SIDEBARS.unitInformation}>
        <MissionPersonnel
          closeSidebar={() => {
            // update list
            setUserId(null);
            sidebars.close(SIDEBARS.unitInformation);

            if (chatParticipantsThread) {
              sidebars.open(SIDEBARS.chatParticipants);
            }
          }}
          userId={userId}
          missionId={missionId}
          isComms
          openDM={async (id) => {
            const thread = await getThreadByUsers(missionId, [
              userValue.user.id,
              id,
            ]).catch(handleError);

            if (thread) {
              selectGroup(thread);
            }
          }}
          users={users}
          teams={teams}
        />
      </Sidebar>
      <Sidebar id={SIDEBARS.chatParticipants}>
        <ChatParticipants
          history={history}
          closeSidebar={() => {
            setChatParticipantsThread(null);
            sidebars.close(SIDEBARS.chatParticipants);
          }}
          openUnitInformation={(id) => {
            setUserId(id);
            sidebars.open(SIDEBARS.unitInformation);
          }}
          createChatWithUser={async (id) => {
            const promise = createChatGroup('', [{ id, type: 'user' }]);

            toast.promise(
              promise.then((t) => {
                if (t) {
                  selectGroup(t);
                }
              }),
              {
                loading: 'Loading...',
                success: `Chat group has been successfully created`,
                error: `Chat group could not be created`,
              }
            );
          }}
          createChatWithTeam={async (id) => {
            const promise = createChatGroup('', [{ id, type: 'team' }]);

            toast.promise(
              promise.then((t) => {
                if (t) {
                  selectGroup(t);
                }
              }),
              {
                loading: 'Loading...',
                success: `Chat group has been successfully created`,
                error: `Chat group could not be created`,
              }
            );
          }}
          thread={chatParticipantsThread}
          readOnly={mission?.archived}
        />
      </Sidebar>
    </div>
  );
});

Comms.propTypes = {
  history: historyType,
  location: routeType,
};

const ChatGroup = ({
  missionId,
  thread,
  closeGroup,
  grow,
  deleteChatThread,
  editChatThread,
  setUserId,
  setChatParticipantsThread,
  readOnly,
}) => {
  const [screen, setScreen] = useState('chat');

  const options = [];
  // TODO: need a thread type
  if (thread.info?.id) {
    options.push({
      text: 'Unit Info',
      ariaLabel: 'Unit Info',
      onClick: () => {
        setUserId(thread.info.id);
      },
    });
    // options.push({
    //   text: 'Create Chat Group',
    //   onClick: () => {
    //     toast.success('Coming soon 👷');
    //   },
    // });
  } else if (thread.missionWide) {
    options.push({
      text: 'View Chat Members',
      ariaLabel: 'View Chat Members',
      icon: 'users',
      onClick: () => {
        setChatParticipantsThread(thread);
      },
    });
  } else {
    if (!readOnly) {
      options.push({
        text: 'Edit Chat Group',
        ariaLabel: 'Edit Chat Group',
        icon: 'pencil-alt',
        onClick: () => {
          editChatThread({
            ...thread,
          });
        },
      });
    }
    options.push({
      text: 'View Chat Members',
      ariaLabel: 'View Chat Members',
      icon: 'users',
      onClick: () => {
        setChatParticipantsThread(thread);
      },
    });
    // options.push({
    //   text: 'Leave Chat Group',
    //   onClick: () => {
    //     toast.success('Coming soon 👷');
    //   },
    // });
    // options.push({
    //   text: 'Delete Chat Group',
    //   onClick: () => {
    //     deleteChatThread(group);
    //   },
    // });
  }

  if (!thread) {
    return <></>;
  }

  return (
    <div className={getClassNames({ 'chat-group': true, grow })}>
      <div className="header">
        <div className="top">
          {thread.lastMessage?.timestamp ? (
            <>Last message {formatTime(thread.lastMessage?.timestamp)}</>
          ) : (
            <span>&nbsp;</span>
          )}
          <DotButton
            className="button"
            onClick={() => closeGroup(thread)}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        <div className="bottom">
          {thread.missionWide ? (
            <img
              className="mission-broadcast"
              src={svgMissionBroadcast}
              alt="mission broadcast"
            />
          ) : (
            <Avatar entity={thread} size="3.75rem" />
          )}
          <h2>
            {thread.threadName}
            <span>
              {thread.participants?.length || thread.participantsFull?.length
                ? pluralize(
                    'member',
                    thread.participants?.length ||
                      thread.participantsFull?.length,
                    true
                  )
                : 'All mission users'}
            </span>
          </h2>
          {thread.location && (
            <DotButton
              className="button"
              onClick={() => toast.success('coming soon! 👷')}
            >
              <FontAwesomeIcon icon="map-marker-alt" />
            </DotButton>
          )}
          <MenuButton
            buttonTheme="light"
            position="left"
            options={options}
            ariaLabel="Chat Menu"
          >
            <FontAwesomeIcon icon="bars" />
          </MenuButton>
        </div>
      </div>
      <Tabs
        items={[
          {
            text: `Messages`,
            active: screen === 'chat',
            onClick: () => setScreen('chat'),
          },
          {
            text: `Files`,
            active: screen === 'files',
            onClick: () => setScreen('files'),
          },
          // {
          //   text: `Tasks`,
          //   active: screen === 'tasks',
          //   onClick: () => setScreen('tasks'),
          // },
        ]}
      />
      <div className="tab-content">
        {screen === 'chat' && (
          <PersonnelMessages
            missionId={missionId}
            id={thread.id}
            readOnly={readOnly}
          ></PersonnelMessages>
        )}
        {screen === 'files' && (
          <ChatFiles missionId={missionId} id={thread.id}></ChatFiles>
        )}
        {screen === 'tasks' && (
          <div style={{ padding: '20px' }}>👷 Coming soon! 👷</div>
        )}
      </div>
    </div>
  );
};
