/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import { postOrganisation } from '../../../api/orgs_users';
import { endpointConfig } from '../../../config/endpoint_config.js';
import { historyType, organisationType } from '../../../enums/propTypes';
import { handleError } from '../../../utils/error';
import { DotButton } from '../../common/buttons/DotButton';
import { Input } from '../../common/Input';
import { LightScrollbar } from '../../common/Scrollbars';
import { TextArea } from '../../common/TextArea';
import { UploadCrop } from '../../common/UploadCrop';
import './CreateEditOrg.scss';
import { ColorPicker } from '../../common/ColorPicker';
import { ShapeColors } from '../../../enums/geofenceLevel';

/**
 * Either Creates or Edits an org.
 *
 *  This is determined by whether "org" is included in the props.
 *  If it is then it is assumed we are in "edit" mode.
 *
 *  @param {any} history react router
 *  @param {any} org organisation to edit
 *  @param {any} closeSidebar callback to close sidebar
 *  @param {Function} setSelectedOrg callback to set selected org
 */
export const CreateEditOrg = withRouter(
  ({ org, closeSidebar, setSelectedOrg, history }) => {
    const getColorCode = (value) => {
      if (!value) return 'Black';
      let colorCode =
        Object.keys(ShapeColors).find((key) => ShapeColors[key] === value) ||
        'Black';
      return colorCode;
    };

    // Image processing state
    const [selectedImg, setSelectedImg] = useState('');

    const [inputValues, setInputValues] = useState({
      organisationName: org?.organisationName || '',
      organisationDescription: org?.organisationDescription || '',
      address: org?.address || '',
      color: getColorCode(org?.color) || 'Black',
      photoUrl: org?.photoUrl || '',
      blob: org?.blob || null,
      // todo: desktop-sharing allowDesktopSharing: org?.allowDesktopSharing || false,
    });

    const [touched, setTouched] = useState(false);

    const setImage = (img) => {
      log.debug('setImage', img);
      setTouched(true);

      setInputValues({
        ...inputValues,
        photoUrl: img.url,
        blob: img.blob,
        removePhoto: img.deleteImage || null,
      });
    };

    const updateOrg = async () => {
      const orgId = org?.id;

      if (inputValues.organisationName === '') {
        toast.error(`Organisation name required`);
        return null;
      }

      // Send api request
      try {
        const param = {
          ...inputValues,
        };
        param.color = ShapeColors[inputValues.color] || inputValues.color;
        return await postOrganisation(orgId, param);
      } catch (err) {
        handleError(err);
      } finally {
        // Reset state hooks
        setInputValues({
          organisationName: '',
          organisationDescription: '',
          address: '',
          color: 'Black',
          photoUrl: '',
          // todo: desktop-sharing allowDesktopSharing: false,
        });
      }
    };

    // Handles new org submission
    const handleUpdate = async (e) => {
      e.preventDefault();

      log.debug('CreateEditOrg', 'handleUpdate');

      const _org = await updateOrg();

      if (_org) {
        log.debug('org updated', _org);

        toast.success('Organisation saved');

        // this function may not be set when creating a new org
        if (setSelectedOrg) {
          setSelectedOrg(_org);
        }

        closeSidebar();
      }
    };

    const updateAndManageUsers = async () => {
      const _org = await updateOrg();

      if (setSelectedOrg) {
        setSelectedOrg(_org);
      }
      closeSidebar();

      if (history) {
        history.push(
          endpointConfig.frontendEndpoints.orgUsers.replace(':id', _org.id)
        );
      }
    }; // eslint-disable-next-line

    const validate = () => {
      return !!inputValues.organisationName;
    };

    return (
      <div className="create-org">
        <div className="top">
          <div className="heading">
            <div className="text">
              {org ? 'Organisation' : 'New Organisation'}
            </div>
            <DotButton
              className="exit"
              onClick={() => {
                closeSidebar();
              }}
              ariaLabel="Close"
            >
              <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
            </DotButton>
          </div>
        </div>
        <LightScrollbar className="content">
          <h3>
            General Information
            <span>* Indicates mandatory field</span>
          </h3>
          <form>
            <div className="controls">
              <Input
                label="Organisation Name"
                placeholder="Enter the name of the organisation"
                value={inputValues.organisationName}
                onChange={(e) => {
                  setTouched(true);
                  setInputValues({
                    ...inputValues,
                    organisationName: e.currentTarget.value,
                  });
                }}
                required={true}
              />
              <TextArea
                label="Organisation Description"
                placeholder="The description that other organisations will see when they add you to a mission."
                value={inputValues.organisationDescription}
                onChange={(e) => {
                  setTouched(true);
                  setInputValues({
                    ...inputValues,
                    organisationDescription: e.currentTarget.value,
                  });
                }}
              />
              <Input
                label="Organisation Location"
                placeholder="Enter the location of the organisation"
                value={inputValues.address}
                onChange={(e) => {
                  setTouched(true);
                  setInputValues({
                    ...inputValues,
                    address: e.currentTarget.value,
                  });
                }}
              />
              <ColorPicker
                changeColor={(color) => {
                  setTouched(true);
                  setInputValues({ ...inputValues, color });
                }}
                selectedColor={inputValues.color}
              ></ColorPicker>
              {/* // todo: desktop-sharing
              <div style={{ display: 'flex' }}>
                <div className="check" style={{ marginRight: '1rem' }}>
                  <CheckBox
                    id={`checkbox-allowDesktopSharing`}
                    onChange={(e) => {
                      setTouched(true);
                      setInputValues({
                        ...inputValues,
                        allowDesktopSharing: e.currentTarget.checked,
                      });
                    }}
                    checked={inputValues.allowDesktopSharing || false}
                  ></CheckBox>
                </div>

                <label
                  htmlFor={`checkbox-allowDesktopSharing`}
                  className="text"
                  style={{ cursor: 'pointer' }}
                >
                  Allow Desktop Location Tracking
                </label>
              </div> */}
              <div className="divider"></div>
              <UploadCrop
                selectedImg={selectedImg}
                setSelectedImg={setSelectedImg}
                photoUrl={inputValues.photoUrl}
                setImage={setImage}
                type="org"
                fallback={[inputValues.organisationName]}
              />
            </div>
          </form>
        </LightScrollbar>
        <div className="foot">
          <DotButton
            className="primary block"
            type="submit"
            onClick={handleUpdate}
            disabled={!validate() || !touched}
          >
            {org ? 'Save Changes' : 'Create Organisation'}
          </DotButton>
          {!org?.id && (
            <DotButton
              className="secondary block"
              onClick={updateAndManageUsers}
              disabled={!validate() || !touched}
            >
              Create, and set up users
            </DotButton>
          )}
        </div>
      </div>
    );
  }
);

CreateEditOrg.propTypes = {
  org: organisationType,
  closeSidebar: PropTypes.func,
  setSelectedOrg: PropTypes.func,
  history: historyType,
};
