/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Constants } from './draw_utils';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';

const SimpleSelect = MapboxDraw.modes.simple_select;

SimpleSelect.onSetup = function (opts) {
  // turn the opts into state.
  const state = {
    dragMoveLocation: null,
    boxSelectStartLocation: null,
    boxSelectElement: undefined,
    boxSelecting: false,
    canBoxSelect: false,
    dragMoving: false,
    canDragMove: false,
    initiallySelectedFeatureIds: opts.featureIds || [],
    history: opts.history?.length ? [...opts.history] : [],
  };

  this.setSelected(
    state.initiallySelectedFeatureIds.filter(
      (id) => this.getFeature(id) !== undefined
    )
  );
  this.fireActionable();

  this.setActionableState({
    combineFeatures: true,
    uncombineFeatures: true,
    trash: true,
  });

  return state;
};

SimpleSelect.fireUpdate = function () {
  // this fires on every update, so push new coords to history
  this._ctx.api.combineFeatures();
  console.log('fireUpdate');

  this.map.fire(Constants.events.UPDATE, {
    action: Constants.updateActions.MOVE,
    features: this.getSelected().map((f) => f.toGeoJSON()),
  });
};

SimpleSelect.startOnActiveFeature = function (state, e) {
  // Stop any already-underway extended interactions
  this.stopExtendedInteractions(state);

  // Disable map.dragPan immediately so it can't start
  this.map.dragPan.disable();

  // Re-render it and enable drag move
  this.doRender(e.featureTarget.properties.id);

  // Set up the state for drag moving
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;

  state.history = [];
  this._ctx.api.combineFeatures();
};

SimpleSelect.clickOnFeature = function (state, e) {
  // Stop everything
  doubleClickZoom.disable(this);
  this.stopExtendedInteractions(state);

  const isShiftClick = CommonSelectors.isShiftDown(e);
  const selectedFeatureIds = this.getSelectedIds();
  const featureId = e.featureTarget.properties.id;
  const isFeatureSelected = this.isSelected(featureId);

  // Click (without shift) on any selected feature but a point
  if (
    !isShiftClick &&
    isFeatureSelected &&
    this.getFeature(featureId).type !== Constants.geojsonTypes.POINT
  ) {
    // Enter direct select mode
    return this.changeMode(Constants.modes.DIRECT_SELECT, {
      featureId,
    });
  }

  // Shift-click on a selected feature
  if (isFeatureSelected && isShiftClick) {
    // Deselect it
    this.deselect(featureId);
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    if (selectedFeatureIds.length === 1) {
      doubleClickZoom.enable(this);
    }
    // Shift-click on an unselected feature
  } else if (!isFeatureSelected && isShiftClick) {
    // Add it to the selection
    this.select(featureId);
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    // Click (without shift) on an unselected feature
  } else if (!isFeatureSelected && !isShiftClick) {
    // Make it the only selected feature
    selectedFeatureIds.forEach((id) => this.doRender(id));
    this.setSelected(featureId);
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  }

  // No matter what, re-render the clicked feature
  this.doRender(featureId);

  state.history = [];
  this._ctx.api.combineFeatures();
};

// unused in this draw mode so use to save
// this is triggered internally in fireUpdate
SimpleSelect.onCombineFeatures = function (state) {
  // console.log('saving...');
  const allFeatures = this._ctx.api.getAll();
  const hist = {};
  for (const f of allFeatures.features) {
    hist[f.id] = [...f.geometry.coordinates];
  }
  state.history.push(hist);
};

// unused in this draw mode so use to recover
SimpleSelect.onUncombineFeatures = function (state) {
  // if we have history
  if (state.history?.length && state.history?.length - 2 >= 0) {
    console.log('undo', state.history);
    const hist = state.history[state.history?.length - 2];
    for (const id in hist) {
      const feature = this.getFeature(id);
      console.log(feature, hist[id]);
      feature.setCoordinates(hist[id]);
    }

    // change mode to reset the drawing - pass existing history to new instance of drawing mode
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: this.getSelectedIds(),
      history: state.history.slice(0, state.history?.length - 1),
    });
  } else {
    // no history
    // eslint-disable-next-line
    throw 'no history';
  }
};

export default SimpleSelect;
