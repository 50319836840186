/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// External Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
// Internal Components and styling
import { postCurrentUser, postLogin, postUser } from '../../api/orgs_users';
import { getRoles } from '../../api/roles';
import { useUser } from '../../context/UserContext';
import { groupType, organisationType, userType } from '../../enums/propTypes';
import { Role } from '../../enums/role';
import { handleError } from '../../utils/error';
import { cancellablePromise } from '../../utils/promise';
import { validateEmail } from '../../utils/string';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { LightScrollbar } from '../common/Scrollbars';
import { Select } from '../common/Select';
import { UploadCrop } from '../common/UploadCrop';
import { secrets } from '../../config/secrets';
import './CreateEditUser.scss';

/**
 * Sidebar - Edit user details.
 *
 * @param {Boolean} profile editing current user profile?
 * @param {any} user user data
 * @param {any} setUser set user data
 * @param {string} userId
 * @param {any} groupSelected group selected in org structure
 * @param {string} orgSelected current organisation
 * @param {Function} closeSidebar callback to close sidebar
 * @param {Function} updateUsers callback to update users list
 */
export const CreateEditUser = ({
  profile,
  user,
  setUser,
  userId,
  groupSelected,
  orgSelected,
  closeSidebar,
  updateUsers,
}) => {
  const userValue = useUser();

  const [selectedImg, setSelectedImg] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    log.debug('CreateEditUser', 'user', userValue.user);
    switch (userValue.user.accessRoles[0]) {
      case Role.SysAdmin:
        setRoles([
          {
            value: Role.SysAdmin,
            text: 'Sys Admin',
          },
          {
            value: Role.OrgAdmin,
            text: 'Org Admin',
          },
          {
            value: Role.User,
            text: 'User',
          },
        ]);
        break;
      case Role.OrgAdmin:
        setRoles([
          {
            value: Role.OrgAdmin,
            text: 'Org Admin',
          },
          {
            value: Role.User,
            text: 'User',
          },
        ]);
        break;
      default:
        setRoles([
          {
            value: Role.User,
            text: 'User',
          },
        ]);
        break;
    }

    const { promise, cancel } = cancellablePromise(getRoles(orgSelected?.id));
    promise
      .then((g) => {
        setGroups(g);
      })
      .catch((e) => {});
    return cancel;
  }, [userValue.user, orgSelected?.id]);

  const isValid = () => {
    if (!user) {
      return false;
    }

    if (user.firstname === '' || user.lastname === '' || user.email === '') {
      return false;
    } else if (!validateEmail(user.email)) {
      return false;
    }

    return true;
  };

  const _setUser = (u) => {
    setUser(u);
    setTouched(true);
  };

  const handleUpdate = async (
    e,
    { updateUsers, orgId, groupSelected, userId }
  ) => {
    e.preventDefault();

    // Handle missing params
    log.debug('state', user);

    if (user.firstname === '' || user.lastname === '' || user.email === '') {
      toast.error(`First Name, Last Name, and Email Address required`);
      return;
    } else if (!validateEmail(user.email)) {
      toast.error(`Email Address is not valid.`);
      return;
    }

    // if (
    //   (user.accessLevel === null || user.accessLevel === undefined) &&
    //   !user.accessRoles?.length
    // ) {
    //   toast.error(`Access level required`);
    //   return;
    // }

    if (profile && newPassword) {
      const rs = await postLogin(user.email, currentPassword).catch(() => {
        handleError('Current password is incorrect');
        return;
      });
      if (!rs) {
        return;
      }

      if (newPassword !== repeatPassword) {
        handleError('Both new password and confirm password must match.');
        return;
      }
    }

    log.debug('updating', user);

    const accessRoleSet = new Set(user.accessRoles || []);
    if (user.accessLevel) {
      accessRoleSet.add(user.accessLevel);
    }

    let newUser = {
      email: user.email,
      organisationId: orgId,
      firstname: user.firstname,
      lastname: user.lastname,
      accessRoles: [...accessRoleSet],
      photoUrl: user.photoUrl,
      blob: user.blob || null,
      callSign: user.callSign,
      roleId: null,
    };

    log.debug('new user', newUser);

    log.debug('updating', newUser);

    if (!userId && groupSelected) {
      newUser.roleId = groupSelected.id;
    } else {
      newUser.roleId = user.roleId;
    }

    try {
      if (profile) {
        if (newPassword) {
          newUser.password = newPassword;
        }
        await postCurrentUser(newUser);
        toast.success('Profile saved');
      } else {
        await postUser(userId, newUser);
        if (userId) {
          toast.success('User saved');
        } else {
          toast.success('User created');
        }
      }
    } catch (err) {
      handleError(err);
      return;
    }

    setUser({
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      accessLevel: '',
      photoUrl: '',
      callSign: '',
      userGroupId: '',
    });
    setSelectedImg('');
    setTouched(false);

    closeSidebar(); // re-use function to revert width/padding
    if (updateUsers) {
      updateUsers();
    }
  };

  const setImage = (img) => {
    log.debug('setImage', img);

    _setUser({
      ...user,
      photoUrl: img.url,
      blob: img.blob,
      removePhoto: img.deleteImage || null,
    });
  };

  return (
    <div className="create-user">
      <div className="top">
        <div className="heading">
          <div className="text">
            {profile ? 'Your Profile' : userId ? 'Edit User' : 'New User'}
          </div>
          <DotButton className="exit" onClick={closeSidebar} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <LightScrollbar className="content">
        {profile && (
          <UploadCrop
            selectedImg={selectedImg}
            setSelectedImg={(i) => {
              setSelectedImg(i);
              setTouched(true);
            }}
            photoUrl={user?.photoUrl}
            setImage={(i) => {
              setImage(i);
              setTouched(true);
            }}
            type="user"
            fallback={[user?.firstname || 'N', user?.lastname || 'A']}
          />
        )}
        <h3>General Information</h3>
        <form>
          <div className="controls">
            <Input
              label="First Name"
              placeholder="Enter team member's first name."
              onChange={(e) =>
                _setUser({
                  ...user,
                  firstname: e.currentTarget.value,
                })
              }
              value={user?.firstname}
              required={true}
              readOnly={profile}
            />
            <Input
              label="Last Name"
              placeholder="Enter team member's last name"
              onChange={(e) =>
                _setUser({
                  ...user,
                  lastname: e.currentTarget.value,
                })
              }
              value={user?.lastname}
              required={true}
              readOnly={profile}
            />
            <Input
              label="Email Address"
              placeholder="Enter team member's email"
              onChange={(e) =>
                _setUser({ ...user, email: e.currentTarget.value })
              }
              value={user?.email}
              required={true}
              type="email"
              readOnly={profile}
              invalid={!validateEmail(user?.email)}
            />
            <Input
              label="Callsign or Nickname"
              placeholder={`This is the name used in ${secrets.EVENT_NAME}s`}
              onChange={(e) =>
                _setUser({
                  ...user,
                  callSign: e.currentTarget.value,
                })
              }
              value={user?.callSign}
            />
            {!profile && (
              <>
                {userValue.user.id !== user.id &&
                  (userValue.user.accessRoles.includes(Role.SysAdmin) ||
                    userValue.user.accessRoles.includes(Role.OrgAdmin)) && (
                    <>
                      <h3>Access Level</h3>
                      <Select
                        label="Access Level"
                        placeholder="Enter team member's access level"
                        onChange={(e) =>
                          _setUser({
                            ...user,
                            accessLevel: e.currentTarget.value,
                          })
                        }
                        value={
                          user?.accessLevel ||
                          (user?.accessRoles?.length
                            ? user?.accessRoles[0]
                            : '')
                        }
                      >
                        {roles.map((r, idx) => (
                          <option key={idx} value={r.value}>
                            {r.text}
                          </option>
                        ))}
                      </Select>
                    </>
                  )}

                <h3>Role</h3>
                <Select
                  label="Role"
                  placeholder="Enter team member's role."
                  onChange={(e) =>
                    _setUser({
                      ...user,
                      roleId: e.currentTarget.value,
                    })
                  }
                  value={user?.roleId || ''}
                >
                  <option value="">No Role</option>
                  {groups &&
                    groups.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.role}
                      </option>
                    ))}
                </Select>
              </>
            )}
            {!profile && (
              <>
                <div className="divider"></div>
                <UploadCrop
                  selectedImg={selectedImg}
                  setSelectedImg={(i) => {
                    setSelectedImg(i);
                    setTouched(true);
                  }}
                  photoUrl={user?.photoUrl}
                  setImage={(i) => setImage(i)}
                  type="user"
                  fallback={[user?.firstname || 'N', user?.lastname || 'A']}
                />
              </>
            )}
          </div>
          {profile && (
            <>
              <h3>Change Password</h3>
              <div className="controls">
                <Input
                  label="Current Password"
                  type="password"
                  onChange={(e) => {
                    setCurrentPassword(e.currentTarget.value);
                    setTouched(true);
                  }}
                  value={currentPassword}
                />
                <Input
                  label="New Password"
                  type="password"
                  onChange={(e) => {
                    setNewPassword(e.currentTarget.value);
                    setTouched(true);
                  }}
                  value={newPassword}
                />
                <Input
                  label="Repeat Password"
                  type="password"
                  onChange={(e) => {
                    setRepeatPassword(e.currentTarget.value);
                    setTouched(true);
                  }}
                  value={repeatPassword}
                />
              </div>
            </>
          )}
        </form>
      </LightScrollbar>
      <div className="foot">
        <DotButton
          className="primary block"
          type="submit"
          disabled={!touched || !isValid()}
          onClick={async (e) => {
            await handleUpdate(e, {
              updateUsers: updateUsers,
              orgId: orgSelected?.id,
              groupSelected: groupSelected,
              userId: userId,
            });
          }}
        >
          {userId ? 'SAVE CHANGES' : 'CREATE USER'}
        </DotButton>
      </div>
    </div>
  );
};

CreateEditUser.propTypes = {
  profile: PropTypes.bool,
  user: userType,
  setUser: PropTypes.func,
  userId: PropTypes.string,
  groupSelected: groupType,
  orgSelected: organisationType,
  closeSidebar: PropTypes.func.isRequired,
  updateUsers: PropTypes.func,
};
