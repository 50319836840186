/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

export const GeofenceLevel = Object.freeze({
  Advisory: 'advisory',
  Caution: 'caution',
  Warning: 'warning',
});

export const AnnotationLevel = Object.freeze({
  Advisory: '#505366',
  Caution: '#ffd43a',
  Warning: '#f66f6f',
});

export const GeofenceColors = Object.freeze({
  advisory: '#505366',
  caution: '#ffd43a',
  warning: '#f66f6f',
});

export const ShapeColors = Object.freeze({
  Black: '#000000',
  Gray01: '#4e4e4e',
  Gray02: '#828282',
  Gray03: '#d3d3d3',
  Gray04: '#ededed',
  White: '#ffffff',
  Pink01: '#f45f89',
  Pink02: '#d64e75',
  Pink03: '#a23a58',
  Magenta01: '#d73696',
  Magenta02: '#b21473',
  Magenta03: '#7c084e',
  Violet01: '#c338d1',
  Violet02: '#a338ac',
  Violet03: '#7d2385',
  Purple01: '#8833e4',
  Purple02: '#6626ad',
  Purple03: '#4b1b80',
  Indigo01: '#5b6cee',
  Indigo02: '#4a58ba',
  Indigo03: '#364087',
  Blue01: '#1b40e7',
  Blue02: '#0d2aaa',
  Blue03: '#0f2160',
  LightBlue01: '#00b0e8',
  LightBlue02: '#0094c2',
  LightBlue03: '#017ba2',
  Cyan01: '#00c3c9',
  Cyan02: '#00a0a5',
  Cyan03: '#007f83',
  Green01: '#00c89c',
  Green02: '#00a580',
  Green03: '#007c4f',
  Lime01: '#94d14a',
  Lime02: '#82aa4b',
  Lime03: '#618037',
  Yellow01: '#dbb54e',
  Yellow02: '#b6943b',
  Yellow03: '#786a3f',
  Orange01: '#dc6b3c',
  Orange02: '#b55830',
  Orange03: '#874022',
});
