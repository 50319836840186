/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import reactDom from 'react-dom';
import { useSidebar } from '../../context/SidebarContext';
import { getClassNames } from '../../utils/string';
import { DotButton } from './buttons/DotButton';
import './Lightbox.scss';

/**
 * LightBox
 *
 * @param {{reference: string, photoUrl: string, name: string}}[]} images images to show in lightbox
 * @param {string} style input placeholder text
 * @param {{reference: string, photoUrl: string, name: string}}} image selected image
 * @param {Function} selectImage set selected image
 * @param {Function} exit callback to close lightbox - X button is shown if this callback is used
 * @param {Function} close callback to close lightbox - X button is NOT shown if this callback is used
 */
export const LightBox = ({
  images,
  image,
  selectImage,
  style,
  exit,
  close,
}) => {
  const [container] = React.useState(document.createElement('div'));
  const { sidebars } = useSidebar();

  const xDown = useRef();
  const yDown = useRef();

  let selectedIndex = images.indexOf(
    images.find(
      (i) => (i.reference || i.photoUrl) === (image.reference || image.photoUrl)
    )
  );
  if (selectedIndex < 0) {
    selectedIndex = 0;
  }

  useEffect(() => {
    container.classList.add('lightbox');

    if (style) {
      for (const key of Object.keys(style)) {
        container.style[key] = style[key];
      }
    }
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (images.length > 1) {
      container.classList.add('has-thumbs');
    } else {
      container.classList.remove('has-thumbs');
    }
    // eslint-disable-next-line
  }, [images]);

  useEffect(() => {
    if (sidebars?.length) {
      container.classList.add('has-sidebar');
    } else {
      container.classList.remove('has-sidebar');
    }
    // eslint-disable-next-line
  }, [sidebars]);

  const scrollLeft = () => {
    let newIndex = selectedIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
    selectImage(images[newIndex]);
  };

  const scrollRight = () => {
    let newIndex = selectedIndex + 1;
    if (newIndex > images.length - 1) {
      newIndex = images.length - 1;
    }
    selectImage(images[newIndex]);
  };

  return reactDom.createPortal(
    <>
      {typeof exit === 'function' && (
        <DotButton onClick={exit} ariaLabel="Close">
          <FontAwesomeIcon
            icon="times"
            style={{ color: 'grey' }}
          ></FontAwesomeIcon>
        </DotButton>
      )}
      <div
        className="main"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (close) {
            close();
          }
          if (exit) {
            exit();
          }
        }}
        onTouchStart={(e) => {
          const firstTouch = e.touches[0];
          xDown.current = firstTouch.clientX;
          yDown.current = firstTouch.clientY;
        }}
        onTouchMove={(e) => {
          if (!xDown.current || !yDown.current) {
            return;
          }

          var xUp = e.touches[0].clientX;
          var yUp = e.touches[0].clientY;

          var xDiff = xDown.current - xUp;
          var yDiff = yDown.current - yUp;

          if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
              scrollRight();
            } else {
              scrollLeft();
            }
          } else {
            if (yDiff > 0) {
              /* down  */
            } else {
              /* up  */
            }
          }
          /* reset */
          xDown.current = null;
          yDown.current = null;
        }}
      >
        <img
          src={
            images[selectedIndex].reference || images[selectedIndex].photoUrl
          }
          alt={images[selectedIndex].description || images[selectedIndex].name}
        ></img>
        {!!images[selectedIndex].description && (
          <div className="meta">{images[selectedIndex].description}</div>
        )}
      </div>
      {images.length > 1 && (
        <>
          <div className="thumbs">
            {images.map((img, index) => {
              const sensitiveContent = img.meta?.adult?.isAdultContent
                ? 'Adult Content'
                : img.meta?.adult?.isRacyContent
                ? 'Racy Content'
                : img.meta?.adult?.isGoryContent
                ? 'Contains Gore'
                : '';

              return (
                <div
                  className={getClassNames({
                    thumb: true,
                    censored: sensitiveContent,
                    selected: selectedIndex === index,
                  })}
                  key={index}
                >
                  <img
                    src={img.reference || img.photoUrl}
                    alt={img.name}
                    onClick={() => {
                      if (selectImage) {
                        selectImage(img);
                      }
                    }}
                  ></img>
                  <div className="censored-text">
                    <FontAwesomeIcon
                      icon="eye-slash"
                      size="2x"
                    ></FontAwesomeIcon>
                    <div>Sensitive Content</div>
                    <span>{sensitiveContent}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="scroll-left">
            <DotButton
              className="button"
              onClick={scrollLeft}
              disabled={selectedIndex === 0}
            >
              <FontAwesomeIcon icon="chevron-left" />
            </DotButton>
          </div>
          <div className="scroll-right">
            <DotButton
              className="button"
              onClick={scrollRight}
              disabled={selectedIndex === images.length - 1}
            >
              <FontAwesomeIcon icon="chevron-right" />
            </DotButton>
          </div>
        </>
      )}
    </>,
    container
  );
};

LightBox.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      reference: PropTypes.string,
      name: PropTypes.string.isRequired,
      photoUrl: PropTypes.string,
    })
  ),
  style: PropTypes.object,
  exit: PropTypes.func,
  close: PropTypes.func,
};
