/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// Styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import { PropTypes } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { getTeams, getUsersInMission } from '../../api/missions';
import { cancellablePromise } from '../../utils/promise';
import { displayName, getClassNames } from '../../utils/string';
import { Avatar } from './Avatar';
import { DotButton } from './buttons/DotButton';
import { EventManager } from './EventManager';
import './TagSelect.scss';

let portal = document.getElementById('tag-select-portal');
if (!portal) {
  portal = document.createElement('div');
  portal.id = 'tag-select-portal';
  document.body.appendChild(portal);
}

/**
 * Autocomplete select control with tags
 *
 * @param {string} label input label (form field set label)
 * @param {string} placeholder input placeholder text
 * @param {string} value check box value
 * @param {Function} setValue callback to set value
 * @param {Function} selectUnitOrPersonnel callback to select unit
 * @param {Function} removeUnitOrPersonnel callback to remove unit
 * @param {Jsx} children child elements
 * @param {string} theme control theme
 * @param {Boolean} required input is required?
 * @param {Boolean} readOnly input is read only?
 * @param {Boolean} disabled input disabled?
 * @param {Boolean} canRemove tags can be removed?
 * @param {string} current mission id
 */
export const TagSelect = ({
  label,
  placeholder,
  value,
  setValue,
  selectUnitOrPersonnel,
  removeUnitOrPersonnel,
  children,
  theme = '',
  required = false,
  readOnly = false,
  disabled = false,
  canRemove = true,
  missionId,
}) => {
  const wrapperRef = useRef(null);
  const liSelected = useRef(null);

  const [touched, setTouched] = useState(false);
  const [text, setText] = useState('');

  const [unitsOrPersonnel, setUnitsOrPersonnel] = useState([]);

  const init = async () => {
    const _teams = await getTeams(missionId);
    _teams.forEach((u) => {
      u.type = 'team';
    });
    const _users = await getUsersInMission(missionId);
    _users.forEach((u) => {
      u.type = 'user';
    });
    const _unitsAndPersonnel = [..._teams, ..._users];
    log.debug('_unitsAndPersonnel', _unitsAndPersonnel);

    // if we can update the value then bind with the meta data
    if (setValue) {
      const newValue = value
        .map((v) => {
          return _unitsAndPersonnel.find((u) => u.id === v.id);
        })
        .filter((v) => !!v);
      setValue(newValue);
    }

    setUnitsOrPersonnel(_unitsAndPersonnel);
  };
  useEffect(() => {
    const { promise, cancel } = cancellablePromise(init());
    promise.then(() => {}).catch((e) => {});

    return () => {
      cancel();
    };
    // eslint-disable-next-line
  }, []);

  const clicked = (e) => {
    e.preventDefault();
    setTouched(true);
  };

  const textChanged = async (e) => {
    liSelected.current = null;
    setText(e.target.value);
  };

  const getStyle = () => {
    var rect = wrapperRef.current.getBoundingClientRect();

    return {
      top: rect.top + rect.height,
      left: rect.left,
      width: rect.width,
    };
  };

  const filteredUnitsOrPersonnel = unitsOrPersonnel.filter(
    (u) =>
      !!text &&
      ((u.team && u.team.toLowerCase().includes(text.toLowerCase())) ||
        (u.firstname &&
          `${u.firstname} ${u.lastname}`
            .toLowerCase()
            .includes(text.toLowerCase())))
  );

  const portalUnits = filteredUnitsOrPersonnel?.length ? (
    ReactDOM.createPortal(
      <div
        style={getStyle()}
        className="dot-tag-select units tag-select-element"
      >
        {filteredUnitsOrPersonnel.map((unit) => (
          <DotButton
            className="button unit-button tag-select-element"
            key={unit.id}
            onClick={() => {
              log.debug('onClick', unit);
              selectUnitOrPersonnel(unit);
              setText('');
              liSelected.current = null;
            }}
          >
            <div className="unit tag-select-element">
              <Avatar entity={unit} />
              <div className="text">
                {displayName(unit)}
                <span>
                  {unit.type === 'user'
                    ? unit.role?.role
                      ? unit.role?.role
                      : 'User'
                    : 'Team'}
                </span>
              </div>
            </div>
          </DotButton>
        ))}
      </div>,
      portal
    )
  ) : (
    <></>
  );

  return (
    <div
      ref={wrapperRef}
      className="dot-tag-select"
      onKeyDown={(e) => {
        const li = document.getElementsByClassName('unit-button');

        for (const l of li) {
          l.classList.remove('selected');
        }

        switch (e.key) {
          case 'ArrowDown':
            if (liSelected.current) {
              const next =
                liSelected.current.nextElementSibling?.nextElementSibling;
              if (next) {
                liSelected.current = next;
              }
            } else {
              liSelected.current = li[0];
            }
            break;
          case 'ArrowUp':
            if (liSelected.current) {
              const prev =
                liSelected.current.previousElementSibling
                  ?.previousElementSibling;
              if (prev) {
                liSelected.current = prev;
              }
            } else {
              liSelected.current = li[0];
            }
            break;
          case 'Enter':
            e.preventDefault();
            if (liSelected.current) {
              liSelected.current.click();
            }
            break;
          default:
            break;
        }

        if (liSelected.current) {
          liSelected.current.classList.add('selected');
        }
      }}
    >
      {!!filteredUnitsOrPersonnel.length && (
        <EventManager
          element={document}
          event="mousedown"
          handler={(event) => {
            if (
              event.target.classList.contains('tag-select-element') ||
              event.target.parentElement.classList.contains(
                'tag-select-element'
              ) ||
              event.target.parentElement.parentElement.classList.contains(
                'tag-select-element'
              )
            ) {
              return;
            }
            if (
              wrapperRef.current &&
              !wrapperRef.current.contains(event.target)
            ) {
              setText('');
            }
          }}
        />
      )}
      <div className={getClassNames({ 'dot-tag-select': true }, theme)}>
        <input
          className={getClassNames({ touched })}
          placeholder={placeholder}
          type="text"
          value={text}
          onChange={textChanged}
          readOnly={readOnly}
          required={required}
          onClick={clicked}
          disabled={disabled}
        >
          {children}
        </input>
        <label>
          {label}
          {required ? ' *' : ''}
        </label>
      </div>
      {!!value?.length && (
        <div className="tags">
          {value.map((unit) =>
            unit.type === 'user' ? (
              <div key={unit.id} className="tag">
                <Avatar entity={unit} size="1.75rem" />
                <FontAwesomeIcon
                  className="unit-icon"
                  icon="user"
                  width="8px"
                  height="8px"
                />
                <span>{displayName(unit)}</span>
                {canRemove && (
                  <DotButton
                    className="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      removeUnitOrPersonnel(unit);
                    }}
                    ariaLabel="Remove"
                  >
                    <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
                  </DotButton>
                )}
              </div>
            ) : (
              <div key={unit.id} className="tag">
                <Avatar entity={unit} size="1.75rem" />
                <FontAwesomeIcon className="unit-icon" icon="project-diagram" />
                <span>{unit.team}</span>
                {canRemove && (
                  <DotButton
                    className="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      removeUnitOrPersonnel(unit);
                    }}
                    ariaLabel="Remove"
                  >
                    <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
                  </DotButton>
                )}
              </div>
            )
          )}
        </div>
      )}

      {portalUnits}
    </div>
  );
};

TagSelect.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.any),
  setValue: PropTypes.func,
  selectUnitOrPersonnel: PropTypes.func.isRequired,
  removeUnitOrPersonnel: PropTypes.func.isRequired,
  children: PropTypes.any,
  theme: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  canRemove: PropTypes.bool,
  missionId: PropTypes.string,
};
