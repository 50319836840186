/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useRef } from 'react';
import { useState } from 'react';
import austacLogo from '../../assets/svg/austac-logo.svg';
import { appConfig } from '../../config/appConfig';
import { endpointConfig } from '../../config/endpoint_config';
import { secrets } from '../../config/secrets';
import { getClassNames } from '../../utils/string';
import { DotButton } from '../common/buttons/DotButton';
import { DarkScrollbar } from '../common/Scrollbars';
import './ContentPage.scss';
import toTop from '../../assets/svg/to-top.svg';
import bgShape from '../../assets/svg/dot-bg-shape.svg';

/**
 * static content Component
 *
 * @param {Jsx} children child elements
 */
export const ContentPage = ({ children, history, className }) => {
  const sb = useRef();
  const [showTop, setShowTop] = useState(false);

  return (
    <div className={getClassNames({ 'content-page': true }, className)}>
      <DarkScrollbar
        ref={sb}
        onUpdate={(e) => {
          setShowTop(!!e.scrollTop);
        }}
      >
        <ContentMenu history={history} />
        <section>{children}</section>{' '}
        <ContentFoot
          history={history}
          showTop={showTop}
          scrollToTop={() => {
            sb.current.scrollToTop();
          }}
        />
      </DarkScrollbar>
    </div>
  );
};

ContentPage.propTypes = {
  children: PropTypes.any,
};

export const ContentMenu = ({ history, about, why }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="content-menu desktop">
        <div className="left">
          <div
            className="logo"
            onClick={() => history.push(endpointConfig.frontendEndpoints.home)}
          >
            <img src={austacLogo} alt="Logo" />
          </div>
          <DotButton
            onClick={() => {
              if (about) {
                about();
              } else {
                history.push(endpointConfig.frontendEndpoints.home, {
                  target: 'about',
                });
              }
            }}
          >
            About {appConfig.name}
          </DotButton>
          <DotButton
            onClick={() => {
              if (why) {
                why();
              } else {
                history.push(endpointConfig.frontendEndpoints.home, {
                  target: 'why',
                });
              }
            }}
          >
            Why {appConfig.name}?
          </DotButton>
        </div>
        <div className="right">
          <DotButton
            onClick={() =>
              history.push(endpointConfig.frontendEndpoints.missionNav)
            }
          >
            Login
          </DotButton>
          <a
            href={`mailto:${secrets.ENQUIRY_EMAIL}?subject=${appConfig.enquirySubject}&body=${appConfig.enquiryBody}`}
            className="secondary"
          >
            Enquire Now
          </a>
        </div>
      </div>
      {isOpen && (
        <div className="mobile-menu">
          <div>
            <div className="shape">
              <img src={bgShape} alt="bg shape" />
            </div>
            <DotButton
              onClick={() => {
                history.push(endpointConfig.frontendEndpoints.home);
                setIsOpen(false);
              }}
            >
              Home
            </DotButton>
            <DotButton
              onClick={() => {
                if (about) {
                  about();
                } else {
                  history.push(endpointConfig.frontendEndpoints.home, {
                    target: 'about',
                  });
                }
                setIsOpen(false);
              }}
            >
              About {appConfig.name}
            </DotButton>
            <DotButton
              onClick={() => {
                if (why) {
                  why();
                } else {
                  history.push(endpointConfig.frontendEndpoints.home, {
                    target: 'why',
                  });
                }
                setIsOpen(false);
              }}
            >
              Why {appConfig.name}?
            </DotButton>
            <a
              href={`mailto:${secrets.ENQUIRY_EMAIL}?subject=${appConfig.enquirySubject}&body=${appConfig.enquiryBody}`}
              onClick={() => {
                setIsOpen(false);
              }}
              className="secondary"
            >
              Enquire Now
            </a>
          </div>
        </div>
      )}

      <div className="content-menu mobile">
        <div className="left">
          <div className="logo" onClick={() => setIsOpen(true)}>
            <img src={austacLogo} alt="Logo" />
          </div>
        </div>

        <div className="right">
          <DotButton
            onClick={() => setIsOpen(!isOpen)}
            ariaLabel="{isOpen ? 'Close Menu' : 'Open Menu'}"
          >
            <FontAwesomeIcon
              icon={isOpen ? 'times' : 'bars'}
              style={{
                height: '1.5rem',
                width: '1.5rem',
              }}
            />
          </DotButton>
        </div>
      </div>
    </>
  );
};

export const ContentFoot = ({ history, showTop, scrollToTop }) => {
  return (
    <>
      <div className="content-foot">
        <div className="logo" onClick={() => scrollToTop()}>
          <img src={austacLogo} alt="Logo" />
        </div>
        <DotButton
          onClick={() =>
            history.push(endpointConfig.frontendEndpoints.privacyPolicy)
          }
        >
          Privacy Policy
        </DotButton>
        <DotButton
          onClick={() =>
            history.push(endpointConfig.frontendEndpoints.termsOfService)
          }
        >
          Terms of Service
        </DotButton>
        <span>&copy; Copyright 2021</span>
      </div>

      {showTop && (
        <div
          className="to-top"
          onClick={() => {
            scrollToTop();
          }}
        >
          <img src={toTop} alt="back to top" />
        </div>
      )}
    </>
  );
};
