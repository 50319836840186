/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import log from 'loglevel';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getUsersInRole } from '../../../api/roles';
import { useUser } from '../../../context/UserContext';
import {
  groupType,
  organisationType,
  orgRoleType,
  userType,
} from '../../../enums/propTypes';
import { cancellablePromise } from '../../../utils/promise';
import { displayName, getClassNames } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';

/**
 * Mission Create Sidebar - show mission unit/group
 *
 * @param {string} missionId mission id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any[]} teams all teams involved in mission
 * @param {any} unit selected unit/group
 */
export const UnitInformation = ({ closeSidebar, missionId, teams, unit }) => {
  // eslint-disable-next-line
  const userValue = useUser();

  const [personnel, setPersonnel] = useState(false);
  const [user, setUser] = useState();

  const [users, setUsers] = useState([]);

  const [unitType, setUnitType] = useState('unit');

  useEffect(() => {
    const stub = async () => {
      if (unit) {
        log.debug('unit', unit);

        const groupUsers = await getUsersInRole(unit.organisationId, unit.id);
        setUsers(groupUsers);
      }
    };

    if (unit?.firstname) {
      setUnitType('personnel');
    }

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
  }, [unit]);

  const userTeams = teams.filter((t) => t.users.includes(user?.id || unit?.id));

  const createPanel = () => {
    if (user) {
      return <Personnel teams={userTeams} unit={unit} user={user} />;
    } else if (personnel) {
      return (
        <PersonnelList teams={userTeams} users={users} setUser={setUser} />
      );
    }
    return (
      <SelectedUnit
        teams={userTeams}
        unit={unit}
        setPersonnel={setPersonnel}
        users={users}
        unitType={unitType}
      />
    );
  };

  const exit = () => {
    if (user) {
      setUser(null);
    } else if (personnel) {
      setPersonnel(false);
    } else {
      closeSidebar();
    }
  };

  let title = 'Role Information';
  if (user) {
    title = 'User Information';
  } else if (personnel) {
    title = 'Personnel List';
  }

  if (unitType === 'personnel') {
    title = 'User Information';
  }

  const showBack = !!user || !!personnel;

  return (
    <div className="mission-sidebar">
      <div className="top">
        <div className="heading">
          <div className="text">{title}</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>

      {showBack && (
        <DotButton className="back" onClick={exit} ariaLabel="Back">
          <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
        </DotButton>
      )}

      {createPanel()}
    </div>
  );
};

UnitInformation.propTypes = {
  closeSidebar: PropTypes.func,
  missionId: PropTypes.string,
  missionOrgs: PropTypes.arrayOf(organisationType),
  unit: groupType,
};

/**
 * Selected unit/group - in mission create
 *
 * @param {any} teams teams
 * @param {any} unit unit/personnel
 * @param {Function} setPersonnel drill down to personnel
 * @param {any[]} users users in the group
 * @param {'personnel' || 'unit'} unitType group or personnel
 * @param {any[]} childGroups group's sub-groups if unit type is unit
 */
export const SelectedUnit = ({
  teams,
  unit,
  setPersonnel,
  users,
  unitType,
}) => {
  return (
    <LightScrollbar className="content">
      <div className="blocks">
        <div className="item-container has-border">
          <div>
            <h5>{unitType === 'personnel' ? 'User Name' : 'Role Name'}</h5>
            <p>{displayName(unit)}</p>
          </div>
          <Avatar entity={unit} />
        </div>

        <div className="item-container">
          <div>
            <h5>Role</h5>
            <p>{unit?.role?.role || 'No Role'}</p>
          </div>
          <Avatar
            entity={unit?.role?.role ? unit?.role : { role: 'No Role' }}
          />
        </div>

        {!!teams?.length &&
          teams.map((t, idx) => (
            <div key={idx} className="item-container">
              <div>
                <h5>Team</h5>
                <p>{t.team}</p>
              </div>
              <Avatar entity={t} />
            </div>
          ))}

        {/* <div className="item-container">
          <h5>Status</h5>
          <p>
            {teams?.length > 1
              ? 'In multiple teams'
              : teams?.length
              ? 'In a team'
              : 'In the mission'}
          </p>
        </div> */}

        {unitType !== 'personnel' && (
          <PersonnelGroup
            count={users.length}
            onClick={() => {
              setPersonnel(true);
            }}
          />
        )}
      </div>
    </LightScrollbar>
  );
};

SelectedUnit.propTypes = {
  organisation: organisationType,
  unit: PropTypes.oneOfType([userType, groupType]),
  setPersonnel: PropTypes.func,
  users: PropTypes.arrayOf(userType),
  unitType: PropTypes.string,
  childGroups: PropTypes.arrayOf(groupType),
};

/**
 * List of personnel
 *
 * @param {any} organisation organisation info
 * @param {any[]} users users list
 * @param {any[]} roles roles list
 * @param {Function} setUser drill down to personnel/user
 * @param {Function} openChatThread callback to chat with user
 */
export const PersonnelList = ({
  organisation,
  users,
  setUser,
  openChatThread,
  roles,
}) => {
  const [searchText, setSearchText] = useState('');

  const searchUsers = (e) => {
    setSearchText(e.target.value);
  };

  let filteredUsers = [];
  if (users?.length) {
    filteredUsers = users.filter(
      (u) =>
        (u.firstname + ' ' + u.lastname)
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) !== -1
    );
  }

  return (
    <>
      <div
        className={getClassNames({
          top: true,
          'has-border': true,
          'has-back': true,
        })}
        style={{ padding: '20px 0' }}
      >
        <SearchInput
          placeholder="Search users..."
          onChange={searchUsers}
          light
        />
      </div>
      <h5 style={{ marginTop: '20px' }}>
        {pluralize('user', filteredUsers?.length || 0, true)} listed
      </h5>
      <LightScrollbar className="user-content" noScrollX scroll>
        <div
          className="blocks"
          style={{ marginLeft: '-20px', marginRight: '-20px' }}
        >
          {filteredUsers.map((user) => (
            <PersonnelItem
              key={user.id}
              user={user}
              onClick={() => {
                setUser(user);
              }}
              openChatThread={openChatThread}
              roles={roles}
            />
          ))}
        </div>
      </LightScrollbar>
    </>
  );
};

PersonnelList.propTypes = {
  organisation: organisationType,
  users: PropTypes.arrayOf(userType),
  roles: PropTypes.arrayOf(orgRoleType),
  setUser: PropTypes.func,
};

/**
 * Personnel info
 *
 * @param {any} user user/personnel
 * @param {any} role role
 * @param {any} team team
 */
export const Personnel = ({ user, role, teams }) => {
  return (
    <LightScrollbar className="content">
      <div className="blocks">
        {!!role && (
          <div className="item-container">
            <div>
              <h5>Role</h5>
              <p>{role.role}</p>
            </div>
            <Avatar entity={role} />
          </div>
        )}

        {teams?.map((team) => (
          <div className="item-container">
            <div>
              <h5>Team</h5>
              <p>{team.team}</p>
            </div>
            <Avatar entity={team} />
          </div>
        ))}

        <hr />

        <div className="item-container">
          <div>
            <h5>USER NAME</h5>
            <p>{displayName(user)}</p>
          </div>
          <Avatar entity={user} />
        </div>
      </div>
    </LightScrollbar>
  );
};

Personnel.propTypes = {
  organisation: organisationType,
  user: userType,
  unit: groupType,
};

/**
 * Personnel count info
 *
 * @param {number} count number of personnel
 * @param {Function} onClick click callback
 */
const PersonnelGroup = ({ count, onClick }) => {
  return (
    <div
      className="item-container"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <div className="img">
        <FontAwesomeIcon
          icon="user"
          style={{ height: '24px', width: '24px' }}
        ></FontAwesomeIcon>
      </div>
      <div className="text">
        {count} <span>Users</span>
      </div>
      <DotButton className="more">
        <FontAwesomeIcon
          icon="chevron-right"
          style={{ height: '14px', width: '14px' }}
        />
      </DotButton>
    </div>
  );
};

PersonnelGroup.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
};

/**
 * Personnel  info
 *
 * @param {any} user number of personnel
 * @param {Function} onClick click callback
 * @param {Function} openChatThread open chat thread
 * @param {any[]} roles roles list
 */
const PersonnelItem = ({ user, onClick, openChatThread, roles }) => {
  const role = roles.find((r) => r.id === user.roleId);

  return (
    <div
      className="item-container has-hover"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <div className="img">
        <Avatar entity={user} />
      </div>
      <div className="text">
        {displayName(user)}
        {!!role && <span>{role.role}</span>}
      </div>
      {!!openChatThread && (
        <DotButton
          ariaLabel="Chat with Team"
          onClick={async (e) => {
            await openChatThread(user, null);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon icon="comment-alt" />
        </DotButton>
      )}
      <DotButton className="more">
        <FontAwesomeIcon
          icon="chevron-right"
          style={{ height: '14px', width: '14px' }}
        />
      </DotButton>
    </div>
  );
};

PersonnelItem.propTypes = {
  user: userType,
  onClick: PropTypes.func,
  openChatThread: PropTypes.func,
  roles: PropTypes.arrayOf(orgRoleType),
};
