/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FeatureType } from '../enums/featureType';
import { GeofenceLevel } from '../enums/geofenceLevel';

export const MAP_COLOUR_MISSION_AREA = '#3B9FC2';
export const MAP_COLOUR_GEOFENCE_ADVISORY = '#505366'; // grey-2
export const MAP_COLOUR_GEOFENCE_CAUTION = '#ffd43a'; // urgent-status-a
export const MAP_COLOUR_GEOFENCE_WARNING = '#f66f6f';

export const getColour = (type, alertLevel = 'advisory') => {
  switch (type) {
    case FeatureType.Mission:
      return MAP_COLOUR_MISSION_AREA;
    case FeatureType.Geofence:
      switch (alertLevel) {
        case GeofenceLevel.Warning:
          return MAP_COLOUR_GEOFENCE_WARNING;
        case GeofenceLevel.Caution:
          return MAP_COLOUR_GEOFENCE_CAUTION;
        default:
          return MAP_COLOUR_GEOFENCE_ADVISORY;
      }
    case FeatureType.Annotation:
      return MAP_COLOUR_GEOFENCE_ADVISORY;
    default:
      return type === 'mission-'
        ? MAP_COLOUR_MISSION_AREA
        : MAP_COLOUR_GEOFENCE_ADVISORY;
  }
};

/**
 * User properties are available iff MapBox draw was created with userProperties: true
 * userProperties, boolean (default: false): properties of a feature will also be available
 * for styling and prefixed with user_, e.g., ['==', 'user_custom_label', 'Example']
 *
 * https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md
 */
export const getDrawStyles = (color) => {
  return [
    {
      id: 'gl-draw-polygon-fill-inactive',
      type: 'fill',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'fill-color': ['coalesce', ['get', 'user_color'], color],
        'fill-outline-color': ['coalesce', ['get', 'user_color'], color],
        'fill-opacity': 0.2,
      },
    },
    {
      id: 'gl-draw-polygon-fill-active',
      type: 'fill',
      filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      paint: {
        'fill-color': ['coalesce', ['get', 'user_color'], color],
        'fill-outline-color': ['coalesce', ['get', 'user_color'], color],
        'fill-opacity': 0.2,
      },
    },
    {
      id: 'gl-draw-polygon-midpoint',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
      paint: {
        'circle-radius': 3,
        'circle-color': color,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': ['coalesce', ['get', 'user_color'], color],
        'line-width': 3,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-active',
      type: 'line',
      filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': ['coalesce', ['get', 'user_color'], color],
        'line-dasharray': [0.2, 2],
        'line-width': 3,
      },
    },
    {
      id: 'gl-draw-line-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'LineString'],
        ['!=', 'mode', 'static'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': ['coalesce', ['get', 'user_color'], color],
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-line-active',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': ['coalesce', ['get', 'user_color'], color],
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 6,
        'circle-color': '#fff',
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': color,
      },
    },
    {
      id: 'gl-draw-point-point-stroke-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-opacity': 1,
        'circle-color': '#fff',
      },
    },
    {
      id: 'gl-draw-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': ['coalesce', ['get', 'user_color'], color],
      },
    },
    {
      id: 'gl-draw-point-stroke-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'active', 'true'],
        ['!=', 'meta', 'midpoint'],
      ],
      paint: {
        'circle-radius': 7,
        'circle-color': '#fff',
      },
    },
    {
      id: 'gl-draw-point-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': ['coalesce', ['get', 'user_color'], color],
      },
    },
    {
      id: 'gl-draw-polygon-fill-static',
      type: 'fill',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      paint: {
        'fill-color': '#404040',
        'fill-outline-color': '#404040',
        'fill-opacity': 0.2,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-static',
      type: 'line',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#404040',
        'line-width': 3,
      },
    },
    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#404040',
        'line-width': 3,
      },
    },
    {
      id: 'gl-draw-point-static',
      type: 'circle',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
      paint: {
        'circle-radius': 5,
        'circle-color': '#404040',
      },
    },
    // rotate styles
    {
      id: 'gl-draw-line-rotate-point',
      type: 'line',
      filter: [
        'all',
        ['==', 'meta', 'midpoint'],
        ['==', 'icon', 'rotate'],
        ['==', '$type', 'LineString'],
        ['!=', 'mode', 'static'],
        // ['==', 'active', 'true']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': ['coalesce', ['get', 'user_color'], color],
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-polygon-rotate-point-stroke',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'midpoint'],
        ['==', 'icon', 'rotate'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 4,
        'circle-color': '#fff',
      },
    },
    {
      id: 'gl-draw-polygon-rotate-point',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'midpoint'],
        ['==', 'icon', 'rotate'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 2,
        'circle-color': ['coalesce', ['get', 'user_color'], color],
      },
    },
    {
      id: 'gl-draw-polygon-rotate-point-icon',
      type: 'symbol',
      filter: [
        'all',
        ['==', 'meta', 'midpoint'],
        ['==', 'icon', 'rotate'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      layout: {
        'icon-image': 'rotate',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-rotation-alignment': 'map',
        'icon-rotate': ['get', 'heading'],
      },
      paint: {
        'icon-opacity': 1.0,
        'icon-opacity-transition': {
          delay: 0,
          duration: 0,
        },
      },
    },
  ];
};
