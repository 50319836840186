/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import badge from '../../assets/svg/dot-badge.svg';
import { endpointConfig } from '../../config/endpoint_config';
import { secrets } from '../../config/secrets';
import { useNotification } from '../../context/NotificationContext';
import { useSidebar } from '../../context/SidebarContext';
import { useUser } from '../../context/UserContext';
import { historyType } from '../../enums/propTypes';
import { releases } from '../../release';
import { getClassNames } from '../../utils/string';
import { DotButton } from './buttons/DotButton';
import { useJoyride } from '../../context/JoyrideContext';
import { Role } from '../../enums/role';
import './Navbar.scss';
import { Tours } from '../../enums/tours';

/**
 * Left SideNav bar
 *
 * @param {Jsx} children child elements
 * @param {any} history react router
 * @param {Boolean} showBadge show bad
 * @param {Boolean} showOnBack show back button
 * @param {Function} onBack
 * @param {'missions' || 'org' || 'map-styles' || 'create-mission' || 'view-mission' || 'org-users'} activeRoute active route
 */
export const NavBar = withRouter(
  ({
    children,
    history,
    activeRoute,
    showBadge = true,
    showOnBack = true,
    onBack,
  }) => {
    const { missionId } = useParams();
    const _notifications = useNotification();
    const notifications = _notifications.notifications;

    const userValue = useUser();

    const { closeAll } = useSidebar();

    useEffect(() => {
      _notifications.setCurrentMissionId(missionId);
    }, [_notifications, missionId]);

    const joyride = useJoyride();

    const isSysAdmin = () => {
      return (
        userValue?.user?.accessRoles?.length &&
        userValue?.user?.accessRoles.includes(Role.SysAdmin)
      );
    };

    return (
      <>
        <div className="app-sidebar">
          <div className="top">
            {showBadge && (
              <DotButton
                className="home"
                onClick={() => {
                  closeAll();
                  history.push(endpointConfig.frontendEndpoints.missionNav);
                }}
                ariaLabel="AUSTAC Logo"
              >
                <img src={badge} alt={'DOT'} />
              </DotButton>
            )}
            <DotButton
              className={getClassNames({
                home: true,
                active: activeRoute === 'missions',
              })}
              onClick={() => {
                closeAll();
                history.push(endpointConfig.frontendEndpoints.missionNav);
              }}
            >
              <FontAwesomeIcon icon={'home'} />
              <span>Home</span>
            </DotButton>
          </div>
          <div className="middle">{children}</div>
          <div
            className={getClassNames({
              bottom: true,
            })}
          >
            {!isSysAdmin() &&
            (activeRoute === 'missions' ||
              activeRoute === 'view-mission' ||
              activeRoute === 'create-mission' ||
              activeRoute === 'org-users') ? (
              <DotButton
                className={getClassNames(
                  {
                    active: _notifications.showNotifications,
                  },
                  'step-8'
                )}
                onClick={() => {
                  if (activeRoute === 'missions') {
                    joyride.setTour(Tours.Home);
                    joyride.setStepIndex(1);
                    joyride.setRunning(true);
                  } else if (activeRoute === 'view-mission') {
                    joyride.setTour(Tours.Map);
                    joyride.setStepIndex(1);
                    joyride.setRunning(true);
                  } else if (activeRoute === 'create-mission') {
                    joyride.setTour(Tours.CreateMission);
                    joyride.setStepIndex(1);
                    joyride.setRunning(true);
                  } else if (activeRoute === 'org-users') {
                    joyride.setTour(Tours.Users);
                    joyride.setStepIndex(1);
                    joyride.setRunning(true);
                  }
                }}
                tip="Take The Tour"
              >
                <FontAwesomeIcon icon={'question-circle'} />
              </DotButton>
            ) : null}
            <DotButton
              className={getClassNames(
                {
                  active: _notifications.showNotifications,
                },
                'step-7'
              )}
              onClick={() => {
                _notifications.openNotifications(missionId || 'all');
              }}
              badge={notifications?.filter((n) => !n.read)?.length}
              tip="Notifications"
            >
              <FontAwesomeIcon icon={'bell'} />
            </DotButton>
            <div
              onClick={() => {
                history.push(endpointConfig.frontendEndpoints.versions);
              }}
              className="app-version"
            >
              v{Object.keys(releases)[0]} {secrets.ENVIRONMENT}
            </div>
          </div>
        </div>
      </>
    );
  }
);

NavBar.propTypes = {
  children: PropTypes.any,
  activeRoute: PropTypes.oneOf([
    'missions',
    'org',
    'map-styles',
    'map-view',
    'create-mission',
    'view-mission',
    'org-users',
  ]),
  history: historyType,
  showBadge: PropTypes.bool,
  showOnBack: PropTypes.bool,
  onBack: PropTypes.func,
};
