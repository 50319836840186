// External Modules
// Icons
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validateMissionInvitation } from '../../../../api/missions';
import { historyType } from '../../../../enums/propTypes';
import { cancellablePromise } from '../../../../utils/promise';
// Internal Modules
import { Welcome } from '../../../generic-components/Welcome';
// Style Sheets
import './Invitation.scss';

/**
 * Accept mission invitiation page
 *
 * @param {any} history react router
 */
export const ExpiredInvitation = ({ history }) => {
  const [invite, setInvite] = useState();

  const { id } = useParams();

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(verifyInvitation(id));
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  const verifyInvitation = async (id) => {
    log.debug('verifying', id);
    try {
      const res = await validateMissionInvitation(id);
      setInvite(res);
    } catch (err) {
      setInvite(null);
      // Debug line
      log.error(`Something went wrong with invitation validation`, err);
    }
  };

  return (
    <Welcome>
      <div className="invitation">
        {!invite ? (
          <div className="invitation-form">
            <div className="invitation-text">
              <div className="invitation-text-1">
                Your invitation to join {invite?.mission?.missionName} is no
                longer valid.
              </div>
              <div className="invitation-text-3">
                If you wish to join the mission, please get in contact with{' '}
                <strong>{invite?.senderOrganisation?.organisationName}</strong>.
              </div>
            </div>
          </div>
        ) : (
          <div> Invalid invitation</div>
        )}
      </div>
    </Welcome>
  );
};

ExpiredInvitation.propTypes = { history: historyType };
