/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { appConfig } from '../../config/appConfig';
import { endpointConfig } from '../../config/endpoint_config';
import { useUser, verifyUserAsync } from '../../context/UserContext';
import { historyType } from '../../enums/propTypes';
import { loginSubmission } from '../../utils/auth';
import { validateEmail } from '../../utils/string';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { Welcome } from '../generic-components/Welcome';

/**
 * Login page
 *
 * @param {any} history react router
 */
export const Login = ({ history }) => {
  const userValue = useUser();

  // Login details
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (userValue.user?.id) {
      history.push(endpointConfig.frontendEndpoints.missionNav);
    }
    // eslint-disable-next-line
  }, [userValue.user]);

  // Handles errors and animations around loggin in
  // Actual login functionality is handled by
  // utils/auth/loginSubmission function
  const loginSubmissionHandler = async (e) => {
    e.preventDefault();

    // Input validation
    if (!validateEmail(email)) {
      toast.error('Email is not valid.');
      setPassword('');
      return;
    } else if (password === '') {
      toast.error('Email or password is incorrect.');
      setPassword('');
      return;
    }

    // Login
    const res = await loginSubmission(email, password, {
      func: verifyUserAsync,
      params: userValue.setUser,
    });
    if (res === false) {
      toast.error('Email or password is incorrect.');
      setPassword('');
    }
  };

  return (
    <Welcome>
      <div className="login-title">Log in to {appConfig.name}</div>
      <form>
        <Input
          label="Email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required={true}
        />
        <Input
          label="Password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          required={true}
        />
        <DotButton
          type="submit"
          onClick={loginSubmissionHandler}
          className="primary block"
        >
          Log In
        </DotButton>
      </form>
      <div className="forgot-signup">
        <button
          className="link forgot-button is-pulled-left"
          onClick={() => {
            history.push(endpointConfig.frontendEndpoints.forgotPassword);
          }}
        >
          I've forgotten my password
        </button>
      </div>
    </Welcome>
  );
};

Login.propTypes = { history: historyType };
