/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import fileSize from 'filesize';
import { getMissionInvitation } from '../api/missions';
import { fileConfig } from '../config/fileConfig';
import { saveAs } from 'file-saver';

/**
 * Get icon for the file type of a file.
 *
 * @param {*} attachment attachment from file input
 * @return {string} Icon
 */
export const getFileIcon = (attachment) => {
  const contentType =
    attachment.contentType ||
    attachment.mimeType ||
    attachment.mimetype ||
    attachment.type ||
    '';
  const filename = attachment.filename || attachment.name;

  let icon = 'paperclip';
  if (contentType === 'application/pdf' || filename?.endsWith('.pdf')) {
    icon = 'file-pdf';
  } else if (
    contentType === 'application/vnd.ms-powerpoint' ||
    contentType ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
    filename?.endsWith('.ppt') ||
    filename?.endsWith('.pptx')
  ) {
    icon = 'file-powerpoint';
  } else if (
    contentType === 'application/msword' ||
    contentType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    filename?.endsWith('.doc') ||
    filename?.endsWith('.docx')
  ) {
    icon = 'file-word';
  } else if (
    contentType === 'application/vnd.ms-excel' ||
    contentType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    filename?.endsWith('.xls') ||
    filename?.endsWith('.xlsx')
  ) {
    icon = 'file-excel';
  } else if (contentType === 'text/csv' || filename?.endsWith('.csv')) {
    icon = 'file-csv';
  } else if (
    contentType?.startsWith('image/') ||
    filename?.endsWith('.jpg') ||
    filename?.endsWith('.jpeg') ||
    filename?.endsWith('.gif') ||
    filename?.endsWith('.png') ||
    filename?.endsWith('.bmp') ||
    filename?.endsWith('.svg') ||
    filename?.endsWith('.tif') ||
    filename?.endsWith('.tiff') ||
    filename?.endsWith('.webp')
  ) {
    icon = 'file-image';
  } else if (
    contentType.startsWith('video/') ||
    filename?.endsWith('.3gpp') ||
    filename?.endsWith('.3gpp2') ||
    filename?.endsWith('.avi') ||
    filename?.endsWith('.mp4') ||
    filename?.endsWith('.mpg') ||
    filename?.endsWith('.mpeg') ||
    filename?.endsWith('.mkv') ||
    filename?.endsWith('.mov') ||
    filename?.endsWith('.mp2t') ||
    filename?.endsWith('.ogv') ||
    filename?.endsWith('.ts') ||
    filename?.endsWith('.webm')
  ) {
    icon = 'file-video';
  } else if (
    contentType.startsWith('audio/') ||
    filename?.endsWith('.aac') ||
    filename?.endsWith('.midi') ||
    filename?.endsWith('.mid') ||
    filename?.endsWith('.mp3') ||
    filename?.endsWith('.oga') ||
    filename?.endsWith('.opus') ||
    filename?.endsWith('.wav') ||
    filename?.endsWith('.weba') ||
    filename?.endsWith('.3gpp') ||
    filename?.endsWith('.3gpp2')
  ) {
    icon = 'file-audio';
  }
  return icon;
};

/**
 * Validate a file. At the moment we just check for max file size.
 *
 * @param {*} file file from file input
 * @return {string} null iff valid, error string iff invalid
 */
export const validateFile = (file) => {
  if (file.size <= fileConfig.maxFileSize) {
    return null;
  }
  return `${fileSize(fileConfig.maxFileSize)} file limit exceeded:
"${file.name}"`;
};

export const downloadInvitation = async (id) => {
  const pdfData = await getMissionInvitation(id);
  saveAs(pdfData, 'invitation.pdf');
};
