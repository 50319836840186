/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import * as log from 'loglevel';
import React, { useCallback, useEffect, useState } from 'react';
import { updatePointOfInterest } from '../../../api/missions';
import { getMissionPersonnel } from '../../../api/orgs_users';
import { useUser } from '../../../context/UserContext';
import { locationType } from '../../../enums/propTypes';
import { canDeletePoi } from '../../../utils/auth';
import { handleError } from '../../../utils/error';
import { toLngLat, toLongitudeLatitude } from '../../../utils/geo';
import { cancellablePromise, wait } from '../../../utils/promise';
import { displayName } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { Input } from '../../common/Input';
import { LightScrollbar } from '../../common/Scrollbars';
import { TextArea } from '../../common/TextArea';
import { LocationInfo } from './LocationInfo';
import './MissionSidebar.scss';
import { PropTypes } from 'prop-types';
import { POI } from '../../../enums/poi';
import { useSidebar } from '../../../context/SidebarContext';
import { useEvent } from '../../../context/EventContext';
import { Signal } from '../../../enums/signal';
import toast from 'react-hot-toast';
import badge from '../../../assets/svg/dot-badge.svg';

/**
 * Sidebar - show waypoint info
 *
 * @param {Function} closeSidebar callback to close sidebar
 * @param {string} missionId mission id
 * @param {any} waypoint POI data
 * @param {any} location location data
 * @param {Boolean} isOwner is owner of this symbol
 * @param {Function} setUnit opens personnel sidebar
 * @param {Function} refreshWaypoints refresh POIs
 * @param {Function} setPoiToDelete delete poi - opens modal
 * @param {object} searchResult result of searching for a location
 */
export const MissionWaypoint = ({
  closeSidebar,
  missionId,
  waypoint,
  location,
  isOwner,
  setUnit,
  refreshWaypoints,
  setPoiToDelete,
  searchResult,
}) => {
  const userValue = useUser();

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const sidebars = useSidebar();

  const [inputValues, setInputValues] = useState({
    reference: waypoint?.reference || '',
    name:
      !!!waypoint?.name &&
      searchResult?.geometry?.coordinates[0] === location?.lng &&
      searchResult?.geometry?.coordinates[1] === location?.lat
        ? searchResult?.place_name.substring(
            0,
            searchResult?.place_name.indexOf(',')
          )
        : waypoint?.name || '',
    description: waypoint?.description || '',
  });

  const [waypointLocation, setWaypointLocation] = useState(location);

  useEffect(() => {
    if (!!waypoint) {
      waypoint.coords = location;
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setLoading(true);
    const stub = async () => {
      if (waypoint && waypoint.userId) {
        const _user = await getMissionPersonnel(missionId, waypoint.userId);
        setUser(_user);
      } else if (!waypoint) {
        setUser(userValue.user);
      }
      setLoading(false);
    };

    log.debug('poi', waypoint);

    setInputValues({
      reference: waypoint?.reference || '',
      name:
        !!!waypoint?.name &&
        searchResult?.geometry?.coordinates[0] === location?.lng &&
        searchResult?.geometry?.coordinates[1] === location?.lat
          ? searchResult?.place_name.substring(
              0,
              searchResult?.place_name.indexOf(',')
            )
          : waypoint?.name || '',
      description: waypoint?.description || '',
    });

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [waypoint]);

  const exit = () => {
    closeSidebar();
  };

  let title = 'Create Waypoint';
  if (waypoint) {
    if (!isOwner) {
      title = 'Waypoint Details';
    } else {
      title = 'Edit Waypoint';
    }
  }

  const isFormValid = () => {
    return !!inputValues?.name && !!inputValues?.reference;
  };

  const submitChanges = useCallback(async () => {
    await wait(2000);
    const newValue = {
      ...waypoint,
      ...inputValues,
    };

    if (newValue.reference.length > 2) {
      newValue.reference = newValue.reference.substring(0, 2);
    }

    if (!newValue.coords) {
      newValue.coords = toLongitudeLatitude(waypointLocation);
    }
    if (!newValue.type) {
      newValue.type = POI.Waypoint;
    }
    newValue.missionId = missionId;

    await updatePointOfInterest(newValue).catch(handleError);
    await refreshWaypoints(waypoint);

    if (newValue.id) {
      toast.success('Waypoint updated', {
        id: 'poi-updated',
      });
    } else {
      toast.success('Waypoint created', {
        id: 'poi-updated',
      });
    }

    exit();
    // eslint-disable-next-line
  }, [inputValues, waypoint, waypointLocation]);

  useEvent(Signal.PoiUpdate, (poi) => {
    if (poi.id === waypoint.id) {
      setWaypointLocation(toLngLat(poi.location));
    }
  });

  return (
    <>
      {(loading || !user) && (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              animation: 'rotation 2s infinite linear',
              marginTop: '150px',
            }}
          >
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Loading details</div>
        </div>
      )}

      <div className="mission-sidebar">
        <div className="top">
          <div className="heading">
            <div className="text">{title}</div>
            <DotButton className="exit" onClick={exit} ariaLabel="Close">
              <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
            </DotButton>
          </div>
          {!!sidebars.previous && (
            <DotButton
              className="back"
              style={{ marginRight: '15px' }}
              onClick={() => {
                sidebars.open(sidebars.previous);
              }}
              ariaLabel="Back"
            >
              <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
            </DotButton>
          )}
        </div>
        <div className="content">
          <div className="blocks">
            {user?.user && (
              <div className="group">
                <h5>Created By</h5>
                <div
                  className="item-container"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (waypoint) {
                      setUnit(user?.user);
                    }
                  }}
                >
                  <Avatar entity={user?.user} />
                  <div style={{ flexGrow: 1 }}>
                    <h4>{displayName(user?.user)}</h4>
                    <p>{user?.role?.role}</p>
                  </div>
                  {!!waypoint && (
                    <DotButton>
                      <FontAwesomeIcon icon="chevron-right" />
                    </DotButton>
                  )}
                </div>
              </div>
            )}

            {!isOwner && (
              <div className="group">
                <h5>{'Waypoint Name'}</h5>
                <p>{waypoint.name}</p>
              </div>
            )}

            <div
              className="group"
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <h5>Date Created</h5>
                <p>
                  {(waypoint?._ts ? dayjs(waypoint._ts * 1000) : dayjs())
                    .format('h:mmA, D MMM YYYY')
                    .toUpperCase()}
                </p>
              </div>
              {isOwner && <span>* Indicates mandatory field</span>}
            </div>
            <hr />
            {!isOwner && (
              <>
                <div className="group">
                  <h5>Description</h5>
                  <p>{waypoint.description}</p>
                </div>
                <hr />
              </>
            )}
          </div>
        </div>
        <LightScrollbar className="content">
          {
            <>
              {isOwner && (
                <form style={{ marginTop: '1rem' }}>
                  <div className="controls">
                    <Input
                      label={'Waypoint Reference (Max. 2 characters)'}
                      placeholder={'Enter the waypoint reference'}
                      value={inputValues.reference}
                      onChange={(e) =>
                        setInputValues({
                          ...inputValues,
                          reference: e.currentTarget.value,
                        })
                      }
                      required={true}
                      maxLength={2}
                      onEnter={() => {
                        if (isFormValid()) {
                          submitChanges();
                        }
                      }}
                    />
                    <Input
                      label={'Waypoint Name'}
                      placeholder={'Enter the name of the waypoint'}
                      value={inputValues.name}
                      onChange={(e) =>
                        setInputValues({
                          ...inputValues,
                          name: e.currentTarget.value,
                        })
                      }
                      required={true}
                      onEnter={() => {
                        if (isFormValid()) {
                          submitChanges();
                        }
                      }}
                    />
                    <TextArea
                      label={'Waypoint Description'}
                      placeholder={
                        'The description that units will see when they view this waypoint.'
                      }
                      value={inputValues.description}
                      onChange={(e) =>
                        setInputValues({
                          ...inputValues,
                          description: e.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </form>
              )}
            </>
          }

          <LocationInfo
            title={'Waypoint Location'}
            location={waypointLocation}
            setLoading={setLoading}
          />
        </LightScrollbar>

        <div className="foot">
          {isOwner && (
            <DotButton
              className="primary block"
              onClick={submitChanges}
              disabled={!isFormValid()}
            >
              Save Changes
            </DotButton>
          )}
          {canDeletePoi(userValue.user, waypoint) && !!setPoiToDelete && (
            <DotButton
              className="secondary block"
              onClick={async () => {
                setPoiToDelete(waypoint);
              }}
            >
              {'Delete Waypoint'}
            </DotButton>
          )}
        </div>
      </div>
    </>
  );
};

MissionWaypoint.propTypes = {
  closeSidebar: PropTypes.func,
  missionId: PropTypes.string,
  waypoint: PropTypes.object,
  location: locationType,
  isOwner: PropTypes.bool,
  setUnit: PropTypes.func,
  refreshWaypoints: PropTypes.func,
  setPoiToDelete: PropTypes.func,
  searchResult: PropTypes.object,
  searchPinClicked: PropTypes.bool,
};
