/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { only } from '../utils';
import { del, get, post } from './global';

/**
 * Get org roles
 *
 * @param {string} organisationId org id
 * @return {any[]} roles
 */
export const getRoles = async (organisationId) => {
  return await get(`/organisations/${organisationId}/roles`);
};

/**
 * Upsert role
 *
 * @param {string} organisationId org id
 * @param {any} role data
 * @return {any} api response
 */
export const postRole = async (organisationId, role) => {
  if (role.id) {
    return await post(
      `/organisations/${organisationId}/roles/${role.id}`,
      only(role, ['role', 'organisationId', 'color'])
    );
  } else {
    return await post(`/organisations/${organisationId}/roles`, role);
  }
};

/**
 * Add users to role
 *
 * @param {string} organisationId org id
 * @param {string} roleId role id
 * @return {any[]} user data
 */
export const getUsersInRole = async (organisationId, roleId) => {
  return await get(`/organisations/${organisationId}/roles/${roleId}/users`);
};

/**
 * Add users to role
 *
 * @param {string} organisationId org id
 * @param {string} roleId role id
 * @param {string[]} users user ids
 * @return {any} api response
 */
export const addUsersToRole = async (organisationId, roleId, users) => {
  return await post(
    `/organisations/${organisationId}/roles/${roleId}/addusers`,
    users
  );
};

/**
 * Delete role
 *
 * @param {string} organisationId org id
 * @param {string} roleId role id
 * @return {any} api response
 */
export const deleteRole = async (organisationId, roleId) => {
  return await del(`/organisations/${organisationId}/roles/${roleId}`);
};
