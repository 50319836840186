/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// Styling
import { useRef, useState } from 'react';
import { getClassNames } from '../../utils/string';
import './TextArea.scss';
import { PropTypes } from 'prop-types';
import { v4 as uuid } from 'uuid';

/**
 * TextArea
 *
 * @param {string} label input label (form field set label)
 * @param {string} placeholder input placeholder text
 * @param {string} value input value
 * @param {EventHandler} onChange on text changed
 * @param {string} theme input theme
 * @param {Boolean} required input is required?
 * @param {Boolean} readOnly input is read only?
 * @param {Boolean} disabled input disabled?
 * @param {function} handleBlur callback to handle blur
 * @param {number} maxLength maximum character length if specified
 * @param {string} height height of the text area
 */
export const TextArea = ({
  label,
  placeholder,
  value,
  onChange,
  theme = '',
  required = false,
  readOnly = false,
  disabled = false,
  handleBlur,
  maxLength = null,
  height = '10rem',
}) => {
  const id = useRef(uuid());
  const [touched, setTouched] = useState(false);

  const clicked = (e) => {
    e.preventDefault();
    setTouched(true);
  };

  return (
    <div
      className={getClassNames({ 'text-area': true }, theme)}
      style={{ height: height }}
    >
      <textarea
        id={id.current}
        className={getClassNames({ touched })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        readOnly={readOnly}
        onClick={clicked}
        disabled={disabled || false}
        onBlur={handleBlur}
        maxLength={maxLength}
      />
      <label htmlFor={id.current}>
        {label}
        {required ? ' *' : ''}
      </label>
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  theme: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func,
  maxLength: PropTypes.number,
  height: PropTypes.string,
};
