/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { Avatar } from './Avatar';
import { Crop } from './Crop';
import './UploadCrop.scss';
import { DotButton } from './buttons/DotButton';

/**
 * Combined image uploader and cropper
 *
 * @param {string} photoUrl current image
 * @param {string} selectedImg selected image - ready to crop
 * @param {Function} setSelectedImg callback to set image to be cropped
 * @param {Function} setImage callback to set cropped image
 * @param {'user' || 'org' || ''} type image type
 * @param {string} fallback Avatar fallback to use if no image set
 */
export const UploadCrop = ({
  selectedImg,
  setSelectedImg,
  photoUrl,
  setImage,
  type,
  fallback,
}) => {
  const handleImage = (e) => {
    // convert image file to base64 string
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        setSelectedImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getType = (type) => {
    switch (type) {
      case 'org':
        return 'Organisation Image';
      case 'user':
        return 'Profile Picture';
      default:
        return 'Image';
    }
  };

  return (
    <>
      <div className="upload-crop">
        <div>
          <h4>{getType(type)}</h4>
          <label
            htmlFor="uploadCropImage"
            className="creat-org-upload-image-input"
          >
            Select image to upload
            <input id="uploadCropImage" type="file" onChange={handleImage} />
          </label>
        </div>
        <div className="current-photo">
          <Avatar photoUrl={photoUrl} fallback={fallback} />
          {!!photoUrl && (
            <DotButton
              className="link"
              onClick={() => {
                document.getElementById('uploadCropImage').value = null;
                setImage({
                  url: null,
                  blob: null,
                  deleteImage: true,
                });
              }}
            >
              Remove image
            </DotButton>
          )}
        </div>
      </div>

      {selectedImg && (
        <Crop close={setSelectedImg} img={selectedImg} setImage={setImage} />
      )}
    </>
  );
};
