/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { post } from './global';
import * as log from 'loglevel';

/**
 * Upload Image to cloud storage
 *
 * @param {string} type file category. 'user', 'organisation', 'organisationgroup', 'photo'
 * @param {Blob} blob Blob to upload
 * @return {any} API response
 */
export const postImage = async (type, blob) => {
  const formData = new FormData();
  formData.append('image', blob);

  log.debug('uploading', blob.size);
  return await post(`/utils/image/${type}`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

/**
 * Upload File to cloud storage
 *
 * @return {any} API response
 */
export const postFile = async (ep, blob) => {
  const formData = new FormData();
  formData.append('file', blob);
  log.debug('uploading', blob.size);
  return await post(ep, formData, {
    'Content-Type': 'multipart/form-data',
  });
};
