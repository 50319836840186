/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import criticalWhite from '../../assets/svg/critical-white.svg';
import { endpointConfig } from '../../config/endpoint_config';
import { useLocation } from '../../context/LocationContext';
import { useNotification } from '../../context/NotificationContext';
import { GeofenceLevel } from '../../enums/geofenceLevel';
import {
  notificationAction,
  NotificationStatus,
} from '../../utils/notifications';
import { getClassNames } from '../../utils/string';
import { secrets } from '../../config/secrets';

/**
 * Notifications banner. Displayed at top of UI when there are critical notifications. As this wraps all mission and map components it's also used to determine if
 * we're currently on a mission or map page.
 *
 * @param {Jsx} children child elements
 * @param {Boolean} isMission are we in a mission
 * @param {Boolean} isMap are we on a map
 * @param {any[]} notifications all notifications
 */
export const NotificationsBanner = ({
  children,
  isMission,
  isMap,
  notifications,
}) => {
  notifications = [];
  const { notificationHistory, lastMissionId } = useNotification();
  const locationServices = useLocation();

  const offlineNotifications = notifications.filter(
    (n) => n.status === NotificationStatus.offline
  );
  const trackedNotifications = notifications.filter(
    (n) =>
      locationServices.allowDesktopSharing &&
      n.status === NotificationStatus.tracked
  );
  const criticalNotifications = notifications.filter(
    (n) => n.status === NotificationStatus.critical
  );

  const warningNotifications = notifications.filter(
    (n) => n.status === GeofenceLevel.Warning
  );
  const cautionNotifications = notifications.filter(
    (n) => n.status === GeofenceLevel.Caution
  );
  const advisoryNotifications = notifications.filter(
    (n) => n.status === GeofenceLevel.Advisory
  );

  const getType = (n) => {
    if (!n) {
      return '';
    }
    switch (n.status) {
      case NotificationStatus.important:
        return 'important';
      case NotificationStatus.critical:
        return 'critical';
      case NotificationStatus.offline:
        return 'offline';
      case NotificationStatus.tracked:
        return 'tracked';
      case GeofenceLevel.Warning:
        return 'warning';
      case GeofenceLevel.Caution:
        return 'caution';
      case GeofenceLevel.Advisory:
        return 'advisory';
      default:
        return 'general';
    }
  };

  const getBannerMessage = () => {
    // if (offlineNotifications.length) {
    //   return (
    //     <>
    //       <div className="fa-layers fa-fw">
    //         <FontAwesomeIcon icon="wifi" />
    //         <FontAwesomeIcon icon="slash" />
    //       </div>
    //       <span>You are offline</span>
    //     </>
    //   );
    // } else if (trackedNotifications.length) {
    //   return (
    //     <>
    //       <div
    //         className="fade-in-out"
    //         style={{
    //           backgroundColor: 'green',
    //           width: '1.6rem',
    //           height: '1.6rem',
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //           borderRadius: '50%',
    //         }}
    //       >
    //         <svg
    //           viewBox="0 0 22 24"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="0.8em"
    //           height="0.8em"
    //         >
    //           <path
    //             d="M19.1002 0.164934L1.23464 9.16025C-0.826569 10.2098 -0.139498 13.5079 2.05921 13.5079H9.61784V21.7537C9.61784 24.1523 12.6411 24.9023 13.6032 22.6532L21.8489 3.16353C22.536 1.364 20.7493 -0.585067 19.1002 0.164934Z"
    //             fill="white"
    //           />
    //         </svg>
    //       </div>
    //       <div style={{ textTransform: 'none', fontWeight: '400' }}>
    //         You are still being tracked in{' '}
    //         <strong style={{ textTransform: 'none', fontWeight: '600' }}>
    //           {missionName}
    //         </strong>
    //         .{' '}
    //         <span style={{ fontWeight: '200' }}>
    //           Stop tracking, or tap to re-enter
    //         </span>
    //       </div>
    //       <div>
    //         <DotButton
    //           className="secondary extra-small"
    //           onClick={async (e) => {
    //             e.preventDefault();
    //             e.stopPropagation();

    //             removeLocation(lastMissionId, user.id);
    //             setLastMissionId(null);
    //             setMissionName(null);
    //           }}
    //         >
    //           Stop
    //         </DotButton>
    //       </div>
    //     </>
    //   );
    // } else
    if (advisoryNotifications.length > 0) {
      return (
        <>
          <img src={criticalWhite} alt="advisory" />
          {pluralize('user', advisoryNotifications.length, true)} in advisory
          geofence
        </>
      );
    } else if (cautionNotifications.length > 0) {
      return (
        <>
          <img src={criticalWhite} alt="caution" />
          {pluralize('user', cautionNotifications.length, true)} in caution
          geofence
        </>
      );
    } else if (warningNotifications.length > 0) {
      return (
        <>
          <img src={criticalWhite} alt="warning" />
          {pluralize('user', warningNotifications.length, true)} in warning
          geofence
        </>
      );
    } else if (criticalNotifications.length > 1) {
      return (
        <>
          <img src={criticalWhite} alt="critical" />
          {criticalNotifications.length} SOS{' '}
          {pluralize('call', criticalNotifications.length, false)} present
          within {secrets.EVENT_NAME} area
        </>
      );
    } else if (criticalNotifications.length > 0) {
      return (
        <>
          <img src={criticalWhite} alt="critical" />
          SOS:&nbsp;
          <ReactMarkdown>{criticalNotifications[0].message}</ReactMarkdown>
        </>
      );
    }
    return <></>;
  };

  return (
    <div
      role="status"
      className={getClassNames({
        'notifications-banner-container': true,
        'has-notification':
          offlineNotifications.length ||
          trackedNotifications.length ||
          advisoryNotifications.length ||
          cautionNotifications.length ||
          warningNotifications.length ||
          criticalNotifications.length,
        'is-mission': isMission,
        'is-map': isMap,
      })}
    >
      {(!!offlineNotifications.length ||
        !!trackedNotifications.length ||
        !!advisoryNotifications.length ||
        !!cautionNotifications.length ||
        !!warningNotifications.length ||
        !!criticalNotifications.length) && (
        <div
          onClick={() => {
            if (offlineNotifications?.length) {
              toast.error('You are offline');
            } else if (trackedNotifications?.length) {
              notificationHistory.push(
                endpointConfig.frontendEndpoints.mission.replace(
                  ':missionId',
                  lastMissionId
                )
              );
            } else if (criticalNotifications?.length) {
              notificationAction(notificationHistory, criticalNotifications);
            }
          }}
          className={getClassNames(
            { 'notifications-banner': true },
            getType(
              offlineNotifications.length
                ? offlineNotifications[0]
                : trackedNotifications.length
                ? trackedNotifications[0]
                : advisoryNotifications.length
                ? advisoryNotifications[0]
                : cautionNotifications.length
                ? cautionNotifications[0]
                : warningNotifications.length
                ? warningNotifications[0]
                : criticalNotifications.length
                ? criticalNotifications[0]
                : null
            )
          )}
        >
          {getBannerMessage()}
        </div>
      )}
      {children}
    </div>
  );
};

NotificationsBanner.propTypes = {
  children: PropTypes.any,
  isMission: PropTypes.bool,
  isMapL: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.any),
};
