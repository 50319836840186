/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  getMissionIntegrations,
  updateIntegrationOptions,
} from '../../../api/missions';
import { useSidebar } from '../../../context/SidebarContext';
import { integrationType } from '../../../enums/propTypes';
import { getClassNames } from '../../../utils/string';
import { DotButton } from '../../common/buttons/DotButton';
import { CheckBox } from '../../common/CheckBox';
import { LightScrollbar } from '../../common/Scrollbars';
import './MissionIntegrations.scss';
import { getLocalStorage } from '../../../utils/localStorage';

/**
 * Sidebar - show mission integrations
 *
 * @param {string} missionId mission id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any[]} orgIntegrations organisation integrations
 * @param {any[]} missionIntegrations mission integrations
 * @param {Function} setMissionIntegrations sets all integrations in the mission
 * @param {any} integration selected integration
 * @param {any} integrationOptions options for the selected integration
 */
export const EditMissionIntegrations = ({
  missionId,
  closeSidebar,
  orgIntegrations,
  missionIntegrations,
  setMissionIntegrations,
  integration,
  integrationOptions,
}) => {
  const [selectAll, setSelectAll] = useState(integration.allOptions);
  const [selectedOptions, setSelectedOptions] = useState(
    integration.options || []
  );
  const [touched, setTouched] = useState(false);
  const sidebars = useSidebar();

  const handleSaveIntegration = async (e) => {
    e.preventDefault();
    if (!selectedOptions.length && !selectAll) {
      toast.error('Please select at least one option');
      return;
    }

    try {
      await updateIntegrationOptions(
        missionId,
        integration.id,
        integration.organisationIntegrationId,
        selectAll,
        selectedOptions
      );
      const newIntegrations = (await getMissionIntegrations(missionId)).map(
        (i) => {
          i.active =
            getLocalStorage(`integrations-${missionId}`).find(
              (mi) => mi.id === i.id
            )?.active || false;
          i.visibleInToolbar =
            getLocalStorage(`integrations-${missionId}`).find(
              (mi) => mi.id === i.id
            )?.visibleInToolbar || false;
          return i;
        }
      );
      setMissionIntegrations(newIntegrations);
      toast.success(
        integration.organisationIntegration.name + ' options were saved'
      );
    } catch (e) {
      toast.error('Error saving integration ' + e);
    }
    closeSidebar();
  };

  useEffect(() => {
    setSelectAll(integration.allOptions);
    setSelectedOptions(integration.options || []);
    setTouched(false);
  }, [integration.options, integration.allOptions]);

  const showBack = !!sidebars.previous;

  const exit = () => {
    closeSidebar();
  };

  return (
    <div className="mission-integrations">
      <div
        className={getClassNames({
          top: true,
          'has-back': showBack,
        })}
      >
        <div className="heading">
          <div className="text">Edit Integration</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        {showBack && (
          <DotButton className="back" onClick={exit} ariaLabel="Back">
            <FontAwesomeIcon icon="arrow-left" /> <span>Back</span>
          </DotButton>
        )}
        <div
          className="sub-heading"
          style={{
            marginTop: '1rem',
            marginBottom: '-1rem',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <div className="text" style={{ paddingTop: '1rem' }}>
            INTEGRATION DETAILS
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '3rem',
          }}
        >
          <div style={{ alignSelf: 'flex-start' }}>
            <div className="text" style={{ fontWeight: '600' }}>
              INTEGRATION NAME
            </div>
            <div className="text" style={{ marginTop: '1rem' }}>
              {integration.organisationIntegration.name}
            </div>
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            <img
              src={integration.organisationIntegration.photoUrl}
              alt={[integration.organisationIntegration.name]}
              style={{ height: '3.75rem' }}
            />
          </div>
        </div>
        {/* <div className="text" style={{ fontWeight: '600', marginTop: '30px' }}>
                    INTEGRATION DESCRIPTION
                </div>
                <div className="text" style={{ marginTop: '15px' }}>
                    {integration.description}
                </div> */}
        <div
          className="text"
          style={{
            fontWeight: '600',
            marginTop: '2rem',
            marginBottom: '-2rem',
          }}
        >
          INTEGRATION OPTIONS
        </div>
      </div>
      <LightScrollbar className="content">
        {integrationOptions && (
          <div className="blocks">
            <div className="item-container" style={{ alignItems: 'center' }}>
              <div className="check">
                <CheckBox
                  id={`checkbox-all`}
                  value={'selectAll'}
                  onChange={(event) => {
                    setTouched(true);
                    setSelectAll(event.target.checked);
                    if (event.target.checked) {
                      setSelectedOptions([
                        ...integrationOptions.map((o) => o.id),
                      ]);
                    } else {
                      setSelectedOptions([]);
                    }
                  }}
                  checked={selectAll}
                ></CheckBox>
              </div>
              <label
                htmlFor={`checkbox-all`}
                className="text"
                style={{ cursor: 'pointer' }}
              >
                Select All
              </label>
            </div>
            {integrationOptions.map((option, index) => (
              <div
                key={index}
                className="item-container"
                style={{ alignItems: 'center' }}
              >
                <div className="check">
                  <CheckBox
                    id={`checkbox-${index}`}
                    value={option.id}
                    onChange={(event) => {
                      setTouched(true);
                      let options = [...selectedOptions];
                      if (event.target.checked) {
                        options.push(option.id);
                      } else {
                        options = options.filter(
                          (optionId) => optionId !== option.id
                        );
                      }
                      setSelectedOptions(options);

                      let allSelected =
                        options.length >= integrationOptions.length;
                      for (const io of integrationOptions) {
                        if (!options.find((o) => o !== io.id)) {
                          allSelected = false;
                          break;
                        }
                      }
                      setSelectAll(allSelected);
                    }}
                    checked={!!selectedOptions.find((o) => o === option.id)}
                  ></CheckBox>
                </div>
                <label
                  htmlFor={`checkbox-${index}`}
                  className="text"
                  style={{ cursor: 'pointer' }}
                >
                  {option.deviceFriendlyName}
                </label>
              </div>
            ))}
          </div>
        )}
      </LightScrollbar>
      <div className="foot">
        <DotButton
          onClick={(e) => handleSaveIntegration(e)}
          className="primary block"
          disabled={!touched}
        >
          Save Changes
        </DotButton>
      </div>
    </div>
  );
};

EditMissionIntegrations.propTypes = {
  missionId: PropTypes.string,
  closeSidebar: PropTypes.func,
  orgIntegrations: PropTypes.arrayOf(integrationType),
  setMissionIntegrations: PropTypes.func,
  integration: PropTypes.any,
  integrationOptions: PropTypes.any,
};
