/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import * as log from 'loglevel';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import { useParams, withRouter } from 'react-router';
import { getMission, getMissionUsers, getTeams } from '../../../api/missions';
import {
  deleteOrderSection,
  deleteTask,
  getOrders,
  saveOrderSection,
  saveTask,
  updateTaskStatus,
} from '../../../api/orders';
import { endpointConfig } from '../../../config/endpoint_config';
import { SIDEBARS, useSidebar } from '../../../context/SidebarContext';
import { useUser } from '../../../context/UserContext';
import {
  historyType,
  sectionType,
  taggableType,
  taskType,
} from '../../../enums/propTypes';
import { Role } from '../../../enums/role';
import { isEqual, only } from '../../../utils';
import { handleError } from '../../../utils/error';
import { useLocalStorage } from '../../../utils/localStorage';
import { orderTemplates } from '../../../utils/orders';
import { cancellablePromise } from '../../../utils/promise';
import { renderRichText } from '../../../utils/richtext';
import {
  diff,
  displayName,
  documentDiff,
  getClassNames,
} from '../../../utils/string';
import { formatDate } from '../../../utils/time';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { Input } from '../../common/Input';
import { MenuButton } from '../../common/Menu';
import { MissionMenu } from '../../common/menus/MissionMenu';
import { NavBar } from '../../common/Navbar';
import { RichText } from '../../common/RichText';
import { LightScrollbar } from '../../common/Scrollbars';
import { Sidebar } from '../../common/Sidebar';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { OrderHistory } from '../../sidebars/tasks/OrderHistory';
import { TaskSidebar } from '../../sidebars/tasks/Task';
import { MissionInfo } from './MissionInfo';
import { SearchInput } from '../../common/Search';
import { CheckBox } from '../../common/CheckBox';
import svgNote from '../../../assets/svg/note.svg';
import svgTasks from '../../../assets/svg/tasks.svg';
import svgCalendar from '../../../assets/svg/calendar.svg';
import svgUsers from '../../../assets/svg/users.svg';
import './Page.scss';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { isMobile } from '../../../utils/device';
import { CreateEditTaskSidebar } from '../../sidebars/CreateEditTask';
import { CreateEditSectionSidebar } from '../../sidebars/CreateEditSection';
import badge from '../../../assets/svg/dot-badge.svg';
import { CallState } from '../../../enums/call';
import { useComms } from '../../../context/CommsContext';
import { secrets } from '../../../config/secrets';

const SECTION_FIELDS = ['description', 'id', 'missionId', 'name'];

const getListStyle = (isDraggingOver) => ({
  borderRadius: '24px',
  background: isDraggingOver ? 'rgba(255,255,255,0.07)' : 'transparent',
});

/**
 * Mission Orders page
 *
 * @param {any} history react router
 */
export const Orders = withRouter(({ history }) => {
  const { missionId } = useParams();
  const { user } = useUser();

  const [mission, setMission] = useState();
  const [task, setTask] = useState(null);
  const [showHistory, setShowHistory] = useState(null);

  const [taggables, setTaggables] = useState([]);
  const [teams, setTeams] = useState([]);

  const sidebars = useSidebar();
  const comms = useComms();

  const [orders, setOrders] = useState();
  const [sections, setSections] = useState([]);
  const [availableSections, setAvailableSections] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedSections, setSelectedSections] = useLocalStorage(
    'selected-sections',
    []
  );

  const [createSection, setCreateSection] = useState(null);
  const [editSection, setEditSection] = useState();
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [ordersToRestore, setOrdersToRestore] = useState(null);

  const [globalEditSection, globalSetEditSection] = useState();
  const [globalEditTask, globalSetEditTask] = useState();

  const scroll = useRef();

  const [canEdit, setCanEdit] = useState(false);

  const [activeTab, setActiveTab] = useState('mission');

  const [activeTaskTab, setActiveTaskTab] = useState('all');

  const [sortByTask, setSortByTask] = useState('Default');

  const [editTask, setEditTask] = useState();

  const [loading, setLoading] = useState(true);

  const refreshOrders = useCallback(
    async (_teams) => {
      const _orders = await getOrders(missionId);
      const _availableSections = [];
      // set team entity
      for (const section of _orders.sections) {
        if (section.tasks) {
          for (const task of section.tasks) {
            if (task.assigned) {
              for (const assigned of task.assigned.filter(
                (a) => a.type === 'team'
              )) {
                assigned.entity = (_teams || teams).find(
                  (t) => t.id === assigned.id
                );
              }
            }
          }
        }
        _availableSections.push({
          id: section.id,
          name: section.name,
        });
      }
      log.debug('orders', _orders);
      setOrders(_orders);
      setSections(_orders.sections);
      setAvailableSections(_availableSections);
      setLoading(false);
    },
    [missionId, teams]
  );

  useEffect(() => {
    const stub = async () => {
      const savedMission = await getMission(missionId);
      setMission(savedMission);

      setCanEdit(
        user?.accessRoles?.includes(Role.SysAdmin) ||
          (user?.accessRoles?.includes(Role.OrgAdmin) &&
            user.organisationId === savedMission.organisationId)
      );

      const units = await getMissionUsers(missionId);
      const _taggables = [];
      _taggables.push(
        ...units.map((u) => {
          return {
            id: u.userId,
            type: 'User',
            name: displayName(u),
            description: '',
            photoUrl: u.photoUrl,
            fallback: [u.displayName],
            color: u.color,
          };
        })
      );
      setTaggables(_taggables);

      const _teams = await getTeams(missionId);
      setTeams(_teams);

      await refreshOrders(_teams);

      if (!savedMission?.published) {
        history.push(
          endpointConfig.frontendEndpoints.editMission.replace(
            ':missionId',
            savedMission?.id || ''
          )
        );
      }
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!search) {
      filterTasks();
    } else if (activeTab === 'task') {
      filterTasks('taskView');
    }
    // eslint-disable-next-line
  }, [search, sections, activeTab, activeTaskTab]);

  const deleteSection = async () => {
    await deleteOrderSection(missionId, sectionToDelete.id);
    await refreshOrders();
    setSectionToDelete(null);
    toast.success('Section deleted!');
  };

  const cloneSection = async (section) => {
    const newSection = { ...section };
    delete newSection.id;
    for (const task of newSection.tasks) {
      delete task.id;
    }

    setEditSection(newSection);

    toast.success('Section cloned!');
  };

  const loadTemplate = useCallback(
    async (template) => {
      if (!sections?.length) {
        for (const section of orderTemplates[template].sections) {
          const updatedSection = {
            ...only(section, SECTION_FIELDS),
            tasks: [],
          };

          log.debug('save section', updatedSection);

          await saveOrderSection(missionId, updatedSection);
        }

        await refreshOrders();
      } else {
        toast.error(`Can't load template when there are existing orders`);
      }
    },
    [missionId, sections, refreshOrders]
  );

  const restoreOrders = async () => {
    if (ordersToRestore) {
      log.debug('orders to restore', ordersToRestore);
      if (ordersToRestore.taskId) {
        ordersToRestore.id = ordersToRestore.taskId;
        globalSetEditTask(ordersToRestore);
        setOrdersToRestore(null);
        setShowHistory(null);
        sidebars.close(SIDEBARS.history);
        toast.success('Task restored!');
      } else {
        ordersToRestore.id = ordersToRestore.orderSectionId;
        globalSetEditSection(ordersToRestore);
        setOrdersToRestore(null);
        setShowHistory(null);
        sidebars.close(SIDEBARS.history);
        toast.success('Section restored!');
      }
    }
    setOrdersToRestore(null);
  };

  const filterTasks = (view = '') => {
    let filteredTasks = [];
    let searchKey = search.toLowerCase();
    if (view === 'taskView') {
      sections?.forEach((section) => {
        section.tasks?.forEach((task) => {
          if (activeTaskTab === 'completed') {
            if (!!task.complete) filteredTasks.push(task);
          } else if (activeTaskTab === 'open') {
            if (!task.complete) filteredTasks.push(task);
          } else {
            filteredTasks.push(task);
          }
        });
      });
    } else {
      sections?.forEach((section) => {
        section.tasks?.forEach((task) => {
          if (
            task.name?.toLowerCase().includes(searchKey) ||
            task.description[0]?.children[0]?.text
              ?.toLowerCase()
              .includes(searchKey)
          ) {
            filteredTasks.push(task);
          }
          task.assigned?.forEach((a) => {
            if (
              a.entity?.firstname?.toLowerCase().includes(searchKey) ||
              a.entity?.lastname?.toLowerCase().includes(searchKey) ||
              a.entity?.displayName?.toLowerCase().includes(searchKey)
            ) {
              if (!filteredTasks.includes(task)) {
                filteredTasks.push(task);
              }
            }
          });
        });
      });
    }

    setFilteredTasks(filteredTasks);
  };

  const getSectionName = (task) => {
    let sectionName = '';
    sections?.forEach((section) => {
      if (task.orderSectionId === section.id) {
        sectionName = section.name;
      }
    });
    return sectionName;
  };

  const getSectionIndexName = (task) => {
    let sectionIndexName = '';
    sections?.forEach((section, index) => {
      if (task.orderSectionId === section.id) {
        sectionIndexName = (parseInt('a', 36) + index)
          .toString(36)
          .toUpperCase();
      }
    });
    return sectionIndexName;
  };

  const options = [
    {
      text: 'Default',
      ariaLabel: 'Default',
      onClick: () => {
        setSortByTask('Default');
      },
    },
    {
      text: 'Most Recent',
      ariaLabel: 'Most Recent',
      onClick: () => {
        setSortByTask('Most Recent');
      },
    },
    //  {
    //    text: 'Relevance',
    //    ariaLabel: 'Relevance',
    //    onClick: () => {
    //      setSortByTask('Relevance');
    //    }
    //  }
  ];

  return (
    <div className="page">
      <NavBar history={history}>
        <MissionMenu
          history={history}
          missionId={missionId}
          active="orders"
          published={mission?.published}
          archived={mission?.archived}
        ></MissionMenu>
      </NavBar>
      {/* {mission && (
        <div className="mission-info-container">
          <MissionInfo
            missions={[mission]}
            history={history}
            enableEditMenu={false}
          />
        </div>
      )} */}
      {loading ? (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ animation: 'rotation 2s infinite linear' }}>
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Loading orders</div>
        </div>
      ) : (
        <>
          <div
            className={getClassNames({
              topnav: true,
              'call-banner': comms.callState !== CallState.Disconnected,
            })}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {mission && (
                <div className="mission-info-container">
                  <MissionInfo
                    missions={[mission]}
                    history={history}
                    enableEditMenu={false}
                  />
                </div>
              )}
              <div
                className={getClassNames({
                  active: activeTab === 'mission',
                  'menu-button': true,
                  'nav-mission': true,
                })}
                onClick={() => {
                  setActiveTab('mission');
                  setSearch('');
                }}
              >
                <img
                  className={getClassNames({
                    active:
                      !!activeTab && activeTab === 'mission' ? true : false,
                  })}
                  src={svgNote}
                  alt="order"
                />

                <DotButton className="link">Orders</DotButton>
              </div>
              <div
                className={getClassNames({
                  active: activeTab === 'task',
                  'menu-button': true,
                  'nav-task': true,
                })}
                onClick={() => {
                  setSearch('');
                  setActiveTaskTab('all');
                  setActiveTab('task');
                }}
              >
                <img
                  className={getClassNames({
                    active: !!activeTab && activeTab === 'task' ? true : false,
                  })}
                  src={svgTasks}
                  alt="task"
                />

                <DotButton className="link">
                  Tasks (
                  {orders?.sections?.reduce(
                    (total, section) => total + section.tasks?.length || 0,
                    0
                  )}
                  )
                </DotButton>
              </div>
            </div>
            {!!orders && (
              <>
                {!!orders.sections?.length ? (
                  <>
                    <div className="nav-search">
                      <SearchInput
                        placeholder="Search orders and tasks..."
                        onChange={(e) => setSearch(e.target.value)}
                        light={true}
                      />
                    </div>
                    <div className="menu-button menu-history-button sub-header">
                      <div>Last Updated: {formatDate(orders._ts * 1000)}</div>
                      <div></div>
                      <div>
                        <DotButton
                          className="link"
                          onClick={() => {
                            setShowHistory(orders);
                            sidebars.open(SIDEBARS.history);
                          }}
                        >
                          <FontAwesomeIcon icon="history" />
                        </DotButton>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="menu-button menu-history-button sub-header">
                    <div>Last Updated: Never</div>
                    <div></div>
                  </div>
                )}
              </>
            )}
          </div>
          <LightScrollbar ref={scroll} className="content">
            {!!orders && (
              <div className="container">
                <div
                  className={getClassNames({
                    'search-header': !!search,
                    header: true,
                  })}
                >
                  {activeTab === 'mission' && !search ? (
                    <h1>Orders</h1>
                  ) : (
                    <>
                      {!!search ? (
                        <>
                          <h1>Search Results</h1>
                          <p>"{search}"</p>
                        </>
                      ) : (
                        <div className="task-bar">
                          <span
                            className={getClassNames({
                              active: activeTaskTab === 'all',
                              'task-menu-button': true,
                            })}
                          >
                            <DotButton
                              className="task-link"
                              onClick={() => {
                                setActiveTaskTab('all');
                              }}
                            >
                              All Tasks
                            </DotButton>
                          </span>
                          <span
                            className={getClassNames({
                              active: activeTaskTab === 'open',
                              'task-menu-button': true,
                            })}
                          >
                            <DotButton
                              className="task-link"
                              onClick={() => {
                                setActiveTaskTab('open');
                              }}
                            >
                              Open Tasks
                            </DotButton>
                          </span>
                          <span
                            className={getClassNames({
                              active: activeTaskTab === 'completed',
                              'task-menu-button': true,
                            })}
                          >
                            <DotButton
                              className="task-link"
                              onClick={() => {
                                setActiveTaskTab('completed');
                              }}
                            >
                              Completed Tasks
                            </DotButton>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {!search && (
                    <div className="header-buttons">
                      {activeTab === 'mission' && (
                        <DotButton
                          className="button create"
                          onClick={() => {
                            setCreateSection({});
                            sidebars.open(SIDEBARS.createEditSection);
                          }}
                        >
                          <span>CREATE SECTION</span>
                        </DotButton>
                      )}
                      {!!orders.sections?.length && (
                        <DotButton
                          className="button create"
                          onClick={() => {
                            setEditTask({});
                            sidebars.open(SIDEBARS.createEditTask);
                          }}
                        >
                          <span>CREATE TASK</span>
                        </DotButton>
                      )}
                    </div>
                  )}
                  {/*!!orders.sections?.length && (
                <div>
                  {!!orders.entity && <Avatar entity={orders.entity} />}
                </div>
              )*/}
                </div>
                {editTask && !!orders.sections?.length && (
                  <Sidebar id={SIDEBARS.createEditTask}>
                    <CreateEditTaskSidebar
                      missionId={missionId}
                      task={editTask}
                      availableSections={availableSections}
                      setTask={async (t, _sectionId) => {
                        if (t) {
                          await saveTask(missionId, _sectionId, t).catch(
                            handleError
                          );
                          toast.success('Task saved!');
                        }
                        setEditTask(null);
                        if (refreshOrders) {
                          refreshOrders();
                        }
                        setEditTask(null);
                      }}
                      closeSidebar={() => {
                        setEditTask(null);
                        sidebars.close(SIDEBARS.createEditTask);
                      }}
                    />
                  </Sidebar>
                )}
                {!!orders.sections?.length &&
                  activeTab === 'mission' &&
                  !search && (
                    <div className="section-bar tasks-bar">
                      {orders.sections.length}{' '}
                      {pluralize('Section', orders.sections.length)}
                    </div>
                  )}
                {(activeTab === 'task' || !!search) && !!filteredTasks ? (
                  <>
                    <div className="section-bar tasks-bar">
                      {filteredTasks.length}{' '}
                      {pluralize('Task', filteredTasks.length)}
                      {' Listed'}
                      <span className="sort-by">
                        Sort by:
                        <MenuButton
                          buttonTheme="light"
                          position="right"
                          options={options}
                        >
                          <span className="sort-option">{sortByTask}</span>
                          <FontAwesomeIcon
                            icon="chevron-down"
                            aria-label="chevron-down"
                          ></FontAwesomeIcon>
                        </MenuButton>
                      </span>
                    </div>
                    <div className="body task-results">
                      <div className="tasks">
                        {!!filteredTasks?.length ? (
                          filteredTasks.map((task, index) => (
                            <Task
                              key={index}
                              task={task}
                              openTask={(task) => {
                                setTask(task);
                                sidebars.open(SIDEBARS.task);
                              }}
                              sectionId={task.sectionId}
                              missionId={missionId}
                              setTaskToDelete={setTaskToDelete}
                              removeTaskFromPending={(task) => {
                                filteredTasks.splice(index, 1);
                              }}
                              refreshOrders={refreshOrders}
                              showHistory={showHistory}
                              globalEditTask={globalEditTask}
                              globalSetEditTask={globalSetEditTask}
                              readOnly={!canEdit}
                              filteredView={true}
                              sectionName={getSectionName(task)}
                              sectionIndexName={getSectionIndexName(task)}
                              availableSections={availableSections}
                            />
                          ))
                        ) : (
                          <div className="no-results">
                            {!!search ? (
                              <>
                                <span>No results found.</span>
                                <DotButton
                                  className="search-result-button"
                                  onClick={() => {
                                    setSearch('');
                                  }}
                                >
                                  Clear search
                                </DotButton>
                              </>
                            ) : (
                              <>
                                {activeTaskTab !== 'all' && (
                                  <>
                                    <span>No {activeTaskTab} tasks.</span>
                                    <DotButton
                                      className="search-result-button"
                                      onClick={() => {
                                        setActiveTaskTab('all');
                                      }}
                                    >
                                      View all tasks
                                    </DotButton>
                                  </>
                                )}
                                {activeTaskTab === 'all' && (
                                  <span>No tasks.</span>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="body">
                    {!!sections?.length ? (
                      <DragDropContext onDragEnd={() => {}}>
                        <Droppable droppableId="sections">
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {sections.map((section, index) => (
                                <Draggable
                                  isDragDisabled={true}
                                  key={section.id}
                                  draggableId={section.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <React.Fragment>
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        // style={getItemStyle(
                                        //   snapshot.isDragging,
                                        //   provided.draggableProps.style
                                        // )}
                                        className={getClassNames({
                                          dragging: snapshot.isDragging,
                                        })}
                                      >
                                        <Section
                                          index={index}
                                          missionId={missionId}
                                          section={section}
                                          openTask={(task) => {
                                            setTask(task);
                                            sidebars.open(SIDEBARS.task);
                                          }}
                                          taggables={taggables}
                                          toggleSection={(id) => {
                                            if (selectedSections.includes(id)) {
                                              setSelectedSections(
                                                selectedSections.filter(
                                                  (s) => s !== id
                                                )
                                              );
                                            } else {
                                              setSelectedSections([
                                                ...selectedSections,
                                                id,
                                              ]);
                                            }
                                          }}
                                          expanded={
                                            selectedSections.includes(
                                              section.id
                                            ) && !snapshot.isDragging
                                          }
                                          setSectionToDelete={
                                            setSectionToDelete
                                          }
                                          cloneSection={cloneSection}
                                          setTaskToDelete={setTaskToDelete}
                                          refreshOrders={refreshOrders}
                                          provided={provided}
                                          showHistory={showHistory}
                                          globalEditSection={globalEditSection}
                                          globalSetEditSection={
                                            globalSetEditSection
                                          }
                                          globalEditTask={globalEditTask}
                                          globalSetEditTask={globalSetEditTask}
                                          canEdit={
                                            canEdit && !mission?.archived
                                          }
                                          availableSections={availableSections}
                                        ></Section>
                                      </div>
                                      {/* {snapshot.isDragging && (
                                  <div className="clone"></div>
                                )} */}
                                    </React.Fragment>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <div>
                        No {secrets.EVENT_NAME} orders.{' '}
                        {canEdit && (
                          <MenuButton
                            className="link"
                            options={Object.keys(orderTemplates).map((key) => {
                              return {
                                text: orderTemplates[key].name,
                                onClick: async () => {
                                  await loadTemplate(key);
                                },
                              };
                            })}
                          >
                            Create from a template?
                          </MenuButton>
                        )}
                      </div>
                    )}

                    {editSection ? (
                      <EditSection
                        missionId={missionId}
                        section={editSection}
                        setSection={(section) => {
                          setEditSection(section);
                          refreshOrders();
                        }}
                        taggables={taggables}
                        refreshOrders={refreshOrders}
                      />
                    ) : (
                      <></>
                    )}

                    {createSection ? (
                      <Sidebar id={SIDEBARS.createEditSection}>
                        <CreateEditSectionSidebar
                          missionId={missionId}
                          section={createSection}
                          setSection={(section) => {
                            setCreateSection(section);
                            refreshOrders();
                          }}
                          taggables={taggables}
                          refreshOrders={refreshOrders}
                          closeSidebar={() => {
                            setCreateSection(null);
                            sidebars.close(SIDEBARS.createEditSection);
                          }}
                        />
                      </Sidebar>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            )}
          </LightScrollbar>
          <Sidebar id={SIDEBARS.task}>
            <TaskSidebar
              history={history}
              missionId={missionId}
              closeSidebar={() => {
                // update list
                // updateContext();
                sidebars.close(SIDEBARS.task);
              }}
              task={task}
            />
          </Sidebar>
          <Sidebar id={SIDEBARS.history}>
            <OrderHistory
              closeSidebar={() => {
                // update list
                // updateContext();
                setShowHistory(null);
                sidebars.close(SIDEBARS.history);
              }}
              missionId={missionId}
              showHistory={showHistory}
              setShowHistory={(item) => {
                if (item) {
                  const section = sections.find(
                    (s) => s.id === item.orderSectionId
                  );
                  if (section) {
                    setSelectedSections([section.id]);
                    if (scroll.current) {
                      // wait for task to become visible
                      setTimeout(() => {
                        const el = document.getElementById(item.orderSectionId);
                        if (el) {
                          scroll.current.scrollTop = el.offsetTop;
                        }
                      }, 500);
                    }
                  }
                }
                setShowHistory(item);
              }}
              currentOrders={orders}
              setOrdersToRestore={setOrdersToRestore}
              canEdit={canEdit}
            />
          </Sidebar>

          <ConfirmModal
            isActive={!!sectionToDelete}
            exit={() => {
              setSectionToDelete(null);
            }}
            icon="exclamation-circle"
            modalId="delete-section"
            title={['Delete this section?']}
            buttons={[
              {
                text: 'Yes, Delete section',
                type: 'primary',
                callback: deleteSection,
              },
              {
                text: 'No, Go Back',
                type: 'secondary',
                callback: () => setSectionToDelete(null),
              },
            ]}
          >
            You will lose any changes you have made to this section including
            tasks that have been created within this section.
          </ConfirmModal>

          <ConfirmModal
            isActive={!!taskToDelete}
            exit={() => {
              setTaskToDelete(null);
            }}
            icon="exclamation-circle"
            modalId="delete-task"
            title={['Delete this task?']}
            buttons={[
              {
                text: 'Yes, Delete task',
                type: 'primary',
                callback: async () => {
                  await deleteTask(
                    missionId,
                    taskToDelete.sectionId,
                    taskToDelete.id
                  );
                  refreshOrders();
                  setTaskToDelete(null);
                  toast.success('Task deleted!');
                },
              },
              {
                text: 'No, Go Back',
                type: 'secondary',
                callback: () => setTaskToDelete(null),
              },
            ]}
          >
            You will lose any changes you have made to this task.
          </ConfirmModal>

          <ConfirmModal
            isActive={!!ordersToRestore}
            exit={() => {
              setOrdersToRestore(null);
            }}
            icon="history"
            modalId="restore-orders"
            title={['Restore this version?']}
            buttons={[
              {
                text: 'Yes, Restore version',
                type: 'primary',
                callback: restoreOrders,
              },
              {
                text: 'No, Go Back',
                type: 'secondary',
                callback: () => setOrdersToRestore(null),
              },
            ]}
          >
            The
            <strong>
              {dayjs(ordersToRestore?._ts * 1000).format('D MMM, YYYY, h:mmA')}
            </strong>
            version of the selected{' '}
            {ordersToRestore?.taskId ? 'task' : 'section'} will be restored in
            edit mode. You can then choose to save it or discard it.
          </ConfirmModal>
        </>
      )}
    </div>
  );
});

Orders.propTypes = {
  history: historyType,
};

/**
 * Order section
 *
 * @param {number} index section index
 * @param {any} section section
 * @param {string} missionId mission id
 * @param {Function} openTask callback to open task
 * @param {any[]} taggables items that can be tagged in content
 * @param {Boolean} toggleSection open/close section
 * @param {Boolean} expanded is section panel open or not
 * @param {Function} setSectionToDelete - delete section - opens modal
 * @param {Function} cloneSection clone section - opens modal
 * @param {Function} setTaskToDelete set task to delete - passed down to task
 * @param {Function} refreshOrders refresh orders
 * @param {any} provided
 * @param {Function} showHistory show diff in place
 * @param {Function} globalEditSection section in edit mode
 * @param {Function} globalSetEditSection sets section in edit mode
 * @param {Function} globalEditTask task in edit mode - passed down to task
 * @param {Function} globalSetEditTask set task in edit mode - passed down to task
 * @param {Boolean} canEdit can edit
 */
const Section = ({
  index,
  section,
  missionId,
  openTask,
  taggables,
  toggleSection,
  expanded,
  setSectionToDelete,
  cloneSection,
  setTaskToDelete,
  refreshOrders,
  provided,
  showHistory,
  globalEditSection,
  globalSetEditSection,
  globalEditTask,
  globalSetEditTask,
  canEdit,
  availableSections,
}) => {
  // eslint-disable-next-line
  const [editTask, setEditTask] = useState();

  const [editSection, setEditSection] = useState(null);

  const sidebars = useSidebar();

  useEffect(() => {
    if (globalEditSection) {
      if (section.id === globalEditSection.id) {
        log.debug('set edit section', globalEditSection);
        setEditSection({ ...globalEditSection, isHistory: true });
        globalSetEditSection(null);
      }
    }
  }, [globalEditSection, globalSetEditSection, section.id]);

  // const extra = [];
  // if (section.tasks?.length) {
  //   extra.push(pluralize('Task', section.tasks?.length || 0, true));
  // }
  // if (section.assigned) {
  //   if (extra.length) {
  //     extra.push(<>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;</>);
  //   }
  //   extra.push(pluralize('Assigned User', section.assigned || 0, true));
  // }

  const isHistory =
    showHistory?.orderSectionId === section.id && !showHistory?.taskId;
  const rendered = { ...(isHistory ? showHistory : section) };
  const sectionIndexName = (parseInt('a', 36) + index)
    .toString(36)
    .toUpperCase();

  // get diff
  if (isHistory) {
    rendered.name = diff(section.name, showHistory.name);
    rendered.description = documentDiff(
      showHistory.description,
      section.description
    );
  } else {
    rendered.name = <span>{rendered.name}</span>;
  }

  const options = [];

  if (section?.id) {
    options.push({
      text: 'Edit Section',
      ariaLabel: 'Edit Section',
      icon: 'pencil-alt',
      iconLeft: true,
      onClick: () => {
        if (!expanded) {
          toggleSection(section.id);
        }
        setEditSection(section);
      },
    });
    options.push({
      text: 'Duplicate Section',
      ariaLabel: 'Duplicate Section',
      icon: 'clone',
      iconLeft: true,
      onClick: async () => {
        await cloneSection(section);
      },
    });
    options.push({
      text: 'Delete Section',
      ariaLabel: 'Delete Section',
      icon: 'trash',
      iconLeft: true,
      onClick: () => {
        setSectionToDelete(section);
      },
    });
  }

  return (
    <section
      id={section.id}
      className={getClassNames({
        expandable: true,
        expanded,
        history: isHistory,
      })}
    >
      <div className="section-header">
        <div className="icon">
          <div className="outer"></div>
          <div className="inner">{sectionIndexName}</div>
        </div>
        <span>
          {rendered?.name}

          <span className="notes section-notes">
            {!!section.tasks?.length && (
              <span>
                <img src={svgTasks} alt="tasks" className="icon-tasks" />
                <span>
                  {section.tasks?.length}{' '}
                  {pluralize('Task', section.tasks?.length)}
                </span>
              </span>
            )}
            {!!section.assigned && (
              <span>
                <img src={svgUsers} alt="user" className="icon-users" />
                <span>
                  {section.assigned}{' '}
                  {pluralize('Assigned User', section.assigned.length)}
                </span>
              </span>
            )}
          </span>
        </span>
        {/* <div className="icon" {...provided.dragHandleProps}>
          <FontAwesomeIcon icon="bars"></FontAwesomeIcon>
        </div> */}
        {canEdit && (
          <div className="icon action-icon actions">
            <MenuButton buttonTheme="light" position="right" options={options}>
              <FontAwesomeIcon
                icon="ellipsis-vertical"
                aria-label="toolbar"
              ></FontAwesomeIcon>
            </MenuButton>
          </div>
        )}
        <div
          className="icon action-icon"
          onClick={() => {
            toggleSection(section.id);
          }}
        >
          <FontAwesomeIcon
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            aria-label={expanded ? 'Collapse' : 'Expand'}
          ></FontAwesomeIcon>
        </div>
      </div>
      {expanded && (
        <div className="section-body">
          {editSection ? (
            <EditSection
              missionId={missionId}
              section={editSection}
              setSection={(section) => {
                setEditSection(null);
                refreshOrders();
              }}
              taggables={taggables}
              setTaskToDelete={setTaskToDelete}
            ></EditSection>
          ) : (
            <>
              <p>{renderRichText(rendered?.description)}</p>
              <div className="rows task-list">
                <h3>
                  <img src={svgTasks} alt="tasks" />
                  Tasks ({section.tasks?.length})
                </h3>
                <DotButton
                  className="button task-create"
                  onClick={() => {
                    setEditTask({});
                    sidebars.open(SIDEBARS.createEditTask);
                  }}
                >
                  <span>CREATE TASK</span>
                </DotButton>
              </div>
              <div className="tasks">
                {!!section.tasks?.length &&
                  section.tasks.map((task, index) => (
                    <Task
                      key={index}
                      task={task}
                      openTask={openTask}
                      sectionId={section.id}
                      missionId={missionId}
                      setTaskToDelete={setTaskToDelete}
                      removeTaskFromPending={(task) => {
                        section.tasks.splice(index, 1);
                      }}
                      refreshOrders={refreshOrders}
                      showHistory={showHistory}
                      globalEditTask={globalEditTask}
                      globalSetEditTask={globalSetEditTask}
                      readOnly={!canEdit}
                      sectionName={rendered?.name}
                      sectionIndexName={sectionIndexName}
                      cloneTask={(task) => {
                        setEditTask(task);
                        sidebars.open(SIDEBARS.createEditTask);
                      }}
                      availableSections={availableSections}
                    />
                  ))}

                {editTask ? (
                  <Sidebar id={SIDEBARS.createEditTask}>
                    <CreateEditTaskSidebar
                      missionId={missionId}
                      task={editTask}
                      availableSections={availableSections}
                      sectionId={section.id}
                      setTask={async (task, _sectionId) => {
                        if (task) {
                          await saveTask(missionId, _sectionId, task).catch(
                            handleError
                          );
                          toast.success('Task saved!');
                        }
                        setEditTask(null);
                        refreshOrders();
                      }}
                      taggables={taggables}
                      closeSidebar={() => {
                        setEditTask(null);
                        sidebars.close(SIDEBARS.createEditTask);
                      }}
                    />
                  </Sidebar>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      )}
      <div className="border"></div>
    </section>
  );
};

Section.propTypes = {
  index: PropTypes.number,
  section: sectionType,
  missionId: PropTypes.string,
  openTask: PropTypes.func,
  taggables: PropTypes.arrayOf(taggableType),
  toggleSection: PropTypes.func,
  expanded: PropTypes.bool,
  setSectionToDelete: PropTypes.func,
  cloneSection: PropTypes.func,
  setTaskToDelete: PropTypes.func,
  refreshOrders: PropTypes.func,
  provided: PropTypes.any,
  showHistory: PropTypes.bool,
  globalEditSection: sectionType,
  globalSetEditSection: PropTypes.func,
  globalEditTask: taskType,
  globalSetEditTask: PropTypes.func,
  canEdit: PropTypes.bool,
};

/**
 * Section task
 *
 * @param {any} task task
 * @param {Function} openTask callback to open task
 * @param {string} sectionId section id
 * @param {string} missionId mission id
 * @param {Function} setTaskToDelete set task to delete - opens modal
 * @param {Function} removeTaskFromPending remove task from pending changes
 * @param {Boolean} readOnly read only?
 * @param {Function} refreshOrders refresh orders
 * @param {Boolean} showHistory show task diffs in place
 * @param {any} globalEditTask task to edit
 * @param {Function} globalSetEditTask edit task in sidebar
 * @param {Boolean} filteredView filtered view?
 * @param {string} sectionName section name
 * @param {string} sectionIndexName section index name
 */
const Task = ({
  task,
  openTask,
  sectionId,
  missionId,
  setTaskToDelete,
  removeTaskFromPending,
  readOnly = false,
  refreshOrders,
  showHistory,
  globalEditTask,
  globalSetEditTask,
  filteredView = false,
  sectionName,
  sectionIndexName,
  availableSections,
  cloneTask,
}) => {
  const [editTask, setEditTask] = useState(null);

  useEffect(() => {
    if (globalEditTask) {
      if (task.id === globalEditTask.id) {
        setEditTask({ ...globalEditTask, isHistory: true });
        globalSetEditTask(null);
      }
    }
  }, [globalEditTask, globalSetEditTask, task?.id]);

  const options = [];

  if (task?.id) {
    options.push({
      text: 'Mark as Complete',
      ariaLabel: 'Mark as Complete',
      icon: 'circle-check',
      iconLeft: true,
      onClick: (e) => {
        handleUpdateTaskStatus(e, true);
      },
    });
    options.push({
      text: 'Task info',
      ariaLabel: 'Task info',
      icon: 'info',
      iconLeft: true,
      onClick: () => {
        openTask(task);
      },
    });
    options.push({
      text: 'Edit Task',
      ariaLabel: 'Edit Task',
      icon: 'pencil',
      iconLeft: true,
      onClick: () => {
        setEditTask(task);
        sidebars.open(SIDEBARS.createEditTask);
      },
    });
    options.push({
      text: 'Duplicate Task',
      ariaLabel: 'Duplicate Task',
      icon: 'clone',
      iconLeft: true,
      onClick: async () => {
        const newTask = { ...task };
        delete newTask.id;
        newTask.isHistory = true;
        cloneTask(newTask);
        toast.success('Task cloned!');
      },
    });
  }
  options.push({
    text: 'Delete Task',
    ariaLabel: 'Delete Task',
    icon: 'trash',
    iconLeft: true,
    onClick: async () => {
      if (!task.id && removeTaskFromPending) {
        removeTaskFromPending(task);
      } else {
        setTaskToDelete({ ...task, sectionId });
      }
    },
  });

  const isHistory =
    !!showHistory &&
    showHistory?.orderSectionId === sectionId &&
    showHistory?.taskId === task.id;
  const rendered = { ...(isHistory ? showHistory : task) };

  // get diff
  if (isHistory) {
    rendered.name = diff(task.name, showHistory.name);
    rendered.description = documentDiff(
      showHistory.description,
      task.description
    );
  } else {
    rendered.name = <span>{rendered.name}</span>;
  }

  const handleUpdateTaskStatus = async (e, checked = '') => {
    const complete = checked || e.target.checked;
    const res = await updateTaskStatus(missionId, sectionId, task.id, complete);
    if (res) {
      await refreshOrders();
      toast.success('Task updated!');
    } else {
      toast.error('Something went wrong. Task not completed.');
    }
  };

  const id = useRef(uuid());
  const sidebars = useSidebar();

  return editTask ? (
    <Sidebar id={SIDEBARS.createEditTask}>
      <CreateEditTaskSidebar
        missionId={missionId}
        task={editTask}
        availableSections={availableSections}
        sectionId={sectionId}
        setTask={async (t, _sectionId) => {
          if (t) {
            await saveTask(missionId, _sectionId, t).catch(handleError);
            toast.success('Task saved!');
          }
          setEditTask(null);
          if (refreshOrders) {
            refreshOrders();
          }
          setEditTask(null);
        }}
        closeSidebar={() => {
          setEditTask(null);
          sidebars.close(SIDEBARS.createEditTask);
        }}
      />
    </Sidebar>
  ) : (
    <section
      id={task?.id}
      className={getClassNames({
        task: true,
        history: isHistory,
        'has-details': task?.id,
        'task-container': true,
      })}
    >
      <div className="row section-name">
        <img src={svgNote} alt="order" />
        <span>
          {sectionIndexName} - {sectionName}
        </span>
      </div>
      <div className="columns">
        <div className="column">
          <div className="section-header task-section">
            <div onClick={(e) => e.stopPropagation()}>
              <div
                className="check"
                data-tip="Mark as Complete"
                data-for={id.current}
              >
                <CheckBox
                  id={'checkbox-complete'}
                  value={'checkbox-complete'}
                  checked={task.complete ? true : false}
                  onChange={(e) => {
                    handleUpdateTaskStatus(e);
                  }}
                ></CheckBox>
              </div>
              <ReactTooltip id={id.current} disable={isMobile()}></ReactTooltip>
            </div>
            <span className="task-name">{rendered.name}</span>
          </div>
          {!!!filteredView ? (
            <div className="section-body">
              <p>{renderRichText(rendered.description)}</p>
            </div>
          ) : (
            <div className="section-body">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>{renderRichText(rendered.description)}</p>
                {!readOnly && (
                  <MenuButton
                    buttonTheme="light"
                    position="right"
                    options={options}
                  >
                    <FontAwesomeIcon icon="chevron-right" />
                  </MenuButton>
                )}
              </div>
            </div>
          )}
        </div>
        {!readOnly && !!!filteredView && (
          <div className="task-actions-bar">
            <MenuButton buttonTheme="light" position="right" options={options}>
              <FontAwesomeIcon icon="bars" />
            </MenuButton>
          </div>
        )}
      </div>
      <div className="section-foot">
        {rendered?.timespanType === 'deadline' ? (
          <div className="time task-footer-box">
            <img src={svgCalendar} alt="time" className="icon" />
            {dayjs(rendered.end).format('h:mma, D MMM YYYY')}
          </div>
        ) : rendered?.timespanType === 'timeframe' ? (
          <div className="time task-footer-box">
            <img src={svgCalendar} alt="time" className="icon" />
            {dayjs(rendered.start).format('h:mma, D MMM YYYY')} -
            {dayjs(rendered.end).format('h:mma, D MMM YYYY')}
          </div>
        ) : (
          <div className="time task-footer-box">
            <img src={svgCalendar} alt="time" className="icon" />
            NA
          </div>
        )}
        {!!rendered?.assigned?.length ? (
          <div className="task-footer-box">
            <img src={svgUsers} alt="time" className="icon" />
            <span className="assigned">
              {rendered.assigned.length} Assigned
            </span>
            <div className="units ">
              {rendered.assigned.slice(0, 3).map((unit, index) => (
                <Avatar key={index} entity={unit.entity} size="21px" />
              ))}
              {rendered.assigned.length > 3 && (
                <div className="avatar dark">
                  <span>+{rendered.assigned.length - 3}</span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="task-footer-box">
            <img src={svgUsers} alt="time" className="icon" />
            <span className="assigned">No assignees</span>
          </div>
        )}
      </div>
    </section>
  );
};

Task.propTypes = {
  task: taskType,
  openTask: PropTypes.func,
  sectionId: PropTypes.string,
  missionId: PropTypes.string,
  setTaskToDelete: PropTypes.func,
  removeTaskFromPending: PropTypes.func,
  readOnly: PropTypes.bool,
  refreshOrders: PropTypes.func,
  showHistory: PropTypes.bool,
  globalEditTask: taskType,
  globalSetEditTask: PropTypes.func,
  filteredView: PropTypes.bool,
  sectionName: PropTypes.string,
  sectionIndexName: PropTypes.string,
  availableSections: PropTypes.arrayOf(PropTypes.any),
};

/**
 * Order section - edit mode
 *
 * @param {any} section,
 * @param {Function} setSection,
 * @param {string} missionId,
 * @param {any[]} taggables,
 * @param {Function} setTaskToDelete,
 */
const EditSection = ({
  section,
  setSection,
  missionId,
  taggables,
  setTaskToDelete,
  availableSections,
}) => {
  const [form, setForm] = useState(section);
  const sidebars = useSidebar();

  // eslint-disable-next-line
  const [tasks, setTasks] = useState(section.tasks || []);
  const [editTask, setEditTask] = useState();

  const [formChanged, setFormChanged] = useState(section.isHistory || false);

  const [modalOpen, setModalOpen] = useState(false);

  const isValid = () => {
    return form?.name;
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const promptExit = () => {
    if (formChanged) {
      setModalOpen(true);
    } else {
      exit();
    }
  };

  const exit = () => {
    setSection(null);
  };

  const setFormWCD = (f) => {
    if (!isEqual(form, f)) {
      setFormChanged(true);
    }
    setForm(f);
  };
  const setTasksWCD = (t) => {
    if (!isEqual(tasks, t)) {
      setFormChanged(true);
    }
    setTasks(t);
  };

  const saveSection = async () => {
    log.debug('save section', only(section, SECTION_FIELDS));
    const updatedSection = {
      ...only(section, SECTION_FIELDS),
      ...only(form, SECTION_FIELDS),
      tasks,
    };

    log.debug('save section', updatedSection);

    // remove user id so the server assigns it
    delete updatedSection.userId;

    await saveOrderSection(missionId, updatedSection);

    setSection(null);

    toast.success('Section saved!');
  };

  return (
    <section className="new">
      <div className="section-header">
        <span>{section.id ? 'Edit section' : 'Create new section'}</span>
        <DotButton className="button" onClick={promptExit} ariaLabel="Close">
          <FontAwesomeIcon
            icon="times"
            style={{ color: 'grey' }}
          ></FontAwesomeIcon>
        </DotButton>
      </div>

      <div className="section-body">
        <Input
          label="Section Name"
          placeholder="Enter a name for this section"
          required
          value={form.name}
          onChange={(e) => setFormWCD({ ...form, name: e.target.value })}
        ></Input>

        <RichText
          label="Section Description"
          placeholder="Enter a description for this section"
          required
          value={form.description}
          onChange={(newValue) =>
            setFormWCD({ ...form, description: newValue })
          }
          taggables={taggables}
        ></RichText>
      </div>

      <div className="tasks">
        {!!tasks.length &&
          tasks.map((task, index) => (
            <div key={index}>
              {editTask && editTask.id === task.id ? (
                <Sidebar id={SIDEBARS.createEditTask}>
                  <CreateEditTaskSidebar
                    missionId={missionId}
                    task={editTask}
                    availableSections={availableSections}
                    sectionId={editTask.sectionId}
                    setTask={(task) => {
                      if (task) {
                        const _newTasks = [...tasks];
                        for (let i = 0; i < _newTasks.length; i++) {
                          if (_newTasks[i].id === task.id) {
                            _newTasks[i] = task;
                          }
                        }
                        setTasksWCD(_newTasks);
                      }
                      setEditTask(null);
                    }}
                    closeSidebar={() => {
                      setEditTask(null);
                      sidebars.close(SIDEBARS.createEditTask);
                    }}
                  />
                </Sidebar>
              ) : (
                <Task
                  task={task}
                  openTask={setEditTask}
                  missionId={missionId}
                  sectionId={section.id}
                  setTaskToDelete={setTaskToDelete}
                  readOnly={!!section.id}
                  removeTaskFromPending={() => {
                    setTasksWCD(tasks.filter((t, idx) => idx !== index));
                  }}
                  availableSections={availableSections}
                />
              )}
            </div>
          ))}
      </div>

      {/* {editTask && !editTask?.id ? (
        <EditTask
          missionId={missionId}
          task={editTask}
          setTask={(task) => {
            if (task) {
              setTasksWCD([...tasks, task]);
            }
            setEditTask(null);
          }}
        ></EditTask>
      ) : !section.id ? (
        <DotButton className="button new" onClick={() => setEditTask({})}>
          <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
          <span>Create new task</span>
        </DotButton>
      ) : (
        <></>
      )} */}

      <div className="section-foot">
        <DotButton className="secondary" onClick={promptExit}>
          Cancel
        </DotButton>
        <DotButton
          className="primary"
          disabled={!isValid() || !formChanged}
          onClick={saveSection}
        >
          {section.id ? 'Save Changes' : 'Create Section'}
        </DotButton>
      </div>
      <ConfirmModal
        isActive={modalOpen}
        exit={() => {
          setModalOpen(null);
        }}
        icon="exclamation-circle"
        modalId="section-changes"
        title={'Cancel changes to this section?'}
        buttons={[
          {
            text: 'Yes, Cancel Changes',
            type: 'primary',
            callback: exit,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: closeModal,
          },
        ]}
      >
        You will lose any changes you have made to this section including tasks
        that have been created within this section.
      </ConfirmModal>
    </section>
  );
};

EditSection.propTypes = {
  section: sectionType,
  setSection: PropTypes.func,
  missionId: PropTypes.string,
  taggables: PropTypes.arrayOf(taggableType),
  setTaskToDelete: PropTypes.func,
};
