/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { postOrgDataSharing } from '../../../api/orgs_users';
import {
  actionSettings,
  generalSettings,
  getPrivacyLevelDescription,
  getPrivacyLevelText,
  organisationSettings,
  userSettings,
} from '../../../enums/privacyLevel';
import {
  organisationType,
  privacyLevelType,
  sharingType,
} from '../../../enums/propTypes';
import { handleError } from '../../../utils/error';
import { getClassNames } from '../../../utils/string';
import { DotButton } from '../../common/buttons/DotButton';
import { CheckBox } from '../../common/CheckBox';
import { LightScrollbar } from '../../common/Scrollbars';
import { ConfirmModal } from '../../modals/ConfirmModal';
import './OrgDataSharing.scss';

/**
 * Sidebar - edit data sharing
 *
 * @param {Function} setOrg callback to set org data
 * @param {any} org current org
 * @param {any} sharingSettings current settings
 * @param {Function} closeSidebar callback to close sidebar
 */
export const OrgDataSharing = ({
  setOrg,
  org,
  sharingSettings,
  closeSidebar,
}) => {
  const [sharing, setSharing] = useState(sharingSettings);
  const [showConfirm, setShowConfirm] = useState(false);

  // Handles new org submission
  const handleUpdate = async (e) => {
    e.preventDefault();

    if (org?.id) {
      try {
        const rs = await postOrgDataSharing(org.id, sharing);

        toast.success(<div>Data Sharing default updated.</div>);

        setSharing(rs);

        closeSidebar(); // re-use function to revert width/padding
      } catch (err) {
        handleError(err);
      }
    }
  };

  return (
    <div className="org-data-sharing">
      <div className="top has-border">
        <div className="heading">
          <div className="text">Data Sharing Default</div>
          <DotButton
            className="exit"
            onClick={() => {
              closeSidebar();
            }}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
        <div className="settings-row">
          <p>
            Data Sharing settings determines how much data relating to your
            organisation and personnel is visible during a mission.
          </p>
          {/*
          TODO: Project SS */}
          {/* <p>
            Data Sharing settings determines how much data relating to your
            organisation and personnel is visible to ally organisations during a
            mission.
          </p> */}
          <div className="default">
            <div className="heading">Data Sharing default applies when:</div>
            <ul>
              <li>Your organisation creates a new mission</li>
              <li>Your organisation joins a mission</li>
              {/* TODO: Project SS */}
              {/* <li>
                Another organisation joins a mission that also involves your
                organisation.
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <LightScrollbar className="content">
        <div className="blocks">
          <div className="title">Select Data Sharing Default</div>

          <EditSharingSection
            title="General Information"
            fields={generalSettings}
            sharing={sharing}
            setSharing={setSharing}
          ></EditSharingSection>
          <EditSharingSection
            title="Organisation Information"
            fields={organisationSettings}
            sharing={sharing}
            setSharing={setSharing}
          ></EditSharingSection>
          <EditSharingSection
            title="User Information"
            fields={userSettings}
            sharing={sharing}
            setSharing={setSharing}
          ></EditSharingSection>
          <EditSharingSection
            title="Actions"
            fields={actionSettings}
            sharing={sharing}
            setSharing={setSharing}
          ></EditSharingSection>
          {/* <DataSharingSection
            level={PrivacyLevel.None}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent privacyLevel={PrivacyLevel.None}></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection>
          <DataSharingSection
            level={PrivacyLevel.Partial}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent
              privacyLevel={PrivacyLevel.Partial}
            ></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection>
          <DataSharingSection
            level={PrivacyLevel.Full}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
          >
            <PrivacyContent privacyLevel={PrivacyLevel.Full}></PrivacyContent>
            <div className="foot">
              <span>How your personnel will display to allies:</span>
              <TestCurrentUser
                privacyLevel={PrivacyLevel.None}
              ></TestCurrentUser>
            </div>
          </DataSharingSection> */}
        </div>
      </LightScrollbar>
      <div className="foot">
        <DotButton
          onClick={(e) => setShowConfirm(true)}
          className="primary block"
        >
          Save Changes
        </DotButton>
      </div>

      <ConfirmModal
        isActive={showConfirm}
        exit={() => {
          setShowConfirm(false);
        }}
        icon="mountain"
        iconStyle={{
          color: 'var(--colour-interactions-a)',
          background: 'var(--orders-bg)',
          borderRadius: '50%',
          height: '67px',
          width: '67px',
          padding: '15px',
        }}
        modalId="update-default-sharing"
        title="Your new default will only apply to newly created/joined missions"
        buttons={[
          {
            text: 'Confirm Changes',
            type: 'primary',
            callback: handleUpdate,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setShowConfirm(false),
          },
        ]}
      >
        <p>
          Active missions will not be affected by the new Data Sharing default.
          Data Sharing settings for active missions can be configured in the
          mission settings.
        </p>
      </ConfirmModal>
    </div>
  );
};

OrgDataSharing.propTypes = {
  setOrg: PropTypes.func.isRequired,
  org: organisationType.isRequired,
  sharingSettings: sharingType,
  closeSidebar: PropTypes.func.isRequired,
};

/**
 * Data sharing section
 *
 * @param {any} sharing current setting
 * @param {Function} setSharing set fields
 * @param {Jsx} children child content
 */
export const EditSharingSection = ({ title, fields, sharing, setSharing }) => {
  const handleCheckElement = (event) => {
    const newSharing = { ...sharing };
    newSharing[event.target.value] = event.target.checked;
    setSharing(newSharing);
  };

  return (
    <div className="information-block">
      <div className="subheading">{title}</div>
      {fields.map((field) => {
        return (
          <label key={field.key} className="field">
            <div className="text">
              {field.text}
              {/* <FontAwesomeIcon
                icon="question-circle"
                style={{
                  height: '16px',
                  width: '16px',
                  color: 'var(--colour-interactions-a)',
                  marginLeft: '5px',
                }}
              /> */}
            </div>
            <CheckBox
              value={field.key}
              checked={sharing ? sharing[field.key] : false}
              onChange={handleCheckElement}
              className="tickbox"
            />
          </label>
        );
      })}
    </div>
  );
};

EditSharingSection.propTypes = {
  sharing: sharingType.isRequired,
  setSharing: PropTypes.func.isRequired,
};

/**
 * Data sharing section
 *
 * @param {'none' || 'partial' || 'full'} level setting (for this instance)
 * @param {'none' || 'partial' || 'full'} privacyLevel current setting
 * @param {Function} setPrivacyLevel set fields
 * @param {Jsx} children child content
 */
export const DataSharingSection = ({
  level,
  privacyLevel,
  setPrivacyLevel,
  children,
}) => {
  const [selected, setSelected] = useState(privacyLevel === level);
  const [expanded, setExpanded] = useState(false);

  // set selected state if updated from somewhere else
  useEffect(() => {
    setSelected(level === privacyLevel);
  }, [level, privacyLevel]);

  return (
    <div className={getClassNames({ expandable: true, expanded })}>
      <div className="collapsed-content">
        <div
          className={getClassNames({ icon: true, selected })}
          onClick={(e) => {
            e.preventDefault();
            setPrivacyLevel(level);
          }}
        >
          <FontAwesomeIcon
            icon="check-circle"
            color="var(--colour-interactions-a)"
          />
        </div>
        <div className="info">
          <div className="title">{getPrivacyLevelText(level)}</div>
          <div className="description">{getPrivacyLevelDescription(level)}</div>
        </div>
        <DotButton
          className="button"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {expanded ? (
            <FontAwesomeIcon icon="chevron-up" />
          ) : (
            <FontAwesomeIcon icon="chevron-down" />
          )}
        </DotButton>
      </div>
      <div className="expanded-content">{children}</div>
    </div>
  );
};

DataSharingSection.propTypes = {
  level: privacyLevelType.isRequired,
  privacyLevel: privacyLevelType.isRequired,
  setFields: PropTypes.func.isRequired,
  children: PropTypes.object,
};
