/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// External
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router';
import { getMission } from '../../../api/missions';
import { historyType, routeType } from '../../../enums/propTypes';
import { handleError } from '../../../utils/error';
import { cancellablePromise } from '../../../utils/promise';
import { MissionMenu } from '../../common/menus/MissionMenu';
import { NavBar } from '../../common/Navbar';
// Styling
import './Mission.scss';
import { MissionMap } from './MissionMap';

/**
 * Mission Map page - single mission
 *
 * @param {any} history react router
 * @param {any} location react route
 */
export const Mission = withRouter(({ history, location }) => {
  const [mission, setMission] = useState(null);

  const { missionId } = useParams();

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(updateMission());
    promise.then(() => {}).catch((e) => {});
    log.debug('state', location.state);
    return cancel;
    // eslint-disable-next-line
  }, []);

  const updateMission = async (id) => {
    const savedMission = await getMission(missionId).catch(handleError);
    if (!savedMission) {
      log.error('invalid mission');
      return;
    }
    if (!savedMission.location) {
      savedMission.location = null;
    }
    if (!savedMission.bounds) {
      savedMission.bounds = null;
    } else if (
      savedMission.bounds &&
      savedMission.bounds?.coordinates?.length &&
      savedMission.bounds?.coordinates[0].length < 4
    ) {
      savedMission.bounds = null;
    }

    setMission(savedMission);
  };

  return (
    <div className="mission">
      <NavBar history={history} activeRoute={'view-mission'}>
        <MissionMenu
          history={history}
          missionId={missionId}
          active="map"
          published={mission?.published}
          archived={mission?.archived}
        ></MissionMenu>
      </NavBar>
      {!!mission && (
        <MissionMap
          missions={[mission]}
          history={history}
          targets={location.state}
          updateMission={updateMission}
        ></MissionMap>
      )}
    </div>
  );
});

Mission.propTypes = {
  history: historyType,
  location: routeType,
};
