/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  getInvitationValidation,
  postAcceptInvitation,
} from '../../api/orgs_users';
import { appConfig } from '../../config/appConfig';
import { endpointConfig } from '../../config/endpoint_config.js';
import { useUser, verifyUserAsync } from '../../context/UserContext';
import { historyType } from '../../enums/propTypes';
import { loginSubmission, logout } from '../../utils/auth';
import { cancellablePromise } from '../../utils/promise';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { Welcome } from '../generic-components/Welcome';
import './Invitation.scss';
import { secrets } from '../../config/secrets';

/**
 * Invitation to DOT page
 *
 * @param {any} history react router
 */
export const Invitation = ({ history }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [email, setEmail] = useState('');
  const [orgName, setOrgName] = useState('');

  const userValue = useUser();

  // Invitation details
  const [callsign, setCallsign] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');

  const { id } = useParams();

  // If user is logged in they cannot access this pag
  useEffect(() => {
    const { promise, cancel } = cancellablePromise(verifyInvitation(id));
    promise.then(() => {}).catch((e) => {});
    return cancel;
    //eslint-disable-next-line
  }, []);

  const verifyInvitation = async (invitationId) => {
    try {
      const res = await getInvitationValidation(invitationId);
      if (res) {
        if (userValue.user?.email === res.email) {
          history.push(endpointConfig.frontendEndpoints.missionNav);
        } else {
          logout(userValue);
        }

        log.debug('getInvitationValidation', res);
        setIsVerified(true);
        setEmail(res.email);
        setOrgName(res.organisationName);
      }
    } catch (err) {
      // Debug line
      log.error(`Something went wrong with invitation validation`, err);
    }
  };

  const acceptInvitation = async (e) => {
    e.preventDefault();

    // Input error checking
    if (newPassword !== newPasswordCheck) {
      toast.error('Passwords do not match');
      return;
    }
    if (newPassword.length < 6) {
      toast.error('Password must have a minimum of 6 characters');
      return;
    }

    let user_object = { callSign: callsign, password: newPassword };
    try {
      await postAcceptInvitation(id, user_object);
    } catch (err) {
      log.error('Something went wrong with accepting invitation', err);
      return;
    }

    try {
      const res = await loginSubmission(email, newPassword, {
        func: verifyUserAsync,
        params: userValue.setUser,
      });

      if (res === true) {
        history.push(endpointConfig.frontendEndpoints.login);
      } else if (res === false) {
        // eslint-disable-next-line
        throw `bad invitation failed`;
      }
    } catch (err) {
      log.error('error bad when loggin in after invitation', err);
      return;
    }
  };

  return (
    <Welcome>
      <div className="invitation">
        {isVerified ? (
          <div className="invitation-form">
            <div className="invitation-text">
              <div className="invitation-text-1">You are invited to join</div>
              <div className="invitation-text-2">
                <p style={{ marginRight: '5px' }}>{orgName}</p> on{' '}
                <p style={{ marginLeft: '5px' }}>{appConfig.name}</p>
              </div>
              <div className="invitation-text-3">
                {orgName} has invited you to join their organisation. Please
                enter your details below to proceed.
              </div>
            </div>
            <div className="invitation-email">
              <div className="invitation-email-text">{email} </div>
              <FontAwesomeIcon
                icon="lock"
                style={{
                  height: '20px',
                  width: '20px',
                  color: 'rgba(189, 189, 189, 0.746)',
                  marginLeft: 'auto',
                  marginRight: '20px',
                }}
              />
            </div>
            <form>
              <Input
                label="Callsign or Nickname"
                placeholder={
                  `This is the name used in ` + secrets.EVENT_NAME + `s`
                }
                value={callsign}
                onChange={(e) => setCallsign(e.target.value)}
              />
              <Input
                label="New Password"
                placeholder="Enter your new password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required={true}
              />
              <Input
                label="Confirm Password"
                placeholder="Re-enter your new password"
                type="password"
                value={newPasswordCheck}
                onChange={(e) => setNewPasswordCheck(e.target.value)}
                required={true}
              />
              <DotButton
                type="submit"
                className="primary "
                onClick={acceptInvitation}
              >
                Log In
              </DotButton>
            </form>
          </div>
        ) : (
          <div> Invalid invitation</div>
        )}
      </div>
    </Welcome>
  );
};

Invitation.propTypes = { history: historyType };
