/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { Role } from '../enums/role';

export const author = {
  id: '8a3b9d6d-0756-45b4-baf3-2252c71b06f5',
  callSign: 'jin',
  email: 'jinathy@gmail.com',
  firstname: 'Jin',
  lastname: 'Chan',
  organisationId: '5b89672d-0b7f-48a7-8136-f55e63d3f54d',
  organisationRole: 'Engineer',
  userGroupId: 'e71510e3-6aeb-4f65-b7a8-865cf3d98073',
  accessRoles: [Role.SysAdmin],
  photoUrl:
    'https://bluedotstatic.blob.core.windows.net/user/8a3b9d6d-0756-45b4-baf3-2252c71b06f51620190394899.png',
};

export async function getNotifications() {
  return [
    // {
    //   id: uuid(),
    //   type: NotificationType.orders,
    //   status: NotificationStatus.general,
    //   message: '**Task** 1 has been updated',
    //   author,
    //   read: false,
    //   _ts: 1623979696,
    //   missionId: '9678aca3-590c-4873-b76c-b4729c43b226',
    // },
    // {
    //   id: uuid(),
    //   type: NotificationType.orders,
    //   status: NotificationStatus.general,
    //   message: 'You have been assigned to **Task 1**',
    //   author,
    //   read: false,
    //   _ts: 1623979696,
    //   missionId: '9678aca3-590c-4873-b76c-b4729c43b226',
    // },
    // {
    //   id: uuid(),
    //   type: NotificationType.orders,
    //   status: NotificationStatus.general,
    //   message: 'Order **C. Execution** has been updated',
    //   author,
    //   read: false,
    //   _ts: 1623979696,
    //   missionId: '9678aca3-590c-4873-b76c-b4729c43b226',
    // },
    // {
    //   id: uuid(),
    //   type: NotificationType.orders,
    //   status: NotificationStatus.general,
    //   message: 'You have been assigned to **Task 2**',
    //   author,
    //   read: true,
    //   _ts: 1623979696,
    //   missionId: '9678aca3-590c-4873-b76c-b4729c43b226',
    // },
    // {
    //   id: uuid(),
    //   type: NotificationType.geofence,
    //   status: NotificationStatus.general,
    //   message: '**Target Area** added to **Map**',
    //   author,
    //   read: true,
    //   _ts: 1623929696,
    //   missionId: '9678aca3-590c-4873-b76c-b4729c43b226',
    // },
    // {
    //   id: uuid(),
    //   type: NotificationType.media,
    //   status: NotificationStatus.important,
    //   message: '**Primary Route** added to **Map**',
    //   author,
    //   read: false,
    //   _ts: 1623929696,
    //   missionId: '9678aca3-590c-4873-b76c-b4729c43b226',
    // },
    // {
    //   id: uuid(),
    //   type: NotificationType.mission,
    //   status: NotificationStatus.general,
    //   message: '**Mission area** has been updated',
    //   author,
    //   read: true,
    //   _ts: 1623929696,
    //   missionId: '9678aca3-590c-4873-b76c-b4729c43b226',
    // },
  ];
}
