/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import log from 'loglevel';
import { useEffect, useState, useRef } from 'react';
import { getElevation, what3words } from '../../../api/mapping';
import w3w from '../../../assets/svg/what-three-words.svg';
import { cancellablePromise } from '../../../utils/promise';
import { DotButton } from '../../common/buttons/DotButton';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { isMobile } from '../../../utils/device';
import toast from 'react-hot-toast';
import { getLocalStorage } from '../../../utils/localStorage';

/**
 * Location Info on all geo enabled sidebars
 *
 * @param {string} title integration icon
 * @param {any} location raw coords data only - will lookup for more info
 * @param {any} personnelLocation location data from SignalR
 * @param {any} bearing degree the map has rotated
 * @param {string} activity activity of the user
 * @param {function} setLoading callback to set loading state
 */
export const LocationInfo = ({
  title,
  location,
  personnelLocation,
  bearing,
  activity,
  setLoading,
}) => {
  const [what3Words, setWhat3Words] = useState();
  const [altitude, setAltitude] = useState(null);
  const [heading, setHeading] = useState(null);
  const [speed, setSpeed] = useState(null);
  const [acc, setAcc] = useState(null);
  const [altitudeAcc, setAltitudeAcc] = useState(null);
  const [headingAcc, setHeadingAcc] = useState(null);
  const [speedAcc, setSpeedAcc] = useState(null);
  const [mapBearing, setMapBearing] = useState(bearing);
  const [mgrs, setMgrs] = useState(null);
  const showMGRS = getLocalStorage('showMGRS');

  const id = useRef(uuid());

  useEffect(() => {
    const stub = async () => {
      if (personnelLocation) {
        setAcc(personnelLocation.location.coords.accuracy);

        setWhat3Words({
          nearestPlace: personnelLocation.address,
          words: personnelLocation.w3w,
          map: `https://what3words.com/${personnelLocation.w3w}`,
        });

        setMgrs(personnelLocation.mgrs);

        if (
          (personnelLocation.location?.coords?.altitude ||
            personnelLocation.location?.coords?.altitude === 0) &&
          personnelLocation.location?.coords?.altitude !== -1
        ) {
          setAltitude(personnelLocation.location.coords.altitude);
          setAltitudeAcc(personnelLocation.location.coords.altitude_accuracy);
        } else {
          setAltitude(null);
          setAltitudeAcc(null);
        }
        if (
          (personnelLocation.location?.coords?.heading ||
            personnelLocation.location?.coords?.heading === 0) &&
          personnelLocation.location?.coords?.heading !== -1
        ) {
          setHeading(personnelLocation.location.coords.heading);
          setHeadingAcc(personnelLocation.location.coords.heading_accuracy);
        } else {
          setHeading(null);
          setHeadingAcc(null);
        }
        if (
          (personnelLocation.location?.coords?.speed ||
            personnelLocation.location?.coords?.speed === 0) &&
          personnelLocation.location?.coords?.speed !== -1
        ) {
          if (activity !== 'Stationary') {
            setSpeed(personnelLocation.location.coords.speed);
            setSpeedAcc(personnelLocation.location.coords.speed_accuracy);
          } else {
            setSpeed(0);
            setSpeedAcc(0);
          }
        } else {
          setSpeed(null);
          setSpeedAcc(null);
        }
      } else if (location?.lat) {
        const _w3wrs = await what3words(location.lat, location.lng);
        log.debug('what 3 words', _w3wrs);
        setWhat3Words({
          nearestPlace: _w3wrs.address,
          words: _w3wrs.w3w,
          map: `https://what3words.com/${_w3wrs.w3w}`,
        });

        setMgrs(_w3wrs.mgrs);

        const _altitude = await getElevation(location.lat, location.lng);
        setAcc(null);
        setAltitude(_altitude);
        setAltitudeAcc(null);
        setHeading(null);
        setHeadingAcc(null);
        setSpeed(null);
        setSpeedAcc(null);
      } else {
        setAcc(null);
        setAltitude(null);
        setAltitudeAcc(null);
        setHeading(null);
        setHeadingAcc(null);
        setSpeed(null);
        setSpeedAcc(null);
      }
      setLoading(false);
    };

    log.debug('init', location, personnelLocation);

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [personnelLocation, location]);

  useEffect(() => {
    setMapBearing(bearing);
  }, [bearing]);

  return (
    <>
      <h3>{title}</h3>
      <div className="location-info list">
        {!!!showMGRS && !!location?.lat && (
          <div className="list-item no-border">
            <FontAwesomeIcon
              icon="globe"
              style={{ height: '24px', width: '24px' }}
            ></FontAwesomeIcon>
            <div
              className="text"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toast.success('Copied to clipboard');
                navigator.clipboard.writeText(
                  location.lat.toFixed(5) + ', ' + location.lng.toFixed(5)
                );
              }}
            >
              <div style={{ display: 'flex' }}>Latitude / Longitude</div>
              <span
                data-for={id.current}
                data-tip="Copy to clipboard"
                style={{ display: 'flex' }}
              >
                {location.lat.toFixed(5)}, {location.lng.toFixed(5)}{' '}
                {acc ? (
                  <span
                    style={{
                      marginLeft: '0.5rem',
                      color: 'var(--colour-grey-7)',
                    }}
                  >
                    ±{acc}m
                  </span>
                ) : (
                  ''
                )}
              </span>
            </div>
            <ReactTooltip id={id.current} disable={isMobile()}></ReactTooltip>
          </div>
        )}
        {!!!showMGRS && location?.location && (
          <div className="list-item no-border">
            <FontAwesomeIcon
              icon="globe"
              style={{ height: '24px', width: '24px' }}
            ></FontAwesomeIcon>
            <div
              className="text"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toast.success('Copied to clipboard');
                navigator.clipboard.writeText(
                  location.location?.coords.latitude.toFixed(5) +
                    ', ' +
                    location.location?.coords.longitude.toFixed(5)
                );
              }}
            >
              Latitude / Longitude
              <span
                data-for={id.current}
                data-tip="Copy to clipboard"
                style={{ display: 'flex' }}
              >
                {location.location?.coords?.latitude &&
                location.location?.coords?.longitude
                  ? location.location?.coords.latitude.toFixed(5) +
                    ' ' +
                    location.location?.coords.longitude.toFixed(5)
                  : 'Unknown'}
              </span>
            </div>
            <ReactTooltip id={id.current} disable={isMobile()}></ReactTooltip>
          </div>
        )}

        {!!mgrs && !!showMGRS && (
          <div className="list-item no-border">
            <FontAwesomeIcon
              icon="border-none"
              style={{ height: '24px', width: '24px' }}
            ></FontAwesomeIcon>
            <div
              className="text"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toast.success('Copied to clipboard');
                navigator.clipboard.writeText(mgrs);
              }}
            >
              MGRS
              <span data-for={id.current} data-tip="Copy to clipboard">
                {mgrs}
              </span>
            </div>
            <ReactTooltip id={id.current} disable={isMobile()}></ReactTooltip>
          </div>
        )}
        {heading !== null && (
          <div className="list-item no-border">
            <FontAwesomeIcon
              icon="compass"
              style={{ height: '24px', width: '24px' }}
            ></FontAwesomeIcon>
            <div className="text">
              Heading
              <span style={{ display: 'flex' }}>
                {Math.round(heading)}°{' '}
                {headingAcc ? (
                  <span
                    style={{
                      marginLeft: '0.5rem',
                      color: 'var(--colour-grey-7)',
                    }}
                  >
                    ±{headingAcc}°
                  </span>
                ) : (
                  ''
                )}
              </span>
            </div>
            <FontAwesomeIcon
              icon="location-arrow"
              transform={{ rotate: -45 + heading - mapBearing }}
              style={{ height: '24px', width: '24px' }}
            ></FontAwesomeIcon>
          </div>
        )}
        {altitude !== null && (
          <div className="list-item no-border">
            <FontAwesomeIcon
              icon="mountain"
              style={{ height: '24px', width: '24px' }}
            ></FontAwesomeIcon>
            <div className="text">
              Elevation
              <span style={{ display: 'flex' }}>
                {altitude}m{' '}
                {altitudeAcc ? (
                  <span
                    style={{
                      marginLeft: '0.5rem',
                      color: 'var(--colour-grey-7)',
                    }}
                  >
                    ±{altitudeAcc}m
                  </span>
                ) : (
                  ''
                )}
              </span>
            </div>
          </div>
        )}
        {speed !== null && (
          <div className="list-item no-border">
            <FontAwesomeIcon
              icon="tachometer-alt"
              style={{ height: '24px', width: '24px' }}
            ></FontAwesomeIcon>
            <div className="text">
              Speed
              <span style={{ display: 'flex' }}>
                {speed}km/h{' '}
                {speedAcc ? (
                  <span
                    style={{
                      marginLeft: '0.5rem',
                      color: 'var(--colour-grey-7)',
                    }}
                  >
                    ±{speedAcc}km/h
                  </span>
                ) : (
                  ''
                )}
              </span>
            </div>
          </div>
        )}
        {!!what3Words && (
          <>
            {!!what3Words?.nearestPlace && (
              <div className="list-item no-border">
                <FontAwesomeIcon
                  icon="map-signs"
                  style={{ height: '24px', width: '24px' }}
                ></FontAwesomeIcon>
                <div
                  className="text"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    toast.success('Copied to clipboard');
                    navigator.clipboard.writeText(what3Words?.nearestPlace);
                  }}
                >
                  Address
                  <span data-for={id.current} data-tip="Copy to clipboard">
                    {what3Words?.nearestPlace}
                  </span>
                </div>
                <ReactTooltip
                  id={id.current}
                  disable={isMobile()}
                ></ReactTooltip>
              </div>
            )}
            {!!what3Words?.words && (
              <div className="list-item no-border">
                <img src={w3w} alt="what three words" />
                <div className="text">
                  What 3 Words
                  <span>{what3Words?.words}</span>
                </div>
                {!!what3Words?.map && (
                  <DotButton
                    className="button"
                    onClick={() => {
                      window.open(what3Words.map, '_blank');
                    }}
                  >
                    <FontAwesomeIcon
                      icon="external-link-alt"
                      style={{ height: '24px', width: '24px' }}
                    ></FontAwesomeIcon>
                  </DotButton>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
