/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import battery from '../assets/images/notifications/battery.svg';
import broadcast from '../assets/images/notifications/broadcast.svg';
import calendar from '../assets/images/notifications/calendar.svg';
import critical from '../assets/images/notifications/critical.svg';
import generalOutage from '../assets/images/notifications/general-outage.svg';
import geofence from '../assets/images/notifications/geofence.svg';
import integration from '../assets/images/notifications/integration.svg';
import location from '../assets/images/notifications/location.svg';
import media from '../assets/images/notifications/media.svg';
import mention from '../assets/images/notifications/mention.svg';
import mission from '../assets/images/notifications/mission.svg';
import offline from '../assets/images/notifications/offline.svg';
import orders from '../assets/images/notifications/orders.svg';
import userInteractive from '../assets/images/notifications/user-interactive.svg';
import userLogoff from '../assets/images/notifications/user-logoff.svg';
import userLogon from '../assets/images/notifications/user-logon.svg';
import waypoint from '../assets/images/notifications/waypoint.svg';
import statusCritical from '../assets/images/notifications/status-critical.svg';
import statusImportant from '../assets/images/notifications/status-important.svg';
import { endpointConfig } from '../config/endpoint_config';
import { navigate, navigateToMapTargets, updateState } from './nav';

/** @type {NotificationType} Notification Type */
export const NotificationType = Object.freeze({
  battery: 'battery',
  broadcast: 'broadcast',
  calendar: 'calendar',
  comms: 'comms',
  critical: 'critical',
  generalOutage: 'general-outage',
  geofence: 'geofence',
  integration: 'integration',
  location: 'location',
  media: 'media',
  mention: 'mention',
  mission: 'mission',
  offline: 'offline',
  orders: 'orders',
  userInteractive: 'user-interactive',
  userLogoff: 'user-logoff',
  userLogon: 'user-logon',
  waypoint: 'waypoint',
  voice: 'voice',
});

/** @type {NotificationStatus} Notification Status */
export const NotificationStatus = Object.freeze({
  critical: 1,
  important: 2,
  general: 3,
  offline: 4,
  tracked: 5,
});

/**
 * Get string representation of NotificationStatus
 *
 * @param {*} status notification status
 * @return {string} status text
 */
export const notificationStatusText = (status) => {
  switch (status) {
    case NotificationStatus.critical:
      return 'Critical';
    case NotificationStatus.important:
      return 'Important';
    default:
      return 'General';
  }
};

/**
 * Get icon for notification
 *
 * @param {*} notificationType notification type
 * @return {string} icon svg
 */
export const getNotificationIcon = (notificationType) => {
  switch (notificationType) {
    case NotificationType.battery:
      return battery;
    case NotificationType.broadcast:
      return broadcast;
    case NotificationType.calendar:
      return calendar;
    case NotificationType.critical:
      return critical;
    case NotificationType.generalOutage:
      return generalOutage;
    case NotificationType.geofence:
      return geofence;
    case NotificationType.integration:
      return integration;
    case NotificationType.location:
      return location;
    case NotificationType.media:
      return media;
    case NotificationType.mention:
      return mention;
    case NotificationType.offline:
      return offline;
    case NotificationType.orders:
      return orders;
    case NotificationType.userInteractive:
      return userInteractive;
    case NotificationType.userLogoff:
      return userLogoff;
    case NotificationType.userLogon:
      return userLogon;
    case NotificationType.waypoint:
      return waypoint;
    case 'status-important':
      return statusImportant;
    case 'status-critical':
      return statusCritical;
    default:
      return mission;
  }
};

/**
 * Action to perform when clicking on a notification. There may be more than one notification when clicking on the notification banner.
 *
 * @param {any} history react router
 * @param {Notification[]} notifications array of notifications
 */
export const notificationAction = (history, notifications) => {
  switch (notifications[0].type) {
    case NotificationType.battery:
      navigate(
        history,
        endpointConfig.frontendEndpoints.mission.replace(
          ':missionId',
          notifications[0].missionId
        )
      );

      break;
    case NotificationType.broadcast:
      navigate(
        history,
        endpointConfig.frontendEndpoints.missionComms.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.comms:
      navigateToMapTargets(history, notifications[0].missionId, [
        {
          type: 'thread',
          threadId: notifications[0].target,
        },
      ]);
      break;
    case NotificationType.calendar:
      navigate(
        history,
        endpointConfig.frontendEndpoints.missionOrders.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.critical:
      const state = notifications.filter((n) => n.target).map((n) => n.target);
      navigateToMapTargets(history, notifications[0].missionId, state);
      break;
    case NotificationType.generalOutage:
      break;
    case NotificationType.geofence:
      navigate(
        history,
        endpointConfig.frontendEndpoints.mission.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.integration:
      navigate(
        history,
        endpointConfig.frontendEndpoints.mission.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.location:
      navigate(
        history,
        endpointConfig.frontendEndpoints.mission.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.media:
      navigate(
        history,
        endpointConfig.frontendEndpoints.mission.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.mention:
      navigate(
        history,
        endpointConfig.frontendEndpoints.missionOrders.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.mission:
      navigate(
        history,
        endpointConfig.frontendEndpoints.mission.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.offline:
      break;
    case NotificationType.orders:
      navigate(
        history,
        endpointConfig.frontendEndpoints.missionOrders.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.userInteractive:
      break;
    case NotificationType.userLogoff:
      break;
    case NotificationType.userLogon:
      break;
    case NotificationType.waypoint:
      navigate(
        history,
        endpointConfig.frontendEndpoints.mission.replace(
          ':missionId',
          notifications[0].missionId
        )
      );
      break;
    case NotificationType.voice:
      updateState(history, [
        {
          type: 'voice',
          action: notifications[0].target,
        },
      ]);
      break;
    default:
      break;
  }
};
