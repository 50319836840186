import './notification-preferences.scss';
import { DotButton } from '../../common/buttons/DotButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LightScrollbar } from '../../common/Scrollbars';
import { useEffect, useState } from 'react';
import { CheckBox } from '../../common/CheckBox';
import { PropTypes } from 'prop-types';

/**
 * Sidebar - edit notification preferences
 *
 * @param {Function} closeSidebar callback to close sidebar
 */
export const NotificationPreferences = ({ closeSidebar }) => {
  const [pushNotifications, setPushNotifications] = useState([]);
  const [generalActivity, setGeneralActivity] = useState([]);
  const [comms, setComms] = useState([]);

  const exit = () => {
    closeSidebar();
  };

  const handleUpdate = (e) => {};

  useEffect(() => {
    setPushNotifications([
      {
        key: 'pause_all',
        name: 'Pause All',
        value: true,
      },
    ]);

    setGeneralActivity([
      {
        key: 'mission',
        name: 'Mission Parameters',
        value: true,
      },
      {
        key: 'integrations',
        name: 'Integrations',
        value: true,
      },
      {
        key: 'geofence',
        name: 'Geofence',
        value: true,
      },
      {
        key: 'waypoint',
        name: 'Waypoint',
        value: true,
      },
      {
        key: 'media',
        name: 'Media Waypoint',
        value: true,
      },
      {
        key: 'orders',
        name: 'Orders / Tasks',
        value: true,
      },
      {
        key: 'user_logon_logoff',
        name: 'User log on/off',
        value: true,
      },
      {
        key: 'user_inactive',
        name: 'User Inactive',
        value: true,
      },
    ]);

    setComms([
      {
        key: 'chat',
        name: 'Chats',
        value: true,
      },
      {
        key: 'mention',
        name: 'Mentions',
        value: true,
      },
    ]);
  }, []);

  return (
    <div className="notification-preferences">
      <div className="top">
        <div className="heading">
          <div className="text">Notification Preferences</div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <LightScrollbar className="content">
        <div className="blocks">
          <PreferencesSection
            title="Push Notifications"
            fields={pushNotifications}
            setFields={setPushNotifications}
          ></PreferencesSection>
          <PreferencesSection
            title="General Activity (In App)"
            fields={generalActivity}
            setFields={setGeneralActivity}
          ></PreferencesSection>
          <PreferencesSection
            title="Comms (In App)"
            fields={comms}
            setFields={setComms}
          ></PreferencesSection>
        </div>
      </LightScrollbar>
      <div className="foot">
        <DotButton onClick={(e) => handleUpdate(e)} className="primary block">
          Save Changes
        </DotButton>
      </div>
    </div>
  );
};

NotificationPreferences.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
};

/**
 * Notification preferences section
 *
 * @param {string} title section title
 * @param {any[]} fields section fields
 * @param {Function} setFields set fields
 */
function PreferencesSection({ title, fields, setFields }) {
  const handleCheckElement = (event) => {
    fields.forEach((field) => {
      if (field.key === event.target.value) field.value = event.target.checked;
    });
    setFields([...fields]);
  };

  return (
    <div className="information-block">
      <div className="subheading">{title}</div>
      {fields.map((field) => {
        return (
          <label key={field.key} className="field">
            <div className="text">
              {field.name}
              <FontAwesomeIcon
                icon="question-circle"
                style={{
                  height: '16px',
                  width: '16px',
                  color: 'var(--colour-interactions-a)',
                  marginLeft: '5px',
                }}
              />
            </div>
            <CheckBox
              value={field.key}
              checked={field.value}
              onChange={handleCheckElement}
              className="tickbox"
            />
          </label>
        );
      })}
    </div>
  );
}

PreferencesSection.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.bool,
    })
  ),
  setFields: PropTypes.func.isRequired,
};
