import { historyType } from '../../enums/propTypes';
import { ContentPage } from '../generic-components/ContentPage';
import bgShape from '../../assets/svg/dot-bg-shape.svg';

/**
 * WEBSITE - DOT Terms Page
 *
 * @param {any} history react router
 */
export const TermsOfService = ({ history }) => {
  return (
    <ContentPage history={history}>
      <h1>Terms of Service</h1>
      <div className="shape">
        <img src={bgShape} alt="bg shape" />
      </div>
      <h2>
        <center>
          AUSTAC Mobile Application End User Licence Agreement (EULA)
        </center>
      </h2>
      <p>
        This End User Licence Agreement (“EULA”) constitutes a binding a legal
        agreement made between INSITEC MIS SYSTEMS PTY LTD ACN 161 023 856 of
        Level 6, 30 Collins Street, Melbourne, Victoria, Australia 3000 (
        <strong>“we”</strong>) and the user (<strong>“you”</strong>) of our
        “AUSTAC Mobile App” (<strong>“the App”</strong>).
      </p>
      <p>
        Unless the context requires otherwise, references to “the App” covers
        all included programs, data, user interface and printed or electronic
        documentation.
      </p>
      <h2>1. Acceptance of terms</h2>
      <p>
        1.1 Your use of the App is conditional upon your acceptance of this
        EULA. If you do not agree with these terms you must not use the App.
      </p>
      <p>
        1.2 Acceptance will bind you and all of your employees and any other
        person using the App on your behalf.
      </p>

      <h2>2. Registration </h2>
      <p>
        2.1 To utilise the App, you will need to register each device upon which
        the App is to run, by providing any necessary requested details. In
        addition, you must notify us of any changes such as upgrades to,
        replacements of, or adding any additional devices. Upon registration we
        may collect information such as your name, address, email address and
        mobile phone number. You agree to provide accurate and complete i
        nformation and to keep this information (as well as any payment
        information required) updated when interacting with us.
      </p>
      <p>
        2.2 You are solely responsible for any activity occurring on your
        account. You must maintain the security of your account details
        including passwords. If you suspect unauthorised use of your account or
        that your password is no longer secure, you must notify us immediately.
        You may not use another user’s account.
      </p>

      <h2>Acknowledgments for Apple / iOS users</h2>
      <p>
        3.1 The provisions of this clause 3 apply ONLY where you download and
        use the App on an iPhone or other iOS enabled device.
      </p>
      <p>3.2 You acknowledge that:</p>
      <ul>
        <li>
          (a) This EULA is concluded between us and you, and that Apple, Inc
          (and its related entities) (“Apple”) has no responsibility for the
          App;
        </li>
        <li>
          (b) In accordance with the terms of this EULA you are granted a
          non-transferable licence to use the App on any Apple-branded products
          that you own, or control and as permitted by the usage rules set out
          in the Apple Media Services Terms and Conditions, except that the App
          may be accessed and used by other accounts associated with you via
          family sharing or volume purchasing;
        </li>
        <li>
          (c) Apple has no obligation whatsoever to furnish any maintenance and
          support services with respect to the App;
        </li>
        <li>
          (d) In the event of any failure of the App to conform to any
          applicable warranty, you may notify Apple and Apple may refund the
          purchase price for the Application to you (if applicable);
        </li>
        <li>
          (e) To the maximum extent permitted by applicable law, Apple will have
          no other warranty obligation in respect of the App, and any other
          claims, losses, liability, damages, costs or expenses attributable to
          any failure to conform to any warranty (subject to the other terms of
          this EULA) will be our sole responsibility;
        </li>
        <li>
          (f) We are responsible for addressing any claims you or any third
          party may have in relation to the App or your possession and se of it,
          including but not limited to (i) product liability claims; (ii) any
          claim that the App fails to conform to any applicable legal or
          regulatory requirement; and (iii) claims arising under consumer
          protection, privacy or similar legislation;
        </li>
        <li>
          (g) In the event of any third-party claim that the App or your use of
          it infringes that third party’s intellectual property rights, we will
          be solely responsible for the investigation, defence, settlement and
          discharge of any such intellectual property infringement compliance;
        </li>
        <li>
          (h) You represent and warrant that you are not located in a country
          that is subject to a U.S Government embargo, or that has been designed
          by the U.S Government as a “terrorist supporting” country and that you
          are not listed on any US Government list of prohibited or restricted
          parties; and
        </li>
        <li>
          (i) You acknowledge and agree that Apple and its subsidiaries are
          third party beneficiaries of this EULA and that upon your acceptance
          of its terms and conditions, Apple will have the right (and will be
          deemed to have accepted the right) to enforce the EULA against you as
          a third-party beneficiary.
        </li>
      </ul>

      <h2>4. Licence scope and limitations on use</h2>
      <p>
        4.1 We grant you a limited, non-exclusive, revocable and
        non-transferable license to use the App on an Android enabled or iOS
        enabled handset that you own or control.
      </p>
      <p>
        4.2 This licence does not allow you to use the App on any handset or
        device that you do not own or control and you may not distribute or make
        the App available over a network where it could be used by multiple
        devices at the same time.
      </p>
      <p>
        4.3 The App may only be used on a device that meets the compatibility
        requirements notified by us from time to time. You agree that it shall
        be your responsibility to ensure that your devices meet such
        requirements and are maintained in good order.
      </p>
      <p>
        4.4 You must supervise and control the use of the App in accordance with
        the terms and conditions of this EULA and must ensure that your
        employees, sub-contractors and other agents who have authorised access
        to the App are made aware of the terms and conditions of this EULA.
      </p>
      <p>
        4.5 You may not re-sell, rent, sub-license or otherwise deal with your
        licence to the App.
      </p>
      <p>
        4.6 You may not give details of or allow any third party to use any
        passwords, account numbers or other access or security-related codes in
        connection with the use of the App.
      </p>

      <h2>5. Licence fee</h2>
      <p>
        5.1 You agree to pay the licence/ subscription fee that has been
        notified to you in respect of the App. You are not entitled to use the
        App until the licence/ subscription fee has been paid.
      </p>
      <h2>6. Term, termination and suspension</h2>
      <p>
        6.1 The licence to use the App continues, subject to your payment of any
        applicable licence or subscription fees, until terminated by us or by
        you. Without limiting the foregoing, the licence shall terminate at the
        end of your subscription period. We may also terminate this licence or
        suspend access to the App (the choice of which shall be in our
        discretion) for such period as we determine and without prior notice if
        you breach or otherwise fail to comply with any of the terms of this
        EULA.
      </p>
      <p>
        6.2 We may suspend access to the App if necessary to conduct maintenance
        or repair of relevant equipment or systems.
      </p>
      <h2>7. Your acknowledgments</h2>
      <p>7.1 You agree and acknowledge that:</p>
      <ul>
        <li>
          (a) Your ability to use the full functionality of the App will depend
          on you being and remaining a subscriber to a 5G or higher mobile
          network;
        </li>
        <li>
          (b) Your use of the App will consume mobile battery charge and your
          use of the App may incur data charges, as use of the App will involve
          downloading of images, content and other items that may attract data
          fees;
        </li>
        <li>
          (c) The App does not provide for international roaming, and
          international roaming costs will be incurred if the App or your mobile
          device is used outside Australia;
        </li>
        <li>
          (d) We may modify, update, add to or remove functionality of the App
          from time to time;
        </li>
        <li>
          (e) You must comply with the terms of any applicable third-party end
          user licence for software/applications that interact with the App;
        </li>
        <li>
          (f) YThe App may enable you to access content over which we have no
          control and about which we make no representation as to its accuracy;
        </li>
        <li>
          (g) While we take reasonable steps in this regard, the App cannot be
          guaranteed safe from corruption or access through malicious third
          party hacking or similar activities;
        </li>
        <li>
          (h) While we endeavour to impose standards of conduct and rules in
          relation to content, some user or third-party materials accessible
          through the App may include content and data which we have no control
          over. We cannot warrant accuracy, legitimacy or legality of such
          third-party content and you acknowledge and agree that we have no
          liability to you in respect of such third-party content should you
          find such material offensive, indecent or objectionable; and
        </li>
        <li>
          (i) The continued availability of the App and all functionality and
          services provided through use of the App may be subject to numerous
          factors, including routine maintenance as well as factors outside of
          our control (such as malfunction in equipment, software, internet
          access or delay or failure of transmission).
        </li>
      </ul>

      <h2>8. Rules of conduct / content</h2>
      <p>
        8.1 You must abide by the following rules when accessing or using the
        App, including any functions enabling you to post comments, material, or
        interact with others:
      </p>
      <ul>
        <li>
          (a) You agree not to use the App for any unlawful purpose and not to
          violate any applicable local, state, national or international law;
        </li>
        <li>
          (b) You must not and must not permit any other person to use the App
          or the functionality and information it provides in any way which is
          offensive or results or could result in loss, damage, injury or death
          to any person;
        </li>
        <li>
          (c) You agree not to defame, abuse, harass, stalk, threaten or
          otherwise violate the legal rights of others;
        </li>
        <li>
          (d) You agree not to submit any information about any person by which
          they may be personally identified without their prior consent or which
          compromises another person’s privacy or breaches confidentiality;
        </li>
        <li>
          (e) You agree not to impersonate any person or entity, or falsely
          state or otherwise misrepresent your affiliation with a person or
          entity;
        </li>
        <li>
          (f) You agree not to submit content that contains material that is
          inappropriate, tasteless, unlawful, threatening, abusive, hateful,
          profane, defamatory, obscene, pornographic, racially vilifying,
          deliberately provocative or indecent;
        </li>
        <li>
          (g) You agree not to violate the property rights of others, and you
          agree not to post any content that infringes any patent, trade mark,
          trade secret, copyright or other proprietary rights of any party; and
        </li>
        <li>
          (h) You agree not to upload files, or cause users to upload files,
          that contain viruses, worms, "trojan horses", corrupted files, or any
          similar software or programs that may adversely affect the operation
          of another's hardware.
        </li>
      </ul>
      <h2>9. Updates and maintenance</h2>
      <p>
        9.1 We may modify or update the App at any time without prior notice.
        You may be required to download the latest version of the App and any
        updates which will be uploaded to the Android and/or iOS markets. It is
        your responsibility to ensure that you have obtained the most up to date
        version of the App at any given time. Updates may include bug fixes,
        error corrections, workflow and design changes.
      </p>
      <p>
        9.2 We may provide maintenance and support in relation to the App at our
        sole discretion, and subject to any other agreement we have with you or
        your organisation.
      </p>
      <h2>10. Warranty / limitation of liability</h2>
      <p>
        10.1 Except as required under any consumer protection law (including the
        Australian Consumer Law) we do not provide any guarantee or warranties
        in relation to the App or its functioning.
      </p>
      <p>
        10.2 Further to clause 10.1 we exclude all warranties and
        representations that the App and the services / functionality it or we
        provide will meet all of your requirements or expectations, will be
        complete, accurate, up-to-date, free from errors or omissions,
        programming bugs or viruses or have full functionality. We do not
        warrant that the App or its functionality will be continuously available
        or will be available without interruption and we disclaim liability for
        all loss for damage that may arise through your use of, or your
        inability to use, the App.
      </p>
      <p>
        10.3 Further, the remedies for breach of contract or any breach of any
        consumer protection law shall be limited to the minimum remedy that may
        be available in any respect of any such breach. Without limiting the
        foregoing, to the extent possible we exclude all liability for:
      </p>
      <ul>
        <li>
          (a) Special, indirect or consequential loss, including loss of
          profits, loss of data or business interruption;
        </li>
        <li>(b) Any loss that was not reasonably foreseeable;</li>
        <li>
          (c) Loss or damage that was not caused by our negligence, our breach
          of statutory obligations, our breach of contract;
        </li>
        <li>(d) Any loss or damage caused or contributed to by you; and</li>
        <li>
          (e) Any loss or damage the extent of which could have been minimised
          by you.
        </li>
      </ul>
      <h2>11. Intellectual property</h2>
      <p>
        11.1 Unless otherwise indicated, we own or use under licence all
        copyright and other intellectual property rights associated with the
        App. Your licence to use these intellectual property rights is strictly
        as set out in this EULA. All other rights are reserved.
      </p>
      <p>
        11.2 Except if and to the extent only expressly permitted at law, you
        must not copy, decompile, reverse engineer, rent, lend, sell,
        redistribute, sublicense, attempt to derive the source code of or modify
        the App, nor create any derivative works of the App.
      </p>
      <p>
        11.3 “AUSTAC” is a trade mark of Insitec MIS Systems Pty Ltd. All trade
        marks, logos, images referred to or made available via the App are the
        property of their respective owners.
      </p>
      <p>
        11.4 If any third-party claims that the App infringes their intellectual
        property rights, you agree that we shall be solely responsible for the
        investigation, defence, settlement and discharge of any such claim.
      </p>
      <h2>12. Collection of data and privacy</h2>
      <p>
        12.1 You agree and acknowledge that all personal information (including
        but not limited to your photograph or likeness (if provided), your name,
        your employment details and connections) that you make available through
        the App may be available to other end users of the App including in
        connection with the same “missions” and similar events.
      </p>
      <p>
        12.2 You agree that you may be required to disclose and make available
        to other users certain personal information in order to use the App and
        obtain the benefits of its features. Our management of and practices
        with respect to personal information are set out at in our privacy
        policy at <a href="https://www.insitec.com.au">www.insitec.com.au</a>,
        and in Appendix 1 to this EULA.
      </p>
      <p>
        12.3 We will comply with any obligations that we have under the Privacy
        Act 1988 (Cth).
      </p>
      <p>
        12.4 We may collect and use technical data and related information that
        does not identify you (and hence is not personal information) including
        information about your device, system and application software and
        peripherals, that may be gathered to facilitate the provision of
        updates, product support. The data that we collect and utilise for our
        own purposes (as opposed to information that is used by you and other
        users) is typically aggregated data and does not identify individuals.
      </p>
      <h2>13. Liability and indemnity</h2>
      <p>
        13.1 You shall at all times indemnify and hold us and our directors,
        officers, employees and agents (<strong>“those indemnified”</strong>)
        harmless from and against any loss (including reasonable legal costs and
        expenses) or liability reasonably incurred or suffered by any of those
        indemnified from any proceedings against those indemnified where such
        loss or liability was (without limitation) caused by (i) a breach by you
        of your obligations under this EULA; (ii) any wilful, unlawful or
        negligent act or omission by you; and (iii) any use of or reliance on
        the App.
      </p>
      <h2>14. Governing law</h2>
      <p>
        14.1 This EULA is subject to and governed by the laws of the State of
        Victoria, Australia. You submit to the exclusive jurisdiction of the
        courts of the State of Victoria, Australia.
      </p>
      <h2>15. Questions, complaints or claims</h2>
      <p>
        15.1 If you have any question, complaints or claims in connection with
        your use of the App these should be addressed to:
      </p>
      <p>
        Privacy Officer <br />
        Insitec Pty Ltd
        <br />
        PO Box 1686
        <br />
        Fyshwick ACT 2609
        <br />
        Telephone: <a href="tel:0262324541">(02) 6232 4541</a>
        <br />
        Email:{' '}
        <a href="mailto:privacy@insitec.com.au">privacy@insitec.com.au</a>
      </p>
      <h2>16. Interaction with other parties’ terms</h2>
      <p>
        16.1 To the extent permissible at law, the terms of this EULA shall
        replace and have precedence over any third party terms (such as the
        Licensed Application End User Licence Agreement of Apple, Inc.) that
        would otherwise govern the use and licence of the App.
      </p>

      <h2>
        <center>Appendix 1: Use of Personal Information</center>
      </h2>
      <h2>What personal information is collected from you?</h2>
      <p>
        We only collect from you the minimum personal information relevant to
        the core functionality of the App.
      </p>
      <p>
        We collect location data only when necessary for your use of the App.{' '}
      </p>
      <p>
        You can select the level of disclosure of personal information that
        occurs when using AUSTAC. However, to be able to use and enjoy the
        functionality of AUSTAC, a certain base level of disclosure will be
        required.
      </p>
      <p>
        You may provide (and we will collect) some or all of the following
        personal information and data to us, through use of the App:
      </p>
      <ul>
        <li>Your personal name;</li>
        <li>A pseudonym or “call sign” if you choose to use one;</li>
        <li>Your log-in details;</li>
        <li>Your photograph or image (optional) if you upload one;</li>
        <li>
          Details of your geographical location and location data (as it changes
          over time, when using the App);
        </li>
        <li>Your log-in history to the App; and</li>
        <li>
          Communication metadata and chat logs, and details of the time and
          length of calls.
        </li>
      </ul>
      <h2>
        Why is this information collected and how do we use or disclose this
        information?
      </h2>
      <p>
        The above information is collected primarily to enable us to operate the
        App and associated platform, to track its use for administrative and
        billing purposes and for you to obtain the benefit of its functionality.
      </p>
      <p>
        If and to the extent that any third party with whom the App shares any
        App user data (in accordance with guidelines of the Apple App Store and
        similar third-party policies), such as analytics tools, advertising
        networks and third-party SDKs, those third parties will provide the same
        or equal protection as stated in this policy.
      </p>
      <p>
        Except as stated in this policy, we do not share data obtained from the
        use of the App or your subscription to it, outside the AUSTAC platform.
      </p>
      <p>
        No personal information will be given to third parties for commercial
        purposes, such as marketing or advertising.
      </p>
      <p>
        Where you as the App user (or, more likely the organisation on whose
        behalf you use the App) you partner with a third party in any session or
        “mission”, some of your personal information may be shared with others
        within that third party organisation for the purposes of obtaining the
        benefits of the functionality of AUSTAC (for example, to help locate you
        in a geographical area).
      </p>
      <p>
        As the App user you can manage your privacy settings, but doing so may
        to some extent limit your ability to utilise all functionality of the
        App.
      </p>
      <p>
        Where you are an individual who is engaged by or is otherwise acting for
        or on behalf of an organisation who has the primary subscription to the
        App and platform with us, some of the above personal information may be
        provided to the primary subscriber (such as your employer). This would
        include, but not necessarily be limited to, your log-in details, your
        log-in history, photographs and callsigns, any audiovisual material you
        may upload through AUSTAC (if and where such functionality exists), your
        location data, your communication metadata, such as chat logs and
        details of times and durations of calls and sessions using the App.
      </p>
      <p>We do not transfer your personal information outside Australia. </p>

      <h2>Retention of data</h2>
      <p>
        We will generally retain personal information for 7 years from the date
        of its collection. If you request us to delete or destroy personal
        information we hold about you before this date, you can contact us with
        a request for same.
      </p>
      <p>
        Please include your name, email address and/or telephone number and
        clearly describe your complaint. We will acknowledge your complaint and
        respond to your complaint with a reasonable period of time (usually
        within 30 days). If you are not satisfied with our response to your
        complaint, you may lodge a formal compliant with the Office of the
        Australian Information Commissioner.
      </p>
      <h2>How to contact us </h2>
      <p>
        You have a right to access your personal information, subject to certain
        exceptions provided for in the Privacy Act. If you equire access to your
        personal information, please contact our Privacy Officer and you should
        receive a response within 30 days. For security reasons, you will be
        required to put your request in writing and provide proof of your
        identity.
      </p>
      <p>
        If we refuse to give you access to your personal information, we will
        notify you in writing setting out the reasons.
      </p>
      <p>
        If you would like more information or have any questions in relation to
        this Privacy Policy, or to access or correct your personal information,
        or make a complaint, please contact:
      </p>
      <p>
        Privacy Officer <br />
        Insitec Pty Ltd
        <br />
        PO Box 1686
        <br />
        Fyshwick ACT 2609
        <br />
        Telephone: <a href="tel:0262324541">(02) 6232 4541</a>
        <br />
        Email:{' '}
        <a href="mailto:privacy@insitec.com.au">privacy@insitec.com.au</a>
      </p>
    </ContentPage>
  );
};

TermsOfService.propTypes = { history: historyType };
