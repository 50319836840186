/**
 * Privacy Level
 *
 * Maps to organisation.privacyLevel
 */
export const PrivacyLevel = Object.freeze({
  None: 'none',
  Partial: 'partial',
  Full: 'full',
});

export const getPrivacyLevelText = (privacyLevel) => {
  switch (privacyLevel) {
    case PrivacyLevel.Full:
      return 'Full Privacy';
    case PrivacyLevel.Partial:
      return 'Partial Privacy';
    case PrivacyLevel.None:
    default:
      return 'No Privacy';
  }
};

export const getPrivacyLevelDescription = (privacyLevel) => {
  switch (privacyLevel) {
    case PrivacyLevel.Full:
      return 'Anonymous alias will replace names';
    case PrivacyLevel.Partial:
      return 'Callsigns will replace names';
    case PrivacyLevel.None:
    default:
      return 'Names will be visible';
  }
};

export const getPrivacyLevelIcon = (privacyLevel) => {
  switch (privacyLevel) {
    case PrivacyLevel.Full:
      return 'mask';
    case PrivacyLevel.Partial:
      return 'mask';
    case PrivacyLevel.None:
    default:
      return 'eye';
  }
};

export const generalSettings = [
  { key: 'userFullname', text: 'Full Name' },
  { key: 'userEmail', text: 'Email' },
  { key: 'userCallSign', text: 'Callsign' },
  { key: 'userPhotoUrl', text: 'Photo' },
];

export const organisationSettings = [
  { key: 'orgDescription', text: 'Organisation Description' },
  { key: 'orgName', text: 'Organisation Name' },
  { key: 'orgGroupName', text: 'Group Name' },
  { key: 'orgGroupDescription', text: 'Group Description' },
  { key: 'orgGroupLocation', text: 'Group Location' },
];

export const userSettings = [
  { key: 'userRole', text: 'Role' },
  { key: 'userStatus', text: 'Status' },
  { key: 'userOrders', text: 'Orders' },
  { key: 'userTasks', text: 'Tasks' },
  { key: 'userTrackHistory', text: 'Track History' },
];

export const actionSettings = [
  { key: 'incomingCalls', text: 'Incoming Calls' },
  { key: 'incomingComms', text: 'Incoming Comms' },
  { key: 'incomingFileTransfers', text: 'Incoming File Transfers' },
];
