/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import lockSvg from '../../../assets/svg/lock.svg';
import saveSvg from '../../../assets/svg/save.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Control7 - Date and time picker with DTG field and 2 action  buttons
 *
 */
export const Control7 = ({
  control,
  hideActionButtons = false,
  defaultLocked = false,
}) => {
  const [time, setTime] = useState();
  const [date, setDate] = useState();
  const [dtg, setDtg] = useState();
  const [locked, setLocked] = useState(defaultLocked ? true : false);

  const handleLock = () => {
    setLocked(!locked);
  };

  String.prototype.replaceAt = function (index, character) {
    return (
      this.substr(0, index) + character + this.substr(index + character.length)
    );
  };

  useEffect(() => {
    if (date && time) {
      let _dtg = moment(date).format('DD HHmm MMMYY');
      _dtg = _dtg.replaceAt(
        3,
        time.substring(0, 2) + time.substring(3, 5) + 'Z'
      );
      setDtg(_dtg);
    }
  }, [date, time]);

  return (
    <div className="control-7">
      <div className="details">
        <div className="row">
          <div className="left">{control.ref}</div>
          <div>
            <div className="label">
              {control.label[0]}
              {!!control.required && <div className="required">* Required</div>}
            </div>
            <DatePicker
              disabled={locked}
              showIcon={true}
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="dd/MM/yyyy"
              customInput={
                <div className="react-datepicker-wrapper">
                  <input
                    onChange={(e) => setTime(e.target.value)}
                    value={date ? moment(date).format('DD/MM/yyyy') : ''}
                    disabled={locked}
                  />
                  <FontAwesomeIcon icon="calendar-alt" />
                </div>
              }
            />
          </div>

          <div className="timepicker-wrapper">
            <div className="label">
              {control.label[1]}
              {!!control.required && <div className="required">* Required</div>}
            </div>
            <input
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              disabled={locked}
            />
          </div>

          <div
            className="input"
            style={{ marginTop: '20px', marginLeft: '30px' }}
          >
            <input disabled={true} value={dtg} />
          </div>
        </div>
        <div className="row">
          <div className="left"></div>
          <div className="info">
            <div className="tip">{control.tip}</div>
          </div>
        </div>
      </div>
      {!!!hideActionButtons && (
        <div className="action-buttons">
          <div className="row">
            <button onClick={handleLock}>
              <img src={lockSvg} alt="lock" />
            </button>
            <button>
              <img src={saveSvg} alt="save" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

Control7.propTypes = {
  control: PropTypes.any.isRequired,
  hideActionButtons: PropTypes.bool,
  defaultLocked: PropTypes.bool,
};
