/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getOrgSingle } from '../../api/orgs_users';
import { SIDEBARS, useSidebar } from '../../context/SidebarContext';
import { useUser, verifyUserAsync } from '../../context/UserContext';
import { Role } from '../../enums/role';
import { cancellablePromise } from '../../utils/promise';
import { displayName } from '../../utils/string';
import { CreateEditUser } from '../sidebars/CreateEditUser';
import './AdminBar.scss';
import { Avatar } from './Avatar';
import { DotButton } from './buttons/DotButton';
import { Sidebar } from './Sidebar';
import { logout } from '../../utils/auth';
import { useLocation } from '../../context/LocationContext';
import { useNotification } from '../../context/NotificationContext';
import { removeLocation } from '../../api/missions';
import { appConfig } from '../../config/appConfig';
import { endpointConfig } from '../../config/endpoint_config';
import { ConfirmModal } from '../modals/ConfirmModal';

/**
 * Admin header bar
 *
 * @param {string} title title
 * @param {Function} onBack
 * @param {Boolean} showOnBack show back button
 * @param {any} history react router
 * @returns
 */
export const AdminBar = ({ title, onBack, showOnBack = true, history }) => {
  const { user, setUser } = useUser();
  const [org, setOrg] = useState(null);
  const sidebars = useSidebar();
  const [profileUser, setProfileUser] = useState(null);
  const [userAppend, setUserAppend] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [iWantToLogOut, setIWantToLogOut] = useState(false);
  const btnRef = useRef();
  const { closeAll } = useSidebar();
  const userValue = useUser();
  const { missionId } = useParams();
  const _notifications = useNotification();
  const locationServices = useLocation();

  useEffect(() => {
    if (user) {
      const { promise, cancel } = cancellablePromise(
        getOrgSingle(user.organisationId)
      );
      promise
        .then((o) => {
          const userAppend = { ...user, organisation: o };
          setUserAppend(userAppend);
          setOrg(o);
        })
        .catch((e) => {});
      return cancel;
    }
    return () => {};
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const closeDropdown = (e) => {
      const path = e.composedPath ? e.composedPath() : e.path;
      if (path[0] !== btnRef.current) {
        setShowDropdown(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, []);

  useEffect(() => {
    _notifications.setCurrentMissionId(missionId);
  }, [_notifications, missionId]);

  const doLogout = async () => {
    closeAll();

    if (locationServices.allowDesktopSharing && _notifications?.lastMissionId) {
      await removeLocation(_notifications.lastMissionId, userValue.user.id);
    }

    logout(userValue);
    history.push(endpointConfig.frontendEndpoints.login);
  };

  const getRole = () => {
    if (org) {
      if (user?.accessRoles && user?.accessRoles?.length) {
        return user.accessRoles.map((r) => {
          switch (r) {
            case Role.SysAdmin:
              return `${org.organisationName} - System management`;
            case Role.OrgAdmin:
              return `${org.organisationName} - Organisation management`;
            default:
              return 'User';
          }
        })[0];
      } else {
        return user.organisationRole || 'User';
      }
    }
    return '';
  };

  return (
    <div className="admin-bar">
      <div className="title">
        {showOnBack && typeof onBack === 'function' ? (
          <DotButton
            className="back"
            onClick={() => {
              sidebars.closeAll();
              onBack();
            }}
          >
            <FontAwesomeIcon
              icon={'arrow-left'}
              style={{
                height: '24px',
                width: '24px',
              }}
            />
          </DotButton>
        ) : (
          <></>
        )}
        {title}
      </div>
      <div className="profile">
        <div className="user">
          <Avatar entity={userAppend} />
          <div className="details">
            <div className="name">{displayName(user)}</div>
            <div className="role">{getRole()}</div>
          </div>
        </div>
        <div>
          <DotButton
            innerRef={btnRef}
            className={'button step-6'}
            onClick={async () => {
              setShowDropdown(!showDropdown);
            }}
          >
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              style={{ color: 'white' }}
            />
          </DotButton>
          {showDropdown && (
            <div className={'dropdown ' + (showDropdown ? 'open' : 'closed')}>
              <button
                className={'item-container'}
                onClick={async (e) => {
                  e.preventDefault();
                  setProfileUser(user);
                  sidebars.open(SIDEBARS.editProfile);
                }}
              >
                <div className={'item-icon'}>
                  <FontAwesomeIcon icon={'user'} />
                </div>
                Profile Details
              </button>
              <button
                className={'item-container'}
                onClick={async (e) => {
                  e.preventDefault();
                  setIWantToLogOut(true);
                }}
              >
                <div className={'item-icon'}>
                  <FontAwesomeIcon icon={'sign-out-alt'} />
                </div>
                Logout
              </button>
            </div>
          )}
        </div>
      </div>

      <Sidebar id={SIDEBARS.editProfile}>
        <CreateEditUser
          profile
          userId={profileUser?.id}
          user={profileUser}
          setUser={setProfileUser}
          closeSidebar={() => {
            sidebars.close(SIDEBARS.editProfile);
          }}
          updateUsers={() => {
            // refresh profile
            verifyUserAsync(setUser).catch(() => {});
          }}
        />
      </Sidebar>
      <ConfirmModal
        isActive={!!iWantToLogOut}
        exit={() => {
          setIWantToLogOut(false);
        }}
        icon="sign-out-alt"
        iconStyle={{
          color: 'var(--colour-interactions-a)',
          background: 'var(--orders-bg)',
          borderRadius: '50%',
          height: '67px',
          width: '67px',
          padding: '15px',
        }}
        modalId="logout-modal"
        title={['Do you want to log out?']}
        buttons={[
          {
            text: 'Yes, Log Out',
            type: 'primary',
            callback: async () => {
              await doLogout();
            },
          },
          {
            text: missionId
              ? 'No, Back to Mission'
              : `No, Back to ${appConfig.name}`,
            type: 'secondary',
            callback: () => setIWantToLogOut(false),
          },
        ]}
      >
        <p>
          If you log out your location will no longer be visible to others on
          the map and you will not receive any notifications on this device.
        </p>
      </ConfirmModal>
    </div>
  );
};

AdminBar.propTypes = {
  title: PropTypes.string.isRequired,
};
