/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * Return new object that only has the fields specified
 *
 * @param {*} obj object
 * @param {*} fields fields we want
 * @return {*} new object
 */
export const only = (obj, fields) => {
  const ret = {};
  for (const f of fields) {
    if (obj[f] === null) {
      ret[f] = null;
    } else if (obj[f] !== undefined) {
      ret[f] = obj[f];
    }
  }
  return ret;
};

/**
 * Return new object that doesn't has the fields specified
 *
 * @param {*} obj object
 * @param {*} fields fields we want
 * @return {*} new object
 */
export const except = (obj, fields) => {
  const ret = { ...obj };
  for (const f of fields) {
    if (obj[f]) {
      delete ret[f];
    }
  }
  return ret;
};

/**
 * prepare combined icon set-icon string for use with Font Awesome
 *
 * @param {*} icon icon set - icon string combo
 * @return {string[]} set and icon as array
 */
export const splitIcon = (icon) => {
  if (!icon) {
    return null;
  }
  const index = icon.indexOf('-');
  return [icon.substr(0, index), icon.substr(index + 1)];
};

/**
 * Call function with debounce
 *
 * @param {*} ref timer ref
 * @param {*} func function to call
 * @param {*} wait timeout (ms)
 * @return {*} executed function
 */
export const debounce = (ref, func, wait) => {
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(ref);
      func(...args);
    };

    clearTimeout(ref);
    ref = setTimeout(later, wait);
  };
};

/**
 * deep compare objects for equality
 *
 * @param {*} a object A
 * @param {*} b object B
 * @return {Boolean} true iff equal
 */
export const isEqual = (a, b) => {
  const jsonA = JSON.stringify(a);
  const jsonB = JSON.stringify(b);
  return jsonA === jsonB;
};
