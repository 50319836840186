/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { endpointConfig } from '../config/endpoint_config';

/**
 * Try to use history to navigate, as a fallback use window.location
 *
 * @param {any} history react router
 * @param {string} pathname route
 * @param {*} state optional state
 */
export const navigate = (history, pathname, state) => {
  if (history) {
    history.push({
      pathname,
      state,
    });
  } else {
    window.location.href = pathname;
  }
};

/**
 * Navigate to the mission map and zoom in on the specified target markers.
 *
 * @param {any} history react router
 * @param {*} missionId mission id
 * @param {*} targets map markers to find
 */
export const navigateToMapTargets = (history, missionId, targets) => {
  navigate(
    history,
    endpointConfig.frontendEndpoints.mission.replace(':missionId', missionId),
    targets
  );
};

/**
 * Navigate to the mission comms and open thread
 *
 * @param {any} history react router
 * @param {*} thread thread
 */
export const navigateToThread = (history, thread) => {
  navigate(
    history,
    endpointConfig.frontendEndpoints.missionComms.replace(
      ':missionId',
      thread.missionId
    ),
    thread.id
  );
};

/**
 * Update the state of the current route
 *
 * @param {any} history react router
 * @param {any} state state
 */
export const updateState = (history, state) => {
  history.replace(history.location.pathname, state);
};
