/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import lockSvg from '../../../assets/svg/lock.svg';
import saveSvg from '../../../assets/svg/save.svg';
import microphoneSvg from '../../../assets/svg/microphone.svg';
import atSvg from '../../../assets/svg/at.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from '../../common/Avatar';
import { getUsersInMission } from '../../../api/missions';
import { cancellablePromise } from '../../../utils/promise';
import { sortBy } from '../../../utils/array';
import { faMultiply } from '@fortawesome/free-solid-svg-icons';

/**
 * Control5 - People picker and 4 action buttons
 *
 */
export const Control5 = ({ control, missionId }) => {
  const [personnelInMission, setPersonnelInMission] = useState([]);
  const [search, setSearch] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState([]);
  const [selectedPersonnelTags, setSelectedPersonnelTags] = useState([]);
  const [locked, setLocked] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const selectedPersonnelRef = useRef(selectedPersonnel);

  const handleLock = () => {
    setLocked(!locked);
  };

  const handleRemovePersonnel = (e, personnel) => {
    const _selectedPersonnel = selectedPersonnelRef.current.filter(
      (p) => p.id !== personnel.id
    );

    const _selectedPersonnelTags = _selectedPersonnel.map(
      (personnel, index) => (
        <div className="personnel-tag">
          <div className="avatar-container">
            <Avatar entity={personnel} size="1.75rem"></Avatar>
          </div>
          <div className="name">
            {personnel.firstname + ' ' + personnel.lastname}
          </div>
          <button
            className="remove-button"
            onClick={(e) => handleRemovePersonnel(e, personnel)}
          >
            <FontAwesomeIcon icon={faMultiply} />
          </button>
        </div>
      )
    );
    setSelectedPersonnel(_selectedPersonnel);
    selectedPersonnelRef.current = _selectedPersonnel;
    setSelectedPersonnelTags(_selectedPersonnelTags);
  };

  const handleSelectPersonnel = (e, personnel) => {
    let _selectedPersonnel = selectedPersonnelRef.current;
    _selectedPersonnel.push(personnel);
    const _selectedPersonnelTags = _selectedPersonnel.map(
      (personnel, index) => (
        <div className="personnel-tag">
          <div className="avatar-container">
            <Avatar entity={personnel} size="1.5rem"></Avatar>
          </div>
          <div className="name">
            {personnel.firstname + ' ' + personnel.lastname}
          </div>
          <button
            className="remove-button"
            onClick={(e) => handleRemovePersonnel(e, personnel)}
          >
            <FontAwesomeIcon icon={faMultiply} />
          </button>
        </div>
      )
    );
    setSelectedPersonnel(_selectedPersonnel);
    selectedPersonnelRef.current = _selectedPersonnel;
    setSelectedPersonnelTags(_selectedPersonnelTags);
  };

  useEffect(() => {
    const stub = async () => {
      const _users = await getUsersInMission(missionId);
      setPersonnelInMission(_users);
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const _filteredPersonnel = sortBy(
      personnelInMission.filter(
        (p) =>
          `${p.firstname} ${p.lastname}`
            .toLowerCase()
            .includes(search.toLowerCase()) &&
          !selectedPersonnelRef.current.includes(p)
      ),
      ['firstName', 'lastName'],
      ['asc', 'asc']
    );
    const _searchResults = _filteredPersonnel.map((personnel, index) => (
      <div onClick={(e) => handleSelectPersonnel(e, personnel)}>
        <div className="avatar-container">
          <Avatar entity={personnel} size="2rem"></Avatar>
        </div>
        <div>{personnel.firstname + ' ' + personnel.lastname}</div>
      </div>
    ));
    setSearchResults(_searchResults);
    setShowDropdown(true);
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="control-5" onClick={() => setShowDropdown(false)}>
      <div className="details">
        <div className="row">
          <div className="left"></div>
          <span className="label">To: </span>
          &nbsp; &nbsp;
          <div style={{ width: '100%' }}>
            <div className="label">
              {control.label}{' '}
              {!!control.required && (
                <div className="required"> * Required</div>
              )}{' '}
            </div>
            <div
              className="people-picker"
              style={{
                background: locked ? '#dee2ed' : '#FFFFFF',
                border: locked ? '1px solid #d6d9e8' : '1px solid #d6d9e8',
              }}
            >
              <input
                placeholder={locked ? '' : 'Search unit or personnel...'}
                disabled={locked}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                style={{
                  background: locked ? '#dee2ed' : '#FFFFFF',
                  cursor: locked ? 'default' : 'pointer',
                }}
              >
                <FontAwesomeIcon icon="users" />
              </button>
            </div>
            {!locked && showDropdown && !!search && (
              <div className="search-results">{searchResults}</div>
            )}
            {<div className="selected-personnel">{selectedPersonnelTags}</div>}
          </div>
          <div className="action-buttons">
            <div className="row">
              <button disabled={true}>
                <img src={microphoneSvg} alt="microphone" />
              </button>
              <button>
                <img src={saveSvg} alt="save" />
              </button>
            </div>
            <div className="row">
              <button disabled={true}>
                <img src={atSvg} alt="@" />
              </button>
              <button onClick={handleLock}>
                <img src={lockSvg} alt="lock" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Control5.propTypes = {
  control: PropTypes.any.isRequired,
  missionId: PropTypes.any.isRequired,
};
