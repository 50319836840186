/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { endpointConfig } from '../../../config/endpoint_config';
import { useSidebar } from '../../../context/SidebarContext';
import { useUser } from '../../../context/UserContext';
import { historyType } from '../../../enums/propTypes';
import { Role } from '../../../enums/role';
import { downloadInvitation } from '../../../utils/files';
import { getClassNames } from '../../../utils/string';
import { DotButton } from '../buttons/DotButton';

/**
 * Mission Menu (left SideNav)
 * @param {any} history react router
 * @param {string} missionId current mission id
 * @param {'map' || 'orders' || 'comms'} active active button
 * @param {bool} published mission published
 */
export const MissionMenu = ({
  history,
  missionId,
  active,
  published,
  archived,
}) => {
  const { closeAll } = useSidebar();
  const { user } = useUser();

  const isAdmin =
    user?.accessRoles?.includes(Role.SysAdmin) ||
    user?.accessRoles?.includes(Role.OrgAdmin);

  return (
    <>
      {published ? (
        <>
          <DotButton
            className={getClassNames({
              active: active === 'map',
            })}
            onClick={() => {
              if (active !== 'map') {
                closeAll();
                history.push(
                  endpointConfig.frontendEndpoints.mission.replace(
                    ':missionId',
                    missionId
                  )
                );
              }
            }}
          >
            <FontAwesomeIcon icon={'map-marked-alt'} />
            <span>Map</span>
          </DotButton>
          <DotButton
            className={getClassNames({
              active: active === 'orders',
            })}
            onClick={() => {
              closeAll();
              history.push(
                endpointConfig.frontendEndpoints.missionOrders.replace(
                  ':missionId',
                  missionId
                )
              );
            }}
          >
            <FontAwesomeIcon icon={'clipboard-list'} />
            <span>Orders</span>
          </DotButton>
          {/* <DotButton
            className={getClassNames({
              active: active === 'forms',
            })}
            onClick={() => {
              closeAll();
              history.push(
                endpointConfig.frontendEndpoints.missionForms.replace(
                  ':missionId',
                  missionId
                )
              );
            }}
          >
            <img
              className={getClassNames({ svg: true })}
              src={formsSvg}
              alt="forms"
            />
            <span>Forms</span>
          </DotButton> */}
          {/* <DotButton
            className={getClassNames({
              active: active === 'comms',
            })}
            onClick={() => {
              closeAll();
              history.push(
                endpointConfig.frontendEndpoints.missionComms.replace(
                  ':missionId',
                  missionId
                )
              );
            }}
            badge={getUnreadMessages(user.id, missionId, comms.threads)}
          >
            <FontAwesomeIcon icon={'comment-alt'} />
            <span>Comms</span>
          </DotButton> */}
        </>
      ) : (
        <></>
      )}
      {isAdmin && (
        <>
          {/* <DotButton
            className={getClassNames({
              active: active === 'edit',
            })}
            onClick={() => {
              closeAll();
              history.push(
                endpointConfig.frontendEndpoints.editMission.replace(
                  ':missionId',
                  missionId
                )
              );
            }}
          >
            <FontAwesomeIcon icon={'pencil-alt'} />
            <span>Edit</span>
          </DotButton>
          <DotButton
            className={getClassNames({
              active: active === 'teams',
            })}
            onClick={() => {
              closeAll();
              history.push(
                endpointConfig.frontendEndpoints.editMissionTeams.replace(
                  ':missionId',
                  missionId
                )
              );
            }}
          >
            <FontAwesomeIcon icon={'project-diagram'} />
            <span>Teams</span>
          </DotButton> */}
          {published && !archived && (
            <DotButton
              onClick={async () => {
                await downloadInvitation(missionId);
              }}
            >
              <FontAwesomeIcon icon={'qrcode'} />
              <span>Download Invitation</span>
            </DotButton>
          )}
        </>
      )}
    </>
  );
};

MissionMenu.propTypes = {
  history: historyType.isRequired,
  missionId: PropTypes.string.isRequired,
  active: PropTypes.oneOf(['map', 'orders', 'comms', 'edit', 'teams'])
    .isRequired,
  published: PropTypes.bool,
  archived: PropTypes.bool,
};
