/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import React, { useEffect, useState } from 'react';

import { getWeather } from '../../../api/mapping';

import Moment from 'react-moment';
import moment from 'moment';
import * as turf from '@turf/turf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClassNames } from '../../../utils/string';

import './Weather.scss';

import { getWeatherIconImage, degreeToCompass, rainColor } from './WeatherIcons'; //prettier-ignore
import { faArrowUp, faTemperatureHalf, faEyeSlash, faDroplet, faWind, faCloud, faTimes} from '@fortawesome/free-solid-svg-icons'; //prettier-ignore
import doubleChevronUp from '../../../assets/svg/double-chevron-up.svg';
import doubleChevronDown from '../../../assets/svg/double-chevron-down.svg';
import { useDarkMode } from '../../../context/DarkModeContext';
import { DotButton } from '../../common/buttons/DotButton';
// import { data } from './data';
import { useBottombar } from '../../../context/BottombarContext';
import badge from '../../../assets/svg/dot-badge.svg';

function LayerButton(props) {
  return (
    <a
      onClick={props.onClick}
      className={`layerButton ${props.active ? 'active' : null}`}
      href="/#"
    >
      <FontAwesomeIcon icon={props.icon} width="20px" height="20px" />
    </a>
  );
}

function ActionButton(props) {
  return (
    <a
      onClick={props.onClick}
      className={`layerButton ${props.active ? 'active' : null}`}
      href="/#"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {props.svg ? (
        <img
          src={props.icon}
          width={props.width ? props.width : '20px'}
          height={props.height ? props.height : '20px'}
          alt="icon"
          style={{ display: 'flex' }}
        />
      ) : (
        <FontAwesomeIcon icon={props.icon} width="20px" height="20px" />
      )}
    </a>
  );
}

export function Weather(props) {
  const [data, setData] = useState(null);
  const [low, setLow] = useState();
  const [high, setHigh] = useState();
  const [distance, setDistance] = useState();
  const bottombars = useBottombar();
  const globalDarkMode = useDarkMode();
  const isDarkMode = globalDarkMode.darkMode;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props?.position) {
      const { lat, lng } =
        !!props.weatherLocation?.lat && !!props.weatherLocation?.lng
          ? props.weatherLocation
          : props.position.getCenter();
      if (!!lat && !!lng) {
        setLoading(true);
        getWeather(lat, lng).then((response) => {
          setLow(response.summaries.daily.list[0].temp.min);
          setHigh(response.summaries.daily.list[0].temp.max);

          //set distance between the weather station and the center of the map
          const latLong1 = turf.point(
            data === null
              ? [lng, lat]
              : [data.city.coord.lon, data.city.coord.lat]
          );
          const latLong2 = turf.point([lng, lat]);
          setDistance(
            turf.distance(latLong1, latLong2, { units: 'kilometers' })
          );

          //set the weather forecast
          setData(response);
          props.setWindDirection(degreeToCompass(response.list[0].wind.deg));
          if (
            bottombars.isOpen('weather-panel') &&
            response &&
            !props.settingWeatherLocation
          ) {
            props.addWeatherMarker(
              { lat: lat, lng: lng },
              new Date().toLocaleTimeString(),
              response.list[0].main.temp.toFixed(0),
              response.summaries.daily.list[0].temp.max.toFixed(0),
              response.summaries.daily.list[0].temp.min.toFixed(0),
              (response.list[0].pop * 100).toFixed(0) ?? 0,
              degreeToCompass(response.list[0].wind.deg),
              (response.list[0].wind.speed * 3.6).toFixed(0)
            );
          }
          setLoading(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [props.position, props.weatherLocation]);

  var heading;

  return (
    <div className="weather-panel shadow">
      {!data || loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <div style={{ animation: 'rotation 2s infinite linear' }}>
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>
            Loading weather forecast for your location
          </div>
        </div>
      ) : (
        <>
          <div className="weatherHeader">
            <div className="weatherHeaderTitle">
              <div className="logo">
                <img
                  src="https://bluedotstatic.blob.core.windows.net/integration/bom.png"
                  alt="logo"
                />
              </div>

              <div>
                <div className="w600">BOM Weather</div>
                <div className="w400 light">
                  As of {new Date().toLocaleTimeString()}
                </div>
              </div>
            </div>
            <div className="weatherHeaderSummary">
              <div className="location">
                <div
                  className={getClassNames({
                    'extra-map-controls': true,
                  })}
                  style={{
                    position: 'unset',
                    marginRight: '10px',
                    maxWidth: '40px',
                    maxHeight: '40px',
                  }}
                >
                  <DotButton
                    tip={
                      props.settingWeatherLocation
                        ? 'Setting location'
                        : 'Set Location'
                    }
                    place="top"
                    className={getClassNames({
                      'map-styles-bar-button': true,
                      active: true,
                    })}
                    style={{ maxWidth: '40px', maxHeight: '40px' }}
                    onClick={() => {
                      if (props.settingWeatherLocation) {
                        props.handleSettingWeatherLocation(false);
                      } else {
                        props.handleSettingWeatherLocation(true);
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        props.settingWeatherLocation ? 'times' : 'location-dot'
                      }
                      size={'2x'}
                    />
                  </DotButton>
                </div>
                {props.settingWeatherLocation ? (
                  <div className="setting-location">Setting Location...</div>
                ) : (
                  <div>
                    <div className="w600">{data.city.name}</div>
                    <div className="light">
                      (Recorded from {distance.toFixed(2)} km away)
                    </div>
                  </div>
                )}
              </div>
              <div className="summary">
                <div>
                  <img
                    src={getWeatherIconImage(data.list[0].weather[0].icon)}
                    className="weatherIconHeading"
                    alt="Current weather"
                  />
                </div>
                <div>
                  <span className="now w400">Now</span>
                  <br />
                  <span className="nowTemp w600">
                    {data.list[0].main.temp.toFixed(0)}°
                  </span>
                </div>
                <div className="w400" style={{ fontSize: '1rem' }}>
                  {/* Temp */}
                  H: {high.toFixed(0)}
                  <br />
                  L: {low.toFixed(0)}
                </div>
                <div className="w400" style={{ fontSize: '1rem' }}>
                  {/* Rain */}
                  <span>
                    <img
                      src={getWeatherIconImage('09d')}
                      className="weatherIconMedium"
                      alt="Chance of rain"
                    />
                    &nbsp;
                    {(data.list[0].pop * 100).toFixed(0) ?? 0}%
                  </span>
                  <br />
                  {/* Wind */}
                  <span>
                    <img
                      src={getWeatherIconImage('50d')}
                      className="weatherIconMedium"
                      alt="Wind"
                    />
                    &nbsp;
                    {degreeToCompass(data.list[0].wind.deg)}
                    &nbsp;
                    {(data.list[0].wind.speed * 3.6).toFixed(0)}km/h
                  </span>
                </div>
              </div>
            </div>
            <div className="weatherHeaderSpacer"></div>
            <div className="weatherHeaderControls">
              {/* prettier-ignore */}
              <LayerButton onClick={async (e) => {
                e.preventDefault();
               props.handleLayerChange();}}
                active={!!!props.weatherLayer ? true : false} icon={faEyeSlash} />
              <LayerButton
                onClick={(e) => {
                  e.preventDefault();
                  if (props.weatherLayer !== 'temp_new') {
                    props.handleLayerChange('temp_new');
                  }
                }}
                active={props.weatherLayer === 'temp_new'}
                icon={faTemperatureHalf}
              />
              <LayerButton
                onClick={(e) => {
                  e.preventDefault();
                  if (props.weatherLayer !== 'precipitation_new') {
                    props.handleLayerChange('precipitation_new');
                  }
                }}
                active={props.weatherLayer === 'precipitation_new'}
                icon={faDroplet}
              />
              <LayerButton
                onClick={(e) => {
                  e.preventDefault();
                  if (props.weatherLayer !== 'wind_new') {
                    props.handleLayerChange(
                      'wind_new',
                      degreeToCompass(data.list[0].wind.deg)
                    );
                  }
                }}
                active={props.weatherLayer === 'wind_new'}
                icon={faWind}
              />
              <LayerButton
                onClick={(e) => {
                  e.preventDefault();
                  if (props.weatherLayer !== 'clouds_new') {
                    props.handleLayerChange('clouds_new');
                  }
                }}
                active={props.weatherLayer === 'clouds_new'}
                icon={faCloud}
              />
            </div>
            <div
              style={{
                display: 'flex',
                borderLeft: 'solid rgba(184, 189, 212, 1) 1px',
                height: '62px',
              }}
            >
              <ActionButton
                onClick={(e) => {
                  e.preventDefault();
                  if (bottombars.isMinimized()) {
                    bottombars.setMinimized(false);
                  } else {
                    bottombars.setMinimized(true);
                  }
                }}
                icon={
                  bottombars.isMinimized() ? doubleChevronUp : doubleChevronDown
                }
                svg={true}
                width="20px"
                height="14px"
              />
              <ActionButton
                onClick={(e) => {
                  e.preventDefault();
                  props.removeWeatherLayer();
                  bottombars.close();
                }}
                icon={faTimes}
              />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <table>
                <tbody>
                  <tr
                    style={{
                      height: '35px',
                    }}
                  >
                    <td className="start">Day</td>
                  </tr>
                  <tr
                    style={{
                      height: '96px',
                    }}
                  >
                    <td className="start">Temperature</td>
                  </tr>
                  <tr
                    style={{
                      height: '70px',
                    }}
                  >
                    <td className="start">
                      Chance of rainfall (%)
                      <br />
                      Rainfall (mm)
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: '90px',
                    }}
                  >
                    <td className="start">
                      Wind Direction
                      <br />
                      Wind Speed (km/h)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="weatherTable">
              <table>
                <tbody
                  style={{
                    borderLeft: 'solid rgba(184, 189, 212, 1) 1px',
                  }}
                >
                  <tr style={{ height: '35px' }}>
                    {data.list.map((forecast, i) => {
                      const day = moment(forecast.dt * 1000).format('dddd');
                      const showHeading = day !== heading;
                      heading = day;
                      if (showHeading) {
                        //calc the number of intervals til the next day
                        var nextDay = 0;
                        for (let d = i; d < data.list.length - 1; d++) {
                          const calcDay = moment(data.list[d].dt * 1000).format("dddd"); //prettier-ignore
                          if (calcDay === day) {
                            nextDay++;
                          } else {
                            break;
                          }
                        }
                        let index = 0;
                        for (
                          let i = 0;
                          i < data.summaries.daily.list.length;
                          i++
                        ) {
                          if (
                            moment(
                              data.summaries.daily.list[i].dt * 1000
                            ).format('dddd') === day
                          ) {
                            index = i;
                          }
                        }

                        return (
                          <React.Fragment key={i}>
                            <td
                              className="day w600"
                              colSpan={2}
                              style={{
                                borderLeft: 'solid rgba(184, 189, 212, 1) 1px',
                              }}
                            >
                              {day}
                            </td>
                            <td
                              className="daySummary"
                              colSpan={
                                nextDay < 8 && i > 1 ? nextDay - 1 : nextDay - 2
                              }
                            >
                              {nextDay - 2 > 1 && (
                                <div>
                                  <img
                                    src={getWeatherIconImage('09d')}
                                    className="weatherIconMedium"
                                    alt="Chance of rain"
                                    style={{
                                      height: '15px',
                                    }}
                                  />
                                  {' ' +
                                    ((
                                      data.summaries.daily.list[index].pop * 100
                                    ).toFixed(0) ?? 0) +
                                    '%'}
                                  &emsp;{'|'}&emsp;
                                  {'H:' +
                                    data.summaries.daily.list[
                                      index
                                    ].temp.max.toFixed(0)}
                                  &emsp;
                                  {'L:' +
                                    data.summaries.daily.list[
                                      index
                                    ].temp.min.toFixed(0)}
                                </div>
                              )}
                            </td>
                          </React.Fragment>
                        );
                      } else {
                        return null;
                        //return <td></td>;
                      }
                    })}

                    {/* {getDateHeader(data.list)} */}
                  </tr>
                  <tr style={{ height: '96px' }}>
                    {data.list.map((forecast, i) => {
                      const forecastDay = moment(forecast.dt * 1000).format(
                        'dddd'
                      );
                      const nextForecastDay = moment(
                        data.list[i + 1]?.dt * 1000
                      ).format('dddd');
                      return (
                        // prettier-ignore
                        <td className="wc" style={{
                          borderRight: nextForecastDay !== forecastDay ? 'solid rgba(184, 189, 212, 1) 1px' : null,
                          backgroundColor: `${forecast.sys.pod === 'n' ? isDarkMode ? "rgba(0, 0, 0, 0.8)" : "rgba(242, 244, 250, 1)" : null}`,
                          transition: 'background 0.4s ease-in-out' }} key={i}>
                      <div className="day w400">
                        <Moment date={forecast.dt * 1000} format="h a" />
                      </div>
                      <div>
                        {/* prettier-ignore */}
                        <img src={getWeatherIconImage(forecast.weather[0].icon)} className="weatherIconGrid" alt="Current weather" />
                      </div>
                      <div className="w600">{forecast.main.temp.toFixed(0)}°</div>
                    </td>
                      );
                    })}
                  </tr>
                  <tr style={{ height: '70px' }}>
                    {data.list.map((forecast, i) => {
                      const forecastDay = moment(forecast.dt * 1000).format(
                        'dddd'
                      );
                      const nextForecastDay = moment(
                        data.list[i + 1]?.dt * 1000
                      ).format('dddd');
                      var volume = forecast.rain ? Object.values(forecast.rain)[0] : 0; //prettier-ignore
                      return (
                        // prettier-ignore
                        <td className="wc" key={i} style={{
                        borderRight: nextForecastDay !== forecastDay ? 'solid rgba(184, 189, 212, 1) 1px' : null,
                        backgroundColor: `${isDarkMode && volume < 10 ? forecast.sys.pod === 'n' ? "rgba(0, 0, 0, 0.8)" : '#191e1f' : rainColor(volume, forecast.sys.pod === 'n')}`,
                        color: isDarkMode && volume < 10 ? '#ffffff' : 'rgba(80,83,102,1)',
                        transition: 'all 0.4s ease-in-out'}}>
                      <div className="w600">
                        {(forecast.pop * 100).toFixed(0)}%
                      </div>
                      <div className="w600">{volume.toFixed(0)} mm</div>
                    </td>
                      );
                    })}
                  </tr>
                  <tr style={{ height: '90px' }}>
                    {data.list.map((forecast, i) => {
                      const forecastDay = moment(forecast.dt * 1000).format(
                        'dddd'
                      );
                      const nextForecastDay = moment(
                        data.list[i + 1]?.dt * 1000
                      ).format('dddd');
                      return (
                        // prettier-ignore
                        <td className="wc" style={{
                          borderRight: nextForecastDay !== forecastDay ? 'solid rgba(184, 189, 212, 1) 1px' : null,
                          backgroundColor: `${forecast.sys.pod === 'n' ? isDarkMode ? "rgba(0, 0, 0, 0.8)":"rgba(242, 244, 250, 1)" : null}`,
                          transition: 'background 0.4s ease-in-out' }} key={i}>
                      <div>
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          width="24px"
                          height="24px"
                          style={{ transform: `rotate(${forecast.wind.deg + 180}deg)` }}
                        />
                      </div>
                      <div className="w600">
                        {degreeToCompass(forecast.wind.deg)}
                      </div>
                      <div className="w400">
                        {(forecast.wind.speed * 3.6).toFixed(0)}km/h
                      </div>
                    </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
