/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { DotButton } from '../common/buttons/DotButton';
import './CreateEditTask.scss';
import { LightScrollbar } from '../common/Scrollbars';
import React, { useState } from 'react';
import { isEqual, only } from '../../utils';
import * as log from 'loglevel';
import { Input } from '../common/Input';
import { RichText } from '../common/RichText';
import { Select } from '../common/Select';
import { TagSelect } from '../common/TagSelect';
import { ConfirmModal } from '../modals/ConfirmModal';
import { PropTypes } from 'prop-types';
import { taggableType, taskType } from '../../enums/propTypes';

const TASK_FIELDS = [
  'assigned',
  'end',
  'description',
  'name',
  'start',
  'timespanType',
  'id',
];

/**
 * @param {any} task
 * @param {Function} setTask
 * @param {string} missionId
 * @param {any[]} taggable
 * @param {Function} closeSidebar callback to close sidebar
 */

export const CreateEditTaskSidebar = ({
  task,
  setTask,
  missionId,
  taggables,
  availableSections,
  sectionId,
  closeSidebar,
}) => {
  const exit = () => {
    closeSidebar();
  };

  const [form, setForm] = useState({
    name: task?.name,
    description: task?.description,
    timespanType: task?.timespanType,
    startDate: task?.start
      ? dayjs(task.start).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
    startTime: task?.start
      ? dayjs(task.start).format('HH:mm')
      : dayjs().format('HH:mm'),
    endDate: task?.end
      ? dayjs(task.end).format('YYYY-MM-DD')
      : dayjs().add(1, 'day').format('YYYY-MM-DD'),
    endTime: task?.end
      ? dayjs(task.end).format('HH:mm')
      : dayjs().add(1, 'day').format('HH:mm'),
  });

  const [unitsOrPersonnel, setUnitsOrPersonnel] = useState(
    task?.assigned || []
  );

  const [formChanged, setFormChanged] = useState(task.isHistory || false);

  const [modalOpen, setModalOpen] = useState(false);

  const [selectedSectionId, setSelectedSectionId] = useState(
    sectionId || availableSections[0].id
  );

  const isValid = () => {
    if (form?.timespanType === 'timeframe') {
      if (
        !(form?.startDate && form?.startTime && form?.endDate && form?.endTime)
      ) {
        return false;
      }

      try {
        const start = dayjs(`${form.startDate} ${form.startTime}`);
        const end = dayjs(`${form.endDate} ${form.endTime}`);

        if (!start.isValid() || !end.isValid()) {
          return false;
        }

        if (start > end) {
          return false;
        }
      } catch {}
    } else if (form?.timespanType === 'deadline') {
      if (!(form?.endDate && form?.endTime)) {
        return false;
      }

      try {
        const end = dayjs(`${form.endDate} ${form.endTime}`);

        if (!end.isValid()) {
          return false;
        }
      } catch {}
    }

    return (
      form?.name &&
      form?.timespanType &&
      (form?.timespanType === 'timeframe' ||
        form?.timespanType === 'deadline' ||
        form?.timespanType === 'none')
    );
  };

  const promptExit = () => {
    if (formChanged) {
      setModalOpen(true);
    } else {
      exit();
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const setFormWCD = (f) => {
    if (!isEqual(form, f)) {
      setFormChanged(true);
    }
    setForm(f);
  };

  const setUnitsOrPersonnelWCD = (f) => {
    if (!isEqual(unitsOrPersonnel, f)) {
      setFormChanged(true);
    }
    setUnitsOrPersonnel(f);
  };

  const saveTask = () => {
    const assigned = unitsOrPersonnel.map((u) => {
      return {
        type: u.type,
        id: u.id,
      };
    });

    const parsedForm = {
      name: form.name,
      description: form.description,
      timespanType: form.timespanType,
      assigned,
    };

    if (form.timespanType === 'timeframe') {
      parsedForm.start = dayjs(`${form.startDate} ${form.startTime}`).toDate();
      parsedForm.end = dayjs(`${form.endDate} ${form.endTime}`).toDate();
    } else if (form.timespanType === 'deadline') {
      parsedForm.end = dayjs(`${form.endDate} ${form.endTime}`).toDate();
    }

    const updatedTask = {
      ...only(task, TASK_FIELDS),
      ...only(parsedForm, TASK_FIELDS),
    };
    // remove user id so the server assigns it
    delete updatedTask.userId;

    log.debug('setTask', updatedTask);

    setTask(updatedTask, selectedSectionId);
  };

  return (
    <div className="task-sidebar">
      <div className="top has-border">
        <div className="heading">
          <div className="text">
            {task?.id ? 'Edit Task' : 'Create New Task'}
          </div>
          <DotButton className="exit" onClick={promptExit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <LightScrollbar className="content">
        <section className="new task sidebarTask">
          <div className="section-body">
            <Select
              label="Orders Section"
              required
              onChange={(e) => setSelectedSectionId(e.currentTarget.value)}
              value={selectedSectionId}
              disabled={!!task?.id}
            >
              {availableSections &&
                availableSections.map((section, index) => (
                  <option key={section.id} value={section.id}>
                    {(parseInt('a', 36) + index).toString(36).toUpperCase()}
                    {' - '}
                    {section.name}
                  </option>
                ))}
            </Select>
            <Input
              label="Task Name"
              value={form.name}
              placeholder="Enter a name for this task"
              required
              onChange={(e) => setFormWCD({ ...form, name: e.target.value })}
            ></Input>

            <RichText
              label="Task Description"
              placeholder="Enter a description for this task"
              required
              value={form.description}
              onChange={(newValue) =>
                setFormWCD({ ...form, description: newValue })
              }
              taggables={taggables}
            ></RichText>

            <TagSelect
              missionId={missionId}
              label="Assign task to..."
              placeholder="Search for teams or users..."
              value={unitsOrPersonnel}
              setValue={setUnitsOrPersonnel}
              selectUnitOrPersonnel={(unit) => {
                const _units = [...unitsOrPersonnel];
                if (!_units.find((u) => u.id === unit.id)) {
                  _units.push(unit);
                  setUnitsOrPersonnelWCD(_units);
                }
              }}
              removeUnitOrPersonnel={(unit) => {
                const _units = unitsOrPersonnel.filter((u) => u.id !== unit.id);
                setUnitsOrPersonnelWCD(_units);
              }}
            ></TagSelect>

            <div className="radio">
              <label>
                <input
                  name="timespanType"
                  type="radio"
                  value="timeframe"
                  checked={form.timespanType === 'timeframe'}
                  onChange={(e) =>
                    setFormWCD({ ...form, timespanType: e.target.value })
                  }
                />
                Timeframe
                <span className="checkmark"></span>
              </label>
              <label>
                <input
                  name="timespanType"
                  type="radio"
                  value="deadline"
                  checked={form.timespanType === 'deadline'}
                  onChange={(e) =>
                    setFormWCD({ ...form, timespanType: e.target.value })
                  }
                />
                Deadline
                <span className="checkmark"></span>
              </label>
              <label>
                <input
                  name="timespanType"
                  type="radio"
                  value="none"
                  checked={form.timespanType === 'none'}
                  onChange={(e) =>
                    setFormWCD({ ...form, timespanType: e.target.value })
                  }
                />
                No Date
                <span className="checkmark"></span>
              </label>
            </div>

            {form.timespanType === 'timeframe' && (
              <>
                <div className="row">
                  <Input
                    label="Start Date"
                    type="date"
                    value={form.startDate}
                    onChange={(e) =>
                      setFormWCD({ ...form, startDate: e.target.value })
                    }
                    className="date"
                  ></Input>
                  <FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon>
                  <Input
                    label="End Date"
                    type="date"
                    value={form.endDate}
                    onChange={(e) =>
                      setFormWCD({ ...form, endDate: e.target.value })
                    }
                    className="date"
                  ></Input>
                </div>

                <div className="row">
                  <Input
                    label="Start Time"
                    type="time"
                    value={form.startTime}
                    onChange={(e) =>
                      setFormWCD({ ...form, startTime: e.target.value })
                    }
                    className="date"
                  ></Input>
                  <FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon>
                  <Input
                    label="End Time"
                    type="time"
                    value={form.endTime}
                    onChange={(e) =>
                      setFormWCD({ ...form, endTime: e.target.value })
                    }
                    className="date"
                  ></Input>
                </div>
              </>
            )}
            {form.timespanType === 'deadline' && (
              <div className="row">
                <Input
                  label="Date"
                  type="date"
                  value={form.endDate}
                  onChange={(e) =>
                    setFormWCD({ ...form, endDate: e.target.value })
                  }
                  className="date"
                ></Input>
                <Input
                  label="Time"
                  type="time"
                  value={form.endTime}
                  onChange={(e) =>
                    setFormWCD({ ...form, endTime: e.target.value })
                  }
                  className="time"
                ></Input>
              </div>
            )}
          </div>
          <ConfirmModal
            isActive={modalOpen}
            exit={() => {
              setModalOpen(null);
            }}
            icon="exclamation-circle"
            modalId="task-changes"
            title={'Cancel changes to this task?'}
            body="You will lose any changes you have made to this section."
            buttons={[
              {
                text: 'Yes, Cancel Changes',
                type: 'primary',
                callback: exit,
              },
              {
                text: 'No, Go Back',
                type: 'secondary',
                callback: closeModal,
              },
            ]}
          >
            You will lose any changes you have made to this section including
            tasks that have been created within this section.
          </ConfirmModal>
        </section>
      </LightScrollbar>
      <div className="section-footer">
        <DotButton className="secondary" onClick={promptExit}>
          Cancel
        </DotButton>
        <DotButton
          className="primary"
          disabled={!isValid() || !formChanged}
          onClick={saveTask}
        >
          {task?.id ? 'Save Changes' : 'Create Task'}
        </DotButton>
      </div>
    </div>
  );
};

CreateEditTaskSidebar.propTypes = {
  task: taskType,
  setTask: PropTypes.func,
  missionId: PropTypes.string,
  taggables: PropTypes.arrayOf(taggableType),
  availableSections: PropTypes.arrayOf(PropTypes.any),
  sectionId: PropTypes.string,
  closeSidebar: PropTypes.func.isRequired,
};
