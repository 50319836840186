/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { getThread } from '../../api/comms';
import { historyType } from '../../enums/propTypes';
import { useLocalStorage } from '../../utils/localStorage';
import { navigateToThread } from '../../utils/nav';
import { cancellablePromise } from '../../utils/promise';

/**
 * Component that redirects to comms and opens the specified thread.
 */
export const ThreadRedirect = withRouter(({ history }) => {
  const { missionId, threadId } = useParams();
  // eslint-disable-next-line
  const [_focusedThreads, setFocusedThreads] = useLocalStorage(
    'focused-threads',
    []
  );

  useEffect(() => {
    const stub = async () => {
      const thread = await getThread(missionId, threadId);

      setFocusedThreads([{ id: threadId, _ts: new Date().getTime() }]);

      navigateToThread(history, thread);
    };

    const { promise, cancel } = cancellablePromise(stub());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  return <></>;
});

ThreadRedirect.propTypes = {
  history: historyType,
};
