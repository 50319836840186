/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { ImageType } from '../enums/image';
import { POI } from '../enums/poi';
import { only } from '../utils';
import { toLongitudeLatitude } from '../utils/geo';
import { getThumbUrl } from '../utils/string';
import { del, delWithBody, get, getBlob, post, patch } from './global';
import { DEFAULT_CRUMB } from './orgs_users';
import { postImage } from './other';

/**
 * Get missions the current user is currently in
 * @return {any} API response
 */
export const getYourMissions = async () => {
  return await get(`/missions`);
};

/**
 * Get all missions you have access to
 *
 * @param {boolean} archived return archived missions?
 * @return {any} API response
 */
export const getAllMissions = async (archived) => {
  const qs = [];
  let delim = '';
  if (archived) {
    qs.push(`archived=${archived}`);
  }
  if (qs.length) {
    delim = '?';
  }

  const allMissions = await get(`/missions/all${delim}${qs.join('&')}`);
  if (allMissions?.length) {
    return allMissions.map((m) => {
      m.archived = archived;
      return m;
    });
  }

  return [];
};

/**
 * Get missions you have been invited to
 *
 * @return {any} API response
 */
export const getInvitedMissions = async () => {
  return await get(`/missions/invitations`);
};

/**
 * Get mission
 *
 * @param {string} id mission id
 * @return {any} API response
 */
export const getMission = async (id) => {
  return await get(`/missions/${id}`);
};

/**
 * Post mission
 *
 * @param {string} missionId mission id
 * @param {string} mission mission
 * @return {any} API response
 */
export const postMission = async (missionId, mission) => {
  if (missionId) {
    return await post(`/missions/${missionId}`, mission);
  } else {
    return await post(`/missions`, mission);
  }
};

/**
 * Update mission status
 *
 * @param {string} missionId mission id
 * @param {boolean} published is mission published?
 * @return {any} API response
 */
export const updateMissionStatus = async (missionId, published) => {
  return await post(`/missions/${missionId}/setStatus`, {
    published,
  });
};

/**
 * Delete mission
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const deleteMission = async (missionId) => {
  return await del(`/missions/${missionId}`);
};

/**
 * Get mission geofences
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const getGeofences = async (missionId) => {
  return await get(`/missions/${missionId}/geofences`);
};

/**
 * Update mission geofences
 *
 * @param {string} missionId mission id
 * @param {string} geofenceId geofence id
 * @return {any} API response
 */
export const postGeofence = async (missionId, geofenceId, geofence) => {
  if (geofenceId) {
    return await post(
      `/missions/${missionId}/geofences/${geofenceId}`,
      geofence
    );
  } else {
    return await post(`/missions/${missionId}/geofences`, geofence);
  }
};

/**
 * Delete mission geofences
 *
 * @param {string} missionId mission id
 * @param {string} geofenceId geofence id
 * @return {any} API response
 */
export const deleteAnnotation = async (missionId, annotationId) => {
  return await del(`/missions/${missionId}/annotations/${annotationId}`);
};

/**
 * Get mission annotations
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const getAnnotations = async (missionId) => {
  return await get(`/missions/${missionId}/annotations`);
};

/**
 * Update mission annotations
 *
 * @param {string} missionId mission id
 * @param {string} annotationId annotation id
 * @return {any} API response
 */
export const postAnnotation = async (missionId, annotationId, annotation) => {
  // fix for validation
  annotation.color = annotation.color.toLowerCase();

  if (annotationId) {
    return await post(
      `/missions/${missionId}/annotations/${annotationId}`,
      annotation
    );
  } else {
    return await post(`/missions/${missionId}/annotations`, annotation);
  }
};

/**
 * Delete mission geofences
 *
 * @param {string} missionId mission id
 * @param {string} geofenceId geofence id
 * @return {any} API response
 */
export const deleteGeofence = async (missionId, geofenceId) => {
  return await del(`/missions/${missionId}/geofences/${geofenceId}`);
};

/**
 * Invite organisation to mission
 *
 * @param {string} missionId mission id
 * @param {string} organisationId organisation id
 * @return {any} API response
 * @deprecated
 */
export const inviteToMission = async (missionId, organisationId) => {
  return await post(`/missions/${missionId}/organisations/invite`, {
    organisationId,
  });
};

/**
 * Get mission organisations
 *
 * @param {string} missionId mission id
 * @return {any} API response
 * @deprecated
 */
export const getMissionOrganisations = async (missionId) => {
  return await get(`/missions/${missionId}/organisations`);
};

/**
 * Validate mission invitation
 *
 * @param {string} id invite id
 * @return {any} API response
 * @deprecated
 */
export const validateMissionInvitation = async (id) => {
  return await get(`/missions/validateinvitation/${id}`);
};

/**
 * Delete mission invitation
 *
 * @param {string} missionId mission id
 * @param {string} id invite id
 * @return {any} API response
 * @deprecated
 */
export const deleteMissionInvitation = async (missionId, inviteId) => {
  return await del(`/missions/${missionId}/organisations/invite/${inviteId}`);
};

/**
 * Validate mission invitation
 *
 * @param {string} missionId mission id
 * @param {string} inviteId invite id
 * @param {boolean} accept accept invite?
 * @param {string} reason reason for declining, if applicable
 * @return {any} API response
 * @deprecated
 */
export async function respondToMissionInvite(
  missionId,
  inviteId,
  accept,
  reason = ''
) {
  return await post(`/missions/${missionId}/organisations/invite/${inviteId}`, {
    accept,
    reason,
  });
}

/**
 * Get mission groups/units
 *
 * @param {string} missionId mission id
 * @return {any} API response
 * @deprecated
 */
export const getMissionUnits = async (missionId) => {
  const units = await get(`/missions/${missionId}/units`).catch((err) => {
    log.error(err);
    return [];
  });
  if (units) {
    units.forEach((u) => (u.breadcrumb = DEFAULT_CRUMB));
  }
  return units;
};

/**
 * Get mission group/unit
 *
 * @param {string} missionId mission id
 * @param {string} groupId group id
 * @return {any} API response
 * @deprecated
 */
export const getMissionUnitSingle = async (missionId, groupId) => {
  const group = (
    await get(`/missions/${missionId}/units`).catch((err) => {
      log.error(err);
      return [];
    })
  ).find((g) => g.groupId === groupId);
  if (group) {
    group.breadcrumb = DEFAULT_CRUMB;
  }
  return group;
};

/**
 * Add unit/group to mission
 *
 * @param {string} missionId mission id
 * @param {string} organisationId organisation id
 * @param {string} groupId group id
 * @return {any} API response
 * @deprecated
 */
export const addMissionUnit = async (missionId, organisationId, groupId) => {
  return await post(`/missions/${missionId}/units`, {
    organisationId,
    groupId,
  });
};

/**
 * Remove unit/group from mission
 *
 * @param {string} missionId mission id
 * @param {string} groupId group id
 * @return {any} API response
 * @deprecated
 */
export const deleteMissionUnits = async (missionId, groupId) => {
  return await del(`/missions/${missionId}/units/${groupId}`);
};

/**
 * Get lng/lat positions for all personnel in a mission
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const getUserLocations = async (missionId) => {
  return await get(
    `/getMissionLocations?missionId=${missionId}`,
    null,
    'realtime'
  );
};

/**

* Get user details in mission context
* @param {string} missionId mission id
* @param {string} userId user id
* @returns {*} api response
*/
export const getMissionUser = async (missionId, userId) => {
  return await get(`/missions/${missionId}/users/${userId}`);
};

/**
 * Update lng/lat positions for a personnel in a mission
 *
 * @param {string} missionId mission id
 * @param {string} userId mission id
 * @param {LongitudeLatitude} coords location LongitudeLatitude
 * @param {any} battery mission id
 * @return {any} API response
 */
export const updateLocation = async (missionId, userId, coords, battery) => {
  log.debug('updateLocation', userId, coords, battery);

  return await post(
    `/setlocation`,
    {
      userId,
      missionId,
      location: {
        coords: toLongitudeLatitude(coords),
        battery,
      },
    },
    null,
    'realtime'
  ).catch((e) => {
    // if (e.message && e.message.includes('503')) {
    //   // eslint-disable-next-line
    //   throw 'Realtime service unavailable';
    // } else {
    //   throw e;
    // }
  });
};

/**
 * Update lng/lat positions for a personnel in a mission
 *
 * @param {string} missionId mission id
 * @param {string} userId mission id
 * @return {any} API response
 */
export const removeLocation = async (missionId, userId) => {
  log.debug('removeLocation', userId);

  return await post(
    `/setlocation`,
    {
      userId,
      missionId,
      offline: true,
    },
    null,
    'realtime'
  ).catch((e) => {
    // if (e.message && e.message.includes('503')) {
    //   // eslint-disable-next-line
    //   throw 'Realtime service unavailable';
    // } else {
    //   throw e;
    // }
  });
};

/**
 * Get all POI's for a mission
 *
 * @param {string} missionId mission id
 * @return {any} API response
 */
export const getPointsOfInterest = async (missionId) => {
  const pois = await get(`/missions/${missionId}/poi`);
  pois.forEach((p) => {
    p.missionId = missionId;
    p.coords = p.location;

    if (p.type === POI.Photo) {
      p.thumb = getThumbUrl(p.reference);
    }
  });
  return pois;
};

/**
 * Update POI
 *
 * @param {any} poi point of interest
 * @return {any} API response
 */
export const updatePointOfInterest = async (poi) => {
  const poiToSave = {
    name: poi.name,
    description: poi.description,
    type: poi.type,
    reference: poi.reference,
    milspec: poi.milspec,
  };
  if (poi.id) {
    poiToSave.id = poi.id;
  }
  if (poi.coords) {
    if (!!poi.coords.lat && poi.coords.lng) {
      poiToSave.location = {
        longitude: poi.coords.lng,
        latitude: poi.coords.lat,
      };
    } else {
      poiToSave.location = poi.coords;
    }
  }

  // new photo was uploaded
  if (poi.blob) {
    const rs = await postImage(ImageType.Photo, poi.blob);
    if (rs.success) {
      poiToSave.reference = rs.url;
      poiToSave.meta = rs.meta;
    } else {
      // eslint-disable-next-line
      throw `Photo failed to upload`;
    }
  }

  if (poi.id) {
    await post(`/missions/${poi.missionId}/poi/${poi.id}`, poiToSave);
  } else {
    await post(`/missions/${poi.missionId}/poi`, poiToSave);
  }
};

/**
 * Delete POI
 *
 * @param {any} poi point of interest
 * @return {any} API response
 */
export const deletePointOfInterest = async (poi) => {
  await del(`/missions/${poi.missionId}/poi/${poi.id}`);
};

/**
 * Get users for a mission
 * @param {string} missionId mission id
 * @returns {any[]} array of users
 */
export const getMissionUsers = async (missionId) => {
  return await get(`/missions/${missionId}/users`);
};

/**
 * Get teams for a mission
 * @param {string} missionId mission id
 * @returns {mission[]} array of missions
 */
export const getTeams = async (missionId) => {
  return await get(`/missions/${missionId}/teams`);
};

/**
 * Update a team
 * @param {string} missionId mission id
 * @param {*} team
 * @returns
 */
export const postTeam = async (missionId, team) => {
  if (team.id) {
    return await post(
      `/missions/${missionId}/teams/${team.id}`,
      only(team, ['team', 'color'])
    );
  } else {
    return await post(
      `/missions/${missionId}/teams`,
      only(team, ['team', 'color'])
    );
  }
};

/**
 * Delete a team
 * @param {string} missionId mission id
 * @param {string} teamId team id
 * @returns {*} api response
 */
export const deleteTeam = async (missionId, teamId) => {
  return await del(`/missions/${missionId}/teams/${teamId}`);
};

/**
 * Get users in a team
 * @param {string} missionId  mission id
 * @param {string} teamId  team id
 * @returns {user[]} array of users
 */
export const getTeamUsers = async (missionId, teamId) => {
  return await get(`/missions/${missionId}/teams/${teamId}/users`);
};

/**
 * Add users or roles to team
 * @param {string} missionId mission id
 * @param {string} teamId  team id
 * @param {string[]} users csv user ids
 * @param {string[]} roles csv role ids
 * @returns {*} api response
 */
export const addUsersToTeam = async (missionId, teamId, users, roles) => {
  return await post(`/missions/${missionId}/teams/${teamId}/users`, {
    users,
    roles,
  });
};

/**
 * Remove users or roles from team
 * @param {string} missionId mission id
 * @param {string} teamId  team id
 * @param {string[]} users csv user ids
 * @param {string[]} roles csv role ids
 * @returns {*} api response
 */
export const deleteUsersFromTeam = async (missionId, teamId, users) => {
  return await delWithBody(`/missions/${missionId}/teams/${teamId}/users`, {
    users,
  });
};

/**
 * Add users or roles to mission
 * @param {string} missionId mission id
 * @param {string[]} users csv user ids
 * @param {string[]} roles csv role ids
 * @returns {*} api response
 */
export const addUsersToMission = async (missionId, users, roles) => {
  return await post(`/missions/${missionId}/users`, {
    users,
    roles,
  });
};

/**
 * Remove users or roles from mission
 * @param {string} missionId mission id
 * @param {string[]} users csv user ids
 * @param {string[]} roles csv role ids
 * @returns {*} api response
 */
export const deleteUsersFromMission = async (missionId, users, roles) => {
  return await delWithBody(`/missions/${missionId}/users`, {
    users,
    roles,
  });
};

/**
 * Get users in mission
 * @param {string} missionId mission id
 * @returns {*} api response
 */
export const getUsersInMission = async (missionId) => {
  return await get(`/missions/${missionId}/users`);
};

export const getMissionInvitation = async (missionId) => {
  return await getBlob(`/missions/${missionId}/invitationpdf`);
};

export const getMissionIntegrations = async (missionId) => {
  return await get(`/missions/${missionId}/integrations`);
};

/**
 * Add an integration to a mission
 *
 * @param {string} missionId mission id
 * @param {string} organisationIntegrationId integration id
 * @param {boolean} allOptions whether all options are selected
 * @param {string[]} options options selected for the integration
 * @return {any} API response
 */
export const postMissionIntegration = async (
  missionId,
  organisationIntegrationId,
  allOptions,
  options
) => {
  return await post(`/missions/${missionId}/integrations`, {
    organisationIntegrationId,
    allOptions,
    options,
  });
};

/**
 * Delete an integration from mission by integration id
 *
 * @param {string} missionId mission id
 * @param {string} integrationId integration id
 * @return {any} API response
 */
export const deleteMissionIntegration = async (missionId, integrationId) => {
  return await del(`/missions/${missionId}/integrations/${integrationId}`);
};

/**
 * Update options for an integration
 *
 * @param {string} missionId mission id
 * @param {string} integrationId integration id
 * @param {boolean} allOptions whether all options are selected
 * @param {string[]} options options selected for the integration
 * @return {any} API response
 */
export const updateIntegrationOptions = async (
  missionId,
  integrationId,
  organisationIntegrationId,
  allOptions,
  options
) => {
  return await patch(`/missions/${missionId}/integrations/${integrationId}`, {
    organisationIntegrationId,
    allOptions,
    options,
  });
};

/**
 * Update options for a symbol
 *
 * @param {any} data mission id
 * @return {any} API response
 */
export const updateMilspecSymbol = async (data) => {
  return await patch(`/milspec/identity/`, data);
};

/**
 * Reset options for a symbol
 *
 * @param {any} id symbol id
 * @return {any} API response
 */
export const resetMilspecSymbol = async (id) => {
  return await del(`/milspec/identity/${id}`);
};

/**
 * Get all symbols
 *
 * @return {any} API response
 */
export const getAllSymbols = async () => {
  return await get(`/symbol/`);
};

/**
 * Add a symbol
 *
 * @return {any} API response
 */
export const addSymbol = async (data) => {
  return await post(`/symbol/`, data);
};

/**
 * Get symbol by ID
 *
 * @return {any} API response
 */
export const getSymbolById = async (symbolId) => {
  return await get(`/symbol/${symbolId}`);
};

/**
 * Update a symbol
 *
 * @return {any} API response
 */
export const updateSymbol = async (symbolId, data) => {
  return await post(`/symbol/${symbolId}`, data);
};

/**
 * Delete a symbol
 *
 * @return {any} API response
 */
export const deleteSymbol = async (symbolId) => {
  return await del(`/symbol/${symbolId}`);
};

/**
 * Gets user tracks
 *
 * @param {string} missionId mission id
 * @param {boolean} returnCheckins whether all options are selected
 * @return {any} API response
 */
export const getUserTracks = async (missionId, returnCheckins) => {
  return await get(
    `/missions/${missionId}/history?returncheckins=${returnCheckins}`
  );
};
