/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import {
  addMapStyle,
  deleteMapStyle,
  getMapStyles,
} from '../../api/integrations';
import { SIDEBARS } from '../../context/SidebarContext';
import { useSidebar } from '../../context/SidebarContext';
import { useUser } from '../../context/UserContext';
import { Role } from '../../enums/role';
import { only, splitIcon } from '../../utils';
import { sortBy } from '../../utils/array';
import { handleError } from '../../utils/error';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { AdminMenu } from '../common/menus/AdminMenu';
import { NavBar } from '../common/Navbar';
import { LightScrollbar } from '../common/Scrollbars';
import { Select } from '../common/Select';
import { Sidebar } from '../common/Sidebar';
import { ConfirmModal } from '../modals/ConfirmModal';
import './EditMapStyles.scss';
import { PropTypes } from 'prop-types';
import { historyType } from '../../enums/propTypes';
import { cancellablePromise } from '../../utils/promise';
import { AdminBar } from '../common/AdminBar';

export const EditMapStyles = ({ history }) => {
  const [edit, setEdit] = useState(false);

  // eslint-disable-next-line
  const [selectedStyle, setSelectedStyle] = useState('');
  const [styleToDelete, setStyleToDelete] = useState();

  const [styles, setStyles] = useState();

  const userValue = useUser();
  const sidebars = useSidebar();

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(handleUpdate());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  const handleDelete = async () => {
    if (styleToDelete) {
      try {
        await deleteMapStyle(styleToDelete.id);
        setStyleToDelete(null);
      } catch (err) {
        handleError(err);
      }
    }

    // Given only sys admin has access to this
    // "get all orgs" api is used.
    await handleUpdate();
  };

  const handleUpdate = async () => {
    // Given only sys admin has access to this
    // "get all orgs" api is used.
    try {
      setStyles(await getMapStyles(userValue.user?.organisationId));
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className="edit-styles-container">
      <NavBar activeRoute="map-styles" history={history}>
        <AdminMenu history={history} activeRoute="map-styles"></AdminMenu>
      </NavBar>
      <div className="edit-styles-middle">
        <AdminBar title="Map Styles" />
        <div className="edit-styles-content">
          <div className="edit-styles-content-row1">
            <div className="level">
              <div className="level-left">
                <div className="level-item edit-styles-content-count-title">
                  Map Styles
                </div>
              </div>
              {(userValue.user.accessRoles.includes(Role.SysAdmin) ||
                userValue.user.accessRoles.includes(Role.OrgAdmin)) && (
                <div className="level-right">
                  <DotButton
                    className="primary"
                    onClick={() => sidebars.open(SIDEBARS.editStyles)}
                  >
                    <span>New Style</span>
                    <FontAwesomeIcon icon="plus" />
                  </DotButton>
                </div>
              )}
            </div>
          </div>
          <div className="list">
            {styles?.length &&
              sortBy(styles, ['default'], ['desc']).map((style) => {
                return (
                  <MapStylePanel
                    key={style.id}
                    style={style}
                    setEdit={setEdit}
                    setSelected={setSelectedStyle}
                    access={
                      userValue.user.accessRoles.includes(Role.SysAdmin) ||
                      userValue.user.accessRoles.includes(Role.OrgAdmin)
                    }
                    setStyleToDelete={setStyleToDelete}
                  />
                );
              })}
          </div>
        </div>
      </div>

      <Sidebar id={SIDEBARS.editStyles}>
        <EditPanel
          edit={edit}
          setEdit={setEdit}
          closeSidebar={() => {
            sidebars.close(SIDEBARS.editStyles);
            handleUpdate();
          }}
        ></EditPanel>
      </Sidebar>

      <ConfirmModal
        isActive={!!styleToDelete}
        exit={() => {
          setStyleToDelete(null);
        }}
        icon="exclamation-circle"
        modalId="delete-style"
        title={[
          'Delete the ',
          <strong>{styleToDelete?.title}</strong>,
          ' style?',
        ]}
        buttons={[
          {
            text: 'Yes, Delete the Style',
            type: 'primary',
            callback: handleDelete,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setStyleToDelete(null),
          },
        ]}
      >
        <p>All missions will no longer be able to use this map style.</p>
      </ConfirmModal>
    </div>
  );
};

EditMapStyles.propTypes = {
  history: historyType,
};

const MapStylePanel = ({
  style,
  access,
  setStyleToDelete,
  setEdit,
  setSelected,
}) => {
  return (
    <div className="block list-item" style={{ marginBottom: '1rem' }}>
      <div style={{ flexBasis: '10%' }}>
        <FontAwesomeIcon
          icon={splitIcon(style.icon)}
          style={{
            height: '15px',
            width: '15px',
            color: 'rgb(0, 181, 197)',
          }}
        />
      </div>
      <div style={{ flexBasis: '10%' }}>{style.title}</div>
      <div style={{}}>{style.url}</div>
      <div className="buttons">
        {access &&
          (!style.default ? (
            <DotButton
              className="danger"
              onClick={() => {
                setStyleToDelete(style);
              }}
            >
              Delete Style
              <FontAwesomeIcon icon="trash" style={{ height: '11px' }} />
            </DotButton>
          ) : (
            <span style={{ width: '100px', textAlign: 'center' }}>default</span>
          ))}
      </div>
    </div>
  );
};

MapStylePanel.propTypes = {
  style: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    default: PropTypes.bool,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  access: PropTypes.bool,
  setStyleToDelete: PropTypes.func.isRequired,
  setEdit: PropTypes.func,
  setSelected: PropTypes.func,
};

const EditPanel = ({ style, edit, setEdit, closeSidebar }) => {
  // Edit states
  const [inputValues, setInputValues] = useState(style);
  const [touched, setTouched] = useState(false);

  const submitEdit = async (e) => {
    e.preventDefault();

    const newStyle = only(inputValues, ['icon', 'title', 'url']);
    newStyle.organisationId = null;
    try {
      await addMapStyle(newStyle);
      log.debug('great success');

      setInputValues();
      closeSidebar();
      setEdit(false);
    } catch (err) {
      handleError(err);
    }
  };

  const _setInputValues = (value) => {
    setInputValues(value);
    setTouched(true);
  };

  const isValid = () => {
    return (
      inputValues &&
      !!inputValues.title &&
      !!inputValues.icon &&
      !!inputValues.url
    );
  };

  return (
    <div id="edit-styles-edit-panel">
      <div className="top">
        <div className="heading">
          <div className="text">{style ? 'Edit Style' : 'New Style'}</div>
          <DotButton
            className="exit"
            onClick={() => {
              closeSidebar();
              setEdit(false);
            }}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      <LightScrollbar className="content">
        <form>
          <div className="controls">
            <Input
              label="Title"
              placeholder="Enter the title for this map style"
              value={inputValues?.title}
              onChange={(e) =>
                _setInputValues({
                  ...inputValues,
                  title: e.currentTarget.value,
                })
              }
              required={true}
            />
            <div className="frsb">
              <Select
                label="Icon"
                placeholder="Select an icon for this map style"
                value={inputValues?.icon}
                onChange={(e) =>
                  _setInputValues({
                    ...inputValues,
                    icon: e.currentTarget.value,
                  })
                }
                required={true}
              >
                <option value="">Select an icon...</option>
                {Object.keys(library.definitions.fas).map((fas, idx) => (
                  <option key={idx} value={'fas-' + fas}>
                    fas-{fas}
                  </option>
                ))}
              </Select>
              <div>
                <FontAwesomeIcon
                  icon={splitIcon(inputValues?.icon)}
                  style={{ height: '16px', width: '16px' }}
                />
              </div>
            </div>
            <Input
              label="Map Box URL"
              placeholder="Enter the MapBox URL for this map style"
              value={inputValues?.url}
              onChange={(e) =>
                _setInputValues({
                  ...inputValues,
                  url: e.currentTarget.value,
                })
              }
              required={true}
            />
          </div>
        </form>
      </LightScrollbar>
      <div className="foot">
        <DotButton
          disabled={!touched || !isValid()}
          className="primary block"
          type="submit"
          onClick={submitEdit}
        >
          {style ? 'Save Changes' : 'Create Map Style'}
        </DotButton>
      </div>
    </div>
  );
};

EditPanel.propTypes = {
  style: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    default: PropTypes.bool,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  closeSidebar: PropTypes.func,
};
