/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { createContext, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useLocalStorage } from '../utils/localStorage';

/**
 * DarkMode state.
 *
 * @param {Boolean} darkMode dark mode enabled
 * @param {Function} setDarkMode set dark mode
 */
export const DarkModeContext = createContext({
  darkMode: false,
  setDarkMode: (darkMode) => {},
});

/**
 * DarkMode provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useLocalStorage('globalDarkMode', false);

  const contextValue = {
    darkMode,
    setDarkMode,
  };

  return (
    <DarkModeContext.Provider value={contextValue}>
      {children}
    </DarkModeContext.Provider>
  );
};

DarkModeProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming DarkMode context.
 *
 * @returns DarkModeContext
 */
export const useDarkMode = () => {
  return useContext(DarkModeContext);
};
