/**
 * Add a cancel funtion to a Promise such that if cancel is called the promise
 * is cancelled and the result is discarded. Effectively acts as a cleanup so we
 * don't end up with unhandled promises floating around in the wild which can lead
 * to potential memory leaks.
 *
 * Example usage:
 * useEffect(() => {
 *   const asyncFunc = async () => {
 *     await someAsyncTask();
 *   }
 *
 *   const { promise, cancel } = cancellablePromise(asyncFunc());
 *   promise.then((rs) => {
 *       // handle promise rs
 *     }).catch((e) => {
 *     if (!e.value) {
 *       // this will be a standard exception if it doesn't have value
 *       handleErr(e);
 *     }
 *   }).finally(() => {
 *     // do things after promise
 *   });
 *   return cancel;
 * }, []);
 *
 * @param {Promise<any>} promise promise
 * @returns {{promise: Promise<any>, cancel: Function}} cancel function
 */
export const cancellablePromise = (promise) => {
  const isCancelled = { value: false };
  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((d) => {
        return isCancelled.value ? reject(isCancelled) : resolve(d);
      })
      .catch((e) => {
        reject(isCancelled.value ? isCancelled : e);
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true;
    },
  };
};

/**
 * Wait for n ms
 *
 * @param {number} timeout timeout ms
 */
export const wait = async (timeout) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, timeout);
  });
};
