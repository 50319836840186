/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getClassNames } from '../../utils/string';
import { DotButton } from '../common/buttons/DotButton';
import { Modal } from './Modal';
import './Modals.scss';
import { PropTypes } from 'prop-types';

/**
 * Display a message as a modal with buttons that perform some action.
 *
 * @param {Boolean} isActive is modal active
 * @param {Jsx} children modal body
 * @param {string} modalId modal id
 * @param {Function} exit close modal
 * @param {string} icon icon
 * @param {any} iconStyle additional icon style
 * @param {string} title title
 * @param {any[]} buttons action buttons
 */
export const ConfirmModal = ({
  isActive,
  children,
  modalId,
  exit,
  icon,
  iconStyle,
  title,
  buttons,
}) => {
  return (
    <Modal {...{ isActive, modalId, exit }}>
      <div className="confirm-modal">
        <FontAwesomeIcon
          icon={icon}
          style={{
            height: '37px',
            width: '37px',
            color: 'rgb(165, 29, 29)',
            ...iconStyle,
          }}
        />
        <h2>
          {typeof title !== 'string'
            ? title.map((t, index) => (
                <span key={modalId + '-title-' + index}>{t}</span>
              ))
            : title}
        </h2>
        {children}
        {buttons ? (
          buttons.map((button, index) => (
            <DotButton
              key={modalId + '-button-' + index}
              className={getClassNames(
                {
                  block: true,
                },
                button.type || 'default'
              )}
              onClick={button.callback}
            >
              {button.text}
            </DotButton>
          ))
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.any,
  modalId: PropTypes.string,
  exit: PropTypes.func,
  icon: PropTypes.string,
  iconStyle: PropTypes.object,
  title: PropTypes.any,
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
};
