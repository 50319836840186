/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import pluralize from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  deleteUser,
  getOrgSingle,
  getOrgUsers,
  postUser,
} from '../../../api/orgs_users';
import { addUsersToRole, getRoles } from '../../../api/roles';
import { endpointConfig } from '../../../config/endpoint_config.js';
import { SIDEBARS, useSidebar } from '../../../context/SidebarContext';
import { useUser } from '../../../context/UserContext';
import { historyType } from '../../../enums/propTypes';
import { Role } from '../../../enums/role';
import { only } from '../../../utils';
import { sortBy, unique } from '../../../utils/array';
import { handleError } from '../../../utils/error';
import { cancellablePromise } from '../../../utils/promise';
import { getClassNames } from '../../../utils/string';
import { AdminBar } from '../../common/AdminBar';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { MenuButton } from '../../common/Menu';
import { AdminMenu } from '../../common/menus/AdminMenu';
import { NavBar } from '../../common/Navbar';
import { DarkScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import { Sidebar } from '../../common/Sidebar';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { CreateEditRole } from '../../sidebars/CreateEditRole';
import { CreateEditUser } from '../../sidebars/CreateEditUser';
import { RolePanel } from './RolePanel';
import { UserPanel } from './UserPanel';
import { useJoyride } from '../../../context/JoyrideContext';
import './UsersAndGroups.scss';
import { Tours } from '../../../enums/tours';
import { AutoSizer, List } from 'react-virtualized';
import { Select } from '../../common/Select';
import badge from '../../../assets/svg/dot-badge.svg';

/**
 * Users and groups page
 *
 * @param {any} history react router
 */
export const UsersAndGroups = ({ history }) => {
  const sidebars = useSidebar();

  // Users from selected org
  const [users, setUsers] = useState([]);
  const [userToDelete, setUserToDelete] = useState();
  const [userSelected, setUserSelected] = useState({
    firstname: '',
    lastname: '',
    role: '',
    email: '',
    accessLevel: '',
    callSign: '',
    userGroupId: '',
  });

  const [usersToDelete, setUsersToDelete] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [selectRoleForUsersOpen, setSelectRoleForUsersOpen] = useState(false);
  const [selectedRoleForUsers, setSelectedRoleForUsers] = useState(null);

  // Which "group" to render users from  (or org if not selected)
  // If === "", render all users from the org
  const [roles, setRoles] = useState([]);
  const [roleSelected, setRoleSelected] = useState(null);
  const [roleToEdit, setRoleToEdit] = useState(null);

  // Global app state vars
  const userValue = useUser();

  const joyride = useJoyride();

  const { id } = useParams();

  const [org, setOrg] = useState(null);
  const [roleSearch, setRoleSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [sortByUx, setSortBy] = useState('Admin Level');
  const [loading, setLoading] = useState(true);

  const list = useRef();

  const refreshRoles = async () => {
    log.debug('refreshRoles');
    try {
      const _roles = await getRoles(id);
      log.debug('getRoles', _roles);
      setRoles(_roles);
    } catch (err) {
      handleError(err);
    }
  };

  const refreshUsers = async () => {
    try {
      if (roleSelected) {
        const users = await getOrgUsers(id);
        const filteredUsers = users.filter((u) => u.roleId === roleSelected.id);
        setUsers(filteredUsers);
      } else {
        setUsers(await getOrgUsers(id));
      }
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!!joyride.joyrideComponent) {
      joyride.setTour(Tours.Users);
      joyride.setStepIndex(0);
      joyride.setChecked(false);
      var tourDate = joyride.getTourDate(Tours.Users);
      joyride.setRunning(
        tourDate != null && tourDate > joyride.dateEdited ? false : true
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userValue?.user) {
      if (
        userValue.user.accessRoles.includes(Role.SysAdmin) ||
        (userValue.user.accessRoles.includes(Role.OrgAdmin) &&
          userValue.user.organisationId === id)
      ) {
        const { promise, cancel } = cancellablePromise(getOrgSingle(id));
        promise
          .then((o) => {
            if (!o) {
              history.push(endpointConfig.frontendEndpoints.organisations);
            } else {
              setOrg(o);
            }
          })
          .catch((e) => {});
        return cancel;
      } else {
        // doesn't have access
        toast.error(`You don't have access to this organisation`);
        history.push(endpointConfig.frontendEndpoints.organisations);
      }
    }
    return () => {};
  }, [userValue?.user, id, history]);

  function filterRoles(roles) {
    return roles?.filter((g) =>
      g?.role.toLowerCase().includes(roleSearch.toLowerCase())
    );
  }

  function filterUsers(users) {
    return users?.filter(
      (g) =>
        g?.firstname.toLowerCase().includes(userSearch.toLowerCase()) ||
        g?.lastname.toLowerCase().includes(userSearch.toLowerCase())
    );
  }

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(refreshRoles());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sidebars.closeAll();

    const { promise, cancel } = cancellablePromise(refreshUsers());
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, [roleSelected]);

  /* Add users to role handler */
  const handleAddUsersToRole = async () => {
    if (org && selectedUsers) {
      let selectedUsersIds = selectedUsers.map((su) => su.userId);
      try {
        await addUsersToRole(org.id, selectedRoleForUsers, selectedUsersIds);
        setSelectRoleForUsersOpen(false);
        refreshUsers();
        if (!selectedRoleForUsers || selectedRoleForUsers === 'null') {
          toast.success(
            `${pluralize('user', selectedUsers.length, true)} removed from role`
          );
        } else {
          toast.success(
            `${pluralize('user', selectedUsers.length, true)} added to role`
          );
        }
        setSelectedUsers([]);
      } catch (err) {
        log.error('error adding multiple users to role: ', err);
        return;
      }
    }
  };

  /* Delete user handler */
  const handleDelete = async () => {
    if (userToDelete) {
      try {
        await deleteUser(userToDelete.id);
        setUserToDelete(null);
        toast.success('User deleted');
      } catch (err) {
        log.error('error deleting org: ', err);
        return;
      }
    }

    await refreshUsers();
  };

  /* Delete user handler */
  const handleDeleteMultiple = async () => {
    if (usersToDelete.length) {
      try {
        for (const user of usersToDelete) {
          if (user.canDelete) {
            await deleteUser(user.userId);
          }
        }

        setUsersToDelete([]);
        toast.success(
          `${pluralize('user', usersToDelete.length, true)} deleted`
        );
      } catch (err) {
        handleError(err);
        return;
      }
    }

    await refreshUsers();
  };

  /* Delete user handler */
  const handleRemoveMultiple = async () => {
    if (usersToRemove.length) {
      try {
        for (const user of usersToRemove) {
          const currentUser = only(
            users.find((u) => u.id === user.userId),
            [
              'email',
              'organisationId',
              'firstname',
              'lastname',
              'accessRoles',
              'photoUrl',
              'blob',
              'callSign',
              'roleId',
            ]
          );

          currentUser.roleId = null;

          await postUser(user.userId, currentUser);
        }

        setUsersToRemove([]);
        toast.success(
          `${pluralize('user', usersToRemove.length, true)} removed from ${
            roleSelected.role
          }`
        );
      } catch (err) {
        handleError(err);
        return;
      }
    }

    await refreshUsers();
  };

  const editUser = (user) => {
    log.debug('editUser', user);
    setUserSelected(
      user || {
        firstname: '',
        lastname: '',
        email: '',
        accessLevel: '',
        callSign: '',
        roleId: roleSelected?.id,
      }
    );
    sidebars.open(SIDEBARS.newUser);
  };

  const sortUsers = (users) => {
    if (users) {
      const _users = [...users];

      if (sortByUx === 'Admin Level') {
        sortBy(
          _users,
          ['accessRoles.0', 'firstname', 'lastname'],
          ['desc', 'asc', 'asc'],
          true
        );
      } else if (sortByUx === 'Email Address') {
        sortBy(
          _users,
          ['email', 'firstname', 'lastname'],
          ['asc', 'asc', 'asc'],
          true
        );
      } else if (sortByUx === 'Name') {
        sortBy(_users, ['firstname', 'lastname'], ['asc', 'asc'], true);
      } else if (sortByUx === 'Role') {
        sortBy(
          _users,
          ['role.role', 'firstname', 'lastname'],
          ['asc', 'asc', 'asc'],
          true
        );
      }

      return _users;
    }

    return users;
  };

  const isSysAdmin = () => {
    return (
      userValue?.user?.accessRoles?.length &&
      userValue?.user?.accessRoles.includes(Role.SysAdmin)
    );
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollLeft } = e;
    const { Grid } = list.current;
    Grid.handleScrollEvent({ scrollTop, scrollLeft });
  };

  const sortedUsers = sortUsers(users);
  const filteredUsers = filterUsers(sortedUsers);

  const rowRenderer = ({ index, key, isScrolling, style }) => {
    const user = userSearch ? filteredUsers[index] : sortedUsers[index];

    return (
      <div
        key={key}
        style={{ ...style, paddingBottom: '16px', width: 'calc(100% - 3rem)' }}
      >
        {!!user && (
          <UserPanel
            key={user.id}
            style={style}
            user={{
              firstname: user.firstname,
              lastname: user.lastname,
              roleId: user.roleId,
              accessLevel: user.accessRoles[0],
              email: user.email,
              photoUrl: user.photoUrl || '',
              id: user.id,
              callSign: user.callSign || '',
              invitationAccepted: user.invitationAccepted,
              role: user.role,
              organisation: user.organisation,
            }}
            editUser={editUser}
            setUserToDelete={setUserToDelete}
            selected={!!selectedUsers.find((s) => s.userId === user.id)}
            selectUser={(u) => {
              const found = selectedUsers.find((s) => s.userId === u.userId);
              if (found) {
                setSelectedUsers(
                  unique([
                    ...selectedUsers.filter((s) => s.userId !== u.userId),
                  ])
                );
              } else {
                setSelectedUsers(unique([...selectedUsers, u]));
              }
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="users-container">
      <NavBar
        activeRoute="org-users"
        history={history}
        onBack={() => history.goBack()}
      >
        <AdminMenu history={history} activeRoute="org-users"></AdminMenu>
      </NavBar>
      <div className="users-middle">
        <AdminBar
          title="Organisation Members"
          activeRoute="org"
          onBack={() => history.goBack()}
        />
        <div className="users-content">
          <div className="users-content-col1">
            <SearchInput
              placeholder="Search roles..."
              onChange={(e) => setRoleSearch(e.target.value)}
            />

            <div className="users-content-col1-title-add step-18">
              <div className="users-content-col1-title-add-title">Roles</div>
              <DotButton
                className="users-content-col1-title-add-add"
                onClick={() => sidebars.open(SIDEBARS.newGroup)}
              >
                <FontAwesomeIcon icon="plus" />
              </DotButton>
            </div>

            {/* RENDER PARENT ORG AND GROUPS /////////////////////////////// */}

            <div className="users-content-col1-division-container">
              <div className="users-content-col1-division-container-separator" />
              <DarkScrollbar>
                <div
                  className={getClassNames({
                    'group-render': true,
                    selected: !roleSelected,
                  })}
                >
                  <DotButton
                    className="group-render-select"
                    onClick={() => {
                      setRoleSelected(null);
                    }}
                  >
                    <Avatar entity={{ role: 'All Roles' }} />
                    <div className="group-render-info">
                      <div className="group-render-info-title">All Roles</div>
                    </div>
                  </DotButton>
                </div>
                {filterRoles(roles) ? (
                  filterRoles(roles).map((role) => {
                    return (
                      <RolePanel
                        key={role.id}
                        role={role}
                        selected={role?.id === roleSelected?.id}
                        editRole={(r) => {
                          setRoleToEdit(r);
                          sidebars.open(SIDEBARS.newGroup);
                        }}
                        updateRoles={(g) => {
                          if (g?.id === roleSelected?.id || g?.deleted) {
                            setRoleSelected(null);
                            refreshRoles();
                            refreshUsers();
                          } else {
                            setRoleSelected({ ...g });
                          }
                        }}
                      />
                    );
                  })
                ) : (
                  <div className="users-content-col1-division-container-load">
                    <FontAwesomeIcon icon="sync-alt" />
                  </div>
                )}
              </DarkScrollbar>
            </div>
          </div>

          {/* GROUP BREADCRUMBS //////////////////////////////////////////////// */}
          {loading ? (
            <div
              className="loading-panel"
              style={{
                position: 'absolute',
                zIndex: '3',
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ animation: 'rotation 2s infinite linear' }}>
                <img
                  src={badge}
                  alt="AUSTAC Logo"
                  style={{ height: '50px', width: '50px' }}
                />
              </div>
              <div style={{ fontWeight: 600, fontSize: 20, color: 'white' }}>
                Loading users
              </div>
            </div>
          ) : (
            <div className="users-content-col2">
              <div className="users-content-col2-row1">
                <div className="level" style={{ marginBottom: '1rem' }}>
                  <div className="level-left">
                    <div className="level-item users-content-col2-row1-company-bar breadcrumb">
                      {org && (
                        <ul>
                          <li>
                            <DotButton
                              className="users-content-col2-row1-company-bar-org"
                              onClick={() => {
                                setRoleSelected(null);
                              }}
                            >
                              {org.organisationName}
                            </DotButton>
                          </li>
                          {!!roleSelected && (
                            <li>
                              <DotButton
                                className="users-content-col2-row1-company-bar-org"
                                onClick={() => {}}
                              >
                                {roleSelected.role}
                              </DotButton>
                              {/* <DotButton
                                className="remove"
                                onClick={() => {
                                  setRoleSelected(null);
                                }}
                              >
                                <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
                              </DotButton> */}
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="level-right">
                    <div className="search-bar">
                      <SearchInput
                        placeholder="Search users..."
                        onChange={(e) => setUserSearch(e.target.value)}
                      />
                    </div>
                    <DotButton
                      className="primary step-1 step-17"
                      onClick={() => editUser(null)}
                      style={{ width: '200px' }}
                    >
                      <span>New User</span>
                      <FontAwesomeIcon icon="plus" />
                    </DotButton>
                  </div>
                  {!isSysAdmin() ? (
                    <div style={{ position: 'absolute' }}>
                      {joyride.joyrideComponent}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {/* USER PANELS //////////////////////////////////////////////// */}
              </div>
              <div className="users-content-col2-row2">
                {selectedUsers.length ? (
                  <div className="users-content-col2-row2-container selected">
                    <div className="users-content-col2-row2-container-left-item">
                      {selectedUsers.length}{' '}
                      {pluralize('User', selectedUsers.length)}
                      {' selected '}
                      <DotButton
                        style={{ marginLeft: '20px' }}
                        className="link"
                        onClick={() => {
                          setSelectedUsers([]);
                        }}
                      >
                        Click to Unselect
                      </DotButton>{' '}
                      <DotButton
                        style={{ marginLeft: '20px' }}
                        className="link"
                        onClick={() => {
                          const allSame = selectedUsers.every(
                            (su, i, arr) => su.roleId === arr[0].roleId
                          );
                          if (allSame) {
                            setSelectedRoleForUsers(selectedUsers[0].roleId);
                          } else {
                            setSelectedRoleForUsers(null);
                          }
                          setSelectRoleForUsersOpen(true);
                        }}
                      >
                        Edit role
                      </DotButton>
                    </div>
                    <div className="users-content-col2-row2-container-right-item-text1">
                      {!!roleSelected && (
                        <DotButton
                          className="link"
                          onClick={() => {
                            setUsersToRemove(selectedUsers);
                          }}
                        >
                          Remove from this role
                        </DotButton>
                      )}
                      {selectedUsers.filter((s) => s.canDelete).length ===
                        selectedUsers.length && (
                        <DotButton
                          className="link"
                          onClick={() => {
                            setUsersToDelete(selectedUsers);
                          }}
                        >
                          Delete
                        </DotButton>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="users-content-col2-row2-container">
                    {!!!userSearch && (
                      <div className="users-content-col2-row2-container-left-item">
                        {users?.length || 0} Active{' '}
                        {pluralize('User', users?.length || 0)}
                      </div>
                    )}
                    {!!userSearch && (
                      <div className="users-content-col2-row2-container-left-item">
                        {filteredUsers?.length || 'No'}{' '}
                        {pluralize('User', filteredUsers?.length || 0)} found
                      </div>
                    )}
                    <div className="users-content-col2-row2-container-right-item-text1">
                      <MenuButton
                        title="Sort By:"
                        options={[
                          {
                            text: 'Admin Level',
                            ariaLabel: 'Admin Level',
                            value: 'Admin Level',
                            onClick: () => {
                              setSortBy('Admin Level');
                            },
                          },
                          {
                            text: 'Email Address',
                            ariaLabel: 'Email Address',
                            value: 'Email Address',
                            onClick: () => {
                              setSortBy('Email Address');
                            },
                          },
                          {
                            text: 'Name',
                            ariaLabel: 'Name',
                            value: 'Name',
                            onClick: () => {
                              setSortBy('Name');
                            },
                          },
                          {
                            text: 'Role',
                            ariaLabel: 'Role',
                            value: 'Role',
                            onClick: () => {
                              setSortBy('Role');
                            },
                          },
                        ]}
                      >
                        <span>{sortByUx}</span>
                        <FontAwesomeIcon icon="chevron-down"></FontAwesomeIcon>
                      </MenuButton>
                    </div>
                  </div>
                )}
              </div>
              <AutoSizer>
                {({ height, width }) => (
                  <DarkScrollbar
                    onScroll={handleScroll}
                    noScrollX
                    style={{ height, width }}
                  >
                    <div className="users-content-col2-row3 ">
                      <List
                        height={72 * filterUsers(users).length}
                        width={width}
                        rowHeight={72}
                        rowRenderer={rowRenderer}
                        ref={list}
                        rowCount={filterUsers(users).length}
                        style={{ overflowX: false, overflowY: false }}
                      />
                    </div>
                  </DarkScrollbar>
                )}
              </AutoSizer>
            </div>
          )}
        </div>
      </div>
      <Sidebar id={SIDEBARS.newUser}>
        <CreateEditUser
          closeSidebar={() => {
            sidebars.close(SIDEBARS.newUser);
            refreshUsers();
          }}
          userId={userSelected?.id}
          user={userSelected}
          setUser={setUserSelected}
          updateUsers={() => {
            refreshUsers();
          }}
          orgSelected={org}
          roleSelected={roleSelected}
        />
      </Sidebar>
      <Sidebar id={SIDEBARS.newGroup} check>
        <CreateEditRole
          closeSidebar={async () => {
            log.debug('closeSidebar');
            sidebars.close(SIDEBARS.newGroup);
            await refreshRoles();
            await refreshUsers();
            setRoleToEdit(null);
          }}
          orgSelected={org}
          role={roleToEdit}
        />
      </Sidebar>
      <ConfirmModal
        isActive={selectRoleForUsersOpen}
        exit={() => {
          setSelectRoleForUsersOpen(false);
        }}
        modalId="add-users-to-role"
        title="Edit role"
        children={
          <div style={{ width: '100%' }}>
            <Select
              label="Role"
              onChange={(e) => setSelectedRoleForUsers(e.currentTarget.value)}
              value={selectedRoleForUsers}
              placeholder=""
            >
              <option value={null}>No Role</option>
              {roles &&
                roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.role}
                  </option>
                ))}
            </Select>
          </div>
        }
        buttons={[
          {
            text: 'SAVE CHANGES',
            type: 'primary',
            callback: handleAddUsersToRole,
          },
        ]}
      ></ConfirmModal>

      <ConfirmModal
        isActive={!!userToDelete}
        exit={() => {
          setUserToDelete(null);
        }}
        icon="exclamation-circle"
        modalId="delete-user"
        title="Delete from organisation?"
        buttons={[
          {
            text: 'Yes, Delete User',
            type: 'primary',
            callback: handleDelete,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setUserToDelete(null),
          },
        ]}
      >
        This account will no longer be accessible by the user or by the
        organisation.
      </ConfirmModal>

      <ConfirmModal
        isActive={!!usersToDelete.length}
        exit={() => {
          usersToDelete([]);
        }}
        icon="exclamation-circle"
        modalId="delete-users"
        title={`Delete ${pluralize(
          'user',
          usersToDelete.length,
          true
        )} from organisation?`}
        buttons={[
          {
            text: 'Yes, Delete Users',
            type: 'primary',
            callback: handleDeleteMultiple,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setUsersToDelete([]),
          },
        ]}
      >
        These accounts will no longer be accessible by the user or by the
        organisation.
      </ConfirmModal>

      <ConfirmModal
        isActive={!!usersToRemove.length}
        icon="exclamation-circle"
        modalId="remove-users"
        title={`Remove ${pluralize('user', usersToRemove.length, true)} from ${
          roleSelected?.role
        }?`}
        buttons={[
          {
            text: 'Yes, Remove Users',
            type: 'primary',
            callback: handleRemoveMultiple,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setUsersToRemove([]),
          },
        ]}
      >
        These users will be removed from the role and will no longer have access
        to missions the role is assigned to.
      </ConfirmModal>
    </div>
  );
};

UsersAndGroups.propTypes = {
  history: historyType,
};
