/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  deleteOrganisation,
  getOrganisations,
  getOrgSingle,
} from '../../../api/orgs_users';
import { SIDEBARS, useSidebar } from '../../../context/SidebarContext';
import { useUser } from '../../../context/UserContext';
import { historyType } from '../../../enums/propTypes';
import { Role } from '../../../enums/role';
import { cancellablePromise } from '../../../utils/promise';
import { AdminBar } from '../../common/AdminBar';
import { DotButton } from '../../common/buttons/DotButton';
import { AdminMenu } from '../../common/menus/AdminMenu';
import { NavBar } from '../../common/Navbar';
import { DarkScrollbar } from '../../common/Scrollbars';
import { Sidebar } from '../../common/Sidebar';
import { ConfirmModal } from '../../modals/ConfirmModal';
import { CreateEditOrg } from '../../sidebars/organisation/CreateEditOrg';
import './Organisations.scss';
import { OrgPanel } from './orgPanel';
import badge from '../../../assets/svg/dot-badge.svg';
import { postFile } from '../../../api/other';

/**
 * Organisations list
 *
 * @param {any} history react router
 */
export const Organisations = ({ history }) => {
  const sidebars = useSidebar();
  const userValue = useUser();

  const canCreateOrg = userValue.user.accessRoles.includes(Role.SysAdmin);

  const [orgToDelete, setOrgToDelete] = useState();
  const [organisations, setOrganisations] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshOrganisations = async () => {
    try {
      if (userValue.user.accessRoles.includes(Role.SysAdmin)) {
        const newOrgs = await getOrganisations();
        setOrganisations(newOrgs);
      } else if (userValue.user.accessRoles.includes(Role.OrgAdmin)) {
        const org = await getOrgSingle(userValue.user.organisationId);
        setOrganisations([org]);
      }
    } catch (err) {
      log.error('error getting orgs', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(refreshOrganisations()); // converting original promise to cancellable promise
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  /*
   Handles deleting organisations

   Only sys admin has access to deleting orgs
   */
  const handleDelete = async () => {
    if (orgToDelete) {
      try {
        await deleteOrganisation(orgToDelete.id);
        setOrgToDelete(null);
        toast.success('Organisation deleted');
      } catch (err) {
        log.error('error deleting org: ', err);
      }
    }

    // Given only sys admin has access to this
    // "get all orgs" api is used.
    refreshOrganisations();
  };

  return (
    <div className="org-container">
      <NavBar activeRoute="org" history={history}>
        <AdminMenu history={history} activeRoute="org"></AdminMenu>
      </NavBar>
      {loading ? (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ animation: 'rotation 2s infinite linear' }}>
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20, color: 'white' }}>
            Loading organisations
          </div>
        </div>
      ) : (
        <div className="org-middle">
          <AdminBar title="Organisations" />
          <div className="org-content">
            <div className="org-content-row1">
              <div className="level">
                <div className="level-left">
                  <div className="level-item org-content-count-title">
                    {organisations &&
                      `${organisations.length} ${
                        organisations.length === 1
                          ? 'Organisation'
                          : 'Organisations'
                      }`}
                  </div>
                </div>
                {canCreateOrg && (
                  <div
                    className="level-right"
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <input
                      id="uploadSpreadsheet"
                      type="file"
                      accept=".csv"
                      style={{ display: 'none' }}
                      onChange={
                        // send file via a post request
                        async (e) => {
                          try {
                            await postFile(
                              `/organisations/upload`,
                              e.target.files[0]
                            ).then(() => {
                              toast.success('Spreadsheet uploaded');
                              refreshOrganisations();
                            });
                          } catch (err) {
                            toast.error('Error uploading spreadsheet');
                          }
                          e.target.value = null;
                        }
                      }
                    />
                    <DotButton
                      className="primary"
                      style={{ marginRight: '1rem' }}
                      onClick={
                        // open file explorer
                        () =>
                          document.getElementById('uploadSpreadsheet').click()
                      }
                    >
                      <span>From Spreadsheet</span>
                      <FontAwesomeIcon icon="file-csv" />
                    </DotButton>
                    <DotButton
                      className="primary"
                      onClick={() => sidebars.open(SIDEBARS.newOrg)}
                    >
                      <span>New Organisation</span>
                      <FontAwesomeIcon icon="plus" />
                    </DotButton>
                  </div>
                )}
              </div>
            </div>
            {organisations?.length ? (
              <DarkScrollbar className="org-content-row2 ">
                {organisations.map((org, index) => {
                  return (
                    <OrgPanel
                      key={index}
                      location={org.address.slice(0, 10) + '...'}
                      activeUsers={org.users}
                      history={history}
                      org={org}
                      setOrgToDelete={setOrgToDelete}
                    />
                  );
                })}
              </DarkScrollbar>
            ) : (
              <div className="no-orgs">
                {canCreateOrg ? (
                  <DotButton onClick={() => sidebars.open(SIDEBARS.newOrg)}>
                    <FontAwesomeIcon icon="plus" />
                    <span>Tap here to create your first organisation</span>
                  </DotButton>
                ) : (
                  <span>No organisations</span>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <Sidebar id={SIDEBARS.newOrg}>
        <CreateEditOrg
          closeSidebar={() => {
            sidebars.close(SIDEBARS.newOrg);
            refreshOrganisations().then(() => {});
          }}
        />
      </Sidebar>

      <ConfirmModal
        isActive={!!orgToDelete}
        exit={() => {
          setOrgToDelete(null);
        }}
        icon="exclamation-circle"
        modalId="delete-organisation"
        title={[
          'Delete the ',
          <strong>{orgToDelete?.organisationName}</strong>,
          ' organisation?',
        ]}
        buttons={[
          {
            text: 'Yes, Delete the Organisation',
            type: 'primary',
            callback: handleDelete,
          },
          {
            text: 'No, Go Back',
            type: 'secondary',
            callback: () => setOrgToDelete(null),
          },
        ]}
      >
        All organisation admins and users will no longer be able to access
        accounts linked to this organisation.
      </ConfirmModal>
    </div>
  );
};

Organisations.propTypes = {
  history: historyType,
};
