/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { postResetPassword } from '../../api/orgs_users';
import { endpointConfig } from '../../config/endpoint_config.js';
import { useUser } from '../../context/UserContext';
import { historyType } from '../../enums/propTypes';
import { logout } from '../../utils/auth';
import { handleError } from '../../utils/error';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { Welcome } from '../generic-components/Welcome';
import './Invitation.scss';

/**
 * Set new password
 *
 * @param {any} history react router
 */
export const SetPassword = ({ history }) => {
  const userValue = useUser();
  const [isVerified, setIsVerified] = useState(false);

  // Password details
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');

  const { id } = useParams();

  // If user is logged in they cannot access this pag
  useEffect(() => {
    log.debug(userValue);
    if (userValue.user?.id) {
      // logged in - logout
      logout(userValue);
    }

    if (id) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
    //eslint-disable-next-line
  }, []);

  const resetPassword = async (e) => {
    e.preventDefault();

    // Input error checking
    if (newPassword !== newPasswordCheck) {
      toast.error('Passwords do not match');
      return;
    }
    if (newPassword.length < 6) {
      toast.error('Password must have a minimum of 6 characters');
      return;
    }

    try {
      await postResetPassword(id, newPassword);
    } catch (err) {
      handleError(err);
      return;
    }

    toast.success('Password successfully changed');

    history.push(endpointConfig.frontendEndpoints.login);
  };

  return (
    <Welcome>
      {isVerified ? (
        <>
          <div className="login-title">Set your new password</div>
          <div className="text">
            <p>
              Enter a new password below.
              {/* Enter a new password below for <strong>{email}</strong> */}
            </p>
          </div>

          <form>
            <Input
              label="New Password"
              placeholder="Enter your new password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required={true}
            />
            <Input
              label="Confirm Password"
              placeholder="Re-enter your new password"
              type="password"
              value={newPasswordCheck}
              onChange={(e) => setNewPasswordCheck(e.target.value)}
              required={true}
            />
            <DotButton
              type="submit"
              className="primary "
              onClick={resetPassword}
            >
              Confirm
            </DotButton>
          </form>
        </>
      ) : (
        <div>Invalid reset URL</div>
      )}
    </Welcome>
  );
};

SetPassword.propTypes = { history: historyType };
