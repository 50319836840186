/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * convert hex colour to RGB values
 *
 * @param {*} hex
 * @return {{r: number, g: number, b: number}} rgb values
 */
export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

/**
 * Get a random colour.
 *
 * @return {string} colour as hex value
 */
export const getRandomColor = () => {
  const allyColours = [
    '#2F7954',
    '#1C858D',
    '#3C4CDE',
    '#84298C',
    '#524290',
    '#9B5A36',
    '#A39200',
  ];
  return allyColours[Math.floor(Math.random() * allyColours.length)];
};

/**
 * Darken an RGB value
 *
 * @param {string} hex hex string #FFFFFF
 * @param {number} percent 0-1
 * @return {string} hex value
 */
export const darkenHex = (hex, percent) => {
  let r = 0;
  let g = 0;
  let b = 0;

  const _hex = hex.replace('#', '');
  if (_hex.length === 6) {
    r = parseInt(_hex.substring(0, 2), 16);
    g = parseInt(_hex.substring(2, 4), 16);
    b = parseInt(_hex.substring(4), 16);
  } else if (_hex.length === 3) {
    r = parseInt(`${_hex.substring(0, 1)}${_hex.substring(0, 1)}`, 16);
    g = parseInt(`${_hex.substring(1, 2)}${_hex.substring(1, 2)}`, 16);
    b = parseInt(`${_hex.substring(2)}${_hex.substring(2)}`, 16);
  }

  r = Math.round(r * percent);
  g = Math.round(g * percent);
  b = Math.round(b * percent);

  let rhex = r.toString(16);
  if (rhex.length === 1) {
    rhex = '0' + rhex;
  }

  let ghex = g.toString(16);
  if (ghex.length === 1) {
    ghex = '0' + ghex;
  }

  let bhex = b.toString(16);
  if (bhex.length === 1) {
    bhex = '0' + bhex;
  }

  return `#${rhex}${ghex}${bhex}`;
};
