/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import pause from '../../assets/svg/pause.svg';
import play from '../../assets/svg/play.svg';
import { useMedia } from '../../context/MediaContext';
import './AudioPlayer.scss';
import { DotButton } from './buttons/DotButton';
import { Avatar } from './Avatar';
import { displayName, getClassNames } from '../../utils/string';
import live from '../../assets/svg/live.svg';
import { PropTypes } from 'prop-types';

/**
 * Audio player that can exist either in a comms chat window or popped out and floting in the UI
 *
 * @param {Boolean} floating is the audio player floating in the UI (detached from comms)
 * @param {File} file audio file to play
 * @param {string} entity personnel who initiated the message
 */
export const AudioPlayer = ({ floating, file, entity }) => {
  const media = useMedia();

  const [isFloating, setIsFloating] = useState(false);
  const [playerStyle, setPlayerStyle] = useState([]);

  useEffect(() => {
    setIsFloating(floating && media?.expanded);
  }, [floating, media?.expanded]);

  useEffect(() => {
    const isActive =
      (floating || media?.media?.file === file?.file) &&
      media?.media?.status === 'playing';

    const styles = [];
    for (let i = 0; i < 40; i++) {
      styles.push({
        animation: isActive
          ? `equalizer ${Math.random() * (3 - 0.3) + 0.3}s ease infinite`
          : '',
        animationDirection: isActive ? `alternate-reverse` : '',
      });
    }

    setPlayerStyle(styles);
  }, [media?.media?.status, media?.media?.file, file?.file, floating]);

  useEffect(() => {
    media.setNumPlayers(document.getElementsByClassName('audio-player').length);
    return () => {
      media.setNumPlayers(
        document.getElementsByClassName('audio-player').length
      );
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={getClassNames({ 'audio-player': true, floating: isFloating })}
    >
      <div className="toolbar">
        <div className="left">
          <Avatar entity={media?.media?.entity || entity} size="1rem"></Avatar>
          {displayName(media?.media?.entity || entity)}
          {(media?.media?.type === 'audio-stream' ||
            file.type === 'audio-stream') && <img src={live} alt="live" />}
        </div>
        <div className="right">
          {isFloating ? (
            <>
              {media.numPlayers > 1 ? (
                <DotButton
                  onClick={() => {
                    media.collapse();
                  }}
                >
                  <FontAwesomeIcon
                    icon="compress-alt"
                    flip="horizontal"
                  ></FontAwesomeIcon>
                </DotButton>
              ) : (
                <DotButton
                  onClick={() => {
                    media.close();
                  }}
                  ariaLabel="Close"
                >
                  <FontAwesomeIcon
                    icon="times"
                    style={{ color: 'grey' }}
                    flip="horizontal"
                  ></FontAwesomeIcon>
                </DotButton>
              )}
            </>
          ) : (
            <DotButton
              onClick={() => {
                media.expand();
              }}
            >
              <FontAwesomeIcon
                icon="expand-alt"
                flip="horizontal"
              ></FontAwesomeIcon>
            </DotButton>
          )}
        </div>
      </div>
      <div className="controls">
        <DotButton
          onClick={async () => {
            await media.playPause(file, entity);
          }}
        >
          <img
            src={media?.media?.status === 'playing' ? pause : play}
            alt="play/pause"
          />
        </DotButton>
        <div className="equalizer">
          <div className="horizontal"></div>
          <div className="vertical-lines">
            {Array.from(Array(40).keys()).map((i, idx) => (
              <div
                key={idx}
                className="vertical"
                style={playerStyle[idx]}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

AudioPlayer.propTypes = {
  floating: PropTypes.bool,
  file: PropTypes.any.isRequired,
  entity: PropTypes.any,
};
