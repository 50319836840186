/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import bbox from '@turf/bbox';
import destination from '@turf/destination';
import mapboxgl from 'mapbox-gl';
import svgDisconnected from '../assets/svg/offline.svg';
import { darkenHex } from './colour';
import reactDom from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import distance from '@turf/distance';
import midpoint from '@turf/midpoint';
import { getInitials } from '../utils/string';
import ms from 'milsymbol';
import { getLocalStorage } from './localStorage';

const selfColour = '#3b9fc2';

/**
 * Creates new bounding box the specified distance larger than the original bounding box
 *
 * @param {*}       bounding              original bounding box
 * @param {*}       distance              padding distance
 * @param {string}  [units='kilometers']  distance units
 * @return {*} new bounding box
 */
export const bboxPad = (bounding, distance, units = 'kilometers') => {
  const sw = [bounding[0], bounding[1]];
  const ne = [bounding[2], bounding[3]];

  const sw_ext = destination(sw, distance, 225, {
    units,
  });
  const ne_ext = destination(ne, distance, 45, {
    units,
  });

  return bbox({
    coordinates: [[sw_ext.geometry.coordinates, ne_ext.geometry.coordinates]],
    type: 'Polygon',
  });
};

/**
 * Generate a random LatitudeLongitude point within a circle.
 *
 * @param {*}   center  centre of circle
 * @param {*}   radius  radius in km
 * @return {*} random point
 */
export const randomPoint = (center, radius) => {
  const y0 = center.latitude;
  const x0 = center.longitude;

  const rd = radius / 111300;

  const u = Math.random();
  const v = Math.random();

  const w = rd * Math.sqrt(u);
  const t = 2 * Math.PI * v;
  const x = w * Math.cos(t);
  const y = w * Math.sin(t);

  return {
    latitude: y + y0,
    longitude: x + x0,
  };
};

/**
 * Convert an unknown coordinate to LngLat
 *
 * @param {*}   coords  some coordinate
 * @return {*} LngLat coordinate
 */
export const toLngLat = (coords) => {
  if (!coords) return null;

  if (Array.isArray(coords)) {
    return {
      lat: coords[1],
      lng: coords[0],
    };
  }

  return {
    lat: coords.lat || coords.latitude,
    lng: coords.lng || coords.longitude,
  };
};

/**
 * Convert an unknown coordinate to LongitudeLatitude
 *
 * @param {*}   coords  some coordinate
 * @return {*} LongitudeLatitude coordinate
 */
export const toLongitudeLatitude = (coords) => {
  if (!coords) return null;

  if (Array.isArray(coords)) {
    return {
      latitude: coords[1],
      longitude: coords[0],
    };
  }

  return {
    latitude: coords.latitude || coords.lat,
    longitude: coords.longitude || coords.lng,
  };
};

/**
 * Convert an unknown coordinate to array
 *
 * @param {*} coords some coordinate
 * @return {*} array coordinates
 */
export const toArray = (coords) => {
  if (!coords) return null;

  return [coords.longitude || coords.lng, coords.latitude || coords.lat];
};

/**
 * Convert bounds to array representation
 *
 * @param {*}   bounds  bounds
 * @return {*} array matrix
 */
export const boundsToArray = (bounds) => {
  const nw = bounds.getNorthWest();
  const ne = bounds.getNorthEast();
  const se = bounds.getSouthEast();
  const sw = bounds.getSouthWest();
  return [
    [nw.lng, nw.lat],
    [ne.lng, ne.lat],
    [se.lng, se.lat],
    [sw.lng, sw.lat],
  ];
};

/**
 * Creates a DOM marker for currently logged in user.
 *
 * @param {Map}     map       MapBox map instance.
 * @param {string}  sourceId  Id of geojson source.
 * @param {any}     feature   Geojson feature.
 *
 * @return {Marker} Map Marker.
 */
export const selfMarker = (map, sourceId, feature) => {
  const id = feature.properties.cluster_id || feature.properties.id;

  const el = document.createElement('div');
  el.className = 'html-marker self-marker step-19';
  el.id = id;
  if (feature.properties.organisationId) {
    el.dataset.organisationId = feature.properties.organisationId;
  }

  const innerEl = document.createElement('div');
  innerEl.className = 'inner';
  innerEl.innerHTML = `<svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.1002 0.164934L1.23464 9.16025C-0.826569 10.2098 -0.139498 13.5079 2.05921 13.5079H9.61784V21.7537C9.61784 24.1523 12.6411 24.9023 13.6032 22.6532L21.8489 3.16353C22.536 1.364 20.7493 -0.585067 19.1002 0.164934Z" fill="white"/>
  </svg>`;

  const numHalos = 4;
  for (let i = 0; i < numHalos - 1; i++) {
    const animationEl = document.createElement('div');
    animationEl.className = `halo ${i === 0 ? 'first' : ''}`;
    // animationEl.style.background = `green`;
    animationEl.style.background = `radial-gradient(50% 50% at 50% 50%, rgba(16, 16, 16, 1) 0%, rgba(16, 16, 16, 1) 100%)`;
    animationEl.style.animationDelay = `${i * (2 / numHalos)}s`;
    el.appendChild(animationEl);
  }
  el.appendChild(innerEl);

  // Add markers to the map.
  return new mapboxgl.Marker(el, {
    draggable: true,
  }).setLngLat(feature.geometry.coordinates);
};

/**
 * Creates a DOM marker for single personnel.
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {any}     feature       Geojson feature.
 *
 * @return {Marker} Map Marker.
 */
export const personnelMarker = (map, sourceId, feature) => {
  const id = feature.properties.cluster_id || feature.properties.id;
  const now = Date.now();
  const timestamp = feature.properties.timestamp * 1000;
  const timeDifference = now - timestamp;
  //milliseconds in 1 hour
  const shortDur = 3600000;
  //milliseconds in 3 hours
  const mediumDur = 10800000;
  //milliseconds in 2 minutes
  //const disconnectedDur = 60 * 2 * 1000;
  //const isDisconnected = timeDifference > disconnectedDur;
  const isDisconnected = false;

  const el = document.createElement('div');
  el.id = id;
  el.className = 'html-marker unit-marker';
  if (feature.properties.color === selfColour) {
    el.className = 'html-marker unit-marker self';
  }
  if (feature.properties.organisationId) {
    el.dataset.organisationId = feature.properties.organisationId;
  }
  if (feature.properties.roleId) {
    el.dataset.roleId = feature.properties.roleId;
  } else if (feature.properties.guest) {
    el.dataset.roleId = 'guest';
  }

  if (isDisconnected) {
    const disconnectedOverlay = document.createElement('div');
    disconnectedOverlay.className = 'disconnected-overlay';
    el.appendChild(disconnectedOverlay);
  }

  const innerEl = document.createElement('div');
  innerEl.className = 'inner';
  // colours == 2
  // innerEl.style.background = `linear-gradient(135deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), ${feature.properties.color}`;
  // colours > 2
  // conic-gradient(#ff0000 120deg, #00ff00 120deg 240deg, blue 240deg)
  innerEl.style.background = `linear-gradient(135deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(268.41deg, #3b9fc2 0%, #2c7690 52.85%)`;
  if (isDisconnected) {
    if (timeDifference <= shortDur) {
      innerEl.style.background = `linear-gradient(135deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(268.41deg, rgba(59,159,194, 0.75) 0%, rgba(44,118,144, 0.75) 52.85%)`;
      innerEl.style.border = 'solid 3px rgba(255, 255, 255, 0.75)';
    } else if (timeDifference > shortDur && timeDifference <= mediumDur) {
      innerEl.style.background = `linear-gradient(135deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(268.41deg, rgba(59,159,194, 0.5) 0%, rgba(44,118,144, 0.5) 52.85%)`;
      innerEl.style.border = 'solid 3px rgba(255, 255, 255, 0.5)';
    } else {
      innerEl.style.border = 'solid 3px rgba(255, 255, 255, 0.3)';
      innerEl.style.background = `linear-gradient(135deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(268.41deg, rgba(59,159,194, 0.3) 0%, rgba(44,118,144, 0.3) 52.85%)`;
    }
  }
  // const criticalIcon = document.createElement('img');
  // criticalIcon.className = 'critical-icon';
  // criticalIcon.src = exclamation;
  // innerEl.appendChild(criticalIcon);

  if (!!feature.properties._ts && isDisconnected) {
    reactDom.render(
      <div>
        <div className="disconnected-icon">
          <img
            src={svgDisconnected}
            style={{
              height: '16px',
              width: '16px',
              position: 'relative',
              zIndex: '5',
            }}
            alt="disconnected"
          />
          <div
            style={{
              width: '20px',
              height: '20px',
              background: `rgba(80, 83, 102, 1)`,
              borderRadius: '50%',
              border: 'solid white 2px',
              position: 'absolute',
              left: '5px',
              top: '4px',
              zIndex: '2',
            }}
          ></div>
        </div>
      </div>,
      innerEl
    );
  }

  if (
    !!feature.properties.color &&
    !isDisconnected &&
    (!!feature.properties.roleId || !!feature.properties.guest)
  ) {
    reactDom.render(
      <div className="role-diamond">
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon="diamond" size="xs" color="white" />
          <FontAwesomeIcon
            icon="diamond"
            size="xs"
            transform="shrink-6"
            color={
              !!feature.properties.guest ? 'black' : feature.properties.color
            }
          />
        </span>
      </div>,
      innerEl
    );
  }

  if (!!feature.properties.photoUrl) {
    const userIcon = document.createElement('img');
    userIcon.className = 'user-icon';
    userIcon.src = feature.properties.photoUrl;
    if (isDisconnected) {
      if (timeDifference <= shortDur) {
        userIcon.style.opacity = '0.75';
      } else if (timeDifference > shortDur && timeDifference <= mediumDur) {
        userIcon.style.opacity = '0.5';
      } else {
        userIcon.style.opacity = '0.3';
      }
    }
    innerEl.appendChild(userIcon);
  } else if (!!feature.properties.callsign) {
    const userCallsign = document.createElement('div');
    userCallsign.className = 'user-callsign';
    userCallsign.innerText = feature.properties.callsign;
    if (isDisconnected) {
      if (timeDifference <= shortDur) {
        userCallsign.style.opacity = '0.75';
      } else if (timeDifference > shortDur && timeDifference <= mediumDur) {
        userCallsign.style.opacity = '0.5';
      } else {
        userCallsign.style.opacity = '0.3';
      }
    }
    innerEl.appendChild(userCallsign);
  } else {
    const userInitials = document.createElement('div');
    userInitials.className = 'user-callsign';
    userInitials.innerText = getInitials(
      feature.properties.firstname + ' ' + feature.properties.lastname
    );
    if (isDisconnected) {
      if (timeDifference <= shortDur) {
        userInitials.style.opacity = '0.75';
      } else if (timeDifference > shortDur && timeDifference <= mediumDur) {
        userInitials.style.opacity = '0.5';
      } else {
        userInitials.style.opacity = '0.3';
      }
    }
    innerEl.appendChild(userInitials);
  }

  const overlay = document.createElement('div');
  overlay.className = 'overlay';
  overlay.style.height = '100%';
  overlay.style.width = '100%';
  overlay.style.pointerEvents = 'none';
  innerEl.appendChild(overlay);

  // const numHalos = 4;
  // for (let i = 0; i < numHalos - 1; i++) {
  //   const animationEl = document.createElement('div');
  //   animationEl.className = `halo ${i === 0 ? 'first' : ''}`;
  //   // animationEl.style.background = `green`;
  //   animationEl.style.background = `radial-gradient(50% 50% at 50% 50%, rgba(16, 16, 16, 1) 0%, rgba(16, 16, 16, 1) 100%)`;
  //   animationEl.style.animationDelay = `${i * (2 / numHalos)}s`;

  //   if (i === 0 && feature.properties.location) {
  //     const location = JSON.parse(feature.properties.location);
  //     if (location?.coords?.accuracy) {
  //       animationEl.className += ' accuracy';
  //       animationEl.style.width = `${location.coords.accuracy}px`;
  //       animationEl.style.height = `${location.coords.accuracy}px`;
  //     }
  //   }
  //   el.appendChild(animationEl);
  // }

  if (!isDisconnected) {
    // const locationRing = document.createElement('div');
    // locationRing.className = 'location-ring';
    // if (feature.properties.location) {
    //   const location = JSON.parse(feature.properties.location);
    //   if (location?.coords?.accuracy) {
    //     locationRing.style.height = `${location.coords.accuracy}px`;
    //     locationRing.style.width = `${location.coords.accuracy}px`;
    //   }
    // }
    // locationRing.style.pointerEvents = 'none';
    // el.appendChild(locationRing);

    const coneEl = document.createElement('div');
    coneEl.className = `heading`;
    if (feature.properties.location) {
      const location = JSON.parse(feature.properties.location);
      if (
        (location?.coords?.heading || location?.coords?.heading >= 0) &&
        location?.coords?.heading_accuracy
      ) {
        const bearing = map.getBearing();
        coneEl.style.background = `conic-gradient(rgba(86, 184, 219, 0) 0deg, rgba(86, 184, 219, 0.9) ${
          location.coords.heading_accuracy / 2.0 -
          location.coords.heading_accuracy / 4.0
        }deg, rgba(86, 184, 219, 0.9) ${
          location.coords.heading_accuracy / 2.0 +
          location.coords.heading_accuracy / 4.0
        }deg, rgba(86, 184, 219, 0) ${
          location.coords.heading_accuracy
        }deg, transparent ${
          location.coords.heading_accuracy
        }deg, transparent 360deg)`;
        coneEl.style.transform = `rotate(${
          -(location.coords.heading_accuracy / 2.0) +
          location.coords.heading -
          bearing
        }deg)`;
        coneEl.style.transform = `rotate(${
          -(location.coords.heading_accuracy / 2.0) +
          location.coords.heading -
          bearing
        }deg)`;
      }
    }
    coneEl.style.pointerEvents = 'none';
    el.appendChild(coneEl);
  }

  const outerRing = document.createElement('div');
  outerRing.className = 'outer-ring';
  if (feature.properties.location) {
    outerRing.style.height = `40px`;
    outerRing.style.width = `40px`;
  }
  outerRing.style.pointerEvents = 'none';
  el.appendChild(outerRing);

  el.appendChild(innerEl);

  // Add markers to the map.
  return new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates);
};

/**
 * Creates a DOM marker for a cluster of missions.
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {any}     feature       Geojson feature.
 *
 * @return {Marker} Map Marker.
 */
export const missionClusterMarker = (map, sourceId, feature) => {
  const id = feature.properties.cluster_id || feature.properties.id;

  var el = document.createElement('div');
  el.id = id;
  el.className = 'html-marker mission-marker';

  var innerEl2 = document.createElement('div');

  var innerEl = document.createElement('div');
  innerEl.style.background = `linear-gradient(135deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), var(--colour-interactions-a)`;

  // is a cluster!
  innerEl.innerText = feature.properties.point_count || 1;

  innerEl2.appendChild(innerEl);
  el.appendChild(innerEl2);

  // Add markers to the map.
  return new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates);
};

/**
 * Creates a DOM marker for a photo or video.
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {any}     feature       Geojson feature.
 * @param {boolean} draggable     Is this marker draggable?
 *
 * @return {Marker} Map Marker.
 */
export const imageMarker = (map, sourceId, feature, draggable) => {
  const id = feature.properties.id;

  const colour = '#3B9FC2';
  const darker = darkenHex(colour, 0.75);

  var el = document.createElement('div');
  el.id = id;
  el.className = 'html-marker image-marker';
  reactDom.render(
    <>
      <div
        className="icon"
        style={{
          background: `linear-gradient(${
            268.41 - 225
          }deg, ${colour} 0%, ${darker} 50%)`,
        }}
      >
        <div className="inner">
          <FontAwesomeIcon icon="image" />
        </div>
      </div>
    </>,
    el
  );

  // Add markers to the map.
  return new mapboxgl.Marker(el, {
    draggable,
    anchor: 'bottom',
  }).setLngLat(feature.geometry.coordinates);
};

/**
 * Creates a DOM marker for a search pin
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {boolean} draggable     Is this marker draggable?
 * @param {string} color          Color of the marker.
 * @param {any} latLng coordinates
 *
 * @return {Marker} Map Marker.
 */
export const searchMarker = (map, sourceId, draggable, color, latLng) => {
  var el = document.createElement('div');
  el.className = 'search-marker';
  reactDom.render(
    <>
      <div className="icon">
        <div className="inner"></div>
      </div>
    </>,
    el
  );

  // Add markers to the map.
  return new mapboxgl.Marker(el, {
    draggable,
    anchor: 'bottom',
    color,
  }).setLngLat(latLng);
};

/**
 * Creates a DOM marker for a weather pin
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {boolean} draggable     Is this marker draggable?
 * @param {any} latLng coordinates
 *
 * @return {Marker} Map Marker.
 */
export const weatherMarker = (map, sourceId, draggable, latLng) => {
  var el = document.createElement('div');
  el.className = 'weather-marker';
  reactDom.render(
    <>
      <div className="icon">
        <div className="inner"></div>
      </div>
    </>,
    el
  );

  // Add markers to the map.
  return new mapboxgl.Marker(el, {
    draggable,
    anchor: 'bottom',
  }).setLngLat(latLng);
};

/**
 * Creates a DOM marker for waypoint.
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {any}     feature       Geojson feature.
 * @param {boolean} draggable     Is this marker draggable?
 * @param {boolean} showMilStdInfo show staff comments next to symbol
 * @param {boolean} showMilStdIcon show icon inside symbol
 * @param {string} selectedStyle selected map style
 *
 * @return {Marker} Map Marker.
 */
export const waypointMarker = (
  map,
  sourceId,
  feature,
  draggable,
  showMilStdInfo,
  showMilStdIcon,
  selectedStyle,
  zoomThreshold
) => {
  const id = feature.properties.id;

  const colour = '#3B9FC2';
  const darker = darkenHex(colour, 0.75);

  var el = document.createElement('div');
  el.id = id;
  el.className = 'html-marker waypoint-marker';
  reactDom.render(
    <>
      <div
        className="icon"
        style={{
          background: `linear-gradient(${
            268.41 - 225
          }deg, ${colour} 0%, ${darker} 50%)`,
        }}
      >
        <div className="inner">
          <span>{feature.properties.reference}</span>
        </div>
      </div>
    </>,
    el
  );

  // Add markers to the map.
  return new mapboxgl.Marker(el, {
    draggable,
    anchor: feature.properties.milspec ? 'center' : 'bottom',
  }).setLngLat(feature.geometry.coordinates);
};

/**
 * Creates a DOM marker for symbol.
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {any}     feature       Geojson feature.
 * @param {boolean} draggable     Is this marker draggable?
 * @param {boolean} showMilStdInfo show staff comments next to symbol
 * @param {boolean} showMilStdIcon show icon inside symbol
 * @param {string} selectedStyle selected map style
 *
 * @return {Marker} Map Marker.
 */
export const symbolMarker = (
  map,
  sourceId,
  feature,
  draggable,
  showMilStdInfo,
  showMilStdIcon,
  selectedStyle,
  zoomThreshold
) => {
  const id = feature.properties.id;

  //const colour = '#3B9FC2';

  var el = document.createElement('div');
  el.id = id;
  if (feature.properties.milspec) {
    const milspec = JSON.parse(feature.properties.milspec);
    const size = getLocalStorage('milStdIconSize')
      ? getLocalStorage('milStdIconSize')
      : 25;

    const options = {};
    options.size = size;
    if (milspec.engagement) {
      options.engagementBar =
        milspec.engagement.engagementState +
        milspec.engagement.remoteAndLocalEngagements +
        milspec.engagement.weaponAssignmentOrDeployment;
    }

    if (milspec.options?.direction) {
      options.direction = milspec.options.direction;
    }

    if (milspec.options?.specialHeadquarters) {
      options.specialHeadquarters = milspec.options.specialHeadquarters;
    }

    if (milspec.options?.direction) {
      options.direction = milspec.options.direction;
    }

    // if (milspec.options.reinforcedReduced) {
    //   options.reinforcedReduced = milspec.options.reinforcedReduced;
    // }

    // if (milspec.options.evaluationRating) {
    //   options.evaluationRating = milspec.options.evaluationRating;
    // }

    // if (milspec.options.combatEffectiveness) {
    //   options.combatEffectiveness = milspec.options.combatEffectiveness;
    // }

    let symbol = feature.properties.symbol.toString();

    let symbolWithoutIcon = symbol.substr(0, 10);
    symbolWithoutIcon += '0000000000';

    const symbolSvg = new ms.Symbol(
      showMilStdIcon ? symbol : symbolWithoutIcon,
      options
    )
      .asCanvas()
      .toDataURL();
    reactDom.render(
      <>
        <img src={symbolSvg} alt={'Symbol'} />
      </>,
      el
    );
  }

  // Add markers to the map.
  return new mapboxgl.Marker(el, {
    draggable,
    anchor: 'center',
  }).setLngLat(feature.geometry.coordinates);
};

/**
 * Creates a DOM marker for a photo or video.
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {any}     feature       Geojson feature.
 * @param {boolean} draggable     Is this marker draggable?
 *
 * @return {Marker} Map Marker.
 */
export const clusterMarker = (map, sourceId, feature) => {
  const id = feature.properties.cluster_id;

  const numUsers = feature.properties.user_count || 0;
  const numPhotos = feature.properties.photo_count || 0;
  const numWaypoints = feature.properties.waypoint_count || 0;

  var el = document.createElement('div');
  el.id = id;
  el.className = 'html-marker cluster-marker';
  el.dataset.pointCount = feature.properties.point_count || 0;
  reactDom.render(
    <>
      <div
        className="marker-wrapper user-cluster"
        data-count={numUsers}
        data-photos={numPhotos}
        data-waypoints={numWaypoints}
        data-users={numUsers}
      >
        <div className="inner-marker">
          <div
            className="icon"
            style={{
              background: `rgba(255, 255, 255, 1)`,
            }}
          >
            <div className="inner">
              <FontAwesomeIcon icon="user-friends" size="xs" />
              {numUsers}
            </div>
          </div>
        </div>
      </div>
      <div
        className="marker-wrapper waypoint-cluster"
        data-count={numWaypoints}
        data-photos={numPhotos}
        data-waypoints={numWaypoints}
        data-users={numUsers}
      >
        <div className="inner-marker">
          <div
            className="icon"
            style={{
              background: `rgba(255, 255, 255, 1)`,
            }}
          >
            <div className="inner">
              <FontAwesomeIcon icon="map-pin" size="xs" />
              {numWaypoints}
            </div>
          </div>
        </div>
      </div>
      <div
        className="marker-wrapper photo-cluster"
        data-count={numPhotos}
        data-photos={numPhotos}
        data-waypoints={numWaypoints}
        data-users={numUsers}
      >
        <div className="inner-marker">
          <div
            className="icon"
            style={{
              background: `rgba(255, 255, 255, 1)`,
            }}
          >
            <div className="inner">
              <FontAwesomeIcon icon="image" size="xs" />
              {numPhotos}
            </div>
          </div>
        </div>
      </div>
    </>,
    el
  );

  // Add markers to the map.
  return new mapboxgl.Marker(el, {
    anchor: 'bottom',
  }).setLngLat(feature.geometry.coordinates);
};

/**
 * Creates a DOM marker for an integration node.
 *
 * @param {any}     feature       Geojson feature.
 *
 * @return {Jsx} Jsx.
 */
export const svgMarker = (icon, w, h) => {
  return (
    <div className="html-marker" style={{ visibility: 'visible' }}>
      <img
        src={`data:image/svg+xml;base64,` + icon}
        style={{ width: w, height: h }}
        alt="iot icon"
      />
    </div>
  );
};

/**
 * Creates a DOM marker for an integration node.
 *
 * @param {any}     feature       Geojson feature.
 *
 * @return {Jsx} Jsx.
 */
export const iotMarker = (icon) => {
  const colour = '#3B9FC2';
  const darker = darkenHex(colour, 0.75);

  return (
    <div className="html-marker image-marker" style={{ visibility: 'visible' }}>
      <div
        className="icon"
        style={{
          background: `linear-gradient(${
            268.41 - 225
          }deg, ${colour} 0%, ${darker} 50%)`,
        }}
      >
        <div className="inner">
          <img src={icon} alt="iot icon" />
        </div>
      </div>
    </div>
  );
};

/**
 * Gets the leaves of a cluster
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {point}   point         Map point.
 *
 * @return {Promise<Feature[]>} Array of features.
 */
export const getClusterLeaves = (map, sourceId, point) => {
  return new Promise((resolve, reject) => {
    const features = map.queryRenderedFeatures(point, {
      layers: [`${sourceId}-cluster`],
    });
    if (features?.length) {
      const clusterId = features[0].properties.cluster_id,
        point_count = features[0].properties.point_count,
        clusterSource = map.getSource(sourceId);

      clusterSource.getClusterLeaves(
        clusterId,
        point_count,
        0,
        (err, aFeatures) => {
          if (err) {
            reject(err);
          }

          resolve(aFeatures);
        }
      );
    }
  });
};

/**
 * Gets the leaves of a cluster by id
 *
 * @param {Map}     map           MapBox map instance.
 * @param {string}  sourceId      Id of geojson source.
 * @param {string}   clusterId    Cluster Id.
 * @param {number}   limit        Limit.
 *
 * @return {Promise<Feature[]>} Array of features.
 */
export const getClusterLeavesById = (map, sourceId, clusterId, limit) => {
  return new Promise((resolve, reject) => {
    const clusterSource = map.getSource(sourceId);

    clusterSource.getClusterLeaves(clusterId, limit, 0, (err, aFeatures) => {
      if (err) {
        reject(err);
      }
      resolve(aFeatures);
    });
  });
};

export const getPixelsPerMeter = (map, mapEl) => {
  if (map && mapEl) {
    try {
      const bounds = map.getBounds();
      const topLeft = [bounds._ne.lng, bounds._ne.lat];
      const topRight = [bounds._sw.lng, bounds._ne.lat];
      const bottomLeft = [bounds._ne.lng, bounds._sw.lat];
      const bottomRight = [bounds._sw.lng, bounds._sw.lat];

      const middleLeft = midpoint(topLeft, bottomLeft);
      const middleRight = midpoint(topRight, bottomRight);
      const dist = distance(middleLeft, middleRight, { units: 'meters' });

      const clientWidth = mapEl.clientWidth;

      return clientWidth / dist;
    } catch (ex) {
      //noop
    }
  }
  return 0;
};
