/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import * as log from 'loglevel';
import { createContext, useContext, useRef, useState } from 'react';
import { AudioPlayer } from '../components/common/AudioPlayer';
import { PropTypes } from 'prop-types';

/**
 * Media state.
 *
 * @param {{
    file: any,
    type: string,
    entity: any,
    status: string,
    expanded: boolean,
  }} media current file
 * @param {number} numPlayers player state tracking - see if we should show the player
 * @param {Boolean} expanded is player expanded
 * @param {Function} setNumPlayers set the number of active media players
 * @param {Function} playPause toggle play or pause
 * @param {Function} expand expand player from comms window
 * @param {Function} collapse collapse player into comms window
 * @param {Function} addPlayer add a new media player
 * @param {Function} removePlayer remove a media player
 * @param {Function} close close media player
 */
export const MediaContext = createContext({
  media: {
    file: null,
    type: null,
    entity: null,
    status: null,
    expanded: false,
  },
  numPlayers: 0,
  expanded: false,
  setNumPlayers: (num) => {},
  playPause: (file, entity) => {},
  expand: () => {},
  collapse: () => {},
  addPlayer: () => {},
  removePlayer: () => {},
  close: () => {},
});

/**
 * Media provider.
 *
 * Top level element that provides the state context to all children. Contains context implementation.
 *
 * @param {Jsx} children child elements
 */
export const MediaProvider = ({ children }) => {
  const audio = useRef();

  const [media, setMedia] = useState({
    file: null,
    type: null,
    entity: null,
    status: null,
  });
  const [expanded, setExpanded] = useState(false);
  const [numPlayers, setNumPlayers] = useState(0);

  const playPause = async (file, entity) => {
    log.debug('playPause', file, media, audio.current);
    // reload media if different to loaded content
    if (!media?.file || media?.file !== file.file) {
      log.debug('loading');
      if (!audio.current) {
        audio.current = new Audio(file.file);
      } else {
        audio.current.setAttribute('src', file.file);
        audio.current.load();
      }
      await audio.current.play();
      setMedia({
        ...media,
        file: file.file,
        type: file.type,
        entity,
        status: 'playing',
      });
    } else if (audio.current) {
      if (audio.current.paused) {
        log.debug('playing');
        await audio.current.play();

        setMedia({ ...media, status: 'playing' });
      } else {
        log.debug('pausing');
        await audio.current.pause();
        setMedia({ ...media, status: 'paused' });
      }
    }
  };

  const expand = () => {
    setExpanded(true);
  };

  const collapse = () => {
    setExpanded(false);
  };

  const close = async () => {
    if (audio.current) {
      await audio.current.pause();
    }
    setMedia({
      ...media,
      file: null,
      type: null,
      entity: null,
      status: null,
    });
    setExpanded(false);
    setNumPlayers(0);
  };

  const contextValue = {
    media,
    expanded,
    numPlayers,
    setNumPlayers: (num) => {
      if (!num) {
        close();
      } else {
        setNumPlayers(num);
      }
    },
    playPause,
    expand,
    collapse,
    close,
  };

  return (
    <MediaContext.Provider value={contextValue}>
      {children}
      {expanded && media?.file && (
        <AudioPlayer file={media} floating></AudioPlayer>
      )}
    </MediaContext.Provider>
  );
};

MediaProvider.propTypes = {
  children: PropTypes.any,
};

/**
 * Helper hook for consuming Media context.
 *
 * @returns MediaContext
 */
export const useMedia = () => {
  return useContext(MediaContext);
};
