/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React from 'react';
import { PropTypes } from 'prop-types';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { Input } from '../../common/Input';
import { TextArea } from '../../common/TextArea';
import { Select } from '../../common/Select';
import ReactSwitch from 'react-switch';
import area from '@turf/area';
import { displayName } from '../../../utils/string';
import { useEffect, useState } from 'react';
import { getMissionUser } from '../../../api/missions';
import * as log from 'loglevel';
import { useDarkMode } from '../../../context/DarkModeContext';
import badge from '../../../assets/svg/dot-badge.svg';
import { ColorPicker } from '../../common/ColorPicker';
import { ShapeColors, GeofenceColors } from '../../../enums/geofenceLevel';
/**
 * Sidebar - shape details
 *
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any} shapeData shape data
 * @param {Function} doSubmitGeofence callback to submit geofence
 * @param {Function} doSubmitAnnotation callback to submit annotation
 * @param {string} missionId mission id
 */
export const ShapeDetails = ({
  closeSidebar,
  shapeData,
  doSubmitGeofence,
  doSubmitAnnotation,
  missionId,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [alertLevel, setAlertLevel] = useState('');
  const [annotationColor, setAnnotationColor] = useState('');
  const [annotationType, setAnnotationType] = useState('');
  const [alertOnEntry, setAlertOnEntry] = useState(false);
  const [alertOnExit, setAlertOnExit] = useState(false);
  const [notifyAdmin, setNotifyAdmin] = useState(false);
  const [notifyUser, setNotifyUser] = useState(false);
  const [timestamp, setTimestamp] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [createdBy, setCreatedBy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAlertLevelColor, setShowAlertLevelColor] = useState(false);
  const [alertLevelColor, setAlertLevelColor] = useState(null);
  const [annotationColorValue, setAnnotationColorValue] = useState('');

  const globalDarkMode = useDarkMode();

  function getColorCode(value) {
    if (!value) return '#505366';
    let colorCode =
      Object.keys(ShapeColors).find((key) => ShapeColors[key] === value) ||
      '#505366';
    return colorCode;
  }

  useEffect(() => {
    setName(shapeData?.properties?.name || '');
    setDescription(shapeData?.properties.notes || '');
    setAlertLevel(shapeData?.properties?.alertLevel || 'advisory');
    setAnnotationType(shapeData?.properties?.annotationType || '');
    setAlertOnEntry(shapeData?.properties?.alertOnEntry || false);
    setAlertOnExit(shapeData?.properties?.alertOnExit || false);
    setNotifyAdmin(shapeData?.properties?.notifyAdmin || false);
    setNotifyUser(shapeData?.properties?.notifyUser || false);
    setTimestamp(shapeData?.properties?._ts);
    setAlertLevelColor(getColorCode(shapeData?.properties?.color));
    setShowAlertLevelColor(shapeData?.properties?.alertLevel === 'none');
    setAnnotationColor(getColorCode(shapeData?.properties?.color));

    const annotationColors = ['#505366', '#ffd43a', '#f66f6f', ''];
    if (annotationColors.includes(shapeData?.properties?.color)) {
      setAnnotationColorValue(shapeData?.properties?.color);
    } else {
      setAnnotationColorValue('more');
      setAnnotationColor(getColorCode(shapeData?.properties?.color));
    }

    // eslint-disable-next-line
  }, [shapeData]);

  useEffect(() => {
    setLoading(true);
    const fetchUser = async () => {
      if (shapeData?.properties?.userId) {
        setCreatedBy(
          await getMissionUser(missionId, shapeData?.properties?.userId)
        );
      } else {
        setCreatedBy(null);
      }
      setLoading(false);
    };
    fetchUser().catch((e) => {
      log.error(e);
    });
  }, [shapeData, missionId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isDisabled) {
      if (shapeData?.geometry?.type !== 'LineString') {
        await doSubmitGeofence({
          id: shapeData?.properties?.id,
          name: name,
          notes: description,
          alertLevel: alertLevel,
          alertOnEntry: alertOnEntry,
          alertOnExit: alertOnExit,
          notifyAdmin: notifyAdmin,
          notifyUser: notifyUser,
          color:
            alertLevel === 'none'
              ? ShapeColors[alertLevelColor]
              : GeofenceColors[alertLevel],
        });
      } else {
        await doSubmitAnnotation({
          id: shapeData?.properties?.id,
          name: name,
          notes: description,
          color:
            annotationColorValue === 'more'
              ? ShapeColors[annotationColor]
              : annotationColorValue,
          annotationType: annotationType,
        });
      }
      closeSidebar();
    }
  };

  const isDarkMode = globalDarkMode.darkMode;

  return (
    <div className="mission-sidebar">
      {loading ? (
        <div
          className="loading-panel"
          style={{
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            top: '150px',
          }}
        >
          <div style={{ animation: 'rotation 2s infinite linear' }}>
            <img
              src={badge}
              alt="AUSTAC Logo"
              style={{ height: '50px', width: '50px' }}
            />
          </div>
          <div style={{ fontWeight: 600, fontSize: 20 }}>Loading details</div>
        </div>
      ) : (
        <>
          <div className="top">
            <div className="heading">
              <div className="text">
                Edit{' '}
                {shapeData?.geometry?.type !== 'LineString'
                  ? 'Shape'
                  : 'Annotation'}
              </div>
              <DotButton
                className="exit"
                onClick={closeSidebar}
                ariaLabel="Close"
              >
                <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
              </DotButton>
            </div>
          </div>
          <LightScrollbar className="content">
            <div className="blocks">
              <div className="group">
                <hr />
                <div className="tags">
                  <h5>Created by</h5>
                  <div key={1} className="tag">
                    <Avatar entity={createdBy?.user} size="1.75rem" />
                    <FontAwesomeIcon
                      className="unit-icon"
                      icon="user"
                      width="8px"
                      height="8px"
                    />
                    <span>
                      {createdBy?.user
                        ? displayName(createdBy?.user)
                        : 'Not recorded'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="group" style={{ flexGrow: 1 }}>
                <p>
                  {(shapeData?.properties?._ts
                    ? dayjs(timestamp * 1000)
                    : dayjs()
                  )
                    .format('h:mmA, D MMM YYYY')
                    .toUpperCase()}
                </p>
              </div>
              {shapeData?.geometry?.type !== 'LineString' && (
                <div className="group" style={{ flexGrow: 1 }}>
                  <p>
                    {shapeData?.geometry
                      ? Math.round(
                          (area(shapeData.geometry) / (1000 * 1000.0)) * 100.0
                        ) / 100.0
                      : 0}{' '}
                    km<sup>2</sup>
                  </p>
                </div>
              )}
              <hr />
            </div>
            <h3>
              {shapeData?.geometry?.type !== 'LineString'
                ? 'Shape'
                : 'Annotation'}{' '}
              Settings
            </h3>
            <form onSubmit={onSubmit} style={{ marginTop: '1rem' }}>
              <div className="controls">
                <Input
                  label="Name"
                  placeholder="Enter the name of the shape"
                  value={name}
                  onChange={(e) => {
                    setName(e.currentTarget.value);
                    if (e.currentTarget.value !== shapeData?.properties?.name) {
                      setIsDisabled(false);
                    }
                  }}
                />
                <TextArea
                  label="Description"
                  placeholder="The description that units will see when they view this shape."
                  value={description}
                  onChange={(e) => {
                    setDescription(e.currentTarget.value);
                    if (
                      e.currentTarget.value !== shapeData?.properties?.notes
                    ) {
                      setIsDisabled(false);
                    }
                  }}
                  required={false}
                />
                {shapeData?.geometry?.type === 'LineString' && (
                  <>
                    <Select
                      placeholder={''}
                      label="Colour"
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        setAnnotationColorValue(value);
                        if (value === 'others') {
                          return;
                        }

                        setAnnotationColor(value);
                        if (value !== shapeData?.properties?.color) {
                          setIsDisabled(false);
                        }
                      }}
                      value={annotationColorValue}
                      required={true}
                    >
                      <option key={1} value={'#505366'}>
                        {'Grey'}
                      </option>
                      <option key={2} value={'#ffd43a'}>
                        {'Yellow'}
                      </option>
                      <option key={3} value={'#f66f6f'}>
                        {'Red'}
                      </option>
                      <option key={4} value={'more'}>
                        {'More'}
                      </option>
                    </Select>
                    {annotationColorValue === 'more' && (
                      <ColorPicker
                        changeColor={(color) => {
                          setAnnotationColor(color);
                          setIsDisabled(false);
                        }}
                        selectedColor={annotationColor}
                      ></ColorPicker>
                    )}
                    <Select
                      placeholder={''}
                      label="Annotation Type"
                      onChange={(e) => {
                        setAnnotationType(e.currentTarget.value);
                        if (
                          e.currentTarget.value !==
                          shapeData?.properties?.annotationType
                        ) {
                          setIsDisabled(false);
                        }
                      }}
                      value={annotationType}
                      required={true}
                    >
                      <option key={1} value={'line'}>
                        {'Line'}
                      </option>
                      <option key={2} value={'arrow'}>
                        {'Arrow'}
                      </option>
                    </Select>
                  </>
                )}
                <br />
                {shapeData?.geometry?.type !== 'LineString' && (
                  <>
                    <hr />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label
                        htmlFor={'switch-conditional-alerts'}
                        style={{ cursor: 'pointer' }}
                      >
                        <h3>
                          <FontAwesomeIcon
                            icon="bell"
                            style={{
                              color: '#56b8db',
                              marginRight: '10px',
                              background: isDarkMode ? '#191e1f' : 'lightgrey',
                              padding: '15px',
                              borderRadius: '50%',
                              border: isDarkMode ? '1px solid #56b8db' : '',
                            }}
                          />
                          Geofence Alerts
                        </h3>
                      </label>
                    </div>
                    <br />
                    <Select
                      placeholder={''}
                      label="Alert level"
                      onChange={(e) => {
                        setAlertLevel(e.currentTarget.value);
                        if (
                          e.currentTarget.value !==
                          shapeData?.properties?.alertLevel
                        ) {
                          setIsDisabled(false);
                        }
                        setShowAlertLevelColor(
                          e.currentTarget.value === 'none'
                        );
                      }}
                      value={alertLevel}
                      required={true}
                    >
                      <option key={1} value={'advisory'}>
                        {'Advisory'}
                      </option>
                      <option key={2} value={'caution'}>
                        {'Caution'}
                      </option>
                      <option key={3} value={'warning'}>
                        {'Warning'}
                      </option>
                      <option key={4} value={'none'}>
                        {'None'}
                      </option>
                    </Select>
                    {!!showAlertLevelColor && (
                      <ColorPicker
                        changeColor={(color) => {
                          setAlertLevelColor(color);
                          setIsDisabled(false);
                        }}
                        selectedColor={alertLevelColor}
                      ></ColorPicker>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label
                        htmlFor={'switch-alert-on-entry'}
                        style={{ cursor: 'pointer', alignSelf: 'center' }}
                      >
                        Alert when ENTERING
                      </label>
                      <div className="switch" style={{ paddingTop: '10px' }}>
                        <label>
                          <ReactSwitch
                            id={'switch-alert-on-entry'}
                            height={28}
                            width={56}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={alertOnEntry}
                            onColor={'#2c7691'}
                            offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                            onChange={(checked) => {
                              setAlertOnEntry(checked);
                              if (
                                checked !== shapeData?.properties?.alertOnEntry
                              ) {
                                setIsDisabled(false);
                              }
                            }}
                          ></ReactSwitch>
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label
                        htmlFor={'switch-alert-on-exit'}
                        style={{ cursor: 'pointer', alignSelf: 'center' }}
                      >
                        Alert when EXITING
                      </label>
                      <div className="switch" style={{ paddingTop: '10px' }}>
                        <label>
                          <ReactSwitch
                            id={'switch-alert-on-exit'}
                            height={28}
                            width={56}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={alertOnExit}
                            onColor={'#2c7691'}
                            offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                            onChange={(checked) => {
                              setAlertOnExit(checked);
                              if (
                                checked !== shapeData?.properties?.alertOnExit
                              ) {
                                setIsDisabled(false);
                              }
                            }}
                          ></ReactSwitch>
                        </label>
                      </div>
                    </div>
                    <hr />
                    <span>
                      <b>Alert recipient:</b>
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label
                        htmlFor={'switch-notify-admin'}
                        style={{ cursor: 'pointer', alignSelf: 'center' }}
                      >
                        Notify Admin
                      </label>
                      <div className="switch" style={{ paddingTop: '10px' }}>
                        <label>
                          <ReactSwitch
                            id={'switch-notify-admin'}
                            height={28}
                            width={56}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={notifyAdmin}
                            onColor={'#2c7691'}
                            offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                            onChange={(checked) => {
                              setNotifyAdmin(checked);
                              if (
                                checked !== shapeData?.properties?.notifyAdmin
                              ) {
                                setIsDisabled(false);
                              }
                            }}
                          ></ReactSwitch>
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label
                        htmlFor={'switch-notify-user'}
                        style={{ cursor: 'pointer', alignSelf: 'center' }}
                      >
                        Notify User
                      </label>
                      <div className="switch" style={{ paddingTop: '10px' }}>
                        <label>
                          <ReactSwitch
                            id={'switch-notify-user'}
                            height={28}
                            width={56}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={notifyUser}
                            onColor={'#2c7691'}
                            offColor={isDarkMode ? '#191e1f' : '#c7cce6'}
                            onChange={(checked) => {
                              setNotifyUser(checked);
                              if (
                                checked !== shapeData?.properties?.notifyUser
                              ) {
                                setIsDisabled(false);
                              }
                            }}
                          ></ReactSwitch>
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </form>
          </LightScrollbar>
          <div className="foot">
            <DotButton
              className="primary block"
              onClick={(e) => {
                onSubmit(e);
              }}
              disabled={isDisabled}
            >
              Save Changes
            </DotButton>
          </div>
        </>
      )}
    </div>
  );
};

ShapeDetails.propTypes = {
  closeSidebar: PropTypes.func,
  doSubmitGeofence: PropTypes.func,
  doSubmitAnnotation: PropTypes.func,
  missionId: PropTypes.string,
};
