import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getClassNames } from '../../../utils/string';
import { Battery } from '../../common/Battery';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import './MissionIntegrations.scss';

/**
 * Map Sidebar - show more information about an integration marker.
 * Support varies from integration to integration.
 *
 * Implemented in IntegrationImplementation.[INTEGRATION].sidebar
 *
 * @param {string} title sidebar title
 * @param {string} titleStyle additional style to apply to title
 * @param {any} battery battery info - IoT devices may broadcast battery data
 * @param {Jsx} subHeader subheader content - can be any Jsx
 * @param {string} topClassName additional styles to add to header
 * @param {string} contentClassName additional styles to add to content
 * @param {Jsx} children child elements - content
 */
export const IntegrationInfo = ({
  title,
  titleStyle,
  battery,
  subHeader,
  topClassName,
  contentClassName,
  children,
}) => {
  return (
    <div className="mission-integrations shadow">
      <div
        className={getClassNames(
          {
            top: true,
          },
          topClassName
        )}
      >
        <div className="heading">
          <div className="text" style={titleStyle}>
            {title}
          </div>
          {!!battery && <Battery data={battery}></Battery>}
          <DotButton
            className="exit"
            onClick={() => {
              if (document.sidebars) {
                document.sidebars.closeAll();
              }
            }}
            ariaLabel="Close"
          >
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>
      </div>
      {subHeader}
      <LightScrollbar
        className={getClassNames(
          {
            content: true,
          },
          contentClassName
        )}
        style={{ padding: '20px' }}
      >
        {children}
      </LightScrollbar>
    </div>
  );
};
