/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import svgMissionBroadcast from '../../../assets/svg/mission-broadcast.svg';
import { useComms } from '../../../context/CommsContext';
import { useUser } from '../../../context/UserContext';
import { CallState } from '../../../enums/call';
import { historyType } from '../../../enums/propTypes';
import { cancellablePromise } from '../../../utils/promise';
import { formatTime } from '../../../utils/time';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { Tabs } from '../../common/Tabs';
import { ChatFiles } from '../messages/ChatFiles';
import { ChatParticipants } from '../messages/ChatParticipants';
import { PersonnelMessages } from '../messages/PersonnelMessages';
import './MissionSidebar.scss';

/**
 * Map Sidebar - mission thread chat group
 *
 * @param {string} missionId mission id
 * @param {string} threadId thread id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {'messages' || 'files'} startingTab tab to start on
 * @param {any} history react router
 * @param {Function} openUnitInformation open the personnel info sidebar
 * @param {Function} createChatWithUser create chat group with specified user and open thread
 * @param {Function} createChatWithTeam create chat group with specified team and open thread
 * @param {any[]} users mission users
 */
export const MissionThread = withRouter(
  ({
    closeSidebar,
    missionId,
    threadId,
    startingTab,
    history,
    readOnly,
    openUnitInformation,
    createChatWithUser,
    createChatWithTeam,
    users,
  }) => {
    const [tab, setTab] = useState(startingTab || 'messages');
    const comms = useComms();
    const { user } = useUser();

    const [thread, setThread] = useState(null);
    const [otherUser, setOtherUser] = useState(null);
    const [messages, setMessages] = useState([]);

    const findThread = async () => {
      const thread = comms.threads[threadId];
      if (thread) {
        return thread;
      } else {
        return null;
      }
    };

    useEffect(() => {
      setTab(startingTab || 'messages');
    }, [startingTab, threadId]);

    useEffect(() => {
      const stub = async () => {
        const _thread = await findThread();

        if (_thread && !_thread.missionWide) {
          const userId = _thread.participants.find((p) => p !== user.id);
          const _user = users.find((u) => u.id === userId);
          setOtherUser(_user);
        }

        setThread(_thread);
      };
      const { promise, cancel } = cancellablePromise(stub());
      promise.then(() => {}).catch((e) => {});
      return cancel;
      // eslint-disable-next-line
    }, [threadId]);

    useEffect(() => {
      if (thread && thread.id) {
        setMessages([
          ...(comms.messages[thread.id]
            ? comms.messages[thread.id].filter((m) => !!m.attachment)
            : []),
        ]);
      }
      // eslint-disable-next-line
    }, [comms.messages, thread]);

    const exit = () => {
      closeSidebar();
    };

    return (
      <div className="mission-sidebar broadcast">
        <div className="header">
          <div className="top">
            {!!comms &&
              !!thread &&
              !!comms?.messages[thread?.id]?.length &&
              comms?.messages[thread?.id][
                comms?.messages[thread?.id]?.length - 1
              ]?._ts && (
                <>
                  Last message{' '}
                  {formatTime(
                    comms.messages[thread.id][
                      comms.messages[thread.id].length - 1
                    ]?._ts
                  )}
                </>
              )}
            <DotButton
              className="button exit"
              onClick={() => exit()}
              ariaLabel="Close"
            >
              <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
            </DotButton>
          </div>
          <div
            className="bottom"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {thread?.missionWide ? (
                <img
                  src={svgMissionBroadcast}
                  alt="mission broadcast"
                  className="mission-broadcast"
                />
              ) : (
                <Avatar entity={thread} />
              )}

              <h2 style={{ marginLeft: '0.5rem', whiteSpace: 'break-spaces' }}>
                {thread?.threadName}
                {!!(
                  thread?.participants?.length ||
                  thread?.participantsFull?.length
                ) && (
                  <span>
                    {pluralize(
                      'member',
                      thread?.participants?.length ||
                        thread?.participantsFull?.length,
                      true
                    )}
                  </span>
                )}
              </h2>
            </div>
            {thread?.participants?.length === 2 &&
              otherUser?.communicationServices && (
                <div>
                  {comms.callState === CallState.Disconnected ? (
                    <DotButton
                      style={{
                        background: 'none',
                        border: 'none',
                        fontSize: '1.2rem',
                      }}
                      onClick={async () => {
                        await comms.makePhoneCall(otherUser);
                      }}
                    >
                      <FontAwesomeIcon icon="phone" />
                    </DotButton>
                  ) : comms.callState === CallState.Connected ? (
                    <DotButton
                      style={{
                        background: 'none',
                        border: 'none',
                        fontSize: '1.2rem',
                      }}
                      onClick={async () => {
                        await comms.hangUp();
                      }}
                    >
                      <FontAwesomeIcon icon="phone-slash" />
                    </DotButton>
                  ) : (
                    <DotButton
                      style={{
                        background: 'none',
                        border: 'none',
                        fontSize: '1.2rem',
                      }}
                      disabled
                      ariaLabel="Dialing..."
                    >
                      <FontAwesomeIcon icon="sync-alt" spin />
                    </DotButton>
                  )}
                </div>
              )}
          </div>
        </div>
        {/* <DotButton
          className="secondary small"
          onClick={() => {
            history.push(
              endpointConfig.frontendEndpoints.missionComms.replace(
                ':missionId',
                missionId
              )
            );
          }}
        >
          Go To Comms
        </DotButton> */}
        <Tabs
          items={[
            {
              text: `Messages`,
              active: tab === 'messages',
              onClick: () => setTab('messages'),
            },
            {
              text: `Files`,
              active: tab === 'files',
              onClick: () => setTab('files'),
            },
            {
              text: `Members`,
              active: tab === 'participants',
              onClick: () => setTab('participants'),
            },
          ]}
        />
        <div className="content tab-content">
          {tab === 'messages' && (
            <>
              {' '}
              {thread ? (
                <PersonnelMessages
                  id={thread.id}
                  missionId={missionId}
                  readOnly={readOnly}
                ></PersonnelMessages>
              ) : (
                <div style={{ padding: '20px' }}>Chat is not available</div>
              )}
            </>
          )}
          {tab === 'files' && (
            <>
              {!!messages.length > 0 ? (
                <div style={{ padding: '20px' }}>
                  Showing{' '}
                  {messages.length + ' ' + pluralize('file', messages.length)}
                </div>
              ) : (
                <div style={{ padding: '20px' }}>No files</div>
              )}
              {thread ? (
                <ChatFiles id={thread.id} missionId={missionId}></ChatFiles>
              ) : (
                <div style={{ padding: '20px' }}>Files are not available</div>
              )}
            </>
          )}
          {tab === 'participants' && (
            <>
              {thread ? (
                <ChatParticipants
                  history={history}
                  thread={thread}
                  readOnly={readOnly}
                  openUnitInformation={openUnitInformation}
                  createChatWithUser={async (uid) => {
                    setTab('messages');
                    await createChatWithUser(uid);
                  }}
                  createChatWithTeam={async (uid) => {
                    setTab('messages');
                    await createChatWithTeam(uid);
                  }}
                ></ChatParticipants>
              ) : (
                <div style={{ padding: '20px' }}>
                  Participants are not available
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

MissionThread.propTypes = {
  closeSidebar: PropTypes.func,
  missionId: PropTypes.string,
  startingTab: PropTypes.oneOf(['messages', 'files']),
  history: historyType,
  openUnitInformation: PropTypes.func,
  createChatWithUser: PropTypes.func,
  createChatWithTeam: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.any),
};
