/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * Sort array by object fields, in specified directions. This method mutates the array and returns a reference to the same array.
 *
 * @param {any[]}     array               Input array
 * @param {string[]}  fields              Object properties to sort by
 * @param {string[]}  [directions=null]   Sort direction for each specified property
 * @param {boolean}   [ignoreCase=false]  Ignore string case when sorting
 * @return {any[]} Output array
 */
export const sortBy = (
  array,
  fields,
  directions = null,
  ignoreCase = false
) => {
  if (!fields?.length) {
    // eslint-disable-next-line
    throw 'No fields specified';
  }
  if (!directions?.length) {
    // eslint-disable-next-line
    throw 'No directions specified';
  }
  if (fields.length !== directions.length) {
    // eslint-disable-next-line
    throw 'Field and direction arrays must be the same size';
  }

  if (!array) {
    return null;
  }

  if (!array?.length) {
    return [];
  }

  return array.sort((a, b) => {
    let idx = 0;
    let sort = 0;

    for (const field of fields) {
      let av = null;
      let bv = null;

      if (field.indexOf('.') !== -1) {
        const parts = field.split('.');
        av = a;
        bv = b;
        for (const p of parts) {
          av = av ? av[p] : null;
          bv = bv ? bv[p] : null;
        }
      } else {
        av = a[field];
        bv = b[field];
      }

      if (typeof av === 'string' && ignoreCase) {
        av = av.toUpperCase();
      }
      if (typeof bv === 'string' && ignoreCase) {
        bv = bv.toUpperCase();
      }

      if (directions[idx] === 'desc' || directions[idx] === 'descending') {
        if (av && (bv === null || bv === undefined)) {
          sort = -1;
        } else if ((av === null || av === undefined) && bv) {
          sort = 1;
        } else if (av > bv) {
          sort = -1;
        } else if (av < bv) {
          sort = 1;
        }
      } else {
        if (av && (bv === null || bv === undefined)) {
          sort = 1;
        } else if ((av === null || av === undefined) && bv) {
          sort = -1;
        } else if (av > bv) {
          sort = 1;
        } else if (av < bv) {
          sort = -1;
        }
      }

      if (!!sort) {
        return sort;
      }

      idx++;
    }

    return sort;
  });
};

/**
 * Sort array in place in given direction. This method mutates the array and returns a reference to the same array.
 *
 * @param {any[]}     array               Input array
 * @param {string}    directions          asc, desc, ascending or descending
 * @return {any[]} Output array
 */
export const sortArray = (array, direction) => {
  if (!array) {
    return null;
  }

  if (!array?.length) {
    return [];
  }

  return array.sort((a, b) => {
    if (direction === 'desc' || direction === 'descending') {
      if (a && (b === null || b === undefined)) {
        return -1;
      } else if ((a === null || a === undefined) && b) {
        return 1;
      } else if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      }
    } else {
      if (a && (b === null || b === undefined)) {
        return 1;
      } else if ((a === null || a === undefined) && b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      }
    }
    return 0;
  });
};

/**
 * Returns a new array with distinct items.
 *
 * @param {*} array source array
 * @return {*} new array
 */
export const unique = (array) => {
  if (!array?.length) {
    return [];
  }
  if (typeof array[0] === 'object') {
    // comparing objects - this will be slower
    return [...new Set(array.map((x) => JSON.stringify(x)))].map((x) =>
      JSON.parse(x)
    );
  }
  return [...new Set(array)];
};
