/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useUser } from '../../../context/UserContext';
import { POI } from '../../../enums/poi';
import { displayName } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightScrollbar } from '../../common/Scrollbars';
import { SearchInput } from '../../common/Search';
import './MissionSidebar.scss';

/**
 * Map Sidebar - show included markers (cluster)
 *
 * @param {string} missionId mission id
 * @param {Function} closeSidebar callback to close sidebar
 * @param {any} includedMarkers markers to show
 * @param {Function} setMarker drill down to a specific marker
 * @param {Function} chatWithUser callback to chat with user
 */
export const MissionIncludedMarkers = ({
  closeSidebar,
  includedMarkers,
  setMarker,
  chatWithUser,
}) => {
  const [searchText, setSearchText] = useState('');
  const { user } = useUser();

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const exit = () => {
    closeSidebar();
  };

  const searchItems = (e) => {
    setSearchText(e.target.value);
  };

  const transformIncluded = includedMarkers.filter(
    (o) => displayName(o).toLowerCase().indexOf(searchText.toLowerCase()) !== -1
  );

  let includedType = 'Marker';
  if (includedMarkers[0]?.poiType === POI.Photo) {
    includedType = 'Photo';
  } else if (includedMarkers[0]?.poiType === POI.Waypoint) {
    includedType = 'Waypoint';
  } else {
    includedType = 'User';
  }

  return (
    <div className="mission-sidebar">
      <div className="top has-border">
        <div className="heading">
          <div className="text">
            {pluralize(includedType, 2, false)} Included
          </div>
          <DotButton className="exit" onClick={exit} ariaLabel="Close">
            <FontAwesomeIcon icon="times" style={{ color: 'grey' }} />
          </DotButton>
        </div>

        <div style={{ marginTop: '20px' }}>
          <SearchInput
            placeholder={`Search ${pluralize(
              includedType,
              2,
              false
            ).toLowerCase()}`}
            onChange={searchItems}
            light
          />
        </div>
      </div>
      <LightScrollbar className="content">
        <div className="blocks" style={{ paddingTop: '0' }}>
          {transformIncluded?.length ? (
            <>
              <h5>
                {pluralize(
                  includedType,
                  transformIncluded.length,
                  true
                ).toLowerCase()}{' '}
                listed
              </h5>
              {transformIncluded.map((included) => (
                <div
                  key={included.id}
                  className="item-container clickable has-hover"
                  style={{
                    marginLeft: '-24px',
                    marginRight: '-24px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                  }}
                  onClick={() => {
                    setMarker(included);
                  }}
                >
                  <div className="img">
                    {included.poiType === POI.Photo ? (
                      <Avatar icon="image" />
                    ) : included.poiType === POI.Waypoint ? (
                      <Avatar icon="map-pin" />
                    ) : (
                      <Avatar entity={included} />
                    )}
                  </div>
                  <div className="text">
                    {displayName(included)}
                    <span>{included.description}</span>
                  </div>
                  {included.markerType === 'User' &&
                    !!chatWithUser &&
                    included.id !== user.id && (
                      <DotButton
                        ariaLabel="Chat with User"
                        onClick={async (e) => {
                          await chatWithUser(included);
                          e.preventDefault();
                          e.stopPropogation();
                        }}
                      >
                        <FontAwesomeIcon icon="comment-alt" />
                      </DotButton>
                    )}
                  <DotButton>
                    <FontAwesomeIcon icon="chevron-right" />
                  </DotButton>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </LightScrollbar>
    </div>
  );
};

MissionIncludedMarkers.propTypes = {
  missionId: PropTypes.string,
  closeSidebar: PropTypes.func,
  includedMarkers: PropTypes.arrayOf(PropTypes.object),
  setMarker: PropTypes.func,
  chatWithUser: PropTypes.func,
};
