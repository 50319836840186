/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import axios from 'axios';
import { mapConfig } from '../config/mapConfig';
import { get } from './global';
import * as log from 'loglevel';

/**
 * Add a map marker image to map
 *
 * @param {mapboxgl.Map} map map instance
 * @param {any} icon image icon
 * @param {string} id image id
 * @return {any} API response
 */
export const addMarkerImageToMap = (map, icon, id) => {
  map.loadImage(icon, (err, image) => {
    if (err) return;
    if (map.hasImage(id)) map.removeImage(id);
    map.addImage(id, image);
  });
};

/**
 * Reverse Geocode a location to address
 *
 * @param {number} lat latitude
 * @param {number} lng longitude
 * @param {string} types address type
 * @return {any} API response
 */
export const reverseGeocode = (lat, lng, types) => {
  const qs = [`access_token=${mapConfig.mapBoxKey}`];
  if (types) {
    if (typeof types === 'string') {
      qs.push(`types=${types}`);
    } else if (types.length) {
      qs.push(`types=${types.join(',')}`);
    }
  }
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?${qs.join(
    '&'
  )}`;
  return axios.get(url);
};

/**
 * Get W3W
 *
 * @param {number} lat latitude
 * @param {number} lng longitude
 * @return {any} API response
 */
export const what3words = async (lat, lng) => {
  return await get(`/utils/geocode?latitude=${lat}&longitude=${lng}`);
};

/**
 * Get Weather
 *
 * @param {number} lat latitude
 * @param {number} lng longitude
 * @return {any} API response
 */
export const getWeather = async (lat, lng) => {
  return await get(`/maps/weather?latitude=${lat}&longitude=${lng}`);
};

/**
 * Get elevation
 *
 * @param {number} lat latitude
 * @param {number} lng longitude
 * @return {any} API response
 */
export const getElevation = async (lat, lng) => {
  const query = `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${lng},${lat}.json?layers=contour&limit=50&access_token=${mapConfig.mapBoxKey}`;
  const data = await axios.get(query).then((response) => {
    return response.data;
  });
  const allFeatures = data.features;
  log.debug(allFeatures);
  // Create an empty array to add elevation data to
  const elevations = [];
  // For each returned feature, add elevation data to the elevations array
  for (let i = 0; i < allFeatures.length; i++) {
    elevations.push(allFeatures[i].properties.ele);
  }
  return Math.max(...elevations);
};
