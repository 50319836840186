/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

export const secrets = {
  IS_PRODUCTION: process.env.NODE_ENV && process.env.NODE_ENV !== 'development',
  ENVIRONMENT: process.env.REACT_APP_API_ENVIRONMENT,
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  API_ENDPOINT_REALTIME: process.env.REACT_APP_API_ENDPOINT_REALTIME,
  APP_STORE: process.env.REACT_APP_APP_STORE,
  PLAY_STORE: process.env.REACT_APP_PLAY_STORE,
  ENQUIRY_EMAIL: process.env.REACT_APP_ENQUIRY_EMAIL,
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  },
  webCert: {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
  },
  APP_INSIGHTS: process.env.REACT_APP_APP_INSIGHTS,
  ANDROID_URL: process.env.REACT_APP_ANDROID_URL,
  ANDROID_PACKAGE: process.env.REACT_APP_ANDROID_PACKAGE,
  ANDROID_NAME: process.env.REACT_APP_ANDROID_NAME,
  IOS_URL: process.env.REACT_APP_IOS_URL,
  IOS_APP_STORE_ID: process.env.REACT_APP_IOS_APP_STORE_ID,
  IOS_NAME: process.env.REACT_APP_IOS_NAME,
  COMMS: {
    ENDPOINT: process.env.REACT_APP_COMMS_ENDPOINT,
    KEY: process.env.REACT_APP_COMMS_KEY,
  },
  EVENT_NAME: 'mission',
};
