/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

// External Modules
// Icons
import * as log from 'loglevel';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  validateMissionInvitation,
  respondToMissionInvite,
} from '../../../../api/missions';
import { endpointConfig } from '../../../../config/endpoint_config';
import { historyType } from '../../../../enums/propTypes';
import { handleError } from '../../../../utils/error';
import { cancellablePromise } from '../../../../utils/promise';
import { DotButton } from '../../../common/buttons/DotButton';
import { Welcome } from '../../../generic-components/Welcome';
import { secrets } from '../../../../config/secrets';
import { capitalize } from '../../../../utils/string';
// Style Sheets
import './Invitation.scss';

/**
 * Accept mission invitiation page
 *
 * @param {any} history react router
 */
export const AcceptInvitation = ({ history }) => {
  const [invite, setInvite] = useState();

  const { id } = useParams();

  useEffect(() => {
    const { promise, cancel } = cancellablePromise(verifyInvitation(id));
    promise.then(() => {}).catch((e) => {});
    return cancel;
    // eslint-disable-next-line
  }, []);

  const verifyInvitation = async (id) => {
    log.debug('verifying', id);
    try {
      const res = await validateMissionInvitation(id);
      setInvite(res);
    } catch (err) {
      setInvite(null);
      // Debug line
      log.error(`Something went wrong with invitation validation`, err);
    }
  };

  const acceptInvitation = async (e) => {
    e.preventDefault();

    if (invite) {
      try {
        await respondToMissionInvite(invite.mission.id, id, true);

        toast.success(
          `You have accepted the invitation to ${invite.mission.missionName}`
        );

        history.push(
          endpointConfig.frontendEndpoints.editMission.replace(
            ':missionId',
            invite.mission.id
          )
        );
      } catch (err) {
        handleError(err);
      } finally {
      }
    }
  };

  return (
    <Welcome>
      <div className="invitation">
        {invite ? (
          <div className="invitation-form">
            <div className="invitation-text">
              <div className="invitation-text-1">
                {['You are invited to join ', invite?.mission?.missionName]}
              </div>
              <div className="invitation-text-3">
                <strong>{invite?.senderOrganisation?.organisationName}</strong>
                &nbsp;has invited you to join their {secrets.EVENT_NAME}.
              </div>
            </div>
            <form>
              <DotButton
                type="submit"
                className="primary "
                onClick={acceptInvitation}
              >
                Join {capitalize(secrets.EVENT_NAME)}
              </DotButton>
            </form>
          </div>
        ) : (
          <div> Invalid invitation</div>
        )}
      </div>
    </Welcome>
  );
};

AcceptInvitation.propTypes = { history: historyType };
