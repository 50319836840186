/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useEffect } from 'react';
import { useNotification } from './context/NotificationContext';
import { PropTypes } from 'prop-types';
import { historyType } from './enums/propTypes';
import { Toaster } from 'react-hot-toast';

/**
 * Router wrapper component to allow anonymous access to pages. This does basically nothing, it's here for code clarity.
 *
 * @param {any} history react router
 * @param {Jsx} children child elements
 */
export const Anonymous = ({ history, children }) => {
  const _notifications = useNotification();

  useEffect(() => {
    _notifications.setHistory(history);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="dot-toaster">
        <Toaster
          position="bottom-right"
          toastOptions={{
            duration: 4000,
            className: 'dot-toast',
            success: {
              iconTheme: {
                primary: 'var(--colour-positive-status-a)',
                secondary: 'var(--colour-white)',
              },
            },
            error: {
              iconTheme: {
                primary: 'var(--colour-white)',
                secondary: 'var(--colour-negative-status-a)',
              },
              style: {
                background: 'var(--colour-negative-status-a)',
                color: 'var(--colour-white)',
              },
            },
          }}
        />
      </div>
      {children}
    </>
  );
};

Anonymous.propTypes = {
  history: historyType,
  children: PropTypes.any,
};
