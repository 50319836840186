/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as log from 'loglevel';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router';
import {
  getAllMissions,
  getYourMissions,
  postMission,
} from '../../api/missions';
import blankCard from '../../assets/svg/blank-card.svg';
import { appConfig } from '../../config/appConfig';
import { endpointConfig } from '../../config/endpoint_config.js';
import { useUser } from '../../context/UserContext';
import { historyType } from '../../enums/propTypes';
import { Role } from '../../enums/role';
import { sortBy } from '../../utils/array';
import { handleError } from '../../utils/error';
import { cancellablePromise } from '../../utils/promise';
import { getClassNames } from '../../utils/string';
import { AdminBar } from '../common/AdminBar';
import { DotButton } from '../common/buttons/DotButton';
import { AdminMenu } from '../common/menus/AdminMenu';
import { NavBar } from '../common/Navbar';
import { DarkScrollbar } from '../common/Scrollbars';
import { SearchInput } from '../common/Search';
import { Tabs } from '../common/Tabs';
import { PromptModal } from '../modals/PromptModal';
import './missionNavigation.scss';
import { MissionPanel } from './MissionPanel';
import { useJoyride } from '../../context/JoyrideContext';
import { Tours } from '../../enums/tours';
import { secrets } from '../../config/secrets';
import { capitalize } from '../../utils/string';

/**
 * Home screen - list of mission
 *
 * @param {any} history react router
 */
export const MissionNavigation = withRouter(({ history }) => {
  // Global user and mission state
  const userValue = useUser();

  // New Mission modal states
  const [modalOpen, setModalOpen] = useState(false);

  // Reference to carousel scroll
  const scrollReference = useRef(null);
  const [mode, setMode] = useState('');

  const [missions, setMissions] = useState([]);
  const [allMissions, setAllMissions] = useState([]);
  const [archived, setArchived] = useState([]);
  const [invited, setInvited] = useState([]);

  const [searchText, setSearchText] = useState('');

  const [loading, setLoading] = useState(true);
  const [missionsDisabled, setMissionDisabled] = useState(false);

  const joyride = useJoyride();

  const isAdmin = () => {
    if (
      userValue?.user?.accessRoles?.length &&
      (userValue?.user?.accessRoles.includes(Role.SysAdmin) ||
        userValue?.user?.accessRoles.includes(Role.OrgAdmin))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isSysAdmin = () => {
    return (
      userValue?.user?.accessRoles?.length &&
      userValue?.user?.accessRoles.includes(Role.SysAdmin)
    );
  };

  useEffect(() => {
    joyride.setTour(Tours.Home);
    joyride.setStepIndex(0);
    joyride.setChecked(false);
    var tourDate = joyride.getTourDate(Tours.Home);
    joyride.setRunning(
      tourDate != null && tourDate > joyride.dateEdited ? false : true
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Refresh missions page load or refresh
   */
  useEffect(() => {
    const { promise, cancel } = cancellablePromise(refreshMissions());
    promise.then(() => {}).catch((e) => {});

    return () => {
      cancel();
    };
    // eslint-disable-next-line
  }, []);

  const refreshMissions = async () => {
    try {
      const _missions = await getYourMissions();
      const _allMissions = await getAllMissions();
      const _archived = await getAllMissions(true);
      // const _invited = await getInvitedMissions();

      setMissions(_missions);
      setAllMissions(_allMissions);
      setArchived(_archived);
      setInvited([]);
    } catch (err) {
      setMissions([]);
      setAllMissions([]);
      setArchived([]);
      setInvited([]);

      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const tabItems = [
    {
      text: `Current ${capitalize(secrets.EVENT_NAME)}s (${
        loading ? '-' : missions.length
      })`,
      active: mode === '',
      onClick: () => {
        setMode('');
      },
    },
  ];

  if (isAdmin()) {
    tabItems.push({
      text: `Archived ${capitalize(secrets.EVENT_NAME)}s (${
        loading ? '-' : archived.length
      })`,
      active: mode === 'archived',
      onClick: () => {
        setMode('archived');
      },
    });
  }
  // if (isAdmin()) {
  //   tabItems.push({
  //     text: `Invited Missions (${loading ? '-' : invited.length})`,
  //     active: mode === 'invited',
  //     onClick: () => {
  //       setMode('invited');
  //     },
  //   });
  // }

  /* Renders the first row containing the welcome sign and refresh/profile buttons */
  const row1 = (history) => {
    return (
      <div className="columns is-10 nav-row1">
        <div className="column nav-row1-col1">
          <div className="nav-row1-col1-welcome">
            <span>{capitalize(secrets.EVENT_NAME)}s</span>
            <div className="buttons">
              {!!missions.length && (
                <DotButton
                  className="secondary dark"
                  style={{ width: '14rem' }}
                  onClick={() =>
                    history.push(endpointConfig.frontendEndpoints.missionNavMap)
                  }
                  disabled={missionsDisabled}
                >
                  Map View
                </DotButton>
              )}
              <SearchInput
                placeholder={`Search for a ${secrets.EVENT_NAME}...`}
                onChange={(e) => setSearchText(e.target.value)}
              ></SearchInput>
            </div>
          </div>
          <div className="nav-row1-col1-active-missions">
            <Tabs
              className={'dark'}
              style={{ width: `${14 * tabItems.length}rem` }}
              items={tabItems}
            />
          </div>
        </div>
      </div>
    );
  };

  /* Renders the second row containing the add button and the mission panel container */
  const row2 = (history) => {
    const submitName = async (value) => {
      if (value === '') {
        toast.error(`Please enter a ${secrets.EVENT_NAME} name`);
        return;
      } else {
        const rq = {
          organisationId: userValue?.user.organisationId,
          missionName: value,
        };

        log.debug('submitting', userValue, rq);
        // submit mission
        try {
          const rs = await postMission(null, rq);

          log.debug('response', rs);

          dismiss(false);

          toast.success(`${capitalize(secrets.EVENT_NAME)} created`);

          // Reroute to the selected mission page
          history.push(
            endpointConfig.frontendEndpoints.mission.replace(
              ':missionId',
              rs.id
            )
          );
        } catch (err) {
          handleError(err);
        }
      }
    };

    // override setModalOpen for close behaviour
    const dismiss = async () => {
      setModalOpen(false);

      await refreshMissions();
    };

    return (
      <>
        {!isSysAdmin() ? joyride.joyrideComponent : null}
        <div className="columns nav-row2 full step-1">
          <DarkScrollbar ref={scrollReference} noScrollX={true}>
            <div className="column nav-row2-panel-container-grid">
              {!loading ? (
                <>
                  {mode === '' && filteredMissions.length !== 0 ? (
                    <>
                      <h3>Your Active {capitalize(secrets.EVENT_NAME)}s</h3>
                    </>
                  ) : (
                    <></>
                  )}
                  {!!isAdmin() && mode === '' && (
                    <MissionCreate
                      onClick={() => setModalOpen(true)}
                      disabled={missionsDisabled}
                    />
                  )}
                  {mode === '' && filteredMissions.length !== 0 ? (
                    <>
                      {filteredMissions.map((item, index) => {
                        return (
                          <MissionPanel
                            key={index}
                            mission={item}
                            history={history}
                            isAdmin={isAdmin()}
                            disabled={missionsDisabled}
                            setMissionDisabled={setMissionDisabled}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                  {mode === '' && filteredAllMissions.length !== 0 ? (
                    <>
                      <div className="mission-seperator"></div>
                      <h3>All {capitalize(secrets.EVENT_NAME)}s</h3>
                      {filteredAllMissions.map((item, index) => {
                        return (
                          <MissionPanel
                            key={index}
                            mission={item}
                            history={history}
                            isAdmin={isAdmin()}
                            disabled={missionsDisabled}
                            setMissionDisabled={setMissionDisabled}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                  {mode === '' &&
                    filteredAllMissions.length + missions.length === 0 && (
                      <>
                        <h3>Your Active {capitalize(secrets.EVENT_NAME)}s</h3>
                        <p style={{ color: 'white' }}>
                          {capitalize(secrets.EVENT_NAME)}s you are
                          participating in will be listed here...
                        </p>
                      </>
                    )}
                  {mode === 'archived' &&
                    filteredArchived.length !== 0 &&
                    filteredArchived.map((item, index) => {
                      return (
                        <MissionPanel
                          key={index}
                          mission={item}
                          history={history}
                          isAdmin={isAdmin()}
                          disabled={missionsDisabled}
                          setMissionDisabled={setMissionDisabled}
                        />
                      );
                    })}
                  {mode === 'archived' && archived.length === 0 && (
                    <>
                      <h3>Archived {capitalize(secrets.EVENT_NAME)}s</h3>
                      <p style={{ color: 'white' }}>
                        Past {secrets.EVENT_NAME}s you have participated in will
                        be listed here...
                      </p>
                    </>
                  )}
                  {/* {mode === 'invited' &&
                    filteredInvited.length !== 0 &&
                    filteredInvited.map((item, index) => {
                      return (
                        <MissionPanel
                          key={index}
                          mission={item}
                          history={history}
                          invitationId={item.id}
                        />
                      );
                    })} */}
                </>
              ) : (
                <MissionsLoading></MissionsLoading>
              )}

              <div className="spacer">&nbsp;</div>
            </div>
          </DarkScrollbar>
        </div>
        <PromptModal
          isActive={modalOpen}
          exit={dismiss}
          modalId="create-mission"
          title={`Create a new ${secrets.EVENT_NAME}`}
          label={`${capitalize(secrets.EVENT_NAME)} Name`}
          placeholder={`Enter the name of the ${capitalize(
            secrets.EVENT_NAME
          )}`}
          buttonText={`Create ${capitalize(secrets.EVENT_NAME)}`}
          onSubmit={submitName}
        >
          A {secrets.EVENT_NAME} name is required to create a{' '}
          {secrets.EVENT_NAME}. You will be able to edit the{' '}
          {secrets.EVENT_NAME} name after creation.
        </PromptModal>
      </>
    );
  };

  const filteredMissions = sortBy(
    missions?.length
      ? missions.filter(
          (m) =>
            m.missionName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : [],
    ['_ts', 'name'],
    ['desc', 'asc']
  );
  const filteredAllMissions = sortBy(
    allMissions?.length
      ? allMissions.filter(
          (m) =>
            m.missionName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : [],
    ['_ts', 'name'],
    ['desc', 'asc']
  );
  const filteredArchived = sortBy(
    archived?.length
      ? archived.filter(
          (m) =>
            m.missionName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : [],
    ['_ts', 'name'],
    ['desc', 'asc']
  );
  // eslint-disable-next-line
  const filteredInvited = sortBy(
    invited && invited.length
      ? invited.filter(
          (m) =>
            m.missionName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : [],
    ['_ts', 'name'],
    ['desc', 'asc']
  );

  return (
    <div className="mission-nav">
      <NavBar activeRoute="missions" history={history}>
        <AdminMenu history={history} activeRoute="missions"></AdminMenu>
      </NavBar>
      <div
        className={getClassNames({
          container: true,
        })}
      >
        <AdminBar title={`Welcome to ${appConfig.name}`} history={history} />
        {row1(history)}
        {row2(history)}
      </div>
    </div>
  );
});

MissionNavigation.propTypes = {
  history: historyType,
};

/**
 * Placeholder component for missions loading
 */
const MissionsLoading = () => {
  return (
    <>
      {Array.from(Array(10).keys()).map((i) => (
        <div key={i} className="blank-card">
          <img src={blankCard} alt="blank card" />
        </div>
      ))}
    </>
  );
};

/**
 * Blank mission template used as a button for Creating a new mission
 */
const MissionCreate = ({ onClick, disabled }) => {
  return (
    <div
      className={getClassNames({ disabled }, 'card create step-3')}
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
    >
      <div className="content">
        <FontAwesomeIcon icon="plus" />
        <span>Create {capitalize(secrets.EVENT_NAME)}</span>
      </div>
    </div>
  );
};
