/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DotButton } from '../common/buttons/DotButton';
import { Input } from '../common/Input';
import { TextArea } from '../common/TextArea';
import { Modal } from './Modal';
import { secrets } from '../../config/secrets';
import { capitalize } from '../../utils/string';
import './Modals.scss';
/**
 * Modal for editing a mission - has controls that don't really fit into the Prompt model.
 *
 * @param {Boolean} isActive is modal active
 * @param {Jsx} mission mission
 * @param {string} modalId modal id
 * @param {Function} exit close modal
 * @param {Function} onSubmit confirm action
 */
export const EditMissionModal = ({
  isActive,
  mission,
  modalId,
  exit,
  onSubmit,
}) => {
  const [value, setValue] = useState(mission);

  // this will only run if initial value is changed
  useEffect(() => {
    setValue(mission);
    // eslint-disable-next-line
  }, [mission]);

  const doExit = () => {
    exit();
  };

  const handleOnSubmit = async () => {
    await onSubmit(value);
    doExit();
  };

  return (
    <Modal {...{ isActive, modalId, exit: doExit }}>
      <div className="prompt-modal">
        <h2>Edit {capitalize(secrets.EVENT_NAME)}</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <Input
            label={`${capitalize(secrets.EVENT_NAME)} Name`}
            placeholder={`Enter the name of the ${secrets.EVENT_NAME}`}
            value={value?.missionName || ''}
            onChange={(e) => {
              e.preventDefault();
              setValue({ ...value, missionName: e.target.value });
            }}
          />
          <TextArea
            label={`${capitalize(secrets.EVENT_NAME)} Description`}
            placeholder={`Enter a brief description of the ${secrets.EVENT_NAME}`}
            value={value?.description || ''}
            onChange={(e) => {
              e.preventDefault();
              setValue({ ...value, description: e.target.value });
            }}
          />
          <DotButton
            className="primary block"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();

              await handleOnSubmit();
            }}
            type="submit"
          >
            Save Changes
          </DotButton>
        </form>
      </div>
    </Modal>
  );
};

EditMissionModal.propTypes = {
  isActive: PropTypes.bool,
  mission: PropTypes.any,
  modalId: PropTypes.string,
  exit: PropTypes.func,
  onSubmit: PropTypes.func,
};
