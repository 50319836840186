/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import filesize from 'filesize';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useComms } from '../../../context/CommsContext';
import { getFileIcon } from '../../../utils/files';
import { getClassNames } from '../../../utils/string';
import { Avatar } from '../../common/Avatar';
import { DotButton } from '../../common/buttons/DotButton';
import { LightBox } from '../../common/Lightbox';
import { LightScrollbar } from '../../common/Scrollbars';
import './Messages.scss';

/**
 * Comms message thread component used in:
 * Comms tab on personnel sidebar
 * Active thread in mission comms
 * Mission broadcast sidebar
 *
 * @param {string} missionid mission id
 * @param {string} id thread id
 */
export const ChatFiles = ({ missionId, id }) => {
  const comms = useComms();

  const [previewImages, setPreviewImages] = useState([]);
  const [image, setImage] = useState(null);

  const [messages, setMessages] = useState([]);

  // when thread or messages are updated
  useEffect(() => {
    setMessages([
      ...(comms.messages[id]
        ? comms.messages[id].filter((m) => !!m.attachment)
        : []),
    ]);
    // eslint-disable-next-line
  }, [comms.messages]);

  const _setImage = (img) => {
    const allImages = messages
      .filter(
        (m) => !!m.attachment && getFileIcon(m.attachment) === 'file-image'
      )
      .map((a) => {
        return {
          photoUrl: a.attachment.url,
          name: 'attachment',
          meta: a.attachment.meta || null,
        };
      });

    setPreviewImages(allImages);
    setImage(img);
  };

  const _openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="chat">
      <LightScrollbar className="files">
        {!!messages?.length &&
          messages.map((m, index) => (
            <ChatFile
              key={index}
              message={m}
              setImage={_setImage}
              openInNewTab={_openInNewTab}
            />
          ))}
      </LightScrollbar>

      {!!previewImages?.length && (
        <LightBox
          exit={() => {
            setPreviewImages([]);
          }}
          images={previewImages}
          image={image}
          selectImage={setImage}
        ></LightBox>
      )}
    </div>
  );
};

ChatFiles.propTypes = {
  missionId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const ChatFile = ({ message, setImage, openInNewTab }) => {
  const attachment = message.attachment;
  let icon = getFileIcon(attachment);

  let onClick = null;

  if (attachment.url && attachment.mimetype.startsWith('image')) {
    onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setImage({
        photoUrl: attachment.url,
        name: 'attachment',
      });
    };
  } else {
    onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      openInNewTab(attachment.url);
    };
  }

  const sensitiveContent = attachment.meta?.adult?.isAdultContent
    ? 'Adult Content'
    : attachment.meta?.adult?.isRacyContent
    ? 'Racy Content'
    : attachment.meta?.adult?.isGoryContent
    ? 'Contains Gore'
    : '';

  return (
    <div
      className="file"
      style={{ cursor: onClick ? 'pointer' : '' }}
      onClick={onClick}
    >
      <div
        className={getClassNames({
          left: true,
          censored: sensitiveContent,
        })}
      >
        {icon === 'file-image' ? (
          <div
            className="img"
            style={{ backgroundImage: `url(${attachment.url})` }}
          ></div>
        ) : (
          <DotButton className="button">
            <div className="icon">
              <FontAwesomeIcon icon={icon} />
            </div>
            {!!attachment.filename && (
              <div className="text">
                <div className="filename">
                  {attachment.filename || 'attachment'}
                </div>
                {!!attachment.size && <span>{filesize(attachment.size)}</span>}
              </div>
            )}
          </DotButton>
        )}
        <div className="censored-text">
          <FontAwesomeIcon icon="eye-slash" size="2x"></FontAwesomeIcon>
          <div>Sensitive Content</div>
          <span>{sensitiveContent}</span>
        </div>
      </div>
      <div className="right">
        <div>
          <div>{dayjs(message._ts * 1000).format('hh:mm A')}</div>
          <div>{dayjs(message._ts * 1000).format('DD/MM/YYYY')}</div>
        </div>
        <Avatar entity={message.user} />
      </div>
    </div>
  );
};
