/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

export const endpointConfig = {
  frontendEndpoints: {
    home: '/',
    privacyPolicy: '/privacy',
    termsOfService: '/terms',
    login: '/login',
    forgotPassword: '/forgot-password',
    setPassword: '/set-password/:id',
    organisations: '/organisations',
    organisation: '/organisations/:id',
    orgUsers: '/organisations/:id/users',
    invitation: '/invitation/:id',
    editMapStyles: '/admin/map-styles',
    missionNav: '/missions',
    missionNavMap: '/missions/map',
    mission: '/missions/:missionId',
    editMission: '/missions/:missionId/edit',
    editMissionTeams: '/missions/:missionId/edit/teams',
    missionOrders: '/missions/:missionId/orders',
    missionComms: '/missions/:missionId/comms',
    missionCommsThread: '/comms/:missionId/:threadId',
    missionAcceptInvitation: '/missions/:id/accept',
    missionDeclineInvitation: '/missions/:id/decline',
    missionExpiredInvitation: '/missions/:id/expired',
    versions: '/versions',
  },
};
