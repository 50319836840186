/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useState } from 'react';
import { PropTypes } from 'prop-types';
import lockSvg from '../../../assets/svg/lock.svg';
import saveSvg from '../../../assets/svg/save.svg';
import microphoneSvg from '../../../assets/svg/microphone.svg';
import atSvg from '../../../assets/svg/at.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '../../common/Select';

/**
 * Control6 - Counter, 2 dropdowns and 4 action buttons
 *
 */
export const Control6 = ({ control, missionId }) => {
  const [locked, setLocked] = useState(false);
  const [number, setNumber] = useState();
  const [order, setOrder] = useState();
  const [vehicleConfiguration, setVehicleConfiguration] = useState();

  const handleLock = () => {
    setLocked(!locked);
  };

  const handleFocus = (e) => {
    setNumber('');
  };

  //To cleanup
  const handleOnChange = (e) => {
    let v = parseInt(e.target.value);
    if (v < 1) {
      v = 1;
      e.target.value = 1;
    }
    if (v > 99) {
      v = 99;
      e.target.value = 99;
    }
    let num = e.target.value.toString();
    if (num.length === 1) {
      num = '0' + num;
      setNumber(num);
    } else {
      setNumber(v.toString());
    }
  };

  //To cleanup
  const handleIncrement = () => {
    if (!!!number) {
      setNumber('01');
    } else if (
      number.charAt(0) === '0' &&
      parseInt(number.charAt(1)) >= 1 &&
      parseInt(number.charAt(1)) < 9
    ) {
      let num = '0' + (parseInt(number.charAt(1)) + 1).toString();
      setNumber(num);
    } else if (number === '09') {
      setNumber('10');
    } else if (parseInt(number) >= 10 && parseInt(number) < 99) {
      let num = (parseInt(number) + 1).toString();
      setNumber(num);
    } else {
      return;
    }
  };

  //To cleanup
  const handleDecrement = () => {
    if (!!!number) {
      setNumber('01');
    } else if (
      number.charAt(0) === '0' &&
      parseInt(number.charAt(1)) > 1 &&
      parseInt(number.charAt(1)) <= 9
    ) {
      let num = '0' + (parseInt(number.charAt(1)) - 1).toString();
      setNumber(num);
    } else if (number === '10') {
      setNumber('09');
    } else if (parseInt(number) > 10 && parseInt(number) <= 99) {
      let num = (parseInt(number) - 1).toString();
      setNumber(num);
    } else {
      return;
    }
  };

  return (
    <div className="control-6">
      <div className="details">
        <div className="row">
          <div className="left"></div>
          <div className="body">
            <div className="number-picker">
              <div className="top-section">
                <div className="ref">{control.ref[0]}</div>
                <div className="label">{control.label[0]}</div>
                <div className="required">* Required</div>
              </div>
              <div className="middle">
                <button onClick={() => handleDecrement()}>
                  <FontAwesomeIcon icon={'minus'} />
                </button>
                <input
                  type="number"
                  disabled={locked}
                  value={number}
                  min="01"
                  max="99"
                  onChange={(e) => handleOnChange(e)}
                  onFocus={(e) => handleFocus(e)}
                />
                <button onClick={() => handleIncrement()}>
                  <FontAwesomeIcon icon={'plus'} />
                </button>
              </div>
              <div className="bottom">{control.tip[0]}</div>
            </div>
            <div className="dropdown-select">
              <div className="top-section">
                <div className="ref">{control.ref[1]}</div>
                <div className="label">{control.label[1]}</div>
                <div className="required">* Required</div>
              </div>
              <div className="middle">
                <Select
                  onChange={(e) => {
                    setOrder(e.currentTarget.value);
                  }}
                  placeholder={''}
                  value={order}
                >
                  <option key={0} value={null}>
                    {''}
                  </option>
                  <option key={1} value={'Marching Order'}>
                    {'Marching Order'}
                  </option>
                  <option key={2} value={'Patrol Order'}>
                    {'Patrol Order'}
                  </option>
                </Select>
              </div>
              <div className="bottom">{control.tip[1]}</div>
            </div>
            <div className="dropdown-select">
              <div className="top-section">
                <div className="ref">{control.ref[2]}</div>
                <div className="label">{control.label[2]}</div>
              </div>
              <div className="middle">
                <Select
                  onChange={(e) => {
                    setVehicleConfiguration(e.currentTarget.value);
                  }}
                  placeholder={''}
                  value={vehicleConfiguration}
                >
                  <option key={0} value={null}>
                    {''}
                  </option>
                  <option key={1} value={1}>
                    {'High Sided'}
                  </option>
                  <option key={2} value={1}>
                    {'Centre Seated'}
                  </option>
                </Select>
              </div>
              <div className="bottom">{control.tip[2]}</div>
            </div>
          </div>
          <div className="action-buttons">
            <div className="row">
              <button disabled={true}>
                <img src={microphoneSvg} alt="microphone" />
              </button>
              <button>
                <img src={saveSvg} alt="save" />
              </button>
            </div>
            <div className="row">
              <button disabled={true}>
                <img src={atSvg} alt="@" />
              </button>
              <button onClick={handleLock}>
                <img src={lockSvg} alt="lock" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Control6.propTypes = {
  control: PropTypes.any.isRequired,
  missionId: PropTypes.any.isRequired,
};
