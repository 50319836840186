/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

import { useRef, useState } from 'react';
import { getClassNames } from '../../utils/string';
import './Input.scss';
import { PropTypes } from 'prop-types';
import { v4 as uuid } from 'uuid';

/**
 * Input
 *
 * @param {string} label input label (form field set label)
 * @param {string} placeholder input placeholder text
 * @param {string} value inout value
 * @param {EventHandler} onChange on input text changed
 * @param {EventHandler} onEnter on enter key pressed
 * @param {string} theme input theme
 * @param {string} type input type
 * @param {Boolean} required input is required?
 * @param {Boolean} readOnly input is read only?
 * @param {Boolean} disabled input disabled?
 * @param {number} maxLength input max text length
 * @param {string} className additional class name
 * @param {string} min minimum value for number inputs
 * @param {string} max maximum value for number inputs
 * @param {EventHandler} onBlur on blur
 */
export const Input = ({
  label,
  placeholder,
  value,
  onChange,
  theme = '',
  type = 'text',
  required = false,
  readOnly = false,
  disabled = false,
  invalid = false,
  maxLength,
  className = '',
  onEnter,
  min,
  max,
  onBlur,
}) => {
  const id = useRef(uuid());
  const [touched, setTouched] = useState(false);

  const clicked = (e) => {
    setTouched(true);
  };

  return (
    <div className={getClassNames({ 'dot-input': true }, theme, className)}>
      <input
        id={id.current}
        className={getClassNames({
          touched,
          'read-only': readOnly,
          invalid,
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        min={min}
        max={max}
        required={required}
        readOnly={readOnly}
        onClick={clicked}
        maxLength={maxLength}
        disabled={disabled}
        onKeyPress={(e) => {
          if (typeof onEnter === 'function') {
            if (e.code === 'Enter') {
              e.preventDefault();
              onEnter(value);
            }
          }
        }}
        onBlur={onBlur}
      />
      <label htmlFor={id.current}>
        {label}
        {required ? ' *' : ''}
      </label>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  theme: PropTypes.oneOf(['light', 'dark']),
  type: PropTypes.oneOf([
    'button',
    'color',
    'date',
    'datetime-local',
    'email',
    'image',
    'month',
    'number',
    'password',
    'range',
    'search',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ]),
  min: PropTypes.string,
  max: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  onBlur: PropTypes.func,
};
